import React, { Component } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube'; 

import './VideoWidget.scss';

const defaultStyle = {
    position: 'relative',
    marginLeft: '0'
}

const getVideoId = (video, type) => {
        
    let param_v = video.indexOf("v=");
    
    if(param_v > -1)
    {
        if(type && type !== 'single_video')
        {
            let param_l = video.indexOf("&list=");
            
            return video.substring(param_v + 2,param_l);
            
        }
        return video.substring(param_v + 2, video.length);
    }

    let param_embed = video.indexOf("/embed/")
    
    if(param_embed > -1)
    {
        return video.substring(param_embed + 7, video.length);
    }
    
    let param_be = video.indexOf("youtu.be/");

    if(param_be > -1)
    {
        return video.substring(param_be + 9, video.length);
    }
    
}

const getListId = (video) => {
    
    let param_l = video.indexOf("&list=");

    if(param_l > -1){
        return video.substring(param_l + 6, video.length)
    }
}

const setStyle = (width) =>
{
    return {
        width: width > 0 & width < 100 ? `${width}%` : '100%',
        marginLeft: width > 0 & width < 100 ? `${(100 - width)/2}%` : defaultStyle.left
    };

    
}

const setOptions = (options, type, video) =>
{
    let isAutoplay = options.find((o) => o === "autoplay") !== undefined;
    let isControls = options.find((o) => o === "controls") !== undefined;
    let isFullscreen = options.find((o) => o === "fullscreen") !== undefined;
    let isLoop = options.find((o) => o === "loop") !== undefined;
    let o = {
        autoplay: isAutoplay ? 1 : 0,
        controls: isControls ? 1 : 0,
        fs: isFullscreen ? 1 : 0,
        loop: isLoop ? 1 : 0,
        rel: 0
    }
    setType(o, type, video);

    return o;
}

const setType = (options, type, video) => {
    if(type === 'playlist')
    {
        options.list = getListId(video);
        options.listType = 'playlist';
    }
}

export default function VideoWidget(props) {
    const { video, width, options, type} = props;
    
    return (
        <div style={setStyle(width)} className="VideoWidget_Wrapper">
            {video !== "" && <div className="VideoWidget">
                <YouTube videoId={getVideoId(video,type)} opts={{playerVars: setOptions(options, type, video)}} className="VideoWidget--video"/>
            </div>}
        </div>
        
    )
    
}

VideoWidget.propTypes = {
    video: PropTypes.string.isRequired,
    width: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string
};

VideoWidget.defaultProps = {
    width: 100,
    options: ["controls", "fullscreen"],
    type: "single_video"
}

