import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecommendedClubs } from '../../actions/Clubs/RecommendedClubs';
import ClubTile from '../ClubTile/ClubTile';
import Loader from '../Loader/Loader';
import Slider from "react-slick";

import './RecommendedClubs.scss'

/** 
 * Recommended Clubs
 */
class RecommendedClubs extends React.Component {

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchRecommendedClubs();
	}

	render() {

		var sliderSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				  	}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				  	}
				}
			  ]
		};

        if (this.props !== null && this.props.clubs.isReady()) {
			const clubs = this.props.clubs.value;

            return (
				<div className="RecommendedClubs">
					<Slider {...sliderSettings}>
						{clubs.map((club, index) => 
							<div className="RecommendedClubs__Tile" key={index} ><ClubTile key={index} club={club} /></div>
						)}
					</Slider>
				</div>
			);
        } else if (this.props.clubs.isFaulted()) {
            return (<Loader currentState={this.props.clubs.state} errorMessage="Failed to load recommended clubs" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubs.state} />);
        }    
    }
}

/**
 * Define expected props.
 */
RecommendedClubs.propTypes = {
    clubs: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchRecommendedClubs: PropTypes.func.isRequired,
};

//Connect this container component to the redux store.
export default connect(
    state => { return { clubs: state.recommendedClubs.clubs } }, 
    dispatch => bindActionCreators({ fetchRecommendedClubs }, dispatch)
)(RecommendedClubs); 