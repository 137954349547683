import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postDemoteMember() {
    return {
        type: actionTypes.SEND_DEMOTEMEMBER
    };
}

export function postDemoteMemberSuccess(data) {
    return {
        type: actionTypes.RECEIVED_DEMOTEMEMBER_SUCCESS,
        data
    };
}

export function postDemoteMemberFailure(error) {
    return {
        type: actionTypes.RECEIVED_DEMOTEMEMBER_FAILURE,
        data: { error }
    };
}

/***
 * Promote Member Function
 */
export function sendDemoteMember(clubId, memberId) {
    return function (dispatch, getState) {
        dispatch(postDemoteMember());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/member/' + memberId + '/demote')
        .then((resp) => {
			dispatch(postDemoteMemberSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postDemoteMemberFailure(error));
		});
    };
}