/**
 * Approve Member Reducers
 */

import * as actionTypes from '../../actions/ActionTypes';
import { StatefulValue, states } from '../../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    member: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.SEND_APPROVEMEMBER:
            return {
                ...state,
                member: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_APPROVEMEMBER_SUCCESS:
            return {
                ...state,
                member: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_APPROVEMEMBER_FAILURE:
            return {
                ...state,
                member: new StatefulValue(action.data, states.Faulted)
            };
        
        default: return state;
    }
};