import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postDeclineMember() {
    return {
        type: actionTypes.SEND_DECLINEMEMBER
    };
}

export function postDeclineMemberSuccess(data) {
    return {
        type: actionTypes.RECEIVED_DECLINEMEMBER_SUCCESS,
        data
    };
}

export function postDeclineMemberFailure(error) {
    return {
        type: actionTypes.RECEIVED_DECLINEMEMBER_FAILURE,
        data: { error }
    };
}

/***
 * Decline Member Function
 */
export function sendDeclineMember(clubId, memberId) {
    return function (dispatch, getState) {
        dispatch(postDeclineMember());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/request/decline/' + memberId + '/')
        .then((resp) => {
			dispatch(postDeclineMemberSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postDeclineMemberFailure(error));
		});
    };
}