/**
 * Community Events
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import CommunityEvents from '../../components/CommunityEvents/CommunityEvents';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../assets/scss/clubs.scss';

export default function Events() {
    return (
        <main className="CommunityEvents" role="main">
			<CommunityEvents />
        </main>
    );
}