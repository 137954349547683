import React from 'react';
import Icon from '../Icon/Icon';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import ChampionshipProgress from '../ChampionshipProgress/ChampionshipProgress';
import ClubMenu from '../ClubMenu/ClubMenu';
import Button from '../Button/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { sendJoinClub } from '../../actions/Clubs/JoinClub';
import { sendLeaveClub } from '../../actions/Clubs/LeaveClub';
import DialogPopup from '../DialogPopup/DialogPopup';
import {Container, Row, Col} from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import map from 'lodash/map';
import MediaQuery from 'react-responsive';

import './ClubOverview.scss';

/** 
 * Club Overview
 */
class ClubOverview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			joinedClub: false,
			leaveClub: false,
			hasAskedToJoin: false,
			menuOpen: false
		}

		this.goToMembers = this.goToMembers.bind(this);
		this.goToMyClubs = this.goToMyClubs.bind(this);
		this.goToCreateChampionship = this.goToCreateChampionship.bind(this);
		this.joinClub = this.joinClub.bind(this);
		this.leaveClub = this.leaveClub.bind(this);
		this.goToResults = this.goToResults.bind(this);
		this.goToStandings = this.goToStandings.bind(this);
		this.goToClubProfile = this.goToClubProfile.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.joinedClub !== newProps.joinedClub) {
			if(this.props.joinedClub.isReady()) {
				if(this.props.joinedClub.value.resultType === "JoinedSuccessfully") {
					this.setState({
						joinedClub: "joined"
					});
				} else if(this.props.joinedClub.value.resultType === "RequestedSuccessfully") {
					this.setState({
						joinedClub: "pending-accept",
						hasAskedToJoin: true
					});
				}
			} else if(this.props.joinedClub.isFaulted()) {
				const error = this.props.joinedClub.value.response.data;
				if(error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function(item) {
						var errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState( prevState => {
							return {
								...prevState,
								joinedClub: "error",
								validationMessage: errorMessage
							}
						})
					}, this);
				}
			}
		}
		
		if(prevProps.leaveClub !== newProps.leaveClub) {
			if(this.props.leaveClub.isReady()) {
				this.setState({
					leaveClub: "left"
				});
			} else if(this.props.leaveClub.isFaulted()) {
				this.setState({
					leaveClub: "error"
				});
			}
		}
	}

	goToCreateChampionship() {
		this.props.push('/clubs/create-championship/' + this.props.club.club.id);
	}

	goToClubProfile() {
		this.props.push('/clubs/club/' + this.props.club.club.id);
	}

	goToMembers() {
		this.props.push('/clubs/club/' + this.props.club.club.id + '/members');
	}

	goToMyClubs() {
		this.props.push('/clubs/my-clubs');
	}

	goToResults() {
		this.props.push('/clubs/club/' + this.props.club.club.id + '/results');
	}

	goToStandings() {
		this.props.push('/clubs/club/' + this.props.club.club.id + '/standings/current');
	}

	joinClub() {
		this.props.sendJoinClub(this.props.club.club.id);
	}

	leaveClub() {
		this.props.sendLeaveClub(this.props.club.club.id);
	}

	toggleMenu() {
		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	resetDialogs() {
		this.setState({
			joinedClub: false,
			leaveClub: false
		})
	}
	
	render() { 
		const clubInfo = this.props.club;

		let bannerImg = require("../../assets/images/covers/" + ('0' + (clubInfo.club.backgroundImageId + 1)).slice(-2) + ".jpg");
		let bannerPlaceholder = require("../../assets/images/covers/" + ('0' + (clubInfo.club.backgroundImageId + 1)).slice(-2) + "_thumb.jpg");

		return (
			<React.Fragment>
				<div className={"ClubOverview " + (!this.props.fullView ? "compact" : "")}>
					<div className="ClubOverview__Banner">
						<Img image={bannerImg} placeholder={bannerPlaceholder} width="100%" height="auto" />
						<div className="ClubOverview__Banner__Overlay">
							<div className="ClubOverview__Banner__Details">
								<div onClick={this.goToClubProfile}>
									<Heading rank="2" className="ClubOverview__Banner__Title">{clubInfo.club.name}</Heading>
								</div>
								{this.props.fullView &&
									<React.Fragment>
										{clubInfo.club.isMember === false && !clubInfo.club.hasAskedToJoin && !this.state.hasAskedToJoin && <Button type="standard" className="ClubOverview__Banner__Join" onClick={this.joinClub}>Join Club</Button>}
										{clubInfo.club.isMember === false && clubInfo.club.hasAskedToJoin && <Button type="standard" className="ClubOverview__Banner__Join is-disabled">Request Pending</Button>}
										{clubInfo.club.isMember === false && this.state.hasAskedToJoin && <Button type="standard" className="ClubOverview__Banner__Join is-disabled">Request Pending</Button>}
										{clubInfo.club.isMember && clubInfo.permissions.canDisbandClub === false && <Button type="standard" className="ClubOverview__Banner__Join" onClick={this.leaveClub}>Leave Club</Button>}
									</React.Fragment>
								}
							</div>
							{this.props.fullView &&
								<React.Fragment>
									<div className="ClubOverview__Banner__Options">
										<ClubMenu permissions={clubInfo.permissions} clubInfo={clubInfo} />
									</div>

									{clubInfo.club.hasActiveChampionship === true &&
										<div className="ClubOverview__Banner__ChampionshipProgress">
											<ChampionshipProgress championship={clubInfo.club.myChampionshipProgress} />
										</div>
									}

									{clubInfo.club.hasActiveChampionship === false &&
										<div className="ClubOverview__Banner__NoActiveChampionship">
											<p>No Active Championship</p>
										</div>
									}	
								</React.Fragment>
							}
						</div>  
					</div>

					<div className={"ClubOverview__Options " + (this.state.menuOpen ? 'is-open' : '')}>
						<MediaQuery query="(max-width: 768px)">
							<div onClick={this.toggleMenu} className={"ClubOverview__Menu " + (this.state.menuOpen ? 'is-open' : '')}>
								<div className="ClubOverview__Menu__Toggle"></div>
								Club Menu
							</div>
						</MediaQuery>
						<ul>	
							<li className="ClubOverview__Option" onClick={this.goToMembers} id="membersLink">
								<Icon name="icon-members_circle" size="32" />
								{clubInfo.club.memberCount} Members
								{clubInfo.club.isMember && clubInfo.permissions.canDisbandClub === true &&
									<span className="ClubOverview__PendingInvites">{clubInfo.pendingInvites}</span>
								}
							</li>
							<li className="ClubOverview__Option" onClick={this.goToStandings} id="standingsLink">
								<Icon name="icon-trophy_circle" size="32" />
								Championship Standings
							</li>
							<li className="ClubOverview__Option" onClick={this.goToResults} id="resultsLink">
								<Icon name="icon-leaderboard_circle" size="32" />
								Recent Results
							</li>

							{clubInfo.permissions.canCreateChampionship &&
								<li className="ClubOverview__Option" onClick={this.goToCreateChampionship} id="createChampionship">
									<Icon name="icon-plus_circle" size="32" />
									Create Championship
								</li>
							}
						</ul>
					</div>

					{this.props.fullView &&
						<div className="ClubOverview__Description">
							<Container>
								<Row>
									<Col sm={{ size: 12 }}>
										{clubInfo.club.description}
									</Col>
								</Row>
							</Container>
						</div>
					}
				</div>

				{this.state.joinedClub === "joined" && 
					<DialogPopup header="Success" description="You have successfully joined the club." confirmText="Ok" onConfirm={this.goToMembers} />
				}

				{this.state.joinedClub === "pending-accept" && 
					<DialogPopup header="Request Pending" description="You have successfully applied to join the club, a club administrator will need to accept your request before you become a member." confirmText="Ok" onConfirm={this.resetDialogs} />
				}

				{this.state.joinedClub === "error" && 
					<DialogPopup header="Error" description={this.state.validationMessage} confirmText="Ok" onConfirm={this.resetDialogs} />
				}

				{this.state.leaveClub === "left" && 
					<DialogPopup header="Success" description="You have successfully left the club." confirmText="Ok" onConfirm={this.goToMyClubs} />
				}

				{this.state.leaveClub === "error" && 
					<DialogPopup header="Error" description="An error occured and you didn't leave the club" confirmText="Ok" onConfirm={this.resetDialogs} />
				}
			</React.Fragment>
		);
	};
}

// Specifies the default values for props:
ClubOverview.defaultProps = {
	fullView: true
};

/**
 * Define expected props.
 */
ClubOverview.propTypes = {
	club: PropTypes.object.isRequired,
	joinedClub: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	sendJoinClub: PropTypes.func.isRequired,
	sendLeaveClub: PropTypes.func.isRequired,
	leaveClub: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
	}),
	fullView: PropTypes.bool
};

//Connect this container component to the redux store.
export default connect(
	state => { 
		return { 
			leaveClub: state.leaveClub.result, 
			joinedClub: state.joinClub.result, 
			application: state.application.value,
			translate: getTranslate(state.localize),
		} 
	},
	dispatch => bindActionCreators({ push, sendJoinClub, sendLeaveClub }, dispatch)
)(ClubOverview); 