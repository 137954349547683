/**
 * Edit Championship
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import EditChampionship from '../../components/EditChampionship/EditChampionship';
import NoGameDataCheck from '../../containers/NoGameDataCheck';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClub } from '../../actions/Clubs/GetClub';
import Loader from '../../components/Loader/Loader'
import ClubOverview from '../../components/ClubOverview/ClubOverview';
import { fetchChampionshipData } from '../../actions/Clubs/GetChampionshipData';

import '../../assets/scss/clubs.scss';

class EditChampionshipPage extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
			clubId: parseInt(props.match.params.clubId),
            championship: {},
            isGenerated: false
        };
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchClub(this.state.clubId);
		this.props.fetchChampionshipData(this.state.clubId);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.championship.value !== newProps.championship.value) {
			if(this.props.championship.isReady()) {
				this.setState({
					championship: this.props.championship.value
				})
			}
		}
	}

	renderEditChampionship() {

		if(this.props.championship.isReady()) {
			return (<EditChampionship clubId={this.state.clubId} championship={this.props.championship.value} existing={true} />);
		} else if(this.props.championship.isFaulted()) {
			return (<Loader currentState={this.props.championship.state} errorMessage="Failed to load championships" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.championship.state} />);
        }    
	}


	render() {
		const clubInfo = this.props.club.value;

		if (this.props.club.isReady()) {
			return (
				<NoGameDataCheck>
					<main className="EditChampionship" role="main">
						<div className="Clubs__EditChampionship">
							<Row>
								<Col xs="12">
									<ClubOverview club={clubInfo} fullView={false} />
								</Col>
							</Row>
							<Row>
								<Col xs="12">
									{this.renderEditChampionship()}
								</Col>
							</Row>
						</div>
					</main>
				</NoGameDataCheck>
			);
		} else if (this.props.club.isFaulted()) {
            return (<NoGameDataCheck><Loader currentState={this.props.club.state} errorMessage="Failed to load club" /></NoGameDataCheck>);
		} else /*isIntialising() or isLoading()*/ {
            return (<NoGameDataCheck><Loader currentState={this.props.club.state} /></NoGameDataCheck>);
		}    
	}
}

/**
 * Define expected props.
 */
EditChampionshipPage.propTypes = {
	club: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchClub: PropTypes.func.isRequired,
	fetchChampionshipData: PropTypes.func.isRequired,
	championship: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
};

//Connect this container component to the redux store.
export default connect(
    state => { return { club: state.getClub.club, championship: state.getChampionshipData.settings  } }, 
    dispatch => bindActionCreators({ fetchClub, fetchChampionshipData  }, dispatch)
)(EditChampionshipPage);
