/**
 * Clubs Home
 */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ClubsBanner from '../../components/ClubsBanner/ClubsBanner';
import Heading from '../../components/Heading/Heading';
import IconButton from '../../components/IconButton/IconButton';
import MyClubs from '../../components/MyClubs/MyClubs';
import RecommendedClubs from '../../components/RecommendedClubs/RecommendedClubs';
import FindClubBanner from '../../components/FindClubBanner/FindClubBanner';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import ClubInvites from '../../components/ClubInvites/ClubInvites';
import NoGameDataCheck from '../../containers/NoGameDataCheck';

import '../../assets/scss/clubs.scss';

class ClubsHome extends React.Component {

	constructor(props) {
		super(props);

		this.goToCreateClub = this.goToCreateClub.bind(this);
	}

	goToCreateClub() {
		this.props.push('/clubs/create-club');
	}

	render() { 
        return (
            <NoGameDataCheck>
			    <main className="ClubsHome" role="main">
				    <ClubsBanner />
				    <div className="ClubsHome__ClubInvites">
					    <ClubInvites />
				    </div>
				    <div className="ClubsHome__MyClubs">
					    <Container>
						    <Row className="m-b-15">
							    <MediaQuery query="(min-width: 769px)">
								    <Col xs="12" style={{textAlign: "center"}}>
									    <Heading rank="3">My Clubs</Heading>
									    <IconButton type="text" size="small" icon="icon-plus_circle" className="ClubsHome__CreateClubButton" onClick={this.goToCreateClub}>Create Club</IconButton>
								    </Col>
							    </MediaQuery>
							    <MediaQuery query="(max-width: 768px)">
								    <Col xs="12">
									    <Heading rank="3">My Clubs</Heading>
									    <IconButton type="text" size="small" icon="icon-plus_circle" className="ClubsHome__CreateClubButton" onClick={this.goToCreateClub}>Create Club</IconButton>
								    </Col>
							    </MediaQuery>
						    </Row>
					    </Container>
					    <Row>
						    <Col xs="12">	
							    <MyClubs />
						    </Col>
					    </Row>
				    </div>
				    <div className="ClubsHome__RecommendedClubs">
					    <Container>
						    <Row className="m-b-15">
							    <MediaQuery query="(min-width: 769px)">
								    <Col xs="12" style={{textAlign: "center"}}>
									    <Heading rank="3">Recommended Clubs</Heading>
								    </Col>
							    </MediaQuery>
							    <MediaQuery query="(max-width: 768px)">
								    <Col xs="12" >
									    <Heading rank="3">Recommended Clubs</Heading>
								    </Col>
							    </MediaQuery>
						    </Row>
					    </Container>

					    <Row className="m-b-50">
						    <Col xs="12">	
							    <RecommendedClubs />
						    </Col>
					    </Row>

						<Row>
							<Col xs="12">	
								<FindClubBanner />
							</Col>
						</Row>
				    </div>
                </main>
            </NoGameDataCheck>
		);
	}
}

ClubsHome.propTypes = {
    push: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
 )(ClubsHome);