import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';
import LoginStatus from '../Identity/LoginStatus';
import { NavLink as RouterNavLink } from 'react-router-dom';
import WebFont from 'webfontloader';
import { withRouter } from "react-router";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import MediaQuery from 'react-responsive';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './NavMenu.scss';

library.add(faSortDown);


WebFont.load({
	monotype: {
		projectId: '756cb4dd-6964-4bce-8c74-017f99d32a80',
		loadAllFonts: true
	}
});

class NavMenu extends Component {
    displayName = NavMenu.name

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({
				isOpen: false
			})
		}
	}
	
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        var app = this.props.applicationStore;
		var identity = this.props.identity;
		
        return (
            <div>
                <Navbar color="dark" dark expand="md">
                    <NavbarBrand tag={RouterNavLink} to="/">DiRT Rally 2.0</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} className={this.state.isOpen ? 'is-open' : ''} />
					<MediaQuery query="(min-width: 769px)">
						<Collapse isOpen={this.state.isOpen} navbar style={{left: app.useAuthentication ? '-45px' : '0px'}}>
							<Nav className="mr-auto" navbar>
								<UncontrolledDropdown nav inNavbar>
									<DropdownToggle nav>
										Contents	<FontAwesomeIcon icon="sort-down" color="#FFF" />
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem tag={RouterNavLink} to="/game-of-the-year">
											Game Of The Year
										</DropdownItem>
										<DropdownItem tag={RouterNavLink} to="/flatout">
											Colin McRae: Flat Out Pack
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/media"><Translate id="navMenu.media">Media</Translate></NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/News"><Translate id="navMenu.news">News</Translate></NavLink>
                                </NavItem>
								<UncontrolledDropdown nav inNavbar>
									<DropdownToggle nav>
										Community 	<FontAwesomeIcon icon="sort-down" color="#FFF" />
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem tag={RouterNavLink} to="/community-events">
											<Translate id="navMenu.communityChallenges">Community Challenges</Translate>
										</DropdownItem>
										<DropdownItem tag={RouterNavLink} to="/clubs">
											<Translate id="navMenu.clubs">Clubs</Translate>
										</DropdownItem>
										<DropdownItem tag={RouterNavLink} to="/esports">eSports</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/Buy" className="is-highlight"><Translate id="navMenu.preOrder">Buy Now</Translate></NavLink>
								</NavItem>
                                <NavItem>
									<NavLink tag={RouterNavLink} to="/free-trial" className="is-highlight"><Translate id="navMenu.freeTrial">Free Trial</Translate></NavLink>
								</NavItem>
							</Nav>
						</Collapse>
						<LoginStatus useAuthentication={app.useAuthentication} identity={identity} />
					</MediaQuery>
					<MediaQuery query="(max-width: 768px)">
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav className="mr-auto" navbar>
								<NavItem>
									<NavLink tag={RouterNavLink} exact to="/"><Translate id="navMenu.home">Home</Translate></NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/game-of-the-year">Game Of The Year</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/flatout">Colin McRae: Flat Out Pack</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/media"><Translate id="navMenu.media">Media</Translate></NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/News"><Translate id="navMenu.news">News</Translate></NavLink>
                                </NavItem>
								<NavItem>
                                    <NavLink tag={RouterNavLink} to="/clubs"><Translate id="navMenu.clubs">Clubs</Translate></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to="/community-events"><Translate id="navMenu.communityChallenges">Community Challenges</Translate></NavLink>
                                </NavItem>
								<NavItem>
                                    <NavLink tag={RouterNavLink} to="/esports">eSports</NavLink>
                                </NavItem>
								<NavItem>
									<NavLink tag={RouterNavLink} to="/Buy" className="is-highlight"><Translate id="navMenu.preOrder">Buy Now</Translate></NavLink>
								</NavItem>
                                {<NavItem>
									<NavLink tag={RouterNavLink} to="/free-trial" className="is-highlight"><Translate id="navMenu.freeTrial">Free Trial</Translate></NavLink>
								</NavItem>}
							</Nav>
							<LoginStatus useAuthentication={app.useAuthentication} identity={identity} />
						</Collapse>
					</MediaQuery>
                </Navbar>
            </div>
        );
    }
};

NavMenu.defaultProps = {
    applicationStore: { useAuthentication: false },
    identity: {
        store: {
            isAuthenticated: false,
            displayName: "",
            platforms: []
        }
    }
};

/**
 * Define expected props.
 */
NavMenu.propTypes = {
    applicationStore: PropTypes.shape({
        useAuthentication: PropTypes.bool.isRequired
    }),
    identity: PropTypes.shape({
        store: PropTypes.shape({
            isAuthenticated: PropTypes.bool.isRequired,
            displayName: PropTypes.string.isRequired,
            platforms: PropTypes.array.isRequired
        }),
        actions: PropTypes.shape({
            fetchUsersIdentity: PropTypes.func.isRequired,
            updateUsersPlatform: PropTypes.func.isRequired
        })
    }) 
};

export default withRouter(NavMenu);
