/**
 * Home Page
 */
import React from 'react';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';

export default function NotFound() {

    return (
        <main role="main">
			<ErrorBanner />
        </main>
    );
};