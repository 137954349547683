import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ChampionshipConfirmation.scss';

library.add(faArrowCircleLeft, faArrowCircleRight);

/**
 * Championship Confirmation
 */
class ChampionshipConfirmation extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedEvent: this.props.selectedEvent,
			totalEvents: this.props.totalEvents
		};

		this.prevEvent = this.prevEvent.bind(this);
		this.nextEvent = this.nextEvent.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevProps.selectedEvent !== newProps.selectedEvent) {
			this.setState({
				selectedEvent: this.props.selectedEvent
			});
		}

		if(prevState.selectedEvent !== newState.selectedEvent) {
			this.props.onEventUpdate(this.state.selectedEvent);
		}
	}

	prevEvent() {
		if(this.state.selectedEvent !== 0) {
			this.setState({selectedEvent: this.state.selectedEvent - 1});
		}
	}

	nextEvent() {
		if(this.state.selectedEvent !== this.props.totalEvents) {
			this.setState({selectedEvent: this.state.selectedEvent + 1});
		}
	}
	
	render() {  
		return (
			<div className="ChampionshipConfirmation">
				<div className={"ChampionshipConfirmation__prevEvent " +  (this.state.selectedEvent !== 0 ? "" : "is-disabled")} onClick={this.prevEvent}>
					<FontAwesomeIcon icon="arrow-circle-left" color="#FFF" />
					<span>Prev Event</span>
				</div>
				
				{this.props.existing &&
					<Button size="small" onClick={this.props.onButtonClick}>Edit Championship</Button>
				}

				{!this.props.existing &&
					<Button size="small" onClick={this.props.onButtonClick}>Create Championship</Button>
				}
				<div className={"ChampionshipConfirmation__nextEvent " +  (this.state.selectedEvent !== this.props.totalEvents ? "" : "is-disabled")} onClick={this.nextEvent}>
					<span>Next Event</span>
					<FontAwesomeIcon icon="arrow-circle-right" color="#FFF" />
				</div>
			</div>
		);
	};
}

ChampionshipConfirmation.propTypes = {
	onButtonClick: PropTypes.func,
	onEventUpdate: PropTypes.func,
	selectedEvent: PropTypes.number,
	totalEvents: PropTypes.number
}

ChampionshipConfirmation.defaultProps = {
	onButtonClick: event => {
		// eslint-disable-next-line no-console
		console.log('You have clicked me!', event.target); 
	},
	onEventUpdate: event => {
		// eslint-disable-next-line no-console
		console.log('You have clicked me!', event.target); 
	}
}

export default ChampionshipConfirmation;