import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {Helmet} from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import Heading from '../../components/Heading/Heading';
import Img from '../../components/Img/Img';
import Button from '../../components/Button/Button';
import MediaQuery from 'react-responsive';
import { push } from 'connected-react-router';

import eSportsBanner from '../../assets/images/esports/bg_header.jpg';
import eSportsBannerPH from '../../assets/images/esports/bg_header_ph.jpg';
import MainFeatureTwo from '../../assets/images/msn_esport02.jpg';
import MainFeatureTwoPlaceholder from '../../assets/images/msn_esport02_ph.jpg';
import FooterImg from '../../assets/images/banner_quote.jpg';
import FooterImgPh from '../../assets/images/banner_quote_ph.jpg';
import FooterImgTab from '../../assets/images/banner_quote_tab.jpg';
import FooterImgTabPh from '../../assets/images/banner_quote_tab_ph.jpg';
import FooterImgMob from '../../assets/images/banner_quote_mob.jpg';
import FooterImgMobPh from '../../assets/images/banner_quote_mob_ph.jpg';
import EuroGamer from '../../assets/images/eurogamer_badge.png';
import FindOutMoreBanner from '../../assets/images/banner_findoutmore.jpg';

import '../../assets/scss/msn-esport.scss';

const racenetSignup = '/account/login';
const YoutubeVideo = "https://www.youtube.com/embed/";

class MsnEsports extends React.Component {
	constructor(props) {
		super(props);

		this.goToBuyNow = this.goToBuyNow.bind(this);
        this.goToTryNow = this.goToTryNow.bind(this);
        this.goToEsports = this.goToEsports.bind(this);
	}

	goToBuyNow() {
		this.props.push('/buy');
    }

    goToTryNow() {
        this.props.push('/free-trial');
    }

    goToEsports() {
        this.props.push('/esports');
    }

	render() {
		return (
			<main className="eSports">
                <Helmet>
                    <title>MSN Esports - DiRT Rally 2.0</title>
                    <meta name="description" content="DiRT Rally 2.0 dares you to carve your way through a selection of iconic rally locations from across the globe, in the most powerful off-road vehicles ever made, knowing that the smallest mistake could end your stage" />
                    <link rel="canonical" href="http://www.dirtrally2.com/motorsport-games-esports" />
                    <meta property="og:description" content="DiRT Rally 2.0 dares you to carve your way through a selection of iconic rally locations from across the globe, in the most powerful off-road vehicles ever made, knowing that the smallest mistake could end your stage" />
                    <meta name="twitter:description" content="DiRT Rally 2.0 dares you to carve your way through a selection of iconic rally locations from across the globe, in the most powerful off-road vehicles ever made, knowing that the smallest mistake could end your stage" />
                </Helmet>

				<section className="eSports__Banner">
					<Img image={eSportsBanner} placeholder={eSportsBannerPH} width="100%" height="auto" alt="" />  
				</section>

				<section className="MsnEsports__MainFeatures">
					<Container>
						<MediaQuery query="(min-width: 1400px)">
							<Row className="m-b-50">
								<Col xs="12" sm="8" md="6">
									<div className="MsnEsports__MainFeatures__Text">
									    <Heading rank="2">The Dirt Rally 2.0 World Series</Heading>
                                        <p>The DiRT Rally&trade; 2.0 World Series esports competition, in partnership with Motorsport Network, is providing some outstanding racing with racers worldwide rising to the challenge. Both Rally and Rallycross disciplines have their own championships supplying racers multiple ways to prove they are the best in the world.</p>
                                        <p>Who will take a cut of the 10k prize, stand victorious on the podium, and be crowned the World Series Champion 2019?</p>
                                        <Link to="/esports"><Img image={FindOutMoreBanner} placeholder={FindOutMoreBanner} width="100%" height="auto" alt="Find Out More" className="m-b-15 MsnEsports__Image" /></Link>
									</div>
								</Col>
                                <Col xs="12" sm="4" md="6">
                                    <div className="MsnEsports__MainFeatures__Trailer">
                                        <iframe className="m-b-15"  src={`${YoutubeVideo}${this.props.application.msnEsportsTrailerId}`} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                 </Col>
							</Row>
							<Row>
								<Col xs="12" sm="4" md="6">
                                    <Img image={MainFeatureTwo} placeholder={MainFeatureTwoPlaceholder} width="100%" height="auto" alt="Polo GTI" className="m-b-15 MsnEsports__Image" />
								</Col>
								<Col xs="12" sm="8" md="6">
									<div className="MsnEsports__MainFeatures__Text">
										<Heading rank="2">Not Yet a Dirt Rally 2.0 Owner?</Heading>
										<p>Carve your way through a selection of iconic rally locations from across the globe, in some of the world's most recognisable off-road vehicles ever made.</p>
										<p>To take part in the future DiRT World Series all you need is a copy of the game and a RaceNet account so sign up <a href={racenetSignup} className="NoColor">here</a> and get your practice in now ready for Series 2.</p>
										<div className="MsnEsports__MainFeatures__Button">	
											<Button type="standard" onClick={this.goToBuyNow}>Buy Now</Button>
                                            <Button type="standard" onClick={this.goToTryNow}>Try For Free</Button>
										</div>
									</div>
								</Col>
							</Row>
						</MediaQuery>
						<MediaQuery query="(min-width: 768px) and (max-width: 1399px)">
							<Row className="MsnEsports__MainFeatures__Heading">
								<Col col="12">
									<Heading rank="2">The Dirt Rally 2.0 World Series</Heading>
								</Col>
							</Row>
							<Row className="m-b-25">
								<Col xs="12" sm="8" md="6">
                                    <div className="MsnEsports__MainFeatures__Text">
                                        <p>The DiRT Rally&trade; 2.0 World Series esports competition, in partnership with Motorsport Network, is providing some outstanding racing with racers worldwide rising to the challenge. Both Rally and Rallycross disciplines have their own championships supplying racers multiple ways to prove they are the best in the world.</p>
                                        <p>Who will take a cut of the 10k prize, stand victorious on the podium, and be crowned the World Series Champion 2019?</p>
                                        <Link to="/esports"><Img image={FindOutMoreBanner} placeholder={FindOutMoreBanner} width="100%" height="auto" alt="Find Out More" className="m-b-15 MsnEsports__Image" /></Link>
                                    </div>
								</Col>
								<Col xs="12" sm="4" md="6">
                                    <div className="MsnEsports__MainFeatures__Trailer">
                                        <iframe className="m-b-15" src={`${YoutubeVideo}${this.props.application.msnEsportsTrailerId}`} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>  
								</Col>
							</Row>
							<Row className="MsnEsports__MainFeatures__Heading">
								<Col col="12">
									<Heading rank="2">Not Yet a Dirt Rally 2.0 Owner?</Heading>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="4" md="6">
									<Img image={MainFeatureTwo} placeholder={MainFeatureTwoPlaceholder} width="100%" height="auto" alt="Polo GTI" className="m-b-15 MsnEsports__Image" />  
								</Col>
								<Col xs="12" sm="8" md="6">
									<div className="MsnEsports__MainFeatures__Text">
										
										<p>Carve your way through a selection of iconic rally locations from across the globe, in some of the world's most recognisable off-road vehicles ever made.</p>
										<p>To take part in the future DiRT World Series all you need is a copy of the game and a RaceNet account so sign up <a href={racenetSignup} className="NoColor">here</a> and get your practice in now ready for Series 2.</p>
										<div className="MsnEsports__MainFeatures__Button">
                                            <Button type="standard" onClick={this.goToBuyNow}>Buy Now</Button>
                                            <Button type="standard" onClick={this.goToTryNow}>Try For Free</Button>
										</div>
									</div>
								</Col>
							</Row>
						</MediaQuery>
						<MediaQuery query="(max-width: 767px)">
							<Row className="m-b-50">
								<Col xs="12" sm="12" md="12">
                                    <div className="MsnEsports__MainFeatures__Trailer">
                                        <iframe className="m-b-15" src={`${YoutubeVideo}${this.props.application.msnEsportsTrailerId}`} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>  
								</Col>
								<Col xs="12" sm="12" md="12">
                                    <div className="MsnEsports__MainFeatures__Text">
                                        <Heading rank="2">The Dirt Rally 2.0 World Series</Heading>
                                        <p>The DiRT Rally&trade; 2.0 World Series esports competition, in partnership with Motorsport Network, is providing some outstanding racing with racers worldwide rising to the challenge. Both Rally and Rallycross disciplines have their own championships supplying racers multiple ways to prove they are the best in the world.</p>
                                        <p>Who will take a cut of the 10k prize, stand victorious on the podium, and be crowned the World Series Champion 2019?</p>
                                        <Link to="/esports"><Img image={FindOutMoreBanner} placeholder={FindOutMoreBanner} width="100%" height="auto" alt="Find Out More" className="m-b-15 MsnEsports__Image" /></Link>
                                    </div>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="12" md="12">
									<Img  image={MainFeatureTwo} placeholder={MainFeatureTwoPlaceholder} width="100%" height="auto" alt="Polo GTI" className="m-b-15 MsnEsports__Image" />  
								</Col>
								<Col xs="12" sm="12" md="12">
									<div className="MsnEsports__MainFeatures__Text">
										<Heading rank="2">Not Yet a Dirt Rally 2.0 Owner?</Heading>
										<p>Carve your way through a selection of iconic rally locations from across the globe, in some of the world's most recognisable off-road vehicles ever made.</p>
										<p>To take part in the future DiRT World Series all you need is a copy of the game and a RaceNet account so sign up <a href={racenetSignup} className="NoColor">here</a> and get your practice in now ready for Series 2.</p>
										<div className="MsnEsports__MainFeatures__Button">
                                            <Button type="standard" onClick={this.goToBuyNow}>Buy Now</Button>
                                            <Button type="standard" onClick={this.goToTryNow}>Try For Free</Button>
										</div>
									</div>
								</Col>
							</Row>  
						</MediaQuery>
					</Container>
				</section>
				<section className="MsnEsports__Footer">
					<MediaQuery query="(min-width: 1200px)">
						<Img image={FooterImg} placeholder={FooterImgPh} alt="MSN Esport footer image" /> 
					</MediaQuery>
					<MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
						<Img image={FooterImgTab} placeholder={FooterImgTabPh} className="MSN Esport footer image" /> 
					</MediaQuery>
					<MediaQuery query="(max-width: 767px)">
						<Img image={FooterImgMob} placeholder={FooterImgMobPh} className="MSN Esport footer image" />  
					</MediaQuery>
					<div className="MsnEsports__Footer__Content">
						<Heading rank="2">One of the best driving experiences available right now</Heading>
						<Img image={EuroGamer} placeholder={EuroGamer} className="MsnEsports__Footer__Logo" />
					</div>
				</section>
			</main>
		)
	}
    
}

//Connect this container component to the redux store.
export default connect(
	state => { return { application: state.application.value } }, 
	dispatch => bindActionCreators({push}, dispatch)
)(MsnEsports);
