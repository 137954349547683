import React from "react";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../components/Heading/Heading";
import "./Credits.scss";

/**
 * Credits
 */
class Credits extends React.Component {
  render() {
    return (
      <Container>
        <div className="Credits">
          <Heading rank="1">Credits</Heading>
          <p>
            &copy; 2019 The Codemasters Software Company Limited
            ("Codemasters"). All rights reserved. "Codemasters"&reg;,
            &ldquo;EGO&rdquo;&reg;, the Codemasters logo,
            &ldquo;DiRT&rdquo;&reg; and &ldquo;DiRT Rally&rdquo;&reg; are
            registered trademarks owned by Codemasters. &ldquo;DiRT Rally
            2.0&rdquo;&trade; and &ldquo;RaceNet&rdquo;&trade; are trademarks of
            Codemasters. All rights reserved. AMD, the AMD Arrow logo, Radeon
            and combinations thereof are trademarks of Advanced Micro Devices,
            Inc. All rights reserved. Uses Bink Video. Copyright &copy;
            1997-2019 by RAD Game Tools, Inc.Dolby and the double-D symbol are
            trademarks of Dolby Laboratories. DTS, DTS-HD, the Symbol, &amp; DTS
            and the Symbol together are registered trademarks of DTS, Inc.
            Powered by Wwise &copy; 2006 - 2019 Audiokinetic Inc. All rights
            reserved. This software product includes Autodesk&reg; Beast&trade;
            software, &copy; 2019 Autodesk, Inc. All rights reserved. Autodesk
            and Beast are registered trademarks or trademarks of Autodesk, Inc.,
            and/or its subsidiaries and/or affiliates in the USA and/or other
            countries. Portions of this software are copyright &copy;2019 The
            FreeType Project (www.freetype.org). All rights reserved.
          </p>
          <p>
            Manufactured under license of FCA Italy S.p.A. &nbsp;Lancia, Abarth
            and the Scorpion are trademarks of FCA Group Marketing S.p.A.
          </p>
          <p>
            ALPINE Official License Product. Vehicle models and trademarks
            protected by intellectual property laws. Used with permission of
            RENAULT and ALPINE.
          </p>
          <p>
            "Aston Martin&rdquo;, &ldquo;Aston Martin Racing&rdquo; and related
            logos and other trade marks are owned, licensed and/or used by Aston
            Martin and Aston Martin Racing. &nbsp;These trade marks may not be
            used, amended and/or reproduced without permission. All rights are
            reserved.
          </p>
          <p>
            Trademarks, design patents and copyrights are used with the approval
            of the owner AUDI AG.
          </p>
          <p>Manufactured under license from Automobiles Peugeot.</p>
          <p>
            The BMW logo, The MINI Logo, the BMW word mark and the MINI word
            mark as well as the BMW and MINI model designations are trademarks
            of BMW AG and are used under License.
          </p>
          <p>
            Mini Cooper S, 33 EJB, from the collection at the British Motor
            Museum, Gaydon
          </p>
          <p>
            The CASTROL logo is a trade mark and is used with permission from
            Castrol Limited.
          </p>
          <p>
            CHAMPION, FERODO and MOOG are trademarks and are reproduced with the
            permission of the Federal-Mogul Corporation.
          </p>
          <p>
            Chevrolet Camaro GT4.R, all related Emblems, and vehicle body
            designs are General Motors Trademarks used under license to The
            Codemasters Software Company Ltd.
          </p>
          <p>
            Manufactured under license from Cosworth Group Holdings Limited.
          </p>
          <Row className="m-b-25">
            <Col md="12">
              <img src="/images/credits/01_citroen.png" alt="Citreon" />
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col md="12">
              <img
                src="/images/credits/02_ford.png"
                alt="Ford"
                className="float-left m-r-15"
              />
              <p>
                Ford Motor Company Trademarks and Trade Dress used under license
                to The Codemasters Software Company Limited.{" "}
              </p>
              <p>
                Under Licence from International Management Group (UK) Limited.
              </p>
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col xs="12">
              <img
                src="/images/credits/03_rx.png"
                alt="FIA World Rallycross Championship"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <p>
                The above images are the registered trade marks of the
                Fédération Internationale de l'Automobile.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <p>
                KAPPA and <img src="/images/credits/04_kappa.png" alt="Kappa" />{" "}
                are registered trademarks owned by Basic Trademark S.A
              </p>
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col xs="12">
              <img src="/images/credits/05_mg.png" alt="MG" />
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col xs="12">
              <p>
                MITSUBISHI MOTORS and emblems are trademarks of the MITSUBISHI
                MOTORS CORPORATION and used under license to The Codemasters
                Software Co. Ltd.
              </p>
              <p>
                MOTUL and the MOTUL LOGO are registered trademarks of MOTUL
                (SA). All rights reserved.{" "}
              </p>
              <p>
                ©M-Sport Ltd 2019 'Nissan' and the names, logos, marks and
                designs of the NISSAN products are trademarks and/or
                intellectual property rights of NISSAN MOTOR CO., LTD, and used
                under license to licensee's name.
              </p>
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col md="12">
              <img
                src="/images/credits/06_opel.png"
                className="float-left m-r-15"
                alt="Opel"
              />
              <p>
                Opel, Adam R2, Corsa Super 1600, Ascona 400, Kadett C GT/E and
                Manta 400, all related Emblems, and vehicle body designs are
                Opel Automotive GmbH Trademarks used under license to The
                Codemasters Software Company Ltd.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p>
                PIRELLI and{" "}
                <img src="/images/credits/07_pirelli.png" alt="Pirelli" /> are
                registered trademarks of Pirelli &amp; C. S.p.A. and are used
                under license.{" "}
              </p>
              <p>
                The trademarks Porsche, Porsche Crest, Porsche Logotype,
                Carrera, 911 and RSR are used under license of Porsche AG.
              </p>
              <p>
                RECARO trademark is used by way of license received from RECARO
                Holding GmbH, Stuttgart, Federal Republic of Germany
              </p>
              <p>Red Bull® Marks are licensed by Red Bull GmbH/Austria</p>
              <p>
                RENAULT Official License Products. Vehicle models and trademarks
                protected by intellectual property laws. Used with permission of
                RENAULT. All rights reserved.
              </p>
              <p>
                Shelby®, GT-350® and GT-350R™ are registered trademarks of
                Carroll Shelby Licensing, Inc. and are used under licence.
              </p>
              <p>
                Shell Trade Marks used with the permission of Shell Brands
                International AG
              </p>
              <p>
                ŠKODA and the “winged arrow in the circle” (ŠKODA logo) are
                registered trademarks owned and licensed by ŠKODA AUTO a.s.
                www.skoda-auto.com
              </p>
              <p>
                “SUBARU”, “IMPREZA”, “WRX”, "STI", all other associated marks,
                emblems and body designs are properties of SUBARU CORPORATION
                and used with permission.
              </p>
              <p>
                TEXACO and the Texaco Logo are Registered Trademarks of Chevron
                Intellectual Property LLC
              </p>
              <p>
                ™VALVOLINE OR ITS SUBSIDIARIES, REGISTERED IN VARIOUS COUNTRIES.
              </p>
            </Col>
          </Row>
          <Row className="m-b-25">
            <Col xs="12">
              <img
                src="/images/credits/08_vw.png"
                className="float-left m-r-15"
                alt="Volkswagon"
              />{" "}
              Trademarks, design patents and copyrights are used with the
              permission of the owner Volkswagen AG.
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center">
              <Heading rank="3">With Special Thanks To:</Heading>
              <p>
                Benny Melander
                <br />
                Christian Geistdörfer
                <br />
                Constanze Diekmann
                <br />
                Daniel Haydon
                <br />
                Dany Colebunders
                <br />
                Derek Ringer
                <br />
                John Saunders
                <br />
                Jon Armstrong
                <br />
                Kalle Grundel
                <br />
                Katsuhiko Taguchi
                <br />
                Kenneth Eriksson
                <br />
                Kerry Michael
                <br />
                The McRae Family
                <br />
                Nicky Grist
                <br />
                Patrick Snijers
                <br />
                Phil Collins
                <br />
                Phill Mills
                <br />
                Rauno Aaltonen
                <br />
                Richard Burns Foundation
                <br />
                Robert Reid
                <br />
                Roger Clark Motorsport
                <br />
                The Solberg Family
                <br />
                Stéphane Prévot
                <br />
                Steve Worthers
                <br />
                Stuart Pegg
                <br />
                Tom Williams
                <br />
                Tuthill Porsche
                <br />
                Veronica Engan
                <br />
                Walter Rohrl
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="text-center">
              <Heading rank="3">Locations:</Heading>
              <p>
                Association Sportive du Rallycross de Lohéac / Lohéac
                <br />
                Cämara Municipal De Montalegre / Circuito de Montalegre
                <br />
                Circuits de Catalunya S.L. / Barcelona-Catalunya
                <br />
                Finnskoga Motorklubb / Höljesbanan
                <br />
                GP3R Inc. / Trois-Rivières
                <br />
                HellRX AS / Lånkebanen Hell
                <br />
                Silverstone Circuits, Limited / Silverstone Circuit
                <br />
                Royal Union Motor Entre Sambre et Meuse / Circuit Jules Tacheny
                Mettet
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="text-center">
              <Heading rank="3">Car Liveries:</Heading>
              <p>
                BMW M1 Procar Rally
                <br />
                Chris Birkbeck / Team Birkbeck Opel Kadett
                <br />
                Chris Atkinson + Patrik Sandell / Subaru WRX STI VT18x
                <br />
                Colin McRae - ŠKODA Fabia Rally
                <br />
                ESmotorsport / Volkswagen Polo S1600
                <br />
                Jon Armstrong / Drive Dmack Ford Fiesta R5
                <br />
                JRM Group Limited / Subaru Impreza WRX STI NR4
                <br />
                Makela Auto Tuning Oy / Aston Martin V8 Vantage GT4
                <br />
                Oliver Bennett / Xite Ford Fiesta Rallycross Mk7
                <br />
                Oliver Solberg / Peugeot 208 R2
                <br />
                Olsbergs MSE - RX Lite
                <br />
                Petter Solberg - Citroën C4 Rally
                <br />
                Phil Collins Rallying / Opel Ascona 400
                <br />
                Russell Brookes – Opel Manta 400
                <br />
                Spencer Sport / Mitsubishi Space Star R5
                <br />
                Tom Williams / Ford Fiesta R2
                <br />
                Tuthill Porsche / Porsche 911 R-GT
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="text-center">
              <Heading rank="3">Manufacturers:</Heading>
              <p>
                Aston Martin Lagonda Limited
                <br />
                Audi AG
                <br />
                Automobiles Citroën
                <br />
                Automobiles Peugeot and L’Aventure Peugeot
                <br />
                Bayerische Motoren Werke Aktiengesellschaft
                <br />
                British Motor Heritage Ltd
                <br />
                Cosworth Group Holdings Limited
                <br />
                FCA Italy S.p.A
                <br />
                FirstCorner Ltd / Co Olsbergs MSE
                <br />
                Ford Motor Company
                <br />
                General Motors, LLC
                <br />
                INDUSTRIAS LAHOZ 2004 S.L.
                <br />
                MG Motor UK Ltd
                <br />
                Mitsubishi Motors Corporation
                <br />
                Mpart Sport AB
                <br />
                M-Sport Limited
                <br />
                Nissan Motor Co., Ltd
                <br />
                Opel Automotive GmbH
                <br />
                Porsche AG
                <br />
                Renault s.a.s
                <br />
                Carroll Shelby Licensing, Inc.
                <br />
                ŠKODA AUTO a.s.
                <br />
                Subaru Corporation
                <br />
                Volkswagen AG
              </p>
            </Col>
          </Row>
          <Row className="text-center">
            <Col xs="12">
              <Heading rank="3">Sponsors:</Heading>
            </Col>
          </Row>
          <Row className="text-center">
            <Col xs="6">
              3M
              <br />
              3M Svenska AB
              <br />
              ACER Inc.
              <br />
              Adolf Wurth GmbH & Co. KG
              <br />
              Advanced Clutch Technology, Inc.
              <br />
              AEM Performance Electronics
              <br />
              Alcon Components Limited
              <br />
              Alpinestars S.p.A.
              <br />
              Altro Limited
              <br />
              Aktiebolaget SKF
              <br />
              Andrews Heat for Hire
              <br />
              Arai Helmet (Europe) B.V.
              <br />
              Armor All
              <br />
              Autoverwertung Kerstingjohanner GmbH
              <br />
              Axalta Coating Systems Germany GmbH & Co. KG
              <br />
              Basic Trademark S.A
              <br />
              BBS GmbH
              <br />
              BOGE
              <br />
              BorgWarner Inc
              <br />
              BOS Suspension
              <br />
              Brantz Rallymeters
              <br />
              Brembo S.p.A.
              <br />
              Bridgestone Corporation
              <br />
              Castrol Limited
              <br />
              Certex UK TRS Motorsport Equipment
              <br />
              Chevron
              <br />
              China Dongxiang (Group) Co., Ltd
              <br />
              Clarion Europe SAS
              <br />
              Cobra Seats Limited
              <br />
              Compomotive International Limited
              <br />
              Corbeau Seats Ltd
              <br />
              Cricket & Co Europe
              <br />
              Custom Wheel House LLC
              <br />
              DT (Automotives) T/A Demon Tweeks
              <br />
              Denso Corporation
              <br />
              EBC Brakes
              <br />
              EDF Motorsports
              <br />
              Elonex
              <br />
              Endless Advance Co., Ltd
              <br />
              Enkei International
              <br />
              Entercard
              <br />
              Exedy Globalparts Corporation
              <br />
              Exxon Mobil Corporation
              <br />
              Firestone / Bridgestone Brands LLC
              <br />
              Folientechnik Obermann
              <br />
              Forge Motorsport
              <br />
              Fresh
              <br />
              GAB Motorsport
              <br />
              Gemini Technologies S.r.l
              <br />
              Genesis 3D Design
              <br />
              GIP Development SARL
              <br />
              H&R Spezialfedern GmbH & Co KG
              <br />
              Hankook Tire Co., Ltd.
              <br />
              Hase Pro Co., Ltd.
              <br />
              Heinrich Eibach GmbH
              <br />
              Hella KGaA Hueck & Co.
              <br />
              Hellvik Hus
              <br />
              HKS Co., Ltd.
              <br />
              Holden Racing UK Ltd
              <br />
              Holinger Engineering
              <br />
              Honeywell International Inc
              <br />
              Hoosier Racing Tire Corp.
              <br />
              Howden Turbo GmbH
              <br />
              HRE Performance Wheels
              <br />
              Hurtigruta Carglass
              <br />
              IHI Corporation
              <br />
              Irmler GmbH
              <br />
              ITW Global Tire Repair Inc.
              <br />
              Jason Lepley Motorsport
              <br />
              JVC Kenwood Corporation
              <br />
              K&N Engineering Inc.
              <br />
              Kampf Schneid-und Wickeltechnik GmbH & Co. KG
              <br />
              Kumho Tyre UK Ltd
              <br />
              KYB Europe GmbH
              <br />
              Lackier und Karosserietechnik Schulz GmbH
              <br />
              Liqui Moly
              <br />
              Logitech Europe S.A.
              <br />
              Lucas Industries Limited
              <br />
              Mad Croc / Ultra Premium Brands Limited
              <br />
              Magneti Marelli S.p.A.
            </Col>
            <Col xs="6">
              Michelin Lifestyle Limited
              <br />
              Millers Oils Limited
              <br />
              MOMO S.r.l.
              <br />
              Motec Pty Ltd
              <br />
              MOTUL
              <br />
              MSC-Hermannsdenkmal Pivitsheide e.V. Im ADAC
              <br />
              Multimatic, Inc.
              <br />
              NGK Spark Plug Co., Limited
              <br />
              Nicky Grist Motorsport
              <br />
              NSK Europe Ltd
              <br />
              Oakley, Inc.
              <br />
              Ohlins Racing AB
              <br />
              Olivetti
              <br />
              OMP Racing S.p.A.
              <br />
              OZ S.p.A.
              <br />
              Performance Clothing UK Ltd
              <br />
              pettersolberg.com
              <br />
              PIAA UK Ltd
              <br />
              Pipercross Performance Air Filters
              <br />
              Pirelli & C. S.p.A.
              <br />
              Playseat
              <br />
              Prodrive (Holdings) Ltd
              <br />
              PUMA SE
              <br />
              Quaife Engineering Ltd
              <br />
              Racelogic UK
              <br />
              Race Technology
              <br />
              Race Winning Brands, Inc.
              <br />
              Ravensberger Schmierstoffvertrieb GmbH
              <br />
              Rebellion
              <br />
              Recaro
              <br />
              re:member
              <br />
              Repsol S.A.
              <br />
              Risse Motorsport GmbH
              <br />
              Ronal AG
              <br />
              Rycote Microphone Windshields Ltd
              <br />
              Sabelt
              <br />
              Sainteloc Racing
              <br />
              Scania Group
              <br />
              Sennheiser Electronic GmbH & Co. KG
              <br />
              Shell Brands International AG
              <br />
              Showa Corporation
              <br />
              Shure UK Ltd
              <br />
              Simpson Performance Products, Inc.
              <br />
              Sound Network Limited
              <br />
              Snap-on UK Holdings Limited
              <br />
              Spa Design
              <br />
              Sparco S.p.A.
              <br />
              Spax Performance Limited
              <br />
              SPEC Inc.
              <br />
              Spy Optic
              <br />
              spydebergreklame.no
              <br />
              STP
              <br />
              Stack Ltd
              <br />
              Stand 21 GbR
              <br />
              Stanley Black & Decker
              <br />
              SteelSeal Limited
              <br />
              Stilo S.r.l
              <br />
              Stirling Bosch Limited
              <br />
              Sumitomo Rubber Industries, Ltd.
              <br />
              Sunoco LP
              <br />
              SVP Motorsport Limited
              <br />
              Taroni & C S.a.s
              <br />
              Tein Inc.
              <br />
              Teng Tools UK Limited
              <br />
              Terratrip Limited
              <br />
              Texaco
              <br />
              The Yokohama Rubber Co., Ltd.
              <br />
              The Royal Air Force
              <br />
              Thyssenkrupp Bilstein GmbH
              <br />
              TMD Friction Services GmbH
              <br />
              Tools AS
              <br />
              Unipart Group Ltd
              <br />
              Valeo (Société Anonyme)
              <br />
              Valvoline LLC
              <br />
              VP Racing Fuels, Inc.
              <br />
              Weckheuer Motorsport
              <br />
              WMX1 Group Limited
              <br />
              Xite Energy
              <br />
              ZF Friedrichshafen AG
              <br />
              Zoom UK Distribution Limited
            </Col>
          </Row>
          <Row className="m-t-25">
            <Col xs="12">
              <p>
                All other copyrights or trademarks are the property of their
                respective owners and are being used under license. Unauthorized
                copying, adaptation, rental, lending, re-sale, arcade use,
                charging for use, broadcast, cable transmission, public
                performance, distribution or extraction of this product or any
                trademark or copyright work that forms part of this product is
                prohibited. Developed by Codemasters.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Credits;
