import React from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrophy, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MemberMenu from '../MemberMenu/MemberMenu';
import { UncontrolledTooltip } from 'reactstrap';
import Guid from 'guid';

import './ClubMember.scss'

library.add(faTrophy, faGamepad);

/** 
 * Club Member
 */
class ClubMember extends React.Component {

	render() { 
		const member = this.props.member;
		const guid = Guid.create();

		return (
			<div className="ClubMember">
				<div className="ClubMember__avatar">
					<Avatar name={member.displayName} size="70" round={true} />
				</div>
				<div className="ClubMember__name">
					{member.displayName}
				</div>

				{member.membershipType !== undefined &&
					<div className="ClubMember__history">

						{member.championshipGolds > 0 &&
							<div className="float-left m-r-10">
								<FontAwesomeIcon icon="trophy" color="#d3a929" className="ClubMember__trophy m-r-5" id={"ClubMember__GoldTrophy__" + guid} />
								x{member.championshipGolds}
								<UncontrolledTooltip placement="bottom" target={"ClubMember__GoldTrophy__" + guid}>
									Number of Gold Trophies
								</UncontrolledTooltip>
							</div>
						}

						{member.championshipSilvers > 0 &&
							<div className="float-left m-l-5 m-r-10">
								<FontAwesomeIcon icon="trophy" color="#b9bbc4" className="ClubMember__trophy m-r-5" id={"ClubMember__SilverTrophy__" + guid} />
								x{member.championshipSilvers}
								<UncontrolledTooltip placement="bottom" target={"ClubMember__SilverTrophy__" + guid}>
									Number of Silver Trophies
								</UncontrolledTooltip>
							</div>
						}

						{member.championshipBronzes > 0 &&
							<div className="float-left m-l-5 m-r-10">
								<FontAwesomeIcon icon="trophy" color="#a96700" className="ClubMember__trophy m-r-5" id={"ClubMember__BronzeTrophy__" + guid} />
								x{member.championshipBronzes}
								<UncontrolledTooltip placement="bottom" target={"ClubMember__BronzeTrophy__" + guid}>
									Number of Bronze Trophies
								</UncontrolledTooltip>
							</div>
						}

						<div className="float-left m-l-5 m-r-10">
							<FontAwesomeIcon icon="gamepad" color="#fff" className="ClubMember__trophy m-r-5" id={"ClubMember__ParticipationCount__" + guid} />
							x{member.championshipParticipation} 
							<UncontrolledTooltip placement="bottom" target={"ClubMember__ParticipationCount__" + guid}>
								Number of Championships Participated In
							</UncontrolledTooltip>
						</div>
						
					</div>

				}
				
				<div className="ClubMember__menu">
					<MemberMenu clubId={parseInt(this.props.club.club.id)} permissions={this.props.club.permissions} member={member} />
				</div>
			</div>
		);
	};
}

/**
 * Define expected props.
 */
ClubMember.propTypes = {
	club: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
};

export default ClubMember;