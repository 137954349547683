/**
 * Youtube Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    playlists: {
        "default": new StatefulValue([])
    }
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_YOUTUBE_PLAYLIST:
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [action.data.name]: new StatefulValue([], states.Loading)
                }

            };

        case actionTypes.RECEIVED_YOUTUBE_PLAYLIST_SUCCESS:
            return {
                ...state,

                playlists: {
                    ...state.playlists,
                    [action.data.name]: new StatefulValue(action.data.items, states.Ready)
                }
            };

        case actionTypes.RECEIVED_YOUTUBE_PLAYLIST_FAILURE:
            return {
                ...state,

                playlists: {
                    ...state.playlists,
                    [action.data.name]: new StatefulValue([], states.Faulted)
                }
            };
        
        default: return state;
    }
};