/* Application Actions */
export const APPLICATION_READY = 'APPLICATION_READY';

/* Error Actions */
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE';

/* Client Store Actions */
export const REQUEST_CLIENT_STORE_INITIAL_STATE = 'REQUEST_CLIENT_STORE_INITIAL_STATE';
export const RECEIVED_CLIENT_STORE_INITIAL_STATE_SUCCESS = 'RECEIVED_CLIENT_STORE_INITIAL_STATE_SUCCESS';
export const RECEIVED_CLIENT_STORE_INITIAL_STATE_FAILED = 'RECEIVED_CLIENT_STORE_INITIAL_STATE_FAILED';

/* Twitter Actions */
export const REQUEST_TWITTER_FAVORITES = 'REQUEST_TWITTER_FAVORITES';
export const RECEIVED_TWITTER_FAVORITES_SUCCESS = 'RECEIVED_TWITTER_FAVORITES_SUCCESS';
export const RECEIVED_TWITTER_FAVORITES_FAILURE = 'RECEIVED_TWITTER_FAVORITES_FAILURE';

/* YouTube Actions */
export const REQUEST_YOUTUBE_PLAYLIST = 'REQUEST_YOUTUBE_PLAYLIST';
export const RECEIVED_YOUTUBE_PLAYLIST_SUCCESS = 'RECEIVED_YOUTUBE_PLAYLIST_SUCCESS';
export const RECEIVED_YOUTUBE_PLAYLIST_FAILURE = 'RECEIVED_YOUTUBE_PLAYLIST_FAILURE';

/* News Actions */
export const REQUEST_NEWS_FEED = 'REQUEST_NEWS_FEED';
export const RECEIVED_NEWS_FEED_SUCCESS = 'RECEIVED_NEWS_FEED_SUCCESS';
export const RECEIVED_NEWS_FEED_FAILURE = 'RECEIVED_NEWS_FEED_FAILURE';

export const REQUEST_NEWS_POST = 'REQUEST_NEWS_POST';
export const RECEIVED_NEWS_POST_SUCCESS = 'RECEIVED_NEWS_POST_SUCCESS';
export const RECEIVED_NEWS_POST_FAILURE = 'RECEIVED_NEWS_POST_FAILURE';

/* Media Actions */
export const REQUEST_SCREENSHOTS = 'REQUEST_SCREENSHOTS';
export const RECEIVED_SCREENSHOTS_SUCCESS = 'RECEIVED_SCREENSHOTS_SUCCESS';
export const RECEIVED_SCREENSHOTS_FAILURE = 'RECEIVED_SCREENSHOTS_FAILURE';

/* Curator Actions */
export const REQUEST_CURATOR_FEED = 'REQUEST_CURATOR_FEED';
export const RECEIVED_CURATOR_FEED_SUCCESS = 'RECEIVED_CURATOR_FEED_SUCCESS';
export const RECEIVED_CURATOR_FEED_FAILURE = 'RECEIVED_CURATOR_FEED_FAILURE';

/* Cover Image Actions */
export const REQUEST_COVERIMAGES = 'REQUEST_COVERIMAGES';
export const RECEIVED_COVERIMAGES_SUCCESS = 'RECEIVED_COVERIMAGES_SUCCESS';
export const RECEIVED_COVERIMAGES_FAILURE = 'RECEIVED_COVERIMAGES_FAILURE';

/* Create Club Actions */
export const SEND_CREATECLUBFORM = 'SEND_CREATECLUBFORM';
export const RECEIVED_CREATECLUBFORM_SUCCESS = 'RECEIVED_CREATECLUBFORM_SUCCESS';
export const RECEIVED_CREATECLUBFORM_FAILURE = 'RECEIVED_CREATECLUBFORM_FAILURE';

/* Event Actions */
export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVED_EVENTS_SUCCESS = 'RECEIVED_EVENTS_SUCCESS';
export const RECEIVED_EVENTS_FAILURE = 'RECEIVED_EVENTS_FAILURE';

/* Identity Actions */
export const REQUEST_IDENTITY = 'REQUEST_IDENTITY';
export const RECEIVED_IDENTITY_SUCCESS = 'RECEIVED_IDENTITY_SUCCESS';
export const RECEIVED_IDENTITY_FAILURE = 'RECEIVED_IDENTITY_FAILURE';

export const REQUEST_UPDATE_PLATFORM = 'REQUEST_UPDATE_PLATFORM';
export const RECIEVED_UPDATE_PLATFORM_SUCCESS = 'RECIEVED_UPDATE_PLATFORM_SUCCESS';
export const RECIEVED_UPDATE_PLATFORM_FAILURE = 'RECIEVED_UPDATE_PLATFORM_FAILURE';

/* Leaderboard Actions */
export const SEND_LEADERBOARD = 'SEND_LEADERBOARD';
export const RECEIVED_LEADERBOARD_SUCCESS = 'RECEIVED_LEADERBOARD_SUCCESS';
export const RECEIVED_LEADERBOARD_FAILURE = 'RECEIVED_LEADERBOARD_FAILURE';

/* My Clubs Actions */
export const REQUEST_MYCLUBS = 'REQUEST_MYCLUBS';
export const RECEIVED_MYCLUBS_SUCCESS = 'RECEIVED_MYCLUBS_SUCCESS';
export const RECEIVED_MYCLUBS_FAILURE = 'RECEIVED_MYCLUBS_FAILURE';

/* Recommended Clubs Actions */
export const REQUEST_RECOMMENDEDCLUBS = 'REQUEST_RECOMMENDEDCLUBS';
export const RECEIVED_RECOMMENDEDCLUBS_SUCCESS = 'RECEIVED_RECOMMENDEDCLUBS_SUCCESS';
export const RECEIVED_RECOMMENDEDCLUBS_FAILURE = 'RECEIVED_RECOMMENDEDCLUBS_FAILURE';

/* Get Club Actions */
export const REQUEST_CLUB = 'REQUEST_CLUB';
export const RECEIVED_CLUB_SUCCESS = 'RECEIVED_CLUB_SUCCESS';
export const RECEIVED_CLUB_FAILURE = 'RECEIVED_CLUB_FAILURE';

/* Get Club Settings Actions */
export const REQUEST_CLUBSETTINGS = 'REQUEST_CLUBSETTINGS';
export const RECEIVED_CLUBSETTINGS_SUCCESS = 'RECEIVED_CLUBSETTINGS_SUCCESS';
export const RECEIVED_CLUBSETTINGS_FAILURE = 'RECEIVED_CLUBSETTINGS_FAILURE';

/* Post Edit Club Actions */
export const SEND_EDITCLUBSETTINGS = 'SEND_EDITCLUBSETTINGS';
export const RECEIVED_EDITCLUBSETTINGS_SUCCESS = 'RECEIVED_EDITCLUBSETTINGS_SUCCESS';
export const RECEIVED_EDITCLUBSETTINGS_FAILURE = 'RECEIVED_EDITCLUBSETTINGS_FAILURE';

/* Get Generated Championship Actions */
export const SEND_GENERATECHAMPIONSHIP = 'SEND_GENERATECHAMPIONSHIP';
export const RECEIVED_GENERATECHAMPIONSHIP_SUCCESS = 'RECEIVED_GENERATECHAMPIONSHIP_SUCCESS';
export const RECEIVED_GENERATECHAMPIONSHIP_FAILURE = 'RECEIVED_GENERATECHAMPIONSHIP_FAILURE';

/* Get Championship Options Actions */
export const REQUEST_CHAMPIONSHIPOPTIONS = 'REQUEST_CHAMPIONSHIPOPTIONS';
export const RECEIVED_CHAMPIONSHIPOPTIONS_SUCCESS = 'RECEIVED_CHAMPIONSHIPOPTIONS_SUCCESS';
export const RECEIVED_CHAMPIONSHIPOPTIONS_FAILURE = 'RECEIVED_CHAMPIONSHIPOPTIONS_FAILURE';

/* Get Generated Stages Actions */
export const SEND_GENERATESTAGES = 'SEND_GENERATESTAGES';
export const RECEIVED_GENERATESTAGES_SUCCESS = 'RECEIVED_GENERATESTAGES_SUCCESS';
export const RECEIVED_GENERATESTAGES_FAILURE = 'RECEIVED_GENERATESTAGES_FAILURE';

/* Post Championship Actions */
export const SEND_CREATECHAMPIONSHIP = 'SEND_CREATECHAMPIONSHIP';
export const RECEIVED_CREATECHAMPIONSHIP_SUCCESS = 'RECEIVED_CREATECHAMPIONSHIP_SUCCESS';
export const RECEIVED_CREATECHAMPIONSHIP_FAILURE = 'RECEIVED_CREATECHAMPIONSHIP_FAILURE';

/* Disband Club Actions */
export const DISBAND_CLUB = 'DISBAND_CLUB';
export const RECEIVED_DISBAND_CLUB_SUCCESS = 'RECEIVED_DISBAND_CLUB_SUCCESS';
export const RECEIVED_DISBAND_CLUB_FAILURE = 'RECEIVED_DISBAND_CLUB_FAILURE';

/* Search Clubs Actions */
export const REQUEST_CLUBS = 'REQUEST_CLUBS';
export const RECEIVED_CLUBS_SUCCESS = 'RECEIVED_CLUBS_SUCCESS';
export const RECEIVED_CLUBS_FAILURE = 'RECEIVED_CLUBS_FAILURE';

/* Get Members Actions */
export const REQUEST_MEMBERS = 'REQUEST_MEMBERS';
export const RECEIVED_MEMBERS_SUCCESS = 'RECEIVED_MEMBERS_SUCCESS';
export const RECEIVED_MEMBERS_FAILURE = 'RECEIVED_MEMBERS_FAILURE';

/* Promote Member Actions */
export const SEND_PROMOTEMEMBER = 'SEND_PROMOTEMEMBER';
export const RECEIVED_PROMOTEMEMBER_SUCCESS = 'RECEIVED_PROMOTEMEMBER_SUCCESS';
export const RECEIVED_PROMOTEMEMBER_FAILURE = 'RECEIVED_PROMOTEMEMBER_FAILURE';

/* Demote Member Actions */
export const SEND_DEMOTEMEMBER = 'SEND_DEMOTEMEMBER';
export const RECEIVED_DEMOTEMEMBER_SUCCESS = 'RECEIVED_DEMOTEMEMBER_SUCCESS';
export const RECEIVED_DEMOTEMEMBER_FAILURE = 'RECEIVED_DEMOTEMEMBER_FAILURE';

/* Kick Member Actions */
export const SEND_KICKMEMBER = 'SEND_KICKMEMBER';
export const RECEIVED_KICKMEMBER_SUCCESS = 'RECEIVED_KICKMEMBER_SUCCESS';
export const RECEIVED_KICKMEMBER_FAILURE = 'RECEIVED_KICKMEMBER_FAILURE';

/* Kick Member Actions */
export const SEND_LEAVECLUB = 'SEND_LEAVECLUB';
export const RECEIVED_LEAVECLUB_SUCCESS = 'RECEIVED_LEAVECLUB_SUCCESS';
export const RECEIVED_LEAVECLUB_FAILURE = 'RECEIVED_LEAVECLUB_FAILURE';

/* Kick Member Actions */
export const SEND_JOINCLUB = 'SEND_JOINCLUB';
export const RECEIVED_JOINCLUB_SUCCESS = 'RECEIVED_JOINCLUB_SUCCESS';
export const RECEIVED_JOINCLUB_FAILURE = 'RECEIVED_JOINCLUB_FAILURE';

/* Get Championships Actions */
export const REQUEST_CHAMPIONSHIPS = 'REQUEST_CHAMPIONSHIPS';
export const RECEIVED_CHAMPIONSHIPS_SUCCESS = 'RECEIVED_CHAMPIONSHIPS_SUCCESS';
export const RECEIVED_CHAMPIONSHIPS_FAILURE = 'RECEIVED_CHAMPIONSHIPS_FAILURE';

/* Get Member Requests Actions */
export const REQUEST_MEMBERREQUESTS = 'REQUEST_MEMBERREQUESTS';
export const RECEIVED_MEMBERREQUESTS_SUCCESS = 'RECEIVED_MEMBERREQUESTS_SUCCESS';
export const RECEIVED_MEMBERREQUESTS_FAILURE = 'RECEIVED_MEMBERREQUESTS_FAILURE';

/* Member Approve Actions */
export const SEND_APPROVEMEMBER = 'SEND_APPROVEMEMBER';
export const RECEIVED_APPROVEMEMBER_SUCCESS = 'RECEIVED_APPROVEMEMBER_SUCCESS';
export const RECEIVED_APPROVEMEMBER_FAILURE = 'RECEIVED_APPROVEMEMBER_FAILURE';

/* Member Decline Actions */
export const SEND_DECLINEMEMBER = 'SEND_DECLINEMEMBER';
export const RECEIVED_DECLINEMEMBER_SUCCESS = 'RECEIVED_DECLINEMEMBER_SUCCESS';
export const RECEIVED_DECLINEMEMBER_FAILURE = 'RECEIVED_DECLINEMEMBER_FAILURE';

/* Get Friends Actions */
export const REQUEST_FRIENDS = 'REQUEST_FRIENDS';
export const RECEIVED_FRIENDS_SUCCESS = 'RECEIVED_FRIENDS_SUCCESS';
export const RECEIVED_FRIENDS_FAILURE = 'RECEIVED_FRIENDS_FAILURE';

/* Invite Friends Actions */
export const SEND_INVITEFRIENDS = 'SEND_INVITEFRIENDS';
export const RECEIVED_FRIENDSINVITE_SUCCESS = 'RECEIVED_FRIENDSINVITE_SUCCESS';
export const RECEIVED_FRIENDSINVITE_FAILURE = 'RECEIVED_FRIENDSINVITE_FAILURE';

/* Cancel Championship Actions */
export const SEND_CANCEL_CHAMPIONSHIP = 'SEND_CANCEL_CHAMPIONSHIP';
export const RECEIVED_CANCEL_CHAMPIONSHIP_SUCCESS = 'RECEIVED_CANCEL_CHAMPIONSHIP_SUCCESS';
export const RECEIVED_CANCEL_CHAMPIONSHIP_FAILURE = 'RECEIVED_CANCEL_CHAMPIONSHIP_FAILURE';

/* Get Invitations Actions */
export const REQUEST_INVITES = 'REQUEST_INVITES';
export const RECEIVED_INVITES_SUCCESS = 'RECEIVED_INVITES_SUCCESS';
export const RECEIVED_INVITES_FAILURE = 'RECEIVED_INVITES_FAILURE';

/* Get Club Results Actions */
export const REQUEST_CLUBRESULTS = 'REQUEST_CLUBRESULTS';
export const RECEIVED_CLUBRESULTS_SUCCESS = 'RECEIVED_CLUBRESULTS_SUCCESS';
export const RECEIVED_CLUBRESULTS_FAILURE = 'RECEIVED_CLUBRESULTS_FAILURE';

/* Get Current Standings Actions */
export const REQUEST_CURRENTSTANDINGS = 'REQUEST_CURRENTSTANDINGS';
export const RECEIVED_CURRENTSTANDINGS_SUCCESS = 'RECEIVED_CURRENTSTANDINGS_SUCCESS';
export const RECEIVED_CURRENTSTANDINGS_FAILURE = 'RECEIVED_CURRENTSTANDINGS_FAILURE';

/* Accept Invite Actions */
export const SEND_ACCEPTINVITE = 'SEND_ACCEPTINVITE';
export const RECEIVED_ACCEPTINVITE_SUCCESS = 'RECEIVED_ACCEPTINVITE_SUCCESS';
export const RECEIVED_ACCEPTINVITE_FAILURE = 'RECEIVED_ACCEPTINVITE_FAILURE';

/* Decline Invite Actions */
export const SEND_DECLINEINVITE = 'SEND_DECLINEINVITE';
export const RECEIVED_DECLINEINVITE_SUCCESS = 'RECEIVED_DECLINEINVITE_SUCCESS';
export const RECEIVED_DECLINEINVITE_FAILURE = 'RECEIVED_DECLINEINVITE_FAILURE';

/* Retailer Actions */
export const REQUEST_GAME_RETAILERS = 'REQUEST_GAME_RETAILERS';
export const RECEIVED_GAME_RETAILERS_SUCCESS = 'RECEIVED_GAME_RETAILERS_SUCCESS';
export const RECEIVED_GAME_RETAILERS_FAILURE = 'RECEIVED_GAME_RETAILERS_FAILURE';

export const REQUEST_CONTENT_RETAILERS = 'REQUEST_CONTENT_RETAILERS';
export const RECEIVED_CONTENT_RETAILERS_SUCCESS = 'RECEIVED_CONTENT_RETAILERS_SUCCESS';
export const RECEIVED_CONTENT_RETAILERS_FAILURE = 'RECEIVED_CONTENT_RETAILERS_FAILURE';

export const REQUEST_TRIAL_RETAILERS = 'REQUEST_TRIAL_RETAILERS';
export const RECEIVED_TRIAL_RETAILERS_SUCCESS = 'RECEIVED_TRIAL_RETAILERS_SUCCESS';
export const RECEIVED_TRIAL_RETAILERS_FAILURE = 'RECEIVED_TRIAL_RETAILERS_FAILURE';

/* Send Edit Championship Actions */
export const SEND_EDITCHAMPIONSHIP = 'SEND_EDITCHAMPIONSHIP';
export const RECEIVED_EDITCHAMPIONSHIP_SUCCESS = 'RECEIVED_EDITCHAMPIONSHIP_SUCCESS';
export const RECEIVED_EDITCHAMPIONSHIP_FAILURE = 'RECEIVED_EDITCHAMPIONSHIP_FAILURE';

/* Get Championship Data Actions */
export const REQUEST_CHAMPIONSHIPDATA = 'REQUEST_CHAMPIONSHIPDATA';
export const RECEIVED_CHAMPIONSHIPDATA_SUCCESS = 'RECEIVED_CHAMPIONSHIPDATA_SUCCESS';
export const RECEIVED_CHAMPIONSHIPDATA_FAILURE = 'RECEIVED_CHAMPIONSHIPDATA_FAILURE';

/* Get Championship End Actions */
export const REQUEST_CHAMPIONSHIPEND = 'REQUEST_CHAMPIONSHIPEND';
export const RECEIVED_CHAMPIONSHIPEND_SUCCESS = 'RECEIVED_CHAMPIONSHIPEND_SUCCESS';
export const RECEIVED_CHAMPIONSHIPEND_FAILURE = 'RECEIVED_CHAMPIONSHIPEND_FAILURE';

/* Get Championship End Actions */
export const SEND_ISNAMEAVAILABLE = 'SEND_ISNAMEAVAILABLE';
export const RECEIVED_ISNAMEAVAILABLE_SUCCESS = 'RECEIVED_ISNAMEAVAILABLE_SUCCESS';
export const RECEIVED_ISNAMEAVAILABLE_FAILURE = 'RECEIVED_ISNAMEAVAILABLE_FAILURE';

/* Get Championship End Actions */
export const SEND_ISDESCRIPTIONALLOWED = 'SEND_ISDESCRIPTIONALLOWED';
export const RECEIVED_ISDESCRIPTIONALLOWED_SUCCESS = 'RECEIVED_ISDESCRIPTIONALLOWED_SUCCESS';
export const RECEIVED_ISDESCRIPTIONALLOWED_FAILURE = 'RECEIVED_ISDESCRIPTIONALLOWED_FAILURE';

/* Cancel Future Championship Actions */
export const SEND_CANCEL_FUTURE_CHAMPIONSHIP = 'SEND_CANCEL_FUTURE_CHAMPIONSHIP';
export const RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_SUCCESS = 'RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_SUCCESS';
export const RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_FAILURE = 'RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_FAILURE';

export const REQUEST_ESPORT_EVENTS = 'REQUEST_ESPORT_EVENTS';
export const RECEIVED_ESPORT_EVENTS_SUCCESS = 'RECEIVED_ESPORT_EVENTS_SUCCESS';
export const RECEIVED_ESPORT_EVENTS_FAILURE = 'RECEIVED_ESPORT_EVENTS_FAILURE';

export const REQUEST_ESPORT_NEWS_FEED = 'REQUEST_ESPORT_NEWS_FEED';
export const RECEIVED_ESPORT_NEWS_FEED_SUCCESS = 'RECEIVED_ESPORT_NEWS_FEED_SUCCESS';
export const RECEIVED_ESPORT_NEWS_FEED_FAILURE = 'RECEIVED_ESPORT_NEWS_FEED_FAILURE';

export const REQUEST_ESPORT_NEWS_POST = 'REQUEST_ESPORT_NEWS_POST';
export const RECEIVED_ESPORT_NEWS_POST_SUCCESS = 'RECEIVED_ESPORT_NEWS_POST_SUCCESS';
export const RECEIVED_ESPORT_NEWS_POST_FAILURE = 'RECEIVED_ESPORT_NEWS_POST_FAILURE';

export const SEND_CUMULATIVE_LEADERBOARD = 'SEND_CUMULATIVE_LEADERBOARD';
export const RECEIVED_CUMULATIVE_LEADERBOARD_SUCCESS = 'RECEIVED_CUMULATIVE_LEADERBOARD_SUCCESS';
export const RECEIVED_CUMULATIVE_LEADERBOARD_FAILURE = 'RECEIVED_CUMULATIVE_LEADERBOARD_FAILURE';