import React from 'react';

import logoImage from '../../assets/images/logo.png';
import logoPlaceholder from '../../assets/images/logo_placeholder.png';
import Img from '../Img/Img';
import MediaQuery from 'react-responsive';
import Heading from '../Heading/Heading';

import './FlatoutLogo.scss';

export default function FlatoutLogo() {
    return (
        <div className="FlatoutLogo">
            <MediaQuery query="(max-width: 767px)">             
                <div className="FlatoutLogo__release">MARCH 24th</div>
                <div className="FlatoutLogo__message">
                    <p className="FlatoutLogo__message--heading">Free for all season owners</p>
                </div>
                <div className="FlatoutLogo__release" style={{color: '#e40e34', fontSize: '1.2rem', fontWeight: 'bold' }}>FLAT OUT PACK</div>
            </MediaQuery>
            <MediaQuery query="(min-width: 768px)">
                <div className="FlatoutLogo__release">MARCH 24th</div>
                <Img image={logoImage} placeholder={logoPlaceholder}  alt="Dirt Rally 2.0 Logo" className="FlatoutLogo__logo" />       
                <div className="FlatoutLogo__release" style={{color: '#e40e34', fontSize: '1.2rem', fontWeight: 'bold' }}>FLAT OUT PACK</div>
                <div className="FlatoutLogo__message">
                    <p className="FlatoutLogo__message--heading">Free for all season owners</p>
                </div>
            </MediaQuery>
        </div>
    )
}
