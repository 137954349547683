import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function postCumulativeLeaderboard() {
    return {
        type: actionTypes.SEND_CUMULATIVE_LEADERBOARD
    };
}

export function postCumulativeLeaderboardSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CUMULATIVE_LEADERBOARD_SUCCESS,
        data
    };
} 

export function postCumulativeLeaderboardFailure(error) {
    return {
        type: actionTypes.RECEIVED_CUMULATIVE_LEADERBOARD_FAILURE,
        data: { error }
    };
}

/***
 * Get the Leaderboard
 */
export function getCumulativeLeaderboard(leaderboardData) {
    return function (dispatch, getState) {
        dispatch(postCumulativeLeaderboard());

        axiosFactory.getApiInstance().post('/api/controller/overall', leaderboardData)
        .then((resp) => {
			dispatch(postCumulativeLeaderboardSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postCumulativeLeaderboardFailure(error));
		});
    };
}