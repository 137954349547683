import React from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';
import { FormGroup, Label, CustomInput } from 'reactstrap';
import MediaQuery from 'react-responsive';

import './LeaderboardToggles.scss';

/**
 * Leaderboard
 */
class LeaderboardToggles extends React.Component {
	
	constructor(props) {
		super(props);

		this.toggleMenu = this.toggleMenu.bind(this);
		this.setDropdownRef = this.setDropdownRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			isOpen: false,
			filters: {
				playerFilter: this.props.filterState.playerFilter,
				filterByAssists: this.props.filterState.filterByAssists,
				filterByWheel: this.props.filterState.filterByWheel,
				platformFilter: this.props.filterState.platformFilter,
			}
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	
	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;

		if(prevState.filters !== newState.filters) {
			this.props.onChange(this.state.filters);
		}
	}

	toggleMenu() {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	handleChange(e) {
		let value = e.target.value;
		let name = e.target.name;

		this.setState( prevState => {
			return {
				filters: {
					...prevState.filters, [name]: value
				}
			}
		});
	}

	setDropdownRef(node) {
		this.dropdownRef = node;
	}

	handleClickOutside(event) {
		if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
			this.setState({
				isOpen: false
			})
		}
	}

	render() {  
		return (
			<form className="LeaderboardToggles form-inline">
					<div className="LeaderboardToggles__options">
						<MediaQuery query="(min-width: 769px)">
							{!this.props.platformOnly &&
								<FormGroup>
									<Label for="leaderboardFilter" className="LeaderboardToggles__label"><Translate id="async.showMe">Show me</Translate>:</Label>
									<div>
										<Translate>{({ translate }) => <CustomInput type="radio" id="filterEveryone" name="playerFilter" label={translate("async.everyone")} value="Everyone" checked={this.state.filters.playerFilter === "Everyone"} onChange={(e) => this.handleChange(e)} inline />}</Translate>
										<Translate>{({ translate }) => <CustomInput type="radio" id="filterFriends" name="playerFilter" label={translate("async.friends")} value="MyFriends" checked={this.state.filters.playerFilter === "MyFriends"} onChange={(e) => this.handleChange(e)} inline />}</Translate>
									</div>
								</FormGroup>
							}

							{this.props.platformOnly &&
								<FormGroup>
									<Label for="leaderboardPlatform" className="LeaderboardToggles__label"><Translate id="async.platform">Platform</Translate></Label>
									<div>
										{!this.props.platformOnly && <Translate>{({ translate }) => <CustomInput type="radio" id="filterPlatformAll" name="platformFilter" label={translate("async.all")} value="None" checked={this.state.filters.platformFilter === "None"} onChange={(e) => this.handleChange(e)} inline />}</Translate>}
										<CustomInput type="radio" id="filterXbox" name="platformFilter" label="Xbox One" value="MicrosoftLive" checked={this.state.filters.platformFilter === "MicrosoftLive"} onChange={(e) => this.handleChange(e)} inline />
										<CustomInput type="radio" id="filterPS" name="platformFilter" label="Playstation&reg;4" value="PlaystationNetwork" checked={this.state.filters.platformFilter === "PlaystationNetwork"} onChange={(e) => this.handleChange(e)} inline />
										<CustomInput type="radio" id="filterSteam" name="platformFilter" label="Steam" value="Steam" checked={this.state.filters.platformFilter === "Steam"} onChange={(e) => this.handleChange(e)} inline />
										{!this.props.platformOnly && <CustomInput type="radio" id="filterOculus" name="platformFilter" label="Oculus" value="Oculus" checked={this.state.filters.platformFilter === "Oculus"} onChange={(e) => this.handleChange(e)} inline />}
									</div>
								</FormGroup>
							}

						</MediaQuery>
					</div>
					<div className={"LeaderboardToggles__MoreOptions " + (this.state.isOpen ? 'is-active' : '')} ref={this.setDropdownRef}>
						{!this.props.platformOnly &&
							<MediaQuery query="(min-width: 769px)">
								<div onClick={() => this.toggleMenu()} style={{cursor: 'pointer'}}>
									<Translate id="async.moreOptions">More Options</Translate> +
								</div>
							</MediaQuery>
						}
						<MediaQuery query="(max-width: 768px)">
							<div onClick={() => this.toggleMenu()} className="LeaderboardToggles__viewButton">
								<Translate id="async.viewFilters">View Filters</Translate> +
							</div>
						</MediaQuery>
						<div className="LeaderboardToggles__Dropdown">
							{!this.props.platformOnly &&
								<React.Fragment>
									<MediaQuery query="(max-width: 768px)">
										<FormGroup>
											<Label for="leaderboardFilter" className="LeaderboardToggles__Dropdown__Label"><Translate id="async.showMe">Show me</Translate>:</Label>
											<div>
												<Translate>{({ translate }) => <CustomInput type="radio" id="filterEveryone" name="playerFilter" label={translate("async.everyone")} value="Everyone" checked={this.state.filters.playerFilter === "Everyone"} onChange={(e) => this.handleChange(e)} />}</Translate>
												<Translate>{({ translate }) => <CustomInput type="radio" id="filterFriends" name="playerFilter" label={translate("async.friends")} value="MyFriends" checked={this.state.filters.playerFilter === "MyFriends"} onChange={(e) => this.handleChange(e)} />}</Translate>
											</div>
										</FormGroup>
									</MediaQuery>
									<FormGroup>
										<Label for="leaderboardControls"className="LeaderboardToggles__Dropdown__Label">Controls</Label>
										<div>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterControlsAll" name="filterByWheel" label={translate("async.all")} value="Unspecified" checked={this.state.filters.filterByWheel === "Unspecified"} onChange={(e) => this.handleChange(e)} />}</Translate>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterController" name="filterByWheel" label={translate("async.controller")} value="Off" checked={this.state.filters.filterByWheel === "Off"} onChange={(e) => this.handleChange(e)} />}</Translate>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterWheel" name="filterByWheel" label={translate("async.steeringWheel")} value="On" checked={this.state.filters.filterByWheel === "On"} onChange={(e) => this.handleChange(e)} />}</Translate>
										</div>
									</FormGroup>
									<FormGroup>
										<Label for="leaderboardControls" className="LeaderboardToggles__Dropdown__Label">Assists</Label>
										<div>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterAssistsAll" name="filterByAssists" label={translate("async.all")} value="Unspecified" checked={this.state.filters.filterByAssists === "Unspecified"} onChange={(e) => this.handleChange(e)} />}</Translate>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterAssistsOn" name="filterByAssists" label={translate("async.on")} value="On" checked={this.state.filters.filterByAssists === "On"} onChange={(e) => this.handleChange(e)} />}</Translate>
											<Translate>{({ translate }) => <CustomInput type="radio" id="filterAssistsOff" name="filterByAssists" label={translate("async.off")} value="Off" checked={this.state.filters.filterByAssists === "Off"} onChange={(e) => this.handleChange(e)} />}</Translate>
										</div>
									</FormGroup>
								</React.Fragment>
							}
							<MediaQuery query="(max-width: 768px)">
								<FormGroup>
									<Label for="leaderboardPlatform" className="LeaderboardToggles__Dropdown__Label"><Translate id="async.platform">Platform</Translate></Label>
									<div>
                                    {!this.props.platformOnly && <Translate>{({ translate }) =>
                                        <CustomInput type="radio" id="filterPlatformAll" name="platformFilter" label={translate("async.all")} value="None" checked={this.state.filters.platformFilter === "None"} onChange={(e) => this.handleChange(e)} />}</Translate>}
										<CustomInput type="radio" id="filterXbox" name="platformFilter" label="Xbox One" value="MicrosoftLive" checked={this.state.filters.platformFilter === "MicrosoftLive"} onChange={(e) => this.handleChange(e)} />
										<CustomInput type="radio" id="filterPS" name="platformFilter" label="Playstation&reg;4" value="PlaystationNetwork" checked={this.state.filters.platformFilter === "PlaystationNetwork"} onChange={(e) => this.handleChange(e)} />
										<CustomInput type="radio" id="filterSteam" name="platformFilter" label="Steam" value="Steam" checked={this.state.filters.platformFilter === "Steam"} onChange={(e) => this.handleChange(e)} />
										{!this.props.platformOnly && <CustomInput type="radio" id="filterOculus" name="platformFilter" label="Oculus" value="Oculus" checked={this.state.filters.platformFilter === "Oculus"} onChange={(e) => this.handleChange(e)} />}
									</div>
								</FormGroup>
							</MediaQuery>
						</div>
					</div>
					
			</form>  
		);
	};
}

LeaderboardToggles.defaultProps = {
	platformOnly: false
};

LeaderboardToggles.propTypes = {
	filterState: PropTypes.object.isRequired,
	platformOnly: PropTypes.bool,
	onChange: PropTypes.func
}

export default LeaderboardToggles;