/**
 * Edit Club
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClub } from '../../actions/Clubs/GetClub';
import { push } from 'connected-react-router';
import { Container, Row, Col } from 'reactstrap';
import ClubOverview from '../../components/ClubOverview/ClubOverview';
import { fetchChampionships } from '../../actions/Clubs/GetChampionships';
import Loader from '../../components/Loader/Loader';
import NoGameDataCheck from '../../containers/NoGameDataCheck';
import DisplayChampionship from '../../components/DisplayChampionship/DisplayChampionship';
import Heading from '../../components/Heading/Heading';
import { Translate } from "react-localize-redux";

import '../../assets/scss/clubs.scss';

/**
 *
 *
 * @class Club
 * @extends {React.Component}
 */
class Club extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            clubId: props.match.params.clubId
        };

		this.goToCreateChampionship = this.goToCreateChampionship.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchClub(this.props.match.params.clubId);
		this.props.fetchChampionships(this.props.match.params.clubId);
	}

	/**
	 *
	 *
	 * @memberof Club
	 */
	goToCreateChampionship() {
		this.props.push('/clubs/create-championship/' + this.state.clubId);
	}

	displayChampionships() {
		if(this.props.championships.isReady()) {
			return (<DisplayChampionship club={this.props.club.value} championships={this.props.championships.value} />);
		} else if(this.props.championships.isFaulted()) {
			if (this.props.championships.value.error.response.status === 403) {
				return (
					<Container>
						<Row>
							<Col xs="12">
								<div className="Club__NoData">
									<Heading rank="3">This is a closed club.</Heading>
									<p>You need to be a member to view this championship.</p>
								</div>
							</Col>
						</Row>
					</Container>
				);
			} else {
				return (<Loader currentState={this.props.championships.state} errorMessage="Failed to load championships" />);
			}
		} else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.championships.state} />);
        }    
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof Club
	 */
	render() {
		if (this.props.club.isReady()) {

			const clubInfo = this.props.club.value;

            return (
                <NoGameDataCheck>
				    <main className="Club" role="main">
					    <Row>
						    <Col xs="12">
							    <ClubOverview club={clubInfo} />
						    </Col>
					    </Row>
					    <div className="Club__Championship">
							<Row>
								<Col xs="12">
									{this.displayChampionships()}
								</Col>
							</Row>
					    </div>
                    </main>
                </NoGameDataCheck>
			);
		} else if (this.props.club.isFaulted()) {
			if (this.props.club.value.error.response.status === 404) {
				return (
					<div className="Club__Error" role="main">
						<Container>
							<Row>
								<Col xs="12">
									<div className="Club__NoData">
										<Heading rank="3"><Translate id="async.noClubExists">Sorry this club does not exist</Translate></Heading>
										<p><Translate id="async.checkBack">Check back again soon</Translate></p>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				)
			} else if (this.props.club.value.error.response.status === 403) {
				return (
					<div className="Club__Error" role="main">
						<Container>
							<Row>
								<Col xs="12">
									<div className="Club__NoData">
										<Heading rank="3">Sorry, this club is closed.</Heading>
										<p>You need an invite to join or view this club.</p>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				)
			} else {
           		return (
				   <NoGameDataCheck>
					    <div className="Club__Championship">
							<Container>
								<Row>
									<Col xs="12">
										<Loader currentState={this.props.club.state} errorMessage="Failed to load club" />
									</Col>
								</Row>
							</Container>
						</div>
					</NoGameDataCheck>
				);
			}
		} else {
            return (
				<NoGameDataCheck>
					 <div className="Club__Championship">
						<Container>
							<Row>
								<Col xs="12">
									<Loader currentState={this.props.club.state} errorMessage="Failed to load club" />
								</Col>
							</Row>
						</Container>
					</div>
				</NoGameDataCheck>
			);
		}    
	};
}

/**
 * Define expected props.
 */
Club.propTypes = {
	club: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	championships: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.array,
		isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired
    }),
	fetchChampionships: PropTypes.func,
	fetchClub: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { club: state.getClub.club, championships: state.getChampionships.championships  } }, 
    dispatch => bindActionCreators({ push, fetchClub, fetchChampionships }, dispatch)
)(Club);