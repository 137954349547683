import React from 'react';
import { Translate } from "react-localize-redux";
import { Container, Row, Col } from 'reactstrap';
import codiesLogo from "../../assets/images/codemasters-logo.png";
import xboxLogo from "../../assets/images/logo_xbox.png";
import ps4Logo from "../../assets/images/logo_ps4.png";
import pcLogo from "../../assets/images/logo_pcdvd.png";
import steamLogo from "../../assets/images/logo_steam.png";
import oculusLogo from "../../assets/images/logo_oculus.png";
import winStoreLogo from "../../assets/images/logo_win10.png";
import xboxPassLogo from "../../assets/images/logo_gamepass.png";
import LanguageSelector from '../LanguageSelector/LanguageSelector';

import './Footer.scss';
import RatingLogo from '../RatingLogo/RatingLogo';

/**
 * Footer
 */
class Footer extends React.Component {
    
    getYearString() {
        //var releaseYear = 2020;
        var now = new Date();
        var currentYear = now.getFullYear();
        //return releaseYear === currentYear ? releaseYear : releaseYear + "-" + currentYear;
        return "©" + currentYear;
    }

    render() { 
        var yearString = this.getYearString();
        
        return (
          <footer className="l-footer">
            <Container>
              <Row>
                <Col xs="12">
                  <Translate>
                    {({ translate }) => (
                      <img
                        src={codiesLogo}
                        alt={translate("general.codemastersLogoAlt")}
                        className="l-footer__logo"
                      />
                    )}
                  </Translate>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <nav>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          href="http://www.codemasters.com/contact-us/"
                          rel="noopener noreferrer"
                        >
                          <Translate id="footer.contactUs">
                            Contact Us
                          </Translate>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.ea.com/terms-of-service."
                          rel="noopener noreferrer"
                        >
                          <Translate id="footer.termsAndConditions">
                            Terms &amp; Conditions
                          </Translate>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.ea.com/en-gb/legal/privacy-policy"
                          rel="noopener noreferrer"
                        >
                          <Translate id="footer.privacyPolicy">
                            Privacy Policy
                          </Translate>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="/credits">Credits</a>
                      </li>
                      <li className="list-inline-item">
                        <div style={{ display: 'inline' }} id="teconsent" />
                      </li>
                    </ul>
                  </nav>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <LanguageSelector languages={this.props.languages} />
                </Col>
              </Row>
            </Container>
            <div className="l-footer-legal">
              <Container>
                <Row>
                  <Col xs="12">
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={xboxLogo}
                          alt={translate("footer.availableOnXbox")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={ps4Logo}
                          alt={translate("footer.availableOnPs4")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={pcLogo}
                          alt={translate("footer.availableOnPc")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={steamLogo}
                          alt={translate("footer.availableOnSteam")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={oculusLogo}
                          alt={translate("footer.availableOnOculus")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={winStoreLogo}
                          alt={translate("footer.availableOnMSStore")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                    <Translate>
                      {({ translate }) => (
                        <img
                          src={xboxPassLogo}
                          alt={translate("footer.availableOnXboxPass")}
                          className="l-footer-legal__logo"
                        />
                      )}
                    </Translate>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="10" className="l-footer-legal__text">
                    <p>
                      <small className="text-left">
                        <Translate
                          id="footer.legal1"
                          data={{ year: yearString }}
                        >
                          2019 The Codemasters Software Company Limited
                          ("Codemasters"). All rights reserved.
                          "Codemasters"&reg;, "EGO"&reg;, the
                          Codemasters logo, and "DiRT"&reg; are
                          registered trademarks owned by Codemasters.
                          "DiRT Rally 2.0"&trade; and "RaceNet"&trade;
                          are trademarks of Codemasters. All rights
                          reserved. Under licence from International
                          Management Group (UK) Limited.All other
                          copyrights or trademarks are the property of
                          their respective owners and are being used
                          under license. Developed and published by
                          Codemasters. Distributed 2019 by Koch Media
                          GmbH, Gewerbegebiet 1, 6604 H�fen, Austria.
                          Unauthorized copying, adaptation, rental,
                          lending, re-sale, arcade use, charging for
                          use, broadcast, cable transmission, public
                          performance, distribution or extraction of
                          this product or any trademark or copyright
                          work that forms part of this product is
                          prohibited.
                        </Translate>
                      </small>
                    </p>
                    <p>
                      <small className="text-left">
                        <Translate id="footer.legal2">
                          Online features may be terminated after 30
                          days notice on www.codemasters.com.
                        </Translate>
                      </small>
                    </p>
                  </Col>
                  <Col xs="12" sm="2">
                    <RatingLogo />
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>
        );
	}
}

export default Footer;