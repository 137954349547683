import React from 'react';
import Img from '../../Img/Img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import './ScreenshotWidget.scss';

export default function ScreenchotWidget(props) {
    return (
        <React.Fragment>
            {props.src != "" && <div className="ScreenshotWidget">
            <a href={props.image.url} target="_blank">
                <Img image={`${props.image.url}?w=1300`} placeholder={`${props.image.url}?w=130`} width="100%" height="auto" alt={props.image.description} />
            </a>
            <div className="ScreenshotWidget__HoverIcon">
                <a href={props.image.src} target="_blank">
                    <FontAwesomeIcon icon="search-plus" color="#FFF" size="6x"/>
                </a>
            </div>
        </div>}
        </React.Fragment>
    )
}

ScreenchotWidget.propTypes = {
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        altText: PropTypes.string
    })
};

ScreenchotWidget.defaultProps  = {
    image: {
        altText: ""
    }
}