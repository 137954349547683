import React, { Component } from 'react';
import Img from '../Img/Img';
import MediaQuery from 'react-responsive';
import FlatoutLogo from '../FlatoutLogo/FlatoutLogo';

import BannerXl from '../../assets/images/goty/header_goty.jpg';
import BannerXl_Ph from '../../assets/images/goty/header_goty_ph.jpg';
import BannerMedium from '../../assets/images/goty/header_goty_1200.jpg';
import BannerMedium_Ph from '../../assets/images/goty/header_goty_1200_ph.jpg';
import BannerSmall from '../../assets/images/goty/header_goty_mob.jpg';
import BannerSmall_Ph from '../../assets/images/goty/header_goty_mob_ph.jpg';
import Logo from '../../assets/images/goty/logo_overlay.png';
import Logo_Ph from '../../assets/images/goty/logo_overlay_ph.png';

import './GotyBanner.scss';

export default class GotyBanner extends Component {
    render() {
        return (
            <div className="GotyBanner">
                 <MediaQuery query="(min-width: 1200px)">
                    <Img image={BannerXl} placeholder={BannerXl_Ph} className="GotyBanner__bg" />
                </MediaQuery>
                <MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
                    <Img image={BannerMedium} placeholder={BannerMedium_Ph} className="GotyBanner__bg--mobile" />
                </MediaQuery>
                <MediaQuery query="(max-width: 767px)">
                    <Img image={BannerSmall} placeholder={BannerSmall_Ph} className="GotyBanner__bg--mobile" />
                </MediaQuery>
                <div className="GotyBanner__container">
                    <div className="GotyBanner__overlay">
                        <Img image={Logo} placeholder={Logo_Ph} />
                    </div>
                </div>         
            </div>
        )
    }
}
