import React from 'react';
import PropTypes from 'prop-types';
import {
	Row,
	Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChallengeSelector from '../ChallengeSelector/ChallengeSelector';
import EventTile from '../EventTile/EventTile';
import { push } from 'connected-react-router';
import DividerButton from '../DividerButton/DividerButton';
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';

import './DisplayChampionship.scss'

/** 
 * Display Championship
 */
class DisplayChampionship extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			activeChampionship: 0,
			championships: []
		}

		this.updateChampionship = this.updateChampionship.bind(this);
		this.getDescription = this.getDescription.bind(this);
		this.goToEditChampionship = this.goToEditChampionship.bind(this);
		this.goToCreateChampionship = this.goToCreateChampionship.bind(this);
	}

	componentDidMount() {
		this.setState({
			championships: this.props.championships,
			activeChampionship: (this.props.championships.findIndex(x => x.isActive === true) !== -1 ? this.props.championships.findIndex(x => x.isActive === true) : this.props.championships.length - 1)
		});
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.championships !== newProps.championships) {
			this.setState({
				championships: this.props.championships,
				activeChampionship: (this.props.championships.findIndex(x => x.isActive === true) !== -1 ? this.props.championships.findIndex(x => x.isActive === true) : this.props.championships.length - 1)
			});
		}
	}

	updateChampionship(championship) {
		this.setState( prevState => {
			return {
				...prevState,
				activeChampionship: championship
			}
		});
	}

	goToEditChampionship() {
		this.props.push('/clubs/edit-championship/' + this.props.club.club.id);
	}

	goToCreateChampionship() {
		this.props.push('/clubs/create-championship/' + this.props.club.club.id);
	}

	getDescription() {
		let newDate = new Date();
		newDate = newDate.toISOString();

		if(
			this.props.championships[this.state.activeChampionship].events[this.props.championships[this.state.activeChampionship].events.length - 1].entryWindow.end > newDate &&
			this.props.championships[this.state.activeChampionship].events[0].entryWindow.start < newDate
		) {
			return "Championship " + (this.state.activeChampionship + 1) +  " - Active";
		} else if(this.props.championships[this.state.activeChampionship].events[0].entryWindow.end < newDate) {
			return "Championship " + (this.state.activeChampionship + 1) +  " - Finished";
		} else {
			return "Championship " + (this.state.activeChampionship + 1) +  " - Upcoming";
		}
	
	}

	getEventStatus(event) {
		let newDate = new Date();
		newDate = newDate.toISOString();

		if(event.entryWindow.start < newDate) {
			return false;
		} else if(event.entryWindow.end > newDate) {
			return true;
		} else {
			return false;
		}
	}

	render() { 
		if(typeof this.props.championships[this.state.activeChampionship] !== 'undefined') {

			const clubInfo = this.props.club;
			const activeChampionship = this.props.championships[this.state.activeChampionship];
			let newDate = new Date().toISOString();

			return (
				<div className="DisplayChampionship">
					<Row>
						<Col xs="12">
							<ChallengeSelector 
								selectedChallenge={this.state.activeChampionship} 
								totalChallenges={this.props.championships.length - 1} 
								onChange={this.updateChampionship} description={this.getDescription()} 
							/>
						</Col>
					</Row>
					<Row>
						{activeChampionship.events.map((event, index) => 
							<Col key={index} xs="12" sm="12" md="6" lg="6" xl="4">
								<EventTile 
									clubId={this.props.club.club.id} 
									championshipId={this.state.activeChampionship} 
									event={event} 
									eventNumber={index} upcoming={this.getEventStatus(event)} />
							</Col>
						)}
					</Row>
					<Row>
						<Col xs="12">
							{this.props.championships[this.state.activeChampionship].events[0].entryWindow.start > newDate && clubInfo.permissions.canEditClubSettings &&
								<DividerButton onClick={this.goToEditChampionship}>Edit Championship</DividerButton>
							}
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<div className="DisplayChampionship__AdvancedSettings">
								<ul>
									<li>
										{this.props.championships[this.state.activeChampionship].allowAssists &&
											<React.Fragment>Assists<br /> Active</React.Fragment>
										}

										{!this.props.championships[this.state.activeChampionship].allowAssists &&
											<React.Fragment>Assists<br /> Disabled</React.Fragment>
										}
									</li>
									<li>
										{this.props.championships[this.state.activeChampionship].forceCockpitCamera &&
											<React.Fragment>Forced Cockpit<br /> Camera Active</React.Fragment>
										}

										{!this.props.championships[this.state.activeChampionship].forceCockpitCamera &&
											<React.Fragment>Forced Cockpit<br /> Camera Disabled</React.Fragment>
										}
									</li>
									<li>
										{this.props.championships[this.state.activeChampionship].useHardcoreDamage &&
											<React.Fragment>Hardcore<br /> Damage Active</React.Fragment>
										}

										{!this.props.championships[this.state.activeChampionship].useHardcoreDamage &&
											<React.Fragment>Hardcore<br /> Damage Disabled</React.Fragment>
										}
									</li>
									<li>
										{this.props.championships[this.state.activeChampionship].useUnexpectedMoments &&
											<React.Fragment>Unexpected<br /> Moments Active</React.Fragment>
										}

										{!this.props.championships[this.state.activeChampionship].useUnexpectedMoments &&
											<React.Fragment>Unexpected<br /> Moments Disabled</React.Fragment>
										}
									</li>
								</ul> 
							</div>
						</Col>
					</Row>
				</div>
			);

		} else {
			const clubInfo = this.props.club;

			return (
				<div className="DisplayChampionship">
					{!clubInfo.club.hasActiveChampionship && !clubInfo.club.hasFutureChampionship && clubInfo.permissions.canCreateChampionship &&
						<div className="Club__NoChampionship">
							<Heading rank="3" className="m-b-10">Looks like you don't have an active championship</Heading>
							<Button onClick={this.goToCreateChampionship}>Create Championship</Button>
						</div>
					}

					{!clubInfo.club.hasActiveChampionship && !clubInfo.club.hasFutureChampionship && !clubInfo.permissions.canCreateChampionship &&
						<div className="Club__NoChampionship">
							<Heading rank="3" className="m-b-10">Sorry, the club owner is yet to set up a championship</Heading>
							<p>Check back again soon</p>
						</div>
					}
				</div>
			);
		}
	};
}

/**
 * Define expected props.
 */
DisplayChampionship.propTypes = {
	push: PropTypes.func.isRequired
};
 
//Connect this container component to the redux store.
export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
)(DisplayChampionship);