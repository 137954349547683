import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavMenu from '../../components/NavMenu/NavMenu';
import * as actions from '../../actions/IdentityActions';
import { bindActionCreators } from 'redux';


class Header extends Component {
    displayName = Header.name
   
    render() 
    { 
        var identity = { store: this.props.identity, actions: this.props.identityActions };
        return (
            <div>
                <NavMenu applicationStore={this.props.application.value} identity={identity} />
            </div>
        );
    }
}

//Connect this container component to the redux store.
export default connect(state => {
    return {
        identity: state.identity,
        application: state.application
    };
}, dispatch => {
    return {
        identityActions: bindActionCreators({ ...actions }, dispatch)
    };
 }, null, { pure: false })(Header);


