import React from 'react';
import {Row, Col} from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMyClubs } from '../../actions/Clubs/MyClubs';
import ClubTile from '../../components/ClubTile/ClubTile';
import { push } from 'connected-react-router';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Loader from '../Loader/Loader';
import Slider from "react-slick";

import './MyClubs.scss';

/** 
 * My Clubs
 */
class MyClubs extends React.Component {

	constructor(props) {
		super(props);

		this.viewMyClubs = this.viewMyClubs.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchMyClubs(/*page*/1, /*pageSize*/6); //todo: use paging.
	}

	viewMyClubs() {
		this.props.push('/clubs/my-clubs');
	}

	render() {

		var sliderSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				  	}
				}
			  ]
		};

        if (this.props.clubs.isReady()) {
			const clubs = this.props.clubs.value.clubs;

            return (
				<div className="MyClubs">
					{clubs.length > 0 &&
						<React.Fragment>
							<Slider {...sliderSettings}>
								{clubs.map((club, index) => 
									<div className="MyClubs__Tile" key={index}><ClubTile key={index} club={club} /></div>
								)}
							</Slider>
							<Row>
								<Col xs="12" className="text-center m-t-50">
									<Button type="standard" size="small" onClick={this.viewMyClubs}>View all</Button>
								</Col>
							</Row>
						</React.Fragment>
					}
					
					{clubs.length < 1 &&
						<Col xs="12">
							<div className="MyClubs__NoResults">
								<Heading rank="3" className="m-b-10">No Clubs Found</Heading>
								<p>You are not a member of any clubs.</p>
							</div>
						</Col>
					}
				</div>
			);
        } else if (this.props.clubs.isFaulted()) {
            return (<Loader currentState={this.props.clubs.state} errorMessage="Failed to load community events" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubs.state} />);
        }    
    }
}

/**
 * Define expected props.
 */
MyClubs.propTypes = {
    clubs: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchMyClubs: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { clubs: state.myClubs.clubs } }, 
    dispatch => bindActionCreators({ fetchMyClubs, push }, dispatch)
)(MyClubs); 