/**
 * Event Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    events: new StatefulValue([]),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_EVENTS:
            return {
                ...state,
                events: new StatefulValue([], states.Loading)
            };

        case actionTypes.RECEIVED_EVENTS_SUCCESS:
            return {
                ...state,
                events: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_EVENTS_FAILURE:
            return {
                ...state,
                events: new StatefulValue([], states.Faulted)
            };
        
        default: return state;
    }
};