import React from 'react';
// import { Translate } from "react-localize-redux";
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import HeroLogo from '../HeroLogo/HeroLogo';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import keyartImage from '../../assets/images/keyart.jpg';
import keyartPlaceholder from '../../assets/images/keyart_placeholder.jpg'; 
import keyartMediumImage from '../../assets/images/header_keyart_1200.jpg';
import keyartImageMobile from '../../assets/images/mob_keyart.jpg';

import './HeroBanner.scss';

library.add(faAngleDown);

class HeroBanner extends React.Component {
  state = {
    actionButtonText: "Pre-order",
  };

  constructor(props) {
    super(props);


    this.getLocalStorage = this.getLocalStorage.bind(this);
    this.checkDate = this.checkDate.bind(this);
    this.goToPreOrder = this.goToPreOrder.bind(this);
    this.goToFindOutMore = this.goToFindOutMore.bind(this);
  }

  getLocalStorage() {
    const item = localStorage.getItem("truste.eu.cookie.notice_gdpr_prefs");

    if (item == null) return undefined;

    return JSON.parse(item);
  }

  checkDate() {
    const today = moment().format("YYYY-MM-DD");
    const releaseDate = "2023-11-03";

    if (moment(today).isBefore(moment(releaseDate))) {
      return;
    } else {
    this.setState({
      actionButtonText: "Buy Now"
    });
    }
  }

  componentDidMount() {
    this.checkDate();
  }

  goToPreOrder() {
    const analyticsUri =
      "https://www.ea.com/games/ea-sports-wrc/wrc/buy?utm_campaign=wrc23_eax_ww_mst_ndig_ap_dr20&utm_source=allplatforms&utm_medium=nondigital&cid=77553&ts=1693500598547";
    const standardUri = "https://www.ea.com/games/ea-sports-wrc/wrc/buy";

    const localStorageItems = this.getLocalStorage();
    const functional = "1";

    if(localStorageItems === undefined) {
       let win = window.open(standardUri, "_blank");

       win.focus();

       return;
    }

    if (localStorageItems && localStorageItems.value.includes(functional)) {
      let win = window.open(analyticsUri, "_blank");

      win.focus();
    } else {
      let win = window.open(standardUri, "_blank");

      win.focus();
    }
  }

  goToFindOutMore() {
    const analyticsUri =
      "https://www.ea.com/games/ea-sports-wrc/wrc?utm_campaign=wrc23_eax_ww_mst_ndig_ap_dr20&utm_source=allplatforms&utm_medium=nondigital&cid=77553&ts=1693500598547";
    const standardUri = "https://www.ea.com/games/ea-sports-wrc/wrc";

    const localStorageItems = this.getLocalStorage();
    const functional = "1";

    
    if (localStorageItems === undefined) {
      let win = window.open(standardUri, "_blank");

      win.focus();

      return;
    }

    if (localStorageItems.value.includes(functional)) {
      let win = window.open(analyticsUri, "_blank");

      win.focus();
    } else {
      let win = window.open(standardUri, "_blank");

      win.focus();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="HeroBanner">
          <MediaQuery query="(min-width: 1200px)">
            <Img
              image={keyartImage}
              placeholder={keyartPlaceholder}
              className="HeroBanner__bg"
            />
          </MediaQuery>
          <MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
            <Img
              image={keyartMediumImage}
              placeholder={keyartPlaceholder}
              className="HeroBanner__bg--mobile"
            />
          </MediaQuery>
          <MediaQuery query="(max-width: 767px)">
            <div className="HeroBannerMobile">
              <div className="HeroBannerMobileLogo">
                <HeroLogo />
              </div>
              <Img
                image={keyartImageMobile}
                placeholder={keyartPlaceholder}
                className="HeroBanner__bg--mobile"
              />
            </div>
          </MediaQuery>
          <div className="HeroBanner__container">
            <div className="HeroBanner__overlay">
              <HeroLogo />
              <div className="HeroBanner__actions">
                {/* OLD BUTTON WITH TRANSLATIONS */}
                {/* <div className="HeroBanner__btn">
                                    <Button type="standard" onClick={this.goToBuyNow} block><Translate id="general.preOrderNow">Buy Now</Translate></Button>
								</div>
								<div className="HeroBanner__btn">
                                    {<Button type="standard" onClick={this.goToTrial} block><Translate id="general.tryForFree">Try For Free</Translate></Button>}
								</div> */}
                {/* NEW BUTTONS W/O TRANSLATIONS */}
                <div className="HeroBanner__btn">
                  <Button
                    type="standard"
                    id="general.preOrderNow"
                    onClick={this.goToPreOrder}
                    block
                  >
                    {this.state.actionButtonText}
                  </Button>
                </div>
                <div className="HeroBanner__btn">
                  {
                    <Button
                      type="standard"
                      id="general.tryForFree"
                      onClick={this.goToFindOutMore}
                      block
                    >
                      Find out more
                    </Button>
                  }
                </div>
              </div>
            </div>
            <div className="HeroBanner__scrollmore">
              <FontAwesomeIcon icon="angle-down" color="#ff003c" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * Define expected props.
 */
HeroBanner.propTypes = {
    application: PropTypes.shape({
        homepageTrailerId: PropTypes.string.isRequired,
    })
};

//Connect this container component to the redux store.
export default connect(
	state => { return { application: state.application.value } }, 
	dispatch => bindActionCreators({push}, dispatch)
)(HeroBanner);