import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestCoverImages() {
    return {
        type: actionTypes.REQUEST_COVERIMAGES
    };
}

export function receivedCoverImagesSuccess(data) {
    return {
        type: actionTypes.RECEIVED_COVERIMAGES_SUCCESS,
        data
    };
}

export function receivedCoverImagesFailure(error) {
    return {
        type: actionTypes.RECEIVED_COVERIMAGES_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the cover images json file
 */
export function fetchCoverImages() {
    return function (dispatch, getState) {
        dispatch(requestCoverImages());

        axiosFactory.getInstance().get(getState().application.value.cdnEndpoint + '/cover_images.json')
            .then((resp) => {
                dispatch(receivedCoverImagesSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedCoverImagesFailure(error));
            });
    };
}