import React, { Component } from 'react';
import Img from '../Img/Img';
import logoImage from '../../assets/images/logo.png';
import logoPlaceholder from '../../assets/images/logo_placeholder.png';

import './TrialLogo.scss';

export default class TrialLogo extends Component {
    render() {
        return (
            <div className="TrialLogo">
				<Img image={logoImage} placeholder={logoPlaceholder}  alt="Dirt Rally 2.0 Logo" className="TrialLogo__logo" />  
				<div className="TrialLogo__release">Trial Version</div>		
			</div>
        )
    }
}
