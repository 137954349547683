import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postIsDescriptionAllowed() {
    return {
        type: actionTypes.SEND_ISDESCRIPTIONALLOWED
    };
}

export function postIsDescriptionAllowedSuccess(data) {
    return {
        type: actionTypes.RECEIVED_ISDESCRIPTIONALLOWED_SUCCESS,
        data
    };
}

export function postIsDescriptionAllowedFailure(error) {
    return {
        type: actionTypes.RECEIVED_ISDESCRIPTIONALLOWED_FAILURE,
        data: { error }
    };
}

/***
 * Post 'is description allowed' check
 */
export function sendIsDescriptonAllowed(data) {
    return function (dispatch) {
        dispatch(postIsDescriptionAllowed());
        
        axiosFactory.getApiInstance().post('/api/Club/IsDescriptionAllowed', data)
        .then((resp) => {
			dispatch(postIsDescriptionAllowedSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postIsDescriptionAllowedFailure(error));
		});
    };
}