import React from 'react';
import PropTypes from 'prop-types';

import './ChampionshipProgress.scss'

/** 
 * Championship Progress
 */
class ChampionshipProgress extends React.Component {
	
	render() { 
		const championship = this.props.championship;
		let events = [];
		
		for (let i = 1; i <= championship.eventCount; i++) {
			events.push(
				<li key={i} className={(i <= championship.finishedCount ? "is-completed" : "") + (i === (championship.finishedCount + 1) ? " in-progress" : "")}><span className="bar"></span></li>
			);
		}

		return (
			<div className="ChampionshipProgress">

				{championship.completedCount < championship.eventCount &&
					<div className="ChampionshipProgress__Title">CHAMPIONSHIP IN PROGRESS: Event {championship.finishedCount + 1} of {championship.eventCount}</div>
				}

				{championship.completedCount === championship.eventCount &&
					<div className="ChampionshipProgress__Title">Championship Finished</div>
				}

				<div className="ChampionshipProgress__ProgressBar">
					<ul>
						{events}
					</ul>
				</div>
			</div>
		);
	};
}

ChampionshipProgress.defaultProps = {
	championship: {
		"eventCount": 8,
		"completedCount": 3
	}
  };

/**
 * Define expected props.
 */
ChampionshipProgress.propTypes = {
	championship: PropTypes.object
};

export default ChampionshipProgress;