/**
 * Error Reducers
 */
import * as actionTypes from '../actions/ActionTypes';

/**
 * initial state
 */
const INIT_STATE = {
    error: null,
    errorInfo: [] 
};

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.SET_ERROR_STATE:
            return {
                ...action.data
            };

        case "@@router/LOCATION_CHANGE":
            //reset error status on navigation change.
            return INIT_STATE;

        default: return state;
    }
};