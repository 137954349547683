/**
 * Application Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';

/**
 * initial state
 */
const INIT_STATE = new StatefulValue({
    environment: "",
    release: "",
    sentryDsn: "",
    cdnEndpoint: "",
    homepageTrailerId: "",
    useAuthentication: false,
    antiForgeryHeaderName: "",
    ratingBoard: null,
    localizeConfig: null,
    languages: []
});

export default function (state = INIT_STATE, action) {
    switch (action.type) {
               
        case actionTypes.REQUEST_CLIENT_STORE_INITIAL_STATE:
            return new StatefulValue({
                ...state.value
            }, states.Loading);

        case actionTypes.RECEIVED_CLIENT_STORE_INITIAL_STATE_SUCCESS:
            return new StatefulValue({
                ...state.value,
                ...action.data.application
            }, states.LoadSuccessful);

        case actionTypes.RECEIVED_CLIENT_STORE_INITIAL_STATE_FAILED:
            return new StatefulValue({
                ...state.value
            }, states.Faulted);

        case actionTypes.APPLICATION_READY:
            return new StatefulValue({
                ...state.value
            }, states.Ready);
        // case "@@router/LOCATION_CHANGE":
        //     var page = action.payload.location.pathname;
        //     if (page) {
        //         ReactGA.pageview(page);
        //         ReactPixel.pageView();
        //     }
        //     return state;

        default: return state;
    }
};