export const decodeHTML = (html) => {
	var text = document.createElement('textarea');
	text.innerHTML = html;
	return text.value;
};

export const decodeUnderligne = (text) => {
    let split = text.split("__");
    let count = split.length;
    if(split.length > 1) {
        let decoded = '';
        for(let i = 0; i < count; i++)
        {           
            if(count - 1 !== i){
                decoded += i%2 == 0 ? `${split[i]}<u>` : `${split[i]}</u>`;
            } else {
                decoded += split[i];
            }
            
            
        }
        return decoded;
    }
    return text;
}

export default { decodeHTML, decodeUnderligne }