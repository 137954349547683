import React from 'react';
import {FormGroup, Input, Row, Col} from 'reactstrap';
import Heading from '../Heading/Heading';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMembers } from '../../actions/Clubs/GetMembers';
import { fetchMemberRequests } from '../../actions/Clubs/GetRequests';
import ClubMember from '../../components/ClubMember/ClubMember';
import Loader from '../Loader/Loader';
import InviteMembers from '../../components/InviteMembers/InviteMembers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination/Pagination';
import debounce from 'lodash/debounce';

import './ClubMembers.scss'

library.add(faSearch);

/** 
 * Club Members
 */
class ClubMembers extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			nameFilter: "",
			page: 1,
			pageSize: 25
		}

		this.emitChangeDebounced = debounce(this.handleSearch, 500);
		this.updatePage = this.updatePage.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchMembers(this.props.club.club.id, this.state);
		this.props.fetchMemberRequests(this.props.club.club.id);
	}
	
	componentWillUnmount() {
		this.emitChangeDebounced.cancel()
	}

	handleChange = e => {   
		e.persist()
		this.emitChangeDebounced(e.target.value);
	}

	handleSearch = value => {
		this.setState( prevState => {
			return { 
				...prevState,
				nameFilter: value
			}
		}, () => {
			this.props.fetchMembers(this.props.club.club.id, this.state);
		})
	}

	updatePage(page) {
		this.setState( prevState => {
			return {
				...prevState,
				page: page
			}
		}, () => {
			this.props.fetchMembers(this.props.club.club.id, this.state);
		});
	}

	displayMemberRequests() {
		if (this.props.memberRequests.isReady()) {
			const requests = this.props.memberRequests.value.pendingRequests;

			return (
				<React.Fragment>
					{requests.length > 0 &&
						<React.Fragment>
							<Row>
								<Col xs="12">
									<div className="ClubMembers__Heading">
										<Heading rank="3">Pending Requests</Heading>
									</div>
								</Col>
							</Row>
							<Row className="m-b-25">
								{requests.map((member, index) => 
									<Col key={index} xs="12" sm="12" md="6" lg="4" xl="3">
										<ClubMember club={this.props.club} member={member} />
									</Col>
								)}
							</Row>
						</React.Fragment>
					}
				</React.Fragment>
			);
		} else {
			return null
		}
	}

	displayMembers() {
		if (this.props.members.isReady()) {
			const owners = (this.props.members.value.members.Owner ? this.props.members.value.members.Owner : []);
			const administrators = (this.props.members.value.members.Administrator ? this.props.members.value.members.Administrator : []);
			const moderators = (this.props.members.value.members.Moderator ? this.props.members.value.members.Moderator : []);
			const players = (this.props.members.value.members.Player ? this.props.members.value.members.Player : []);

			return (
				<React.Fragment>
					{owners.length > 0 &&
						<React.Fragment>
							<Row>
								<Col xs="12">
									<div className="ClubMembers__Heading">
										<Heading rank="3">Owner{owners.length > 1 && <React.Fragment>s</React.Fragment>}</Heading>
									</div>
								</Col>
							</Row>
							<Row className="m-b-25">
								{owners.map((owner, index) => 
									<Col key={index} xs="12" sm="12" md="6" lg="4" xl="3">
										<ClubMember club={this.props.club} member={owner} key={index} />
									</Col>
								)}
							</Row>
						</React.Fragment>
					}

					{administrators.length > 0 &&
						<React.Fragment>
							<Row>
								<Col xs="12">
									<div className="ClubMembers__Heading">
										<Heading rank="3">Administrator{administrators.length > 1 && <React.Fragment>s</React.Fragment>}</Heading>
									</div>
								</Col>
							</Row>
							<Row className="m-b-25">
								{administrators.map((administrator, index) => 
									<Col key={index} xs="12" sm="12" md="6" lg="4" xl="3">
										<ClubMember club={this.props.club} member={administrator} key={index} />
									</Col>
								)}
							</Row>
						</React.Fragment>
					}

					{moderators.length > 0 &&
						<React.Fragment>
							<Row>
								<Col xs="12">
									<div className="ClubMembers__Heading">
										<Heading rank="3">Moderator{moderators.length > 1 && <React.Fragment>s</React.Fragment>}</Heading>
									</div>
								</Col>
							</Row>
							<Row className="m-b-25">
								{moderators.map((moderator, index) => 
									<Col key={index} xs="12" sm="12" md="6" lg="4" xl="3">
										<ClubMember club={this.props.club} member={moderator} key={index} />
									</Col>
								)}
							</Row>
						</React.Fragment>
					}

					{players.length > 0 &&
						<React.Fragment>
							<Row>
								<Col xs="12">
									<div className="ClubMembers__Heading">
										<Heading rank="3">Players</Heading>
									</div>
								</Col>
							</Row>
							<Row className="m-b-25">
								{players.map((player, index) => 
									<Col key={index} xs="12" sm="12" md="6" lg="4" xl="3">
										<ClubMember club={this.props.club} member={player} key={index} />
									</Col>
								)}
							</Row>
						</React.Fragment>
					}

					{players.length < 1 && moderators.length < 1 && administrators.length < 1 && owners.length < 1 &&
						<div className="ClubMembers__NoResults">
							<Heading rank="3" className="m-b-10">No Members Found</Heading>
							<p>Please adjust your search terms.</p>
						</div>
					}

				{this.props.members.value.pageCount > 1 &&
					<Row>
						<Col xs="12">
							<div className="ClubMembers__Pagination">
								<Pagination activePage={this.state.page} pageSize={this.props.members.value.pageCount} onChange={this.updatePage} />
							</div>
						</Col>
					</Row>
				}

				</React.Fragment>
			);
		} else if (this.props.members.isFaulted()) {
			return (<Loader currentState={this.props.members.state} errorMessage="Failed to load community events" />);
		} else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.members.state} />);
		}    
	}

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col xs="12" className="text-center">
					
						<div className="ClubMembers__MemberFunctions">
							<div className="ClubMembers__Search">
								<FormGroup>
									<div style={{position: 'relative'}}>
										<Input
											type="search"
											name="memberSearch"
											id="memberSearch"
											placeholder="Find Members"
											onChange={this.handleChange}
										/>
										<FontAwesomeIcon icon="search" color="#FFF" />
									</div>
								</FormGroup>
							</div>

							{this.props.club.role === "Administrator" &&
								<div className="ClubMembers__Invite">
									<InviteMembers clubId={parseInt(this.props.club.club.id)} />
								</div>
							}

							{this.props.club.role === "Owner" &&
								<div className="ClubMembers__Invite">
									<InviteMembers clubId={parseInt(this.props.club.club.id)} />
								</div>
							}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						{this.displayMemberRequests()}
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						{this.displayMembers()}
					</Col>
				</Row>
			</React.Fragment>
		)
    }
}

/**
 * Define expected props.
 */
ClubMembers.propTypes = {
	club: PropTypes.object.isRequired,
    members: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	memberRequests: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchMembers: PropTypes.func.isRequired,
	fetchMemberRequests: PropTypes.func.isRequired,
};

//Connect this container component to the redux store.
export default connect(
    state => { return { members: state.clubMembers.members, memberRequests: state.getMemberRequests.members } }, 
    dispatch => bindActionCreators({ fetchMembers, fetchMemberRequests }, dispatch)
)(ClubMembers); 