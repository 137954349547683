/**
 * News Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    latest: new StatefulValue([]),
    posts: {}
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_NEWS_FEED:
            return {
                ...state,
                latest: new StatefulValue([], states.Loading)
            };

        case actionTypes.RECEIVED_NEWS_FEED_SUCCESS:
            return {
                ...state,
                latest: new StatefulValue(action.data || [], states.Ready)
            };

        case actionTypes.RECEIVED_NEWS_FEED_FAILURE:
            return {
                ...state,
                latest: new StatefulValue([], states.Faulted)
            };

        case actionTypes.REQUEST_NEWS_POST:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [action.data.id]: new StatefulValue(null, states.Loading)
                } 
            };

        case actionTypes.RECEIVED_NEWS_POST_SUCCESS:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [action.data.id]: new StatefulValue(action.data.post, states.Ready)
                }
            };

        case actionTypes.RECEIVED_NEWS_POST_FAILURE:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [action.data.id]: new StatefulValue(null, states.Faulted)
                }
            };
        
        default: return state;
    }
};