/**
 * App Reducers
 */
import { combineReducers } from 'redux';

import IdentityReducers from '../reducers/IdentityReducers';
import ApplicationReducers from '../reducers/ApplicationReducers';
import ErrorReducers from '../reducers/ErrorReducers';
import TwitterReducers from './TwitterReducers';
import YouTubeReducers from './YouTubeReducers';
import NewsReducers from './NewsReducers';
import MediaReducers from './MediaReducers';
import RetailersReducers from './RetailersReducers';
import CuratorReducers from './CuratorReducers';
import EventReducers from './EventReducers';
import LeaderboardReducers from './LeaderboardReducers';
import { localizeReducer } from "react-localize-redux";

/* Clubs */
import CoverImageReducers from './Clubs/CoverImages';
import CreateClubReducers from './Clubs/CreateClub';
import CreateChampionshipReducers from './Clubs/CreateChampionship';
import MyClubReducers from './Clubs/MyClub';
import RecommendedClubReducers from './Clubs/RecommendedClub';
import GetClubReducers from './Clubs/GetClub';
import GetClubSettingsReducers from './Clubs/GetClubSettings';
import EditClubSettingsReducers from './Clubs/EditClubSettings';
import GenerateChampionshipReducers from './Clubs/GenerateChampionship';
import ChampionshipOptionsReducers from './Clubs/ChampionshipOptions';
import GenerateStagesReducers from './Clubs/GenerateStages';
import DisbandClubReducers from './Clubs/DisbandClub';
import ClubSearchReducers from './Clubs/ClubSearch';
import GetMembersReducers from './Clubs/GetMembers';
import PromoteMemberReducers from './Clubs/PromoteMember';
import DemoteMemberReducers from './Clubs/DemoteMember';
import KickMemberReducers from './Clubs/KickMember';
import LeaveClubReducers from './Clubs/LeaveClub';
import JoinClubReducers from './Clubs/JoinClub';
import GetChampionshipsReducers from './Clubs/GetChampionships';
import GetRequestsReducers from './Clubs/GetRequests';
import ApproveMemberReducers from './Clubs/ApproveMember';
import DeclineMemberReducers from './Clubs/DeclineMember';
import GetFriendsReducers from './Clubs/GetFriends';
import InviteFriendsReducers from './Clubs/InviteFriends';
import CancelChampionshipReducers from './Clubs/CancelChampionship';
import GetInvitesReducers from './Clubs/GetInvites';
import GetClubResults from './Clubs/ClubResults';
import GetStandings from './Clubs/GetStandings';
import AcceptInvite from './Clubs/AcceptInvite';
import DeclineInvite from './Clubs/DeclineInvite';
import EditChampionship from './Clubs/EditChampionship';
import GetChampionshipData from './Clubs/GetChampionshipData';
import GetChampionshipEnd from './Clubs/GetChampionshipEnd';
import IsNameAvailable from './Clubs/IsNameAvailable';
import IsDescriptionAllowed from './Clubs/IsDescriptionAllowed';
import cancelFutureChampionship from './Clubs/CancelFutureChampionship';
import EsportReducers from './EsportReducers';
import EsportNewsReducers from './EsportNewsReducers';
import CumulativeLeaderboardReducers from './CumulativeLeaderboardReducers';


const reducers = combineReducers({
    localize: localizeReducer,
    /*Add reducers here...*/
    application: ApplicationReducers,
    identity: IdentityReducers,
    applicationError: ErrorReducers,
    twitter: TwitterReducers,
	youtube: YouTubeReducers,
	news: NewsReducers,
	media: MediaReducers,
	retailers: RetailersReducers,
	curator: CuratorReducers,
	coverImages: CoverImageReducers,
	createClub: CreateClubReducers,
	createChampionship: CreateChampionshipReducers,
	challenges: EventReducers,
	challenge: LeaderboardReducers,
	myClubs: MyClubReducers,
	getClub: GetClubReducers,
	recommendedClubs: RecommendedClubReducers,
	championship: GenerateChampionshipReducers,
	championshipStages: GenerateStagesReducers,
	championshipOptions: ChampionshipOptionsReducers,
	getClubSettings: GetClubSettingsReducers,
	editClubSettings: EditClubSettingsReducers,
	disbandClub: DisbandClubReducers,
	clubSearch: ClubSearchReducers,
	clubMembers: GetMembersReducers,
	promoteMember: PromoteMemberReducers,
	demoteMember: DemoteMemberReducers,
	kickMember: KickMemberReducers,
	leaveClub: LeaveClubReducers,
	joinClub: JoinClubReducers,
	getChampionships: GetChampionshipsReducers,
	getMemberRequests: GetRequestsReducers,
	approveMember: ApproveMemberReducers,
	declineMember: DeclineMemberReducers,
	getFriends: GetFriendsReducers,
	inviteFriends: InviteFriendsReducers,
	cancelChampionship: CancelChampionshipReducers,
	getInvites: GetInvitesReducers,
	clubResults: GetClubResults,
	getCurrentStandings: GetStandings,
	acceptInvite: AcceptInvite,
	declineInvite: DeclineInvite,
	editChampionship: EditChampionship,
	getChampionshipData: GetChampionshipData,
	getChampionshipEnd: GetChampionshipEnd,
	isNameAvailable: IsNameAvailable,
	isDescriptionAllowed: IsDescriptionAllowed,
	cancelFutureChampionship: cancelFutureChampionship,	
	esports: EsportReducers,
	esportNews: EsportNewsReducers,
	cumulativeLeaderboard: CumulativeLeaderboardReducers
});

export default reducers;