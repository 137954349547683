/**
 * Buy Page
 */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import BuyNowForm from '../../components/BuyNowForm/BuyNowForm';
import * as actions from '../../actions/RetailersActions';
import { Row, Col } from 'reactstrap';


import '../../assets/scss/buy.scss';

class Buy extends Component {
    displayName = Buy.name

    componentDidMount() {
        this.props.fetchGameRetailers();
        this.props.fetchContentRetailers();
    }

    render() {
        return (
            <main className="Buy" role="main">
                <Helmet>
                    <title>Buy Now - DiRT Rally 2.0</title>
                    <meta name="description" content="Buy now DiRT Rally 2.0 Standard, Deluxe and Super Deluxe EdItions, available on PS4, Xbox One, PC and VR on Oculus" />
                    <link rel="canonical" href="http://www.dirtrally2.com/buy" />
                    <meta property="og:description" content="Buy now DiRT Rally 2.0 Standard, Deluxe and Super Deluxe EdItions, available on PS4, Xbox One, PC and VR on Oculus" />
                    <meta name="twitter:description" content="Buy now DiRT Rally 2.0 Standard, Deluxe and Super Deluxe EdItions, available on PS4, Xbox One, PC and VR on Oculus" />
                </Helmet>
                <div className="BuyGame">
                    <Row className="no-gutters">
                        <Col lg={{ size: 6, offset: 6 }}>
                            <BuyNowForm align="right" state={this.props.gameRetailers.state} content={this.props.gameRetailers.value} />
                        </Col>
                    </Row>
                </div>
                <div className="BuyContent">
                    <Row className="no-gutters">
                        <Col lg="6">
                            <BuyNowForm align="left" state={this.props.contentRetailers.state} content={this.props.contentRetailers.value} />
                        </Col>
                    </Row>
                </div>
            </main>
        );
    }
}

export default connect(
    state => { return { gameRetailers: state.retailers.gameRetailers, contentRetailers: state.retailers.contentRetailers }; },
    dispatch => bindActionCreators({ ...actions }, dispatch)
)(Buy);