import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';
import Icon from '../../components/Icon/Icon';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';

import './LoginStatus.scss';

library.add(faSortDown);

export default class LoginStatus extends Component {
    
    static defaultProps = {
        useAuthentication: false,
        identity: {
            store: {
                isAuthenticated: false,
                displayName: "",
                platforms: []
            }
        }
    };

    static propTypes = {
        /* Required information / actions for the current users identity */
        identity: PropTypes.shape({
            store: PropTypes.shape({
                isAuthenticated: PropTypes.bool.isRequired,
                displayName: PropTypes.string.isRequired,
                platforms: PropTypes.array.isRequired
            }),
            actions: PropTypes.shape({
                fetchUsersIdentity: PropTypes.func.isRequired,
                updateUsersPlatform: PropTypes.func.isRequired
            })
        }),
        /* Indicates if the application is using authentication. Component only renders if true. */
        useAuthentication: PropTypes.bool.isRequired
    };

	constructor(props) {
		super(props);

        this.state = {
            isOpen: false
        };

		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
       
	componentWillMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	
	toggleMenu() {
        this.setState({
            isOpen: !this.state.isOpen
        });
	}
	
    selectPlatform(platform) {
        this.props.identity.actions.updateUsersPlatform(platform);
	}

    renderPlatforms(platforms) {
        var self = this;
        var platformsMarkup = platforms.map(function (platform) {
            if (platform.isAvailable) {
                if (platform.isSelected) {
                    return (<li key={platform.id} className="is-active">{platform.name}</li>);
                } else {
                    return (<li key={platform.id} onClick={self.selectPlatform.bind(self, platform.id)}>{platform.name}</li>);
                }
            } else {
                return null;
            }

        });

        return (
            <ul className="LoginStatus__platforms">{platformsMarkup}</ul>
        );
    }

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(e) {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				isOpen: false
			});
		}
	}

    /**
     * Render the component.
     */
    render() {
        if (!this.props.useAuthentication) {
            return null;
        }

        if (this.props.identity.store.isAuthenticated) {
            return (
				
				<div 
					className={"navbar__loginStatus LoginStatus " + (this.state.isOpen ? 'is-active' : '')} 
					ref={this.setWrapperRef}
				>
					<div onClick={() => this.toggleMenu()} style={{cursor: 'pointer'}}>
						<div className="LoginStatus__avatar">
							<Icon name="icon-avatar" size="42" />
						</div>
					</div>
						<MediaQuery query="(min-width: 769px)">
							<div className="LoginStatus__dropdown">
								<ul>
									<li><a target="_blank" href="/account/manage"><Translate id="loginStatus.manageAccount">Manage Account</Translate></a></li>
									<li><a href="/account/LogoutOAuth"><Translate id="loginStatus.logout">Logout</Translate></a></li>
								</ul>
								<div className="LoginStatus__divider"></div>
								{this.renderPlatforms(this.props.identity.store.platforms)}
							</div>
						</MediaQuery>

						<MediaQuery query="(max-width: 768px)">
							<div className="LoginStatus__dropdown">
								<div className="LoginStatus__leadPlatform">Lead Platform</div>
								{this.renderPlatforms(this.props.identity.store.platforms)}
								<div className="LoginStatus__account">
									<ul>
										<li><a target="_blank" href="/account/manage"><Translate id="loginStatus.manageAccount">Manage Account</Translate></a></li>
										<li><a href="/account/LogoutOAuth"><Translate id="loginStatus.logout">Logout</Translate></a></li>
									</ul>
								</div>
							</div>
						</MediaQuery>
				</div>
				
            );
        } else {
            const returnUri = window.location.pathname;
            const loginUri = '/account/login?returnUri=' + encodeURIComponent(returnUri);

            return (
                <div className="navbar__loginStatus logged-out">
                    <a href={loginUri}>
                        <Translate id="loginStatus.login">Login</Translate>&nbsp;
                        <span><Translate id="loginStatus.or">or</Translate></span>&nbsp;
                        <Translate id="loginStatus.signUp">Sign Up</Translate></a>
				</div>
            );
        }
    }
}


