import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postEditChampionship() {
    return {
        type: actionTypes.SEND_EDITCHAMPIONSHIP
    };
}

export function postEditChampionshipSuccess(data) {
    return {
        type: actionTypes.RECEIVED_EDITCHAMPIONSHIP_SUCCESS,
        data
    };
}

export function postEditChampionshipFailure(error) {
    return {
        type: actionTypes.RECEIVED_EDITCHAMPIONSHIP_FAILURE,
        data: { error }
    };
}

/***
 * Post Edit Championship Form
 */
export function sendEditChampionshipForm(championshipData, clubId) {
    return function (dispatch, getState) {
        dispatch(postEditChampionship());

        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/championship', championshipData)
        .then((resp) => {
			dispatch(postEditChampionshipSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postEditChampionshipFailure(error));
		});
    };
}