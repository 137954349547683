import React from 'react';
import PropTypes from 'prop-types';

import './Tabs.scss'

/**
 * Tabs
 */
class Tabs extends React.Component {

	constructor(props) {
		super(props);

		this.setActiveTab = this.setActiveTab.bind(this);

		this.state = {
			activeTab: 0
		}
	}

	setActiveTab(index) {
		this.setState({
			activeTab: index
		});
	};

	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevState.activeTab !== newState.activeTab) {
			this.props.onChange(this.state.activeTab);
		}

		if(prevProps.tabs !== newProps.tabs) {
			this.setState({
				activeTab: 0
			})
		}
	}

	render() { 

		const tabs = this.props.tabs;

		return (
			<div className="Tabs">
				<ul>
					{tabs.slice(0).map((tab, index) => 
						<li key={index} onClick={() => this.setActiveTab(index)} className={this.state.activeTab === index ? 'is-active' : ''}>
							{tab.name} 
						</li>
					)}
				</ul>
			</div>	
		);
	};
}

Tabs.propTypes = {
	/** Specify tabs to output */
	tabs: PropTypes.array.isRequired,
	onChange: PropTypes.func
}
 
export default Tabs;