import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './DividerButton.scss';

/**
 * Divider Button
 */
class DividerButton extends React.Component {
	
	render() {  

		return (
			<div className="dividerButton">
				<Button buttonType={this.props.buttonType} size="small" onClick={this.props.onClick} className={this.props.className}>{this.props.children}</Button>
			</div>
		);
	};
}

DividerButton.propTypes = {
	onClick: PropTypes.func,
	buttonType: PropTypes.oneOf(['button', 'submit']),
	className: PropTypes.string
}

DividerButton.defaultProps = {
	onClick: event => {
		// eslint-disable-next-line no-console
		console.log('You have clicked me!', event.target); 
	},
	buttonType: 'button',
	className: ""
}

export default DividerButton;