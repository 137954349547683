import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersIdentity } from '../actions/IdentityActions';

class PageLoad extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            //Causing page reload in IE11
            //this.props.fetchUsersIdentity();
        }
    }

    render() {
        return this.props.children;
    }
}

PageLoad.propTypes = {
	fetchUsersIdentity: PropTypes.func,
};

export default withRouter(connect(
    state => { return { championship: state.championship.settings} },   
    dispatch => bindActionCreators({ fetchUsersIdentity }, dispatch)
)(PageLoad));