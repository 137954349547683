import React from 'react'
import PropTypes from 'prop-types';
import './Icon.scss'

class Icon extends React.Component {

	_mergeStyles(...args) {
		return Object.assign({}, ...args);
	}

	renderGraphic() {
		switch (this.props.name) {
			case 'icon-blog_circle':
				return (
					<g><path fill="currentColor" d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16 16-7.169 16-16-7.169-16-16-16zM24.615 24.554c0 1.046-0.862 1.908-1.908 1.908h-14.062c-1.046 0-1.908-0.862-1.908-1.908v-16.462c0-1.046 0.862-1.908 1.908-1.908h14.062c1.046 0 1.908 0.862 1.908 1.908 0 0.215-0.092 0.4-0.215 0.554l-1.508 1.508c-0.308 0.308-0.831 0.092-0.831-0.338v-1.077h-12.769v15.169h12.769v-4.062c0-0.123 0.062-0.246 0.154-0.338l1.6-1.6c0.308-0.308 0.831-0.092 0.831 0.338v6.308zM15.385 20.954h-3.538c-0.185 0-0.369-0.154-0.369-0.369v-0.892c0-0.185 0.154-0.369 0.369-0.369h3.6l-0.092 0.954c-0.062 0.246-0.031 0.462 0.031 0.677zM11.846 18.092c-0.185 0-0.369-0.154-0.369-0.369v-0.892c0-0.185 0.154-0.369 0.369-0.369h4.708l-0.923 0.923-0.031 0.4-0.031 0.308h-3.723zM11.846 15.2c-0.185 0-0.369-0.154-0.369-0.369v-0.892c0-0.185 0.154-0.369 0.369-0.369h7.6l-1.631 1.631h-5.969zM11.846 12.338c-0.185 0-0.369-0.154-0.369-0.369v-0.892c0-0.185 0.154-0.369 0.369-0.369h7.815c0.185 0 0.369 0.154 0.369 0.369v0.831c0 0.246-0.185 0.431-0.431 0.431h-7.754zM25.415 14.8l-5.908 5.908-2.523 0.277c-0.338 0.031-0.615-0.246-0.585-0.585l0.277-2.523 5.908-5.908c0.092-0.092 0.277-0.092 0.369 0l2.431 2.431c0.123 0.123 0.123 0.308 0.031 0.4zM26.4 13.815c-0.092 0.092-0.277 0.092-0.369 0l-2.462-2.431c-0.092-0.092-0.092-0.277 0-0.369l0.954-0.954c0.4-0.4 1.077-0.4 1.508 0l1.323 1.323c0.4 0.4 0.4 1.077 0 1.508l-0.954 0.923z"></path></g>
				);
			case 'icon-cog':
				return (
					<g><path fill="currentColor" d="M28.169 18.254l2.749 1.577c0.316 0.18 0.451 0.541 0.361 0.901-0.721 2.299-1.938 4.372-3.515 6.084-0.225 0.27-0.631 0.316-0.946 0.135l-2.749-1.577c-1.172 0.992-2.479 1.758-3.921 2.254v3.155c0 0.361-0.27 0.676-0.586 0.766-2.254 0.496-4.687 0.541-7.031 0-0.361-0.090-0.586-0.406-0.586-0.766v-3.155c-1.442-0.496-2.749-1.262-3.921-2.254l-2.839 1.623c-0.316 0.18-0.721 0.135-0.946-0.135-1.577-1.713-2.794-3.786-3.515-6.084-0.090-0.361 0.045-0.721 0.361-0.901l2.749-1.623c-0.27-1.487-0.27-3.020 0-4.552l-2.749-1.532c-0.316-0.18-0.451-0.541-0.361-0.901 0.721-2.299 1.938-4.372 3.515-6.085 0.225-0.27 0.631-0.316 0.946-0.135l2.749 1.577c1.172-0.992 2.479-1.758 3.921-2.254v-3.155c0-0.361 0.27-0.676 0.586-0.766 2.254-0.496 4.687-0.541 7.031 0 0.361 0.090 0.586 0.406 0.586 0.766v3.155c1.442 0.496 2.749 1.262 3.921 2.254l2.749-1.577c0.316-0.18 0.721-0.135 0.946 0.135 1.577 1.713 2.794 3.786 3.515 6.085 0.090 0.361-0.045 0.721-0.361 0.901l-2.659 1.577c0.27 1.487 0.27 3.020 0 4.507v0zM21.183 16c0-2.839-2.299-5.183-5.183-5.183s-5.183 2.299-5.183 5.183 2.344 5.183 5.183 5.183 5.183-2.344 5.183-5.183z"></path></g>
				);
			case 'icon-download':
				return (
					<g><path fill="currentColor" d="M13.476 0h5.001c0.834 0 1.482 0.648 1.482 1.482v10.512h5.511c1.111 0 1.667 1.343 0.88 2.13l-9.493 9.493c-0.463 0.463-1.25 0.463-1.713 0l-9.493-9.493c-0.787-0.787-0.232-2.13 0.88-2.13h5.465v-10.512c0-0.834 0.648-1.482 1.482-1.482zM32 23.479v6.993c0 0.834-0.648 1.482-1.482 1.482h-29.036c-0.834 0-1.482-0.648-1.482-1.482v-6.993c0-0.834 0.648-1.482 1.482-1.482h9.169l3.056 3.056c1.25 1.25 3.288 1.25 4.538 0l3.056-3.056h9.169c0.834 0 1.528 0.648 1.528 1.482zM24.22 28.99c0-0.695-0.556-1.25-1.25-1.25s-1.25 0.556-1.25 1.25 0.556 1.25 1.25 1.25 1.25-0.556 1.25-1.25zM28.249 28.99c0-0.695-0.556-1.25-1.25-1.25s-1.25 0.556-1.25 1.25 0.556 1.25 1.25 1.25 1.25-0.556 1.25-1.25z"></path></g>
				);
			case 'icon-expand_circle':
				return (
					<g><path fill="currentColor" d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16c8.831 0 16-7.169 16-16s-7.169-16-16-16zM25.692 12.185c0 0.923-1.108 1.385-1.785 0.738l-1.569-1.569-4.615 4.646 4.646 4.646 1.569-1.569c0.646-0.646 1.785-0.185 1.785 0.738v4.862c0 0.585-0.462 1.046-1.046 1.046h-4.862c-0.923 0-1.385-1.108-0.738-1.785l1.569-1.569-4.646-4.646-4.646 4.646 1.569 1.569c0.646 0.646 0.185 1.785-0.738 1.785h-4.831c-0.585 0-1.046-0.462-1.046-1.046v-4.862c0-0.923 1.108-1.385 1.785-0.738l1.569 1.569 4.615-4.646-4.646-4.646-1.538 1.569c-0.646 0.646-1.785 0.185-1.785-0.738v-4.831c0-0.585 0.462-1.046 1.046-1.046h4.862c0.923 0 1.385 1.108 0.738 1.785l-1.569 1.569 4.615 4.615 4.646-4.646-1.569-1.538c-0.646-0.646-0.185-1.785 0.738-1.785h4.862c0.585 0 1.046 0.462 1.046 1.046v4.831z"></path></g>
				);
			case 'icon-leaderboard_circle':
				return (
					<g><path fill="currentColor"  d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16c8.831 0 16-7.169 16-16s-7.169-16-16-16zM26.369 24.4c0 0.338-0.338 0.646-0.738 0.646h-19.262c-0.4 0-0.738-0.277-0.738-0.646v-1.569c0-0.338 0.338-0.646 0.738-0.646h19.262c0.4 0 0.738 0.277 0.738 0.646v1.569zM26.369 19.323c0 0.338-0.338 0.646-0.738 0.646h-19.262c-0.4 0-0.738-0.277-0.738-0.646v-1.569c0-0.338 0.338-0.646 0.738-0.646h19.262c0.4 0 0.738 0.277 0.738 0.646v1.569zM26.369 14.246c0 0.338-0.338 0.646-0.738 0.646h-19.262c-0.4 0-0.738-0.277-0.738-0.646v-1.569c0-0.338 0.338-0.646 0.738-0.646h19.262c0.4 0 0.738 0.277 0.738 0.646v1.569zM26.369 9.2c0 0.338-0.338 0.646-0.738 0.646h-19.262c-0.4 0-0.738-0.277-0.738-0.646v-1.569c0-0.338 0.338-0.646 0.738-0.646h19.262c0.4 0 0.738 0.277 0.738 0.646v1.569z"></path></g>
				);
			case 'icon-left_circle':
				return (
					<g><path fill="currentColor"  d="M15.985 32c-8.84 0-15.985-7.175-15.985-16.015s7.145-15.985 15.985-15.985 15.985 7.145 15.985 15.985-7.145 16.015-15.985 16.015zM23.463 13.169h-7.478v-4.571c0-0.696-0.848-1.029-1.332-0.545l-7.357 7.387c-0.303 0.303-0.303 0.787 0 1.090l7.387 7.417c0.484 0.484 1.332 0.151 1.332-0.545v-4.571h7.478c0.424 0 0.787-0.363 0.787-0.787v-4.117c-0.030-0.424-0.363-0.757-0.817-0.757z"></path></g>
				);
			case 'icon-members_circle':
				return (
					<g><path fill="currentColor"  d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16c8.831 0 16-7.169 16-16s-7.169-16-16-16zM24.154 9.938c1.877 0 3.415 1.508 3.415 3.385s-1.508 3.415-3.415 3.415-3.385-1.508-3.385-3.415c0-1.846 1.508-3.385 3.385-3.385zM16 7.231c2.338 0 4.246 1.908 4.246 4.246s-1.908 4.246-4.246 4.246c-2.338 0-4.246-1.908-4.246-4.246s1.908-4.246 4.246-4.246zM7.846 9.938c1.877 0 3.385 1.508 3.385 3.385s-1.508 3.415-3.385 3.415-3.415-1.508-3.415-3.415c0-1.846 1.538-3.385 3.415-3.385zM7.846 19.538v2.646h-4.431c-0.554 0-1.015-0.462-1.015-1.015v-1.354c0-1.323 1.077-2.369 2.369-2.369h0.615c0.831 0.492 1.785 0.708 2.708 0.677-0.154 0.431-0.246 0.923-0.246 1.415zM22.8 22.523c0 0.554-0.462 1.015-1.015 1.015h-11.569c-0.554 0-1.015-0.462-1.015-1.015v-2.985c0-1.631 1.323-2.985 2.985-2.985h0.738c1.877 1.138 4.246 1.138 6.123 0h0.769c1.631 0 2.985 1.323 2.985 2.985v2.985zM28.585 22.185h-4.431v-2.646c0-0.492-0.092-0.985-0.246-1.446 0.923 0.062 1.877-0.185 2.708-0.677h0.615c1.323 0 2.369 1.077 2.369 2.369v1.354c0 0.585-0.462 1.046-1.015 1.046z"></path></g>
				)
			case 'icon-play_circle':
				return (
					<g><path fill="currentColor"  d="M15.985 0c-8.84 0-15.985 7.145-15.985 15.985s7.145 15.985 15.985 15.985 15.985-7.145 15.985-15.985-7.145-15.985-15.985-15.985zM23.463 17.529l-11.353 6.539c-1.029 0.575-2.301-0.151-2.301-1.362v-13.412c0-1.181 1.272-1.907 2.301-1.362l11.353 6.903c1.060 0.605 1.060 2.119 0 2.694z"></path></g>
				)
			case 'icon-plus_circle':
				return (
					<g><path fill="currentColor"  d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16 16-7.169 16-16-7.169-16-16-16zM26.769 18.154h-8.615v8.615h-4.308v-8.615h-8.615v-4.308h8.615v-8.615h4.308v8.615h8.615v4.308z"></path></g>
				)
			case 'icon-right_circle':
				return (
					<g><path fill="currentColor"  d="M0 15.985c0-8.84 7.145-15.985 15.985-15.985s15.985 7.145 15.985 15.985-7.145 16.015-15.985 16.015-15.985-7.175-15.985-16.015zM7.75 13.926v4.117c0 0.424 0.363 0.787 0.787 0.787h7.478v4.571c0 0.696 0.817 1.029 1.332 0.545l7.387-7.417c0.303-0.303 0.303-0.787 0-1.090l-7.417-7.417c-0.484-0.484-1.332-0.151-1.332 0.545v4.571h-7.478c-0.424 0.030-0.757 0.363-0.757 0.787z"></path></g>
				)
			case 'icon-search':
				return (
					<g><path fill="currentColor"  d="M31.536 27.641l-6.215-6.215c-0.278-0.278-0.649-0.417-1.067-0.417h-1.020c1.716-2.226 2.736-4.962 2.736-7.977 0-7.188-5.797-12.986-12.986-12.986s-12.986 5.751-12.986 12.939 5.797 12.986 12.986 12.986c3.014 0 5.797-1.020 7.977-2.736v1.020c0 0.417 0.139 0.788 0.417 1.067l6.261 6.215c0.603 0.603 1.53 0.603 2.133 0l1.762-1.762c0.603-0.557 0.603-1.53 0-2.133zM12.986 21.009c-4.406 0-7.977-3.571-7.977-7.977s3.571-7.977 7.977-7.977c4.406 0 7.977 3.571 7.977 7.977 0.046 4.406-3.525 7.977-7.977 7.977z"></path></g>
				)
			case 'icon-trophy':
				return (
					<g><path fill="currentColor"  d="M30.67 5.444h-5.777v-2.244c0-0.748-0.582-1.33-1.33-1.33h-15.127c-0.748 0-1.33 0.582-1.33 1.33v2.203h-5.777c-0.748 0.042-1.33 0.623-1.33 1.371v3.117c0 1.995 1.247 4.031 3.449 5.61 1.745 1.247 3.865 2.078 6.109 2.327 1.745 2.909 3.782 4.073 3.782 4.073v3.99h-2.66c-1.953 0-3.574 1.164-3.574 3.117v0.665c0 0.374 0.291 0.665 0.665 0.665h16.457c0.374 0 0.665-0.291 0.665-0.665v-0.665c0-1.953-1.579-3.117-3.574-3.117h-2.66v-3.99c0 0 2.036-1.205 3.782-4.073 2.244-0.249 4.364-1.039 6.109-2.327 2.203-1.621 3.449-3.657 3.449-5.61v-3.117c0-0.748-0.582-1.33-1.33-1.33zM5.527 12.592c-1.371-0.997-1.953-2.078-1.953-2.701v-0.873h3.574c0.042 1.829 0.332 3.408 0.707 4.779-0.873-0.332-1.662-0.707-2.327-1.205zM28.426 9.891c0 0.914-0.997 1.995-1.953 2.701-0.706 0.499-1.496 0.914-2.327 1.205 0.374-1.371 0.665-2.992 0.706-4.779h3.574v0.873z"></path></g>
				)
			case 'icon-trophy_circle':
				return (
					<g>
						<path fill="currentColor"  d="M22.031 15.2c0.615-0.215 1.2-0.523 1.723-0.892 0.738-0.523 1.446-1.354 1.446-2v-0.646h-2.646c-0.031 1.323-0.215 2.523-0.523 3.538z"></path>
						<path fill="currentColor"  d="M6.8 11.662v0.646c0 0.492 0.462 1.292 1.446 2 0.523 0.369 1.108 0.677 1.723 0.892-0.277-1.015-0.492-2.215-0.523-3.538h-2.646z"></path>
						<path fill="currentColor"  d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16c8.831 0 16-7.169 16-16s-7.169-16-16-16zM25.292 16.462c-1.292 0.923-2.862 1.538-4.523 1.723-1.292 2.154-2.8 3.015-2.8 3.015v2.954h1.969c1.446 0 2.646 0.862 2.646 2.308v0.492c0 0.277-0.215 0.492-0.492 0.492h-12.185c-0.277 0-0.492-0.215-0.492-0.492v-0.492c0-1.446 1.169-2.308 2.646-2.308h1.969v-2.954c0 0-1.508-0.892-2.8-3.015-1.662-0.185-3.231-0.769-4.523-1.723-1.631-1.169-2.554-2.677-2.554-4.154v-2.308c0-0.554 0.431-0.985 0.985-0.985h4.277v-1.631c0-0.554 0.431-0.985 0.985-0.985h11.2c0.554 0 0.985 0.431 0.985 0.985v1.631h4.277c0.554 0 0.985 0.431 0.985 0.985v2.308c0 1.477-0.923 2.985-2.554 4.154z"></path>
					</g>
				)
			case 'icon-upload':
				return (
					<g>
						<path fill="currentColor"  d="M18.478 23.988h-5.001c-0.834 0-1.482-0.648-1.482-1.482v-10.512h-5.465c-1.111 0-1.667-1.343-0.88-2.13l9.493-9.493c0.463-0.463 1.25-0.463 1.713 0l9.494 9.493c0.787 0.787 0.232 2.13-0.88 2.13h-5.465v10.512c0 0.787-0.695 1.482-1.528 1.482zM32 23.479v6.993c0 0.834-0.648 1.482-1.482 1.482h-29.036c-0.834 0-1.482-0.648-1.482-1.482v-6.993c0-0.834 0.648-1.482 1.482-1.482h8.475v0.509c0 1.945 1.575 3.52 3.52 3.52h5.001c1.945 0 3.52-1.575 3.52-3.52v-0.509h8.475c0.834 0 1.528 0.648 1.528 1.482zM24.22 28.99c0-0.695-0.556-1.25-1.25-1.25s-1.25 0.556-1.25 1.25 0.556 1.25 1.25 1.25 1.25-0.556 1.25-1.25zM28.249 28.99c0-0.695-0.556-1.25-1.25-1.25s-1.25 0.556-1.25 1.25 0.556 1.25 1.25 1.25 1.25-0.556 1.25-1.25z"></path>
					</g>
				)	
			case 'icon-video_circle':
				return (
					<g>
						<path fill="currentColor"  d="M16 0c-8.831 0-16 7.169-16 16s7.169 16 16 16 16-7.169 16-16-7.169-16-16-16zM27.077 21.538c0 1.015-0.831 1.846-1.846 1.846h-0.462c-0.492 0-0.954-0.185-1.292-0.554l-3.785-3.754v2.462c0 1.015-0.831 1.846-1.846 1.846h-11.077c-1.015 0-1.846-0.831-1.846-1.846v-11.077c0-1.015 0.831-1.846 1.846-1.846h11.077c1.015 0 1.846 0.831 1.846 1.846v2.462l3.754-3.754c0.338-0.338 0.831-0.554 1.292-0.554h0.492c1.015 0 1.846 0.831 1.846 1.846v11.077z"></path>
					</g>
				)
			case 'icon-facebook':
				return (
					<g>
						<path fill="currentColor" d="M30.2,0H1.8C0.8,0,0,0.8,0,1.8v28.5c0,1,0.8,1.8,1.8,1.8h15.3V19.6h-4.2v-4.8h4.2v-3.6
							c0-4.1,2.5-6.4,6.2-6.4C25.1,4.8,26.6,5,27,5v4.3l-2.6,0c-2,0-2.4,1-2.4,2.4v3.1h4.8l-0.6,4.8h-4.2V32h8.2c1,0,1.8-0.8,1.8-1.8V1.8
							C32,0.8,31.2,0,30.2,0z"/>
					</g>
				)
			case 'icon-twitter':
				return (
					<g>
						<path fill="currentColor" d="M10.1,29c12.1,0,18.7-10,18.7-18.7c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1c-1.2,0.5-2.4,0.9-3.8,1
							c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5
							C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1
							c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.2-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8
							c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29"/>
					</g>
				)
			case 'icon-youtube':
				return (
					<g>
						<path fill="currentColor" d="M31.4,8.5C31,7.1,29.9,6,28.5,5.7C26,5,16,5,16,5S6,5,3.5,5.7C2.2,6,1.1,7.1,0.7,8.5C0,11,0,16.3,0,16.3
							s0,5.2,0.7,7.8c0.4,1.4,1.5,2.5,2.8,2.8C6,27.5,16,27.5,16,27.5s10,0,12.5-0.7c1.4-0.4,2.5-1.5,2.8-2.8c0.7-2.5,0.7-7.8,0.7-7.8
							S32,11,31.4,8.5z M12.7,21.1v-9.5l8.4,4.8L12.7,21.1z"/> 
					</g>
				)
			case 'icon-avatar':
				return (
					<g>
						<path fill="currentColor" d="M12.42,31.29a15.45,15.45,0,0,0,3.58.32,15.45,15.45,0,0,0,3.58-.32c2.65-.42,5.75-5.79,8-7.9A25.86,25.86,0,0,0,29,15.22C29,6.21,23.19.67,15.94.61S3,6.18,3,15.28a26.27,26.27,0,0,0,1.43,8.16C6.61,25.5,9.77,30.9,12.42,31.29Zm-6.2-18.4C8.34,14.74,12.06,16,16,16a15.06,15.06,0,0,0,9.75-3.1,18.72,18.72,0,0,1-.63,7.28s-4,5.6-9.15,5.48c-5.16.12-9.18-5.48-9.18-5.48A21.36,21.36,0,0,1,6.22,12.89Z" /> 
					</g>
				) 
			case 'icon-community-events':
				return (
					<g>
						<path fill="currentColor" d="M4,13.68H3.16a.92.92,0,0,0-1,.89l-.43,2.77c-.1.61.15.89.7.89h.87a.93.93,0,0,0,1-.89l.09-.59H3.47l-.12.68s0,.06-.07.09a.11.11,0,0,1-.09,0H2.76a.11.11,0,0,1-.12-.12l.46-3a.18.18,0,0,1,.06-.1.11.11,0,0,1,.09,0h.43a.12.12,0,0,1,.1,0,.13.13,0,0,1,0,.1L3.72,15h.92l.06-.43C4.82,14,4.58,13.68,4,13.68Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M7.19,13.68H6.27a.94.94,0,0,0-1,.89l-.43,2.77c-.09.61.16.89.71.89h.92a.93.93,0,0,0,1-.89l.43-2.77C8,14,7.78,13.68,7.19,13.68Zm-.71,3.78s0,.06-.06.09a.11.11,0,0,1-.09,0H5.9a.11.11,0,0,1-.12-.12l.46-3s0-.06.06-.1a.11.11,0,0,1,.09,0h.43a.11.11,0,0,1,.13.13Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M12.05,13.68h-.49a1,1,0,0,0-.77.34.65.65,0,0,0-.64-.34h-.5a.92.92,0,0,0-1,.89l-.59,3.6H9l.58-3.69s0-.06.06-.1a.14.14,0,0,1,.1,0h.37a.11.11,0,0,1,.09,0,.14.14,0,0,1,0,.1l-.59,3.69h1l.58-3.69s0-.06.06-.1a.14.14,0,0,1,.1,0h.37a.11.11,0,0,1,.09,0,.14.14,0,0,1,0,.1l-.59,3.69h1l.58-3.6C12.85,14,12.61,13.68,12.05,13.68Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M16.85,13.68h-.49a1,1,0,0,0-.77.34.65.65,0,0,0-.64-.34h-.5a.92.92,0,0,0-1,.89l-.59,3.6h1l.58-3.69s0-.06.06-.1a.14.14,0,0,1,.1,0H15a.11.11,0,0,1,.09,0,.14.14,0,0,1,0,.1l-.59,3.69h.9L16,14.48s0-.06.06-.1a.12.12,0,0,1,.1,0h.36a.12.12,0,0,1,.1,0,.14.14,0,0,1,0,.1L16,18.17h1l.58-3.6C17.65,14,17.41,13.68,16.85,13.68Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M19.93,13.77l-.58,3.69s0,.06-.07.09a.11.11,0,0,1-.09,0h-.37a.11.11,0,0,1-.12-.12l.58-3.69h-1l-.58,3.6c-.1.61.15.89.7.89h.87a.93.93,0,0,0,1-.89l.58-3.6Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M23.25,13.68h-.86a.92.92,0,0,0-1,.89l-.59,3.6h1l.58-3.69s0-.06.06-.1a.12.12,0,0,1,.1,0h.36a.12.12,0,0,1,.1,0,.14.14,0,0,1,0,.1l-.59,3.69h1l.58-3.6C24.05,14,23.81,13.68,23.25,13.68Z" transform="translate(0.01 -5)" />
						<polygon fill="currentColor" points="24.03 13.2 24.98 13.2 25.69 8.77 24.74 8.77 24.03 13.2" />
						<polygon fill="currentColor" points="26.03 9.57 26.77 9.57 26.18 13.2 27.14 13.2 27.72 9.57 28.43 9.57 28.55 8.77 26.15 8.77 26.03 9.57" />
						<polygon fill="currentColor" points="29.85 10.62 29.6 8.77 28.71 8.77 29.2 11.57 28.95 13.2 29.91 13.2 30.18 11.57 31.57 8.77 30.68 8.77 29.85 10.62" />
						<path fill="currentColor" d="M15.41,7.06a8.6,8.6,0,0,0,.15.86c.06.25.74,1.2,1.2,1.2h0c.46,0,1.14-.95,1.2-1.2,0-.09.12-.74.16-.86V7c.09-1-.53-2-1.36-2h0c-.86,0-1.44,1-1.35,2Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M31.93,12.35l-4.21-.52-.5.43a5.53,5.53,0,0,0-1.78-2.4,3.44,3.44,0,0,0,.18-.46c0-.09.22-.58.25-.68V8.66A1.17,1.17,0,0,0,25,7.22h0a1,1,0,0,0-1.23,1v.09c0,.1.09,2,1.23,2h0a.71.71,0,0,0,.24-.06c-.09.28-.31.65-.61.65S23.16,9.8,23.07,9.83a8.2,8.2,0,0,0-1.05.43s0,.06.06.09a16.27,16.27,0,0,1,.31,1.76l1.42-.22-.93.68h9.05C32,12.51,32,12.35,31.93,12.35Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M16.48,12.85a2,2,0,0,0,.59,0l4.09-.62h0c-.15-1.08-.34-2.31-.37-2.43s0-.09-.06-.09c-.21-.16-1.41-.62-2.15-1a.23.23,0,0,0-.31.19V9a2.18,2.18,0,0,1-1.51,1.2A2.15,2.15,0,0,1,15.25,9V8.94a.22.22,0,0,0-.3-.19c-.71.34-1.94.8-2.16,1,0,0-.06.06-.06.09s-.21,1.38-.37,2.43h0Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M6.52,12.51h4.21l-.92-.68,1.41.22c.13-.9.31-1.67.31-1.76s0-.06.06-.09a8.57,8.57,0,0,0-1-.43c-.1,0-1.08,1.11-1.51,1.11s-.52-.37-.62-.65a.25.25,0,0,0,.25.06h0c1.14-.06,1.23-1.94,1.23-2V8.17a1,1,0,0,0-1.23-1h0A1.17,1.17,0,0,0,7.75,8.6v.06A6.13,6.13,0,0,0,8,9.34a2.65,2.65,0,0,0,.19.46,5.65,5.65,0,0,0-1.82,2.52l-.61-.52-4.22.55c-.09,0-.09.16,0,.16h5Z" transform="translate(0.01 -5)" />
						<path fill="currentColor" d="M30.73,20.6l-4.49.55h0l-.77,4.93-2.4.43,1.81-.74,1-6.09a3.56,3.56,0,0,0-1.26-.43H7.81a4.72,4.72,0,0,0-1.42.43l-.95,6.09,1.63.74-2.31-.46.8-4.93h0l-4.4-.52,2.49,3.17L0,26.35,8.76,27,6.27,25.58A20.07,20.07,0,0,1,10,25.12H20.55a17.64,17.64,0,0,1,3.57.46l-2.56,1.48,8.25-.71-2.59-2.58Zm-20.37.34H9.41l-.16.92h.9l-.1.68H9.16L9,23.46h1l-.12.71H8.05l.62-3.94h1.78ZM11,24.17l-.19-3.94h.83l0,2.86.93-2.86h.83l-1.45,3.94Zm3.57-3.23-.16.92h.89l-.09.68h-.89l-.15.92h1l-.12.71H13.16l.62-3.94h1.78l-.12.68h-.92Zm3.66,0-.5,3.2h-.86l.53-3.26a.11.11,0,0,0,0-.09s0,0-.07,0h-.33a.1.1,0,0,0-.1,0s0,.06-.06.09l-.52,3.26h-.86l.49-3.2a.83.83,0,0,1,.89-.8h.74C18.05,20.17,18.27,20.45,18.18,21ZM20,21l-.49,3.2h-.86l.49-3.2h-.64l.12-.8h2.12l-.12.8Zm3.2,0-.06.28h-.8l.06-.31a.11.11,0,0,0,0-.09s0,0-.06,0H22a.11.11,0,0,0-.09,0s0,.06-.06.09l-.12.71a.11.11,0,0,0,0,.09s0,0,.06,0h.55c.49,0,.71.28.62.8l-.13.83a.83.83,0,0,1-.89.8h-.71c-.49,0-.7-.28-.61-.8l.06-.4h.83l-.06.46a.11.11,0,0,0,0,.09s0,0,.06,0h.37a.11.11,0,0,0,.09,0s0-.06.06-.09l.13-.77a.11.11,0,0,0,0-.09s0,0-.06,0h-.56c-.49,0-.71-.28-.61-.8l.12-.77a.83.83,0,0,1,.89-.8h.68C23.1,20.17,23.32,20.45,23.22,21Z" transform="translate(0.01 -5)" />
					</g>
				) 
			case 'icon-timer':
				return (
					<g>
						<rect fill="currentColor" y="26.27" x="8" width="18.36" height="4.67" />
   						<path fill="currentColor" d="M26,6.72h0V0H7.64V6.72h0l7.8,8.72-7.8,8.75H26l-7.8-8.75ZM10.53,2.89H23.11V6.72H10.53Zm1.75,5.89h9.11l-4.55,5.11Z" />
					</g>
				)
			case 'icon-eRallyRGtCaps':
				return (
					<g>
						<g>
							<path fill="currentColor" d="M17.019,15.887H5.333c-0.624,0-1.253,0.511-1.397,1.135l-1.213,4.886c-0.144,0.624,0.249,1.135,0.873,1.135h11.686
								c0.624,0,1.253-0.511,1.397-1.135l1.213-4.886C18.036,16.398,17.643,15.887,17.019,15.887z M16.507,17.257l-0.422,0.612
								c-0.065,0.094-0.194,0.171-0.287,0.171h-0.012h-2.34h-0.213c-0.06,0-0.12,0.049-0.133,0.108l-0.831,3.601
								c-0.014,0.06-0.074,0.108-0.133,0.108h-1.01c-0.06,0-0.097-0.049-0.083-0.108l0.831-3.601c0.014-0.06-0.023-0.108-0.083-0.108
								h-0.124H9.237H6.755c-0.286,0-0.571,0.232-0.637,0.517L5.64,20.631c-0.024,0.105-0.013,0.187,0.035,0.249
								c0.047,0.061,0.123,0.092,0.228,0.092c0.486,0,1.754-0.01,2.28-0.029c0.059-0.002,0.115-0.05,0.129-0.108l0.169-0.733
								c0.014-0.06-0.023-0.108-0.083-0.108H6.833c-0.093,0-0.115-0.077-0.05-0.171l0.422-0.612c0.065-0.094,0.194-0.171,0.287-0.171
								h2.352c0.065,0,0.094,0.038,0.085,0.093h0.003l-0.583,2.527c-0.013,0.056-0.065,0.102-0.122,0.108
								c-1.104,0.107-2.953,0.161-3.817,0.161c-0.445,0-0.749-0.105-0.909-0.314c-0.161-0.209-0.19-0.536-0.087-0.982l0.013-0.054
								l0.473-2.047l0.008-0.034l0.011-0.047c0.11-0.477,0.298-0.824,0.564-1.04c0.266-0.216,0.637-0.324,1.114-0.324h2.863h2.429h2.218
								h1.902h0.45C16.55,17.086,16.572,17.163,16.507,17.257z"/>
						</g>
						<path fill="currentColor" d="M14.963,8.957H13.75c-0.274,0-0.565,0.169-0.73,0.426l-2.713,4.196l0.366,1.218h0.473l0.655-1.043h2.086l0.052,0.567
							c0.026,0.285,0.246,0.475,0.549,0.475h1.122L14.963,8.957z M12.467,12.669l1.193-1.96l0.142,1.96H12.467z"/>
						<path fill="currentColor" d="M9.533,12.961c0.356-0.083,0.645-0.253,0.866-0.509c0.221-0.256,0.394-0.614,0.518-1.076l0.201-0.751
							c0.156-0.584,0.138-1.008-0.055-1.272c-0.193-0.264-0.582-0.396-1.166-0.396H6.692c-0.165,0-0.335,0.134-0.38,0.299l-1.484,5.54
							H6.33l0.345-1.286l-0.793-0.398c0.042-0.043,0.098-0.068,0.151-0.068h0.767h0.791h0.377l0.377,1.347
							c0.07,0.25,0.29,0.405,0.575,0.405h1.165L9.533,12.961z M9.415,11.376c-0.089,0.334-0.301,0.501-0.635,0.501H7.112l0.469-1.752
							H9.25c0.334,0,0.456,0.167,0.366,0.501L9.415,11.376z"/>
						<path fill="currentColor" d="M28.323,8.957c-0.367,0-0.758,0.21-1.007,0.54l-0.444,0.554l-2.012,2.551c0,0-0.045-0.097-0.045-0.12
							c0-0.012,0.374-0.532,0.65-1.359l-0.482-2.098c0,0,0,0,0,0c-0.009-0.041-0.043-0.067-0.089-0.067h-1.511l0.986,4.287l-1.23,1.555
							h1.587l4.58-5.842H28.323z"/>
						<path fill="currentColor" d="M23.353,13.629h-2.425l0.758-2.83l0.494-1.842h-1.082c-0.232,0-0.47,0.188-0.532,0.419l-0.285,1.063l-0.471,1.757
							l-0.384,1.432h-1.935H16.99l1.252-4.672H17.16c-0.232,0-0.47,0.188-0.532,0.419l-0.756,2.821l0.288,2.6h1.02h2.004h0.05
							l0.231-0.862l0.349,0.862h2.619L23.353,13.629z"/>
   					</g>
				)
			case 'icon-eRallyGrpACaps':
				return (
					<g>
						<path fill="currentColor" d="M15.42,21.745c-0.16,0.031-0.312,0.009-0.373-0.161c0.003-0.092,0.098-0.092,0.153-0.132
							c0-0.058,0-0.117,0-0.175c0.046-0.073,0.129-0.153,0.22-0.187v-0.497h-0.71l0.189-0.933h1.598l0.121,0.563
							c0.128-0.045,0.264-0.054,0.401,0.003c-0.055,0.105-0.221,0.101-0.285,0.197c0.025,0.434-0.469,0.445-0.723,0.263
							c0.025-0.028,0.055-0.06,0.087-0.093h-0.11v1.845c0.183-0.197,0.406-0.399,0.768-0.35c0.091-0.062,0.048-0.184,0.066-0.351
							c0.104-0.078,0.306-0.127,0.416-0.022c0.021,0.108-0.101,0.074-0.088,0.175c0.165,0.05,0.367-0.262,0.548-0.066
							c-0.01,0.1-0.114,0.105-0.197,0.131c-0.076,0.187-0.147,0.379-0.438,0.35c-0.065,0.015-0.073,0.087-0.066,0.175
							c-0.017,0.016-0.038,0.032-0.059,0.048h3.689v-1.937h-0.473l-0.03-0.108c-0.083,0.05-0.164,0.102-0.234,0.157
							c-0.15,0.117-0.366,0.41-0.635,0.219c0.253-0.2,0.544-0.361,0.827-0.531l-1.927-6.989h-1.324c-0.023,0.213-0.348,0.144-0.406,0.324
							c0.033,0.074,0.148,0.061,0.188,0.127c-0.048,0.12-0.196,0.049-0.299,0.077c-0.133,0.037-0.271,0.205-0.4,0.064
							c0.005-0.09,0.175-0.158,0.277-0.21c0.027-0.057-0.011-0.074-0.005-0.151c0.154-0.072,0.308-0.197,0.497-0.231h-0.788
							c-0.023,0.03-0.069,0.037-0.099,0.061c0.001,0.044,0.003,0.089,0.004,0.134c-0.064,0.108-0.228,0.24-0.348,0.112
							c-0.013-0.102-0.087-0.138-0.141-0.196c0.022-0.031,0.035-0.07,0.046-0.111h-1.512l-0.435,1.576
							c0.031-0.013,0.067-0.019,0.108-0.016c0.059,0.005,0.093,0.083,0.154,0.095c0.241,0.048,0.442-0.19,0.665-0.123
							c0.003,0.132-0.174,0.097-0.228,0.175c0.04,0.067,0.047,0.15-0.01,0.201c0.02,0.066,0.114,0.052,0.122,0.13
							c-0.064,0.051-0.209,0.075-0.284,0.026c-0.02-0.019-0.021-0.058-0.02-0.099c-0.17-0.094-0.374-0.154-0.546-0.246l-1.526,5.532
							h-0.473v1.937h4.047V21.745z M19.933,21.079c-0.077,0.042-0.12-0.007-0.175-0.044C19.779,20.953,19.953,20.988,19.933,21.079z
							M16.603,21.364c0.061-0.127,0.344-0.212,0.46-0.088C17.007,21.422,16.703,21.517,16.603,21.364z M17.742,20.575
							c0.083-0.01,0.103,0.043,0.153,0.066C17.876,20.722,17.698,20.691,17.742,20.575z M17.633,19.743
							c0.122-0.04,0.356-0.189,0.46-0.044C18.044,19.845,17.696,19.921,17.633,19.743z M18.575,22.526
							c-0.119-0.012-0.223-0.04-0.219-0.175C18.484,22.345,18.603,22.445,18.575,22.526z M18.684,20.356
							c0.029-0.119,0.313-0.074,0.307,0.044C18.929,20.456,18.688,20.484,18.684,20.356z M19.166,18.384
							c0.139,0.014,0.225,0.082,0.219,0.241c-0.188,0.033-0.281,0.271-0.504,0.197C18.855,18.64,19.131,18.577,19.166,18.384z
							M18.991,19.326c0.2-0.149,0.393-0.366,0.679-0.197c-0.044,0.108-0.183,0.24-0.307,0.329c-0.205,0.147-0.575,0.36-0.745,0.153
							C18.701,19.512,18.849,19.432,18.991,19.326z M18.794,21.386c-0.02,0.12-0.202,0.052-0.241,0c0.012-0.018,0.023-0.036,0.022-0.066
							C18.643,21.273,18.783,21.307,18.794,21.386z M18.246,21.079c0.015-0.114,0.26-0.152,0.307-0.044
							C18.52,21.131,18.299,21.194,18.246,21.079z M17.479,19.37c-0.05-0.089-0.055-0.199,0.022-0.263
							c-0.023-0.087-0.147-0.073-0.153-0.175c0.086-0.064,0.277-0.089,0.373-0.022c0.025,0.026,0.025,0.077,0.022,0.131
							c0.247,0.148,0.553,0.236,0.789,0.394c-0.044,0.097-0.143,0.17-0.285,0.153c-0.077-0.009-0.118-0.113-0.197-0.131
							c-0.313-0.073-0.587,0.229-0.876,0.131C17.175,19.416,17.404,19.471,17.479,19.37z M17.139,13.539
							c-0.023,0.098-0.194,0.032-0.186-0.061C17.039,13.473,17.121,13.472,17.139,13.539z M13.878,14.135
							c-0.063,0.01-0.08-0.03-0.119-0.046C13.772,14.026,13.909,14.046,13.878,14.135z M13.634,14.813c0.033-0.113,0.297-0.18,0.35-0.045
							C13.892,14.801,13.717,14.921,13.634,14.813z M12.965,21.868c0.052-0.122,0.288-0.211,0.394-0.088
							C13.331,21.925,13.043,22.03,12.965,21.868z M15.888,14.151c-0.03,0.202-0.386,0.322-0.563,0.186
							C15.362,14.148,15.69,14.085,15.888,14.151z M15.589,14.73c-0.074,0.043-0.188-0.001-0.204-0.077
							C15.46,14.621,15.572,14.649,15.589,14.73z M15.645,16.045h0.118l0.485,2.362H15.16L15.645,16.045z M15.263,14.49
							c0.004,0.095-0.05,0.137-0.06,0.22c-0.085,0.005-0.14-0.024-0.17-0.078C15.068,14.552,15.143,14.436,15.263,14.49z M15.196,14.007
							c-0.141,0.166-0.44,0.497-0.757,0.377c0.039-0.082,0.166-0.083,0.212-0.158C14.621,13.895,14.998,13.874,15.196,14.007z
							M14.728,13.504c-0.043,0.099-0.257,0.171-0.349,0.079C14.417,13.47,14.647,13.389,14.728,13.504z M13.447,21.211
							c0.035-0.128,0.255-0.033,0.241,0.088C13.576,21.301,13.468,21.3,13.447,21.211z M14.696,21.211
							c-0.257,0.109-0.516,0.33-0.855,0.285c0.03-0.292,0.463-0.18,0.548-0.417c-0.04-0.099-0.191-0.086-0.241-0.175
							c0.069-0.154,0.259-0.055,0.394-0.088c0.176-0.043,0.364-0.257,0.526-0.066c-0.011,0.117-0.236,0.199-0.373,0.263
							C14.658,21.087,14.707,21.111,14.696,21.211z"/>
						<path fill="currentColor" d="M15.854,10.311c0.002,0.026,0.006,0.047,0.012,0.063c0.006,0.016,0.013,0.033,0.023,0.051
							c0.009,0.018,0.022,0.031,0.038,0.04c0.016,0.009,0.035,0.013,0.057,0.013c0.02,0,0.037-0.004,0.052-0.012
							c0.015-0.008,0.027-0.02,0.036-0.036c0.009-0.016,0.016-0.031,0.021-0.046c0.005-0.014,0.009-0.034,0.011-0.058
							c0.002-0.024,0.003-0.043,0.004-0.056c0.001-0.013,0.001-0.031,0.001-0.054c0-0.005,0-0.01,0-0.013V9.206
							c0-0.006,0-0.019,0.001-0.037c0.001-0.019,0.001-0.033,0-0.042c0-0.009-0.001-0.023-0.002-0.041c0-0.018-0.002-0.032-0.005-0.042
							c-0.003-0.01-0.006-0.022-0.009-0.037c-0.004-0.015-0.009-0.026-0.015-0.034c-0.006-0.008-0.014-0.016-0.022-0.025
							c-0.008-0.009-0.019-0.015-0.031-0.019C16.014,8.924,16,8.922,15.984,8.922c-0.022,0-0.041,0.004-0.057,0.011
							c-0.016,0.007-0.029,0.019-0.038,0.035c-0.009,0.016-0.017,0.031-0.023,0.044c-0.006,0.013-0.01,0.032-0.012,0.058
							c-0.002,0.025-0.003,0.044-0.003,0.055c0,0.011,0,0.029,0,0.055c0,0.004,0,0.008,0,0.011c0,0.003,0,0.006,0,0.009v0.997
							c0,0.023,0,0.041,0.001,0.055C15.851,10.265,15.852,10.285,15.854,10.311z"/>
						<path fill="currentColor" d="M13.629,9.153c0-0.101-0.014-0.165-0.043-0.193c-0.029-0.028-0.087-0.042-0.174-0.042h-0.03V9.38h0.137
							C13.592,9.38,13.629,9.304,13.629,9.153z"/>
						<path fill="currentColor" d="M21.05,9.507c0.015-0.002,0.033-0.005,0.053-0.011c0.02-0.006,0.035-0.015,0.045-0.028
							c0.01-0.013,0.02-0.031,0.03-0.053c0.01-0.022,0.017-0.051,0.021-0.086c0.003-0.035,0.005-0.078,0.005-0.128
							c0-0.109-0.014-0.185-0.043-0.227c-0.029-0.043-0.078-0.064-0.149-0.064h-0.061v0.6h0.031C21.013,9.51,21.035,9.509,21.05,9.507z"
							/>
						<path fill="currentColor" d="M9.229,7.457v3.477v0.901v12.125v0.517v0.068h13.542v-0.068v-0.517V11.834v-0.901V7.457H9.229z M20.412,8.458
							h0.726c0.096,0,0.18,0.012,0.251,0.037c0.072,0.025,0.135,0.065,0.19,0.121c0.055,0.056,0.097,0.134,0.126,0.233
							c0.029,0.099,0.043,0.22,0.043,0.361c0,0.122-0.008,0.226-0.026,0.312c-0.017,0.087-0.047,0.164-0.088,0.232
							c-0.042,0.068-0.101,0.118-0.177,0.152c-0.076,0.034-0.17,0.051-0.282,0.051h-0.223v0.983h-0.54V8.458z M17.852,8.458h0.534v1.777
							c0,0.002,0,0.006,0,0.011c0,0.027,0,0.048,0.001,0.064c0.001,0.016,0.003,0.037,0.008,0.064c0.005,0.027,0.011,0.048,0.02,0.064
							c0.009,0.016,0.022,0.029,0.04,0.041c0.018,0.012,0.04,0.018,0.066,0.018c0.022,0,0.041-0.004,0.057-0.011
							c0.016-0.007,0.029-0.018,0.038-0.034c0.009-0.015,0.017-0.03,0.023-0.043c0.006-0.014,0.01-0.033,0.012-0.056
							c0.002-0.023,0.003-0.041,0.004-0.054c0.001-0.012,0.001-0.03,0.001-0.053c0-0.005,0-0.009,0-0.011V8.458h0.534v1.825
							c0,0.224-0.054,0.395-0.162,0.511c-0.108,0.116-0.276,0.174-0.504,0.173c-0.232,0.001-0.402-0.057-0.51-0.173
							c-0.108-0.116-0.162-0.287-0.162-0.511V8.458z M15.306,9.087c0-0.207,0.056-0.367,0.168-0.481c0.112-0.114,0.28-0.172,0.505-0.175
							c0.231,0.003,0.403,0.061,0.514,0.175c0.112,0.114,0.168,0.274,0.168,0.481v1.183c0,0.101-0.013,0.192-0.038,0.275
							c-0.026,0.083-0.064,0.156-0.116,0.22c-0.052,0.065-0.123,0.114-0.212,0.15c-0.089,0.035-0.191,0.052-0.307,0.048
							c-0.122,0.003-0.227-0.013-0.316-0.048c-0.089-0.035-0.16-0.085-0.212-0.15c-0.052-0.064-0.091-0.138-0.116-0.22
							c-0.025-0.083-0.038-0.174-0.038-0.275V9.087z M12.823,8.458h0.843c0.087,0,0.16,0.012,0.222,0.035
							c0.061,0.023,0.11,0.054,0.145,0.092c0.035,0.038,0.063,0.088,0.084,0.151c0.02,0.063,0.034,0.126,0.04,0.189
							c0.006,0.063,0.009,0.139,0.009,0.228c0,0.047-0.001,0.085-0.002,0.114c-0.002,0.03-0.007,0.067-0.017,0.112
							c-0.01,0.045-0.024,0.083-0.042,0.113c-0.018,0.03-0.044,0.06-0.08,0.089c-0.036,0.029-0.079,0.051-0.13,0.067
							c0.051,0.009,0.093,0.027,0.127,0.052c0.033,0.025,0.057,0.057,0.072,0.094c0.015,0.037,0.024,0.072,0.03,0.104
							c0.005,0.032,0.008,0.067,0.008,0.107c0,0.411-0.002,0.722-0.006,0.935h-0.537V9.972c0-0.038-0.004-0.069-0.012-0.092
							c-0.008-0.023-0.022-0.039-0.044-0.049c-0.021-0.01-0.042-0.016-0.062-0.018c-0.02-0.002-0.05-0.003-0.09-0.003v1.13h-0.556V8.458z
							M10.263,9.124c0-0.117,0.016-0.22,0.047-0.307c0.031-0.087,0.07-0.155,0.115-0.202c0.045-0.047,0.101-0.085,0.166-0.113
							c0.066-0.028,0.125-0.046,0.179-0.054c0.054-0.008,0.112-0.012,0.174-0.012c0.077,0,0.147,0.005,0.209,0.016
							c0.063,0.011,0.116,0.028,0.162,0.051c0.045,0.023,0.085,0.049,0.119,0.077c0.034,0.029,0.063,0.064,0.085,0.106
							c0.022,0.042,0.041,0.084,0.055,0.125c0.014,0.041,0.025,0.09,0.032,0.148c0.007,0.058,0.012,0.111,0.015,0.161
							c0.003,0.05,0.004,0.109,0.004,0.176H11.1V9.101c0-0.04-0.004-0.072-0.013-0.098c-0.008-0.026-0.02-0.044-0.035-0.055
							c-0.015-0.011-0.029-0.018-0.041-0.022c-0.012-0.004-0.026-0.005-0.042-0.005c-0.031,0-0.057,0.004-0.076,0.012
							c-0.019,0.008-0.034,0.019-0.045,0.031c-0.011,0.013-0.019,0.035-0.024,0.068c-0.005,0.033-0.008,0.064-0.009,0.093
							c-0.001,0.029-0.001,0.075-0.001,0.137v0.96c0,0.036,0.001,0.067,0.004,0.092c0.003,0.025,0.009,0.052,0.018,0.081
							c0.01,0.029,0.025,0.051,0.046,0.066c0.022,0.015,0.049,0.023,0.082,0.023c0.055,0,0.092-0.022,0.11-0.065
							c0.018-0.043,0.027-0.113,0.027-0.21V9.923h-0.147V9.494h0.665v1.446h-0.214l-0.094-0.211c-0.098,0.159-0.249,0.239-0.453,0.239
							c-0.396,0-0.595-0.256-0.595-0.768V9.124z M22.186,23.958H9.814V11.834h12.372V23.958z"/>
						<path fill="currentColor" d="M16.831,13.332c-0.052-0.005-0.101-0.001-0.148,0.008h0.147C16.83,13.337,16.83,13.335,16.831,13.332z"/>
					</g>
				)
			case 'icon-eRallyGrpB4wdCaps':
				return (
					<g> 
						<path fill="currentColor" d="M13.139,8.91c0.002,0.026,0.006,0.047,0.012,0.063c0.006,0.016,0.013,0.033,0.023,0.051
							c0.009,0.018,0.022,0.031,0.038,0.04c0.016,0.009,0.035,0.013,0.057,0.013c0.02,0,0.037-0.004,0.052-0.012
							c0.015-0.008,0.027-0.02,0.036-0.036c0.009-0.016,0.016-0.032,0.021-0.046c0.005-0.015,0.009-0.034,0.011-0.058
							c0.002-0.024,0.003-0.043,0.004-0.056c0-0.013,0.001-0.031,0.001-0.054c0-0.005,0-0.01,0-0.013V7.805c0-0.006,0-0.019,0.001-0.037
							c0.001-0.019,0.001-0.033,0-0.042c-0.001-0.009-0.001-0.023-0.001-0.041c-0.001-0.018-0.002-0.032-0.005-0.042
							c-0.003-0.01-0.006-0.022-0.009-0.038C13.375,7.59,13.37,7.578,13.364,7.57c-0.006-0.008-0.014-0.016-0.022-0.025
							c-0.008-0.009-0.019-0.015-0.031-0.019c-0.012-0.004-0.026-0.005-0.042-0.005c-0.022,0-0.041,0.004-0.057,0.011
							c-0.016,0.007-0.029,0.019-0.038,0.035c-0.009,0.016-0.017,0.031-0.023,0.044c-0.006,0.013-0.01,0.032-0.013,0.058
							c-0.002,0.025-0.003,0.044-0.003,0.055c0,0.011,0,0.029,0,0.055c0,0.004,0,0.008,0,0.011c0,0.003,0,0.006,0,0.009v0.997
							c0,0.023,0,0.041,0.001,0.055C13.136,8.864,13.137,8.884,13.139,8.91z"/>
						<path fill="currentColor" d="M10.914,7.752c0-0.101-0.014-0.165-0.043-0.193c-0.029-0.028-0.087-0.042-0.174-0.042h-0.03V7.98h0.137
							C10.877,7.98,10.914,7.904,10.914,7.752z"/>
						<path fill="currentColor" d="M18.335,8.107c0.015-0.002,0.033-0.005,0.053-0.011c0.02-0.006,0.036-0.015,0.045-0.028c0.01-0.013,0.02-0.031,0.03-0.053
							c0.01-0.022,0.017-0.051,0.021-0.087C18.488,7.893,18.49,7.85,18.49,7.8c0-0.109-0.014-0.185-0.043-0.227
							c-0.029-0.043-0.078-0.064-0.149-0.064h-0.061v0.6h0.031C18.298,8.109,18.32,8.108,18.335,8.107z"/>
						<path fill="currentColor" d="M7.099,10.433h12.372v9.33h0.585v-9.33V9.533V6.056H6.514v3.477v0.901v12.124v0.517v0.068h8.012v-0.585H7.099V10.433z
							M17.697,7.057h0.726c0.096,0,0.18,0.012,0.251,0.037c0.072,0.025,0.135,0.066,0.191,0.122c0.055,0.056,0.097,0.134,0.126,0.233
							c0.029,0.099,0.043,0.22,0.043,0.361c0,0.122-0.008,0.226-0.026,0.312c-0.017,0.087-0.047,0.164-0.088,0.232
							c-0.042,0.067-0.101,0.118-0.177,0.152c-0.076,0.034-0.17,0.051-0.282,0.051h-0.223V9.54h-0.54V7.057z M15.137,7.057h0.534v1.777
							c0,0.002,0,0.006,0,0.011c0,0.027,0,0.048,0.001,0.064c0.001,0.016,0.003,0.037,0.008,0.064c0.005,0.027,0.011,0.048,0.02,0.064
							c0.009,0.016,0.022,0.029,0.04,0.041c0.018,0.012,0.04,0.018,0.066,0.018c0.022,0,0.041-0.004,0.057-0.011
							c0.016-0.007,0.029-0.018,0.038-0.034c0.009-0.015,0.017-0.03,0.023-0.044c0.006-0.014,0.01-0.033,0.012-0.056
							c0.002-0.023,0.003-0.041,0.004-0.054c0.001-0.012,0.001-0.03,0.001-0.053c0-0.005,0-0.009,0-0.011V7.057h0.534v1.825
							c0,0.224-0.054,0.395-0.162,0.511c-0.108,0.116-0.276,0.174-0.504,0.173c-0.232,0.001-0.402-0.057-0.51-0.173
							c-0.108-0.116-0.162-0.287-0.162-0.511V7.057z M12.591,7.687c0-0.207,0.056-0.367,0.168-0.481c0.112-0.114,0.28-0.172,0.505-0.175
							c0.231,0.003,0.403,0.062,0.515,0.175c0.112,0.114,0.168,0.274,0.168,0.481V8.87c0,0.101-0.013,0.192-0.038,0.275
							c-0.026,0.083-0.064,0.156-0.116,0.221C13.74,9.43,13.67,9.48,13.58,9.515c-0.089,0.035-0.191,0.051-0.307,0.048
							c-0.122,0.003-0.227-0.013-0.316-0.048c-0.089-0.035-0.16-0.085-0.212-0.15c-0.052-0.064-0.091-0.138-0.116-0.221
							c-0.025-0.083-0.038-0.174-0.038-0.275V7.687z M10.108,7.057h0.843c0.086,0,0.16,0.012,0.222,0.035
							c0.061,0.023,0.11,0.054,0.145,0.092c0.035,0.038,0.063,0.088,0.084,0.151c0.02,0.063,0.034,0.126,0.04,0.188
							c0.006,0.063,0.01,0.139,0.01,0.229c0,0.047-0.001,0.085-0.002,0.114c-0.002,0.03-0.007,0.067-0.017,0.112
							c-0.01,0.045-0.024,0.083-0.041,0.113c-0.018,0.03-0.044,0.06-0.08,0.089c-0.036,0.029-0.08,0.052-0.13,0.067
							c0.051,0.009,0.093,0.027,0.127,0.052c0.033,0.025,0.057,0.057,0.072,0.094c0.015,0.037,0.024,0.072,0.03,0.104
							c0.005,0.032,0.008,0.067,0.008,0.107c0,0.41-0.002,0.722-0.006,0.935h-0.537V8.572c0-0.039-0.004-0.069-0.012-0.092
							c-0.008-0.023-0.022-0.039-0.044-0.049c-0.021-0.01-0.042-0.016-0.062-0.018c-0.02-0.002-0.05-0.003-0.091-0.003v1.13h-0.556V7.057
							z M7.548,7.724c0-0.117,0.016-0.22,0.047-0.307c0.031-0.087,0.07-0.155,0.115-0.202C7.755,7.168,7.81,7.13,7.876,7.102
							c0.066-0.028,0.125-0.046,0.179-0.054C8.108,7.04,8.166,7.037,8.229,7.037c0.077,0,0.147,0.005,0.209,0.016
							C8.5,7.064,8.554,7.081,8.6,7.104c0.045,0.023,0.085,0.049,0.119,0.077c0.034,0.029,0.063,0.064,0.085,0.106
							C8.827,7.329,8.845,7.37,8.859,7.412c0.014,0.041,0.025,0.09,0.032,0.148c0.007,0.058,0.012,0.111,0.015,0.161
							c0.003,0.05,0.004,0.108,0.004,0.176H8.385V7.701c0-0.04-0.004-0.072-0.013-0.098c-0.008-0.026-0.02-0.044-0.035-0.055
							C8.322,7.536,8.308,7.529,8.296,7.525C8.283,7.522,8.269,7.52,8.254,7.52c-0.031,0-0.056,0.004-0.076,0.012
							C8.159,7.54,8.144,7.551,8.133,7.563C8.122,7.576,8.114,7.599,8.108,7.631C8.103,7.664,8.1,7.695,8.099,7.724
							C8.098,7.753,8.098,7.799,8.098,7.861v0.96c0,0.037,0.001,0.067,0.004,0.092C8.104,8.938,8.11,8.965,8.119,8.994
							c0.01,0.029,0.025,0.051,0.046,0.066c0.021,0.015,0.049,0.023,0.082,0.023c0.055,0,0.092-0.022,0.11-0.065
							c0.018-0.043,0.027-0.113,0.027-0.21V8.522H8.238V8.093h0.665V9.54H8.689l-0.094-0.21C8.498,9.488,8.347,9.568,8.143,9.568
							c-0.397,0-0.595-0.256-0.595-0.768V7.724z"/>
						<path fill="currentColor" d="M17.191,19.764c0.14-0.094,0.275-0.193,0.393-0.308c0.014-0.013,0.022-0.034,0.032-0.051
							c0.054-0.012,0.078,0.009,0.118,0.013c-0.053,0.142-0.178,0.249-0.321,0.347h0.206c0.15-0.287,0.226-0.631,0.226-1.032
							c0-0.441-0.081-0.798-0.243-1.07c-0.162-0.272-0.357-0.485-0.586-0.638c-0.229-0.154-0.47-0.26-0.722-0.319
							c-0.253-0.059-0.466-0.096-0.639-0.112v-0.118c0.166-0.031,0.361-0.095,0.586-0.189c0.225-0.094,0.44-0.228,0.645-0.402
							c0.205-0.173,0.379-0.394,0.521-0.662c0.142-0.268,0.213-0.591,0.213-0.969c0-0.764-0.28-1.343-0.841-1.737
							c-0.561-0.394-1.326-0.591-2.297-0.591h-0.547c-0.023,0.019-0.046,0.037-0.068,0.053c-0.205,0.147-0.575,0.36-0.745,0.153
							c0.061-0.073,0.158-0.136,0.261-0.206h-0.399c0.017,0.01,0.034,0.02,0.05,0.031c-0.044,0.097-0.143,0.17-0.285,0.153
							c-0.077-0.009-0.118-0.113-0.197-0.131c-0.313-0.073-0.587,0.229-0.876,0.131c0.002-0.148,0.169-0.13,0.265-0.184H8.928v1.417
							c0.041,0.003,0.081,0.004,0.117-0.005c0.176-0.043,0.364-0.257,0.526-0.066c-0.011,0.117-0.236,0.199-0.373,0.263
							c-0.038,0.073,0.011,0.097,0,0.197c-0.085,0.036-0.171,0.085-0.26,0.132h0.7v0.14c0.022-0.009,0.045-0.018,0.064-0.032
							c0-0.058,0-0.117,0-0.175c0.088-0.138,0.309-0.304,0.46-0.131c0.012,0.134,0.108,0.184,0.175,0.263
							c-0.045,0.057-0.066,0.139-0.088,0.219c-0.177,0.084-0.455,0.195-0.611,0.081v4.953H8.928v1.938h2.832
							c0.068-0.061,0.19-0.09,0.255-0.019c-0.001,0.006-0.005,0.013-0.007,0.019h2.473c0.023,0,0.044-0.001,0.067-0.002
							c0.02-0.129,0.054-0.253,0.104-0.37c-0.067,0.014-0.136,0.004-0.173-0.049c0.032-0.073,0.157-0.131,0.257-0.119
							c0.276-0.486,0.798-0.816,1.396-0.816H17.191z M16.358,18.359c0.107,0.007,0.174,0.057,0.174,0.178
							c-0.143,0.03-0.207,0.214-0.379,0.164C16.127,18.562,16.336,18.507,16.358,18.359z M15.224,12.393
							c0.019-0.017,0.03-0.043,0.044-0.066c0.071-0.013,0.102,0.015,0.153,0.022c-0.155,0.375-0.694,0.549-1.03,0.811
							c-0.15,0.117-0.366,0.41-0.635,0.219C14.212,13.018,14.797,12.785,15.224,12.393z M13.882,19.865
							c-0.037,0.189-0.365,0.252-0.563,0.186C13.349,19.85,13.704,19.729,13.882,19.865z M13.617,19.472
							c0.074-0.043,0.188,0.001,0.203,0.077C13.747,19.581,13.634,19.554,13.617,19.472z M13.676,19.181h-0.722v-2.033h0.722
							c0.229,0,0.401,0.059,0.515,0.177c0.114,0.118,0.172,0.311,0.172,0.579v0.52c0,0.268-0.057,0.461-0.172,0.579
							C14.077,19.122,13.905,19.181,13.676,19.181z M14.435,13.598c-0.077,0.042-0.12-0.007-0.175-0.044
							C14.281,13.472,14.455,13.507,14.435,13.598z M13.493,12.919c-0.062,0.056-0.303,0.084-0.307-0.044
							C13.216,12.756,13.499,12.802,13.493,12.919z M13.077,13.84c0.054-0.037,0.151-0.024,0.196,0.023h0.32
							c0.229,0,0.401,0.059,0.515,0.177c0.114,0.118,0.172,0.311,0.172,0.579v0.425c0,0.268-0.058,0.461-0.172,0.579
							c-0.115,0.118-0.286,0.177-0.515,0.177h-0.639v-0.781c-0.059-0.023-0.099-0.066-0.096-0.15c0.034-0.001,0.066,0.005,0.096,0.016
							v-1.023h0.119C13.075,13.855,13.077,13.849,13.077,13.84z M13.055,13.555c-0.033,0.096-0.254,0.159-0.307,0.044
							C12.763,13.484,13.008,13.446,13.055,13.555z M12.595,12.218c-0.049,0.146-0.397,0.222-0.46,0.044
							C12.256,12.222,12.49,12.073,12.595,12.218z M11.521,12.744c-0.055,0.105-0.221,0.101-0.285,0.197
							c0.025,0.434-0.469,0.445-0.723,0.263C10.705,12.993,11.111,12.573,11.521,12.744z M11.565,13.796
							c-0.055,0.146-0.36,0.241-0.46,0.088C11.165,13.756,11.448,13.671,11.565,13.796z M10.535,12.284
							c0.112-0.003,0.182,0.037,0.219,0.11c-0.048,0.102-0.152,0.251-0.307,0.175C10.447,12.444,10.518,12.391,10.535,12.284z
							M10.294,12.349c-0.098,0.038-0.244-0.002-0.263-0.11C10.13,12.187,10.278,12.25,10.294,12.349z M9.615,12.985
							c0.048-0.263,0.519-0.405,0.745-0.219C10.302,13.011,9.87,13.08,9.615,12.985z M12.003,14.475c-0.076,0.187-0.147,0.379-0.438,0.35
							c-0.065,0.015-0.073,0.087-0.066,0.175c-0.187,0.172-0.658,0.339-0.92,0.504c-0.051,0.032-0.437,0.417-0.438,0.088
							c-0.001-0.186,0.688-0.287,0.329-0.613c0.187-0.203,0.413-0.424,0.789-0.373c0.091-0.062,0.048-0.184,0.066-0.351
							c0.104-0.078,0.306-0.127,0.416-0.022c0.021,0.108-0.101,0.074-0.088,0.175c0.165,0.05,0.367-0.262,0.548-0.066
							C12.191,14.444,12.086,14.448,12.003,14.475z M12.068,20.663c0.023-0.098,0.194-0.032,0.186,0.061
							C12.168,20.729,12.086,20.73,12.068,20.663z M12.244,13.095c0.083-0.01,0.103,0.043,0.153,0.066
							C12.378,13.242,12.2,13.21,12.244,13.095z M13.015,20.48c-0.027,0.057,0.011,0.074,0.005,0.151
							c-0.193,0.09-0.385,0.265-0.645,0.239c0.015-0.223,0.349-0.149,0.407-0.332c-0.033-0.074-0.148-0.061-0.188-0.127
							c0.048-0.12,0.196-0.049,0.299-0.077c0.133-0.037,0.271-0.205,0.4-0.064C13.288,20.36,13.118,20.428,13.015,20.48z M13.834,20.921
							c-0.165,0.085-0.448,0.206-0.536-0.015c0-0.07,0.073-0.072,0.114-0.104c-0.001-0.044-0.003-0.089-0.004-0.134
							c0.064-0.108,0.228-0.24,0.348-0.112c0.013,0.102,0.087,0.138,0.141,0.196C13.862,20.797,13.849,20.86,13.834,20.921z
							M13.943,19.712c-0.004-0.095,0.05-0.137,0.059-0.22c0.086-0.005,0.14,0.024,0.17,0.078C14.139,19.65,14.063,19.766,13.943,19.712z
							M14.555,19.976c0.031,0.331-0.346,0.351-0.545,0.219c0.141-0.166,0.44-0.497,0.757-0.377C14.728,19.9,14.601,19.901,14.555,19.976
							z M15.223,19.435c0.092-0.034,0.266-0.153,0.35-0.045C15.54,19.502,15.276,19.569,15.223,19.435z M15.687,19.302
							c-0.059-0.005-0.093-0.083-0.154-0.095c-0.241-0.048-0.442,0.19-0.665,0.123c-0.003-0.132,0.174-0.097,0.229-0.175
							c-0.04-0.067-0.047-0.15,0.01-0.201c-0.02-0.066-0.114-0.052-0.122-0.13c0.065-0.051,0.21-0.075,0.284-0.026
							c0.02,0.019,0.021,0.058,0.02,0.099c0.192,0.106,0.428,0.165,0.612,0.281C15.869,19.252,15.796,19.311,15.687,19.302z
							M16.249,19.082c0.148-0.119,0.29-0.289,0.513-0.168c-0.031,0.083-0.134,0.187-0.225,0.259c-0.152,0.118-0.429,0.29-0.565,0.136
							C16.032,19.231,16.143,19.166,16.249,19.082z"/>
						<path fill="currentColor" d="M23.171,22.04c-0.016,0-0.046,0.001-0.089,0.004v2.316c0.005-0.001,0.014-0.002,0.027-0.002
							c0.009,0,0.026,0.001,0.052,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193v-1.785
							c0-0.132-0.021-0.222-0.063-0.271C23.347,22.064,23.274,22.04,23.171,22.04z"/>
						<path fill="currentColor" d="M24.036,20.484h-6.085c-0.024-0.007-0.05-0.007-0.072,0h-1.092c-0.107,0-0.212,0.012-0.312,0.035
							c-0.082-0.025-0.203,0.008-0.243,0.076c-0.525,0.218-0.895,0.735-0.895,1.339v2.56c0,0.801,0.649,1.45,1.45,1.45h7.249
							c0.801,0,1.45-0.649,1.45-1.45v-2.56C25.486,21.133,24.837,20.484,24.036,20.484z M18.577,24.409h-0.159v0.615h-0.84v-0.615h-1.233
							v-0.607l0.803-2.396h1.27v2.309h0.159V24.409z M21.554,25.022h-0.96l-0.2-1.744l-0.191,1.744h-0.976l-0.43-3.618h0.783l0.221,2.282
							l0.225-2.282h0.746l0.214,2.282l0.214-2.282h0.792L21.554,25.022z M24.039,24.756c-0.142,0.177-0.37,0.266-0.686,0.266h-1.076
							v-3.618h1.104c0.299,0,0.517,0.076,0.654,0.228c0.137,0.152,0.207,0.361,0.208,0.626l0.009,1.69
							C24.252,24.309,24.181,24.579,24.039,24.756z"/>
						<polygon fill="currentColor" points="17.578,23.716 17.578,22.072 17.127,23.716 	"/>
					</g>
				)
			case 'icon-eRallyxSupercarsCaps':
				return (
					<g>
						<g>
							<g>
								<path fill="currentColor" d="M15.91,23.389c-1.488,0-2.847-0.567-3.871-1.495l-1.899,0.413c1.365,1.676,3.444,2.749,5.77,2.749
									c3.458,0,6.372-2.372,7.202-5.574l-1.901,0.414C20.326,21.949,18.283,23.389,15.91,23.389z"/>
								<path fill="currentColor" d="M12.87,21.713c0.85,0.633,1.902,1.007,3.04,1.007c1.922,0,3.598-1.069,4.468-2.642l-0.99,0.216
									c-0.804,1.042-2.063,1.715-3.478,1.715c-0.741,0-1.44-0.185-2.053-0.511L12.87,21.713z"/>
								<path fill="currentColor" d="M25.049,13.483l-0.059,0.013l-0.002,0.962l0.273-0.059c0.144-0.031,0.217-0.205,0.218-0.52
									c0.001-0.209-0.028-0.337-0.084-0.383C25.337,13.45,25.222,13.445,25.049,13.483z"/>
								<polygon fill="currentColor" points="22.025,14.154 21.873,16.661 22.268,16.591 			"/>
								<path fill="currentColor" d="M10.018,16.74l-0.121,0.026l-0.003,1.247L9.957,18c0.058-0.013,0.102-0.024,0.132-0.033
									c0.03-0.01,0.065-0.025,0.105-0.046c0.04-0.021,0.07-0.047,0.09-0.078c0.02-0.031,0.04-0.072,0.061-0.123
									c0.021-0.051,0.035-0.114,0.042-0.189c0.007-0.075,0.011-0.164,0.011-0.268c0.001-0.227-0.028-0.378-0.084-0.454
									C10.257,16.732,10.159,16.709,10.018,16.74z"/>
								<path fill="currentColor" d="M1.575,16.804l-0.028,6.793l28.878-6.288l0.028-6.793L1.575,16.804z M5.108,21.016c-0.023,0.153-0.064,0.304-0.122,0.454
									c-0.058,0.15-0.134,0.281-0.228,0.393c-0.094,0.112-0.216,0.212-0.366,0.298c-0.15,0.087-0.324,0.151-0.522,0.194
									c-0.506,0.11-0.866,0.068-1.081-0.127c-0.215-0.195-0.322-0.545-0.321-1.051l0.001-0.505l1.078-0.235l-0.001,0.463
									c-0.001,0.332,0.085,0.48,0.259,0.442c0.08-0.018,0.144-0.064,0.191-0.138c0.047-0.075,0.07-0.165,0.07-0.271
									c0-0.138-0.018-0.251-0.054-0.338c-0.036-0.087-0.09-0.165-0.163-0.235c-0.073-0.069-0.193-0.161-0.36-0.276l-0.46-0.318
									c-0.371-0.254-0.556-0.591-0.555-1.012c0.001-0.458,0.114-0.823,0.338-1.095c0.225-0.272,0.533-0.451,0.925-0.537
									c0.01-0.002,0.02-0.004,0.028-0.006c0.008-0.002,0.063-0.013,0.164-0.036c0.101-0.022,0.231-0.026,0.39-0.012
									c0.159,0.014,0.3,0.064,0.424,0.151c0.124,0.086,0.216,0.224,0.278,0.412c0.061,0.188,0.092,0.428,0.091,0.72l-1.106,0.241
									l-0.005-0.31c-0.002-0.058-0.026-0.105-0.071-0.14c-0.045-0.035-0.096-0.047-0.152-0.035c-0.072,0.016-0.134,0.052-0.184,0.11
									c-0.051,0.058-0.076,0.123-0.076,0.196c0,0.147,0.101,0.284,0.306,0.412l0.658,0.414c0.107,0.067,0.2,0.139,0.279,0.216
									c0.079,0.077,0.142,0.156,0.188,0.237c0.046,0.082,0.084,0.157,0.113,0.225c0.029,0.068,0.048,0.145,0.06,0.23
									c0.011,0.085,0.018,0.149,0.02,0.193c0.002,0.044,0.003,0.101,0.003,0.173C5.143,20.696,5.131,20.863,5.108,21.016z
									M8.232,19.981c-0.001,0.466-0.109,0.844-0.323,1.132c-0.214,0.289-0.547,0.481-1,0.577c-0.461,0.102-0.797,0.056-1.01-0.139
									c-0.213-0.195-0.319-0.526-0.318-0.993l0.009-3.793l1.059-0.231l-0.009,3.693c0,0.004,0,0.012,0,0.023c0,0.056,0,0.1,0.001,0.132
									c0.001,0.032,0.006,0.075,0.015,0.129c0.009,0.054,0.022,0.096,0.04,0.124c0.018,0.029,0.044,0.051,0.079,0.069
									c0.035,0.017,0.078,0.02,0.13,0.009c0.043-0.009,0.081-0.025,0.113-0.047c0.032-0.022,0.058-0.051,0.076-0.086
									c0.019-0.035,0.034-0.069,0.045-0.1c0.011-0.032,0.019-0.072,0.024-0.122c0.004-0.05,0.007-0.088,0.008-0.113
									c0.001-0.026,0.002-0.063,0.002-0.11c0-0.011,0-0.018,0-0.023l0.009-3.693l1.059-0.231L8.232,19.981z M11.423,17.708
									c-0.034,0.188-0.093,0.361-0.176,0.519c-0.083,0.158-0.2,0.289-0.352,0.392c-0.152,0.103-0.338,0.179-0.559,0.227l-0.443,0.096
									l-0.005,2.044L8.816,21.22l0.012-5.16l1.44-0.313c0.19-0.041,0.356-0.051,0.499-0.03c0.142,0.021,0.268,0.077,0.377,0.17
									c0.109,0.093,0.192,0.236,0.248,0.43c0.056,0.194,0.084,0.438,0.083,0.731C11.475,17.3,11.458,17.52,11.423,17.708z
									M14.104,20.069l-2.251,0.49l0.012-5.161l2.18-0.475l-0.002,1.001l-1.071,0.233l-0.003,1.007l1.028-0.224l-0.002,0.969
									l-1.028,0.224l-0.003,1.176l1.142-0.249L14.104,20.069z M17.252,15.908c-0.003,0.062-0.015,0.143-0.034,0.241
									c-0.02,0.098-0.047,0.182-0.082,0.253c-0.035,0.07-0.089,0.143-0.16,0.219c-0.071,0.076-0.158,0.141-0.259,0.195
									c0.101-0.003,0.184,0.015,0.25,0.054c0.066,0.038,0.113,0.094,0.142,0.165c0.029,0.071,0.048,0.139,0.058,0.203
									c0.01,0.064,0.015,0.137,0.015,0.218c-0.002,0.853-0.008,1.502-0.017,1.947L16.1,19.634l0.005-2.012
									c0-0.08-0.007-0.142-0.023-0.186c-0.015-0.044-0.044-0.072-0.087-0.083c-0.042-0.011-0.083-0.015-0.122-0.01
									c-0.039,0.004-0.099,0.015-0.18,0.033l-0.006,2.349l-1.102,0.24l0.012-5.161l1.672-0.364c0.171-0.037,0.318-0.045,0.44-0.023
									c0.122,0.022,0.217,0.065,0.287,0.128c0.07,0.064,0.125,0.156,0.165,0.278c0.04,0.122,0.066,0.247,0.078,0.375
									c0.012,0.128,0.018,0.285,0.017,0.471C17.257,15.766,17.256,15.845,17.252,15.908z M20.372,17.458
									c-0.001,0.449-0.124,0.805-0.368,1.069c-0.244,0.263-0.585,0.443-1.023,0.538c-0.388,0.085-0.704,0.037-0.947-0.142
									c-0.243-0.179-0.364-0.483-0.363-0.91l0.006-2.394c0.001-0.512,0.11-0.918,0.326-1.219c0.216-0.301,0.555-0.501,1.015-0.601
									c0.188-0.041,0.361-0.054,0.52-0.04c0.159,0.014,0.3,0.055,0.422,0.123c0.123,0.068,0.219,0.172,0.289,0.311
									c0.07,0.139,0.105,0.309,0.104,0.51l-0.002,0.91l-1.078,0.235l0.001-0.599c0.001-0.309-0.086-0.444-0.259-0.407
									c-0.103,0.023-0.171,0.071-0.205,0.147c-0.033,0.075-0.05,0.173-0.05,0.294l-0.006,2.439c0,0.106,0.023,0.193,0.069,0.26
									c0.046,0.068,0.107,0.093,0.184,0.077c0.07-0.015,0.131-0.058,0.183-0.126c0.052-0.068,0.078-0.176,0.078-0.323l0.002-0.887
									l1.102-0.24L20.372,17.458z M22.42,18.258l-0.076-0.761l-0.551,0.12l-0.07,0.792l-1.059,0.231l0.57-5.282l1.707-0.371
									l0.533,5.042L22.42,18.258z M26.538,13.886c-0.003,0.062-0.015,0.142-0.034,0.241c-0.02,0.098-0.048,0.182-0.083,0.252
									c-0.036,0.07-0.089,0.144-0.16,0.22c-0.071,0.076-0.158,0.141-0.259,0.195c0.101-0.002,0.185,0.016,0.251,0.054
									c0.066,0.038,0.113,0.094,0.142,0.165c0.029,0.072,0.048,0.139,0.058,0.203c0.01,0.064,0.015,0.136,0.015,0.218
									c-0.002,0.853-0.008,1.502-0.017,1.946l-1.065,0.232l0.005-2.012c0-0.08-0.007-0.142-0.023-0.186
									c-0.015-0.044-0.044-0.072-0.087-0.083c-0.042-0.011-0.083-0.015-0.122-0.011c-0.039,0.004-0.099,0.015-0.18,0.033l-0.006,2.349
									l-1.103,0.24l0.012-5.16l1.672-0.364c0.171-0.037,0.318-0.045,0.44-0.023c0.122,0.022,0.218,0.065,0.287,0.128
									c0.07,0.064,0.125,0.157,0.165,0.278c0.04,0.122,0.066,0.247,0.078,0.375c0.012,0.128,0.018,0.285,0.017,0.471
									C26.543,13.744,26.541,13.823,26.538,13.886z M29.545,15.695c-0.023,0.153-0.064,0.304-0.122,0.454
									c-0.058,0.15-0.134,0.281-0.228,0.393c-0.094,0.112-0.216,0.211-0.366,0.298c-0.15,0.086-0.324,0.151-0.522,0.194
									c-0.506,0.11-0.867,0.068-1.082-0.127c-0.215-0.195-0.322-0.545-0.321-1.05l0.001-0.505l1.078-0.235l-0.001,0.463
									c-0.001,0.333,0.085,0.48,0.259,0.442c0.08-0.017,0.144-0.064,0.19-0.138c0.047-0.075,0.07-0.166,0.071-0.271
									c0-0.138-0.018-0.251-0.053-0.338c-0.036-0.087-0.09-0.165-0.163-0.235c-0.073-0.069-0.193-0.161-0.36-0.276l-0.461-0.317
									c-0.371-0.254-0.556-0.591-0.555-1.012c0.001-0.458,0.114-0.823,0.338-1.095c0.225-0.272,0.533-0.451,0.925-0.537
									c0.01-0.002,0.02-0.004,0.028-0.006c0.008-0.002,0.063-0.014,0.164-0.036c0.101-0.022,0.231-0.026,0.39-0.012
									c0.159,0.014,0.3,0.064,0.424,0.15c0.123,0.087,0.216,0.224,0.278,0.412c0.061,0.188,0.092,0.428,0.091,0.72l-1.106,0.241
									l-0.006-0.31c-0.002-0.058-0.026-0.105-0.071-0.14c-0.045-0.035-0.096-0.047-0.152-0.035c-0.072,0.016-0.134,0.052-0.185,0.11
									c-0.051,0.058-0.076,0.123-0.076,0.197c0,0.147,0.102,0.284,0.306,0.413l0.658,0.413c0.107,0.068,0.2,0.14,0.28,0.216
									c0.079,0.077,0.142,0.156,0.188,0.237c0.046,0.082,0.084,0.157,0.112,0.225c0.029,0.068,0.048,0.145,0.06,0.23
									c0.011,0.085,0.018,0.149,0.02,0.193c0.002,0.044,0.003,0.101,0.003,0.172C29.58,15.375,29.568,15.542,29.545,15.695z"/>
								<path fill="currentColor" d="M15.763,15.505l-0.059,0.013l-0.002,0.962l0.272-0.059c0.145-0.031,0.217-0.205,0.218-0.52
									c0.001-0.209-0.028-0.337-0.084-0.383C16.051,15.471,15.936,15.467,15.763,15.505z"/>
							</g>
							<path fill="currentColor" d="M12.39,14.998c0.801-1.074,2.081-1.77,3.52-1.77c0.771,0,1.496,0.2,2.126,0.551l0.971-0.21
								c-0.859-0.659-1.933-1.053-3.097-1.053c-1.942,0-3.633,1.091-4.494,2.692L12.39,14.998z"/>
						</g>
						<path fill="currentColor" d="M23.473,11.124H20.04C19.469,10.256,18.917,9.4,18.917,9.4l1.609-2.458h-5.364h-0.869h-0.289h-1.303h-1.234V9.93v1.728
							v1.063l-0.684-0.485c-0.958,0.912-1.673,2.075-2.039,3.384l1.918-0.402c0.912-1.987,2.92-3.37,5.246-3.37
							c1.472,0,2.817,0.554,3.837,1.465l1.488-0.433c-0.051-0.062-0.138-0.182-0.248-0.342L23.473,11.124z M18.089,7.55h1.276
							l-1.058,1.616l-0.636-0.929L18.089,7.55z M13.236,11.124h-1.19V7.55h1.19V11.124z M14.351,11.124l-0.512-1.072h-0.415V9.36h0.894
							V8.264h-0.894V7.55h1.36l0.716,0.714v1.072l-0.549,0.549l0.67,1.239H14.351z M17.022,11.124h-1.36l1.139-1.664l0.638,0.97
							L17.022,11.124z M15.735,7.55h1.285l2.397,3.573h-0.349h-0.949L15.735,7.55z"/>
					</g>
				)
			case 'icon-eRallyx1600sCaps':
				return (
					<g>
						<g>
							<path fill="currentColor" d="M6.943,20.265c0.085-0.193,0.144-0.394,0.178-0.602c0.033-0.208,0.05-0.439,0.05-0.695c0-0.1-0.002-0.181-0.005-0.244
								c-0.003-0.062-0.013-0.155-0.03-0.278C7.12,18.323,7.09,18.21,7.047,18.105C7.004,18,6.949,17.884,6.88,17.754
								c-0.068-0.129-0.161-0.26-0.279-0.392c-0.117-0.132-0.255-0.262-0.413-0.39L5.216,16.19c-0.301-0.243-0.452-0.467-0.452-0.674
								c0-0.103,0.037-0.187,0.112-0.253c0.074-0.065,0.165-0.098,0.272-0.098c0.082,0,0.156,0.032,0.223,0.096
								c0.067,0.064,0.102,0.137,0.105,0.219l0.009,0.437l1.63-0.001c0-0.41-0.046-0.757-0.137-1.041
								c-0.092-0.284-0.229-0.506-0.411-0.665c-0.183-0.159-0.391-0.273-0.626-0.341c-0.234-0.068-0.426-0.102-0.575-0.102
								c-0.149,0-0.23,0-0.242,0c-0.012,0-0.026,0-0.041,0c-0.579,0-1.033,0.158-1.362,0.472c-0.33,0.315-0.495,0.794-0.495,1.438
								c0.001,0.592,0.274,1.124,0.823,1.594l0.68,0.588c0.247,0.212,0.424,0.379,0.532,0.499c0.108,0.12,0.189,0.247,0.242,0.381
								c0.053,0.134,0.08,0.298,0.08,0.492c0,0.149-0.034,0.268-0.103,0.36c-0.068,0.091-0.162,0.137-0.281,0.137
								c-0.255,0-0.384-0.234-0.384-0.702l0-0.651l-1.588,0.001l0,0.711c0,0.711,0.159,1.236,0.478,1.576C4.023,21,4.555,21.17,5.3,21.17
								c0.292,0,0.548-0.038,0.769-0.114c0.221-0.076,0.4-0.179,0.539-0.308C6.746,20.619,6.858,20.457,6.943,20.265z"/>
							<path fill="currentColor" d="M8.068,21.567c0.028-0.082,0.048-0.144,0.058-0.186c0.01-0.042,0.014-0.074,0.008-0.095
								c-0.006-0.021-0.024-0.051-0.054-0.09c-0.029-0.039-0.079-0.065-0.148-0.08c-0.028-0.005-0.055-0.008-0.079-0.007
								C7.829,21.11,7.811,21.11,7.798,21.11c-0.066,0-0.217,0.053-0.454,0.159l-0.21,0.1C6.781,21.541,6.58,21.668,6.53,21.75
								c-0.114,0.114-0.171,0.231-0.171,0.349c0,0.089,0.034,0.133,0.102,0.133c0.051,0,0.121-0.026,0.212-0.077l0.341-0.199
								l-0.722,1.567c-0.027,0.06-0.04,0.113-0.04,0.158c0,0.022,0.006,0.039,0.017,0.049c0.048,0.044,0.149,0.076,0.303,0.092
								c0.052,0.006,0.103,0.01,0.153,0.012c0.049,0.002,0.097,0.003,0.143,0.004c0.046,0,0.093-0.015,0.143-0.045
								c0.031-0.02,0.063-0.049,0.097-0.087c0.033-0.038,0.077-0.109,0.132-0.212c0.055-0.103,0.215-0.454,0.481-1.052
								c0.058-0.134,0.141-0.336,0.25-0.605C8.007,21.74,8.039,21.649,8.068,21.567z"/>
							<path fill="currentColor" d="M10.749,21.943c-0.205,0-0.419,0.041-0.64,0.123c-0.101,0.037-0.195,0.079-0.283,0.129
								c-0.089,0.05-0.168,0.099-0.239,0.149c-0.07,0.049-0.106,0.103-0.106,0.163c0,0.028,0.013,0.053,0.039,0.075
								c0.026,0.022,0.057,0.033,0.092,0.033c0.035,0,0.091-0.017,0.166-0.052c0.075-0.035,0.156-0.052,0.244-0.052
								c0.088,0,0.156,0.01,0.206,0.029c0.033,0.011,0.06,0.028,0.081,0.05c0.022,0.022,0.03,0.059,0.025,0.111
								c-0.005,0.052-0.029,0.109-0.072,0.169c-0.081,0.116-0.202,0.22-0.365,0.313c-0.238,0.14-0.416,0.21-0.532,0.21
								c-0.168,0-0.252-0.113-0.252-0.339c0-0.227,0.088-0.463,0.264-0.708c0.07-0.098,0.147-0.191,0.234-0.28
								c0.087-0.089,0.177-0.172,0.27-0.251c0.092-0.078,0.185-0.15,0.278-0.216c0.093-0.066,0.179-0.123,0.26-0.173
								c0.08-0.049,0.152-0.09,0.216-0.122c0.063-0.032,0.108-0.052,0.134-0.061c0.026-0.008,0.053-0.015,0.082-0.019
								c0.028-0.004,0.052-0.017,0.07-0.038c0.042-0.048,0.019-0.111-0.07-0.188c-0.038-0.032-0.094-0.049-0.166-0.049
								c-0.215,0-0.47,0.07-0.765,0.212c-0.139,0.066-0.278,0.145-0.419,0.236c-0.141,0.091-0.277,0.191-0.408,0.298
								c-0.131,0.108-0.255,0.221-0.371,0.34c-0.116,0.12-0.218,0.24-0.307,0.362c-0.089,0.122-0.161,0.245-0.218,0.366
								c-0.056,0.122-0.088,0.22-0.096,0.294c-0.008,0.074-0.011,0.144-0.011,0.209c0,0.065,0.017,0.138,0.051,0.22
								c0.11,0.266,0.401,0.398,0.871,0.398c0.375,0,0.759-0.086,1.154-0.257c0.184-0.079,0.354-0.172,0.51-0.278
								c0.156-0.106,0.291-0.223,0.406-0.351c0.114-0.129,0.172-0.284,0.172-0.466c0-0.159-0.046-0.297-0.138-0.412
								C11.021,22.002,10.899,21.943,10.749,21.943z"/>
							<path fill="currentColor" d="M13.535,17.956h0.653c0.325,0,0.6-0.05,0.824-0.149c0.223-0.099,0.396-0.247,0.518-0.444
								c0.122-0.198,0.207-0.423,0.258-0.677c0.05-0.254,0.075-0.431,0.075-0.787c0-0.413-0.042-0.638-0.126-0.928
								c-0.084-0.29-0.206-0.517-0.368-0.681c-0.161-0.164-0.347-0.282-0.557-0.355c-0.21-0.073-0.455-0.109-0.735-0.109l-2.122,0.001
								l0.002,5.992l1.579-0.001L13.535,17.956z M13.533,15.147l0.178,0c0.207,0,0.352,0.062,0.436,0.186
								c0.084,0.125,0.126,0.22,0.126,0.539c0,0.146-0.005,0.144-0.016,0.247c-0.011,0.103-0.031,0.188-0.062,0.253
								c-0.031,0.066-0.06,0.117-0.089,0.155c-0.029,0.038-0.073,0.066-0.132,0.082c-0.059,0.017-0.111,0.028-0.156,0.032
								c-0.044,0.005-0.109,0.007-0.194,0.007l-0.091,0L13.533,15.147z"/>
							<path fill="currentColor" d="M15.114,21.541c-0.055-0.052-0.117-0.097-0.187-0.133c-0.069-0.037-0.14-0.067-0.21-0.089
								c-0.07-0.023-0.138-0.039-0.203-0.05c-0.065-0.011-0.132-0.016-0.201-0.016c-0.068,0-0.13,0.011-0.183,0.032
								c-0.014-0.015-0.063-0.053-0.145-0.114c-0.083-0.06-0.142-0.091-0.177-0.091c-0.103,0-0.274,0.062-0.513,0.184
								c-0.112,0.056-0.23,0.124-0.356,0.203c-0.125,0.078-0.251,0.163-0.376,0.253c-0.125,0.091-0.245,0.186-0.36,0.285
								c-0.115,0.099-0.218,0.199-0.308,0.299c-0.09,0.099-0.164,0.197-0.222,0.293c-0.057,0.096-0.09,0.185-0.098,0.266
								c-0.007,0.081-0.011,0.164-0.011,0.248c0,0.072,0.016,0.156,0.049,0.25c0.105,0.303,0.427,0.455,0.967,0.455
								c0.431,0,0.851-0.08,1.26-0.239c0.196-0.077,0.379-0.169,0.549-0.277c0.169-0.108,0.319-0.228,0.448-0.361
								c0.129-0.133,0.239-0.286,0.33-0.46c0.091-0.175,0.137-0.348,0.137-0.52C15.303,21.797,15.24,21.659,15.114,21.541z
								M14.491,22.011c-0.02,0.179-0.118,0.365-0.297,0.556c-0.085,0.089-0.182,0.173-0.293,0.251c-0.111,0.079-0.23,0.147-0.358,0.207
								c-0.128,0.059-0.261,0.106-0.4,0.14c-0.138,0.033-0.273,0.051-0.405,0.051c-0.132,0-0.221-0.018-0.269-0.055
								c-0.024-0.018-0.04-0.043-0.049-0.074c-0.008-0.031-0.01-0.072-0.005-0.123c0.005-0.052,0.031-0.113,0.077-0.183
								c0.092-0.137,0.238-0.283,0.437-0.438c0.288-0.225,0.6-0.417,0.937-0.578c0.04-0.01,0.087-0.018,0.142-0.023
								c0.028-0.003,0.056-0.006,0.083-0.008c0.027-0.002,0.057-0.003,0.09-0.003c0.033,0,0.07,0.006,0.111,0.017
								C14.439,21.79,14.505,21.878,14.491,22.011z"/>
							<path fill="currentColor" d="M9.527,19.897c0.666,0.003,1.157-0.166,1.472-0.506c0.315-0.34,0.472-0.839,0.472-1.495l-0.002-4.069l-1.561,0.001
								l0.002,3.927c0,0.006,0,0.017,0,0.032c0,0.067-0.001,0.119-0.002,0.155c-0.002,0.037-0.005,0.089-0.011,0.157
								c-0.006,0.068-0.018,0.123-0.034,0.164c-0.017,0.041-0.039,0.084-0.066,0.128c-0.028,0.044-0.065,0.077-0.112,0.098
								c-0.047,0.021-0.103,0.032-0.167,0.032c-0.076,0-0.14-0.017-0.192-0.052c-0.052-0.035-0.091-0.075-0.116-0.121
								c-0.026-0.046-0.045-0.108-0.059-0.187c-0.014-0.079-0.021-0.142-0.023-0.187c-0.001-0.046-0.002-0.108-0.002-0.187
								c0-0.015,0-0.026,0-0.032l-0.002-3.928l-1.561,0.001l0.002,4.069c0.001,0.656,0.158,1.154,0.473,1.494
								C8.351,19.732,8.848,19.9,9.527,19.897z"/>
							<polygon fill="currentColor" points="19.623,18.398 17.939,18.399 17.938,17.758 19.454,17.757 19.453,16.522 17.938,16.523 17.937,15.232 
								19.517,15.231 19.516,13.823 16.303,13.825 16.305,19.817 19.624,19.815 		"/>
							<path fill="currentColor" d="M22.275,22.336c-0.038-0.019-0.076-0.029-0.116-0.029c-0.039,0-0.084,0.009-0.132,0.028
								c-0.049,0.018-0.145,0.088-0.29,0.208l-0.248,0.203c-0.206,0.169-0.383,0.281-0.53,0.337c0.043-0.034,0.084-0.07,0.121-0.11
								c0.12-0.125,0.18-0.232,0.18-0.32c0-0.132-0.065-0.272-0.195-0.417c-0.054-0.059-0.109-0.114-0.166-0.164
								c-0.057-0.05-0.104-0.094-0.141-0.132c0.047-0.058,0.07-0.11,0.07-0.156c0-0.046-0.007-0.083-0.021-0.111
								c-0.047-0.096-0.12-0.144-0.219-0.144c-0.155,0-0.312,0.057-0.471,0.17c-0.167,0.12-0.25,0.252-0.25,0.396l-0.224,0.152
								c-0.026,0.019-0.056,0.039-0.092,0.063c-0.036,0.023-0.093,0.066-0.173,0.128c-0.079,0.062-0.122,0.115-0.129,0.159
								c-0.004,0.025-0.006,0.051-0.007,0.079c0,0.151,0.036,0.229,0.107,0.235c0.121,0.005,0.253-0.054,0.396-0.176l0.206-0.174
								c0.068-0.054,0.124-0.108,0.17-0.16c0.022,0.039,0.051,0.079,0.085,0.12c0.034,0.041,0.066,0.083,0.098,0.125
								c0.078,0.103,0.117,0.191,0.117,0.265c0,0.079-0.029,0.118-0.087,0.118c-0.024,0-0.065-0.02-0.123-0.059
								c-0.021-0.014-0.044-0.027-0.07-0.039c-0.025-0.012-0.062-0.018-0.111-0.018c-0.049,0-0.123,0.029-0.222,0.087
								c-0.043,0.025-0.084,0.055-0.124,0.087c-0.041,0.032-0.076,0.065-0.107,0.098c-0.031,0.032-0.056,0.063-0.074,0.094
								c-0.018,0.03-0.028,0.059-0.027,0.086c0,0.028,0.018,0.066,0.052,0.114c0.035,0.049,0.084,0.095,0.149,0.141
								c0.157,0.108,0.342,0.163,0.556,0.163c0.382,0,0.729-0.088,1.043-0.263c0.148-0.084,0.292-0.184,0.431-0.3
								c0.14-0.116,0.283-0.244,0.431-0.384c0.054-0.054,0.095-0.106,0.123-0.157c0.02-0.035,0.037-0.073,0.051-0.115
								c0.014-0.042,0.021-0.085,0.021-0.129C22.331,22.388,22.313,22.356,22.275,22.336z"/>
							<path fill="currentColor" d="M23.615,14.193c-0.103-0.111-0.245-0.2-0.425-0.269c-0.179-0.069-0.395-0.102-0.648-0.102l-2.465,0.001l0.002,5.992
								l1.625-0.001l-0.001-2.037c0.119,0,0.207,0.003,0.265,0.009c0.058,0.006,0.118,0.023,0.181,0.052
								c0.063,0.029,0.105,0.077,0.128,0.143c0.023,0.067,0.034,0.157,0.034,0.269l0.001,1.563l1.57-0.001
								c0.012-0.622,0.018-0.268,0.018-1.467c0-0.115-0.008-0.22-0.023-0.312c-0.015-0.093-0.044-0.193-0.087-0.303
								c-0.043-0.109-0.113-0.201-0.21-0.275c-0.098-0.075-0.221-0.125-0.37-0.152c0.149-0.046,0.276-0.111,0.381-0.196
								c0.105-0.085,0.183-0.172,0.235-0.26c0.052-0.088,0.092-0.198,0.121-0.33c0.029-0.132,0.046-0.242,0.05-0.328
								c0.005-0.087,0.007-0.198,0.007-0.335c0-0.262-0.009-0.484-0.028-0.668c-0.018-0.184-0.057-0.367-0.116-0.551
								C23.8,14.451,23.719,14.304,23.615,14.193z M22.434,15.854c0,0.444-0.106,0.665-0.319,0.665l-0.402,0l-0.001-1.353l0.087,0
								c0.256,0,0.425,0.041,0.509,0.122C22.392,15.371,22.434,15.56,22.434,15.854z"/>
							<polygon fill="currentColor" points="24.875,12.903 24.573,11.965 24.273,12.903 23.333,13.205 24.273,13.506 24.574,14.445 24.876,13.506 
								25.814,13.204 		"/>
							<path fill="currentColor" d="M29.986,13.202l-4.098-1.313l-1.317-4.097l-1.312,4.098l-2.245,0.721l-5.923,0.002c0,0-0.648,0-1.015,0.001L6.35,12.618
								c0,0-0.717-0.064-0.985-0.063H5.082c-0.897,0-1.636,0.272-2.197,0.806c-0.578,0.55-0.871,1.329-0.87,2.315
								c0,0.531,0.129,1.028,0.384,1.486l-0.384,0l0.001,1.922c0,0.03,0.001,0.059,0.003,0.088l0,0.011
								c-0.002,0.025-0.003,0.051-0.003,0.076l0.002,4.108c0.001,1.017,0.828,1.844,1.845,1.844l19.407-0.009
								c1.017,0,1.844-0.828,1.843-1.845l-0.002-4.108c0-0.021-0.001-0.042-0.003-0.068c0.002-0.151,0.002-0.387,0.002-0.837
								c0-0.181-0.013-0.352-0.039-0.509c-0.023-0.138-0.06-0.28-0.11-0.426l0.927-2.892L29.986,13.202z M24.575,16.647
								c-0.092,0.251-0.343,0.72-0.293,0.655c0.038,0.063,0.071,0.129,0.098,0.199c0.058,0.149,0.099,0.294,0.122,0.43
								c0.021,0.127,0.031,0.266,0.031,0.415c0,0.466-0.001,0.701-0.002,0.85c0.001,0.018,0.003,0.036,0.003,0.055l0.002,4.109
								c0,0.699-0.567,1.266-1.266,1.267L3.862,24.634c-0.699,0-1.267-0.566-1.267-1.266L2.594,19.26c0-0.023,0.002-0.046,0.004-0.068
								c-0.001-0.036-0.004-0.07-0.004-0.107L2.593,17.74l1.03-0.001c-0.683-0.591-1.03-1.285-1.031-2.064
								c0-0.821,0.232-1.459,0.691-1.896c0.45-0.429,1.055-0.647,1.799-0.647l0.283,0c0.211,0,0.458,0.041,0.753,0.127
								c0.3,0.087,0.571,0.233,0.81,0.43l0-0.494l4.392-0.002l0.781,0l1.975-0.001c0.349,0,0.667,0.048,0.942,0.144
								c0.239,0.083,0.457,0.209,0.651,0.373l0-0.517l3.775-0.002l0.705,0l0.955,0l2.614-0.84l0.854-2.665l0.857,2.665l2.665,0.854
								l-2.664,0.856C25.429,14.059,24.631,16.496,24.575,16.647z"/>
							<path fill="currentColor" d="M18.72,21.406c-0.069-0.037-0.139-0.067-0.21-0.089c-0.071-0.023-0.138-0.039-0.204-0.049
								c-0.065-0.011-0.132-0.016-0.2-0.016c-0.068,0-0.13,0.011-0.184,0.032c-0.014-0.015-0.062-0.054-0.145-0.114
								c-0.083-0.06-0.142-0.091-0.177-0.091c-0.103,0-0.275,0.062-0.513,0.184c-0.111,0.057-0.23,0.124-0.356,0.203
								c-0.126,0.078-0.251,0.163-0.376,0.253c-0.125,0.09-0.245,0.185-0.36,0.285c-0.115,0.099-0.217,0.199-0.308,0.299
								c-0.091,0.1-0.164,0.197-0.222,0.293c-0.057,0.096-0.089,0.185-0.097,0.266c-0.008,0.081-0.011,0.164-0.011,0.248
								c0,0.072,0.016,0.156,0.049,0.25c0.105,0.303,0.427,0.455,0.967,0.454c0.431,0,0.851-0.08,1.259-0.24
								c0.196-0.076,0.379-0.169,0.549-0.276c0.17-0.108,0.319-0.228,0.448-0.361c0.129-0.133,0.239-0.286,0.33-0.46
								c0.092-0.175,0.137-0.348,0.137-0.52c0-0.161-0.063-0.3-0.189-0.417C18.851,21.487,18.789,21.443,18.72,21.406z M18.283,22.01
								c-0.02,0.179-0.119,0.365-0.297,0.557c-0.085,0.089-0.183,0.173-0.293,0.251c-0.111,0.078-0.23,0.148-0.358,0.207
								c-0.128,0.06-0.261,0.106-0.4,0.14c-0.139,0.034-0.274,0.051-0.405,0.051c-0.132,0-0.221-0.018-0.269-0.055
								c-0.024-0.018-0.04-0.043-0.049-0.074c-0.009-0.031-0.01-0.072-0.006-0.124c0.005-0.052,0.031-0.113,0.077-0.183
								c0.092-0.137,0.238-0.283,0.437-0.438c0.288-0.225,0.601-0.417,0.937-0.578c0.039-0.01,0.087-0.018,0.142-0.023
								c0.028-0.003,0.056-0.006,0.083-0.008c0.027-0.002,0.057-0.003,0.09-0.003c0.033,0,0.071,0.006,0.112,0.017
								C18.231,21.789,18.297,21.877,18.283,22.01z"/>
						</g>
						<g>
							<path fill="currentColor" d="M17.133,9.946l2.068-3.158h-6.892h-1.117h-0.371H9.147H7.561v6.371h3.068h0.077h0.486h3.511h0.352l0.007-0.012
								l0.008,0.012h4.218L17.133,9.946z M15.035,12.028l-0.319,0.532h-1.018h-1.856h-0.834l-0.414-0.867v0.867H8.16V7.387h1.585h0.848
								h1.347h0.121h2.634l0.339,0.505l0.307-0.505h2.752L16.414,9.95l1.75,2.61H15.39L15.035,12.028z"/>
							<g>
								<rect fill="currentColor" x="8.692" y="7.919" width="1.369" height="4.109"/>
								<polygon fill="currentColor" points="12.665,9.972 12.665,8.74 11.841,7.919 10.278,7.919 10.278,8.74 11.306,8.74 11.306,10 10.278,10 
									10.278,10.795 10.754,10.795 11.343,12.028 12.804,12.028 12.033,10.604 			"/>
								<polygon fill="currentColor" points="17.108,7.919 15.641,7.919 15.161,8.709 15.891,9.777 			"/>
								<polygon fill="currentColor" points="12.851,12.028 14.414,12.028 14.894,11.229 14.16,10.114 			"/>
								<polygon fill="currentColor" points="14.411,7.919 12.934,7.919 15.675,12.028 17.167,12.028 			"/>
							</g>
						</g>
					</g>
				)
			case 'icon-eRallyR5Caps':
				return (
					<g>
						<path fill="currentColor" d="M28.959,18.015c-0.083,0.297-0.364,0.268-0.43,0.903c-0.196,0.169-0.557,0.073-0.813,0.147
							c-0.057,0.247-0.113,0.493-0.17,0.74c0.449,0.411,0.714,0.537,1.147,0.819C28.695,19.888,29.411,18.669,28.959,18.015z"/>
						<path fill="currentColor" d="M25.357,23.53c0.094-0.082,0.701,0.281,0.894-0.017c0.287,0.065,0.393,0.558,0.634,0.146
							c-0.465-0.101-0.486-0.48-0.854-0.641C25.837,23.148,25.118,23.186,25.357,23.53z"/>
						<path fill="currentColor" d="M17.472,8.925c0.533-0.145,0.641,0.413,1.09,0.584c0.352,0.081,0.44-0.261,0.812-0.147c0.01-0.417-0.706-0.859-0.984-0.56
							c0.113,0.08,0.214,0.168,0.269,0.284c-0.449-0.359-0.829-0.68-1.537-0.576C17.238,8.649,17.492,8.701,17.472,8.925z"/>
						<path fill="currentColor" d="M4.007,18.629c0.497,0.322,1.033,0.62,1.44,0.998c0.211-0.193,0.645-0.03,0.755-0.383
							c-0.447-0.323-0.676-0.783-1.204-1.055C4.661,18.325,4.195,18.255,4.007,18.629z"/>
						<path fill="currentColor" d="M3.756,19.239c0.292-0.138,0.731,0.69,1.163,0.267C4.675,19.306,3.6,18.608,3.756,19.239z"/>
						<path fill="currentColor" d="M5.087,17.319c-0.517-0.279-0.704-0.766-1.204-1.055c-0.338,0.135-0.803,0.066-0.991,0.44
							c0.204,0.174,0.37,0.372,0.797,0.406c0.182,0.218,0.351,0.443,0.643,0.593C4.501,17.443,5.005,17.719,5.087,17.319z"/>
						<path fill="currentColor" d="M4.655,9.989C4.383,9.767,4.572,9.256,4.191,9.103c-0.166,0.567-0.236,0.874,0.123,0.918
							c-0.322,0.206-0.585,0.074-0.812,0.147c0.103,0.237,0.469,0.309,0.667,0.487C4.286,10.362,4.618,10.411,4.655,9.989z"/>
						<path fill="currentColor" d="M27.337,21.203c0.218-0.033,0.146-0.299,0.341-0.033c0.042-0.329-0.317-0.68-0.537-0.568
							c0.083-0.384-0.512-0.343-0.643-0.593c-0.222,0.342-0.343,0.303-0.679,0.197c0.412-0.418,0.838-1.261,0.685-2.155
							c-0.083,0.005-0.121,0.057-0.147,0.122c0.01-0.067,0.017-0.135,0.024-0.203c0.055-0.083,0.1-0.154,0.123-0.194
							c-0.002-0.068-0.044-0.095-0.099-0.108c0.005-0.104,0.009-0.209,0.009-0.316c0-0.002,0-0.003,0-0.005
							c0.075-0.04,0.11-0.121,0.225-0.121c0.001-0.183,0.001-0.367,0.001-0.55c-0.304,0.348-0.245-0.725-0.397-1.103
							c-0.074-0.183-0.212-0.285-0.317-0.336c-0.005-0.001-0.009-0.002-0.014-0.003c-0.001-0.002-0.002-0.003-0.003-0.005
							c0.006,0.003,0.011,0.005,0.017,0.008c0.092,0.019,0.237,0.041,0.451,0.062c-0.075-0.129-0.37-0.21-0.133-0.275
							c-0.083-0.22-0.181-0.225-0.293-0.225l0.001-0.277c0.072,0.037,0.134,0.077,0.16,0.089c-0.046-0.216-0.103-0.302-0.16-0.338
							l0.001-0.332c0.067-0.097,0.117-0.185,0.161-0.018c0.192-0.957-0.037-1.282,0.139-2.616c-0.112,0.087-0.208,0.157-0.295,0.217
							l0.001-0.394c0.011,0.008,0.02,0.02,0.027,0.039c0.206-0.042,0.104-0.141-0.027-0.244l0-0.051c0.008,0.007,0.017,0.014,0.027,0.02
							c0.326-0.007-0.265-1.056,0.268-0.688c-0.07-0.111-0.139-0.224-0.132-0.413c-0.348-0.009-0.618,0.276-0.855,0.402l-0.343-0.001
							c0.03-0.149,0.244-0.107,0.398-0.128c-0.757-0.404-0.736,0.073-1.466-0.279c-0.108,0.026-0.133,0.138-0.267,0.137
							c0.054,0.113,0.109,0.227,0.235,0.267l-0.187,0c-0.093-0.104-0.196-0.215-0.267-0.116c-0.051,0-0.101-0.001-0.149-0.005
							c0.058-0.098,0.238-0.206,0.368-0.145c-0.51-0.216-0.639,0.174-1.2-0.141c-0.009,0.003-0.019,0.008-0.031,0.013
							c-0.289-0.146-0.554-0.293-0.905-0.289c0.09,0.076,0.212,0.171,0.35,0.259c-0.168,0.013-0.363,0.054-0.48,0.014
							c-0.211,0.327,0.408,0.285,0.241,0.372c-0.015,0.002-0.032,0.009-0.053,0.022c-0.009,0.003-0.019,0.007-0.03,0.01l-0.133,0
							c-0.004-0.002-0.009-0.004-0.014-0.006c-0.136-0.234-0.287-0.459-0.565-0.605c-0.205-0.013-0.395-0.004-0.561,0.046
							c-0.284-0.048-0.589,0.01-0.887,0.294c0.115,0.407,0.2-0.319,0.534-0.137c-0.186,0.149-0.311,0.361-0.599,0.403l-0.068,0
							c0-0.089,0-0.178,0.001-0.266c-0.143,0.045-0.313,0.215-0.17,0.266l-0.273-0.001c-0.133-0.04-0.222-0.15-0.223-0.405
							c-0.234,0.102-0.445,0.227-0.608,0.403l-0.2-0.001c0.136-0.125,0.296-0.262,0.066-0.378c0.079-0.047,0.148-0.109,0.201-0.197
							c-0.13-0.16-0.402-0.231-0.456-0.438c-0.601,0.15-0.549-0.607-1.301-0.632c-0.079,0.201,0.095,0.242,0.139,0.366
							c-0.106,0.168-0.499-0.123-0.577,0.09c0.335,0.363,0.606,0.767,1.098,1.031c0.267-0.08,0.589-0.072,0.834-0.186
							c-0.039,0.237-0.363,0.023-0.395,0.651c-0.038,0.018-0.075,0.036-0.113,0.054c0.035,0.112,0.071,0.223,0.107,0.334
							c-0.049,0.108-0.015,0.241,0.061,0.351l-0.019,0.209c-0.014-0.007-0.027-0.014-0.04-0.021c-0.066-0.075-0.135-0.147-0.207-0.216
							c-0.01-0.056,0.01-0.117,0.075-0.183c-0.089,0.004-0.165,0.02-0.232,0.047c-0.018-0.015-0.034-0.031-0.052-0.045
							c-0.035-0.027-0.072-0.053-0.108-0.079c0.078-0.054,0.167-0.082,0.259-0.061c-0.135-0.483-0.532-0.503-0.814-0.522
							c-0.097-0.096-0.184-0.199-0.249-0.316c-0.396-0.058-0.459-0.792-0.878-0.535c0.097,0.157,0.217,0.3,0.341,0.44
							c-0.275-0.046-0.622,0.263-0.798,0.24c-0.093-0.189-0.133-0.399-0.185-0.317c-0.046-0.109-0.094-0.216-0.147-0.321
							c0.023,0.146-0.008,0.245-0.062,0.307c-0.001-0.029-0.002-0.057-0.006-0.084c-0.175,0.097-0.282,0.075-0.387,0.019
							c-0.015-0.037-0.022-0.079-0.017-0.128c0.18,0.047,0.29-0.019,0.366-0.139c-0.343,0.004-0.137-0.337-0.48-0.333
							c-0.234,0.053-0.244,0.249-0.264,0.414c-0.108-0.02-0.241-0.019-0.417,0.026c0.014,0.158,0.058,0.25,0.102,0.318
							c0.009,0.076,0.02,0.151,0.033,0.224l-0.196,0c-0.114-0.14-0.211-0.266-0.073-0.266c-0.102-0.014-0.279,0.021-0.484,0.047
							c-0.065-0.123-0.143-0.237-0.244-0.338c-0.133,0.142-0.402-0.034-0.423-0.097c0.037-0.134,0.169-0.115,0.179-0.293
							c-0.468-0.106-0.543-0.815-1.09-0.584c-0.006,0.396,0.125,0.706,0.334,0.967c0.098-0.225,0.603,0.032,0.504,0.227
							c-0.086-0.037-0.153-0.046-0.207-0.043c-0.011-0.01-0.024-0.017-0.035-0.028c-0.019,0.008-0.042,0.023-0.066,0.044
							c-0.083,0.021-0.136,0.038-0.221-0.095c0.013,0.117,0.031,0.231,0.053,0.344c-0.013,0.045-0.012,0.085,0.014,0.111l-0.112,0
							c-0.096-0.108-0.179-0.192-0.2,0h-0.012c-0.333-0.271,0.315-0.112,0.278-0.265c-0.186-0.005-0.409-0.032-0.644-0.06
							c-0.101-0.241-0.217-0.473-0.36-0.688C10.146,9.536,9.992,9.7,9.802,9.766C9.61,9.76,9.426,9.767,9.262,9.799
							C9.224,9.797,9.186,9.796,9.15,9.796c0.003,0.01,0.006,0.019,0.008,0.028c-0.144,0.04-0.27,0.104-0.365,0.2
							C8.668,9.955,8.559,9.914,8.462,9.89C8.378,9.77,8.253,9.706,8.044,9.765C8.012,9.803,7.997,9.842,7.991,9.881
							C7.913,9.891,7.837,9.9,7.757,9.894C7.61,9.558,7.4,9.223,7.093,9.547c0.121,0.045,0.304,0.051,0.293,0.178
							C7.382,9.734,7.38,9.743,7.377,9.752C7.32,9.714,7.26,9.667,7.194,9.608C7.086,9.633,7.061,9.745,6.927,9.744
							c0.025,0.156,0.133,0.077,0.133,0C7.254,9.806,7.047,9.865,7.06,10.02c0.046,0.161,0.126,0.035,0.269,0.001
							c-0.001,0.042,0.001,0.084,0.005,0.128l-0.097,0l0,0.129c-0.165,0.122-0.312,0.227-0.001,0.28l0,0.083
							c-0.118,0.13-0.195,0.381-0.188,0.646c-0.079-0.13-0.187-0.241-0.271-0.367c0.386-0.078-0.16-0.309,0.015-0.553
							c-0.425,0.002-0.635-0.327-0.846-0.194c0.064-0.281-0.232-0.337-0.326-0.52c0.094-0.139,0.224-0.22,0.39-0.244
							c0.214,0.142,0.234,0.872,0.643,0.593C6.578,9.96,6.447,9.952,6.468,9.848c0.26-0.082-0.272-0.425-0.042-0.788
							c-0.138-0.094-0.22-0.224-0.244-0.39c0.182-0.567-0.397-0.656-0.286-1.178c-0.794-0.126-0.433,0.731-1.17,0.733
							c0.306,0.532,0.225,1.307,0.717,1.723c-0.107-0.024-0.175,0.154-0.049,0.211c0.13-0.293,0.128,0.2,0.293,0.179
							c0.042-0.076,0.051-0.208,0.154-0.187c-0.203,0.393-0.048,0.484-0.366,0.139c0.007,0.643,0.536,0.479,0.806,0.852
							c-0.087,0.005-0.209-0.045-0.236,0.057c0.366,0.121,0.193,0.35,0.61,0.251c-0.047,0.18,0.019,0.29,0.139,0.365
							c0.046-0.119,0.194-0.076,0.341-0.033c0.004-0.02,0.006-0.038,0.009-0.057c0.013,0.029,0.028,0.058,0.044,0.085
							c-0.059,0.2-0.117-0.014-0.266-0.001c-0.155,0.987,0.6,0.875,0.129,1.652c0.084,0.019,0.14,0.009,0.178-0.019l0,0.187
							c-0.094,0.026-0.204,0.023-0.311-0.031c0.11,0.442,0.113,0.286-0.135,0.551c0.01,0.189,0.226,0.166,0.444,0.141l0,0.122
							l0.381,0.001c0.247,1.347-0.5,2.15,0.102,2.63c-0.055,0.086-0.109,0.174-0.127,0.299l-0.362-0.001l0,0.016
							c-0.039-0.003-0.087,0.02-0.178,0.098c0.055,0.042,0.115,0.113,0.178,0.199l-0.001,0.452c-0.02,0.026-0.041,0.057-0.062,0.089
							c-0.038-0.073-0.048-0.182-0.216-0.227c-0.258-0.07-0.628,0.075-0.976-0.113c-0.028,0.319,0.283,0.426,0.407,0.65
							c0.229,0.163,0.299,0.427,0.574,0.561c0.001,0.156,0.463,0.146-0.175,0.316c0.103,0.123,0.143,0.311,0.132,0.551
							c0.083,0.012,0.145,0.002,0.19-0.026c0.036,0.019,0.07,0.044,0.104,0.07c-0.007,0.252-0.12,0.627-0.295,0.37
							c0.021,0.697-0.206,0.694-0.003,1.101c0.184-0.137-0.056-0.227,0.268-0.412c0.018-0.007,0.033-0.011,0.045-0.012l-0.001,0.278
							c-0.03,0.095-0.053,0.166,0,0.115l0,0.148c-0.22,0.038-0.492,0.096-0.312,0.434c0.346-0.187,0.42-0.444,0.266,0.138
							c0.43-0.06,0.723-0.262,1.201-0.273c-0.029-0.114-0.115-0.171-0.182-0.246c-0.006-0.003-0.01-0.007-0.015-0.01h0.006
							c0.003,0.004,0.006,0.007,0.009,0.01c0.351,0.195,1.653-0.078,1.915,0.25c-0.225-0.593,0.843,0.465,1.041-0.254l0.074,0
							c0.216,0.499,1.001,0.408,1.323,0.003l0.218,0.001c0.558,0.612,1.679,0.501,2.509,0.006l0.027,0
							c-0.033,0.095-0.127,0.126-0.127,0.256c0.098-0.06,0.206-0.099,0.32-0.124c-0.027,0.014-0.053,0.029-0.078,0.047
							c0.311,0.167,0.354,0.502,0.748,0.617c0.257,0.025,0.281-0.322,0.601-0.196c0.092,0.244,0.415,0.343,0.667,0.487
							c0.037-0.134,0.169-0.115,0.178-0.293c-0.327-0.206-0.689-0.392-0.981-0.62c0.204,0.03,0.391,0.055,0.54,0.046
							c0.325,0.201,0.661,0.394,0.994,0.59c0.105-0.313,0.678,0.12,0.65-0.407c-0.106-0.039-0.192-0.091-0.267-0.15
							c0.311-0.034,0.632-0.074,0.892-0.053c0.078,0.069,0.165,0.133,0.269,0.186c0.03-0.033,0.064-0.058,0.102-0.078
							c0.08,0.054,0.138,0.129,0.162,0.236c0.239,0.013,0.33-0.128,0.368-0.323c0.046,0.006,0.092,0.012,0.139,0.017
							c-0.023,0.173-0.065,0.328-0.099,0.314c0.11,0.114,0.314,0.089,0.735,0.127c-0.056,0.033-0.111,0.067-0.164,0.104
							c0.32,0.433,0.915,0.694,1.44,0.998c0.273-0.046,0.473-0.208,0.731-0.277c-0.476-0.242-0.629-0.687-1.025-0.98
							c0.178-0.074,0.398-0.158,0.638-0.22c0.492,0.293,0.808,0.696,1.2,1.052c0.209-0.34,0.808-0.058,0.91-0.57
							c-0.415-0.248-0.663-0.365-1.001-0.632c-0.009-0.105-0.056-0.171-0.12-0.219c0.026-0.01,0.051-0.02,0.076-0.03
							c0.026,0.1,0.078,0.187,0.172,0.235c0.042-0.045,0.098-0.075,0.162-0.097c0.172,0.138,0.321,0.289,0.702,0.296
							c0.251,0.394,0.352,0.418,0.797,0.406c-0.096-0.586,0.128-0.019,0.414-0.35c-0.471-0.218-0.546-0.683-1.017-0.901
							c-0.164-0.005-0.309,0.021-0.442,0.066c-0.024-0.006-0.05-0.013-0.076-0.021c0.081-0.052,0.16-0.106,0.237-0.163
							c0.253,0.033,0.522,0.019,0.692-0.196c0.003-0.09-0.084-0.176-0.178-0.259c0.021-0.022,0.041-0.044,0.061-0.067
							c0.023,0.005,0.048,0.005,0.074,0.001c-0.004,0.018-0.008,0.035-0.012,0.053c0.457,0.317,0.796,0.707,1.204,1.055
							c0.021-0.063,0.044-0.121,0.13-0.082c0.005,0.088-0.045,0.209,0.057,0.236c-0.036-0.189,0.538-0.057,0.423,0.097
							c-0.053,0.046-0.549,0.039-0.26,0.163c0.234-0.011,0.393-0.144,0.601-0.196c-0.041-0.359,0.015-0.291,0.227-0.504
							C27.587,21.153,27.334,21.535,27.337,21.203z M22.114,9.934c0.009,0.004,0.018,0.007,0.026,0.011
							c-0.11,0.096-0.184,0.203-0.058,0.24l-0.359-0.001C21.706,9.665,21.922,10.214,22.114,9.934z M15.641,10.142
							c0.023,0.026,0.047,0.053,0.07,0.079c-0.071,0.034-0.139,0.072-0.187,0.131c-0.013-0.003-0.027-0.008-0.04-0.011
							c-0.054-0.013-0.11-0.023-0.165-0.035C15.364,10.191,15.485,10.096,15.641,10.142z M13.749,19.95l0.007-2.752
							c0.001-0.234-0.024-0.424-0.075-0.568c-0.051-0.144-0.132-0.254-0.246-0.329c-0.113-0.074-0.256-0.125-0.428-0.153
							c-0.172-0.028-0.379-0.042-0.622-0.042l-0.004,1.921l0.775,0.002l-0.005,1.921l-4.764-0.011l0.005-1.921l0.704,0.002l0.012-5.271
							l-0.704-0.001l0.005-1.921l5.504,0.013c0.477,0.001,0.909,0.049,1.296,0.143c0.387,0.095,0.713,0.246,0.979,0.454
							c0.265,0.207,0.47,0.479,0.614,0.815c0.144,0.336,0.215,0.746,0.214,1.23c-0.001,0.515-0.106,0.964-0.314,1.346
							c-0.208,0.382-0.512,0.651-0.911,0.806L15.8,15.75c0.751,0.119,1.125,0.581,1.123,1.385l-0.002,0.902l0.551,0.001l-0.005,1.921
							L13.749,19.95z M18.297,21.631l0.089,0c0.037,0.055,0.075,0.12,0.097,0.199C18.414,21.768,18.354,21.7,18.297,21.631z
							M19.164,21.613c-0.017-0.006-0.03-0.012-0.042-0.018c0.017,0.005,0.035,0.009,0.051,0.014
							C19.171,21.611,19.167,21.612,19.164,21.613z M21.54,22.125c-0.061,0.014-0.12,0.033-0.177,0.055
							c0.051-0.075,0.083-0.17,0.118-0.261c0.023,0,0.045,0.001,0.068,0.001c0.029,0,0.057-0.001,0.087-0.001
							C21.602,21.983,21.559,22.058,21.54,22.125z M24.277,19.366c-0.643,0.592-1.551,0.886-2.725,0.884
							c-0.43-0.001-0.82-0.027-1.167-0.079c-0.348-0.052-0.663-0.119-0.944-0.201c-0.281-0.083-0.53-0.175-0.745-0.277
							c-0.215-0.102-0.404-0.2-0.568-0.294l0.006-2.354l2.722,0.006c-0.001,0.274,0.04,0.484,0.122,0.633
							c0.082,0.148,0.252,0.223,0.51,0.223c0.227,0.001,0.385-0.08,0.476-0.243c0.09-0.163,0.136-0.431,0.137-0.803
							c0.001-0.396-0.043-0.684-0.133-0.863c-0.089-0.179-0.256-0.268-0.498-0.269c-0.235,0-0.395,0.07-0.482,0.212
							c-0.086,0.142-0.13,0.301-0.13,0.478l-2.23-0.005l-0.526-0.81l0.445-4.754l6.243,0.015l-0.005,2.272l-3.896-0.009l-0.108,1.218
							c0.204-0.117,0.458-0.212,0.763-0.285c0.305-0.073,0.583-0.11,0.834-0.109c0.454,0.001,0.86,0.072,1.22,0.214
							c0.359,0.142,0.66,0.34,0.902,0.594c0.242,0.255,0.427,0.56,0.555,0.915c0.128,0.356,0.192,0.748,0.191,1.178
							C25.243,17.935,24.92,18.774,24.277,19.366z"/>
						<path fill="currentColor" d="M13.705,13.507c0-0.265-0.056-0.457-0.169-0.574c-0.113-0.117-0.283-0.176-0.51-0.177l-0.634-0.002l-0.004,1.921
							l0.634,0.001c0.227,0.001,0.397-0.058,0.511-0.174c0.114-0.117,0.171-0.308,0.171-0.574L13.705,13.507z"/>
						<path fill="currentColor" d="M25,24.115c-0.021-0.205-0.19-0.776-0.431-0.544c0.303,0.172,0.325,0.52,0.488,0.78c0.247,0.056,0.493,0.113,0.74,0.17
							C25.908,23.964,25.355,24.001,25,24.115z"/>
						<path fill="currentColor" d="M13.691,7.946c0.329,0.618,0.877,0.719,1.48,0.34c-0.463-0.104-0.816-0.56-0.846-0.194
							c-0.341-0.438-0.667-0.155-1.114-0.478C13.099,8.035,13.418,7.955,13.691,7.946z"/>
						<path fill="currentColor" d="M13.757,8.628c-0.269-0.043-0.294-0.238-0.35-0.414c-0.676,0.142-0.558-0.407-1.09-0.584
							c-0.333,0.046-0.745-0.033-0.861,0.359c0.531,0.271,0.573,0.848,1.204,1.055C13.066,8.969,13.487,8.919,13.757,8.628z"/>
						<path fill="currentColor" d="M6.187,13.01c-0.587-0.118-0.872,0.245-1.39,0.237c-0.186,0.81-0.372,1.62-0.558,2.431c0.689,0.413,0.983,1.074,1.66,1.494
							c0.228-0.414-0.402-0.291-0.431-0.544c-0.045-0.36,0.225-0.219,0.308-0.374c-0.235-0.034-0.316-0.164-0.244-0.39
							c-0.398-0.083-0.874-0.116-0.643-0.593c0.061-0.192,0.373,0.017,0.471-0.114C5.328,14.367,6.167,14.15,6.187,13.01z"/>
						<path fill="currentColor" d="M4.089,11.972c0.11-0.257,0.498-0.073,0.626-0.302c0.121,0.045,0.304,0.051,0.293,0.179
							c-0.272-0.049-0.445,0.06-0.52,0.326c0.21,0.05,0.068,0.319,0.374,0.308c0.274,0.052,0.246-0.378,0.52-0.326
							c0.121,0.194,0.544,0.135,0.601-0.196c-0.432-0.242-0.53-0.693-1.147-0.82c-0.045,0.121-0.051,0.304-0.178,0.293
							c-0.229-0.308-1.317,0.006-1.097,0.416C3.644,11.61,4.038,11.897,4.089,11.972z"/>
						<path fill="currentColor" d="M19.845,22.821c0.243,0.672,1.225,0.424,1.83,0.754c0.088-0.005,0.143-0.06,0.154-0.187
							c-0.394-0.326-0.959-0.545-1.358-0.868C20.281,22.65,19.901,22.476,19.845,22.821z"/>
					</g>
                )
            case 'icon-eRallyGrpBRwdCaps':
                return (
                    <g>
	                    <path d="M17.554,22.074h-0.043v0.675h0.2c0.106,0,0.159-0.111,0.159-0.332c0-0.147-0.021-0.241-0.063-0.282
		                    C17.767,22.095,17.682,22.074,17.554,22.074z"/>
	                    <path d="M23.232,22.041c-0.016,0-0.046,0.001-0.089,0.005v2.316c0.005-0.001,0.014-0.002,0.027-0.002
		                    c0.009,0,0.026,0.001,0.051,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193v-1.785
		                    c0-0.132-0.021-0.222-0.063-0.271C23.408,22.065,23.335,22.041,23.232,22.041z"/>
	                    <path d="M24.049,20.483h-7.249c-0.801,0-1.45,0.649-1.45,1.45v2.56c0,0.801,0.649,1.45,1.45,1.45h7.249
		                    c0.801,0,1.45-0.649,1.45-1.45v-2.56C25.498,21.133,24.849,20.483,24.049,20.483z M18.65,22.584
		                    c-0.002,0.043-0.01,0.098-0.025,0.163c-0.014,0.066-0.035,0.121-0.061,0.165C18.539,22.956,18.5,23,18.448,23.042
		                    c-0.052,0.042-0.116,0.075-0.19,0.098c0.074,0.014,0.136,0.039,0.184,0.076c0.048,0.037,0.083,0.083,0.104,0.137
		                    c0.021,0.055,0.036,0.105,0.043,0.151c0.008,0.046,0.011,0.098,0.011,0.156c0,0.598-0.003,1.052-0.009,1.363h-0.783v-1.41
		                    c0-0.056-0.006-0.101-0.017-0.134c-0.011-0.033-0.032-0.057-0.063-0.071c-0.031-0.014-0.061-0.023-0.09-0.026
		                    c-0.029-0.003-0.073-0.005-0.132-0.005v1.647h-0.81v-3.618h1.229c0.126,0,0.233,0.017,0.323,0.051
		                    c0.089,0.034,0.16,0.079,0.211,0.134c0.052,0.055,0.092,0.129,0.122,0.22c0.029,0.092,0.049,0.183,0.058,0.275
		                    c0.009,0.092,0.014,0.203,0.014,0.333C18.653,22.486,18.652,22.541,18.65,22.584z M21.615,25.023h-0.96l-0.2-1.744l-0.191,1.744
		                    h-0.976l-0.43-3.618h0.783l0.221,2.283l0.225-2.283h0.746l0.214,2.283l0.214-2.283h0.792L21.615,25.023z M24.1,24.757
		                    c-0.142,0.177-0.371,0.266-0.686,0.266h-1.076v-3.618h1.103c0.299,0,0.517,0.076,0.654,0.228c0.137,0.152,0.207,0.361,0.208,0.626
		                    l0.009,1.69C24.313,24.31,24.242,24.58,24.1,24.757z"/>
	                    <path d="M13.127,8.91c0.002,0.026,0.006,0.047,0.012,0.063c0.006,0.016,0.013,0.033,0.023,0.051
		                    c0.009,0.018,0.022,0.031,0.038,0.04c0.016,0.009,0.035,0.013,0.057,0.013c0.02,0,0.037-0.004,0.052-0.012
		                    c0.015-0.008,0.027-0.02,0.036-0.036c0.009-0.016,0.016-0.032,0.021-0.046c0.005-0.015,0.009-0.034,0.011-0.058
		                    c0.002-0.024,0.003-0.043,0.004-0.056c0-0.013,0.001-0.031,0.001-0.054c0-0.005,0-0.01,0-0.013V7.805c0-0.006,0-0.019,0.001-0.037
		                    c0.001-0.019,0.001-0.033,0-0.042c0-0.009-0.001-0.023-0.001-0.041c-0.001-0.018-0.002-0.032-0.005-0.042
		                    c-0.003-0.01-0.006-0.022-0.009-0.038c-0.004-0.015-0.009-0.026-0.015-0.034c-0.006-0.008-0.014-0.016-0.022-0.025
		                    c-0.008-0.009-0.019-0.015-0.031-0.019c-0.012-0.004-0.026-0.005-0.042-0.005c-0.022,0-0.041,0.004-0.057,0.011
		                    c-0.016,0.007-0.029,0.019-0.038,0.035c-0.009,0.016-0.017,0.031-0.023,0.044c-0.006,0.013-0.01,0.032-0.013,0.058
		                    c-0.002,0.025-0.003,0.044-0.003,0.055c0,0.011,0,0.029,0,0.055c0,0.004,0,0.008,0,0.011c0,0.003,0,0.006,0,0.009v0.997
		                    c0,0.023,0,0.041,0.001,0.055C13.123,8.865,13.125,8.884,13.127,8.91z"/>
	                    <path d="M10.901,7.752c0-0.101-0.014-0.165-0.043-0.193c-0.029-0.028-0.087-0.042-0.174-0.042h-0.03V7.98h0.137
		                    C10.865,7.98,10.901,7.904,10.901,7.752z"/>
	                    <path d="M18.322,8.107c0.015-0.002,0.033-0.005,0.053-0.011c0.02-0.006,0.036-0.015,0.045-0.028c0.01-0.013,0.02-0.031,0.03-0.053
		                    c0.01-0.022,0.017-0.051,0.021-0.087c0.004-0.035,0.005-0.078,0.005-0.128c0-0.109-0.014-0.185-0.043-0.227
		                    c-0.029-0.043-0.078-0.064-0.149-0.064h-0.061v0.6h0.031C18.285,8.109,18.307,8.108,18.322,8.107z"/>
	                    <path d="M7.086,10.434h12.372v9.33h0.585v-9.33V9.533V6.056H6.502v3.477v0.901v12.124v0.517v0.068h8.012v-0.585H7.086V10.434z
		                     M17.684,7.057h0.726c0.096,0,0.18,0.012,0.251,0.037c0.072,0.025,0.135,0.066,0.191,0.122c0.055,0.056,0.097,0.134,0.126,0.233
		                    c0.029,0.099,0.043,0.22,0.043,0.361c0,0.122-0.008,0.226-0.026,0.312c-0.017,0.087-0.047,0.164-0.088,0.232
		                    c-0.042,0.067-0.101,0.118-0.177,0.152c-0.076,0.034-0.17,0.051-0.282,0.051h-0.223V9.54h-0.54V7.057z M15.124,7.057h0.534v1.777
		                    c0,0.002,0,0.006,0,0.011c0,0.027,0,0.048,0.001,0.064c0.001,0.016,0.003,0.037,0.008,0.064c0.005,0.027,0.011,0.048,0.02,0.064
		                    c0.009,0.016,0.022,0.029,0.04,0.041c0.018,0.012,0.04,0.018,0.066,0.018c0.022,0,0.041-0.004,0.057-0.011
		                    c0.016-0.007,0.029-0.018,0.038-0.034c0.009-0.015,0.017-0.03,0.023-0.044c0.006-0.014,0.01-0.033,0.012-0.056
		                    c0.002-0.023,0.003-0.041,0.004-0.054c0.001-0.012,0.001-0.03,0.001-0.053c0-0.005,0-0.009,0-0.011V7.057h0.534v1.825
		                    c0,0.224-0.054,0.395-0.162,0.511c-0.108,0.116-0.276,0.174-0.504,0.173c-0.232,0.001-0.402-0.057-0.51-0.173
		                    c-0.108-0.116-0.162-0.287-0.162-0.511V7.057z M12.579,7.687c0-0.207,0.056-0.367,0.168-0.481c0.112-0.114,0.28-0.172,0.505-0.175
		                    c0.231,0.003,0.403,0.062,0.515,0.175c0.112,0.114,0.168,0.274,0.168,0.481V8.87c0,0.101-0.013,0.192-0.038,0.275
		                    c-0.026,0.083-0.064,0.156-0.117,0.221c-0.052,0.065-0.122,0.114-0.212,0.15c-0.089,0.035-0.191,0.051-0.307,0.048
		                    c-0.122,0.003-0.227-0.013-0.316-0.048c-0.089-0.035-0.16-0.085-0.212-0.15c-0.052-0.064-0.091-0.138-0.116-0.221
		                    c-0.025-0.083-0.038-0.174-0.038-0.275V7.687z M10.095,7.057h0.843c0.086,0,0.16,0.012,0.222,0.035
		                    c0.061,0.023,0.11,0.054,0.145,0.092c0.035,0.038,0.063,0.088,0.084,0.151c0.02,0.063,0.034,0.126,0.04,0.188
		                    c0.006,0.063,0.01,0.139,0.01,0.229c0,0.047-0.001,0.085-0.002,0.114c-0.002,0.03-0.007,0.067-0.017,0.112
		                    c-0.01,0.045-0.024,0.083-0.041,0.113c-0.018,0.03-0.044,0.06-0.08,0.089c-0.036,0.029-0.08,0.052-0.13,0.067
		                    c0.051,0.009,0.093,0.027,0.127,0.052c0.033,0.025,0.057,0.057,0.072,0.094c0.015,0.037,0.024,0.072,0.03,0.104
		                    c0.005,0.032,0.008,0.067,0.008,0.107c0,0.41-0.002,0.722-0.006,0.935h-0.537V8.572c0-0.039-0.004-0.069-0.012-0.092
		                    c-0.008-0.023-0.022-0.039-0.044-0.049c-0.021-0.01-0.042-0.016-0.062-0.018c-0.02-0.002-0.05-0.003-0.091-0.003v1.13h-0.556V7.057
		                    z M7.535,7.724c0-0.118,0.016-0.22,0.047-0.307c0.031-0.087,0.07-0.155,0.115-0.202C7.742,7.168,7.798,7.13,7.863,7.102
		                    c0.066-0.028,0.125-0.046,0.179-0.054c0.054-0.008,0.112-0.012,0.174-0.012c0.077,0,0.147,0.005,0.209,0.016
		                    c0.062,0.011,0.116,0.028,0.161,0.051c0.045,0.023,0.085,0.049,0.119,0.077C8.741,7.21,8.769,7.245,8.792,7.287
		                    c0.022,0.042,0.041,0.083,0.055,0.125C8.86,7.453,8.871,7.502,8.878,7.56C8.885,7.618,8.89,7.671,8.893,7.721
		                    c0.003,0.05,0.004,0.109,0.004,0.176H8.372V7.701c0-0.04-0.004-0.072-0.013-0.098C8.351,7.577,8.34,7.558,8.325,7.548
		                    C8.31,7.536,8.296,7.529,8.283,7.526C8.271,7.522,8.257,7.52,8.241,7.52c-0.031,0-0.056,0.004-0.076,0.012
		                    C8.146,7.541,8.131,7.551,8.12,7.564C8.109,7.576,8.101,7.599,8.096,7.632C8.091,7.664,8.088,7.695,8.087,7.724
		                    C8.086,7.753,8.085,7.799,8.085,7.861v0.96c0,0.037,0.001,0.067,0.004,0.092c0.003,0.025,0.009,0.052,0.018,0.081
		                    c0.01,0.029,0.025,0.051,0.046,0.066c0.021,0.015,0.049,0.023,0.082,0.023c0.055,0,0.092-0.022,0.11-0.065
		                    c0.018-0.043,0.027-0.113,0.027-0.21V8.522H8.226V8.094h0.665V9.54H8.677L8.583,9.33C8.485,9.489,8.334,9.568,8.13,9.568
		                    c-0.397,0-0.595-0.256-0.595-0.768V7.724z"/>
	                    <path d="M17.178,19.764c0.14-0.094,0.275-0.193,0.393-0.308c0.014-0.013,0.022-0.034,0.032-0.051
		                    c0.054-0.012,0.078,0.009,0.118,0.013c-0.053,0.142-0.178,0.249-0.321,0.347h0.206c0.15-0.287,0.226-0.631,0.226-1.032
		                    c0-0.441-0.081-0.798-0.243-1.07c-0.162-0.272-0.357-0.484-0.586-0.638c-0.229-0.154-0.47-0.26-0.722-0.319
		                    c-0.253-0.059-0.466-0.096-0.639-0.112v-0.118c0.166-0.031,0.361-0.095,0.586-0.189c0.225-0.094,0.44-0.228,0.645-0.402
		                    c0.205-0.173,0.379-0.394,0.521-0.662c0.142-0.268,0.213-0.591,0.213-0.969c0-0.764-0.28-1.343-0.841-1.737
		                    c-0.561-0.394-1.326-0.591-2.297-0.591h-0.547c-0.023,0.019-0.046,0.037-0.068,0.053c-0.205,0.147-0.575,0.36-0.745,0.153
		                    c0.061-0.073,0.158-0.136,0.261-0.206H12.97c0.017,0.01,0.034,0.02,0.05,0.031c-0.044,0.097-0.143,0.17-0.285,0.153
		                    c-0.077-0.009-0.118-0.113-0.197-0.131c-0.313-0.073-0.587,0.229-0.876,0.131c0.002-0.148,0.169-0.13,0.265-0.184H8.916v1.417
		                    c0.041,0.003,0.081,0.004,0.117-0.005c0.176-0.043,0.364-0.257,0.526-0.066c-0.011,0.117-0.236,0.199-0.373,0.263
		                    c-0.038,0.073,0.011,0.097,0,0.197c-0.085,0.036-0.171,0.085-0.26,0.132h0.7v0.14c0.022-0.009,0.045-0.018,0.064-0.032
		                    c0-0.058,0-0.117,0-0.175c0.088-0.138,0.309-0.304,0.46-0.131c0.012,0.134,0.108,0.184,0.175,0.263
		                    c-0.045,0.057-0.066,0.139-0.088,0.219c-0.177,0.084-0.455,0.195-0.611,0.081v4.953H8.916v1.938h2.832
		                    c0.068-0.061,0.19-0.09,0.255-0.019c-0.001,0.006-0.005,0.013-0.007,0.019h2.473c0.023,0,0.044-0.001,0.067-0.002
		                    c0.02-0.129,0.054-0.253,0.104-0.37c-0.067,0.014-0.136,0.004-0.173-0.049c0.032-0.073,0.157-0.131,0.257-0.119
		                    c0.276-0.486,0.798-0.816,1.396-0.816H17.178z M16.345,18.359c0.107,0.007,0.174,0.057,0.174,0.178
		                    c-0.143,0.03-0.207,0.214-0.379,0.164C16.115,18.562,16.324,18.507,16.345,18.359z M15.212,12.394
		                    c0.019-0.017,0.03-0.043,0.044-0.066c0.071-0.013,0.102,0.015,0.153,0.022c-0.155,0.375-0.694,0.549-1.03,0.811
		                    c-0.15,0.117-0.366,0.41-0.635,0.219C14.2,13.018,14.785,12.785,15.212,12.394z M13.869,19.865
		                    c-0.037,0.189-0.365,0.252-0.563,0.186C13.336,19.85,13.692,19.729,13.869,19.865z M13.605,19.473
		                    c0.074-0.043,0.188,0.001,0.203,0.077C13.734,19.581,13.622,19.554,13.605,19.473z M13.664,19.181h-0.722v-2.033h0.722
		                    c0.229,0,0.401,0.059,0.515,0.177c0.114,0.118,0.172,0.311,0.172,0.579v0.52c0,0.268-0.057,0.461-0.172,0.579
		                    C14.064,19.122,13.892,19.181,13.664,19.181z M14.423,13.599c-0.077,0.042-0.12-0.007-0.175-0.044
		                    C14.268,13.473,14.443,13.507,14.423,13.599z M13.481,12.92c-0.062,0.056-0.303,0.084-0.307-0.044
		                    C13.203,12.757,13.487,12.802,13.481,12.92z M13.064,13.84c0.054-0.037,0.151-0.024,0.196,0.023h0.32
		                    c0.229,0,0.401,0.059,0.515,0.177c0.114,0.118,0.172,0.311,0.172,0.579v0.425c0,0.268-0.058,0.461-0.172,0.579
		                    c-0.115,0.118-0.286,0.177-0.515,0.177h-0.639V15.02c-0.059-0.023-0.099-0.066-0.096-0.15c0.034-0.001,0.066,0.005,0.096,0.016
		                    v-1.023h0.119C13.062,13.855,13.065,13.849,13.064,13.84z M13.042,13.555c-0.033,0.096-0.254,0.159-0.307,0.044
		                    C12.75,13.485,12.996,13.446,13.042,13.555z M12.582,12.218c-0.049,0.146-0.397,0.222-0.46,0.044
		                    C12.244,12.222,12.478,12.073,12.582,12.218z M11.508,12.744c-0.055,0.105-0.221,0.101-0.285,0.197
		                    c0.025,0.434-0.469,0.445-0.723,0.263C10.693,12.994,11.098,12.574,11.508,12.744z M11.552,13.796
		                    c-0.055,0.146-0.36,0.241-0.46,0.088C11.153,13.756,11.436,13.671,11.552,13.796z M10.522,12.284
		                    c0.112-0.003,0.182,0.037,0.219,0.11c-0.048,0.102-0.152,0.251-0.307,0.175C10.434,12.444,10.506,12.392,10.522,12.284z
		                     M10.281,12.35c-0.098,0.038-0.244-0.002-0.263-0.11C10.117,12.187,10.265,12.25,10.281,12.35z M9.602,12.985
		                    c0.048-0.263,0.519-0.405,0.745-0.219C10.29,13.012,9.858,13.081,9.602,12.985z M11.99,14.475c-0.076,0.187-0.147,0.379-0.438,0.35
		                    c-0.065,0.015-0.073,0.087-0.066,0.175c-0.187,0.172-0.658,0.339-0.92,0.504c-0.051,0.032-0.437,0.417-0.438,0.088
		                    c-0.001-0.186,0.688-0.287,0.329-0.613c0.187-0.203,0.413-0.424,0.789-0.373c0.091-0.062,0.048-0.184,0.066-0.351
		                    c0.104-0.078,0.306-0.127,0.416-0.022c0.021,0.108-0.101,0.074-0.088,0.175c0.165,0.05,0.367-0.262,0.548-0.066
		                    C12.178,14.444,12.073,14.448,11.99,14.475z M12.055,20.663c0.023-0.098,0.194-0.032,0.186,0.061
		                    C12.155,20.729,12.073,20.73,12.055,20.663z M12.232,13.095c0.083-0.01,0.103,0.043,0.153,0.066
		                    C12.366,13.242,12.188,13.21,12.232,13.095z M13.003,20.481c-0.027,0.057,0.011,0.074,0.005,0.151
		                    c-0.193,0.09-0.385,0.265-0.645,0.239c0.015-0.223,0.349-0.149,0.407-0.332c-0.033-0.074-0.148-0.061-0.188-0.127
		                    c0.048-0.12,0.196-0.049,0.299-0.077c0.133-0.037,0.271-0.205,0.4-0.064C13.275,20.36,13.105,20.429,13.003,20.481z M13.821,20.921
		                    c-0.165,0.085-0.448,0.206-0.536-0.015c0-0.07,0.073-0.072,0.114-0.104c-0.001-0.044-0.003-0.089-0.004-0.134
		                    c0.064-0.108,0.228-0.24,0.348-0.112c0.013,0.102,0.087,0.138,0.141,0.196C13.849,20.797,13.836,20.86,13.821,20.921z
		                     M13.931,19.713c-0.004-0.095,0.05-0.137,0.059-0.22c0.086-0.005,0.14,0.024,0.17,0.078C14.126,19.65,14.051,19.767,13.931,19.713z
		                     M14.542,19.977c0.031,0.331-0.346,0.351-0.545,0.219c0.141-0.166,0.44-0.497,0.757-0.377C14.715,19.9,14.589,19.901,14.542,19.977
		                    z M15.21,19.435c0.092-0.034,0.266-0.154,0.35-0.045C15.527,19.502,15.263,19.57,15.21,19.435z M15.674,19.302
		                    c-0.059-0.005-0.093-0.083-0.154-0.095c-0.241-0.048-0.442,0.19-0.665,0.123c-0.003-0.132,0.174-0.097,0.229-0.175
		                    c-0.04-0.067-0.047-0.15,0.01-0.201c-0.02-0.066-0.114-0.052-0.122-0.13c0.065-0.051,0.21-0.075,0.284-0.026
		                    c0.02,0.019,0.021,0.058,0.02,0.1c0.192,0.106,0.428,0.165,0.612,0.281C15.857,19.253,15.783,19.311,15.674,19.302z M16.236,19.082
		                    c0.148-0.119,0.29-0.289,0.513-0.168c-0.031,0.083-0.134,0.187-0.225,0.259c-0.152,0.118-0.429,0.29-0.565,0.136
		                    C16.02,19.231,16.131,19.167,16.236,19.082z"/>
                        </g>
                )
            case 'icon-eRallyKitcarCaps':
                return (
                    <g>
	                    <path d="M28.576,16.334l1.782-6.649H29.5l-0.376,1.403h-1.484l0.376-1.403h-1.979l-0.376,1.403H23.98l0.376-1.403H4.997
		                    l-1.782,6.649h17.508l0.875-3.265h3.535l-0.875,3.265H28.576z M27.338,13.393h0.808l-0.216,0.808h-0.808L27.338,13.393z
		                     M9.237,11.545H6.702l-0.308,1.151h2.058L8.19,13.673H6.132l-0.477,1.779H4.4l1.309-4.884h4.052L9.237,11.545z M13.146,12.103
		                    c-0.106,0.395-0.365,0.737-0.777,1.026c-0.126,0.088-0.261,0.177-0.406,0.265l-1.79,1.081h2.337l-0.262,0.977H8.481l0.355-1.326
		                    l2.998-1.814l0.093-0.349c0.075-0.279-0.027-0.419-0.306-0.419H9.586l0.523-0.977h2.052c0.488,0,0.813,0.111,0.975,0.331
		                    c0.162,0.221,0.177,0.576,0.046,1.064L13.146,12.103z M16.796,12.33h-0.502l0.134-0.502h0.502L16.796,12.33z M18.611,14.201h-0.808
		                    l0.216-0.808h0.808L18.611,14.201z M19.485,10.938h-0.808l0.216-0.808h0.808L19.485,10.938z M21.62,13.065h-1.972l0.529-1.972
		                    h1.972L21.62,13.065z M25.663,11.092h1.972l-0.529,1.972h-1.972L25.663,11.092z"/>
	                    <polygon points="5.923,17.431 4.321,19.349 3.693,19.349 4.207,17.431 2.951,17.431 1.643,22.315 2.898,22.315 3.431,20.326 
		                    4.059,20.326 4.608,22.315 5.933,22.315 5.237,19.838 7.249,17.431 	"/>
	                    <polygon points="9.394,18.408 10.859,18.408 9.812,22.315 11.068,22.315 12.115,18.408 13.58,18.408 13.842,17.431 9.656,17.431 	
		                    "/>
	                    <path d="M26.597,20.78c0.298-0.07,0.539-0.212,0.724-0.426c0.185-0.214,0.33-0.514,0.433-0.9l0.168-0.628
		                    c0.131-0.488,0.115-0.843-0.046-1.064c-0.162-0.221-0.487-0.331-0.975-0.331h-2.93l-1.309,4.884h1.256l0.393-1.465h0.977
		                    l0.41,1.465h1.36L26.597,20.78z M26.499,19.454c-0.075,0.279-0.252,0.419-0.531,0.419h-1.395l0.393-1.465h1.395
		                    c0.279,0,0.381,0.14,0.306,0.419L26.499,19.454z"/>
	                    <polygon points="6.483,22.315 7.739,22.315 9.048,17.43 7.792,17.43 	"/>
	                    <path d="M20.379,17.431l-2.525,3.906h-2.031c-0.279,0-0.381-0.14-0.306-0.419l0.303-1.131h0.001l0.258-0.961
		                    c0.075-0.279,0.252-0.419,0.531-0.419h2.233l0.262-0.977h-2.512c-0.488,0-0.873,0.111-1.153,0.331
		                    c-0.28,0.221-0.486,0.576-0.616,1.064l-0.171,0.637H14.65l-0.39,1.455c-0.131,0.488-0.115,0.843,0.046,1.064
		                    c0.162,0.221,0.487,0.331,0.975,0.331h1.94l-0.001,0.001h1.36l0.548-0.872h1.744l0.08,0.872h1.36l-0.54-4.884H20.379z
		                     M19.686,20.535l0.997-1.64l0.119,1.64H19.686z"/>
                    </g>
                )
			case 'icon-eMultiCrosskartCaps':
				return (
					<g>
						<path fill="currentColor" d="M17.269,20.68l-0.238,0c0.058,0.044,0.113,0.091,0.165,0.141L17.269,20.68z"/>
						<path fill="currentColor" d="M16.884,12.648c-0.05,0.049-0.116,0.075-0.189,0.075h-1.533c-0.05,0-0.087,0.01-0.111,0.028
							c-0.024,0.019-0.044,0.056-0.057,0.106l-0.174,0.666c-0.014,0.055-0.008,0.075-0.008,0.077c0,0,0,0,0,0
							c0.001,0,0.018,0.009,0.068,0.009h1.801c0.344,0,0.64-0.082,0.878-0.243c0.239-0.161,0.393-0.386,0.472-0.688l0.156-0.597
							c0.074-0.284,0.06-0.484-0.044-0.594c-0.112-0.12-0.287-0.198-0.52-0.232l-0.818-0.114c-0.103-0.008-0.151-0.071-0.17-0.107
							c-0.028-0.055-0.033-0.119-0.014-0.192c0.018-0.067,0.051-0.123,0.099-0.168c0.052-0.048,0.118-0.073,0.191-0.073h1.328
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.107l0.177-0.677c0.014-0.054,0.008-0.075,0.007-0.077
							c0,0,0,0,0,0c-0.002,0-0.019-0.009-0.068-0.009h-1.596c-0.347,0-0.644,0.082-0.881,0.242c-0.237,0.161-0.39,0.386-0.469,0.688
							l-0.156,0.597c-0.075,0.284-0.06,0.484,0.042,0.595c0.11,0.12,0.284,0.198,0.517,0.232l0.823,0.12
							c0.095,0.007,0.144,0.058,0.167,0.098c0.029,0.053,0.032,0.123,0.009,0.212C16.961,12.55,16.93,12.604,16.884,12.648z"/>
						<path fill="currentColor" d="M15.496,14.418c-0.005-0.058-0.026-0.074-0.032-0.079c-0.022-0.017-0.06-0.026-0.11-0.026h-1.533
							c-0.06,0-0.111,0.011-0.152,0.034c-0.041,0.023-0.076,0.058-0.108,0.109l-0.835,1.293c-0.125,0.194-0.226,0.365-0.299,0.509
							c-0.071,0.141-0.122,0.267-0.15,0.374l-0.393,1.502c-0.014,0.055-0.008,0.075-0.007,0.077c0.001,0,0.018,0.01,0.068,0.01h0.838
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.106l0.208-0.796c0.011-0.041,0.048-0.07,0.091-0.07h1.009
							c0.029,0,0.057,0.014,0.075,0.037c0.018,0.023,0.024,0.053,0.016,0.082l-0.209,0.796c-0.014,0.054-0.006,0.071-0.006,0.071
							c0,0,0.017,0.015,0.084,0.015h0.86c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.107l0.393-1.502
							c0.028-0.106,0.043-0.23,0.046-0.37c0.003-0.142-0.008-0.312-0.032-0.504L15.496,14.418z M14.54,16.343
							c-0.018,0.019-0.042,0.029-0.068,0.029h-0.895c-0.034,0-0.065-0.018-0.082-0.047c-0.017-0.029-0.017-0.065,0-0.094l0.494-0.865
							c0.035-0.051,0.065-0.082,0.095-0.104c0.037-0.027,0.078-0.04,0.121-0.04h0.142c0.05,0,0.094,0.02,0.125,0.055
							c0.025,0.029,0.041,0.068,0.051,0.119c0.001,0.004,0.001,0.008,0.001,0.012l0.041,0.865C14.568,16.299,14.558,16.324,14.54,16.343z
							"/>
						<path fill="currentColor" d="M13.337,13.612c0.233-0.054,0.426-0.13,0.574-0.224c0.144-0.092,0.259-0.205,0.34-0.334
							c0.083-0.134,0.147-0.285,0.19-0.449l0.508-1.939c0.043-0.163,0.058-0.311,0.045-0.442c-0.012-0.121-0.061-0.222-0.15-0.309
							c-0.092-0.09-0.239-0.163-0.437-0.215c-0.206-0.055-0.485-0.082-0.828-0.082c-0.344,0-0.64,0.028-0.879,0.083
							c-0.235,0.054-0.429,0.13-0.577,0.226c-0.145,0.094-0.26,0.208-0.341,0.337c-0.083,0.134-0.147,0.285-0.19,0.449l-0.508,1.94
							c-0.043,0.163-0.058,0.311-0.045,0.442c0.012,0.12,0.061,0.22,0.15,0.305c0.092,0.089,0.241,0.16,0.441,0.213
							c0.208,0.055,0.487,0.082,0.831,0.082C12.806,13.695,13.1,13.667,13.337,13.612z M12.249,12.4l0.401-1.53
							c0.024-0.093,0.082-0.173,0.17-0.237c0.089-0.065,0.238-0.095,0.469-0.095c0.243,0,0.38,0.034,0.445,0.11
							c0.064,0.075,0.083,0.168,0.057,0.27l-0.401,1.53c-0.026,0.097-0.083,0.179-0.171,0.243c-0.089,0.065-0.238,0.095-0.469,0.095
							c-0.243,0-0.38-0.034-0.445-0.11C12.259,12.623,12.214,12.533,12.249,12.4z"/>
						<path fill="currentColor" d="M18.964,14.313h-1.898c-0.05,0-0.087,0.009-0.111,0.028c-0.024,0.019-0.043,0.055-0.057,0.106l-0.965,3.686
							c-0.015,0.056-0.008,0.075-0.007,0.077c0.001,0,0.018,0.01,0.068,0.01h0.878c0.05,0,0.087-0.009,0.111-0.028
							c0.024-0.019,0.043-0.056,0.057-0.106l0.292-1.115c0.011-0.041,0.048-0.07,0.091-0.07h0.798c0.116,0,0.196,0.037,0.235,0.109
							c0.034,0.062,0.037,0.145,0.009,0.254l-0.228,0.87c-0.014,0.055-0.008,0.075-0.007,0.077c0.001,0,0.018,0.01,0.068,0.01h0.883
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.106l0.316-1.206c0.056-0.212,0.046-0.38-0.029-0.501
							c-0.074-0.119-0.193-0.187-0.364-0.206c-0.043-0.005-0.077-0.039-0.083-0.082c-0.005-0.043,0.02-0.084,0.06-0.1
							c0.113-0.043,0.201-0.11,0.267-0.207c0.07-0.102,0.125-0.229,0.164-0.377l0.069-0.262c0.066-0.252,0.049-0.458-0.05-0.61
							C19.6,14.388,19.354,14.313,18.964,14.313z M18.581,15.534l-0.051,0.193c-0.024,0.091-0.065,0.169-0.123,0.231
							c-0.066,0.071-0.163,0.107-0.289,0.107h-0.427c-0.029,0-0.057-0.013-0.075-0.037c-0.018-0.023-0.024-0.053-0.017-0.082l0.191-0.728
							c0.011-0.041,0.048-0.07,0.091-0.07h0.427c0.175,0,0.241,0.074,0.267,0.135C18.604,15.354,18.606,15.438,18.581,15.534z"/>
						<path fill="currentColor" d="M7.7,14.345c-0.002,0-0.019-0.009-0.068-0.009H6.077c-0.144,0-0.246-0.03-0.31-0.092c-0.049-0.047-0.099-0.138-0.057-0.3
							l1.291-4.676c0.03-0.115,0.09-0.205,0.179-0.266c0.085-0.058,0.197-0.088,0.334-0.088h14.761c0.05,0,0.087-0.009,0.111-0.028
							c0.024-0.019,0.043-0.056,0.057-0.106l0.18-0.688c0.014-0.054,0.008-0.075,0.007-0.077h0c-0.002,0-0.019-0.009-0.067-0.009H7.437
							c-0.453,0-0.816,0.091-1.079,0.271c-0.259,0.178-0.44,0.455-0.537,0.825l-1.399,5.091c-0.094,0.358-0.062,0.623,0.093,0.786
							c0.161,0.169,0.468,0.255,0.914,0.255h1.919c0.05,0,0.087-0.01,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.106l0.177-0.677
							C7.707,14.368,7.701,14.348,7.7,14.345C7.7,14.345,7.7,14.345,7.7,14.345z"/>
						<path fill="currentColor" d="M22.111,9.702h-1.596c-0.347,0-0.644,0.082-0.881,0.242c-0.237,0.161-0.39,0.386-0.469,0.688l-0.156,0.597
							c-0.074,0.284-0.06,0.484,0.042,0.595c0.11,0.12,0.284,0.198,0.517,0.232l0.823,0.12c0.095,0.007,0.144,0.057,0.167,0.098
							c0.029,0.053,0.032,0.123,0.009,0.212c-0.016,0.062-0.048,0.116-0.093,0.16c-0.05,0.049-0.116,0.075-0.189,0.075H18.75
							c-0.05,0-0.087,0.01-0.111,0.028c-0.024,0.019-0.044,0.056-0.057,0.106l-0.174,0.666c-0.015,0.056-0.008,0.075-0.007,0.077l0,0
							c0.001,0,0.018,0.01,0.068,0.01h1.801c0.344,0,0.64-0.082,0.878-0.243c0.239-0.161,0.393-0.386,0.472-0.688l0.157-0.597
							c0.074-0.284,0.06-0.484-0.044-0.594c-0.112-0.12-0.287-0.198-0.52-0.232l-0.818-0.114c-0.103-0.008-0.151-0.07-0.17-0.107
							c-0.028-0.055-0.033-0.119-0.014-0.192c0.017-0.067,0.051-0.123,0.099-0.168c0.052-0.048,0.118-0.073,0.191-0.073h1.328
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.106l0.177-0.677c0.014-0.054,0.008-0.075,0.007-0.078
							C22.176,9.713,22.162,9.702,22.111,9.702z"/>
						<path fill="currentColor" d="M27.272,22.886c0.277,0.001,0.504-0.365,0.506-0.817c0.002-0.453-0.221-0.821-0.498-0.822
							c-0.277-0.001-0.504,0.365-0.506,0.817C26.771,22.517,26.994,22.885,27.272,22.886z"/>
						<polygon fill="currentColor" points="26.955,17.84 24.898,17.83 26.257,18.782 	"/>
						<path fill="currentColor" d="M22.676,22.152c-0.331-0.034-0.638,0.32-0.686,0.789c-0.047,0.47,0.183,0.877,0.514,0.911
							c0.331,0.034,0.638-0.32,0.685-0.789C23.237,22.593,23.007,22.185,22.676,22.152z"/>
						<path fill="currentColor" d="M23.323,18.612c-0.472,0.401-1.393,1.211-1.787,1.558l0.512,0.219l1.954,0.129c-0.003,0,0.171-0.145,0.731-0.585
							l-0.606-1.083L23.323,18.612z"/>
						<path fill="currentColor" d="M28.432,16.738l-5.088,0.114l-0.442,0.019l0.225-0.848h0.294c0.231,0,0.441-0.069,0.608-0.201
							c0.163-0.129,0.276-0.308,0.334-0.531l0.181-0.689c0.093-0.358-0.015-0.608-0.124-0.756c-0.16-0.215-0.412-0.334-0.711-0.334
							h-1.594c0.124-0.185,0.218-0.396,0.279-0.63l0.157-0.598c0.135-0.517,0.059-0.877-0.073-1.121c0.14-0.126,0.238-0.292,0.291-0.495
							l0.177-0.677c0.056-0.213,0.04-0.388-0.005-0.527c0.132-0.124,0.225-0.286,0.277-0.482l0.18-0.688
							c0.094-0.359-0.015-0.609-0.124-0.757c-0.16-0.215-0.413-0.333-0.711-0.333H7.437c-0.618,0-1.133,0.138-1.531,0.411
							c-0.423,0.29-0.712,0.72-0.857,1.275l-1.401,5.099c-0.21,0.803,0.04,1.282,0.287,1.541c0.322,0.34,0.812,0.505,1.495,0.505h1.919
							c0.045,0,0.091-0.003,0.136-0.008h0.149l-0.847,3.279c-0.099,0.38,0.035,0.636,0.121,0.753c0.159,0.218,0.413,0.338,0.714,0.338
							h0.001l10.324-0.017l-0.323,0.271l-0.253,0.488L17.127,21.2c-0.226-0.315-0.658-0.571-1.13-0.573
							c-0.842-0.004-1.426,0.703-1.43,1.488c-0.004,0.786,0.504,1.498,1.416,1.502c0.447,0.002,1.059-0.345,1.273-0.791l0.044,0.083
							l1.07,0.117l0.363,0.039l0.411-0.041l0.145-0.015l1.091,0.111c0.084,0.707,0.645,1.51,1.407,1.624
							c0.06,0.009,0.312,0.041,0.421,0.049c0.736-0.014,1.668-0.624,1.743-1.727c0.023-0.337,0.026-0.525-0.08-0.824l0.114-0.149
							l1.179,0.146c0.218,1.371,1.18,1.763,1.838,1.713c0.731-0.056,1.342-1.004,1.347-1.962c0-0.085-0.006-0.169-0.015-0.252
							c0,0-0.044-0.973-0.753-1.422c-0.182-0.133-0.385-0.213-0.596-0.213c-0.201-0.001-0.461-0.016-0.63,0.084l0.732-0.589l-0.319-0.205
							l1.663-2.23V16.738z M7.621,20.019c-0.228,0-0.349-0.1-0.41-0.183c-0.058-0.078-0.117-0.218-0.06-0.435l0.969-3.751H7.458
							c-0.035,0.005-0.072,0.008-0.111,0.008H5.429c-0.575,0-0.974-0.127-1.222-0.387c-0.263-0.277-0.329-0.676-0.195-1.186l1.4-5.094
							c0.123-0.468,0.36-0.827,0.707-1.064c0.335-0.229,0.778-0.345,1.318-0.345h15.125c0.226,0,0.347,0.099,0.409,0.181
							c0.086,0.118,0.107,0.264,0.062,0.437l-0.18,0.688c-0.037,0.142-0.106,0.254-0.204,0.331c-0.079,0.063-0.174,0.1-0.283,0.113
							c0.072,0.035,0.121,0.083,0.153,0.126c0.087,0.118,0.107,0.265,0.062,0.438l-0.177,0.677c-0.038,0.143-0.106,0.254-0.204,0.331
							c-0.099,0.079-0.224,0.119-0.372,0.12c0.08,0.049,0.151,0.107,0.213,0.173c0.21,0.224,0.258,0.557,0.144,0.991l-0.157,0.598
							c-0.105,0.401-0.322,0.714-0.646,0.932c-0.1,0.068-0.208,0.125-0.322,0.171h2.647c0.227,0,0.347,0.099,0.409,0.181
							c0.086,0.118,0.107,0.264,0.062,0.436L24,15.196c-0.037,0.142-0.106,0.254-0.204,0.331c-0.101,0.079-0.227,0.119-0.374,0.119
							h-0.584l-0.378,1.423l-0.579,0.528l0.612-2.304c0.011-0.041,0.048-0.07,0.091-0.07h0.838c0.05,0,0.087-0.009,0.111-0.028
							c0.024-0.019,0.043-0.056,0.057-0.106l0.18-0.688c0.014-0.054,0.008-0.075,0.007-0.078c0,0.002-0.016-0.009-0.068-0.009h-3.009
							c-0.05,0-0.088,0.009-0.111,0.028c-0.024,0.019-0.043,0.055-0.057,0.106l-0.18,0.688c-0.014,0.054-0.008,0.075-0.007,0.077
							c0.001-0.001,0.016,0.009,0.068,0.009h0.838c0.029,0,0.057,0.014,0.075,0.037c0.018,0.023,0.024,0.053,0.016,0.082l-0.658,2.477
							l-0.653,0.088l-0.566,0.779h-8.271c-0.029,0-0.057-0.014-0.075-0.037c-0.018-0.023-0.024-0.053-0.016-0.082l0.565-2.086
							c0.052-0.199,0.04-0.358-0.035-0.473c-0.074-0.113-0.198-0.172-0.377-0.182c-0.034-0.002-0.065-0.022-0.08-0.053
							c-0.015-0.031-0.012-0.067,0.008-0.095l0.928-1.325c0.012-0.016,0.019-0.029,0.023-0.038c-0.006-0.001-0.013-0.001-0.022-0.001
							h-0.878c-0.057,0-0.104,0.01-0.143,0.031c-0.039,0.021-0.076,0.059-0.112,0.113l-0.936,1.328c-0.018,0.025-0.046,0.04-0.077,0.04
							H9.739c-0.029,0-0.057-0.013-0.074-0.037c-0.018-0.023-0.024-0.053-0.017-0.081l0.591-2.317c0-0.006,0.001-0.012,0.003-0.018
							l0.289-1.104c0.056-0.212,0.046-0.38-0.029-0.501c-0.074-0.12-0.193-0.187-0.364-0.207c-0.043-0.005-0.077-0.039-0.083-0.082
							c-0.005-0.043,0.02-0.085,0.06-0.1c0.113-0.043,0.201-0.11,0.267-0.207c0.07-0.102,0.125-0.229,0.164-0.377l0.069-0.262
							c0.066-0.252,0.049-0.458-0.05-0.61c-0.096-0.147-0.343-0.222-0.732-0.222H7.934c-0.05,0-0.087,0.009-0.111,0.028
							C7.799,9.749,7.78,9.785,7.767,9.837l-0.958,3.685C6.794,13.578,6.8,13.597,6.801,13.6c0.001,0.001,0.014,0.01,0.068,0.01h0.883
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.044-0.056,0.057-0.106l0.114-0.437l0.164-0.676c0.01-0.042,0.048-0.072,0.091-0.072
							h0.798c0.116,0,0.195,0.036,0.235,0.108c0.034,0.063,0.037,0.146,0.009,0.254l-0.228,0.87c-0.004,0.015-0.006,0.028-0.008,0.041
							c0,0.004-0.001,0.008-0.002,0.012L7.56,19.507c-0.014,0.056-0.008,0.075-0.007,0.077c0.001,0,0.018,0.01,0.068,0.01h0.883
							c0.05,0,0.087-0.009,0.111-0.028c0.024-0.019,0.043-0.056,0.057-0.106l0.695-2.677c0.011-0.042,0.048-0.071,0.091-0.071h0.752
							c0.118,0,0.176,0.052,0.203,0.095c0.036,0.057,0.042,0.13,0.02,0.216L9.957,18.77c-0.001,0.004-0.002,0.007-0.003,0.01
							c-0.005,0.012-0.009,0.025-0.013,0.04l-0.185,0.689c-0.014,0.055-0.008,0.074-0.007,0.076c0.001,0,0.018,0.01,0.068,0.01h8.988
							l-0.119,0.164l-0.289,0.242L7.621,20.019z M9.448,10.923l-0.051,0.193c-0.024,0.091-0.065,0.168-0.123,0.231
							c-0.066,0.071-0.163,0.107-0.289,0.107H8.558c-0.029,0-0.057-0.013-0.075-0.036c-0.018-0.023-0.024-0.053-0.017-0.082l0.191-0.728
							c0.011-0.041,0.048-0.07,0.091-0.07h0.427c0.175,0,0.242,0.074,0.267,0.135C9.471,10.744,9.473,10.828,9.448,10.923z
							M16.149,23.172c-0.435-0.002-0.786-0.452-0.784-1.006c0.003-0.553,0.358-1,0.793-0.997c0.273,0.001,0.513,0.179,0.654,0.448
							l-0.282,0.107c-0.069-0.061-0.152-0.097-0.243-0.098c-0.237-0.001-0.429,0.242-0.431,0.542c-0.002,0.3,0.189,0.545,0.425,0.546
							c0.131,0.001,0.248-0.074,0.328-0.191l0.269,0.04C16.758,22.922,16.476,23.174,16.149,23.172z M17.163,22.432l-0.859-0.123
							c-0.041-0.002-0.08-0.019-0.109-0.051c-0.01-0.012-0.018-0.025-0.024-0.038v-0.001c-0.003-0.007-0.006-0.015-0.008-0.023
							c-0.001-0.004-0.002-0.009-0.003-0.013c-0.001-0.004-0.002-0.007-0.002-0.011c-0.003-0.023-0.001-0.046,0.007-0.069
							c0.018-0.05,0.059-0.084,0.106-0.096l1.098-0.416l-0.317,0.421l-0.011,0.212L17.163,22.432z M20.719,22.785
							c-0.005,0.028-0.007,0.055-0.01,0.083l-1.598-0.261c-0.04-0.002-0.08-0.02-0.108-0.054c-0.054-0.064-0.046-0.16,0.018-0.214
							c0.041-0.034,0.093-0.043,0.141-0.03l1.607,0.263C20.748,22.641,20.731,22.712,20.719,22.785z M20.805,22.468l-0.872-0.663
							c-0.035-0.021-0.061-0.055-0.07-0.098c-0.018-0.082,0.034-0.163,0.116-0.181c0.052-0.011,0.103,0.005,0.138,0.039l0.825,0.627
							C20.889,22.279,20.842,22.371,20.805,22.468z M23.416,23.185c-0.119,0.713-0.686,1.212-1.268,1.115
							c-0.581-0.097-0.957-0.752-0.838-1.465c0.119-0.712,0.686-1.212,1.267-1.115C23.159,21.817,23.534,22.473,23.416,23.185z
							M23.355,22.067c-0.194-0.287-0.484-0.493-0.833-0.551c-0.02-0.003-0.04-0.004-0.06-0.006c-0.021-0.005-0.041-0.01-0.062-0.014
							c-0.534-0.088-1.05,0.144-1.372,0.561l-0.836-0.634c-0.074-0.063-0.176-0.093-0.277-0.071c-0.174,0.038-0.284,0.209-0.247,0.383
							c0.014,0.064,0.046,0.119,0.089,0.162h0l0.003,0.002c0.016,0.016,0.035,0.03,0.055,0.042l0.507,0.387l-1.164-0.189
							c-0.095-0.021-0.198,0-0.278,0.067c-0.136,0.115-0.153,0.318-0.039,0.453c0.004,0.004,0.008,0.008,0.012,0.012L18.6,22.696
							l-0.232-0.364v-0.11l1.043-1.054l1.805-1.136l0.127,0.054c0.321-0.283,1.213-1.068,1.745-1.523l-2.979,0.091
							c-0.018,0.025-0.029,0.042-0.029,0.042l-0.9,1.306l1.721,0.036l-1.656,1.03l-1.076,1.106l-0.009,0.137l0.198,0.339l-0.837-0.091
							l-0.227-0.338l0.012-0.157l0.511-1.138l1.018-0.932l0.119,0.002l0.967-1.403c0.007-0.014,0.279-0.468,0.784-0.468
							c0.528,0,2.45-0.072,2.728-0.087c0.174-0.009,0.328-0.008,0.506,0.041c0.06,0.011,0.123,0.034,0.195,0.07h0
							c0.194,0.086,0.423,0.23,0.728,0.471l1.302,0.963l-0.003,0.004l0.157,0.096l-0.49,0.394l-0.378-0.422
							c-0.055-0.042-0.123-0.067-0.195-0.068c-0.176,0-0.32,0.141-0.321,0.315c0,0.042,0.01,0.083,0.025,0.12l0.367,0.458L23.355,22.067z
							M25.59,21.92l-1.399-0.173l0.328-0.264l1.125,0.139C25.618,21.718,25.599,21.818,25.59,21.92z M25.708,20.673l-0.557-0.65
							c-0.027-0.028-0.043-0.065-0.043-0.106c0.001-0.084,0.069-0.151,0.152-0.151c0.053,0.001,0.099,0.028,0.126,0.068l0.547,0.637
							C25.852,20.53,25.776,20.598,25.708,20.673z M27.07,20.747c0.489,0.002,0.883,0.58,0.88,1.29c-0.004,0.71-0.402,1.284-0.892,1.281
							c-0.489-0.002-0.883-0.58-0.88-1.29C26.181,21.319,26.581,20.745,27.07,20.747z M26.338,19.247l-1.493-1.082
							c-0.462-0.347-0.733-0.407-0.946-0.402c-0.563,0.014-1.588,0.047-1.588,0.047l0.485-0.466l5.126-0.221L26.338,19.247z"/>
						<path fill="currentColor" d="M22.246,24.795c-0.009,0-0.022-0.001-0.037-0.002c-0.011,0-0.022,0.002-0.033,0.002
							C22.175,24.795,22.246,24.795,22.246,24.795z"/>
					</g>
				)
			case 'icon-eRallyNr4R4Caps':
				return (
					<g>
						<path fill="currentColor" d="M16.784,11.905l-0.53,0l-0.156,0.904h0.53c0.123,0,0.211-0.031,0.264-0.095c0.053-0.063,0.088-0.142,0.104-0.236
							l0.041-0.24c0.016-0.094,0.009-0.173-0.023-0.237C16.983,11.937,16.906,11.905,16.784,11.905z"/>
						<path fill="currentColor" d="M15.862,17.659l-0.531-0.001l-0.156,0.904l0.53,0c0.123,0,0.21-0.032,0.264-0.095c0.053-0.063,0.088-0.142,0.104-0.236
							l0.041-0.24c0.016-0.094,0.009-0.173-0.023-0.236C16.061,17.691,15.984,17.658,15.862,17.659z"/>
						<path fill="currentColor" d="M26.015,8.753c-0.349-0.409-0.857-0.643-1.393-0.643L9.35,8.11c-0.902,0-1.662,0.646-1.809,1.537L5.571,21.76
							c-0.087,0.533,0.063,1.073,0.411,1.483c0.349,0.411,0.858,0.647,1.397,0.647l15.347,0.001c0.907,0,1.668-0.65,1.81-1.547
							l1.895-12.113C26.515,9.701,26.364,9.162,26.015,8.753z M21.679,23.082c-0.047,0.04-0.115,0.06-0.205,0.06l-1.089,0
							c-0.09,0-0.151-0.02-0.184-0.06c-0.034-0.04-0.042-0.105-0.027-0.194l0.255-1.455l-1.195,0c-0.457,0-0.77-0.095-0.937-0.286
							c-0.168-0.191-0.217-0.486-0.148-0.886l0.602-3.531c0.015-0.089,0.047-0.154,0.094-0.194c0.047-0.04,0.115-0.06,0.204-0.06l1.096,0
							c0.09,0,0.151,0.02,0.184,0.06c0.033,0.04,0.042,0.105,0.027,0.194l-0.509,2.988c-0.013,0.075-0.003,0.134,0.03,0.176
							c0.033,0.043,0.082,0.064,0.148,0.064l0.658,0l0.287-1.998c0.016-0.09,0.047-0.154,0.093-0.194c0.047-0.04,0.116-0.06,0.205-0.06
							h1.089c0.09,0,0.151,0.02,0.184,0.06c0.033,0.04,0.042,0.105,0.027,0.194l-0.796,4.929C21.757,22.978,21.725,23.042,21.679,23.082z
							M17.726,19.007c0.127,0.179,0.165,0.416,0.114,0.713l-0.258,1.496c-0.015,0.089-0.047,0.154-0.093,0.194
							c-0.047,0.041-0.115,0.06-0.205,0.06l-1.096,0c-0.089,0-0.151-0.02-0.184-0.06c-0.033-0.04-0.042-0.105-0.027-0.194l0.186-1.08
							c0.017-0.104,0.011-0.18-0.021-0.23c-0.032-0.05-0.097-0.074-0.196-0.074l-0.99,0l-0.239,1.383
							c-0.015,0.089-0.047,0.154-0.093,0.194c-0.047,0.04-0.115,0.06-0.205,0.06l-1.089,0c-0.09,0-0.151-0.02-0.184-0.06
							c-0.033-0.04-0.042-0.105-0.026-0.194l0.788-4.574c0.015-0.089,0.047-0.154,0.094-0.194c0.047-0.04,0.115-0.06,0.205-0.06l2.355,0
							c0.528,0,0.873,0.11,1.036,0.328c0.163,0.219,0.214,0.502,0.154,0.85l-0.056,0.325c-0.034,0.198-0.093,0.366-0.176,0.505
							c-0.083,0.139-0.203,0.239-0.359,0.3C17.41,18.725,17.599,18.828,17.726,19.007z M20.8,14.14c0.033,0.042,0.082,0.063,0.148,0.063
							l0.658,0l0.287-1.999c0.015-0.089,0.047-0.154,0.093-0.194c0.047-0.04,0.115-0.06,0.205-0.06l1.089,0c0.09,0,0.151,0.02,0.184,0.06
							c0.033,0.04,0.042,0.105,0.026,0.194l-0.7,4.398c-0.016,0.089-0.047,0.154-0.094,0.194c-0.047,0.04-0.115,0.06-0.205,0.06h-1.089
							c-0.09,0-0.151-0.02-0.184-0.06c-0.033-0.04-0.042-0.105-0.026-0.194l0.159-0.925l-1.195,0c-0.457,0-0.77-0.095-0.937-0.286
							c-0.168-0.191-0.217-0.486-0.148-0.886l0.603-3.531c0.015-0.089,0.046-0.154,0.094-0.194c0.047-0.04,0.115-0.06,0.205-0.06l1.096,0
							c0.09,0,0.151,0.02,0.184,0.06c0.033,0.04,0.042,0.105,0.027,0.194l-0.509,2.987C20.757,14.039,20.767,14.098,20.8,14.14z
							M18.673,11.813l-0.056,0.325c-0.034,0.197-0.092,0.366-0.175,0.505c-0.083,0.139-0.203,0.239-0.359,0.3
							c0.25,0.028,0.438,0.132,0.565,0.311c0.127,0.179,0.165,0.416,0.114,0.713l-0.258,1.496c-0.015,0.09-0.047,0.154-0.093,0.194
							c-0.047,0.04-0.116,0.06-0.205,0.06l-1.096,0c-0.09,0-0.151-0.02-0.184-0.06c-0.033-0.04-0.042-0.105-0.027-0.194l0.186-1.08
							c0.018-0.104,0.011-0.18-0.02-0.229c-0.031-0.049-0.097-0.074-0.196-0.074l-0.99,0l-0.238,1.383
							c-0.016,0.09-0.047,0.155-0.094,0.194c-0.047,0.04-0.115,0.059-0.205,0.059h-1.089c-0.09,0-0.151-0.02-0.184-0.06
							c-0.033-0.04-0.042-0.105-0.026-0.194l0.788-4.574c0.015-0.09,0.047-0.154,0.094-0.194c0.047-0.04,0.115-0.06,0.205-0.06l2.355,0
							c0.528,0,0.873,0.109,1.036,0.328C18.682,11.182,18.733,11.465,18.673,11.813z M14.31,10.889l-0.788,4.574
							c-0.015,0.09-0.047,0.154-0.094,0.194c-0.047,0.04-0.115,0.06-0.205,0.06l-1.061,0c-0.09,0-0.151-0.02-0.184-0.06
							c-0.033-0.04-0.042-0.105-0.026-0.194l0.149-0.868l-0.957-1.461l-0.671,3.907c-0.016,0.09-0.047,0.155-0.094,0.195
							c-0.047,0.04-0.115,0.06-0.205,0.06l-1.061,0c-0.09,0-0.151-0.02-0.184-0.06c-0.033-0.04-0.042-0.105-0.027-0.194l1.057-6.153
							c0.016-0.09,0.047-0.154,0.094-0.194c0.047-0.04,0.115-0.06,0.205-0.06h0.736c0.089,0,0.163,0.014,0.223,0.042
							c0.059,0.028,0.113,0.078,0.162,0.148l1.102,1.567l0.259-1.504c0.016-0.09,0.046-0.154,0.094-0.194
							c0.047-0.04,0.115-0.06,0.205-0.06H14.1c0.089,0,0.151,0.02,0.184,0.06C14.317,10.734,14.326,10.799,14.31,10.889z M11.829,16.388
							l1.578,0L9.896,21.47H8.318L11.829,16.388z M25.692,10.113l-1.895,12.114c-0.083,0.527-0.537,0.914-1.071,0.914l-0.177,0
							l0.177-1.084l1.895-12.115L9.35,9.942l-1.97,12.115l12.204,0.001l-0.211,1.084L7.379,23.141c-0.318,0-0.62-0.14-0.826-0.383
							c-0.206-0.242-0.295-0.563-0.243-0.877L8.28,9.766c0.086-0.524,0.539-0.908,1.07-0.909l15.271,0.001
							c0.317,0,0.619,0.139,0.824,0.381C25.652,9.48,25.742,9.8,25.692,10.113z"/>
					</g>
				)
			case 'icon-eRallyH2RwdCaps':
				return (
					<g>
						<polygon fill="currentColor" points="13.97,17.366 12.741,17.366 13.336,14.591 11.15,14.591 9.79,20.975 11.966,20.975 12.57,18.13 13.809,18.13 
							13.204,20.975 15.38,20.975 16.76,14.591 14.564,14.591 	"/>
						<path fill="currentColor" d="M14.721,13.488c-0.027,0.024-0.041,0.048-0.042,0.073c-0.001,0.017,0.017,0.037,0.056,0.058
							c0.065,0.036,0.139,0.054,0.224,0.054c0.112,0,0.197-0.035,0.255-0.105c0.118-0.149,0.226-0.307,0.323-0.475l0.42-0.034
							l-0.113,0.184l-0.092,0.156c-0.026,0.046-0.047,0.084-0.062,0.115c-0.015,0.031-0.024,0.056-0.027,0.075
							c-0.003,0.019,0.002,0.039,0.015,0.059c0.035,0.06,0.116,0.089,0.244,0.089c0.155,0,0.245-0.046,0.268-0.138
							c0.042-0.169,0.114-0.36,0.218-0.576c0.127,0,0.211,0.005,0.249,0.015c0.029,0,0.046-0.004,0.052-0.014
							c0.013-0.045,0.02-0.094,0.02-0.146c0-0.072-0.015-0.125-0.046-0.158c-0.017-0.019-0.049-0.031-0.095-0.035l0.207-0.346
							c0.027-0.045,0.049-0.083,0.066-0.114c0.017-0.031,0.027-0.056,0.03-0.077c0.003-0.02,0-0.039-0.008-0.058
							c-0.008-0.019-0.03-0.028-0.064-0.028c-0.034,0-0.089,0.019-0.164,0.056c-0.04,0.019-0.11,0.093-0.211,0.222
							c-0.049,0.063-0.139,0.189-0.27,0.378l-0.467,0.058l0.152-0.309c0.039-0.08,0.058-0.137,0.058-0.173
							c0-0.018-0.008-0.034-0.023-0.048c-0.034-0.031-0.071-0.047-0.11-0.047c-0.056,0-0.11,0.027-0.164,0.081
							c-0.022,0.023-0.043,0.051-0.066,0.085c-0.022,0.034-0.119,0.189-0.291,0.465l-0.434,0.053c-0.02,0.001-0.038,0.003-0.053,0.007
							c-0.015,0.004-0.029,0.015-0.042,0.033c-0.012,0.017-0.019,0.039-0.019,0.065c0,0.038,0.008,0.072,0.024,0.102
							c0.02,0.037,0.07,0.054,0.15,0.052l0.16-0.013C14.943,13.272,14.832,13.391,14.721,13.488z"/>
						<path fill="currentColor" d="M17.53,12.455c0.027,0.01,0.055,0.015,0.084,0.015c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.045-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.022,0.029-0.043,0.041-0.065c0.011-0.021,0.02-0.042,0.025-0.062
							c0.006-0.02,0.009-0.042,0.009-0.067c0-0.024-0.011-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C17.479,12.432,17.503,12.446,17.53,12.455z"/>
						<path fill="currentColor" d="M23.666,12.454c0.027,0.01,0.055,0.015,0.085,0.014c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.045-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.021,0.029-0.043,0.04-0.064c0.011-0.021,0.02-0.042,0.026-0.063
							c0.006-0.02,0.009-0.043,0.009-0.067c0-0.024-0.01-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C23.615,12.431,23.639,12.444,23.666,12.454z"/>
						<path fill="currentColor" d="M17.064,12.704c-0.083,0.062-0.151,0.138-0.204,0.229c-0.058,0.101-0.088,0.2-0.088,0.298c0,0.106,0.045,0.203,0.135,0.29
							c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035c0.048,0,0.097-0.008,0.147-0.025
							c0.05-0.016,0.102-0.038,0.155-0.066c0.053-0.027,0.106-0.059,0.159-0.096c0.053-0.037,0.105-0.075,0.154-0.115
							c0.05-0.04,0.095-0.081,0.138-0.123c0.013-0.013,0.025-0.026,0.038-0.039c0.03-0.014,0.061-0.035,0.093-0.062l0.101-0.085
							c0.033-0.027,0.061-0.054,0.083-0.079c0.011,0.02,0.025,0.039,0.042,0.059c0.017,0.02,0.032,0.041,0.048,0.061
							c0.038,0.051,0.057,0.094,0.057,0.13c0,0.039-0.014,0.058-0.043,0.058c-0.012,0-0.032-0.01-0.06-0.029
							c-0.01-0.007-0.022-0.014-0.034-0.019c-0.013-0.006-0.031-0.009-0.055-0.009c-0.024,0-0.06,0.014-0.109,0.043
							c-0.021,0.013-0.041,0.027-0.061,0.043c-0.02,0.016-0.037,0.032-0.053,0.048c-0.015,0.016-0.028,0.031-0.037,0.046
							c-0.009,0.015-0.013,0.029-0.013,0.043c0,0.014,0.008,0.032,0.025,0.056c0.017,0.024,0.042,0.047,0.074,0.07
							c0.077,0.053,0.168,0.08,0.273,0.08c0.188,0,0.359-0.043,0.513-0.129c0.073-0.041,0.144-0.09,0.212-0.147
							c0.026-0.022,0.052-0.045,0.079-0.068c-0.002,0.026-0.004,0.051-0.004,0.077c0,0.064,0.03,0.125,0.091,0.182
							c0.07,0.067,0.161,0.1,0.272,0.1c0.118,0,0.25-0.04,0.395-0.121c0.062-0.035,0.135-0.084,0.217-0.145
							c0.003-0.002,0.006-0.005,0.009-0.007c0.013,0.043,0.035,0.086,0.066,0.129c0.068,0.092,0.161,0.138,0.278,0.138
							c0.112,0,0.243-0.037,0.393-0.113c0.141-0.071,0.249-0.148,0.323-0.233c0.013,0.009,0.027,0.018,0.041,0.026
							c0.045,0.026,0.106,0.039,0.182,0.039c0.072,0,0.149-0.026,0.233-0.078c0.011-0.007,0.019-0.015,0.029-0.022
							c0.008,0.002,0.015,0.006,0.023,0.006c0.034,0,0.104-0.049,0.213-0.148l0.102-0.094c0.041-0.036,0.068-0.054,0.08-0.054
							c0.029,0,0.051,0.002,0.066,0.005c0.015,0.004,0.022,0.012,0.022,0.025c0,0.008-0.021,0.034-0.063,0.078
							c-0.049,0.051-0.092,0.109-0.128,0.175c-0.018,0.033-0.033,0.067-0.044,0.1c-0.011,0.034-0.017,0.065-0.017,0.094
							c0,0.029,0.005,0.056,0.016,0.079c0.033,0.079,0.126,0.118,0.278,0.118c0.104,0,0.219-0.03,0.343-0.091
							c0.055-0.026,0.107-0.056,0.157-0.089c0.049-0.033,0.094-0.066,0.135-0.098c0.032-0.025,0.059-0.048,0.084-0.071
							c0.016,0.078,0.058,0.15,0.127,0.217c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035
							c0.048,0,0.097-0.008,0.147-0.025c0.05-0.016,0.102-0.038,0.154-0.065c0.053-0.027,0.106-0.059,0.159-0.096
							c0.051-0.035,0.1-0.072,0.147-0.11c0.008,0.025,0.021,0.052,0.039,0.079c0.017,0.028,0.039,0.054,0.065,0.079
							c0.026,0.025,0.056,0.047,0.091,0.066c0.035,0.019,0.075,0.034,0.119,0.046c0.044,0.011,0.1,0.017,0.167,0.017
							c0.067,0,0.138-0.009,0.213-0.026c0.198-0.044,0.379-0.127,0.541-0.248c0.084-0.06,0.17-0.137,0.258-0.232
							c0.086-0.094,0.128-0.157,0.128-0.189c0-0.056-0.021-0.084-0.064-0.084c-0.043,0-0.114,0.038-0.214,0.115l-0.095,0.074
							c-0.2,0.159-0.374,0.239-0.525,0.239c-0.065,0-0.12-0.02-0.166-0.06c-0.021-0.019-0.037-0.04-0.049-0.064
							c-0.011-0.024-0.017-0.049-0.017-0.074c0-0.025,0.012-0.054,0.035-0.086c0.029-0.039,0.063-0.066,0.104-0.08
							c0.059,0.024,0.134,0.035,0.225,0.035c0.067,0,0.127-0.02,0.181-0.06c0.028-0.021,0.051-0.047,0.069-0.078
							c0.018-0.031,0.027-0.064,0.027-0.098c0-0.035-0.014-0.062-0.042-0.082c-0.012-0.009-0.027-0.016-0.044-0.021
							c-0.017-0.005-0.038-0.008-0.063-0.008c-0.025,0-0.067,0.005-0.126,0.014c-0.137,0.021-0.281,0.066-0.432,0.137
							c-0.08,0.037-0.149,0.076-0.206,0.117c-0.032,0.014-0.067,0.034-0.106,0.063l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075
							c-0.035,0.024-0.069,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045c-0.032,0.011-0.059,0.017-0.083,0.017
							c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073c0.022-0.019,0.05-0.051,0.086-0.095
							c0.019-0.023,0.038-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086c0.017-0.03,0.031-0.059,0.042-0.088
							c0.011-0.029,0.016-0.056,0.016-0.079c0-0.023-0.001-0.043-0.003-0.06c-0.007-0.057-0.061-0.085-0.163-0.085
							c-0.071,0-0.148,0.031-0.23,0.093c-0.082,0.061-0.149,0.136-0.202,0.225c-0.016,0.009-0.032,0.02-0.051,0.033l-0.087,0.061
							c-0.032,0.024-0.095,0.067-0.191,0.129c-0.035,0.022-0.066,0.041-0.093,0.057c-0.027,0.016-0.05,0.027-0.068,0.035
							c-0.018,0.008-0.035,0.012-0.05,0.012c-0.015,0-0.028-0.005-0.041-0.015c-0.012-0.01-0.019-0.022-0.019-0.035
							c0-0.013,0.025-0.045,0.074-0.096l0.069-0.068c0.095-0.093,0.143-0.16,0.143-0.203c0-0.042-0.015-0.08-0.044-0.114
							c-0.013-0.015-0.029-0.028-0.046-0.037c-0.017-0.009-0.032-0.014-0.045-0.014c-0.013,0-0.031,0.001-0.054,0.003l-0.148,0.01
							c-0.024,0.002-0.041,0.003-0.052,0.003c-0.011,0-0.022-0.009-0.033-0.026l-0.014-0.023c-0.021-0.033-0.061-0.05-0.12-0.05
							c-0.067,0-0.147,0.032-0.24,0.096c-0.087,0.059-0.163,0.129-0.228,0.21c-0.031,0.039-0.055,0.075-0.072,0.108
							c-0.038,0.016-0.072,0.025-0.1,0.025c-0.064,0-0.096-0.019-0.096-0.056c0-0.01,0.002-0.02,0.006-0.031
							c0.004-0.011,0.006-0.027,0.006-0.047c0-0.068-0.022-0.124-0.067-0.168c-0.019-0.019-0.048-0.039-0.087-0.058
							c-0.039-0.019-0.084-0.029-0.134-0.029c-0.112,0-0.238,0.022-0.378,0.066c-0.066,0.021-0.14,0.05-0.222,0.089
							c-0.059,0.028-0.119,0.065-0.181,0.111c-0.019,0.004-0.042,0.014-0.071,0.033l-0.073,0.051c-0.026,0.019-0.058,0.046-0.097,0.082
							c-0.039,0.036-0.092,0.075-0.161,0.118c-0.037,0.023-0.076,0.042-0.115,0.058c-0.039,0.016-0.079,0.024-0.121,0.024
							c-0.041,0-0.074-0.016-0.098-0.047c-0.025-0.031-0.037-0.07-0.037-0.117c0-0.047,0.023-0.122,0.07-0.227l0.023-0.051
							c0.005-0.011,0.013-0.027,0.025-0.049l0.047-0.087c0.02-0.036,0.034-0.056,0.043-0.06l0.382-0.001c0.188,0,0.283-0.053,0.283-0.158
							c0-0.044-0.022-0.067-0.065-0.067l-0.496,0.001l0.058-0.142c0.008-0.019,0.011-0.034,0.011-0.045c0-0.026-0.017-0.051-0.051-0.073
							c-0.017-0.01-0.039-0.019-0.064-0.026c-0.025-0.007-0.057-0.01-0.094-0.011c-0.037,0-0.07,0.011-0.097,0.034
							c-0.028,0.023-0.055,0.059-0.082,0.107l-0.064,0.109c-0.017,0.028-0.028,0.043-0.033,0.045l-0.255-0.001
							c-0.046,0-0.081,0.005-0.104,0.015c-0.017,0.006-0.031,0.016-0.042,0.03c-0.011,0.014-0.017,0.036-0.017,0.066
							c0,0.03,0.014,0.057,0.042,0.08c0.028,0.024,0.067,0.035,0.118,0.035l0.135,0c0,0.007-0.006,0.022-0.019,0.046
							c-0.038,0.069-0.074,0.15-0.108,0.243c-0.003,0.001-0.006,0.001-0.009,0.003c-0.024,0.009-0.071,0.043-0.142,0.102l-0.122,0.1
							c-0.101,0.083-0.188,0.138-0.261,0.166c0.022-0.017,0.041-0.035,0.059-0.054c0.059-0.062,0.089-0.114,0.089-0.158
							c0-0.065-0.032-0.133-0.096-0.205c-0.026-0.029-0.054-0.056-0.082-0.081c-0.028-0.025-0.051-0.046-0.069-0.065
							c0.023-0.028,0.034-0.054,0.034-0.076c0-0.023-0.003-0.041-0.01-0.055c-0.023-0.047-0.059-0.071-0.107-0.071
							c-0.077,0-0.154,0.028-0.232,0.083c-0.082,0.059-0.123,0.124-0.123,0.195l-0.085,0.057c-0.039,0.012-0.084,0.035-0.137,0.074
							l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075c-0.035,0.024-0.07,0.046-0.103,0.065c-0.034,0.019-0.066,0.033-0.098,0.045
							c-0.032,0.011-0.059,0.017-0.082,0.017c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073
							c0.022-0.019,0.05-0.051,0.086-0.095c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086
							c0.017-0.03,0.031-0.059,0.042-0.089c0.011-0.029,0.016-0.056,0.016-0.078c0-0.023-0.001-0.043-0.003-0.06
							c-0.007-0.057-0.061-0.085-0.163-0.085C17.223,12.612,17.147,12.643,17.064,12.704z M20.538,13.174l0.001-0.003
							c0-0.012,0.008-0.028,0.025-0.049c0.052-0.061,0.13-0.121,0.235-0.178c0.095-0.052,0.17-0.078,0.226-0.078
							c-0.044,0.05-0.066,0.1-0.066,0.15c0,0.023,0.005,0.047,0.017,0.069c-0.064,0.052-0.136,0.095-0.215,0.129
							c-0.032,0.014-0.066,0.025-0.101,0.035c-0.035,0.01-0.069,0.004-0.101-0.015c-0.013-0.009-0.02-0.028-0.02-0.058V13.174z"/>
						<path fill="currentColor" d="M28.216,12.406l0.002-0.001l-0.871-1.202l-0.003,0.001c-0.012-0.016-0.023-0.034-0.036-0.05
							c-0.292-0.362-0.743-0.569-1.235-0.569H14.362c0.01,0.074,0.017,0.148,0.017,0.225v0.5h11.694c0.581,0,0.952,0.471,0.827,1.053
							l-1.811,8.572c-0.125,0.582-0.698,1.053-1.279,1.053H9.398c-0.582,0-0.952-0.472-0.827-1.053l1.403-6.639H9.233l-1.371,6.487
							c-0.097,0.451-0.014,0.901,0.225,1.256l-0.002,0.001l0.877,1.207l0.002,0c0.009,0.012,0.017,0.025,0.026,0.037
							c0.292,0.362,0.743,0.569,1.235,0.569h14.411c0.919,0,1.792-0.714,1.988-1.628l1.811-8.569
							C28.532,13.206,28.451,12.76,28.216,12.406z"/>
						<path fill="currentColor" d="M5.729,9.739H5.686v0.675h0.2c0.106,0,0.159-0.11,0.159-0.332c0-0.147-0.021-0.241-0.062-0.282
							C5.941,9.76,5.856,9.739,5.729,9.739z"/>
						<path fill="currentColor" d="M11.406,9.705c-0.017,0-0.046,0.001-0.089,0.004v2.316c0.004-0.001,0.014-0.002,0.027-0.002
							c0.009,0,0.026,0.001,0.051,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193V10.05
							c0-0.132-0.021-0.222-0.063-0.271C11.582,9.73,11.51,9.705,11.406,9.705z"/>
						<path fill="currentColor" d="M13.673,12.158v-2.56c0-0.801-0.649-1.45-1.45-1.45H4.974c-0.801,0-1.45,0.649-1.45,1.45v2.56
							c0,0.801,0.649,1.45,1.45,1.45h7.249C13.024,13.608,13.673,12.959,13.673,12.158z M6.825,10.249
							c-0.002,0.043-0.011,0.098-0.025,0.163c-0.015,0.066-0.035,0.121-0.061,0.165c-0.026,0.044-0.065,0.087-0.117,0.129
							c-0.052,0.042-0.116,0.075-0.19,0.098c0.074,0.014,0.136,0.039,0.184,0.076c0.049,0.037,0.083,0.083,0.105,0.137
							c0.021,0.055,0.036,0.105,0.043,0.151c0.008,0.046,0.011,0.098,0.011,0.156c0,0.598-0.003,1.052-0.009,1.363H5.984v-1.41
							c0-0.056-0.006-0.101-0.017-0.134c-0.011-0.033-0.032-0.057-0.064-0.072c-0.031-0.014-0.061-0.023-0.09-0.026
							c-0.029-0.003-0.073-0.005-0.132-0.005v1.647h-0.81V9.069H6.1c0.126,0,0.233,0.017,0.323,0.051c0.089,0.034,0.16,0.079,0.211,0.134
							c0.052,0.055,0.092,0.129,0.122,0.22c0.03,0.092,0.049,0.183,0.058,0.275c0.009,0.092,0.014,0.203,0.014,0.333
							C6.828,10.15,6.827,10.206,6.825,10.249z M9.79,12.687h-0.96l-0.2-1.744l-0.191,1.744H7.462l-0.43-3.618h0.783l0.221,2.282
							l0.225-2.282h0.746l0.214,2.282l0.214-2.282h0.792L9.79,12.687z M11.589,12.687h-1.076V9.069h1.103
							c0.299,0,0.517,0.076,0.654,0.228c0.137,0.152,0.207,0.361,0.208,0.626l0.009,1.69c0,0.362-0.071,0.631-0.213,0.809
							C12.133,12.599,11.904,12.687,11.589,12.687z"/>
						<path fill="currentColor" d="M16.471,21.018h7.326l1.363-6.427h-7.326L16.471,21.018z M18.554,19.396c0.343-0.109,0.676-0.247,0.999-0.413
							c0.323-0.166,0.611-0.367,0.865-0.603c0.254-0.236,0.456-0.51,0.608-0.822c0.152-0.312,0.227-0.672,0.227-1.079
							c0-0.198-0.02-0.352-0.06-0.461c-0.04-0.109-0.109-0.164-0.209-0.164c-0.09,0-0.173,0.042-0.25,0.126
							c-0.077,0.085-0.147,0.191-0.209,0.32s-0.119,0.273-0.171,0.432c-0.052,0.159-0.096,0.313-0.131,0.461h-1.349l0.246-1.116
							c0.278-0.238,0.571-0.423,0.88-0.554c0.308-0.131,0.656-0.197,1.044-0.197c0.238,0,0.463,0.027,0.674,0.082
							c0.211,0.055,0.395,0.14,0.552,0.257c0.156,0.116,0.28,0.263,0.373,0.439c0.092,0.176,0.138,0.386,0.138,0.629
							c0,0.184-0.031,0.365-0.093,0.543c-0.062,0.179-0.171,0.363-0.328,0.554c-0.157,0.191-0.368,0.389-0.634,0.595
							c-0.266,0.206-0.602,0.428-1.01,0.666h1.662l-0.149,1.034h-3.839L18.554,19.396z"/>
  					</g>
				)
			case 'icon-eRallyH3RwdCaps':
				return (
					<g>
						<polygon fill="currentColor" points="13.972,17.366 12.743,17.366 13.338,14.591 11.152,14.591 9.792,20.975 11.968,20.975 12.572,18.13 13.811,18.13 
							13.207,20.975 15.382,20.975 16.762,14.591 14.566,14.591 	"/>
						<path fill="currentColor" d="M16.474,21.018H23.8l1.363-6.427h-7.326L16.474,21.018z M18.775,18.488h1.26c0-0.005-0.004,0.012-0.011,0.052
							c-0.008,0.04-0.015,0.092-0.022,0.156c-0.008,0.065-0.015,0.137-0.022,0.216c-0.008,0.079-0.011,0.156-0.011,0.231
							c0,0.204,0.026,0.349,0.078,0.435c0.052,0.087,0.126,0.13,0.22,0.13c0.104,0,0.203-0.035,0.294-0.104
							c0.092-0.069,0.17-0.156,0.235-0.26c0.064-0.104,0.116-0.219,0.153-0.346c0.037-0.127,0.056-0.249,0.056-0.368
							c0-0.228-0.059-0.398-0.175-0.51c-0.117-0.112-0.26-0.167-0.429-0.167c0.015-0.094,0.033-0.179,0.052-0.253
							c0.015-0.065,0.029-0.123,0.041-0.175c0.012-0.052,0.018-0.08,0.018-0.086c0.164,0,0.302-0.026,0.414-0.078
							c0.112-0.052,0.204-0.124,0.276-0.216c0.072-0.092,0.123-0.201,0.153-0.327c0.03-0.127,0.045-0.262,0.045-0.405
							c0-0.06-0.004-0.121-0.011-0.183c-0.007-0.062-0.021-0.119-0.041-0.171c-0.02-0.052-0.049-0.094-0.086-0.126
							c-0.038-0.032-0.088-0.048-0.153-0.048c-0.134,0-0.265,0.079-0.392,0.238c-0.126,0.159-0.24,0.429-0.339,0.811h-1.282l0.223-1.049
							c0.149-0.094,0.321-0.178,0.514-0.253c0.164-0.064,0.362-0.124,0.593-0.179c0.231-0.054,0.496-0.082,0.794-0.082
							c0.194,0,0.388,0.016,0.581,0.048c0.194,0.032,0.369,0.091,0.526,0.175c0.157,0.085,0.283,0.198,0.38,0.342
							c0.097,0.144,0.145,0.327,0.145,0.551c0,0.169-0.029,0.316-0.086,0.443c-0.057,0.126-0.14,0.239-0.25,0.338
							c-0.109,0.099-0.24,0.189-0.391,0.268c-0.152,0.079-0.324,0.154-0.518,0.223c0.149,0.015,0.288,0.044,0.417,0.089
							c0.129,0.045,0.242,0.107,0.339,0.186c0.097,0.079,0.174,0.176,0.231,0.29c0.057,0.114,0.086,0.25,0.086,0.409
							c0,0.213-0.057,0.412-0.172,0.595c-0.114,0.183-0.277,0.342-0.488,0.476c-0.212,0.134-0.467,0.238-0.768,0.312
							c-0.301,0.074-0.637,0.112-1.01,0.112c-0.353,0-0.663-0.046-0.932-0.138c-0.268-0.092-0.527-0.254-0.775-0.487L18.775,18.488z"/>
						<path fill="currentColor" d="M14.723,13.488c-0.027,0.024-0.041,0.048-0.042,0.073c-0.001,0.017,0.017,0.037,0.056,0.058
							c0.065,0.036,0.139,0.054,0.224,0.054c0.112,0,0.197-0.035,0.255-0.105c0.118-0.149,0.226-0.307,0.323-0.475l0.42-0.034
							l-0.113,0.184l-0.092,0.156c-0.026,0.046-0.047,0.084-0.062,0.115c-0.015,0.031-0.024,0.056-0.027,0.075
							c-0.003,0.019,0.002,0.039,0.015,0.059c0.035,0.06,0.116,0.089,0.244,0.089c0.155,0,0.245-0.046,0.268-0.138
							c0.042-0.169,0.114-0.36,0.218-0.576c0.127,0,0.211,0.005,0.249,0.015c0.029,0,0.046-0.004,0.052-0.014
							c0.013-0.045,0.02-0.094,0.02-0.146c0-0.072-0.015-0.125-0.046-0.158c-0.017-0.019-0.049-0.031-0.095-0.035l0.207-0.346
							c0.027-0.045,0.049-0.083,0.066-0.114c0.017-0.031,0.027-0.056,0.03-0.077c0.003-0.02,0-0.039-0.008-0.058
							c-0.008-0.019-0.03-0.028-0.064-0.028c-0.034,0-0.089,0.019-0.164,0.056c-0.04,0.019-0.11,0.093-0.211,0.222
							c-0.049,0.063-0.139,0.189-0.27,0.378l-0.467,0.058l0.152-0.309c0.039-0.08,0.058-0.137,0.058-0.173
							c0-0.018-0.008-0.034-0.023-0.048c-0.034-0.031-0.071-0.047-0.11-0.047c-0.056,0-0.11,0.027-0.164,0.081
							c-0.022,0.023-0.043,0.051-0.066,0.085c-0.022,0.034-0.119,0.189-0.291,0.465l-0.434,0.053c-0.02,0.001-0.038,0.003-0.053,0.007
							c-0.015,0.004-0.029,0.015-0.042,0.033c-0.012,0.017-0.019,0.039-0.019,0.065c0,0.038,0.008,0.072,0.024,0.102
							c0.02,0.037,0.07,0.054,0.15,0.052l0.16-0.013C14.945,13.272,14.834,13.391,14.723,13.488z"/>
						<path fill="currentColor" d="M17.532,12.455c0.027,0.01,0.055,0.015,0.084,0.015c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.045-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.022,0.029-0.043,0.041-0.065c0.011-0.021,0.02-0.042,0.025-0.062
							c0.006-0.02,0.009-0.042,0.009-0.067c0-0.024-0.011-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C17.482,12.432,17.505,12.446,17.532,12.455z"/>
						<path fill="currentColor" d="M23.668,12.454c0.027,0.01,0.055,0.015,0.085,0.014c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.045-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.021,0.029-0.043,0.04-0.064c0.011-0.021,0.02-0.042,0.026-0.063
							c0.006-0.02,0.009-0.043,0.009-0.067c0-0.024-0.01-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C23.617,12.431,23.641,12.444,23.668,12.454z"/>
						<path fill="currentColor" d="M17.066,12.704c-0.083,0.062-0.151,0.138-0.204,0.229c-0.058,0.101-0.088,0.2-0.088,0.298c0,0.106,0.045,0.203,0.135,0.29
							c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035c0.048,0,0.097-0.008,0.147-0.025
							c0.05-0.016,0.102-0.038,0.155-0.066c0.053-0.027,0.106-0.059,0.159-0.096c0.053-0.037,0.105-0.075,0.154-0.115
							c0.05-0.04,0.095-0.081,0.138-0.123c0.013-0.013,0.025-0.026,0.038-0.039c0.03-0.014,0.061-0.035,0.093-0.062l0.101-0.085
							c0.033-0.027,0.061-0.054,0.083-0.079c0.011,0.02,0.025,0.039,0.042,0.059c0.017,0.02,0.032,0.041,0.048,0.061
							c0.038,0.051,0.057,0.094,0.057,0.13c0,0.039-0.014,0.058-0.043,0.058c-0.012,0-0.032-0.01-0.06-0.029
							c-0.01-0.007-0.022-0.014-0.034-0.019c-0.013-0.006-0.031-0.009-0.055-0.009c-0.024,0-0.06,0.014-0.109,0.043
							c-0.021,0.013-0.041,0.027-0.061,0.043c-0.02,0.016-0.037,0.032-0.053,0.048c-0.015,0.016-0.028,0.031-0.037,0.046
							c-0.009,0.015-0.013,0.029-0.013,0.043c0,0.014,0.009,0.032,0.025,0.056c0.017,0.024,0.042,0.047,0.074,0.07
							c0.077,0.053,0.168,0.08,0.273,0.08c0.188,0,0.359-0.043,0.513-0.129c0.073-0.041,0.144-0.09,0.212-0.147
							c0.026-0.022,0.052-0.045,0.079-0.068c-0.002,0.026-0.004,0.051-0.004,0.077c0,0.064,0.03,0.125,0.091,0.182
							c0.07,0.067,0.161,0.1,0.272,0.1c0.118,0,0.25-0.04,0.395-0.121c0.062-0.035,0.135-0.084,0.217-0.145
							c0.003-0.002,0.006-0.005,0.009-0.007c0.013,0.043,0.035,0.086,0.066,0.129c0.068,0.092,0.161,0.138,0.278,0.138
							c0.112,0,0.243-0.037,0.393-0.113c0.141-0.071,0.249-0.148,0.323-0.233c0.013,0.009,0.027,0.018,0.041,0.026
							c0.045,0.026,0.106,0.039,0.182,0.039c0.072,0,0.149-0.026,0.233-0.078c0.011-0.007,0.019-0.015,0.029-0.022
							c0.008,0.002,0.015,0.006,0.023,0.006c0.034,0,0.104-0.049,0.213-0.148l0.102-0.094c0.041-0.036,0.068-0.054,0.08-0.054
							c0.029,0,0.051,0.002,0.066,0.005c0.015,0.004,0.022,0.012,0.022,0.025c0,0.008-0.021,0.034-0.063,0.078
							c-0.049,0.051-0.092,0.109-0.128,0.175c-0.018,0.033-0.033,0.067-0.044,0.1c-0.011,0.034-0.017,0.065-0.017,0.094
							c0,0.029,0.005,0.056,0.016,0.079c0.033,0.079,0.126,0.118,0.278,0.118c0.104,0,0.219-0.03,0.343-0.091
							c0.055-0.026,0.107-0.056,0.157-0.089c0.049-0.033,0.094-0.066,0.135-0.098c0.032-0.025,0.059-0.048,0.084-0.071
							c0.016,0.078,0.058,0.15,0.127,0.217c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035
							c0.048,0,0.097-0.008,0.147-0.025c0.05-0.016,0.102-0.038,0.154-0.065c0.053-0.027,0.106-0.059,0.159-0.096
							c0.051-0.035,0.1-0.072,0.147-0.11c0.008,0.025,0.021,0.052,0.039,0.079c0.017,0.028,0.039,0.054,0.065,0.079
							c0.026,0.025,0.056,0.047,0.091,0.066c0.035,0.019,0.075,0.034,0.119,0.046c0.044,0.011,0.1,0.017,0.167,0.017
							c0.067,0,0.138-0.009,0.213-0.026c0.198-0.044,0.379-0.127,0.541-0.248c0.084-0.06,0.17-0.137,0.258-0.232
							c0.086-0.094,0.128-0.157,0.128-0.189c0-0.056-0.021-0.084-0.064-0.084c-0.043,0-0.114,0.038-0.214,0.115l-0.095,0.074
							c-0.2,0.159-0.374,0.239-0.525,0.239c-0.065,0-0.12-0.02-0.166-0.06c-0.021-0.019-0.037-0.04-0.049-0.064
							c-0.011-0.024-0.017-0.049-0.017-0.074c0-0.025,0.012-0.054,0.035-0.086c0.029-0.039,0.063-0.066,0.104-0.08
							c0.059,0.024,0.134,0.035,0.225,0.035c0.067,0,0.127-0.02,0.181-0.06c0.028-0.021,0.051-0.047,0.069-0.078
							c0.018-0.031,0.027-0.064,0.027-0.098c0-0.035-0.014-0.062-0.042-0.082c-0.012-0.009-0.027-0.016-0.044-0.021
							c-0.017-0.005-0.038-0.008-0.063-0.008c-0.025,0-0.067,0.005-0.126,0.014c-0.137,0.021-0.281,0.066-0.432,0.137
							c-0.08,0.037-0.149,0.076-0.206,0.117c-0.032,0.014-0.067,0.034-0.106,0.063l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075
							c-0.035,0.024-0.069,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045c-0.032,0.011-0.059,0.017-0.083,0.017
							c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073c0.022-0.019,0.05-0.051,0.086-0.095
							c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086c0.017-0.03,0.031-0.059,0.042-0.088
							c0.011-0.029,0.016-0.056,0.016-0.079c0-0.023-0.001-0.043-0.003-0.06c-0.007-0.057-0.061-0.085-0.163-0.085
							c-0.071,0-0.148,0.031-0.23,0.093c-0.082,0.061-0.149,0.136-0.202,0.225c-0.016,0.009-0.032,0.02-0.051,0.033l-0.087,0.061
							c-0.032,0.024-0.095,0.067-0.191,0.129c-0.035,0.022-0.066,0.041-0.093,0.057c-0.027,0.016-0.05,0.027-0.068,0.035
							c-0.018,0.008-0.035,0.012-0.05,0.012c-0.015,0-0.028-0.005-0.041-0.015c-0.012-0.01-0.019-0.022-0.019-0.035
							c0-0.013,0.025-0.045,0.074-0.096l0.069-0.068c0.095-0.093,0.143-0.16,0.143-0.203c0-0.042-0.015-0.08-0.044-0.114
							c-0.013-0.015-0.029-0.028-0.046-0.037c-0.017-0.009-0.032-0.014-0.045-0.014c-0.013,0-0.031,0.001-0.054,0.003l-0.148,0.01
							c-0.024,0.002-0.041,0.003-0.052,0.003c-0.011,0-0.022-0.009-0.033-0.026L22.25,12.64c-0.021-0.033-0.061-0.05-0.12-0.05
							c-0.067,0-0.147,0.032-0.24,0.096c-0.087,0.059-0.163,0.129-0.228,0.21c-0.031,0.039-0.055,0.075-0.072,0.108
							c-0.038,0.016-0.072,0.025-0.1,0.025c-0.064,0-0.096-0.019-0.096-0.056c0-0.01,0.002-0.02,0.006-0.031
							c0.004-0.011,0.006-0.027,0.006-0.047c0-0.068-0.022-0.124-0.067-0.168c-0.019-0.019-0.048-0.039-0.087-0.058
							c-0.039-0.019-0.084-0.029-0.134-0.029c-0.112,0-0.238,0.022-0.378,0.066c-0.066,0.021-0.14,0.05-0.222,0.089
							c-0.059,0.028-0.119,0.065-0.181,0.111c-0.019,0.004-0.042,0.014-0.071,0.033l-0.073,0.051c-0.026,0.019-0.058,0.046-0.097,0.082
							c-0.039,0.036-0.092,0.075-0.161,0.118c-0.037,0.023-0.076,0.042-0.115,0.058c-0.039,0.016-0.079,0.024-0.121,0.024
							c-0.041,0-0.074-0.016-0.098-0.047c-0.025-0.031-0.037-0.07-0.037-0.117c0-0.047,0.023-0.122,0.07-0.227l0.023-0.051
							c0.005-0.011,0.013-0.027,0.025-0.049l0.047-0.087c0.02-0.036,0.034-0.056,0.043-0.06l0.382-0.001c0.188,0,0.283-0.053,0.283-0.158
							c0-0.044-0.022-0.067-0.065-0.067l-0.496,0.001l0.058-0.142c0.008-0.019,0.011-0.034,0.011-0.045c0-0.026-0.017-0.051-0.051-0.073
							c-0.017-0.01-0.039-0.019-0.064-0.026c-0.025-0.007-0.057-0.01-0.094-0.011c-0.037,0-0.07,0.011-0.097,0.034
							c-0.028,0.023-0.055,0.059-0.082,0.107l-0.064,0.109c-0.017,0.028-0.028,0.043-0.033,0.045l-0.255-0.001
							c-0.046,0-0.081,0.005-0.104,0.015c-0.017,0.006-0.031,0.016-0.042,0.03c-0.011,0.014-0.017,0.036-0.017,0.066
							c0,0.03,0.014,0.057,0.042,0.08c0.028,0.024,0.067,0.035,0.118,0.035l0.135,0c0,0.007-0.006,0.022-0.019,0.046
							c-0.038,0.069-0.074,0.15-0.108,0.243c-0.003,0.001-0.006,0.001-0.009,0.003c-0.024,0.009-0.071,0.043-0.142,0.102l-0.122,0.1
							c-0.101,0.083-0.188,0.138-0.261,0.166c0.022-0.017,0.041-0.035,0.059-0.054c0.059-0.062,0.089-0.114,0.089-0.158
							c0-0.065-0.032-0.133-0.096-0.205c-0.026-0.029-0.054-0.056-0.082-0.081c-0.028-0.025-0.051-0.046-0.069-0.065
							c0.023-0.028,0.034-0.054,0.034-0.076c0-0.023-0.003-0.041-0.01-0.055c-0.023-0.047-0.059-0.071-0.107-0.071
							c-0.077,0-0.154,0.028-0.232,0.083c-0.082,0.059-0.123,0.124-0.123,0.195l-0.085,0.057c-0.039,0.012-0.084,0.035-0.137,0.074
							l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075c-0.035,0.024-0.07,0.046-0.103,0.065c-0.034,0.019-0.066,0.033-0.098,0.045
							c-0.032,0.011-0.059,0.017-0.082,0.017c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073
							c0.022-0.019,0.05-0.051,0.086-0.095c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086
							c0.017-0.03,0.031-0.059,0.042-0.089c0.011-0.029,0.016-0.056,0.016-0.078c0-0.023-0.001-0.043-0.003-0.06
							c-0.007-0.057-0.061-0.085-0.163-0.085C17.226,12.612,17.149,12.643,17.066,12.704z M20.541,13.174l0.001-0.003
							c0-0.012,0.008-0.028,0.025-0.049c0.052-0.061,0.13-0.121,0.235-0.178c0.095-0.052,0.17-0.078,0.226-0.078
							c-0.044,0.05-0.066,0.1-0.066,0.15c0,0.023,0.005,0.047,0.017,0.069c-0.064,0.052-0.136,0.095-0.215,0.129
							c-0.032,0.014-0.066,0.025-0.101,0.035c-0.035,0.01-0.069,0.004-0.101-0.015c-0.013-0.009-0.02-0.028-0.02-0.058V13.174z"/>
						<path fill="currentColor" d="M28.214,12.406l0.002-0.001l-0.846-1.167c-0.02-0.028-0.038-0.057-0.06-0.084c-0.292-0.362-0.743-0.569-1.235-0.569H14.364
							c0.01,0.074,0.017,0.148,0.017,0.225v0.5h11.694c0.581,0,0.952,0.471,0.827,1.053l-1.811,8.572
							c-0.125,0.582-0.698,1.053-1.279,1.053H9.4c-0.582,0-0.952-0.472-0.827-1.053l1.403-6.639H9.235l-1.371,6.487
							c-0.097,0.451-0.014,0.9,0.224,1.254l-0.005,0.002l0.877,1.207l0.002,0c0.009,0.012,0.017,0.025,0.026,0.037
							c0.292,0.362,0.743,0.569,1.235,0.569h14.411c0.919,0,1.792-0.714,1.988-1.628l1.811-8.569
							C28.53,13.206,28.449,12.76,28.214,12.406z"/>
						<path fill="currentColor" d="M11.408,9.705c-0.017,0-0.046,0.001-0.089,0.004v2.316c0.004-0.001,0.014-0.002,0.027-0.002
							c0.009,0,0.026,0.001,0.051,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193V10.05
							c0-0.132-0.021-0.222-0.063-0.271C11.584,9.73,11.512,9.705,11.408,9.705z"/>
						<path fill="currentColor" d="M5.731,9.739H5.688v0.675h0.2c0.106,0,0.159-0.11,0.159-0.332c0-0.147-0.021-0.241-0.062-0.282
							C5.943,9.76,5.858,9.739,5.731,9.739z"/>
						<path fill="currentColor" d="M13.675,12.158v-2.56c0-0.801-0.649-1.45-1.45-1.45H4.976c-0.801,0-1.45,0.649-1.45,1.45v2.56
							c0,0.801,0.649,1.45,1.45,1.45h7.249C13.026,13.608,13.675,12.959,13.675,12.158z M6.827,10.249
							c-0.002,0.043-0.011,0.098-0.025,0.163c-0.015,0.066-0.035,0.121-0.061,0.165c-0.026,0.044-0.065,0.087-0.117,0.129
							c-0.052,0.042-0.116,0.075-0.19,0.098c0.074,0.014,0.136,0.039,0.184,0.076c0.049,0.037,0.083,0.083,0.105,0.137
							c0.021,0.055,0.036,0.105,0.043,0.151c0.008,0.046,0.011,0.098,0.011,0.156c0,0.598-0.003,1.052-0.009,1.363H5.986v-1.41
							c0-0.056-0.006-0.101-0.017-0.134c-0.011-0.033-0.032-0.057-0.064-0.072c-0.031-0.014-0.061-0.023-0.09-0.026
							c-0.029-0.003-0.073-0.005-0.132-0.005v1.647h-0.81V9.069h1.229c0.126,0,0.233,0.017,0.323,0.051
							c0.089,0.034,0.16,0.079,0.211,0.134c0.052,0.055,0.092,0.129,0.122,0.22c0.029,0.092,0.049,0.183,0.058,0.275
							C6.825,9.841,6.83,9.952,6.83,10.082C6.83,10.15,6.829,10.206,6.827,10.249z M9.792,12.687h-0.96l-0.2-1.744L8.44,12.687H7.464
							l-0.43-3.618h0.783l0.221,2.282l0.225-2.282h0.746l0.214,2.282l0.214-2.282h0.792L9.792,12.687z M11.591,12.687h-1.076V9.069h1.103
							c0.299,0,0.517,0.076,0.654,0.228c0.137,0.152,0.207,0.361,0.208,0.626l0.009,1.69c0,0.362-0.071,0.631-0.213,0.809
							C12.135,12.599,11.906,12.687,11.591,12.687z"/>
  					</g>
				)
			case 'icon-eRallyR2Caps':
				return (
					<g>
						<path fill="currentColor" d="M29.116,9.393c-0.097,0.014-0.097,0.194,0.027,0.203C29.118,9.484,29.261,9.42,29.116,9.393z"/>
						<path fill="currentColor" d="M28.843,9.533c-0.013,0.124-0.473,0.323-0.137,0.433C28.718,9.842,29.178,9.643,28.843,9.533z"/>
						<path fill="currentColor" d="M30.008,18.286c-0.023,0.032,0.067,0.148,0.109,0.089C30.14,18.343,30.051,18.227,30.008,18.286z"/>
						<path fill="currentColor" d="M28.333,12.294c0.106,0.295,0.199,0.607,0.478,0.661c0.166-0.167,0.234-0.414,0.301-0.662
							C28.824,12.584,28.605,11.98,28.333,12.294z"/>
						<path fill="currentColor" d="M29.003,12.205c0.023-0.032-0.066-0.148-0.109-0.09C28.872,12.148,28.961,12.264,29.003,12.205z"/>
						<path fill="currentColor" d="M29.626,17.612c-0.023,0.032,0.067,0.148,0.109,0.089C29.758,17.669,29.669,17.553,29.626,17.612z"/>
						<path fill="currentColor" d="M29.611,18.96c0.257,0.127,0.236-0.383,0.055-0.318c-0.054,0.348-0.382,0.031-0.424,0.471
							c0.164-0.027,0.256,0.046,0.314,0.165c-0.058,0.014-0.111,0.03-0.082,0.115c0.294-0.092,0.423,0.187,0.465-0.166
							C29.794,19.189,29.684,19.1,29.611,18.96z"/>
						<path fill="currentColor" d="M27.366,8.694c0.314,0.291,0.615,0.212,0.834,0.407c0.013-0.201,0.36-0.13,0.233-0.446
							c-0.208-0.167-0.242,0.021-0.479,0.064c-0.257-0.299-0.593-0.376-0.998-0.178c-0.023,0.233,0.202,0.121,0.232,0.28
							c-0.22,0.164-0.437-0.246-0.479,0.064c0.251,0.052,0.413,0.229,0.711,0.216C27.706,8.858,27.051,8.913,27.366,8.694z"/>
						<path  fill="currentColor" d="M28.174,8.172C28.252,8.312,28.288,8.146,28.174,8.172L28.174,8.172z"/>
						<path  fill="currentColor" d="M28.174,8.172C28.114,7.87,27.789,8.322,28.174,8.172L28.174,8.172z"/>
						<path  fill="currentColor" d="M28.309,9.19c-0.464,0.204-0.534-0.141-0.889-0.088C27.521,9.463,28.179,9.562,28.309,9.19z"/>
						<path  fill="currentColor" d="M28.762,19.902c-0.111-0.166-0.225-0.33-0.519-0.241c0.073,0.308-0.697,0.392-0.302,0.662
							c0.077-0.153,0.164,0.022,0.26-0.242c-0.015,0.223,0.163,0.175,0.314,0.165c0.022-0.133,0.096-0.224,0.26-0.242
							c0.146,0.038,0.255,0.128,0.328,0.267c-0.38-0.019-0.341,0.045-0.588-0.026c-0.007,0.085-0.082,0.114-0.068,0.216
							c0.282,0.129,0.822-0.21,0.711,0.216c0.311-0.08,0.447-0.302,0.616-0.496C29.37,20.182,29.295,19.723,28.762,19.902z"/>
						<path fill="currentColor" d="M26.654,9.928c-0.003,0.406,0.429,0.207,0.547,0.444c-0.276,0.316,0.465,0.871,0.656,0.535
							c-0.112,0.047-0.369-0.25-0.137-0.293c0.174,0.291,0.268-0.062,0.424,0.254c-0.191-0.016-0.078,0.482,0.136,0.293
							c-0.058-0.169,0.049-0.202,0.165-0.229c-0.131-0.341-0.29-0.642-0.587-0.751c0.057,0.203-0.28,0.016-0.301-0.063
							c0.14-0.073-0.008-0.107-0.109-0.089c0.007,0.285-0.273,0.216-0.383,0.051c0.119-0.039,0.085-0.321-0.041-0.306
							c0.008,0.056-0.062,0.126-0.096,0.013c-0.032,0.004-0.064,0.008-0.095,0.013C26.842,9.901,26.725,9.896,26.654,9.928z
							M27.337,10.666c0.043-0.059,0.133,0.057,0.11,0.089C27.404,10.814,27.314,10.698,27.337,10.666z"/>
						<path fill="currentColor" d="M28.416,11.454c-0.091-0.214,0.228-0.36-0.137-0.292C28.291,11.308,28.302,11.452,28.416,11.454z"/>
						<path fill="currentColor" d="M29.121,17.472c-0.184-0.507-0.814-0.394-1.08-0.788c-0.162-0.473-0.121-1.38-0.285-2.137
							c-0.238,0.077-0.421,0.201-0.534,0.382c0.282-0.03,0.386,0.185,0.533,0.343c-0.381-0.099-0.146,0.304-0.451,0.267
							c-0.151-0.112-0.108-0.493-0.355-0.47c-0.463,0.107-0.021,0.952-0.221,1.272c0.206,0.495,0.063,0.579,0.163,1.221
							c0.48,0.215,0.963,0.427,1.449,0.636c0.057-0.205,0.072,0.102,0.205,0.076c0.008-0.231,0.584-0.177,0.301,0.064
							c0.382-0.134,0.368-0.56,0.192-0.751c-0.071,0.093-0.02,0.286-0.151,0.331C28.845,17.632,28.89,17.276,29.121,17.472z
							M27.331,15.742c-0.043,0.059-0.132-0.057-0.109-0.089C27.264,15.594,27.353,15.711,27.331,15.742z M27.63,17.256
							c-0.042,0.059-0.132-0.057-0.109-0.089C27.563,17.108,27.653,17.225,27.63,17.256z M28.027,17.307
							c0.008,0.056-0.062,0.126-0.096,0.013c-0.385,0.15-0.06-0.302,0,0C27.963,17.316,27.995,17.311,28.027,17.307
							C28.001,17.185,28.157,17.226,28.027,17.307z M28.245,17.485c0.188,0.02,0.212,0.268,0.506,0.14
							c0.013,0.102,0.027,0.204,0.04,0.305C28.722,17.626,28.18,17.979,28.245,17.485z"/>
						<path fill="currentColor" d="M28.17,11.798c0.252-0.346-0.488-0.224-0.068-0.509c-0.078-0.013-0.123-0.071-0.123-0.191
							c-0.161,0.081-0.579-0.048-0.561,0.178c0.077,0.013,0.19-0.022,0.205,0.076c0.014,0.102,0.027,0.203,0.041,0.305
							C27.958,11.531,28.039,11.699,28.17,11.798z"/>
						<path fill="currentColor" d="M29.037,19.037c-0.023,0.031,0.067,0.149,0.11,0.089C29.169,19.094,29.079,18.978,29.037,19.037z"/>
						<path fill="currentColor" d="M27.992,11.925c-0.032-0.076-0.029-0.2-0.123-0.191C27.901,11.811,27.898,11.935,27.992,11.925z"/>
						<path fill="currentColor" d="M28.846,18.338C28.436,18.293,28.867,18.644,28.846,18.338L28.846,18.338z"/>
						<path fill="currentColor" d="M27.447,10.03C27.368,9.89,27.333,10.056,27.447,10.03L27.447,10.03z"/>
						<path fill="currentColor" d="M28.706,20.946c-0.023,0.031,0.067,0.148,0.11,0.089C28.838,21.003,28.749,20.887,28.706,20.946z"/>
						<path fill="currentColor" d="M27.831,20.959c0.005,0.034,0.009,0.068,0.014,0.102c0.16,0.139,0.502,0.024,0.779,0
							C28.539,20.53,28.09,20.742,27.831,20.959z"/>
						<path fill="currentColor" d="M26.928,9.788c0.032-0.004,0.064-0.009,0.096-0.013C26.99,9.663,26.92,9.732,26.928,9.788z"/>
						<path fill="currentColor" d="M26.913,10.412c-0.023,0.032,0.067,0.148,0.109,0.089C27.045,10.469,26.956,10.352,26.913,10.412z"/>
						<path fill="currentColor" d="M26.832,9.801c-0.005-0.034-0.009-0.068-0.014-0.102c-0.188-0.02-0.27-0.187-0.506-0.14
							C26.311,9.883,26.56,9.857,26.832,9.801z"/>
						<path fill="currentColor" d="M26.312,9.56C26.234,9.42,26.198,9.585,26.312,9.56L26.312,9.56z"/>
						<path fill="currentColor" d="M27.612,20.781c0.054,0.085,0.108,0.17,0.219,0.178C27.843,20.781,27.731,20.777,27.612,20.781z"/>
						<path fill="currentColor" d="M27.489,20.59c0.001,0.12,0.045,0.178,0.123,0.191C27.612,20.66,27.567,20.603,27.489,20.59z"/>
						<path fill="currentColor" d="M27.078,21.163c0.158-0.034,0.653,0.248,0.561-0.178C27.479,21.065,27.06,20.936,27.078,21.163z"/>
						<path fill="currentColor" d="M27.489,20.59C27.429,20.288,27.105,20.74,27.489,20.59L27.489,20.59z"/>
						<path fill="currentColor" d="M24.467,8.873c0.282-0.346,0.651,0.213,0.998,0.178c0.107-0.065,0.202-0.137,0.26-0.242
							c-0.476-0.186-0.92-0.341-1.313-0.343C24.529,8.643,24.171,8.844,24.467,8.873z"/>
						<path fill="currentColor" d="M27.229,20.832C27.36,20.751,27.203,20.71,27.229,20.832L27.229,20.832z"/>
						<path fill="currentColor" d="M27.229,20.832c-0.087-0.06-0.283-0.124-0.287,0.038C27.029,20.93,27.225,20.993,27.229,20.832z"/>
						<path fill="currentColor" d="M26.942,20.87C26.811,20.951,26.968,20.991,26.942,20.87L26.942,20.87z"/>
						<path fill="currentColor" d="M24.671,9.675c0.114-0.119,0.267-0.207,0.342-0.357c-0.359-0.027-0.505-0.421-0.889-0.088
							c-0.133-0.218-0.34-0.332-0.628-0.331c-0.398,0.039-0.518,0.304-0.712,0.509c0.208,0.032,0.307,0.216,0.602,0.127
							c0.089-0.079,0.068-0.248,0.26-0.242c0.005,0.034,0.009,0.068,0.013,0.102c0.16-0.021,0.319-0.042,0.479-0.064
							C24.167,9.653,24.43,9.648,24.671,9.675z"/>
						<path fill="currentColor" d="M21.334,9.497c0.266,0.15,0.638-0.219,0.712-0.509c-0.343-0.037-0.348,0.201-0.657,0.191
							C21.438,9.34,21.322,9.367,21.334,9.497z"/>
						<path fill="currentColor" d="M21.389,9.179c-0.005-0.034-0.009-0.068-0.014-0.102c-0.392,0.078-0.835-0.133-1.162,0.052
							C20.512,9.245,21.111,9.418,21.389,9.179z"/>
						<path fill="currentColor" d="M20.691,9.79c0.032-0.004,0.064-0.008,0.096-0.013C20.753,9.664,20.683,9.734,20.691,9.79z"/>
						<path fill="currentColor" d="M16.711,9.906c0.398-0.302,0.666,0.159,1.094,0.165c0.228-0.116,0.339-0.329,0.629-0.395
							c-0.125-0.374-0.862-0.534-0.998-0.177c0.059-0.002,0.115,0,0.109,0.089c0.077,0.013,0.19-0.022,0.205,0.077
							c-0.077-0.013-0.189,0.022-0.205-0.077c-0.059,0.003-0.115,0-0.109-0.089c-0.359,0.035-0.783-0.111-1.149,0.153
							C16.428,9.737,16.656,9.701,16.711,9.906z"/>
						<path fill="currentColor" d="M15.89,22.031c-0.252,0.097-0.422,0.262-0.521,0.484c0.182,0.069,0.312,0.208,0.52,0.241
							c0.22-0.442,1.034,0.016,1.095-0.56C16.517,22.471,16.341,22.084,15.89,22.031z M16.054,22.528
							c-0.043,0.059-0.132-0.058-0.109-0.089C15.987,22.379,16.076,22.496,16.054,22.528z"/>
						<path fill="currentColor" d="M13.386,22.987C13.465,23.127,13.501,22.961,13.386,22.987L13.386,22.987z"/>
						<path fill="currentColor" d="M13.277,22.898c-0.006,0.089,0.05,0.091,0.109,0.089C13.393,22.898,13.337,22.896,13.277,22.898z"/>
						<path fill="currentColor" d="M12.06,22.541c-0.189,0.179-0.489,0.268-0.616,0.497c0.239-0.08,0.591,0.288,0.684,0.013
							c-0.111-0.005-0.067-0.095-0.013-0.102c0.415-0.08,0.848,0.269,1.162-0.051c-0.004-0.034-0.009-0.068-0.014-0.102
							C12.814,22.778,12.438,22.659,12.06,22.541z"/>
						<path fill="currentColor" d="M8.6,22.899c0.517,0.124,1.058,0.216,1.517,0.419c0.114-0.24,0.527-0.236,0.507-0.585c-0.475-0.143-0.813-0.477-1.34-0.546
							C9.047,22.416,8.637,22.506,8.6,22.899z"/>
						<path fill="currentColor" d="M8.955,23.37c-0.156-0.265-0.528-0.23-0.738-0.419c-0.013,0.352-0.591,0.245-0.494,0.687
							c0.493,0.134,0.519,0.067,0.958-0.128c0.435-0.055,0.717,0.262,0.958-0.128C9.346,23.437,9.16,23.081,8.955,23.37z"/>
						<path fill="currentColor" d="M8.204,22.123c-0.228-0.005-0.426-0.05-0.519-0.242c-0.366,0.278-0.594-0.166-0.998-0.178
							c-0.221,0.062-0.131,0.377-0.438,0.369c0.242,0.467,0.719,0.606,1.175,0.776C7.686,22.608,8.107,22.497,8.204,22.123z M6.85,22.2
							c-0.043,0.059-0.132-0.057-0.109-0.089C6.783,22.051,6.873,22.168,6.85,22.2z"/>
						<path fill="currentColor" d="M6.703,19.63c0.032,0.076,0.029,0.2,0.123,0.191C6.794,19.745,6.796,19.62,6.703,19.63z"/>
						<path fill="currentColor" d="M6.346,20.61c0.19,0.017,0.128,0.385,0.424,0.254C6.78,20.634,6.408,20.341,6.346,20.61z"/>
						<path fill="currentColor" d="M6.097,23.129c-0.109-0.403-0.741-0.619-0.793-0.102c0.179,0.072,0.514-0.072,0.52,0.241
							c-0.123-0.07-0.275-0.1-0.492-0.038c0.127,0.426,0.597,0.375,0.861,0.611c0.122-0.263,0.647-0.199,0.603-0.599
							C6.482,23.301,6.311,22.845,6.097,23.129z M6.028,23.345c-0.043,0.059-0.132-0.057-0.11-0.089
							C5.961,23.197,6.051,23.313,6.028,23.345z M6.22,23.32c-0.042,0.059-0.132-0.057-0.11-0.089C6.153,23.171,6.243,23.288,6.22,23.32z
							"/>
						<path fill="currentColor" d="M7.212,12.116c-0.141,0.037-0.358,0.011-0.369,0.153C7.019,12.197,7.296,12.528,7.212,12.116z"/>
						<path fill="currentColor" d="M4.815,15.336c-0.137,0.01-0.273,0.019-0.273,0.14c0.341,0.168,0.464,0.64,0.874,0.712
							c0.117,0.177-0.241,0.378,0.054,0.407c-0.012-0.103,0.151-0.151,0.192-0.026c-0.064,0.008-0.128,0.017-0.192,0.026
							c0.005,0.034,0.009,0.067,0.013,0.102c0.093,0.122,0.2,0.199,0.314,0.246c-0.013-0.002-0.026,0.001-0.035,0.015
							c-0.023,0.032,0.067,0.149,0.109,0.089c0.015-0.022-0.021-0.081-0.059-0.099c0.329,0.125,0.719-0.007,1.052-0.125
							C6.749,16.381,6.504,16.12,6.25,15.87c-0.063-0.233-0.156-0.425-0.355-0.47c-0.284,0.169,0.122,0.334-0.342,0.356
							c0.044,0.14,0.225,0.088,0.314,0.165c-0.008-0.057,0.062-0.126,0.096-0.013c-0.032,0.004-0.064,0.009-0.096,0.013
							c0.006,0.116,0.16,0.14,0.027,0.203c-0.22-0.015-0.334-0.178-0.519-0.241c-0.013-0.102-0.027-0.204-0.041-0.306
							c-0.067-0.085-0.445-0.322-0.246-0.382c0.178-0.047,0.227,0.087,0.396,0.051c0.01-0.082,0.064-0.129,0.178-0.127
							c-0.015-0.3-0.227-0.327-0.355-0.471C5.124,13.76,4.293,14.27,4.05,14.713C4.428,14.749,4.629,15.032,4.815,15.336z M6.4,16.264
							C6.677,16.575,6.046,16.061,6.4,16.264L6.4,16.264z M6.359,16.684c-0.046,0.191-0.172,0.038-0.287,0.038
							C6.118,16.531,6.244,16.684,6.359,16.684z M5.922,16.328c0.043-0.06,0.132,0.057,0.109,0.089
							C5.988,16.476,5.899,16.36,5.922,16.328z M5.744,16.455c0.043-0.059,0.132,0.058,0.109,0.089
							C5.811,16.603,5.721,16.487,5.744,16.455z M5.157,14.98c0.043-0.059,0.132,0.057,0.11,0.089C5.224,15.128,5.135,15.011,5.157,14.98
							z M5.061,15.718c-0.042,0.059-0.132-0.058-0.109-0.089C4.994,15.569,5.083,15.686,5.061,15.718z M4.856,14.916
							c0.043-0.059,0.132,0.057,0.109,0.089C4.923,15.064,4.834,14.948,4.856,14.916z"/>
						<path fill="currentColor" d="M6.714,17.155c-0.023,0.032,0.067,0.148,0.109,0.089C6.846,17.212,6.756,17.096,6.714,17.155z"/>
						<path fill="currentColor" d="M5.508,23.829c-0.023,0.032,0.067,0.149,0.109,0.089C5.64,23.886,5.551,23.77,5.508,23.829z"/>
						<path fill="currentColor" d="M6.604,17.066C6.034,17.032,6.623,17.512,6.604,17.066L6.604,17.066z"/>
						<path fill="currentColor" d="M5.485,15.247c0.013,0.161-0.233,0.112-0.151,0.331c0.303,0.035,0.258-0.213,0.561-0.178
							c-0.019-0.175-0.106-0.254-0.232-0.28C5.652,15.201,5.598,15.249,5.485,15.247z"/>
						<path fill="currentColor" d="M5.29,18.898c0.142,0.139,0.518-0.299,0.616-0.497c-0.109,0.032-0.173,0.001-0.205-0.076
							c0.07-0.064,0.141-0.127,0.274-0.14c-0.004-0.034-0.009-0.067-0.013-0.101C5.826,18.11,5.692,18.137,5.66,18.02
							c0.137-0.009,0.273-0.019,0.274-0.14c-0.399,0.069-0.238-0.022-0.123-0.191c-0.333-0.361-0.623,0.075-1.053,0.14
							c-0.149,0.574-1.116,0.483-1,1.273c0.089,0.077,0.27,0.025,0.314,0.165c0.133-0.133,0.156-0.357,0.438-0.369
							c-0.026-0.122,0.131-0.081,0,0c-0.06,0.191-0.279,0.358,0.054,0.407c0.09-0.109-0.02-0.379,0.247-0.343
							c-0.037,0.211-0.273,0.478,0.068,0.509C4.928,19.208,5.193,19.122,5.29,18.898z M5.345,17.855c0.043-0.06,0.132,0.057,0.109,0.089
							C5.412,18.003,5.322,17.887,5.345,17.855z M5.304,18.275C5.283,17.967,5.714,18.319,5.304,18.275
							C5.33,18.397,5.173,18.356,5.304,18.275z M4.784,18.758c-0.043,0.059-0.132-0.058-0.109-0.089
							C4.717,18.61,4.806,18.726,4.784,18.758z M5.03,18.415c0.043-0.059,0.132,0.058,0.109,0.089C5.097,18.563,5.007,18.447,5.03,18.415
							z M4.852,18.542c0.042-0.059,0.132,0.057,0.109,0.089C4.919,18.691,4.829,18.574,4.852,18.542z"/>
						<path fill="currentColor" d="M5.553,15.755C5.474,15.615,5.439,15.781,5.553,15.755L5.553,15.755z"/>
						<path fill="currentColor" d="M5.128,16.226c-0.023,0.032,0.067,0.149,0.109,0.089C5.261,16.284,5.171,16.167,5.128,16.226z"/>
						<path fill="currentColor" d="M3.787,17.13c-0.23,0.102,0.214,0.248,0.164,0.496c0.491-0.084,0.67-0.422,0.876-0.738
							c-0.178,0.029-0.213-0.052-0.123-0.191c-0.283,0.17-0.405-0.049-0.548,0.28c-0.164-0.134-0.228-0.406-0.451-0.458
							c0,0.121-0.137,0.13-0.274,0.14C3.448,16.96,3.659,16.987,3.787,17.13z"/>
						<path fill="currentColor" d="M4.458,16.316c-0.159-0.008-0.152,0.118-0.274,0.14c0.005,0.034,0.009,0.068,0.014,0.102
							C4.395,16.566,4.495,16.497,4.458,16.316z"/>
						<path fill="currentColor" d="M4.184,16.456C4.225,15.965,3.751,16.626,4.184,16.456L4.184,16.456z"/>
						<path fill="currentColor" d="M3.843,16.087c0.102-0.009,0.237-0.08,0.082-0.115C3.823,15.981,3.688,16.053,3.843,16.087z"/>
						<path fill="currentColor" d="M2.041,18.283c-0.023,0.032,0.067,0.148,0.109,0.089C2.173,18.34,2.083,18.224,2.041,18.283z"/>
						<path fill="currentColor" d="M1.931,18.194C1.801,18.275,1.957,18.315,1.931,18.194L1.931,18.194z"/>
						<path fill="currentColor" d="M3.501,16.443c0.053-0.007,0.098-0.097-0.014-0.102C3.434,16.348,3.389,16.438,3.501,16.443z"/>
						<path fill="currentColor" d="M3.144,17.423c0.055,0.286,0.01,0.709,0.286,0.687c-0.017-0.134,0.155-0.115,0.164-0.229
							C3.4,17.791,3.339,17.514,3.144,17.423z"/>
						<path fill="currentColor" d="M26.605,20.449l0-0.141c0.318-0.279,0.275-0.298,0.15-0.263c0.168-0.123,0.389-0.203,0.407-0.448
							c-0.152,0.011-0.109-0.25-0.314-0.165c-0.038,0.005-0.077,0.006-0.115,0.003c0.031-0.099,0.048-0.203,0.043-0.312
							c0.415,0.016,0.68,0.307,1.016,0.079c-0.009-0.068-0.018-0.136-0.027-0.203c-0.053,0.007-0.12-0.068-0.013-0.102
							c0.004,0.034,0.009,0.068,0.013,0.102c0.079-0.057,0.159-0.112,0.164-0.229c-0.361-0.14-0.621-0.421-1.039-0.483
							c-0.008,0.003-0.015,0.007-0.022,0.01c-0.082-0.332,0.041-0.878-0.185-1.058c0.043,0.101-0.007,0.232-0.074,0.314l0-0.258
							c0.126-0.139,0.236-0.294,0.204-0.598c-0.075,0.036-0.142,0.059-0.203,0.075l0-0.247l-0.734-0.001
							c-0.018-0.133-0.036-0.267-0.053-0.4c0.07,0.051,0.135,0.048,0.214-0.105c-0.199-0.053-0.019-0.163,0.129,0
							c-0.091-0.539,0.082-0.895,0.19-1.326c0.372-0.277,0.751-0.548,1.005-0.921c-0.369-0.044-0.605-0.273-0.921-0.39
							c0.078-0.232,0.059-0.44-0.037-0.609c0.689,0.06,0.973,0.684,1.779,0.58c-0.166-0.412-0.378-0.76-0.71-0.941
							c-0.083-0.326-0.271-0.507-0.478-0.662c0.229-0.102-0.214-0.248-0.164-0.496c-0.08-0.01-0.109-0.09-0.205-0.076
							c0.008,0.056-0.062,0.126-0.096,0.013c0.032-0.004,0.064-0.009,0.096-0.013c-0.133-0.119-0.059-0.155-0.041-0.306
							c-0.214,0.058-0.364,0.024-0.41-0.152c0.11-0.031,0.056-0.196,0.055-0.318c-0.109,0.031-0.174,0.001-0.205-0.076
							c-0.001-0.401-0.3-0.386-0.382-0.674c-0.469,0.066-0.607,0.402-0.91,0.604c-0.237-0.04-0.698,0.102-0.374-0.202
							c-0.018-0.006-0.034-0.009-0.051-0.012c0.007-0.003,0.014-0.005,0.022-0.008c0.097-0.658-0.683-0.095-0.669-0.636
							c-0.12,0.084-0.149,0.241-0.137,0.433c0.298-0.046,0.342,0.26,0.612,0.251c-0.055,0.035-0.103,0.086-0.147,0.141
							c-0.03-0.01-0.061-0.02-0.092-0.029c0.031-0.04,0.056-0.087,0.074-0.141c-1.59-0.628-1.776,0.373-2.722-0.546
							c-0.086,0-0.173,0-0.259,0c-0.028,0.116-0.085,0.202-0.16,0.268c-0.013,0.002-0.026,0.003-0.04,0.006
							c0.001,0.009,0,0.017-0.002,0.026c-0.02,0.015-0.041,0.028-0.063,0.041C20.71,9.836,20.7,9.819,20.691,9.79
							c-0.108-0.011-0.092-0.194-0.219-0.178c0.031,0.116,0.003,0.184-0.069,0.216c-0.211-0.188-0.423-0.375-0.738-0.42
							c-0.375,0.057-0.495,0.323-0.712,0.509c0.193,0.088,0.381,0.183,0.572,0.275c-0.01,0.006-0.02,0.01-0.029,0.017
							c-0.142,0.034-0.277,0.069-0.404,0.104c-0.433-0.209-0.471,0.35-1.089,0.136c0.011,0.106-0.001,0.186-0.031,0.248
							c-0.127,0.05-0.252,0.103-0.366,0.165c-0.039-0.001-0.079-0.002-0.122-0.007c-0.001,0.029,0.001,0.054,0.003,0.079
							c-0.019,0.013-0.037,0.025-0.054,0.039l-0.203,0.101c0-0.026-0.001-0.053-0.005-0.083c-0.092-0.045-0.19-0.098-0.291-0.154
							c0.198-0.118,0.378-0.251,0.419-0.497c-0.246,0.021-0.36-0.142-0.519-0.241c-0.453,0.329-0.651-0.361-1.286-0.14
							c-0.002,0.204,0.156,0.185,0.232,0.28c-0.001,0.005-0.003,0.01-0.005,0.015c-0.235-0.092-0.486-0.169-0.753-0.214
							c-0.006-0.001-0.069,0.05-0.135,0.093c-0.052-0.007-0.105-0.012-0.159-0.017c0.014-0.063,0.062-0.17,0.164-0.347
							c-0.398-0.095-0.539,0.079-0.621,0.316c-0.013,0-0.026,0.001-0.039,0.001c-0.058-0.002-0.115-0.004-0.174-0.005
							c-0.023-0.015-0.048-0.029-0.074-0.042c-0.004,0.016-0.01,0.029-0.016,0.04l-0.392-0.001c-0.103-0.029-0.204-0.047-0.274,0
							l-0.238,0c0.026-0.049,0.07-0.107,0.143-0.176c-1.347,0.049-2.59,0.233-2.464-0.003c-0.194,0.023-0.478-0.049-0.519,0.135
							c0.035-0.001,0.069,0.015,0.102,0.04l-0.258,0c-0.002-0.054-0.026-0.114-0.103-0.176c-0.033-0.021-0.226,0.112-0.357,0.175
							l-0.122,0c0.004-0.031,0.03-0.086,0.09-0.176c-0.333-0.037-0.965,0.378-0.648-0.137C8.675,9.718,8.672,9.942,8.54,10.03
							c-0.047-0.737-0.411-0.075-0.911,0.039l-0.209,0l0,0.012c-0.058-0.007-0.116-0.024-0.176-0.052c0,0.226-0.001,0.452-0.001,0.678
							c0.078,0.116,0.122,0.266,0.176,0.408l-0.001,0.74c-0.025,0.102-0.028,0.203,0,0.269l0,0.198c-0.025-0.028-0.06-0.045-0.082-0.014
							c-0.012,0.016,0.005,0.053,0.03,0.079c-0.028,0.09,0.023,0.263,0.02,0.38c-0.082-0.01-0.168,0.009-0.27,0.088
							c-0.154-0.188-0.361-0.302-0.629-0.33c-0.298,0.12-0.538,0.288-0.712,0.509c0.545,0.166,0.876,0.629,1.545,0.623
							c0.027-0.028,0.06-0.051,0.095-0.072l-0.001,0.515c-0.013,0.045-0.028,0.09-0.047,0.133c0.017,0.007,0.032,0.011,0.046,0.015
							l0,0.033l0.415,0.001c0.032,0.124,0.153,0.334,0.279,0.524l-0.001,0.552c-0.035,0.049-0.047,0.105-0.051,0.169
							c-0.224-0.291-0.458-0.568-0.615-0.952c-0.484-0.371-1.115-0.537-1.585-0.929c-0.302,0.206-0.466-0.377-0.588-0.025
							c0.749,0.806,1.272,1.928,2.103,2.62c0.015-0.199,0.249-0.22,0.124-0.534c-0.114,0.025-0.079-0.14,0,0
							c0.114,0.243,0.465,0.156,0.464,0.559c0.062,0,0.11-0.029,0.147-0.056l0,0.153c-0.11,0.009-0.254-0.01-0.325,0.03
							c-0.014-0.101-0.027-0.203-0.041-0.305c-0.271-0.04-0.166,0.228-0.356,0.255c0.082,0.371,0.407,0.405,0.721,0.453l0,0.378
							L7.41,17.17l0,0.327c-0.05-0.022-0.108-0.033-0.176-0.013c0,0.045,0,0.091,0,0.136c0.11,0.09,0.158,0.19,0.176,0.296l0,0.241
							c-0.022,0.191-0.072,0.385-0.001,0.555l0,0.243c-0.042,0.024-0.1,0.031-0.176,0.02c0,0.09,0,0.181,0,0.271
							c0.099,0.138,0.148,0.253,0.175,0.351l-0.001,0.56c-0.014,0.011-0.03,0.024-0.047,0.038c0.032,0.379,0.022,0.663,0.039,0.89
							c-0.18,0.132-0.346,0.275-0.439,0.477c0.393-0.003,0.443,0.264,0.749,0.155c0.048,0.036,0.103,0.071,0.168,0.105
							c0.023-0.02,0.041-0.04,0.058-0.059c0.131,0.143,0.31,0.219,0.543,0.218c0.047-0.062,0.115-0.107,0.189-0.147
							c0.218,0.033,0.354,0.108,0.247,0.261c0.576-0.1,0.633-0.541,1.262-0.283c0.291,0.27,0.706,0.369,1.078,0.525
							c0.176-0.218,0.568-0.26,0.663-0.543c0.076,0.015,0.155,0.027,0.238,0.034c0.036-0.45-0.501-0.038-0.022-0.39
							c0.015,0.004,0.031,0.008,0.047,0.012c0.102,0.146,0.038,0.466,0.364,0.379c0.051-0.233-0.192-0.348,0.001-0.407
							c-0.012,0.48,0.393,0.292,0.469,0.347c-0.225,0.181-0.535,0.293-0.584,0.619c0.427,0.169,1.029,0.095,1.326,0.445
							c0.331-0.123,0.548-0.34,0.698-0.611c-0.355-0.072-0.609-0.286-0.915-0.426c0.716-0.038,1.657,0.245,2.117,0.037
							c-0.141-0.028-0.198-0.144-0.254-0.261c0.001-0.001,0.002-0.002,0.003-0.003c0.273,0.28,0.422-0.004,0.615-0.179l0.14,0
							c-0.086,0.242-0.501,0.491-0.246,0.578c0.18-0.129,0.307-0.216,0.43-0.275c-0.003,0.021-0.005,0.044-0.003,0.07
							c0.131,0.381,0.736,0.101,0.93,0.394c0.301-0.221,0.809-0.006,1.011-0.442c0.546,0.064,1.044,0.16,1.134-0.149
							c0.015,0.038,0.031,0.076,0.048,0.112c-0.141,0.095-0.282,0.191-0.25,0.427c0.34-0.071,0.392,0.259,0.711,0.216
							c0.221-0.061,0.131-0.377,0.438-0.369c0.154,0.187,0.455,0.17,0.711,0.216c-0.01-0.105,0.062-0.144,0.066-0.239
							c0.243,0.044,0.47,0.021,0.611-0.225c0.005,0.187-0.062,0.298-0.13,0.407c0.49-0.105,1.103-0.232,1.038-0.541
							c0.145,0.576,0.437,0.23,0.627,0.008c0.208,0.198,0.485,0.132,0.803,0.055c0.215,0.059,0.437,0.07,0.612-0.094
							c0.049,0.001,0.099,0.004,0.149,0.01c-0.018,0.12-0.054,0.22-0.116,0.294c0.583,0.003,0.87-0.395,0.908,0.001
							c0.128-0.183,0.346-0.271,0.519-0.406c-0.038,0.277-0.351,0.266-0.39,0.542c0.51,0.037,0.901-0.051,1.038-0.406
							c-0.049-0.286-0.168-0.666,0.001-1.084C26.745,20.474,26.66,20.477,26.605,20.449z M27.464,18.936
							c0.042-0.059,0.132,0.057,0.109,0.089C27.531,19.084,27.441,18.968,27.464,18.936z M26.843,12.078
							C26.817,11.957,26.974,11.997,26.843,12.078C26.869,12.2,26.712,12.159,26.843,12.078z M26.651,12.104
							C26.256,11.842,26.896,11.723,26.651,12.104L26.651,12.104z M26.788,12.397c-0.025,0.131-0.095,0.225-0.26,0.242
							C26.454,12.427,26.638,12.426,26.788,12.397z M6.965,13.185c-0.059,0.002-0.115,0-0.109-0.089
							c-0.077-0.013-0.189,0.023-0.205-0.076c-0.114,0.026-0.079-0.14,0,0c0.077,0.013,0.189-0.022,0.205,0.076
							C6.914,13.094,6.971,13.096,6.965,13.185C7.079,13.159,7.043,13.325,6.965,13.185z M10.176,21.437
							c-0.008-0.02-0.016-0.038-0.026-0.053l0.093,0c0.001,0.001,0.001,0.002,0.002,0.003C10.223,21.404,10.199,21.42,10.176,21.437z
							M10.68,21.69c-0.042,0.059-0.132-0.058-0.109-0.089C10.613,21.541,10.703,21.658,10.68,21.69z M14.23,21.425
							c-0.005-0.013-0.011-0.025-0.016-0.036h0.044c0.005,0.003,0.01,0.005,0.015,0.008C14.258,21.407,14.242,21.417,14.23,21.425z
							M17.409,21.725c-0.016-0.003-0.033-0.003-0.051-0.003c0.015-0.004,0.031-0.007,0.046-0.01
							C17.406,21.716,17.408,21.72,17.409,21.725C17.523,21.7,17.487,21.866,17.409,21.725z M17.077,13.454
							c-0.001,0.509-0.104,0.952-0.309,1.33c-0.205,0.378-0.505,0.643-0.899,0.797l0.011,0.116c0.742,0.117,1.112,0.572,1.111,1.366
							l-0.001,0.891l0.544,0.001l-0.002,1.897l-3.673-0.005l0.004-2.718c0-0.231-0.025-0.418-0.075-0.561
							c-0.05-0.143-0.131-0.25-0.243-0.324c-0.112-0.073-0.253-0.123-0.423-0.151c-0.17-0.027-0.375-0.041-0.614-0.041l-0.002,1.897
							l0.765,0.001l-0.002,1.897L8.564,19.84l0.002-1.897l0.695,0.001l0.007-5.205l-0.695-0.001l0.002-1.897l5.435,0.007
							c0.471,0.001,0.898,0.047,1.28,0.14c0.382,0.093,0.704,0.242,0.967,0.447c0.262,0.205,0.465,0.473,0.607,0.805
							C17.007,12.572,17.078,12.977,17.077,13.454z M24.789,10.53c0.01,0.016,0.019,0.032,0.029,0.048
							c-0.013,0.002-0.025,0.004-0.039,0.007c-0.006-0.004-0.013-0.008-0.019-0.012C24.771,10.559,24.78,10.545,24.789,10.53z
							M19.829,9.905c0.165-0.121,0.361,0.262,0.383-0.051c0.059-0.002,0.115,0,0.109,0.089c0.001,0.011,0.003,0.022,0.004,0.034
							c-0.123,0.025-0.251,0.046-0.375,0.071C19.902,10.013,19.859,9.967,19.829,9.905C19.445,10.055,19.77,9.602,19.829,9.905z
							M20.445,21.839c-0.022-0.038-0.049-0.068-0.079-0.095c0.047,0.001,0.097,0.008,0.15,0.019
							C20.493,21.781,20.469,21.805,20.445,21.839z M25.237,13.326c-0.001,0.47-0.111,0.919-0.332,1.347
							c-0.221,0.428-0.501,0.836-0.842,1.225c-0.34,0.389-0.713,0.755-1.12,1.097c-0.406,0.343-0.795,0.664-1.166,0.964l1.414,0.002
							l0.001-0.671l2.26,0.003l-0.003,2.568l-7.358-0.01l0.002-1.77c0.603-0.508,1.156-0.991,1.659-1.45
							c0.503-0.458,0.936-0.881,1.3-1.271c0.364-0.389,0.646-0.732,0.847-1.028c0.201-0.297,0.302-0.538,0.302-0.722
							c0-0.301-0.065-0.519-0.196-0.654c-0.131-0.135-0.328-0.203-0.591-0.203c-0.263,0-0.46,0.067-0.591,0.202
							c-0.132,0.135-0.197,0.352-0.198,0.653l-0.001,0.578l-2.317-0.003l0.004-2.741c0.201-0.1,0.421-0.19,0.661-0.271
							c0.239-0.081,0.522-0.159,0.846-0.236c0.324-0.077,0.67-0.136,1.037-0.178c0.367-0.042,0.74-0.062,1.118-0.062
							c1.097,0.002,1.916,0.224,2.456,0.668C24.969,11.809,25.238,12.463,25.237,13.326z"/>
						<path fill="currentColor" d="M13.138,12.744l-0.626-0.001L12.51,14.64l0.626,0.001c0.224,0,0.392-0.057,0.504-0.173
							c0.112-0.115,0.168-0.304,0.169-0.566l0.001-0.416c0-0.262-0.055-0.451-0.167-0.567C13.53,12.802,13.362,12.744,13.138,12.744z"/>
  					</g>
				)
			case 'icon-eRallyH2FwdCaps':
				return (
					<g>
						<polygon fill="currentColor" points="13.961,17.366 12.732,17.366 13.326,14.591 11.14,14.591 9.781,20.975 11.956,20.975 12.561,18.13 13.8,18.13 
							13.195,20.975 15.371,20.975 16.75,14.591 14.555,14.591 	"/>
						<path fill="currentColor" d="M14.712,13.487c-0.027,0.024-0.041,0.049-0.042,0.073c-0.001,0.017,0.017,0.037,0.056,0.058
							c0.065,0.036,0.139,0.054,0.224,0.054c0.112,0,0.197-0.035,0.255-0.105c0.118-0.149,0.226-0.307,0.323-0.475l0.42-0.034
							l-0.113,0.184l-0.092,0.156c-0.026,0.046-0.047,0.084-0.062,0.115c-0.015,0.031-0.024,0.056-0.027,0.075
							c-0.003,0.019,0.002,0.039,0.015,0.059c0.035,0.06,0.116,0.089,0.244,0.089c0.155,0,0.245-0.046,0.268-0.138
							c0.042-0.169,0.114-0.36,0.218-0.576c0.127,0,0.211,0.005,0.249,0.015c0.029,0,0.046-0.004,0.052-0.014
							c0.013-0.045,0.02-0.094,0.02-0.146c0-0.072-0.015-0.125-0.046-0.158c-0.017-0.019-0.049-0.031-0.095-0.035l0.207-0.346
							c0.027-0.045,0.049-0.083,0.066-0.114c0.017-0.031,0.027-0.056,0.03-0.077c0.003-0.02,0.001-0.039-0.008-0.058
							c-0.008-0.019-0.03-0.028-0.064-0.028c-0.034,0-0.089,0.019-0.164,0.056c-0.04,0.019-0.11,0.093-0.211,0.222
							c-0.049,0.063-0.139,0.189-0.27,0.378L15.7,12.777l0.152-0.309c0.039-0.08,0.058-0.137,0.058-0.173
							c0-0.018-0.008-0.034-0.023-0.048c-0.034-0.031-0.071-0.047-0.11-0.047c-0.056,0-0.11,0.027-0.164,0.081
							c-0.022,0.023-0.043,0.051-0.066,0.085c-0.022,0.034-0.119,0.189-0.291,0.465l-0.434,0.053c-0.02,0.001-0.038,0.003-0.053,0.007
							c-0.015,0.004-0.029,0.015-0.042,0.033c-0.012,0.017-0.019,0.039-0.019,0.065c0,0.038,0.008,0.072,0.024,0.102
							c0.02,0.037,0.07,0.054,0.15,0.052l0.16-0.013C14.934,13.272,14.823,13.39,14.712,13.487z"/>
						<path fill="currentColor" d="M17.52,12.455c0.027,0.01,0.055,0.015,0.084,0.015c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.046-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.022,0.029-0.043,0.041-0.065c0.011-0.021,0.02-0.042,0.025-0.062
							c0.006-0.02,0.009-0.042,0.009-0.067c0-0.024-0.011-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C17.47,12.432,17.493,12.445,17.52,12.455z"/>
						<path fill="currentColor" d="M23.656,12.454c0.027,0.01,0.055,0.015,0.085,0.014c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.046-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.021,0.029-0.043,0.04-0.064c0.011-0.022,0.02-0.042,0.026-0.063
							c0.006-0.02,0.009-0.043,0.009-0.067c0-0.024-0.01-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C23.606,12.43,23.629,12.444,23.656,12.454z"/>
						<path fill="currentColor" d="M17.054,12.704c-0.083,0.062-0.151,0.138-0.204,0.229c-0.058,0.101-0.088,0.2-0.088,0.298c0,0.106,0.045,0.203,0.135,0.29
							c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035c0.048,0,0.097-0.008,0.147-0.025
							c0.05-0.016,0.102-0.038,0.155-0.066c0.053-0.027,0.106-0.059,0.159-0.096c0.053-0.037,0.105-0.075,0.154-0.115
							c0.05-0.04,0.095-0.081,0.138-0.123c0.013-0.013,0.025-0.026,0.038-0.039c0.03-0.014,0.061-0.035,0.093-0.062l0.101-0.085
							c0.033-0.027,0.061-0.054,0.083-0.079c0.011,0.02,0.025,0.039,0.042,0.059c0.017,0.02,0.032,0.041,0.048,0.061
							c0.038,0.051,0.057,0.094,0.057,0.13c0,0.039-0.014,0.058-0.043,0.058c-0.012,0-0.032-0.01-0.06-0.029
							c-0.01-0.007-0.022-0.014-0.034-0.019c-0.013-0.006-0.031-0.009-0.055-0.009c-0.024,0-0.06,0.014-0.109,0.043
							c-0.021,0.013-0.041,0.027-0.061,0.043c-0.02,0.016-0.037,0.032-0.053,0.048c-0.015,0.016-0.028,0.031-0.037,0.046
							c-0.009,0.015-0.013,0.029-0.013,0.043c0,0.014,0.008,0.032,0.025,0.056c0.017,0.024,0.042,0.047,0.074,0.07
							c0.077,0.053,0.168,0.08,0.273,0.08c0.188,0,0.359-0.043,0.513-0.129c0.073-0.041,0.144-0.09,0.212-0.147
							c0.026-0.022,0.052-0.045,0.079-0.068c-0.002,0.026-0.004,0.051-0.004,0.077c0,0.064,0.03,0.125,0.091,0.182
							c0.07,0.067,0.161,0.1,0.272,0.1c0.118,0,0.25-0.04,0.395-0.121c0.063-0.035,0.135-0.084,0.217-0.145
							c0.003-0.002,0.006-0.005,0.009-0.007c0.013,0.043,0.035,0.086,0.066,0.129c0.068,0.092,0.161,0.138,0.278,0.138
							c0.112,0,0.243-0.037,0.393-0.113c0.141-0.071,0.249-0.148,0.323-0.233c0.013,0.009,0.027,0.018,0.041,0.026
							c0.045,0.026,0.106,0.039,0.182,0.039c0.072,0,0.149-0.026,0.233-0.078c0.011-0.006,0.019-0.015,0.029-0.022
							c0.008,0.002,0.015,0.006,0.023,0.006c0.034,0,0.104-0.049,0.213-0.148l0.102-0.094c0.041-0.036,0.068-0.054,0.08-0.054
							c0.029,0,0.051,0.002,0.066,0.005c0.015,0.004,0.022,0.012,0.022,0.025c0,0.008-0.021,0.034-0.063,0.078
							c-0.049,0.051-0.092,0.109-0.128,0.175c-0.018,0.033-0.033,0.067-0.044,0.1c-0.011,0.034-0.017,0.065-0.017,0.094
							c0,0.029,0.005,0.056,0.016,0.079c0.033,0.079,0.126,0.118,0.278,0.118c0.104,0,0.219-0.03,0.343-0.091
							c0.055-0.026,0.107-0.056,0.157-0.089c0.049-0.033,0.094-0.066,0.135-0.098c0.032-0.025,0.059-0.048,0.084-0.071
							c0.016,0.078,0.058,0.15,0.127,0.217c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035
							c0.048,0,0.097-0.008,0.147-0.025c0.05-0.016,0.102-0.038,0.154-0.065c0.053-0.027,0.106-0.059,0.159-0.096
							c0.051-0.035,0.1-0.072,0.147-0.11c0.008,0.025,0.021,0.052,0.039,0.079c0.017,0.028,0.039,0.054,0.065,0.079
							c0.026,0.025,0.056,0.047,0.091,0.066c0.035,0.019,0.075,0.034,0.119,0.046c0.044,0.011,0.1,0.017,0.167,0.017
							c0.067,0,0.138-0.009,0.213-0.026c0.198-0.044,0.379-0.127,0.541-0.248c0.084-0.06,0.17-0.137,0.258-0.232
							c0.086-0.094,0.128-0.157,0.128-0.189c0-0.056-0.021-0.084-0.064-0.084c-0.043,0-0.114,0.038-0.214,0.115l-0.095,0.074
							c-0.2,0.159-0.374,0.239-0.525,0.239c-0.065,0-0.12-0.02-0.166-0.06c-0.021-0.019-0.037-0.04-0.049-0.064
							c-0.011-0.024-0.017-0.049-0.017-0.074c0-0.025,0.012-0.054,0.035-0.086c0.029-0.039,0.063-0.066,0.104-0.08
							c0.059,0.024,0.134,0.035,0.225,0.035c0.067,0,0.127-0.02,0.181-0.06c0.028-0.021,0.051-0.047,0.069-0.078
							c0.018-0.031,0.027-0.064,0.027-0.098c0-0.035-0.014-0.062-0.042-0.082c-0.012-0.009-0.027-0.016-0.044-0.021
							c-0.017-0.005-0.038-0.008-0.063-0.008c-0.025,0-0.067,0.005-0.126,0.014c-0.137,0.021-0.281,0.066-0.432,0.137
							c-0.08,0.037-0.149,0.076-0.206,0.117c-0.032,0.014-0.067,0.034-0.106,0.063l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075
							c-0.035,0.024-0.069,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045c-0.032,0.011-0.059,0.017-0.083,0.017
							c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073c0.022-0.019,0.05-0.051,0.086-0.095
							c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086c0.017-0.03,0.031-0.059,0.042-0.088
							s0.016-0.056,0.016-0.079c0-0.023-0.001-0.043-0.003-0.06c-0.007-0.057-0.061-0.085-0.163-0.085c-0.071,0-0.148,0.031-0.23,0.093
							c-0.082,0.061-0.149,0.136-0.202,0.225c-0.015,0.009-0.032,0.02-0.051,0.033l-0.087,0.061c-0.032,0.024-0.095,0.067-0.191,0.129
							c-0.035,0.022-0.066,0.041-0.093,0.057c-0.027,0.016-0.05,0.027-0.068,0.035c-0.018,0.008-0.035,0.012-0.05,0.012
							c-0.015,0-0.028-0.005-0.041-0.015c-0.012-0.01-0.019-0.022-0.019-0.035c0-0.013,0.025-0.045,0.074-0.096l0.069-0.068
							c0.095-0.093,0.143-0.16,0.143-0.203c0-0.042-0.015-0.08-0.044-0.114c-0.013-0.015-0.029-0.028-0.045-0.037
							c-0.017-0.009-0.032-0.014-0.045-0.014c-0.013,0-0.031,0.001-0.054,0.003l-0.148,0.01c-0.024,0.002-0.041,0.003-0.052,0.003
							c-0.011,0-0.022-0.009-0.033-0.026l-0.014-0.023c-0.021-0.033-0.061-0.05-0.12-0.05c-0.067,0-0.147,0.032-0.24,0.096
							c-0.087,0.059-0.163,0.129-0.228,0.21c-0.031,0.039-0.055,0.075-0.072,0.108c-0.038,0.016-0.072,0.025-0.1,0.025
							c-0.064,0-0.096-0.019-0.096-0.056c0-0.01,0.002-0.02,0.006-0.031c0.004-0.011,0.006-0.027,0.006-0.047
							c0-0.068-0.022-0.124-0.067-0.168c-0.019-0.019-0.048-0.039-0.087-0.058c-0.039-0.019-0.084-0.029-0.134-0.029
							c-0.112,0-0.238,0.022-0.378,0.066c-0.066,0.021-0.14,0.05-0.222,0.089c-0.059,0.028-0.119,0.065-0.181,0.111
							c-0.019,0.004-0.042,0.014-0.071,0.033l-0.073,0.051c-0.026,0.019-0.058,0.046-0.097,0.082c-0.039,0.036-0.092,0.075-0.161,0.118
							c-0.037,0.023-0.076,0.042-0.115,0.058c-0.039,0.016-0.079,0.024-0.121,0.024c-0.041,0-0.074-0.016-0.098-0.047
							c-0.025-0.031-0.037-0.07-0.037-0.117c0-0.047,0.023-0.122,0.07-0.227l0.023-0.051c0.005-0.011,0.013-0.027,0.025-0.049
							l0.047-0.087c0.02-0.036,0.034-0.056,0.043-0.06l0.382-0.001c0.188,0,0.283-0.053,0.283-0.158c0-0.044-0.022-0.067-0.065-0.067
							l-0.496,0.001l0.058-0.142c0.008-0.019,0.011-0.034,0.011-0.045c0-0.026-0.017-0.051-0.051-0.073
							c-0.017-0.01-0.039-0.019-0.064-0.026c-0.025-0.007-0.057-0.01-0.094-0.011c-0.037,0-0.07,0.011-0.097,0.034
							c-0.028,0.023-0.055,0.059-0.082,0.107l-0.064,0.109c-0.017,0.028-0.028,0.043-0.033,0.045l-0.255-0.001
							c-0.046,0-0.081,0.005-0.104,0.015c-0.017,0.006-0.031,0.016-0.042,0.03c-0.011,0.014-0.017,0.036-0.017,0.066
							c0,0.03,0.014,0.057,0.042,0.08c0.028,0.024,0.067,0.035,0.118,0.035l0.135,0c0,0.007-0.006,0.022-0.019,0.046
							c-0.038,0.069-0.074,0.15-0.108,0.243c-0.003,0.001-0.006,0.001-0.009,0.003c-0.024,0.009-0.071,0.043-0.142,0.102l-0.122,0.1
							c-0.101,0.083-0.188,0.138-0.261,0.166c0.022-0.017,0.041-0.035,0.059-0.054c0.059-0.062,0.089-0.114,0.089-0.158
							c0-0.065-0.032-0.133-0.096-0.205c-0.026-0.029-0.054-0.056-0.082-0.081c-0.028-0.025-0.051-0.046-0.069-0.065
							c0.023-0.028,0.034-0.054,0.034-0.076c0-0.023-0.003-0.041-0.01-0.055c-0.023-0.047-0.059-0.071-0.107-0.071
							c-0.077,0-0.154,0.028-0.232,0.083c-0.082,0.059-0.123,0.124-0.123,0.195l-0.085,0.057c-0.039,0.012-0.084,0.035-0.137,0.074
							l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075c-0.035,0.024-0.07,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045
							c-0.032,0.011-0.059,0.017-0.082,0.017c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073
							c0.022-0.019,0.05-0.051,0.086-0.095c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086
							c0.017-0.03,0.031-0.059,0.042-0.089c0.011-0.029,0.016-0.056,0.016-0.078c0-0.023-0.001-0.043-0.003-0.06
							c-0.007-0.057-0.061-0.085-0.163-0.085C17.214,12.611,17.137,12.642,17.054,12.704z M20.529,13.174l0.001-0.003
							c0-0.012,0.008-0.028,0.025-0.049c0.052-0.061,0.13-0.121,0.235-0.178c0.095-0.052,0.17-0.078,0.225-0.078
							c-0.044,0.05-0.066,0.1-0.066,0.15c0,0.023,0.005,0.047,0.017,0.069c-0.064,0.052-0.136,0.095-0.215,0.129
							c-0.032,0.014-0.066,0.025-0.101,0.035c-0.035,0.009-0.069,0.004-0.101-0.015c-0.013-0.009-0.02-0.028-0.02-0.058V13.174z"/>
						<path fill="currentColor" d="M16.462,21.018h7.326l1.363-6.427h-7.326L16.462,21.018z M18.545,19.395c0.343-0.109,0.676-0.247,0.999-0.413
							c0.323-0.166,0.611-0.367,0.865-0.603c0.254-0.236,0.456-0.51,0.608-0.822c0.152-0.312,0.227-0.672,0.227-1.079
							c0-0.198-0.02-0.352-0.06-0.461c-0.04-0.109-0.109-0.164-0.209-0.164c-0.09,0-0.173,0.042-0.25,0.126
							c-0.077,0.085-0.147,0.191-0.209,0.32s-0.119,0.273-0.171,0.432c-0.052,0.159-0.096,0.313-0.131,0.461h-1.349l0.246-1.116
							c0.278-0.238,0.571-0.423,0.88-0.554c0.308-0.131,0.656-0.197,1.044-0.197c0.238,0,0.463,0.027,0.674,0.082
							c0.211,0.055,0.395,0.14,0.552,0.257c0.156,0.116,0.281,0.263,0.373,0.439c0.092,0.176,0.138,0.386,0.138,0.629
							c0,0.184-0.031,0.365-0.093,0.543s-0.171,0.363-0.328,0.554c-0.157,0.191-0.368,0.389-0.634,0.595
							c-0.266,0.206-0.602,0.428-1.01,0.666h1.662l-0.149,1.034h-3.839L18.545,19.395z"/>
						<path fill="currentColor" d="M28.213,12.406l0.002-0.001l-0.871-1.202l-0.008,0.002c-0.013-0.017-0.023-0.035-0.037-0.051
							c-0.292-0.362-0.743-0.569-1.235-0.569H14.353c0.01,0.074,0.017,0.148,0.017,0.225v0.5h11.694c0.581,0,0.952,0.471,0.827,1.053
							l-1.811,8.572c-0.125,0.582-0.698,1.053-1.279,1.053H9.389c-0.582,0-0.952-0.472-0.827-1.053l1.403-6.639H9.224l-1.371,6.487
							c-0.107,0.497,0.003,0.993,0.3,1.361c0.008,0.009,0.016,0.017,0.024,0.027l0.782,1.076l0.002,0
							c0.009,0.012,0.017,0.025,0.026,0.037c0.292,0.362,0.743,0.569,1.235,0.569h14.411c0.919,0,1.792-0.714,1.988-1.628l1.811-8.569
							C28.529,13.206,28.448,12.759,28.213,12.406z"/>
						<path fill="currentColor" d="M11.437,9.713c-0.017,0-0.047,0.002-0.089,0.005v2.316c0.005-0.001,0.014-0.002,0.027-0.002
							c0.009,0,0.026,0.001,0.051,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193v-1.785
							c0-0.132-0.021-0.222-0.062-0.271C11.612,9.738,11.54,9.713,11.437,9.713z"/>
						<path fill="currentColor" d="M13.676,12.159v-2.56c0-0.801-0.649-1.45-1.45-1.45H4.977c-0.801,0-1.45,0.649-1.45,1.45v2.56
							c0,0.801,0.649,1.45,1.45,1.45h7.249C13.027,13.609,13.676,12.959,13.676,12.159z M6.454,9.783H5.658v0.572h0.755v0.697H5.658
							v1.642H4.853V9.077h1.602V9.783z M9.599,12.695h-0.96l-0.2-1.744l-0.191,1.744H7.271l-0.43-3.618h0.783l0.221,2.283L8.07,9.077
							h0.746L9.03,11.36l0.214-2.283h0.792L9.599,12.695z M10.543,12.695V9.077h1.104c0.299,0,0.517,0.076,0.654,0.228
							c0.137,0.152,0.207,0.361,0.208,0.626l0.009,1.69c0,0.362-0.071,0.631-0.213,0.809c-0.142,0.177-0.37,0.266-0.686,0.266H10.543z"/>
  					</g>
				)
			case 'icon-eRallyH1FwdCaps':
				return (
					<g>
						<polygon fill="currentColor" points="13.963,17.366 12.735,17.366 13.329,14.591 11.143,14.591 9.784,20.975 11.959,20.975 12.563,18.13 13.802,18.13 
							13.198,20.975 15.373,20.975 16.753,14.591 14.558,14.591 	"/>
						<path fill="currentColor" d="M16.465,21.018h7.326l1.363-6.427h-7.326L16.465,21.018z M19.55,16.229c0.313-0.045,0.597-0.139,0.852-0.279
							c0.255-0.141,0.458-0.307,0.61-0.499h1.159l-1.03,4.8h-1.636l0.75-3.515h-0.818L19.55,16.229z"/>
						<path fill="currentColor" d="M14.714,13.487c-0.027,0.024-0.041,0.049-0.042,0.073c-0.001,0.017,0.017,0.037,0.056,0.058
							c0.065,0.036,0.139,0.054,0.224,0.054c0.112,0,0.197-0.035,0.255-0.105c0.118-0.149,0.226-0.307,0.323-0.475l0.42-0.034
							l-0.113,0.184l-0.092,0.156c-0.026,0.046-0.047,0.084-0.062,0.115c-0.015,0.031-0.024,0.056-0.027,0.075
							c-0.003,0.019,0.002,0.039,0.015,0.059c0.035,0.06,0.116,0.089,0.244,0.089c0.155,0,0.245-0.046,0.268-0.138
							c0.042-0.169,0.114-0.36,0.218-0.576c0.127,0,0.211,0.005,0.249,0.015c0.029,0,0.046-0.004,0.052-0.014
							c0.013-0.045,0.02-0.094,0.02-0.146c0-0.072-0.015-0.125-0.046-0.158c-0.017-0.019-0.049-0.031-0.095-0.035l0.207-0.346
							c0.027-0.045,0.049-0.083,0.066-0.114c0.017-0.031,0.027-0.056,0.03-0.077c0.003-0.02,0.001-0.039-0.008-0.058
							c-0.008-0.019-0.03-0.028-0.064-0.028c-0.034,0-0.089,0.019-0.164,0.056c-0.04,0.019-0.11,0.093-0.211,0.222
							c-0.049,0.063-0.139,0.189-0.27,0.378l-0.467,0.058l0.152-0.309c0.039-0.08,0.058-0.137,0.058-0.173
							c0-0.018-0.008-0.034-0.023-0.048c-0.034-0.031-0.071-0.047-0.11-0.047c-0.056,0-0.11,0.027-0.164,0.081
							c-0.022,0.023-0.043,0.051-0.066,0.085c-0.022,0.034-0.119,0.189-0.291,0.465l-0.434,0.053c-0.02,0.001-0.038,0.003-0.053,0.007
							c-0.015,0.004-0.029,0.015-0.042,0.033c-0.012,0.017-0.019,0.039-0.019,0.065c0,0.038,0.008,0.072,0.024,0.102
							c0.02,0.037,0.07,0.054,0.15,0.052l0.16-0.013C14.937,13.272,14.826,13.39,14.714,13.487z"/>
						<path fill="currentColor" d="M17.523,12.455c0.027,0.01,0.055,0.015,0.084,0.015c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.046-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.022,0.029-0.043,0.041-0.065c0.011-0.021,0.02-0.042,0.025-0.062
							c0.006-0.02,0.009-0.042,0.009-0.067c0-0.024-0.011-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C17.473,12.432,17.496,12.445,17.523,12.455z"/>
						<path fill="currentColor" d="M17.057,12.704c-0.083,0.062-0.151,0.138-0.204,0.229c-0.058,0.101-0.088,0.2-0.088,0.298c0,0.106,0.045,0.203,0.135,0.29
							c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035c0.048,0,0.097-0.008,0.147-0.025
							c0.05-0.016,0.102-0.038,0.155-0.066c0.053-0.027,0.106-0.059,0.159-0.096c0.053-0.037,0.105-0.075,0.154-0.115
							c0.05-0.04,0.095-0.081,0.138-0.123c0.013-0.013,0.025-0.026,0.038-0.039c0.03-0.014,0.061-0.035,0.093-0.062l0.101-0.085
							c0.033-0.027,0.061-0.054,0.083-0.079c0.011,0.02,0.025,0.039,0.042,0.059c0.017,0.02,0.032,0.041,0.048,0.061
							c0.038,0.051,0.057,0.094,0.057,0.13c0,0.039-0.014,0.058-0.043,0.058c-0.012,0-0.032-0.01-0.06-0.029
							c-0.01-0.007-0.022-0.014-0.034-0.019c-0.013-0.006-0.031-0.009-0.055-0.009c-0.024,0-0.06,0.014-0.109,0.043
							c-0.021,0.013-0.041,0.027-0.061,0.043c-0.02,0.016-0.037,0.032-0.053,0.048c-0.015,0.016-0.028,0.031-0.037,0.046
							c-0.009,0.015-0.013,0.029-0.013,0.043c0,0.014,0.009,0.032,0.025,0.056c0.017,0.024,0.042,0.047,0.074,0.07
							c0.077,0.053,0.168,0.08,0.273,0.08c0.188,0,0.359-0.043,0.513-0.129c0.073-0.041,0.144-0.09,0.212-0.147
							c0.026-0.022,0.052-0.045,0.079-0.068c-0.002,0.026-0.004,0.051-0.004,0.077c0,0.064,0.03,0.125,0.091,0.182
							c0.07,0.067,0.161,0.1,0.272,0.1c0.118,0,0.25-0.04,0.395-0.121c0.062-0.035,0.135-0.084,0.217-0.145
							c0.003-0.002,0.006-0.005,0.009-0.007c0.013,0.043,0.035,0.086,0.066,0.129c0.068,0.092,0.161,0.138,0.278,0.138
							c0.112,0,0.243-0.037,0.393-0.113c0.141-0.071,0.249-0.148,0.323-0.233c0.013,0.009,0.027,0.018,0.041,0.026
							c0.045,0.026,0.106,0.039,0.182,0.039c0.072,0,0.149-0.026,0.233-0.078c0.011-0.006,0.019-0.015,0.029-0.022
							c0.008,0.002,0.015,0.006,0.023,0.006c0.034,0,0.104-0.049,0.213-0.148l0.102-0.094c0.041-0.036,0.068-0.054,0.08-0.054
							c0.029,0,0.051,0.002,0.066,0.005c0.015,0.004,0.022,0.012,0.022,0.025c0,0.008-0.021,0.034-0.063,0.078
							c-0.049,0.051-0.092,0.109-0.128,0.175c-0.018,0.033-0.033,0.067-0.044,0.1c-0.011,0.034-0.017,0.065-0.017,0.094
							c0,0.029,0.005,0.056,0.016,0.079c0.033,0.079,0.126,0.118,0.278,0.118c0.104,0,0.219-0.03,0.343-0.091
							c0.055-0.026,0.107-0.056,0.157-0.089c0.049-0.033,0.094-0.066,0.135-0.098c0.032-0.025,0.059-0.048,0.084-0.071
							c0.016,0.078,0.058,0.15,0.127,0.217c0.04,0.039,0.086,0.07,0.138,0.093c0.052,0.023,0.102,0.035,0.15,0.035
							c0.048,0,0.097-0.008,0.147-0.025c0.05-0.016,0.102-0.038,0.154-0.065c0.053-0.027,0.106-0.059,0.159-0.096
							c0.051-0.035,0.1-0.072,0.147-0.11c0.008,0.025,0.021,0.052,0.039,0.079c0.017,0.028,0.039,0.054,0.065,0.079
							c0.026,0.025,0.056,0.047,0.091,0.066c0.035,0.019,0.075,0.034,0.119,0.046c0.044,0.011,0.1,0.017,0.167,0.017
							c0.067,0,0.138-0.009,0.213-0.026c0.198-0.044,0.379-0.127,0.541-0.248c0.084-0.06,0.17-0.137,0.258-0.232
							c0.086-0.094,0.128-0.157,0.128-0.189c0-0.056-0.021-0.084-0.064-0.084c-0.043,0-0.114,0.038-0.214,0.115l-0.095,0.074
							c-0.2,0.159-0.374,0.239-0.525,0.239c-0.065,0-0.12-0.02-0.166-0.06c-0.021-0.019-0.037-0.04-0.049-0.064
							c-0.011-0.024-0.017-0.049-0.017-0.074c0-0.025,0.012-0.054,0.035-0.086c0.029-0.039,0.063-0.066,0.104-0.08
							c0.059,0.024,0.134,0.035,0.225,0.035c0.067,0,0.127-0.02,0.181-0.06c0.028-0.021,0.051-0.047,0.069-0.078
							c0.018-0.031,0.027-0.064,0.027-0.098c0-0.035-0.014-0.062-0.042-0.082c-0.012-0.009-0.027-0.016-0.044-0.021
							c-0.017-0.005-0.038-0.008-0.063-0.008c-0.025,0-0.067,0.005-0.126,0.014c-0.137,0.021-0.281,0.066-0.432,0.137
							c-0.08,0.037-0.149,0.076-0.206,0.117c-0.032,0.014-0.067,0.034-0.106,0.063l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075
							c-0.035,0.024-0.069,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045c-0.032,0.011-0.059,0.017-0.083,0.017
							c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073c0.022-0.019,0.05-0.051,0.086-0.095
							c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086c0.017-0.03,0.031-0.059,0.042-0.088
							s0.016-0.056,0.016-0.079c0-0.023-0.001-0.043-0.003-0.06c-0.007-0.057-0.061-0.085-0.163-0.085c-0.071,0-0.148,0.031-0.23,0.093
							c-0.082,0.061-0.149,0.136-0.202,0.225c-0.016,0.009-0.032,0.02-0.051,0.033l-0.087,0.061c-0.032,0.024-0.095,0.067-0.191,0.129
							c-0.035,0.022-0.066,0.041-0.093,0.057c-0.027,0.016-0.05,0.027-0.068,0.035c-0.018,0.008-0.035,0.012-0.05,0.012
							c-0.015,0-0.028-0.005-0.041-0.015c-0.012-0.01-0.019-0.022-0.019-0.035c0-0.013,0.025-0.045,0.074-0.096l0.069-0.068
							c0.095-0.093,0.143-0.16,0.143-0.203c0-0.042-0.015-0.08-0.044-0.114c-0.013-0.015-0.029-0.028-0.046-0.037
							c-0.017-0.009-0.032-0.014-0.045-0.014c-0.013,0-0.031,0.001-0.054,0.003l-0.148,0.01c-0.024,0.002-0.041,0.003-0.052,0.003
							c-0.011,0-0.022-0.009-0.033-0.026l-0.014-0.023c-0.021-0.033-0.061-0.05-0.12-0.05c-0.067,0-0.147,0.032-0.24,0.096
							c-0.087,0.059-0.163,0.129-0.228,0.21c-0.031,0.039-0.055,0.075-0.072,0.108c-0.038,0.016-0.072,0.025-0.1,0.025
							c-0.064,0-0.096-0.019-0.096-0.056c0-0.01,0.002-0.02,0.006-0.031c0.004-0.011,0.006-0.027,0.006-0.047
							c0-0.068-0.022-0.124-0.067-0.168c-0.019-0.019-0.048-0.039-0.087-0.058c-0.039-0.019-0.084-0.029-0.134-0.029
							c-0.112,0-0.238,0.022-0.378,0.066c-0.066,0.021-0.14,0.05-0.222,0.089c-0.059,0.028-0.119,0.065-0.181,0.111
							c-0.019,0.004-0.042,0.014-0.071,0.033l-0.073,0.051c-0.026,0.019-0.058,0.046-0.097,0.082c-0.039,0.036-0.092,0.075-0.161,0.118
							c-0.037,0.023-0.076,0.042-0.115,0.058c-0.039,0.016-0.079,0.024-0.121,0.024c-0.041,0-0.074-0.016-0.098-0.047
							c-0.025-0.031-0.037-0.07-0.037-0.117c0-0.047,0.023-0.122,0.07-0.227l0.023-0.051c0.005-0.011,0.013-0.027,0.025-0.049
							l0.047-0.087c0.02-0.036,0.034-0.056,0.043-0.06l0.382-0.001c0.188,0,0.283-0.053,0.283-0.158c0-0.044-0.022-0.067-0.065-0.067
							l-0.496,0.001l0.058-0.142c0.008-0.019,0.011-0.034,0.011-0.045c0-0.026-0.017-0.051-0.051-0.073
							c-0.017-0.01-0.039-0.019-0.064-0.026c-0.025-0.007-0.057-0.01-0.094-0.011c-0.037,0-0.07,0.011-0.097,0.034
							c-0.028,0.023-0.055,0.059-0.082,0.107l-0.064,0.109c-0.017,0.028-0.028,0.043-0.033,0.045l-0.255-0.001
							c-0.046,0-0.081,0.005-0.104,0.015c-0.017,0.006-0.031,0.016-0.042,0.03c-0.011,0.014-0.017,0.036-0.017,0.066
							c0,0.03,0.014,0.057,0.042,0.08c0.028,0.024,0.067,0.035,0.118,0.035l0.135,0c0,0.007-0.006,0.022-0.019,0.046
							c-0.038,0.069-0.074,0.15-0.108,0.243c-0.003,0.001-0.006,0.001-0.009,0.003c-0.024,0.009-0.071,0.043-0.142,0.102l-0.122,0.1
							c-0.101,0.083-0.188,0.138-0.261,0.166c0.022-0.017,0.041-0.035,0.059-0.054c0.059-0.062,0.089-0.114,0.089-0.158
							c0-0.065-0.032-0.133-0.096-0.205c-0.026-0.029-0.054-0.056-0.082-0.081c-0.028-0.025-0.051-0.046-0.069-0.065
							c0.023-0.028,0.034-0.054,0.034-0.076c0-0.023-0.003-0.041-0.01-0.055c-0.023-0.047-0.059-0.071-0.107-0.071
							c-0.077,0-0.154,0.028-0.232,0.083c-0.082,0.059-0.123,0.124-0.123,0.195l-0.085,0.057c-0.039,0.012-0.084,0.035-0.137,0.074
							l-0.191,0.149c-0.034,0.026-0.069,0.051-0.104,0.075c-0.035,0.024-0.07,0.046-0.103,0.065c-0.034,0.019-0.066,0.034-0.098,0.045
							c-0.032,0.011-0.059,0.017-0.082,0.017c-0.023,0-0.052-0.012-0.085-0.035c-0.035-0.025-0.053-0.049-0.053-0.073
							c0.022-0.019,0.05-0.051,0.086-0.095c0.019-0.023,0.039-0.048,0.059-0.077c0.021-0.028,0.039-0.057,0.056-0.086
							c0.017-0.03,0.031-0.059,0.042-0.089c0.011-0.029,0.016-0.056,0.016-0.078c0-0.023-0.001-0.043-0.003-0.06
							c-0.007-0.057-0.061-0.085-0.163-0.085C17.217,12.611,17.14,12.642,17.057,12.704z M20.532,13.174l0.001-0.003
							c0-0.012,0.008-0.028,0.025-0.049c0.052-0.061,0.13-0.121,0.235-0.178c0.095-0.052,0.17-0.078,0.226-0.078
							c-0.044,0.05-0.066,0.1-0.066,0.15c0,0.023,0.005,0.047,0.017,0.069c-0.064,0.052-0.136,0.095-0.215,0.129
							c-0.032,0.014-0.066,0.025-0.101,0.035c-0.035,0.009-0.069,0.004-0.101-0.015c-0.013-0.009-0.02-0.028-0.02-0.058V13.174z"/>
						<path fill="currentColor" d="M23.659,12.454c0.027,0.01,0.055,0.015,0.085,0.014c0.029,0,0.056-0.005,0.079-0.016c0.024-0.011,0.045-0.025,0.066-0.042
							c0.02-0.017,0.038-0.036,0.054-0.058c0.016-0.021,0.029-0.043,0.04-0.064c0.011-0.022,0.02-0.042,0.026-0.063
							c0.006-0.02,0.009-0.043,0.009-0.067c0-0.024-0.01-0.049-0.031-0.072c-0.01-0.012-0.024-0.021-0.04-0.029
							c-0.016-0.007-0.041-0.011-0.074-0.011c-0.033,0-0.074,0.008-0.125,0.023c-0.15,0.044-0.225,0.111-0.225,0.2
							c0,0.058,0.022,0.106,0.066,0.144C23.609,12.43,23.632,12.444,23.659,12.454z"/>
						<path fill="currentColor" d="M28.21,12.406l0.002-0.001l-0.871-1.202l-0.004,0.001c-0.012-0.016-0.023-0.034-0.036-0.05
							c-0.292-0.362-0.743-0.569-1.235-0.569H14.355c0.01,0.074,0.017,0.148,0.017,0.225v0.5h11.694c0.581,0,0.952,0.471,0.827,1.053
							l-1.811,8.572c-0.125,0.582-0.698,1.053-1.279,1.053H9.392c-0.582,0-0.952-0.472-0.827-1.053l1.403-6.639H9.227l-1.371,6.487
							c-0.097,0.451-0.014,0.901,0.225,1.256L8.08,22.039l0.877,1.207l0.002,0c0.009,0.012,0.017,0.025,0.026,0.037
							c0.292,0.362,0.743,0.569,1.235,0.569h14.411c0.919,0,1.792-0.714,1.988-1.628l1.811-8.569
							C28.526,13.206,28.445,12.759,28.21,12.406z"/>
						<path fill="currentColor" d="M11.44,9.713c-0.017,0-0.047,0.002-0.089,0.005v2.316c0.005-0.001,0.014-0.002,0.027-0.002
							c0.009,0,0.026,0.001,0.051,0.002c0.025,0.002,0.045,0.002,0.06,0.002c0.153,0,0.23-0.064,0.23-0.193v-1.785
							c0-0.132-0.021-0.222-0.062-0.271C11.615,9.738,11.543,9.713,11.44,9.713z"/>
						<path fill="currentColor" d="M13.679,12.159v-2.56c0-0.801-0.649-1.45-1.45-1.45H4.98c-0.801,0-1.45,0.649-1.45,1.45v2.56c0,0.801,0.649,1.45,1.45,1.45
							h7.249C13.03,13.609,13.679,12.959,13.679,12.159z M6.457,9.783H5.661v0.572h0.755v0.697H5.661v1.642H4.855V9.077h1.602V9.783z
							M9.602,12.695h-0.96l-0.2-1.744L8.25,12.695H7.274l-0.43-3.618h0.783l0.221,2.283l0.225-2.283h0.746l0.214,2.283l0.214-2.283
							h0.792L9.602,12.695z M10.546,12.695V9.077h1.104c0.299,0,0.517,0.076,0.654,0.228c0.137,0.152,0.207,0.361,0.208,0.626l0.009,1.69
							c0,0.362-0.071,0.631-0.213,0.809c-0.142,0.177-0.37,0.266-0.686,0.266H10.546z"/>
  					</g>
				)
			case 'icon-eRallyxLitesCaps':
				return (
					<g>
					  	<path fill="currentColor"  d="M31.261,12.126L28.245,9.11l-6.026,0l-1.386,0.017h-5.138l-0.493,0.811l-0.544-0.811H0.739V22.89h30.522v-4.801h-2.085
							l2.085-2.085V12.126z M30.646,15.75l-2.655,2.655l0.005,0.299h2.65v3.572H19.562H1.354v-2.53V9.741h12.977l0.897,1.338l0.813-1.338
							h4.792l1.393-0.017h5.765l2.655,2.655V15.75z"/>
						<polygon fill="currentColor"  points="7.587,18.988 10.606,18.988 9.014,16.045 10.319,14.74 10.319,12.194 8.617,10.498 5.386,10.498 5.386,12.194 
							7.51,12.194 7.51,14.797 5.386,14.797 5.386,16.441 6.371,16.441 	"/>
						<rect fill="currentColor"  x="2.111" y="10.498" width="2.828" height="8.489"/>
						<polygon fill="currentColor"  points="19.499,10.498 16.467,10.498 15.475,12.13 16.985,14.337 	"/>
						<polygon fill="currentColor"  points="13.409,15.034 10.703,18.988 13.933,18.988 14.925,17.337 	"/>
						<polygon fill="currentColor"  points="10.875,10.498 16.537,18.988 19.621,18.988 13.927,10.498 	"/>
						<rect fill="currentColor"  x="2.111" y="19.745" width="17.51" height="1.731"/>
						<polygon fill="currentColor"  points="24.025,12.481 25.603,12.481 25.603,10.498 22.565,10.498 20.359,12.696 20.359,14.081 24.025,14.081 	"/>
						<rect fill="currentColor"  x="24.605" y="19.522" width="5.244" height="1.982"/>
						<polygon fill="currentColor"  points="26.183,10.498 26.183,15.35 22.706,15.35 20.359,17.556 20.359,21.504 24.025,21.504 24.025,17.614 27.651,17.614 
							29.849,15.417 29.849,12.696 27.651,10.498 	"/>
					</g>
				)
			case 'icon-eRally':
				return (
					<g>
						<path fill="currentColor" d="M17.68,7L2.25,9.83L0.08,20.88l14.17,3.18l0.08,0.02l15.5-3.19L32,9.83L17.68,7z M29.4,20.51l-14.95,3.1L0.7,20.51l1.98-10.29l14.88-2.75l13.82,2.75L29.4,20.51z"/>
						<polygon fill="currentColor" points="17.39,8.37 3.87,10.81 29.97,10.81"/>
						<polygon fill="currentColor" points="14.65,22.56 28.37,19.89 1.97,19.89"/>
						<path fill="currentColor" d="M4.98,16.04h1.04L5.5,18.8h2.18l0.39-2.08c0.04-0.23,0.05-0.43,0.01-0.58c-0.03-0.15-0.09-0.28-0.17-0.37
							c-0.08-0.09-0.17-0.16-0.27-0.2c-0.1-0.04-0.2-0.07-0.29-0.07c0.21-0.03,0.38-0.1,0.53-0.19c0.14-0.09,0.26-0.2,0.35-0.33
							c0.09-0.13,0.16-0.27,0.21-0.43c0.05-0.16,0.09-0.32,0.13-0.49c0.03-0.18,0.06-0.36,0.09-0.53c0.02-0.17,0.03-0.34,0.01-0.49
							c-0.02-0.15-0.07-0.3-0.14-0.42c-0.08-0.13-0.2-0.24-0.37-0.33c-0.17-0.09-0.4-0.17-0.68-0.22C7.19,12,6.83,11.97,6.41,11.97
							H3.56L2.28,18.8h2.18L4.98,16.04z M5.55,12.99h1.04l-0.38,2.04H5.17L5.55,12.99z"/>
						<path fill="currentColor" d="M14.41,18.8l0.15-6.83h-3.75l-2.4,6.83h2.26l0.5-1.68h1.13l-0.04,1.68H14.41z M11.41,16.35l1.08-3.38
							l-0.18,3.38H11.41z"/>
						<polygon fill="currentColor" points="16.47,11.97 15.19,18.8 18.72,18.8 18.91,17.79 17.56,17.79 18.65,11.97"/>
						<polygon fill="currentColor" points="20.88,11.97 19.59,18.8 23.13,18.8 23.32,17.79 21.97,17.79 23.06,11.97"/>
						<polygon fill="currentColor" points="26.33,14.57 26.09,11.97 23.92,11.97 24.83,16.84 24.46,18.8 26.64,18.8 27,16.88 29.7,11.97 27.58,11.97 			"/>
					</g>
				)
			case 'icon-eRallycross':
				return (
					<g>
						<path fill="currentColor" d="M15.2,17.8c-0.3,0-0.6,0-0.8,0.4c-0.2,0.3-0.3,0.8-0.3,1c0,0.5,0.3,0.6,0.6,0.6c0.4,0,0.6-0.1,0.8-0.4
	c0.1-0.1,0.3-0.6,0.3-1.1C15.8,18,15.6,17.8,15.2,17.8z"/>
						<path fill="currentColor" d="M10,17.7H9.1l-0.2,0.9h0.8c0.4,0,0.6-0.3,0.6-0.6C10.4,17.8,10.2,17.7,10,17.7z"/>
						<path fill="currentColor" d="M6.8,13.2c0-0.2-0.1-0.3-0.4-0.3H5.6l-0.2,0.9h0.8C6.6,13.8,6.8,13.4,6.8,13.2z"/>
						<polygon fill="currentColor" points="11.9,12.7 11.9,12.7 10.9,14.3 11.9,14.3 "/>
						<path fill="currentColor" d="M28.2,16.1c-0.9-0.1-1.9-0.2-2.6-0.2c-0.2,0-0.3,0-0.5,0l3.4-4.7l0,0h-3.3l0,0l-0.6,1l-0.1-1.1l0,0h-1.2
							l0.9-0.9l-3-3l-3.9,4h-2.7l0,0l-0.3,1.2l0,0v-1.2l0,0h-1.2l-3.9-4l-3,3l0.9,0.9H3.7l0,0l-1.4,5.3H3c-0.1,0.1-0.1,0.1-0.2,0.2l0,0
							l0,0c-0.7,0.8-1,2.3-1,3.1c0,0.6,0.2,1.1,0.7,1.4s1,0.3,1.7,0.3c0.4,0,1,0,1.7-0.1l0,0l0,0l0,0h1.3l-1,1l3,3l4-4
							c0.4,0.1,0.9,0.1,1.4,0.1c0.6,0,1.4,0,2.1-0.3l0.4,0.1l4.2,4.2l3-3l-1-1c0.5,0.1,1.1,0.1,1.7,0.1c0.6,0,1.1,0,1.5-0.2
							c0.4-0.1,0.8-0.4,1-0.8c0.2-0.3,0.3-0.8,0.4-1.3c0-0.4-0.1-0.7-0.3-0.9c0,0,0.1,0,0.1,0l0.4,0l0.6-2.2l0,0l0,0L28.2,16.1z
							M24.1,11.6l0.2,1.9h0l1.1-1.9h2.2L24.5,16h-2.3l0.7-1L22,11.6H24.1z M21.9,12.6l0.4,1.4h-1.6l0.1-0.3L21.9,12.6z M20.8,11.6
							l-0.7,2.9H22L21.6,16h-3.8l1.1-4.4H20.8z M15,11.6h1.9l-0.7,2.9H18L17.6,16h-3.8L15,11.6z M2.9,16l1.2-4.4h3.6c0.8,0,1,0.4,1,1
							c0,1.1-0.6,1.6-1.4,1.6H7L8,15.7l2.5-4.1h3.3V16h-1.9v-0.6h-1.6L10,16H8.3H7.9H6.1l-0.9-1.5L4.8,16H2.9z M4.1,21
							c-1.3,0-1.9-0.3-1.9-1.3c0-0.6,0.3-2.1,0.9-2.8c0.5-0.5,1.3-0.6,2-0.6c0.8,0,1.5,0.1,2.3,0.3L7,18c-0.4-0.1-0.7-0.1-1.1-0.1
							c-0.5,0-1,0-1.4,0.4c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.3,0.6,1,0.6c0.5,0,0.9-0.1,1.4-0.2l-0.3,1.3C5.4,20.9,4.6,21,4.1,21z
							M11.8,20.9H9.6l-0.9-1.5l-0.4,1.5H6.4l1.2-4.4h3.6c0.8,0,1,0.4,1,1c0,1.1-0.6,1.6-1.4,1.6h-0.3L11.8,20.9z M14.6,21
							c-1.4,0-2.4,0-2.4-1.4c0-0.6,0.3-1.6,0.7-2.3c0.5-0.8,1.4-1,2.6-1c1.1,0,2.4,0,2.4,1.4c0,0.6-0.3,1.7-0.7,2.4
							C16.6,20.9,15.4,21,14.6,21z M20,21c-1,0-1.8-0.1-2.6-0.2l0.3-1.1c0.7,0.1,1.4,0.1,2.1,0.1c0.2,0,0.6,0,0.6-0.3
							c0-0.2-0.1-0.3-1.2-0.3c-0.6,0-1.2-0.2-1.2-0.9c0-0.9,0.5-1.7,1.2-1.9c0.4-0.1,1-0.1,1.5-0.1c0.6,0,1.6,0,2.5,0.2l-0.4,1.4
							c-0.6-0.1-1.2-0.1-1.8-0.1c-0.7,0-0.9,0.1-0.9,0.3c0,0.2,0.1,0.2,1.1,0.2c0.7,0,1.3,0,1.3,1c0,0.3-0.2,0.8-0.3,1
							C21.8,20.9,21.2,21,20,21z M27.8,17.9c-0.6-0.1-1.2-0.1-1.8-0.1c-0.7,0-0.9,0.1-0.9,0.3c0,0.2,0.1,0.2,1.1,0.2c0.7,0,1.3,0,1.3,1
							c0,0.3-0.2,0.8-0.3,1c-0.4,0.6-1,0.7-2.2,0.7c-1,0-1.8-0.1-2.6-0.2l0.3-1.1c0.7,0.1,1.4,0.1,2.1,0.1c0.2,0,0.6,0,0.6-0.3
							c0-0.2-0.1-0.3-1.2-0.3c-0.6,0-1.2-0.2-1.2-0.9c0-0.9,0.5-1.7,1.2-1.9c0.4-0.1,1-0.1,1.5-0.1c0.6,0,1.6,0,2.5,0.2L27.8,17.9z"/>
						<path fill="currentColor" d="M28.4,14.3L32,9.4h-6.1l-4.7-4.7l-4.7,4.7h-2.7L9.2,4.8L4.5,9.4H2.4L0,18.2h0.2c-0.1,0.5-0.2,1-0.2,1.4v0.1
							c0,0,0,0.1,0,0.1l0,0C0,21,0.6,22,1.6,22.6c0.9,0.5,1.7,0.5,2.6,0.6c0.1,0,0.2,0,0.3,0l4.7,4.7l4.7-4.7c0.2,0,0.4,0,0.5,0h0.2h0
							c0.1,0,0.2,0,0.2,0c0.4,0,1,0,1.6-0.2l4.8,4.8l4.7-4.7c0.4,0,0.8-0.1,1.1-0.2c0.9-0.2,1.6-0.8,2.1-1.6l0,0c0.4-0.6,0.6-1.4,0.6-2.1
							v0c0,0,0,0,0-0.1l1.3-4.5L28.4,14.3C28.5,14.3,28.5,14.3,28.4,14.3z M29.3,18.7l-0.1,0.4v0c0,0.1,0,0.1,0,0.1c0,0.7-0.2,1.4-0.5,1.9
							c-0.4,0.7-1.1,1.2-1.8,1.4c-0.4,0.1-0.8,0.2-1.2,0.2l-4.5,4.4l-4.7-4.7c-0.8,0.2-1.5,0.2-2,0.2c-0.3,0-0.6,0-0.9,0l-4.5,4.5
							l-4.5-4.5c-0.2,0-0.4,0-0.5,0c-0.7,0-1.5,0-2.3-0.5c-0.9-0.5-1.3-1.5-1.3-2.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
							c0-0.5,0.1-1.2,0.3-1.9H0.6l2.1-7.8h1.9l4.5-4.5l4.5,4.5h0.7h2.5l4.5-4.5l4.5,4.5h5.4l-3.5,4.8c0.3,0,0.6,0.1,0.8,0.1l1.9,0.3
							L29.3,18.7z"/>
					</g>
				)
			case 'icon-eRallyUpTo20004wdCaps':
				return (
					<g>
						<g>
							<path fill="currentColor" d="M20.582,11.632c-0.199,0.11-0.349,0.223-0.448,0.34c-0.032,0.04-0.048,0.071-0.048,0.094l-0.002,0.006l0,0.002
								c0,0.057,0.013,0.094,0.038,0.111c0.061,0.038,0.125,0.047,0.193,0.029c0.068-0.018,0.132-0.04,0.193-0.067
								c0.151-0.065,0.288-0.147,0.41-0.246c-0.021-0.042-0.032-0.086-0.032-0.131c0-0.095,0.042-0.191,0.125-0.286
								C20.906,11.483,20.762,11.532,20.582,11.632z"/>
							<g>
								<path fill="currentColor" d="M7.758,9.769c-0.016,0-0.045,0.001-0.086,0.004v2.354c0.004-0.001,0.013-0.002,0.026-0.002
									c0.009,0,0.025,0.001,0.05,0.002c0.024,0.002,0.043,0.003,0.058,0.002c0.149,0,0.223-0.066,0.223-0.196l0-1.814
									c0-0.134-0.02-0.226-0.061-0.276C7.928,9.793,7.858,9.769,7.758,9.769z"/>
								<polygon fill="currentColor" points="2.334,11.471 2.334,9.801 1.897,11.471 			"/>
							</g>
						</g>
						<g>
							<path fill="currentColor" d="M10.833,16c-0.019-0.088-0.044-0.157-0.076-0.208c-0.032-0.051-0.08-0.095-0.143-0.132
								c-0.064-0.037-0.143-0.056-0.237-0.056c-0.094,0-0.173,0.019-0.237,0.056c-0.064,0.037-0.112,0.082-0.144,0.132
								C9.965,15.843,9.94,15.912,9.921,16c-0.019,0.088-0.029,0.161-0.031,0.219c-0.002,0.058-0.003,0.136-0.003,0.233l0,3.957
								c0,0.21,0.051,0.372,0.155,0.486c0.103,0.115,0.215,0.171,0.335,0.171c0.12,0,0.232-0.057,0.335-0.171
								c0.103-0.114,0.155-0.277,0.155-0.486l0-3.957c0-0.097-0.001-0.175-0.003-0.233C10.862,16.162,10.852,16.088,10.833,16z"/>
							<path fill="currentColor" d="M16.704,16.001c-0.019-0.088-0.044-0.157-0.076-0.208c-0.032-0.051-0.08-0.095-0.144-0.132
								c-0.064-0.037-0.143-0.056-0.237-0.056c-0.094,0-0.173,0.019-0.236,0.056c-0.064,0.038-0.112,0.081-0.144,0.132
								c-0.032,0.051-0.057,0.12-0.076,0.208c-0.019,0.088-0.029,0.161-0.031,0.219c-0.002,0.058-0.003,0.136-0.003,0.233l0,3.957
								c0,0.21,0.052,0.372,0.155,0.486c0.103,0.114,0.215,0.171,0.335,0.171c0.12,0,0.232-0.057,0.335-0.172
								c0.103-0.114,0.155-0.276,0.155-0.486l0-3.957c0-0.098-0.001-0.176-0.003-0.233C16.733,16.162,16.722,16.089,16.704,16.001z"/>
							<path fill="currentColor" d="M22.574,16.001c-0.019-0.088-0.044-0.157-0.076-0.208c-0.032-0.051-0.08-0.095-0.143-0.132
								c-0.064-0.037-0.143-0.056-0.237-0.056c-0.094,0-0.173,0.019-0.237,0.056c-0.064,0.037-0.112,0.081-0.144,0.132
								c-0.032,0.051-0.057,0.12-0.076,0.208c-0.019,0.088-0.029,0.161-0.031,0.219c-0.002,0.058-0.003,0.136-0.003,0.233l0,3.957
								c0,0.21,0.052,0.372,0.155,0.486c0.103,0.114,0.215,0.172,0.335,0.171c0.12,0,0.232-0.057,0.335-0.171
								c0.103-0.114,0.155-0.276,0.155-0.486l0-3.957c0-0.098-0.001-0.175-0.003-0.233C22.603,16.162,22.593,16.089,22.574,16.001z"/>
							<path fill="currentColor" d="M31.632,18.498c-0.121-0.252-0.291-0.461-0.508-0.621c-0.188-0.139-0.403-0.244-0.64-0.313
								c-0.22-0.064-0.458-0.097-0.707-0.097c-0.637,0-1.137,0.189-1.489,0.558c-0.053-0.053-0.11-0.103-0.171-0.148
								c-0.188-0.139-0.403-0.244-0.64-0.313c-0.22-0.064-0.458-0.097-0.707-0.097c-0.503,0-0.92,0.117-1.246,0.347v-1.026
								c0-1.137-0.298-2.025-0.887-2.641c-0.572-0.598-1.376-0.912-2.388-0.939c0.104-0.041,0.209-0.094,0.313-0.159
								c0.11-0.069,0.214-0.148,0.308-0.233c0.093-0.083,0.176-0.171,0.249-0.261c0.08-0.1,0.147-0.201,0.198-0.302
								c0.082-0.163,0.124-0.328,0.124-0.492c0-0.238-0.087-0.462-0.245-0.63c-0.138-0.146-0.317-0.238-0.514-0.268l-0.485-0.064
								c-0.01-0.011-0.02-0.021-0.031-0.032c-0.099-0.101-0.223-0.186-0.379-0.263c-0.182-0.088-0.383-0.133-0.598-0.133
								c-0.156,0-0.321,0.015-0.493,0.046c-0.146-0.296-0.447-0.49-0.812-0.49l-0.137,0.001c-0.077-0.143-0.194-0.269-0.344-0.367
								l-0.024-0.015c-0.094-0.057-0.201-0.101-0.32-0.134c-0.117-0.032-0.242-0.047-0.384-0.047c-0.255,0-0.493,0.085-0.686,0.247
								c-0.101,0.084-0.191,0.188-0.274,0.316l-0.055,0c-0.187,0-0.346,0.027-0.485,0.082c-0.158,0.061-0.298,0.164-0.405,0.298
								c-0.049,0.061-0.101,0.145-0.141,0.254c-0.22-0.122-0.43-0.155-0.59-0.155c-0.06,0-0.119,0.002-0.179,0.007
								c-0.051-0.145-0.138-0.276-0.256-0.38c-0.12-0.106-0.328-0.232-0.64-0.232c-0.183,0-0.365,0.048-0.556,0.147
								c-0.041,0.021-0.082,0.044-0.123,0.069c-0.006-0.007-0.013-0.014-0.019-0.021c-0.055-0.058-0.115-0.112-0.178-0.16
								c-0.055-0.043-0.112-0.082-0.168-0.116c-0.066-0.04-0.132-0.074-0.197-0.102c-0.124-0.052-0.252-0.078-0.378-0.078
								c-0.363,0-0.59,0.233-0.741,0.387c-0.018,0.018-0.054,0.056-0.331,0.361c-0.071-0.143-0.186-0.294-0.367-0.439
								c-0.264-0.212-0.539-0.319-0.818-0.319c-0.203,0-0.393,0.063-0.548,0.183l-0.059,0.051c-0.105,0.099-0.227,0.237-0.385,0.435
								l-0.209,0.279l-0.008,0.01c-0.19,0.264-0.092,0.121-0.215,0.349l0-1.583c0-0.64-0.519-1.159-1.159-1.159l-7.148,0
								c-0.64,0-1.159,0.519-1.159,1.159l0,3.315c0,0.64,0.519,1.159,1.159,1.159l1.219,0C2.298,13.91,2.065,14.134,1.88,14.4
								c-0.258,0.371-0.434,0.819-0.523,1.334c-0.076,0.443-0.117,0.944-0.121,1.49l0,0.038l0.089,1.003h1.882
								c-0.262,0.282-0.509,0.578-0.735,0.881c-0.256,0.343-0.483,0.713-0.676,1.1c-0.204,0.409-0.354,0.86-0.446,1.345
								c-0.061,0.33-0.092,0.656-0.092,0.968l0,1.243l6.706,0l0-0.84c0.158,0.154,0.329,0.293,0.516,0.412
								c0.295,0.187,0.609,0.325,0.933,0.409c0.309,0.08,0.633,0.12,0.965,0.12c0.331,0,0.656-0.04,0.965-0.12
								c0.324-0.084,0.638-0.222,0.933-0.409c0.299-0.19,0.564-0.428,0.786-0.707c0.092-0.115,0.176-0.241,0.252-0.377
								c0.076,0.136,0.16,0.262,0.252,0.377c0.223,0.279,0.487,0.517,0.786,0.706c0.295,0.187,0.609,0.325,0.933,0.409
								c0.309,0.08,0.633,0.12,0.965,0.121c0.331,0,0.656-0.041,0.965-0.121c0.324-0.084,0.638-0.221,0.933-0.409
								c0.299-0.19,0.564-0.427,0.787-0.706c0.092-0.115,0.175-0.241,0.252-0.378c0.076,0.136,0.16,0.263,0.251,0.378
								c0.223,0.279,0.487,0.517,0.786,0.707c0.295,0.187,0.609,0.325,0.933,0.409c0.381,0.099,0.79,0.136,1.193,0.113
								c0.232-0.018,0.46-0.054,0.679-0.11c0.334-0.086,0.657-0.228,0.961-0.421c0.308-0.196,0.581-0.441,0.809-0.727
								c0.024-0.03,0.047-0.061,0.07-0.093c0.119,0.33,0.305,0.56,0.471,0.712c0.374,0.34,0.849,0.512,1.41,0.512
								c0.596,0,1.085-0.155,1.454-0.459c0.034-0.028,0.068-0.059,0.103-0.093c0.013,0.013,0.027,0.028,0.04,0.04
								c0.374,0.34,0.848,0.512,1.41,0.512c0.596,0,1.085-0.155,1.454-0.459c0.283-0.234,0.598-0.681,0.598-1.481v-1.638v-0.885
								C31.806,19.012,31.747,18.74,31.632,18.498z M4.275,9.122l0.214,2.32l0.219-2.32h0.724l0.208,2.32l0.207-2.32h0.768L6.19,12.799
								l-0.931,0l-0.194-1.773l-0.185,1.773l-0.947,0L3.516,9.122L4.275,9.122z M1.138,12.175v-0.616l0.779-2.435l1.231,0l0,2.347h0.154
								l0,0.704l-0.154,0v0.625l-0.814,0l0-0.625L1.138,12.175z M7.181,21.113l0,1.81l-5.142-0.001l0-0.461
								c0-0.266,0.026-0.541,0.079-0.826c0.079-0.412,0.205-0.791,0.377-1.138c0.173-0.347,0.374-0.674,0.603-0.981
								c0.229-0.307,0.478-0.602,0.746-0.885c0.268-0.283,0.536-0.565,0.803-0.846c0.158-0.169,0.282-0.356,0.372-0.562
								c0.09-0.206,0.135-0.442,0.135-0.708c0-0.251-0.038-0.451-0.115-0.599c-0.077-0.148-0.217-0.222-0.419-0.222
								c-0.33,0-0.496,0.285-0.496,0.854v0.838l-2.084,0l-0.023-0.253c0.004-0.506,0.04-0.96,0.11-1.363
								c0.07-0.403,0.201-0.743,0.394-1.02c0.193-0.277,0.46-0.49,0.8-0.638c0.34-0.148,0.782-0.222,1.326-0.222
								c0.42,0,0.792,0.061,1.115,0.183c0.323,0.122,0.594,0.295,0.814,0.52c0.22,0.225,0.387,0.497,0.501,0.815
								c0.115,0.319,0.172,0.676,0.172,1.074c0,0.405-0.068,0.785-0.206,1.141c-0.137,0.356-0.312,0.68-0.527,0.972
								c-0.173,0.244-0.351,0.464-0.535,0.661c-0.184,0.197-0.365,0.391-0.544,0.582c-0.178,0.191-0.35,0.389-0.515,0.593
								c-0.165,0.204-0.314,0.432-0.445,0.683L7.181,21.113z M6.891,12.799l0-3.677h1.07c0.289,0,0.501,0.077,0.634,0.232
								c0.133,0.155,0.2,0.366,0.202,0.636l0.009,1.717c0,0.368-0.069,0.642-0.206,0.822c-0.138,0.18-0.359,0.27-0.665,0.27H6.891z
								M13.001,20.214c0,0.393-0.05,0.75-0.149,1.071c-0.1,0.32-0.234,0.586-0.403,0.798c-0.169,0.212-0.367,0.39-0.594,0.534
								c-0.227,0.144-0.464,0.248-0.709,0.312c-0.246,0.063-0.502,0.095-0.769,0.095c-0.267,0-0.523-0.032-0.769-0.096
								c-0.246-0.063-0.483-0.168-0.71-0.312c-0.227-0.144-0.425-0.322-0.594-0.534c-0.169-0.212-0.303-0.478-0.403-0.798
								c-0.099-0.32-0.149-0.677-0.149-1.071l0-3.525c0-0.933,0.223-1.633,0.67-2.1c0.447-0.467,1.096-0.7,1.949-0.7
								c0.86,0,1.513,0.233,1.96,0.7c0.447,0.466,0.67,1.166,0.67,2.1L13.001,20.214z M13.485,13.874
								c-0.041,0.041-0.087,0.062-0.138,0.062c-0.125,0-0.223-0.044-0.295-0.13c-0.072-0.087-0.108-0.197-0.108-0.33
								c0-0.133,0.017-0.264,0.052-0.394c0.025-0.093,0.057-0.188,0.096-0.283c-0.071,0.035-0.138,0.065-0.202,0.086
								c-0.06,0.02-0.13,0.03-0.21,0.03c-0.08,0-0.157-0.019-0.232-0.058c-0.074-0.039-0.134-0.088-0.177-0.149
								c-0.078-0.111-0.117-0.263-0.117-0.453c0-0.081,0.006-0.16,0.02-0.237c-0.291,0.311-0.541,0.534-0.751,0.666
								c-0.278,0.175-0.568,0.263-0.872,0.263c-0.178,0-0.34-0.06-0.486-0.181c-0.156-0.129-0.233-0.283-0.233-0.463
								c0-0.343,0.226-0.827,0.677-1.451l0.193-0.258c0.133-0.166,0.237-0.284,0.313-0.356c0.017-0.013,0.041-0.02,0.07-0.02
								c0.097,0,0.206,0.049,0.328,0.147c0.132,0.105,0.191,0.203,0.179,0.294c-0.011,0.109-0.08,0.244-0.207,0.407
								c-0.056,0.07-0.115,0.142-0.178,0.214c-0.063,0.072-0.123,0.146-0.18,0.22c-0.131,0.172-0.205,0.323-0.221,0.451
								c-0.009,0.07,0.018,0.123,0.082,0.157c0.019,0.011,0.055,0.016,0.11,0.016c0.054,0,0.129-0.017,0.224-0.053
								c0.095-0.035,0.191-0.082,0.289-0.139c0.097-0.058,0.194-0.123,0.291-0.196c0.096-0.073,0.188-0.147,0.275-0.223
								c0.087-0.075,0.167-0.149,0.24-0.221l0.189-0.193c0.393-0.435,0.612-0.675,0.657-0.721c0.098-0.101,0.159-0.151,0.181-0.151
								c0.023,0,0.048,0.006,0.077,0.018c0.028,0.012,0.059,0.028,0.092,0.048c0.032,0.02,0.065,0.043,0.097,0.068
								c0.033,0.025,0.063,0.052,0.091,0.081c0.06,0.062,0.092,0.117,0.098,0.165c0.008,0.029-0.011,0.087-0.057,0.174
								c-0.046,0.087-0.095,0.161-0.148,0.222l-0.175,0.203c-0.24,0.276-0.378,0.489-0.414,0.638c-0.011,0.045-0.016,0.089-0.016,0.131
								c0,0.134,0.042,0.201,0.125,0.201c0.037,0,0.102-0.029,0.193-0.088c0.18-0.121,0.305-0.205,0.374-0.254
								c0.128-0.3,0.324-0.593,0.586-0.877c0.179-0.193,0.346-0.33,0.5-0.409c0.077-0.04,0.143-0.06,0.197-0.06
								c0.055,0,0.096,0.012,0.124,0.037c0.028,0.024,0.042,0.055,0.042,0.092c0,0.037-0.011,0.083-0.034,0.138
								c-0.023,0.056-0.053,0.118-0.092,0.187c-0.039,0.069-0.084,0.142-0.136,0.221l-0.165,0.24c-0.013,0.02-0.02,0.038-0.02,0.054
								c0,0.025,0.015,0.038,0.046,0.038c0.035,0,0.098-0.022,0.191-0.066l0.319-0.147c0.279-0.126,0.546-0.189,0.801-0.189
								c0.133,0,0.257,0.063,0.374,0.189c0.118,0.127,0.177,0.264,0.177,0.41c0,0.225-0.093,0.399-0.277,0.521l-0.247,0.163
								c-0.089,0.056-0.181,0.111-0.275,0.165l-0.287,0.157c-0.323,0.167-0.572,0.251-0.748,0.25c-0.176,0-0.304-0.022-0.383-0.066
								c-0.08-0.044-0.12-0.105-0.12-0.185c0-0.107,0.088-0.2,0.265-0.278c0.044-0.019,0.105-0.048,0.183-0.087
								c0.479-0.245,0.742-0.38,0.789-0.405l0.084-0.046c0.086-0.056,0.126-0.099,0.12-0.131c-0.015-0.067-0.051-0.095-0.109-0.082
								c-0.059,0.013-0.119,0.03-0.182,0.051c-0.063,0.021-0.13,0.047-0.2,0.078c-0.148,0.061-0.277,0.122-0.389,0.183
								c-0.39,0.224-0.626,0.36-0.705,0.407c-0.057,0.129-0.108,0.256-0.153,0.384l-0.241,0.68C13.638,13.657,13.562,13.8,13.485,13.874z
								M18.872,20.214c0,0.394-0.05,0.75-0.149,1.071c-0.1,0.32-0.234,0.587-0.403,0.798c-0.169,0.212-0.367,0.39-0.594,0.534
								c-0.227,0.144-0.464,0.248-0.71,0.312c-0.246,0.064-0.502,0.095-0.769,0.095c-0.267,0-0.523-0.032-0.769-0.095
								c-0.246-0.064-0.482-0.168-0.71-0.312c-0.227-0.144-0.425-0.322-0.594-0.534c-0.169-0.212-0.303-0.478-0.403-0.798
								c-0.1-0.32-0.149-0.677-0.149-1.071l0.001-3.525c0-0.933,0.223-1.633,0.67-2.1c0.447-0.466,1.096-0.7,1.949-0.7
								c0.86,0,1.513,0.233,1.96,0.7c0.447,0.467,0.67,1.167,0.67,2.099L18.872,20.214z M19.377,12.711
								c-0.043-0.058-0.076-0.116-0.101-0.175c-0.125,0.089-0.237,0.163-0.335,0.218c-0.278,0.153-0.529,0.231-0.755,0.231
								c-0.212,0-0.386-0.064-0.52-0.191c-0.116-0.11-0.174-0.226-0.173-0.348c0-0.122,0.012-0.243,0.036-0.364
								c0.024-0.12,0.055-0.239,0.094-0.354c0.077-0.235,0.161-0.434,0.253-0.598c0.024-0.045,0.036-0.074,0.036-0.087h-0.257
								c-0.097,0-0.172-0.023-0.225-0.068c-0.053-0.045-0.08-0.096-0.08-0.153c0-0.057,0.011-0.099,0.032-0.125
								c0.021-0.027,0.048-0.046,0.08-0.058c0.045-0.019,0.111-0.028,0.199-0.028l0.488,0.002c0.011-0.004,0.032-0.033,0.064-0.086
								l0.122-0.209c0.052-0.093,0.104-0.161,0.156-0.205c0.052-0.044,0.114-0.065,0.185-0.066c0.071,0,0.131,0.007,0.179,0.02
								c0.048,0.013,0.089,0.03,0.123,0.05c0.065,0.042,0.098,0.089,0.098,0.139c0,0.021-0.007,0.05-0.022,0.086l-0.111,0.27l0.946-0.002
								c0.082,0,0.124,0.043,0.124,0.127c0,0.202-0.18,0.302-0.54,0.302l-0.729,0.002c-0.016,0.008-0.043,0.046-0.082,0.115l-0.09,0.165
								c-0.022,0.041-0.039,0.072-0.048,0.093l-0.044,0.097c-0.089,0.2-0.134,0.345-0.134,0.433c0,0.089,0.024,0.163,0.071,0.223
								c0.047,0.06,0.11,0.089,0.188,0.089c0.078,0,0.155-0.015,0.23-0.045c0.075-0.031,0.148-0.068,0.22-0.112
								c0.13-0.081,0.232-0.156,0.307-0.225c0.001-0.001,0.002-0.002,0.003-0.003c0.052-0.07,0.117-0.138,0.196-0.206
								c0.167-0.141,0.329-0.247,0.485-0.32c0.156-0.073,0.297-0.129,0.423-0.169c0.267-0.083,0.507-0.125,0.721-0.125
								c0.096,0,0.181,0.019,0.256,0.055c0.075,0.036,0.13,0.073,0.166,0.11c0.085,0.083,0.127,0.19,0.127,0.32
								c0,0.038-0.004,0.068-0.012,0.089c-0.008,0.021-0.012,0.041-0.012,0.059c0,0.072,0.061,0.108,0.183,0.108
								c0.09,0,0.208-0.044,0.354-0.131l0.16-0.088c0.049-0.028,0.092-0.042,0.128-0.042c0.037,0,0.065,0.011,0.087,0.034
								c0.021,0.023,0.032,0.054,0.032,0.094c0,0.04-0.013,0.086-0.04,0.138c-0.027,0.053-0.063,0.107-0.11,0.165
								c-0.046,0.058-0.101,0.115-0.163,0.171c-0.062,0.057-0.13,0.107-0.201,0.152c-0.16,0.099-0.308,0.149-0.444,0.149
								c-0.145,0-0.26-0.024-0.347-0.074c-0.026-0.016-0.052-0.033-0.078-0.05c-0.142,0.162-0.348,0.31-0.618,0.445
								c-0.287,0.143-0.537,0.215-0.751,0.215C19.684,12.976,19.507,12.887,19.377,12.711z M24.742,20.215
								c0,0.394-0.05,0.751-0.149,1.071c-0.099,0.321-0.234,0.587-0.403,0.798c-0.169,0.212-0.367,0.39-0.594,0.534
								c-0.227,0.144-0.464,0.248-0.71,0.312c-0.246,0.064-0.502,0.095-0.768,0.095c-0.267,0-0.523-0.032-0.769-0.095
								c-0.246-0.064-0.482-0.168-0.709-0.312c-0.227-0.144-0.425-0.322-0.594-0.534c-0.169-0.212-0.303-0.478-0.403-0.798
								c-0.1-0.32-0.149-0.677-0.149-1.071l0-3.525c0-0.933,0.223-1.633,0.67-2.1c0.447-0.467,1.096-0.7,1.948-0.7
								c0.86,0,1.513,0.233,1.96,0.7c0.447,0.466,0.67,1.167,0.67,2.1L24.742,20.215z M28.059,21.83c0,0.411-0.117,0.712-0.35,0.905
								c-0.233,0.192-0.56,0.289-0.979,0.289c-0.372,0-0.674-0.106-0.908-0.318s-0.35-0.514-0.35-0.905v-2.188
								c0-0.468,0.103-0.818,0.31-1.05c0.207-0.232,0.531-0.348,0.972-0.348c0.18,0,0.346,0.022,0.498,0.067
								c0.152,0.044,0.287,0.11,0.405,0.197c0.118,0.087,0.21,0.201,0.277,0.342c0.067,0.141,0.101,0.304,0.101,0.487v0.832h-1.033
								v-0.548c0-0.282-0.083-0.423-0.249-0.423c-0.099,0-0.164,0.031-0.196,0.093c-0.032,0.062-0.047,0.149-0.047,0.259v2.23
								c0,0.097,0.022,0.181,0.067,0.252c0.044,0.071,0.103,0.107,0.177,0.107c0.067,0,0.126-0.026,0.175-0.078s0.074-0.146,0.074-0.28
								v-0.811h1.056V21.83z M31.066,21.83c0,0.411-0.117,0.712-0.35,0.905c-0.233,0.192-0.56,0.289-0.979,0.289
								c-0.372,0-0.674-0.106-0.908-0.318s-0.35-0.514-0.35-0.905v-2.188c0-0.468,0.103-0.818,0.31-1.05
								c0.207-0.232,0.531-0.348,0.972-0.348c0.18,0,0.346,0.022,0.498,0.067c0.152,0.044,0.287,0.11,0.405,0.197
								c0.118,0.087,0.21,0.201,0.277,0.342c0.067,0.141,0.101,0.304,0.101,0.487v0.832H30.01v-0.548c0-0.282-0.083-0.423-0.249-0.423
								c-0.099,0-0.164,0.031-0.196,0.093c-0.032,0.062-0.047,0.149-0.047,0.259v2.23c0,0.097,0.022,0.181,0.067,0.252
								c0.044,0.071,0.103,0.107,0.177,0.107c0.067,0,0.126-0.026,0.175-0.078s0.074-0.146,0.074-0.28v-0.811h1.056V21.83z"/>
						</g>
					</g>
				)
			case 'icon-chequered-flag':
				return (
					<g>
						<rect x="12.229" y="1.561" transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.9156 12.8755)" className="st0" width="7.542" height="7.542"/>
						<rect x="22.896" y="12.228" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.5025 23.5423)" className="st0" width="7.542" height="7.542"/>
						<rect x="1.563" y="12.228" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.7508 8.4575)" className="st0" width="7.542" height="7.542"/>
						<rect x="12.229" y="22.894" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -14.1691 19.1238)" className="st0" width="7.542" height="7.542"/>
						<rect x="12.229" y="12.228" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.6266 15.9997)" className="st0" width="7.542" height="7.542"/>
					</g>
				)
			default:
				return ("")
		}
	}

	render() {
		let styles = {
			fill: "currentcolor",
			verticalAlign: "middle",
			width: this.props.size,
			height: this.props.size
		};
		
		return (
			<svg width={this.props.size} height={this.props.size} viewBox="0 0 32 32" preserveAspectRatio="xMidYMid meet" fit="true"
				style={this._mergeStyles(
					styles,
					this.props.style 
			)}>
				{this.renderGraphic()}
			</svg>
		);
	}
}

Icon.propTypes = {
	/** Specify icon to output */ 
	name: PropTypes.oneOf([ 
		'icon-blog_circle', 
		'icon-cog', 
		'icon-download', 
		'icon-expand_circle', 
		'icon-leaderboard_circle',
		'icon-left_circle',
		'icon-members_circle',
		'icon-play_circle',
		'icon-plus_circle',
		'icon-right_circle',
		'icon-search',
		'icon-trophy',
		'icon-trophy_circle',
		'icon-upload',
		'icon-video_circle',
		'icon-facebook',
		'icon-twitter',
		'icon-youtube',
		'icon-avatar',
		'icon-community-events',
		'icon-timer',
		'icon-eRallyRGtCaps',
		'icon-eRallyGrpACaps',
		'icon-eRallyGrpB4wdCaps',
		'icon-eRallyxSupercarsCaps',
		'icon-eRallyx1600sCaps',
        'icon-eRallyR5Caps',
        'icon-eRallyGrpBRwdCaps',
        'icon-eRallyKitcarCaps',
		'icon-eMultiCrosskartCaps',
		'icon-eRallyNr4R4Caps',
		'icon-eRallyH2RwdCaps',
		'icon-eRallyH3RwdCaps',
		'icon-eRallyR2Caps',
		'icon-eRallyH2FwdCaps',
		'icon-eRallyH1FwdCaps',
		'icon-eRallyxLitesCaps',
		'icon-eRally',
		'icon-eRallycross',
		'icon-eRallyUpTo20004wdCaps',
		'icon-chequered-flag'
	]).isRequired,
	/** Specify icon size */ 
	size: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	/** Provide icon styles */ 
	style: PropTypes.object
}

Icon.defaultProps = {
	size: 32
}
 
export default Icon;