import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import IconDivider from '../IconDivider/IconDivider';
import Heading from '../Heading/Heading';
import Img from '../Img/Img';

import './Signpost.scss'

/**
 * Signpost
 */
class Signpost extends React.Component {

	trimCharacters(string, length) {
		if(string !== undefined && string.length > length) {
			return string.substring(0, length) + "...";
		} else {
			return string;
		}
	}
	  
	render() { 
		const {img, placeholder, title, icon} = this.props;

		return (
			<div className="Signpost">
				<Img image={img} placeholder={placeholder} alt={title} width="100%" height="auto" className="Signpost__img" />
				<div className="Signpost__overlay">
					<div className="Signpost__content">
						{title !== "" && 
							<React.Fragment>
								<MediaQuery query="(min-width: 1400px)">
									<Heading rank="3">{title}</Heading>
								</MediaQuery>
								<MediaQuery query="(max-width: 1399px)">
									<Heading rank="3">{this.trimCharacters(title, 30)}</Heading>
								</MediaQuery>
							</React.Fragment>
						}
						<IconDivider icon={icon} />
					</div>
				</div>
			</div>
		);
	};
}

Signpost.propTypes = {
	/** Specify img to output */ 
	img: PropTypes.string.isRequired,
	/** Specify placeholder img to output */ 
	placeholder: PropTypes.string.isRequired,
	/** Specify title to output */ 
	title: PropTypes.string,
	/** Specify icon to output */ 
	icon: PropTypes.oneOf([ 
		'icon-blog_circle', 
		'icon-cog', 
		'icon-download', 
		'icon-expand_circle', 
		'icon-leaderboard_circle',
		'icon-left_circle',
		'icon-members_circle',
		'icon-play_circle',
		'icon-right_circle',
		'icon-search',
		'icon-trophy',
		'icon-trophy_circle',
		'icon-upload',
		'icon-video_circle'
	]).isRequired
}

Signpost.defaultProps = {
	icon: 'icon-blog_circle'
}


export default Signpost;