import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClubResults } from '../../actions/Clubs/ClubResults';
import { getLeaderboard } from '../../actions/LeaderboardActions';
import { Row, Col } from 'reactstrap';
import ChallengeSelector from '../ChallengeSelector/ChallengeSelector';
import EventDropdownMenu from '../EventDropdownMenu/EventDropdownMenu';
import Leaderboard from '../Leaderboard/Leaderboard';
import Loader from '../Loader/Loader';
import Heading from '../Heading/Heading';
import { Translate } from "react-localize-redux";

import './ClubResults.scss';

/** 
 * Club Results
 */
class ClubResults extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selected: {
				challenge: this.props.championshipId,
				event: this.props.eventId,
				stage: 0
			},
			leaderboard: {
				challengeId: 0,
				selectedEventId: 0,
				stageId: 0,
				page: 1,
				pageSize: 100,
				orderByTotalTime: true,
				platformFilter: "None",
				playerFilter: "Everyone", 
				filterByAssists: "Unspecified",
				filterByWheel: "Unspecified",
				nationalityFilter: "None"
			}
		}

		this.updateChallenge = this.updateChallenge.bind(this);
		this.updateEvent = this.updateEvent.bind(this);
		this.updateStage = this.updateStage.bind(this);
		this.updateLeaderboard = this.updateLeaderboard.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchClubResults(this.props.clubId);
	}

 	componentDidUpdate(oldProps, prevState) {
		const newProps = this.props;
		const newState = this.state;

		if(oldProps.clubResults.value !== newProps.clubResults.value) {
			if (this.props.clubResults.isReady()) {
				if(typeof this.props.clubResults.value.championships[this.state.selected.challenge] !== 'undefined') {
					this.setState( prevState => {
						return {
							leaderboard: {
								...prevState.leaderboard, 
                                challengeId: this.props.clubResults.value.championships[this.props.championshipId].events[this.props.eventId].challengeId,
								eventId: this.props.clubResults.value.championships[this.props.championshipId].events[this.props.eventId].id,
								stageId: this.props.clubResults.value.championships[this.props.championshipId].events[0].stages[0].id
							}
						}
					}, () => this.props.getLeaderboard(this.state.leaderboard));
				}
			}
		}

		if(prevState.selected !== newState.selected) {
			this.setState( prevState => {
				return {
					leaderboard: {
						...prevState.leaderboard, 
                        challengeId: this.props.clubResults.value.championships[this.state.selected.challenge].events[this.state.selected.event].challengeId,
							eventId: this.props.clubResults.value.championships[this.state.selected.challenge].events[this.state.selected.event].id,
							stageId: this.props.clubResults.value.championships[this.state.selected.challenge].events[this.state.selected.event].stages[this.state.selected.stage].id
					}
				}
			}, () => this.props.getLeaderboard(this.state.leaderboard));
		}
	} 

	updateChallenge(challenge) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challenge: challenge,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateEvent(event) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					event: event,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateStage(stage) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					stage: stage
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}
	
	updateLeaderboard(options) {
		this.setState( prevState => {
			return {
				leaderboard: {
					...prevState.leaderboard,
					platformFilter: options.platformFilter,
					playerFilter: options.playerFilter,
					filterByAssists: options.filterByAssists,
					filterByWheel: options.filterByWheel,
					nationalityFilter: options.nationalityFilter,
					page: options.page,
					orderByTotalTime: options.orderByTotalTime
				}
			}
		}, () => this.props.getLeaderboard(this.state.leaderboard));
	}

	render() {
		if (this.props.clubResults.isReady()) {		
			if(typeof this.props.clubResults.value.championships[this.state.selected.challenge] !== 'undefined') {
				return (
					<React.Fragment>
						<Row noGutters={true} className="CommunityEvents__EventSelectors">
							<Col xs="12">
								<ChallengeSelector selectedChallenge={this.state.selected.challenge} totalChallenges={this.props.clubResults.value.championships.length - 1} onChange={this.updateChallenge} description={"Championship " + (this.state.selected.challenge + 1) +  " - Results"} />
							</Col>
							<Col xs="12" md={{ size: 6 }} lg={{size: 4, offset: 2}}>
								<EventDropdownMenu type="event" options={this.props.clubResults.value.championships[this.state.selected.challenge].events} selectedEvent={this.state.selected.event} onChange={this.updateEvent} />
							</Col>
							<Col xs="12" md={{ size: 6 }} lg={{size: 4}}> 
								<EventDropdownMenu type="stages" options={this.props.clubResults.value.championships[this.state.selected.challenge].events[this.state.selected.event].stages} onChange={this.updateStage} />
							</Col>
						</Row>	
						<Row>
							<Col xs="12">
								<Leaderboard data={this.props.leaderboard} onChange={this.updateLeaderboard} />
							</Col>
						</Row>
					</React.Fragment>
				);
			} else {
				return (
					<Col xs="12">
						<div className="ClubResults__NoResults">
							<Heading rank="3" className="m-b-10"><Translate id="clubs.noResultsTitle">No Recent Results</Translate></Heading>
							<p><Translate id="clubs.noResults">Event results will appear here when the event duration has expired.</Translate></p>
						</div>
					</Col>
				);
			}
		} else if (this.props.clubResults.isFaulted()) {
            return (<Loader currentState={this.props.clubResults.state} errorMessage="Failed to load community events" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubResults.state} />);
        }  
	}
}

/**
 * Define expected props.
 */
ClubResults.propTypes = {
	clubId: PropTypes.number.isRequired,
	clubResults: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	leaderboard: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchClubResults: PropTypes.func.isRequired,
	getLeaderboard: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
	state => { return { clubResults: state.clubResults.championships, leaderboard: state.challenge.leaderboard   } },
	dispatch => bindActionCreators({ fetchClubResults, getLeaderboard }, dispatch)
)(ClubResults); 