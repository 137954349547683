import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import IconDivider from '../IconDivider/IconDivider';

import './NewsBanner.scss';

/** 
 * News banner
 */
class NewsBanner extends React.Component {
	
	render() { 
		const {image, placeholder, title, author, date} = this.props;
		const formattedDate = moment.utc(date).format('MMMM Do');

		return (
			<div className="NewsBanner">
				<Img image={image} placeholder={placeholder} width="100%" height="auto" alt={title} className="NewsBanner__img" /> 
				<div className="NewsBanner__overlay">
					<div className="NewsBanner__content">
						<Heading rank="1" className="NewsBanner__title">{title}</Heading>
						<IconDivider icon="icon-blog_circle" />
						<div className="NewsBanner__meta">Posted on {formattedDate} by {author}</div>
					</div>
				</div>
			</div>
		);
	};
}

NewsBanner.propTypes = {
	/** Specify img to output */
	image: PropTypes.string.isRequired,
	/** Specify img placeholder to output */
	placeholder: PropTypes.string.isRequired,
	/** Specify heading text output */
	title: PropTypes.string.isRequired,
	/** Specify author of article */
	author: PropTypes.string.isRequired,
	/** Specify date of article */
	date: PropTypes.string.isRequired
}

export default NewsBanner;