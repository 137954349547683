import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postKickMember() {
    return {
        type: actionTypes.SEND_KICKMEMBER
    };
}

export function postKickMemberSuccess(data) {
    return {
        type: actionTypes.RECEIVED_KICKMEMBER_SUCCESS,
        data
    };
}

export function postKickMemberFailure(error) {
    return {
        type: actionTypes.RECEIVED_KICKMEMBER_FAILURE,
        data: { error }
    };
}

/***
 * Kick Member Function
 */
export function sendKickMember(clubId, memberId) {
    return function (dispatch, getState) {
        dispatch(postKickMember());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/member/' + memberId + '/kick')
        .then((resp) => {
			dispatch(postKickMemberSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postKickMemberFailure(error));
		});
    };
}