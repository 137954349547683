import * as actionTypes from './ActionTypes';

/**
 * Error Actions
 */

export function setErrorState(error, errorInfo) {
    return {
        type: actionTypes.SET_ERROR_STATE,
        data: { error, errorInfo }
    };
}

export function clearErrorState() {
    return {
        type: actionTypes.CLEAR_ERROR_STATE
    };
}



