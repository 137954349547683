import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestMembers() {
    return {
        type: actionTypes.REQUEST_MEMBERS
    };
}

export function receivedMembersSuccess(data) {
    return {
        type: actionTypes.RECEIVED_MEMBERS_SUCCESS,
        data
    };
}

export function receivedMembersFailure(error) {
    return {
        type: actionTypes.RECEIVED_MEMBERS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function fetchMembers(id, data) {
    return function (dispatch) {
        dispatch(requestMembers());
        axiosFactory.getApiInstance().get('/api/Club/' + id + '/members?pageSize=' + data.pageSize + '&page=' + data.page + '&nameFilter=' + data.nameFilter)
            .then((resp) => {
                dispatch(receivedMembersSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedMembersFailure(error));
            });
    };
}