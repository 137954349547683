import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestClub() {
    return {
        type: actionTypes.REQUEST_CLUB
    };
}

export function receivedClubSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CLUB_SUCCESS,
        data
    };
}

export function receivedClubFailure(error) {
    return {
        type: actionTypes.RECEIVED_CLUB_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function fetchClub(id) {
    return function (dispatch) {
        dispatch(requestClub());
        axiosFactory.getApiInstance().get('/api/Club/' + id)
            .then((resp) => {
                dispatch(receivedClubSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedClubFailure(error));
            });
    };
}