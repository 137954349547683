import React, { Component } from 'react';
import Img from '../Img/Img';
import MediaQuery from 'react-responsive';
import FlatoutLogo from '../FlatoutLogo/FlatoutLogo';

import BannerXl from '../../assets/images/colinMcRae/header_flatout.jpg';
import BannerXl_Ph from '../../assets/images/colinMcRae/header_flatout_ph.jpg';
import BannerMedium from '../../assets/images/colinMcRae/header_flatout_1200.jpg';
import BannerMedium_Ph from '../../assets/images/colinMcRae/header_flatout_1200_ph.jpg';
import BannerSmall from '../../assets/images/colinMcRae/header_flatout_mob.jpg';
import BannerSmall_Ph from '../../assets/images/colinMcRae/header_flatout_mob_ph.jpg';

import './FlatoutBanner.scss';

export default class FlatoutBanner extends Component {
    render() {
        return (
            <div className="FlatoutBanner">
                 <MediaQuery query="(min-width: 1200px)">
                    <Img image={BannerXl} placeholder={BannerXl_Ph} className="FlatoutBanner__bg" />
                </MediaQuery>
                <MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
                    <Img image={BannerMedium} placeholder={BannerMedium_Ph} className="FlatoutBanner__bg--mobile" />
                </MediaQuery>
                <MediaQuery query="(max-width: 767px)">
                    <Img image={BannerSmall} placeholder={BannerSmall_Ph} className="FlatoutBanner__bg--mobile" />
                </MediaQuery>
                <div className="TrialBanner__container">
                    <div className="TrialBanner__overlay">
                        <FlatoutLogo />
                    </div>
                </div>         
            </div>
        )
    }
}
