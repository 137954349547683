import React from 'react'
import Img from '../Img/Img';
import logoImage from '../../assets/images/logo.png';
import logoPlaceholder from '../../assets/images/logo_placeholder.png';
import MediaQuery from 'react-responsive';


import './HeroLogo.scss'

class HeroLogo extends React.Component {
	render() {
		return (
      <div className="HeroLogo">
        <MediaQuery query="(max-width: 767px)">
          <Img
            image={logoImage}
            placeholder={logoPlaceholder}
            alt="Dirt Rally 2.0 Logo"
            className="HeroLogo__logo"
          />
        </MediaQuery>
        <MediaQuery query="(min-width: 768px)">
          <Img
            image={logoImage}
            placeholder={logoPlaceholder}
            alt="Dirt Rally 2.0 Logo"
            className="HeroLogo__logo"
          />
          <div className="HeroLogo__release" />
        </MediaQuery>
      </div>
    );
	}
}
 
export default HeroLogo;