import axios from 'axios';

class AxiosFactory {
    
    constructor() {
        this.apiInstance = null;
        this.instance = null;
        this.CSRFHeaderName = null;
        this.CSRFHeaderValue = null;
    }

    configureAxiosCSRFToken(name, value) {
        this.CSRFHeaderName = name;
        this.CSRFHeaderValue = value;
    }    

    getApiInstance() {
        if (!this.apiInstance) {
            var headers = {};
            if (this.CSRFHeaderName && this.CSRFHeaderValue) {
                headers[this.CSRFHeaderName] = this.CSRFHeaderValue;
            }
            this.apiInstance = axios.create({ headers: headers });
        }
        return this.apiInstance;
    }

    getInstance() {
        if (!this.instance) {
            this.instance = axios.create();
        }
        return this.instance;
    }
}

export default new AxiosFactory();
