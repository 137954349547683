import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import {
	Form, 
	FormGroup, 
	Label, 
	Input, 
	Row,
	Col
} from 'reactstrap';
import DialogPopup from '../DialogPopup/DialogPopup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendGenerateChampionship } from '../../actions/Clubs/GenerateChampionship';

import ClubsBannerImg from '../../assets/images/createchamp_wide.jpg';
import ClubsBannerImgPh from '../../assets/images/createchamp_ph.jpg';

import ClubBannerImg1200 from '../../assets/images/createchamp_1200.jpg';
import ClubBannerImgMob from '../../assets/images/mob_createchamp.jpg';


import './GenerateChampionship.scss'

/** 
 * Create Championship Banner
 */
class GenerateChampionship extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			eventCount: 6,
			stageCount: 12,
			confirmGenerate: false
		}

		this.generateChampionship = this.generateChampionship.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.confirmGenerate = this.confirmGenerate.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.championship.value !== newProps.championship.value) {
			if(this.props.championship.isReady()) {
				const championship = newProps.championship.value;
				this.props.onGenerate(championship);
			}
		}
	}

	generateChampionship() {
		this.props.sendGenerateChampionship(this.state);

		this.setState({
			confirmGenerate: false
		})
	}
	
	handleInput(e, limit) {
		if(e.target.value > 0 && e.target.value <= limit) {
			let value = e.target.value;
			let name = e.target.name;
			this.setState( prevState => {
				return { 
					...prevState, [name]: parseInt(value)
				}
			})
		}
	}

	confirmGenerate(){
		this.setState({
			confirmGenerate: true
		})
	}

	resetDialogs() {
		this.setState({
			confirmGenerate: false
		})
	}
	
	render() { 
		let events = [];
		let stages = [];

		for (let i = 1; i <= 12; i++) { 
            events.push(<option key={i} value={i}>{i}</option>);
		}

		for (let i = 1; i <= 12; i++) { 
            stages.push(<option key={i} value={i}>{i}</option>);
		}

		return (
			<div className="GenerateChampionship">
				<MediaQuery query="(min-width: 1201px)">
					<Img image={ClubsBannerImg} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(min-width: 769px) and (max-width: 1200px)">
					<Img image={ClubBannerImg1200} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(max-width: 768px)">
					<Img image={ClubBannerImgMob} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<div className="GenerateChampionship__content">
					<Heading rank="2" className="GenerateChampionship__header">Create a Championship</Heading>
					<p>Setting up a Championship is easy, and can be created within minutes. Just start with selecting how many Events and Stages you’d like and then refine each event and away you go.</p>
					<Row className="GenerateChampionship__counts">
						<Col xs="12">
							<Form>
								<Heading rank="3">Championship Length</Heading>
								<Row>
									<Col xs="6">
										<FormGroup>
											<Label for="eventCount">Events</Label>
											<Input 
												type="select" 
												name="eventCount" 
												id="eventCount" 
												className="small"
												value={this.state.eventCount} 
												onChange={(e) => this.handleInput(e, 12)}  
											>
												{events}
											</Input>
										</FormGroup>
									</Col>
									<Col xs="6">
										<FormGroup>
											<Label for="stageCount">Stages</Label>
											<Input 
												type="select" 
												name="stageCount" 
												id="stageCount" 
												className="small"
												value={this.state.stageCount} 
												onChange={(e) => this.handleInput(e, 12)}  
											>
												{stages}
											</Input>

										</FormGroup>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							{!this.props.isGenerated &&
								<Button type="standard" className="GenerateChampionship__button" onClick={this.generateChampionship}>Generate</Button>
							}

							{this.props.isGenerated &&
								<Button type="standard" className="GenerateChampionship__button" onClick={this.confirmGenerate}>Regenerate</Button>
							}
						</Col>
					</Row>
				</div>  

				{this.state.confirmGenerate && 
					<DialogPopup header="Please Confirm" description="This will reset any previously configured changes." confirmText="Confirm" dismissText="Cancel" onConfirm={this.generateChampionship} onDismiss={this.resetDialogs} />
				}
			</div>
		);
	};
}

/**
 * Define expected props.
 */
GenerateChampionship.propTypes = {
    championship: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired
    }),
	sendGenerateChampionship: PropTypes.func,
	onGenerate: PropTypes.func
};
 
//Connect this container component to the redux store.
export default connect(
    state => { return { championship: state.championship.settings} },   
    dispatch => bindActionCreators({ sendGenerateChampionship }, dispatch)
)(GenerateChampionship);