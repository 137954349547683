import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestMyClubs() {
    return {
        type: actionTypes.REQUEST_MYCLUBS
    };
}

export function receivedMyClubsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_MYCLUBS_SUCCESS,
        data
    };
}

export function receivedMyClubsFailure(error) {
    return {
        type: actionTypes.RECEIVED_MYCLUBS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch My Clubs
 */
export function fetchMyClubs(page, pageSize) {
    return function (dispatch) {
        dispatch(requestMyClubs());

        axiosFactory.getApiInstance().get('/api/Club/MyClubs?page=' + page + '&pageSize=' + pageSize)
            .then((resp) => {
                dispatch(receivedMyClubsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedMyClubsFailure(error));
            });
    };
}