import React from 'react';
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import CreateClubsBannerImg from '../../assets/images/createclub_wide.jpg';
import CreateClubsBannerImgPh from '../../assets/images/createclub_ph.jpg';
import CreateClubsBanner1200 from '../../assets/images/createclub_1200.jpg';
import CreateClubsBannerMob from '../../assets/images/createclub_mob.jpg';

import './CreateClubBanner.scss'

/** 
 * Clubs Banner
 */
class CreateClubBanner extends React.Component {
	
	render() { 
		return (
			<div className="CreateClubBanner">
				<MediaQuery query="(min-width: 1201px)">
					<Img image={CreateClubsBannerImg} placeholder={CreateClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(min-width: 769px) and (max-width: 1200px)">
					<Img image={CreateClubsBanner1200} placeholder={CreateClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(max-width: 768px)">
					<Img image={CreateClubsBannerMob} placeholder={CreateClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<div className="CreateClubBanner__content">
					<Heading rank="3" className="CreateClubBanner__header">Create a Club</Heading>
					<p>Anyone can create, manage, and run their own club. Give it a name, write a description to describe the kind of competition players should expect, and invite your friends.</p>
					<p>Competitions will take place in the form of Championships; several stages, grouped into Events, with points awarded for the fastest player. The most points at the end of the Championship wins the bragging rights.
</p>
					<p>The rest is up to you. You set the events, the vehicle restrictions, even the weather conditions and time of day.</p>
				</div>  
			</div>
		);
	};
}

export default CreateClubBanner;