/**
 * Create Championship
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import GenerateChampionship from '../../components/GenerateChampionship/GenerateChampionship';
import EditChampionship from '../../components/EditChampionship/EditChampionship';
import NoGameDataCheck from '../../containers/NoGameDataCheck';

import '../../assets/scss/clubs.scss';

class CreateChampionship extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            championship: {},
			isGenerated: false,
			clubId: parseInt(this.props.match.params.clubId)
        };
		
		this.storeChampionship = this.storeChampionship.bind(this);
	}

	storeChampionship(championship) {
		this.setState({
			isGenerated: false
		}, () => {
            this.setState({
                championship: championship,
                isGenerated: true
            });
		});
	}

	render() {
        return (
            <NoGameDataCheck>
			    <main className="CreateChampionship" role="main">
				    <GenerateChampionship onGenerate={this.storeChampionship} isGenerated={this.state.isGenerated} />

				    {this.state.isGenerated &&
					    <div className="Clubs__CreateChampionship">
						    <Row>
							    <Col xs="12">
								    <EditChampionship clubId={this.state.clubId} championship={this.state.championship} />
							    </Col>
						    </Row>
					    </div>
				    }
                </main>
            </NoGameDataCheck>
		);
	}
}

export default CreateChampionship;

