import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCurrentStandings } from '../../actions/Clubs/GetStandings';
import Loader from '../../components/Loader/Loader';
import Pagination from '../Pagination/Pagination';
import Heading from '../Heading/Heading';
import { Translate } from "react-localize-redux";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';

import './ChampionshipStandings.scss'

library.add(faArrowCircleRight, faArrowCircleLeft);


/**
 * Championship Standings
 */
class ChampionshipStandings extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			page: 1,
			pageLength: 100
		}

		this.updatePage = this.updatePage.bind(this);
		this.goToCurrentStandings = this.goToCurrentStandings.bind(this);
		this.goToPreviousStandings = this.goToPreviousStandings.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		if(this.props.standings === "previous") {
			this.props.fetchCurrentStandings(this.props.clubId, this.state, "previous");
		} else {
			this.props.fetchCurrentStandings(this.props.clubId, this.state, "current");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const newProps = this.props;
		const newState = this.state;

		if(prevProps.currentStandings !== newProps.currentStandings) {
			if(this.props.currentStandings.isReady()) {
				this.setState({
					standings: this.props.currentStandings.value
				});
			}
		} 

		if(prevState.page !== newState.page) {
			if(this.props.standings === "previous") {
				this.props.fetchCurrentStandings(this.props.clubId, this.state, "previous");
			} else {
				this.props.fetchCurrentStandings(this.props.clubId, this.state, "current");
			}
		}
	}

	goToCurrentStandings() {
		this.props.push('/clubs/club/' + this.props.clubId + '/standings/current/');
	}

	goToPreviousStandings() {
		this.props.push('/clubs/club/' + this.props.clubId + '/standings/previous/');
	}

	updatePage(page) {
		this.setState( prevState => {
			return {
				...prevState,
				page: page
			}
		});
	}

	addLeadingZeros(value) {
		value = String(value);
		while (value.length < 2) {
		  value = '0' + value;
		}
		return value;
	}
	
	render() {
		if (this.props.currentStandings.isReady()) {
			const entries = this.props.currentStandings.value.standings;

			return (
				<div className="ChampionshipStandings">
					<div className="ChallengeSelector">
						<div className={"ChallengeSelector__prev " +  (this.props.standings === "previous" ? "is-disabled" : "")} onClick={this.goToPreviousStandings}>
							<FontAwesomeIcon icon="arrow-circle-left" color="#FFF" className="fa-2x" />
						</div>
						<div className="ChallengeSelector__heading">
							<Heading rank="2">Championship Standings</Heading> 
						</div>
						<div className={"ChallengeSelector__next " +  (this.props.standings === "current" ? "is-disabled" : "")} onClick={this.goToCurrentStandings}>
							<FontAwesomeIcon icon="arrow-circle-right" color="#FFF" className="fa-2x" />
						</div>
					</div>
					<table>
						<thead>
							<tr>
								<th>
									Pos.
								</th>
								<th className="driver">
									Driver
								</th>
								{[...Array(this.props.currentStandings.value.eventCount)].map((x, index) => 
									<th className="text-center points" key={index}>
										{this.addLeadingZeros(index + 1)}
									</th>
								)}
								<th className="text-center">Pts</th>
							</tr>
						</thead>
						<tbody>
							{entries.length &&
								<React.Fragment>
									{entries.map((entry, index) => 
										<tr key={index} className={(entry.isMe ? "is-user" : "")}>
											<td className="pos">{entry.rank}</td>
											<td className="driver">
												{entry.displayName}
											</td>
											{entry.eventPoints.map((points, index) =>
												<td key={index}  className="points">
													
													<React.Fragment>
														{points.points === "" &&
															0
														}
													</React.Fragment>
													
													{points.points !== "" &&
														<React.Fragment>{points.points}</React.Fragment>
													}
													
												</td>
											)}
											<td className="totalPoints">{entry.totalPoints}</td>
										</tr>
									)}
								</React.Fragment>
							}

							{!entries.length &&
								<React.Fragment>
									{[...Array(20)].map((x, i) =>
										<tr key={i}>
											<td className="pos">&nbsp;</td> 
											<td className="driver">&nbsp;</td>
											{[...Array(this.props.currentStandings.value.eventCount)].map((x, index) => 
												<td className="points">
													&nbsp;
												</td>
											)}
											<td className="totalPoints">&nbsp;</td>
										</tr>
									)}
								</React.Fragment>
							}

						</tbody>
					</table>

					{!entries.length &&
						<div className="ChampionshipStandings__noEntries">
							<Heading rank="3"><Translate id="async.noDataChamp">Sorry, there is no data for this event</Translate></Heading>
							<p><Translate id="async.checkBack">Check back again soon</Translate></p>
						</div>
					}

					{this.props.currentStandings.value.pageCount > 1 &&
						<div className="ChampionshipStandings__Pagination">
							<Pagination activePage={this.state.page} pageSize={this.props.currentStandings.value.pageCount} onChange={this.updatePage} />
						</div>
					}
				</div>
			);
		} else if (this.props.currentStandings.isFaulted()) {
			return (
				<div className="ChampionshipStandings">
					<div className="ChallengeSelector">
						<div className={"ChallengeSelector__prev " +  (this.props.standings === "previous" ? "is-disabled" : "")} onClick={this.goToPreviousStandings}>
							<FontAwesomeIcon icon="arrow-circle-left" color="#FFF" className="fa-2x" />
						</div>
						<div className="ChallengeSelector__heading">
							<Heading rank="2">Championship Standings</Heading> 
						</div>
						<div className={"ChallengeSelector__next " +  (this.props.standings === "current" ? "is-disabled" : "")} onClick={this.goToCurrentStandings}>
							<FontAwesomeIcon icon="arrow-circle-right" color="#FFF" className="fa-2x" />
						</div>
					</div>
					<div className="ChampionshipStandings__NoData">
						<Heading rank="3"><Translate id="async.noDataChamp">Sorry, there is no data for this Championship</Translate></Heading>
						<p><Translate id="async.checkBack">Check back again soon</Translate></p>
					</div>
				</div>
			);
		} else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.currentStandings.state} />);
		}    
	};
}
 
/**
 * Define expected props.
 */
ChampionshipStandings.propTypes = {
    currentStandings: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired
    }),
	fetchCurrentStandings: PropTypes.func,
	push: PropTypes.func.isRequired
}
 
//Connect this container component to the redux store.
export default connect(
    state => { return { currentStandings: state.getCurrentStandings.standings} },   
    dispatch => bindActionCreators({ fetchCurrentStandings, push }, dispatch)
)(ChampionshipStandings);