import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../../components/Heading/Heading';
import {Container, Row, Col} from 'reactstrap';
import { Form, FormGroup, Label, Input, CustomInput, FormText, FormFeedback } from 'reactstrap';
import CoverImageSelector from '../../components/CoverImageSelector/CoverImageSelector';
import DividerButton from '../../components/DividerButton/DividerButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendCreateClubForm } from '../../actions/Clubs/CreateClub';
import { sendIsNameAvailable } from '../../actions/Clubs/IsNameAvailable';
import { sendIsDescriptonAllowed } from '../../actions/Clubs/IsDescriptionAllowed';
import DialogPopup from '../DialogPopup/DialogPopup';
import { push } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import map from 'lodash/map';
import debounce from 'lodash/debounce';

import './CreateClubForm.scss';

/** 
 * Create Club Form
 */
class CreateClubForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			newClub: {
				name: "",
				clubLanguageName: "English",
				description: "",
				clubAccessType: "Open",
				isCrossPlatform: true,
				coverImageId: 0
			},
			validation: {
				isValid: false,
				name: {
					isValid: false,
					hasError: false,
					errorMessage: ""
				},
				description: {
					isValid: false,
					hasError: false,
					errorMessage: ""
				}
			},
			showConfirmation: false,
			showError: false,
			errorMessage: "",
			clubId: null,
			submitted: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = debounce(this.validateForm.bind(this), 500);
		this.handleInput = this.handleInput.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.updateCoverImage = this.updateCoverImage.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
		this.goToClub = this.goToClub.bind(this);
    }

    componentDidUpdate(prevProps) {
		const newProps = this.props;
		
        if (prevProps.club.value !== newProps.club.value) {

            if (this.props.club.isFaulted()) {
                const error = this.props.club.value.error.response.data;
                if (error.hasValidationErrors) {
                    error.validationFieldErrors.forEach(function (item) {
                        var errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						
						if(item.name === "") {
							this.setState(prevState => {
								return {
									...prevState,
									showError: true,
									errorMessage: errorMessage
								};
							});
						} else {
							this.setState(prevState => {
								return {
									validation: {
										...prevState.validation, [item.name]: {
											isValid: false,
											hasError: true,
											errorMessage: errorMessage
										}
									}
								};
							});
						}
                    }, this);
                }
			}

            if (this.props.club.isReady()) {
                this.setState({
                    showConfirmation: true,
					clubId: this.props.club.value.clubId,
					submitted: false
                });
            }
		}

		if (prevProps.isNameAvailable.value !== newProps.isNameAvailable.value) {
			if(this.props.isNameAvailable.isReady()) {
				this.setState(prevState => {
					return {
						validation: {
							...prevState.validation, 
							name: {
								isValid: true,
								hasError: false,
								errorMessage: ""
							}
						}
					};
				});
			}

			if (this.props.isNameAvailable.isFaulted()) {
				const error = this.props.isNameAvailable.value.error.response.data;
				if (error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function (item) {
						var errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState(prevState => {
							return {
								validation: {
									...prevState.validation, 
									isValid: false,
									name: {
										isValid: false,
										hasError: true,
										errorMessage: errorMessage
									}
								}
							};
						});
					}, this);
				}
			}
		}
		
		if (prevProps.isDescriptionAllowed.value !== newProps.isDescriptionAllowed.value) {
			if(this.props.isDescriptionAllowed.isReady()) {
				this.setState(prevState => {
					return {
						validation: {
							...prevState.validation, 
							description: {
								isValid: true,
								hasError: false,
								errorMessage: ""
							}
						}
					};
				});
			}

			if (this.props.isDescriptionAllowed.isFaulted()) {
				const error = this.props.isDescriptionAllowed.value.error.response.data;
				if (error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function (item) {
						var errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState(prevState => {
							return {
								validation: {
									...prevState.validation, 
									isValid: false,
									description: {
										isValid: false,
										hasError: true,
										errorMessage: errorMessage
									}
								}
							};
						});
					}, this);
				}
			}
		}
    }

    validateName(name) {
        const { validation } = this.state;
		const regex = /^[a-zA-Z0-9\s\-_áéíóúüñ¿¡èàùâêîôûëïçäößìò«»ÁÉÍÓÚÜÑÈÀÙÂÊÎÔÛËÏÇÄÖßÌÒ]+$/;

		const serverValidation = {
			"name": name,
			"checkForProfanity": true
		}

        if (!name || name.trim() === '') {
            validation.hasError = true;
            validation.name.hasError = true;
            validation.name.errorMessage = this.props.translate("clubs.error.nameMissing");
            validation.name.isValid = false;
        } else if (!regex.test(name)) {
            validation.hasError = true;
            validation.name.hasError = true;
            validation.name.errorMessage = this.props.translate("clubs.error.nameChars");
            validation.name.isValid = false;
        } else if (name.length < 3 || name.length > 16) {
            validation.hasError = true;
            validation.name.hasError = true;
            validation.name.errorMessage = this.props.translate("clubs.error.nameLength");
            validation.name.isValid = false;
        } else {
			this.props.sendIsNameAvailable(serverValidation)
        }

        this.setState({ validation });
    }

    validateDescription(description) {
        const { validation } = this.state; 
		const regex = /^[a-zA-Z0-9\s\-_áéíóúüñ¿¡èàùâêîôûëïçäößìò«»ÁÉÍÓÚÜÑÈÀÙÂÊÎÔÛËÏÇÄÖßÌÒ\n'""\!”‘`%&\(\)#:;,\.<>\?/]+$/;
		
		const serverValidation = {
			"description": description,
			"checkForProfanity": true
		}

        if (!description || description.trim() === '') {
            validation.hasError = true;
            validation.description.hasError = true;
            validation.description.errorMessage = this.props.translate("clubs.error.descMissing");
            validation.description.isValid = false;
        } else if (!regex.test(description)) {
            validation.hasError = true;
            validation.description.hasError = true;
            validation.description.errorMessage = this.props.translate("clubs.error.descChars");
            validation.description.isValid = false;
        } else {
			this.props.sendIsDescriptonAllowed(serverValidation)
        }

        this.setState({ validation });
	}

    validateForm(e) {
		if (e.target.name === 'name') {
			this.validateName(e.target.value);
		}

		if (e.target.name === 'description') {
			this.validateDescription(e.target.value);
		}      
	}

	handleSubmit(e) {
		e.preventDefault();
		
        if (this.state.validation.name.isValid && this.state.validation.description.isValid) {
			this.setState({
				submitted: true
			});

			let clubData = this.state.newClub;
			this.props.sendCreateClubForm(clubData);
		}
	}

	handleInput(e) {
		e.persist();
		let value = e.target.value;
		let name = e.target.name;

        this.setState(prevState => {
            return {
                newClub: {
                    ...prevState.newClub, [name]: value
                }
            };
        }, () => {
			this.validateForm(e);
		});
	}

	handleCheck(e) {
		let name = e.target.name;
		let value = this.state.newClub[name];
        this.setState(prevState => {
            return {
                newClub: {
                    ...prevState.newClub, [name]: !value
                }
            };
        });
	}
	
	updateCoverImage(image) {
        this.setState(prevState => {
            return {
                newClub: {
					...prevState.newClub, 
					coverImageId: image
                }
            };
        });
	}

	goToClub() {
        this.props.push('/clubs/club/' + this.state.clubId);
	}

	resetDialogs() {
		this.setState({
			showConfirmation: false,
			showError: false,
			errorMessage: "",
			submitted: false
		});
	}

	render() { 
		return (
			<Container>
				<Form id="CreateClubForm" className="CreateClubForm">
					<div className="CreateClubForm__Options">
						<Heading rank="2">Create a Club</Heading>
						<FormGroup>
							<Label for="clubName" className="sr-only">Club Name</Label>
							<Input 
								type="text" 
								placeholder="Club Name" 
								name="name" 
								id="clubName"
								value={this.state.newClub.name}
								valid={this.state.validation.name.isValid}
								invalid={this.state.validation.name.hasError}
								onChange={this.handleInput}
							/>
							<FormFeedback>
								{this.state.validation.name.errorMessage}
							</FormFeedback>
						</FormGroup>
						<FormGroup>
							<Label for="clubDescription" className="sr-only">Club Description</Label>
							<Input 
								type="textarea" 
								name="description" 
								id="clubDescription" 
								placeholder="Club Description" 
								value={this.state.newClub.description}
								valid={this.state.validation.description.isValid}
								invalid ={this.state.validation.description.hasError}
								onChange={this.handleInput}
							/>
							<FormFeedback>
								{this.state.validation.description.errorMessage}
							</FormFeedback>
						</FormGroup>
						<FormGroup>
							<Label for="clubJoinOptions" className="sr-only">Club Join Options</Label>
							<div>
								<CustomInput 
									type="radio" 
									id="clubJoinOption_Open"
									name="clubAccessType" 
									label="Open" 
									value="Open" 
									checked={(this.state.newClub.clubAccessType === 'Open' ? true : false)}
									onChange={this.handleInput} 
									className="large"
									inline 
								/>
								<CustomInput 
									type="radio"
									id="clubJoinOption_Moderated" 
									name="clubAccessType" 
									label="Moderated" 
									value="Moderated" 
									checked={(this.state.newClub.clubAccessType === 'Moderated' ? true : false)}
									onChange={this.handleInput} 
									className="large"
									inline 
								/>
								<CustomInput 
									type="radio" 
									id="clubJoinOption_Closed" 
									name="clubAccessType" 
									label="Closed" 
									value="Closed" 
									checked={(this.state.newClub.clubAccessType === 'Closed' ? true : false)}
									onChange={this.handleInput}
									className="large"
									inline 
								/>
							</div>

							{this.state.newClub.clubAccessType === "Open" &&
								<FormText>Anyone can view and join your club at any point with no admin approval required</FormText>
							}

							{this.state.newClub.clubAccessType === "Moderated" &&
								<FormText>Anyone can view and request to join your club with admin approval required</FormText>
							}

							{this.state.newClub.clubAccessType === "Closed" &&
								<FormText>Only people with an invite can join your club. Won’t appear on search pages</FormText>
							}

						</FormGroup>
						<FormGroup check>
							<Label for="crossPlatformClub" check>
								<CustomInput 
									id="crossPlatformClub" 
									type="checkbox" 
									label="Cross-Platform Club" 
									name="isCrossPlatform" 
									checked={this.state.newClub.isCrossPlatform}
									className="large"
									onChange={this.handleCheck}
								/> 
								<FormText>People from across Playstation 4, Xbox One and PC can all join in with your created club</FormText>
							</Label>
						</FormGroup>
						<Row>
							<Col xs="12">
								<CoverImageSelector onChange={this.updateCoverImage} />
							</Col>
						</Row>
					</div>
					<Row>
						<Col xs="12">
							<DividerButton onClick={this.handleSubmit} className={(this.state.submitted ? "is-submitted " : "") + (!this.state.validation.name.isValid || !this.state.validation.description.isValid ? "is-disabled" : "")}>All Done</DividerButton>
						</Col>
					</Row>
				</Form>

				{this.state.showConfirmation && <DialogPopup header="Club Created" description="You have successfully created a club." confirmText="Ok" onConfirm={this.goToClub} />}
				{this.state.showError && <DialogPopup header="Error Occured" description={this.state.errorMessage} confirmText="Ok" onConfirm={this.resetDialogs} />}
				
			</Container>
		);
	};
}

/**
 * Define expected props.
 */
CreateClubForm.propTypes = {
    club: PropTypes.shape({
        state: PropTypes.string,
        value: PropTypes.object
    }),
	sendCreateClubForm: PropTypes.func,
	sendIsNameAvailable: PropTypes.func,
	isNameAvailable: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func,
		isFaulted: PropTypes.func
    }),
	sendIsDescriptonAllowed: PropTypes.func,
	isDescriptionAllowed: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func,
		isFaulted: PropTypes.func
    }),
	push: PropTypes.func.isRequired
};
 
//Connect this container component to the redux store.
export default connect(
    state => { 
		return { 
			club: state.createClub.form, 
			translate: getTranslate(state.localize),
			isNameAvailable: state.isNameAvailable.response,
			isDescriptionAllowed: state.isDescriptionAllowed.response,
		};  
	},   
    dispatch => bindActionCreators({ 
		push, 
		sendCreateClubForm,
		sendIsNameAvailable, 
		sendIsDescriptonAllowed 
	}, dispatch)
)(CreateClubForm);