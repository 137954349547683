import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function CancelChampionship() {
    return {
        type: actionTypes.SEND_CANCEL_CHAMPIONSHIP
    };
}

export function receivedCancelChampionshipSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CANCEL_CHAMPIONSHIP_SUCCESS,
        data
    };
}

export function receivedCancelChampionshipFailure(error) {
    return {
        type: actionTypes.RECEIVED_CANCEL_CHAMPIONSHIP_FAILURE,
        data: { error }
    };
}

/***
 * Cancel Championship Function
 */
export function sendCancelChampionship(cludId) {
    return function (dispatch) {
        dispatch(CancelChampionship());
        axiosFactory.getApiInstance().delete('/api/Club/' + cludId + '/activeChampionship')
            .then((resp) => {
                dispatch(receivedCancelChampionshipSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedCancelChampionshipFailure(error));
            });
    };
}