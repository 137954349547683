import React from 'react';
import Button from '../Button/Button';
import Signpost from '../Signpost/Signpost';
import Modal from 'react-modal';
import YouTube from 'react-youtube'; 
import NProgress from 'nprogress/nprogress.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Video.scss';
import '../../assets/scss/modal.scss';

library.add(faTimes);

/**
 * Video
 */
class Video extends React.Component {
		
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		
		this.state = {
			modalIsOpen: false		
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentWillMount() {
		Modal.setAppElement('body');
	}

	openModal() {
		NProgress.start()
		this.setState({modalIsOpen: true});
	}

	closeModal() {
		NProgress.done();
		this.setState({modalIsOpen: false});
	}

	_onReady() {
		NProgress.done();
	}

	handleClick() {
		this.openModal();
	}
	  
	render() {  
		const youtubeConfig = {
			playerVars: {
				autoplay: 1
			}
		};

		const {video} = this.props;

		console.log(video)

		return (
			<div className="Video">
				<div onClick={this.handleClick}>
					<Signpost img={video.highResThumbnail.url} placeholder={video.lowResThumbnail.url} icon="icon-play_circle" />
				</div>
				<Modal 
					isOpen={this.state.modalIsOpen} 
					onRequestClose={this.closeModal}  
					portalClassName="cmModal" 
					className="cmModal__body"
					overlayClassName="cmModal__overlay"
					contentLabel={"Watch " + video.title}
				>
					<Button type="standard" className="cmModal__close" onClick={this.closeModal}>
						<FontAwesomeIcon icon="times" color="#FFF" />
					</Button>
					<div className="cmModal__content">
						<div className="video-wrapper">
							<YouTube
								videoId={video.videoId}
								opts={youtubeConfig}
								onReady={this._onReady}
							/>
						</div>
					</div>
				</Modal>
			</div>
		);
	};
}

export default Video;