import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

/**
 *
 *
 * @export
 * @returns
 */
export function postApproveMember() {
    return {
        type: actionTypes.SEND_APPROVEMEMBER
    };
}

/**
 *
 *
 * @export
 * @param {*} data
 * @returns
 */
export function postApproveMemberSuccess(data) {
    return {
        type: actionTypes.RECEIVED_APPROVEMEMBER_SUCCESS,
        data
    };
}

/**
 *
 *
 * @export
 * @param {*} error
 * @returns
 */
export function postApproveMemberFailure(error) {
    return {
        type: actionTypes.RECEIVED_APPROVEMEMBER_FAILURE,
        data: { error }
    };
}

/***
 * Approve Member Function
 */
export function sendApproveMember(clubId, memberId) {
    return function (dispatch, getState) {
        dispatch(postApproveMember());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/request/approve/' + memberId + '/')
        .then((resp) => {
			dispatch(postApproveMemberSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postApproveMemberFailure(error));
		});
    };
}