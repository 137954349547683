import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postLeaveClub() {
    return {
        type: actionTypes.SEND_LEAVECLUB
    };
}

export function postLeaveCLubSuccess(data) {
    return {
        type: actionTypes.RECEIVED_LEAVECLUB_SUCCESS,
        data
    };
}

export function postLeaveClubFailure(error) {
    return {
        type: actionTypes.RECEIVED_LEAVECLUB_FAILURE,
        data: { error }
    };
}

/***
 * Kick Member Function
 */
export function sendLeaveClub(clubId) {
    return function (dispatch, getState) {
        dispatch(postLeaveClub());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/leave')
        .then((resp) => {
			dispatch(postLeaveCLubSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postLeaveClubFailure(error));
		});
    };
}