/**
 *  Club Search Reducers
 */

import * as actionTypes from '../../actions/ActionTypes';
import { StatefulValue, states } from '../../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    clubs: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_CLUBS:
            return {
                ...state,
                clubs: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_CLUBS_SUCCESS:
            return {
                ...state,
                clubs: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_CLUBS_FAILURE:
            return {
                ...state,
                clubs: new StatefulValue({}, states.Faulted)
            };
        
        default: return state;
    }
};