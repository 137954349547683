import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

/**
 * Identity Action Creators
 */

export function requestIdentity() {
    return {
        type: actionTypes.REQUEST_IDENTITY
    };
}

export function receivedIdentitySuccess(data) {
    return {
        type: actionTypes.RECEIVED_IDENTITY_SUCCESS,
        data
    };
}

export function receivedIdentityFailure(error) {
    return {
        type: actionTypes.RECEIVED_IDENTITY_FAILURE,
        data: { error }
    };
}

export function requestUpdatePlatform() {
    return {
        type: actionTypes.REQUEST_UPDATE_PLATFORM
    };
}

export function receivedUpdatePlatformSuccess(data) {
    return {
        type: actionTypes.RECIEVED_UPDATE_PLATFORM_SUCCESS,
        data
    };
}

export function receivedUpdatePlatformFailure(error) {
    return {
        type: actionTypes.RECIEVED_UPDATE_PLATFORM_FAILURE,
        data: { error }
    };
}

/***
 * Fetch users identity.
 */
export function fetchUsersIdentity() {
    return function (dispatch, getState) {
        dispatch(requestIdentity());

        axiosFactory.getApiInstance().get('/api/identity')
            .then((resp) => { dispatch(receivedIdentitySuccess(resp.data)); })
            .catch((error) => { dispatch(receivedIdentityFailure(error)); });
    };
}

/***
 * Update users platform.
 */
export function updateUsersPlatform(platform) {
    return function (dispatch, getState) {
        dispatch(requestUpdatePlatform());

        axiosFactory.getApiInstance().put('/api/identity/selectedPlatform?platform=' + platform)
            .then((resp) => { dispatch(receivedUpdatePlatformSuccess(resp.data)); })
            .catch((error) => { dispatch(receivedUpdatePlatformFailure(error)); });
    };
}