import values from 'lodash/values';

export const states = {
    /*Standard States*/
    Initialising: "INITIALISING",
    Loading: "LOADING",
    Ready: "READY",
    Faulted: "FAULTED",

    /*Custom Application States*/
    LoadSuccessful: "LOADSUCCESSFUL"
};

export const statesArray = (function () {
    return values(states);
})();

export class StatefulValue {
	
    constructor(value, initialState) {
        this.state = initialState || states.Initialising;
        this.value = value;
    }

    isInitialising = function () {
        return this.state === states.Initialising;
    }

    isLoading = function () {
        return this.state === states.Loading;
    }

    isReady = function () {
        return this.state === states.Ready;
    }

    isFaulted = function () {
        return this.state === states.Faulted;
    }

    isInState = function (state) {
        return this.state === state;
    }
}
