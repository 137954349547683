import React from 'react';
import PropTypes from 'prop-types';

import './Heading.scss';

/**
 * General typography heading styles 
 */
class Heading extends React.Component {

	render() { 
		const {rank, className, divider} = this.props;
        const Tag = rank > 5 ? 'h5' : `h${rank}`;

		return (
			<Tag className={className  + (divider ? ' with-divider' : '')}> 
				{this.props.children}
			</Tag>
		);
	};
}
 
Heading.propTypes = {
    /** Add class name to header */
    className: PropTypes.string,
    /** Display divider */
    divider: PropTypes.bool,
    /** Specify heading size to output */
    rank: PropTypes.oneOf([
        "1",
        "2",
        "3",
        "4",
        "5"
    ])
};

Heading.defaultProps = {
    rank: 3,
    className: "",
    divider: false
};
 
export default Heading;