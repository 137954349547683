import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import {Container, Row, Col} from 'reactstrap';
import { Form, FormGroup, Label, Input, CustomInput, FormText, FormFeedback } from 'reactstrap';
import CoverImageSelector from '../CoverImageSelector/CoverImageSelector';
import DividerButton from '../DividerButton/DividerButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendEditSettingsForm  } from '../../actions/Clubs/EditClubSettings';
import { getClubSettings  } from '../../actions/Clubs/GetClubSettings';
import { sendIsDescriptonAllowed } from '../../actions/Clubs/IsDescriptionAllowed';
import Loader from '../../components/Loader/Loader';
import DialogPopup from '../DialogPopup/DialogPopup';
import { push } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import map from 'lodash/map';

import './EditClubForm.scss'

/** 
 * Create Club Form
 */
class EditClubForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			clubSettings: {
				name: "",
				clubLanguageName: "English",
				description: "",
				clubAccessType: "Open",
				isCrossPlatform: true,
				coverImageId: 0,
				platform: ""
			},
			validation: {
				hasError: false,
				name: {
					isValid: false,
					hasError: false,
					errorMessage: ""
				},
				description: {
					isValid: false,
					hasError: false,
					errorMessage: ""
				}
			},
			showConfirmation: false,
			showError: false,
			errorMessage: "",
			submitted: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.updateCoverImage = this.updateCoverImage.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
		this.goToClub = this.goToClub.bind(this);
	}

	goToClub() {
		this.props.push('/clubs/club/' + this.props.clubId);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.getClubSettings(this.props.clubId);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.clubSettings.value !== newProps.clubSettings.value) {
			if(this.props.clubSettings.isReady()) {
				const clubSettings = newProps.clubSettings.value;
				this.setState( prevState => {
					return {
						...prevState.clubSettings, 
						clubSettings
					}
				});
			}
		}

		if(prevProps.editClubSettings !== newProps.editClubSettings) {

			if(this.props.editClubSettings.isReady()) {
				this.setState({showConfirmation: true});
			}

			if(this.props.editClubSettings.isFaulted()) {
				const error = this.props.editClubSettings.value.error.data;
				if(error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function(item) {
						const errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");

						if(item.name === "") {
							this.setState(prevState => {
								return {
									...prevState,
									showError: true,
									errorMessage: errorMessage
								};
							});
						} else {
							this.setState( prevState => {
								return {
									validation: {
										...prevState.validation, [item.name]: {
											isValid: false,
											hasError: true,
											errorMessage: errorMessage
										}
									}
								}
							});
						}
					}, this);
				}
			}
		}

		if (prevProps.isDescriptionAllowed.value !== newProps.isDescriptionAllowed.value) {

			if(this.props.isDescriptionAllowed.isReady()) {
				this.setState(prevState => {
					return {
						validation: {
							...prevState.validation, 
							hasError: false,
							description: {
								isValid: true,
								hasError: false,
								errorMessage: ""
							}
						}
					};
				});
			}

			if (this.props.isDescriptionAllowed.isFaulted()) {
				const error = this.props.isDescriptionAllowed.value.error.response.data;
				if (error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function (item) {
						var errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState(prevState => {
							return {
								validation: {
									...prevState.validation, 
									hasError: true,
									description: {
										isValid: false,
										hasError: true,
										errorMessage: errorMessage
									}
								}
							};
						});
					}, this);
				}
			}
		}
	}

	validateForm(e) {
		const { validation } = this.state;

		const serverValidation = {
			"description": e.target.value,
			"checkForProfanity": true
		}

		if(e.target.name === 'description') {
			const regex = /^[a-zA-Z0-9\s\-_áéíóúüñ¿¡èàùâêîôûëïçäößìò«»ÁÉÍÓÚÜÑÈÀÙÂÊÎÔÛËÏÇÄÖßÌÒ\n'""\!”‘`%&\(\)#:;,\.<>\?/]+$/;
			if (!e.target.value || e.target.value.trim() === '') {
				validation.hasError = true;
				validation.description.hasError = true;
				validation.description.errorMessage = this.props.translate("clubs.error.descMissing");
				validation.description.isValid = false;
			} else if (!regex.test(e.target.value)) {
				validation.hasError = true;
				validation.description.hasError = true;
				validation.description.errorMessage = this.props.translate("clubs.error.descChars");
				validation.description.isValid = false;
			} else {
				this.props.sendIsDescriptonAllowed(serverValidation);
			}
		}

		this.setState({ validation });
	}

	resetDialogs() {
		this.setState({
			showConfirmation: false,
			showError: false,
			errorMessage: "",
			submitted: false
		});
	}
	
	handleSubmit(e) {
		e.preventDefault();

		if(!this.state.validation.hasError) {

			this.setState({
				submitted: true
			});

			let clubData = this.state.clubSettings;
			this.props.sendEditSettingsForm(this.props.clubId, clubData);
		}
	}

	handleInput(e) {
		let value = e.target.value;
		let name = e.target.name;
		this.setState( prevState => {
			return { 
				clubSettings: {
					...prevState.clubSettings, [name]: value
				}
			}
		})
	}

	handleCheck(e) {
		let name = e.target.name;
		let value = this.state.clubSettings[name];
		this.setState( prevState => {
			return { 
				clubSettings: {
					...prevState.clubSettings, [name]: !value 
				}
			}
		})
	}
	
	updateCoverImage(image) {
		this.setState( prevState => {
			return { 
				clubSettings: {
					...prevState.clubSettings, 
					coverImageId: image 
				}
			}
		})
	}

	render() { 
		if(this.props.clubSettings.isReady()) {
			return (
				<Container>
					<Form id="EditClubForm" className="EditClubForm">
						<div className="EditClubForm__Options">
							<Heading rank="2">Edit My Club</Heading>
							<FormGroup>
								<Label for="clubDescription" className="sr-only">Club Description</Label>
								<Input 
									type="textarea" 
									name="description" 
									id="clubDescription" 
									placeholder="Club Description" 
									value={this.state.clubSettings.description}
									onBlur={this.validateForm} 
									valid={this.state.validation.description.isValid}
									invalid ={this.state.validation.description.hasError}
									onChange={this.handleInput}
								/>
								<FormFeedback>
									{this.state.validation.description.errorMessage}
								</FormFeedback>
							</FormGroup>
							<FormGroup>
								<Label for="clubJoinOptions" className="sr-only">Club Join Options</Label>
								<div>
									<CustomInput 
										type="radio" 
										id="clubJoinOption_Open"
										name="clubAccessType" 
										label="Open" 
										value="Open" 
										checked={(this.state.clubSettings.clubAccessType === 'Open' ? true : false)}
										onChange={this.handleInput}
										className="large"
										inline 
									/>
									<CustomInput 
										type="radio"
										id="clubJoinOption_Moderated" 
										name="clubAccessType" 
										label="Moderated" 
										value="Moderated" 
										checked={(this.state.clubSettings.clubAccessType === 'Moderated' ? true : false)}
										onChange={this.handleInput} 
										className="large"
										inline 
									/>
									<CustomInput 
										type="radio" 
										id="clubJoinOption_Closed" 
										name="clubAccessType" 
										label="Closed" 
										value="Closed" 
										checked={(this.state.clubSettings.clubAccessType === 'Closed' ? true : false)}
										onChange={this.handleInput}
										className="large"
										inline 
									/>
								</div>

								{this.state.clubSettings.clubAccessType === "Open" &&
									<FormText>Anyone can view and join your club at any point with no admin approval required</FormText>
								}

								{this.state.clubSettings.clubAccessType === "Moderated" &&
									<FormText>Anyone can view and request to join your club with admin approval required</FormText>
								}

								{this.state.clubSettings.clubAccessType === "Closed" &&
									<FormText>Only people with an invite can join your club. Won’t appear on search pages</FormText>
								}							
							</FormGroup>
							<FormGroup check>
								<Label for="crossPlatformClub" check>
									<CustomInput 
										id="crossPlatformClub" 
										type="checkbox" 
										label="Cross-Platform Club" 
										name="isCrossPlatform" 
										checked={this.state.clubSettings.isCrossPlatform}
										className="large"
										onChange={this.handleCheck}
									/> 
									<FormText>When selected people from across PlayStation 4, Xbox One and PC can all join in with your created club</FormText>
								</Label>
							</FormGroup>
							<Row>
								<Col xs="12">
									<CoverImageSelector selectedImage={this.state.clubSettings.coverImageId} onChange={this.updateCoverImage} />
								</Col>
							</Row>
						</div>
						<Row>
							<Col xs="12">
								<DividerButton onClick={this.handleSubmit} className={(this.state.submitted ? "is-submitted" : "") + (this.state.validation.hasError ? "is-disabled" : "")}>All Done</DividerButton>
							</Col>
						</Row>
					</Form>
					{this.state.showConfirmation && <DialogPopup header="Edit Complete" description="You have successfully edited your club." confirmText="Ok" onConfirm={this.goToClub} />}
					{this.state.showError && <DialogPopup header="Error Occured" description={this.state.errorMessage} confirmText="Ok" onConfirm={this.resetDialogs} />}
				</Container>
			);
		} else if(this.props.clubSettings.isFaulted()) {
			return (
				<Row>
					<Col xs="12"> 
						<Loader currentState={this.props.clubSettings.state}  errorMessage="Failed to club" />
					</Col>
				</Row>
			);
		} else {
			return (
				<Row>
					<Col xs="12">
						<Loader currentState={this.props.clubSettings.state} />
					</Col>
				</Row>
			);
		}
	};
}

/**
 * Define expected props.
 */
EditClubForm.propTypes = {
	clubId: PropTypes.number.isRequired,
    editClubSettings: PropTypes.shape({
		state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	clubSettings: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	getClubSettings: PropTypes.func,
	sendEditSettingsForm: PropTypes.func,
	push: PropTypes.func,
	sendIsDescriptonAllowed: PropTypes.func,
	isDescriptionAllowed: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.object,
		isReady: PropTypes.func,
		isFaulted: PropTypes.func
    }),
};
 
//Connect this container component to the redux store.
export default connect(
    state => { 
		return { 
			editClubSettings: state.editClubSettings.form, 
			translate: getTranslate(state.localize),
			clubSettings: state.getClubSettings.club,
			isDescriptionAllowed: state.isDescriptionAllowed.response
		} 
	},   
    dispatch => bindActionCreators({ push, sendEditSettingsForm, getClubSettings, sendIsDescriptonAllowed}, dispatch)
)(EditClubForm);