import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function requestScreenshots() {
    return {
        type: actionTypes.REQUEST_SCREENSHOTS
    };
}

export function receivedScreenshotsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_SCREENSHOTS_SUCCESS,
        data
    };
}

export function receivedScreenshotsFailure(error) {
    return {
        type: actionTypes.RECEIVED_SCREENSHOTS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the screenshots json file
 */
export function fetchScreenshots() {
    return function (dispatch, getState) {
        dispatch(requestScreenshots());

        axiosFactory.getInstance().get(getState().application.value.cdnEndpoint + '/screenshots.json')
            .then((resp) => {
                dispatch(receivedScreenshotsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedScreenshotsFailure(error));
            });
    };
}