import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestClubs() {
    return {
        type: actionTypes.REQUEST_CLUBS
    };
}

export function receivedClubsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CLUBS_SUCCESS,
        data
    };
}

export function receivedClubsFailure(error) {
    return {
        type: actionTypes.RECEIVED_CLUBS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function searchClubs(query) {
    return function (dispatch) {
        dispatch(requestClubs());
        axiosFactory.getApiInstance().post('/api/Club/Search', query)
            .then((resp) => {
                dispatch(receivedClubsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedClubsFailure(error));
            });
    };
}