/**
 * Edit Club Settings Reducers
 */

import * as actionTypes from '../../actions/ActionTypes';
import { StatefulValue, states } from '../../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    form: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.SEND_EDITCLUBSETTINGS:
            return {
                ...state,
                form: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_EDITCLUBSETTINGS_SUCCESS:
            return {
                ...state,
                form: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_EDITCLUBSETTINGS_FAILURE:
            return {
                ...state,
                form: new StatefulValue(action.data, states.Faulted)
            };
        
        default: return state;
    }
};