import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function requestGameRetailers() {
    return {
        type: actionTypes.REQUEST_GAME_RETAILERS
    };
}

export function receivedGameRetailersSuccess(data) {
    return {
        type: actionTypes.RECEIVED_GAME_RETAILERS_SUCCESS,
        data
    };
}

export function receivedGameRetailersFailure(error) {
    return {
        type: actionTypes.RECEIVED_GAME_RETAILERS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the game retailers json file
 */
export function fetchGameRetailers() {
    return function (dispatch, getState) {
        dispatch(requestGameRetailers());

        axiosFactory.getInstance().get(getState().application.value.cdnEndpoint + '/gameRetailers.json')
            .then((resp) => {
                dispatch(receivedGameRetailersSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedGameRetailersFailure(error));
            });
    };
}

export function requestTrialsRetailers() {
    return {
        type: actionTypes.REQUEST_TRIAL_RETAILERS
    };
}

export function receivedTrialsRetailersSuccess(data) {
    return {
        type: actionTypes.RECEIVED_TRIAL_RETAILERS_SUCCESS,
        data
    };
}

export function receivedTrialsRetailersFailure(error) {
    return {
        type: actionTypes.RECEIVED_TRIAL_RETAILERS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the trial retailers json file
 */
export function fetchGameTrial() {
    return function (dispatch, getState) {
        dispatch(requestTrialsRetailers());

        axiosFactory.getInstance().get(getState().application.value.cdnEndpoint + '/gameTrials.json')
            .then((resp) => {
                dispatch(receivedTrialsRetailersSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedTrialsRetailersFailure(error));
            });
    };
}

export function requestContentRetailers() {
    return {
        type: actionTypes.REQUEST_CONTENT_RETAILERS
    };
}

export function receivedContentRetailersSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CONTENT_RETAILERS_SUCCESS,
        data
    };
}

export function receivedContentRetailersFailure(error) {
    return {
        type: actionTypes.RECEIVED_CONTENT_RETAILERS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the content retailers json file
 */
export function fetchContentRetailers() {
    return function (dispatch, getState) {
        dispatch(requestContentRetailers());

        axiosFactory.getInstance().get(getState().application.value.cdnEndpoint + '/contentRetailers.json')
            .then((resp) => {
                dispatch(receivedContentRetailersSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedContentRetailersFailure(error));
            });
    };
}