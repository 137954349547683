import React from 'react';
import ReactDOM from "react-dom";
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FormGroup, Label, Row, Col, CustomInput, FormFeedback } from 'reactstrap';
import moment from 'moment';
import Moment from 'react-moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle, faTimes, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendCreateChampionshipForm } from '../../actions/Clubs/CreateChampionship';
import { sendEditChampionshipForm } from '../../actions/Clubs/EditChampionship';
import { fetchChampionshipEnd } from '../../actions/Clubs/GetChampionshipEnd';
import DialogPopup from '../DialogPopup/DialogPopup';
import { push } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import map from 'lodash/map';

import './ChampionshipPreview.scss';

library.add(faPlusCircle, faTimes, faClock);

/** 
 * ChampionshipPreview
 */
class ChampionshipPreview extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            championship: this.props.championship,
            advancedSettings: false,
            championshipCreated: false,
			championshipEdited: false,
			permissionsError: false,
            validation: {
                hasError: false,
                start: {
                    isValid: false,
                    hasError: false,
                    errorMessage: ""
                }
            },
            lastChampionshipEnd: new Date()
        };

		this.updateDate = this.updateDate.bind(this);
		this.endDate = this.endDate.bind(this);
		this.toggleSettings = this.toggleSettings.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submitChampionship = this.submitChampionship.bind(this);
		this.goToClub = this.goToClub.bind(this);
		this.resetDialog = this.resetDialog.bind(this);
	}

	componentDidMount() {
		this.props.fetchChampionshipEnd(this.props.clubId);
	}
	
	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.championshipResult !== newProps.championshipResult) {
			if(this.props.championshipResult.isReady()) {
				this.setState({
					championshipCreated: "success"
				});
			} else if(this.props.championshipResult.isFaulted()) {
				const error = this.props.championshipResult.value.error.response.data;
				if(error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function(item) {
						let errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState( prevState => {
                            return {
                                validation: {
                                    ...prevState.validation, [item.name]: {
                                        isValid: false,
                                        hasError: true,
                                        errorMessage: errorMessage
                                    }
                                }
                            };
						});
					}, this);
				} else if (this.props.championshipResult.value.error.response.status === 403) {
					this.setState( prevState => {
						return {
							permissionsError: "error"
						};
					});
				}
			}
		}

		if(prevProps.editChampionshipResult !== newProps.editChampionshipResult) {
			if(this.props.editChampionshipResult.isReady()) {
				this.setState({
					championshipEdited: "success"
				});
			} else if(this.props.editChampionshipResult.isFaulted()) {
                const error = this.props.editChampionshipResult.value.error.response.data;
				if(error.hasValidationErrors) {
					error.validationFieldErrors.forEach(function(item) {
						let errors = map(item.errors, this.props.translate);
						let errorMessage = errors.join(", ");
						this.setState( prevState => {
                            return {
                                validation: {
                                    ...prevState.validation, [item.name]: {
                                        isValid: false,
                                        hasError: true,
                                        errorMessage: errorMessage
                                    }
                                }
                            };
						});
					}, this);
				} else if (this.props.championshipResult.value.error.response.status === 403) {
					this.setState( prevState => {
						return {
							permissionsError: "error"
						};
					});
				}
			}
		}

		if(prevProps.getChampionshipEnd !== newProps.getChampionshipEnd) {
			if(this.props.getChampionshipEnd.isReady()) {
                this.setState({
                    lastChampionshipEnd: new Date(this.props.getChampionshipEnd.value)
                });
			}
		}
	}

	submitChampionship() {
		if(this.props.existing) {
			this.props.sendEditChampionshipForm(this.state.championship, this.props.clubId);
		} else {
			this.props.sendCreateChampionshipForm(this.state.championship, this.props.clubId);
		}
	}

	resetDialog() {
        this.setState({
			championshipCreated: false,
			permissionsError: false
        });
	}

	closeModal() {
		this.props.cancelPreview();
	}

	handleChange(e) {
		let name = e.target.name;
		let checked = e.target.checked;

		this.setState( prevState => {
			return {
				championship: {
					...prevState.championship, 
					[name]: checked
				}
			}
		});
	}

	toggleSettings() {
        this.setState({
            advancedSettings: !this.state.advancedSettings
        });
	}

	goToClub() {
		this.props.push('/clubs/club/' +  this.props.clubId)
	}

	updateDate(date) {
		let newDate = new Date(date);
		newDate = newDate.toISOString();

		let championship = this.state.championship;
		championship.start = newDate;

		this.setState( prevState => {
            return {
                ...prevState,
                championship: championship
            };
		});
	}

	endDate() {
		let date = moment(this.state.championship.start);
		this.state.championship.events.forEach((event) => {
			date.add(parseInt(event.durationDays), 'days').add(parseInt(event.durationHours), 'hours').add(parseInt(event.durationMins), 'minutes');
		});
		return (date);
	}

	render() { 
		return (
			<React.Fragment>
				{this.state.championshipCreated === false &&
					<Portal>
						<div className="ChampionshipPreview" tabIndex="-1" role="dialog">
							<div className="ChampionshipPreview__Modal" role="document">
								<Button type="standard" className="ChampionshipPreview__close" onClick={this.closeModal}>
									<FontAwesomeIcon icon="times" color="#FFF" />
								</Button>
								<div className="ChampionshipPreview__ModalContent">
									<Heading rank="3" className="m-b-10">Championship Preview</Heading>
									<FormGroup>
										<Row>
											<Col xs="12">
												<Label for="start">Set Start Date and Time:</Label>
											</Col>
										</Row>
										<Row>
											<Col xs="12">
												<div className="ChampionshipPreview__DatePicker">
													<div className="ChampionshipPreview__DatePicker__Icon">
														<FontAwesomeIcon icon="clock" color="#FFF" />
													</div>
													<DatePicker
														name="start"
														className={"form-control small " + (this.state.validation.start.hasError ? "is-invalid" : "")}
														selected={new Date(this.state.championship.start)} 
														onChange={this.updateDate}
														showTimeSelect
														timeFormat="HH:mm"
														timeIntervals={15}
														dateFormat="MMMM d, yyyy h:mm aa"
														timeCaption="time"
														minDate={this.state.lastChampionshipEnd}
													/>
												</div>
												<FormFeedback valid={!this.state.validation.start.hasError} style={{display: (this.state.validation.start.hasError ? "block" : "none")}}>
													{this.state.validation.start.errorMessage}
												</FormFeedback>
											</Col>
										</Row>
									</FormGroup>
									<Row>
										<Col xs="12">
											<table>
												<thead>
													<tr>
														<th className="text-left">Location</th>
														<th className="text-right">Duration</th>
													</tr>
												</thead>
												<tbody>
													{this.state.championship.events.map((event, index) =>
														<tr key={index}>
															<td className="text-left">{index + 1} - {this.props.translate("clubs.locations." + event.locationId)}</td>
															<td className="text-right">
																{event.stages.length} Stages - 
																{event.durationDays > 1 &&
																	<React.Fragment>
																		{event.durationDays} Days
																	</React.Fragment>
																}
																{event.durationHours !== 0 && 
																	<React.Fragment>
																		&nbsp;{event.durationHours} Hour{(event.durationHours > 1 ? "s" : "")} 
																	</React.Fragment>
																}
																{event.durationMins !== 0 && 
																	<React.Fragment>
																		&nbsp;{event.durationMins} Min{(event.durationMins > 1 ? "s" : "")} 
																	</React.Fragment>
																}
															</td>
														</tr>
													)}
												</tbody>
												<tfoot>
													<tr>
														<td colSpan="2">Championship ends on <Moment format="MMMM Do @ HH:mm">{this.endDate()}</Moment></td>
													</tr>
												</tfoot>
											</table>
										</Col>
									</Row>
									<Row>
										<Col xs="12">
											<div className={"ChampionshipPreview__advanced " + (this.state.advancedSettings ? "is-active" : "")}>
												<div className="ChampionshipPreview__advanced__toggle" onClick={this.toggleSettings}>
													<FontAwesomeIcon icon="plus-circle" color="#FFF" />
													Advanced Settings
												</div>
												<div className="ChampionshipPreview__advanced__toggles">
													<Row>
														<Col xs="12">
															<FormGroup>
																<CustomInput className="large" type="checkbox" id="useHardcoreDamage" name="useHardcoreDamage" label="Use hardcore damage" checked={this.state.championship.useHardcoreDamage} onChange={(e) => this.handleChange(e)} inline />
															</FormGroup>
															<FormGroup>
																<CustomInput className="large" type="checkbox" id="useUnexpectedMoments" name="useUnexpectedMoments" label="Use unexpected moments" checked={this.state.championship.useUnexpectedMoments} onChange={(e) => this.handleChange(e)} inline />
															</FormGroup>
															<FormGroup>
																<CustomInput className="large" type="checkbox" id="forceCockpitCamera" name="forceCockpitCamera" label="Force cockpit camera" checked={this.state.championship.forceCockpitCamera} onChange={(e) => this.handleChange(e)} inline />
															</FormGroup>
															<FormGroup>
																<CustomInput className="large" type="checkbox" id="allowAssists" name="allowAssists" label="Allow assists" checked={this.state.championship.allowAssists} onChange={(e) => this.handleChange(e)} inline />
															</FormGroup>
														</Col>
													</Row>
												</div>	
											</div>						
										</Col>
									</Row>
									<Row>
										<Col xs="12" className="text-center">
											{this.props.existing &&
												<Button type="standard" onClick={this.submitChampionship} block>Edit Championship</Button>
											}

											{!this.props.existing &&
												<Button type="standard" onClick={this.submitChampionship} block>Submit Championship</Button>
											}
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Portal>
				}

				{this.state.championshipCreated === "success" && 
					<DialogPopup header="Championship Created" description="You have successfully created a championship." confirmText="Ok" onConfirm={this.goToClub} />
				}

				{this.state.championshipEdited === "success" && 
					<DialogPopup header="Championship Edited" description="You have successfully edited a championship." confirmText="Ok" onConfirm={this.goToClub} />
				}

				{this.state.championshipCreated === "error" && 
					<DialogPopup header="Error" description="An error occured and your championship wasn't created." confirmText="Ok" onConfirm={this.resetDialog} />
				}

				{this.state.permissionsError === "error" && 
					<DialogPopup header="Error" description="You do not have the sufficient permissions to complete this action." confirmText="Ok" onConfirm={this.resetDialog} />
				}
			</React.Fragment>
		);
	};
}

/**
 * Define expected props.
 */
ChampionshipPreview.propTypes = {
	clubId: PropTypes.number.isRequired,
	championship: PropTypes.object.isRequired,
	championshipResult: PropTypes.shape({
        state: PropTypes.string,
        value: PropTypes.object
    }),
	sendCreateChampionshipForm: PropTypes.func,
	push: PropTypes.func.isRequired,
	sendEditChampionshipForm: PropTypes.func,
	editChampionshipResult: PropTypes.shape({
        state: PropTypes.string,
        value: PropTypes.object
	}),
	fetchChampionshipEnd: PropTypes.func,
	getChampionshipEnd: PropTypes.shape({
        state: PropTypes.string,
        value: PropTypes.string
	}),
};

const container = document.createElement("div");
document.body.appendChild(container);
ReactDOM.render(document.getElementById('app'), container);

//Connect this container component to the redux store.
export default connect(
    state => { 
		return { 
			championshipResult: state.createChampionship.form, 
			editChampionshipResult: state.editChampionship.form, 
			getChampionshipEnd: state.getChampionshipEnd.date,
			translate: getTranslate(state.localize)
		} 
	},   
    dispatch => bindActionCreators({ push, sendCreateChampionshipForm, sendEditChampionshipForm, fetchChampionshipEnd }, dispatch)
)(ChampionshipPreview);