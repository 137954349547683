import React from 'react';
import PropTypes from 'prop-types';
import {
	Container,
	Row,
	Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChampionshipOptions } from '../../actions/Clubs/ChampionshipOptions';
import ChampionshipEventEditor from '../ChampionshipEventEditor/ChampionshipEventEditor';
import Loader from '../Loader/Loader';
import ChampionshipConfirmation from '../ChampionshipConfirmation/ChampionshipConfirmation';
import ChampionshipPreview from '../ChampionshipPreview/ChampionshipPreview';
import ChallengeSelector from '../ChallengeSelector/ChallengeSelector';
import DialogPopup from '../DialogPopup/DialogPopup';

import './EditChampionship.scss'


/** 
 * Edit Championship
 */
class EditChampionship extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			activeEvent: 0,
			championship: this.props.championship,
			previewChampionship: false,
			minimumEvents: false
		};

		this.updateEvent = this.updateEvent.bind(this);
		this.updateActiveEvent = this.updateActiveEvent.bind(this);
		this.togglePreview = this.togglePreview.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);
		this.addEvent = this.addEvent.bind(this);
		this.closeDialogs = this.closeDialogs.bind(this);
	}

	componentDidMount() {
		this.props.getChampionshipOptions();
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.championship !== newProps.championship) {
			this.setState({
				championship: this.props.championship
			})
		}
	}

	togglePreview() {
		this.setState({
			previewChamionship: !this.state.previewChampionship
		})
	}

	closeDialogs() {
		this.setState({
			previewChamionship: false,
			minimumEvents: false
		})
	}

	updateEvent(event) {
		const championship = this.state.championship;	
		championship.events[this.state.activeEvent] = event;

		this.setState(prevState => {
			return {
				...prevState.championship, 
				championship: championship
			}
		});
	}

	updateActiveEvent(event) {
		this.setState(prevState => {
			return {
				...prevState.activeEvent,
				activeEvent: event
			}
		});
	}

	addEvent() {
		if(this.state.championship.events.length < 12) {
			let newEvents = this.state.championship.events;

			const newEvent = {
				"locationId": "eSpain",
				"durationDays": 2,
				"durationHours": 0,
				"durationMins": 0,
				"vehicleClassRestriction": "eRallyGrpACaps",
				"stages": [
					{
						"route": "eSpainRally01Route1",
						"timeOfDay": "eMiddayDry",
						"serviceArea": "eMedium",
						"surfaceDegradation": 0
					}
				]
			}

			newEvents.push(newEvent);

			this.setState(prevState => {
				return {
					championship: {
						...prevState.championship,
						events: newEvents
					}
				}
			}, () => {
				this.setState({
					activeEvent: this.state.championship.events.length - 1
				})
			});
		}
	}

	deleteEvent() {
		if(this.state.championship.events.length > 1) {
			let newEvents = this.state.championship.events;
			newEvents.splice(this.state.activeEvent, 1);

			this.setState(prevState => {
				return {
					activeEvent: (this.state.activeEvent > 1 ? this.state.activeEvent - 1 : 0),
					championship: {
						...prevState.championship,
						events: newEvents
					}
				}
			});
		} else {
			this.setState({
				minimumEvents: true
			});
		}
	}
	
	render() { 
		if (this.props.options.isReady()) {
			return (
				<div className="EditChampionship">
					<Container>
						<Row>
							<Col xs="12">
								<ChallengeSelector selectedChallenge={this.state.activeEvent} totalChallenges={this.state.championship.events.length - 1} onChange={this.updateActiveEvent} description={"Event " + (this.state.activeEvent + 1) + " of " + this.props.championship.events.length} />
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<ChampionshipEventEditor event={this.state.championship.events[this.state.activeEvent]} options={this.props.options.value} eventUpdate={this.updateEvent} addEvent={this.addEvent} deleteEvent={this.deleteEvent} totalEvents={this.state.championship.events.length} />
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<ChampionshipConfirmation selectedEvent={this.state.activeEvent} totalEvents={this.props.championship.events.length - 1} onEventUpdate={this.updateActiveEvent} onButtonClick={this.togglePreview} existing={this.props.existing} />
							</Col>
						</Row>
					</Container>

					{this.state.previewChamionship &&
						<ChampionshipPreview clubId={this.props.clubId} championship={this.state.championship} cancelPreview={this.closeDialogs} existing={this.props.existing} />
					}

					{this.state.minimumEvents && 
						<DialogPopup header="Minimum Events Reached" description="A minumum of 1 event is required." confirmText="Ok" onConfirm={this.closeDialogs} onDismiss={this.closeDialogs} />
					}

				</div>
			);
		} else if (this.props.options.isFaulted()) {
			return (<Loader currentState={this.props.options.state} errorMessage="Failed to load" />);
        } else /*isIntialising() or isLoading()*/ {
            return (<Loader currentState={this.props.options.state} />);
        }    
	};
}

/**
 * Define expected props.
 */
EditChampionship.propTypes = {
	clubId: PropTypes.number.isRequired,
    options: PropTypes.shape({
        state: PropTypes.string,
        value: PropTypes.object,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
    }),
	getChampionshipOptions: PropTypes.func,
	championship: PropTypes.object
};
 
//Connect this container component to the redux store.
export default connect(
    state => { return { options: state.championshipOptions.options} },   
    dispatch => bindActionCreators({ getChampionshipOptions }, dispatch)
)(EditChampionship);