import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import NewsListing from '../NewsListing/NewsListing';
import { NavLink } from 'react-router-dom';

import './LatestNews.scss';

/**
 * LatestNews
 */
export default class LatestNews extends React.Component {
	render() {  
		return (
            <div className="LatestNews">
				<Row>
					{this.props.news.map((item, index) =>
						<Col key={`col-${index}`} xs="12" sm="6" md="4">
							<NavLink key={item.codename} to={"/News/" + item.url}>
								<NewsListing 
								key={item.codename} 
								image={(item.featureImage.url == null ? "/images/news_default.jpg" : item.featureImage.url)} 
								placeholder={(item.featureImage.url == null ? "/images/news_default.jpg" : item.featureImage.url)} 
								title={item.title} />
							</NavLink>
						</Col>
					)}
				</Row>
			</div>
		);
	};
}

/**
 * Define expected props.
 */
LatestNews.propTypes = {
    news: PropTypes.array.isRequired
};



