import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

/**
 * Client Store Actions
 */

export function requestInitialState() {
    return {
        type: actionTypes.REQUEST_CLIENT_STORE_INITIAL_STATE
    };
}

export function receivedInitialStateSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CLIENT_STORE_INITIAL_STATE_SUCCESS,
        data
    };
}

export function receivedInitialStateFailure(error) {
    return {
        type: actionTypes.RECEIVED_CLIENT_STORE_INITIAL_STATE_FAILED,
        data: { error }
    };
}

/***
 * Fetch the applications initial state. 
 */
export function fetchInitialState() {
    return function (dispatch, getState) {
        dispatch(requestInitialState());

        axiosFactory.getInstance().get('/api/ClientStore/GetInitialState')
            .then((resp) => { dispatch(receivedInitialStateSuccess(resp.data)); })
            .catch((error) => { dispatch(receivedInitialStateFailure(error)); });
    };
}