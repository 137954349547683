import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function requestEsportEvents() {
    return {
        type: actionTypes.REQUEST_ESPORT_EVENTS
    };
}

export function receivedEsportEventsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_ESPORT_EVENTS_SUCCESS,
        data
    };
}

export function receivedEsportEventsFailure(error) {
    return {
        type: actionTypes.RECEIVED_ESPORT_EVENTS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the community events
 */
export function fetchEsportEvents() {
    return function (dispatch, getState) {
        dispatch(requestEsportEvents());

        axiosFactory.getApiInstance().get('/api/Esport')
            .then((resp) => {
                dispatch(receivedEsportEventsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedEsportEventsFailure(error));
            });
    };
}