import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function requestEvents() {
    return {
        type: actionTypes.REQUEST_EVENTS
    };
}

export function receivedEventsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_EVENTS_SUCCESS,
        data
    };
}

export function receivedEventsFailure(error) {
    return {
        type: actionTypes.RECEIVED_EVENTS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the community events
 */
export function fetchEvents() {
    return function (dispatch, getState) {
        dispatch(requestEvents());

        axiosFactory.getApiInstance().get('/api/Challenge/Community')
            .then((resp) => {
                dispatch(receivedEventsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedEventsFailure(error));
            });
    };
}