import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { bindActionCreators } from 'redux';
import { fetchCoverImages } from '../../actions/Clubs/CoverImages';
import { Scrollbars } from 'react-custom-scrollbars';
import Heading from '../../components/Heading/Heading';
import {Row, Col} from 'reactstrap'; 
import MediaQuery from 'react-responsive';

import './CoverImageSelector.scss'

/** 
 * Cover Image Selector
 */
class CoverImageSelector extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedImage: 0
		}
		
		this.selectImage = this.selectImage.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchCoverImages();
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.selectedImage !== newProps.selectedImage) {
			this.setState( prevState => {
				return {
					...prevState.selectedImage,
					selectedImage: this.props.selectedImage
				}
			});
		}

		if(prevProps.gallery !== newProps.gallery) {
			if(this.props.gallery.isReady() && this.props.selectedImage === undefined) {
				this.selectImage(this.props.gallery.value[Math.floor(Math.random() * this.props.gallery.value.length)].id);
			}
		}
	}

	/**
     * Turns the provided relative path to an absolute cdn path. 
     * @param {any} relativePath
	*/
    getCdnImagePath(relativePath) {
        return this.props.application.cdnEndpoint + relativePath;
	}

	selectImage(index) {
		this.setState({selectedImage: index});
		this.props.onChange(index);
	}
	
	selectedImage() {
		return this.props.application.cdnEndpoint + "/covers/" + ('0' + (this.state.selectedImage + 1)).slice(-2) + ".jpg";
	}

	render() { 
		const images = this.props.gallery.value;

		if (this.props.gallery.isReady()) {
			return (
				<div className="CoverImageSelector">
					<Row>
						<Col xs="12">
							<Heading rank="4">Select Cover Image:</Heading>
						</Col>
					</Row>
					<Row>
						<Col sm="6">
							<div className="CoverImageSelector__full">
								<img src={this.selectedImage()} 
									width="100%" 
									height="auto" 
									alt=""
								/>
							</div>
						</Col>
						<Col sm="6">
							<MediaQuery query="(min-width: 769px)">
								<Scrollbars 
									style={{ width: '100%', height: '100%', renderTrackVertical: true}}
									renderTrackVertical={({ style, ...props }) => <div {...props} className="trackVertical" />
								}>
									<div className="CoverImageSelector__thumbnails">
										{images.map((image, index) =>
											<div key={index} className={"CoverImageSelector__thumb " + (this.state.selectedImage === index ? "is-active" : "")} onClick={() => this.selectImage(index)}>
												<img src={this.getCdnImagePath(image.thumbnail)} 
													width="100%" 
													height="auto" 
													alt="" 
												/>
											</div> 
										)}
									</div>
								</Scrollbars>
							</MediaQuery>
							<MediaQuery query="(max-width: 768px)">
								<Scrollbars 
									style={{ width: '100%', height: '150px', renderTrackVertical: true}}
									renderTrackVertical={({ style, ...props }) => <div {...props} className="trackVertical" />
								}>
									<div className="CoverImageSelector__thumbnails">
										{images.map((image, index) =>
											<div key={index} className={"CoverImageSelector__thumb " + (this.state.selectedImage === index ? "is-active" : "")} onClick={() => this.selectImage(index)}>
												<img src={this.getCdnImagePath(image.thumbnail)} 
													width="100%" 
													height="auto" 
													alt="" 
												/>
											</div> 
										)}
									</div>
								</Scrollbars>
							</MediaQuery>
						</Col>
					</Row>
				</div>
			);
		} else if (this.props.gallery.isFaulted()) {
			return (
				<Row>
					<Col xs="12">
						<Loader currentState={this.props.gallery.state}  errorMessage="Failed to load cover images" />
					</Col>
				</Row>
			);
		} else {
			return (
				<Row>
					<Col xs="12">
						<Loader currentState={this.props.gallery.state} />
					</Col>
				</Row>
			);
		}
	};
}

/**
 * Define expected props.
 */
CoverImageSelector.propTypes = {
	selectedImage: PropTypes.number,
    gallery: PropTypes.shape({
        state: PropTypes.string.isRequired,
        value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
    }),
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
    }),
	fetchCoverImages: PropTypes.func.isRequired,
	/** Gets called when the user clicks a cover image */
	onUpdate: PropTypes.func,
};

//Connect this container component to the redux store.
export default connect(
    state => { return { gallery: state.coverImages.images, application: state.application.value } }, 
    dispatch => bindActionCreators({ fetchCoverImages }, dispatch)
)(CoverImageSelector);