import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Bootstrapper from './containers/Bootstrapper';
import Layout from './containers/Layout';
import Home from './routes/home';
import Features from './routes/features';
import Media from './routes/media';
import Buy from './routes/buy';
import News from './routes/news';
import NewsPost from './routes/news/NewsPost';
import ClubsHome from './routes/clubs/ClubsHome';
import CreateClub from './routes/clubs/CreateAClub';
import EditClub from './routes/clubs/EditClub';
import FindClub from './routes/clubs/FindClub';
import Club from './routes/clubs/Club';
import ClubMembers from './routes/clubs/Members';
import NotFound from './routes/error/NotFound';
import SentryErrorBoundary from './containers/SentryErrorBoundary';
import CreateChampionship from './routes/clubs/CreateChampionship';
import EditChampionshipPage from './routes/clubs/EditChampionship';
import ClubResults from './routes/clubs/RecentResults';
import CurrentStandings from './routes/clubs/CurrentStandings';
import PreviousStandings from './routes/clubs/PreviousStandings';
import CreditsPage from './routes/credits/Credits';
import MyClubs from './routes/clubs/MyClubs';
import communityEvents from './routes/community-events';
import AuthorisedRoute from './containers/AuthorisedRoute';
import eSports from './routes/esports';
import FreeTrial from './routes/free-trial/index';
import MsnEsports from './routes/msn-esports';
import Flatout from './routes/flatout';
import Goty from './routes/goty';

export default class App extends Component {
	displayName = App.name

    render() {
        return (
            <Bootstrapper>
                <Layout>                               
                    <SentryErrorBoundary>
                        <Switch>
                            <Route exact path='/' component={Home} />

                            <Route exact path="/game-of-the-year" component={Goty} /> 

                            <Route exact path='/media' component={Media} />
                            <Route exact path='/buy' component={Buy} />
                            <Route exact path='/free-trial' component={FreeTrial} />
                            <Route path='/not-found' component={NotFound} />

                            <Route exact path='/news' component={News} />
                            <Route exact path='/news/:url' component={NewsPost} />

							<AuthorisedRoute exact path='/clubs' component={ClubsHome} />
							<AuthorisedRoute exact path='/clubs/club/:clubId' component={Club} />
                            <AuthorisedRoute exact path='/clubs/create-club' component={CreateClub} />

							<AuthorisedRoute path='/clubs/edit-club/:clubId' component={EditClub} />
							<AuthorisedRoute path='/clubs/club/:clubId/members' component={ClubMembers} />

							<AuthorisedRoute exact path='/clubs/club/:clubId/results/' component={ClubResults} />
							<AuthorisedRoute exact path='/clubs/club/:clubId/results/championship/:championshipId/event/:eventId' component={ClubResults} />

							<AuthorisedRoute exact path='/clubs/club/:clubId/standings/current' component={CurrentStandings} />
							<AuthorisedRoute exact path='/clubs/club/:clubId/standings/previous' component={PreviousStandings} />

							<AuthorisedRoute exact path='/clubs/create-championship/:clubId' component={CreateChampionship} />
							<AuthorisedRoute exact path='/clubs/edit-championship/:clubId' component={EditChampionshipPage} />

							<AuthorisedRoute exact path='/clubs/find-club/' component={FindClub} />
							<AuthorisedRoute exact path='/clubs/find-club/page/:page' component={FindClub} />

                            <Route exact path='/community-events' component={communityEvents} />
                            <Route exact path='/esports' component={eSports} />

							<AuthorisedRoute exact path='/clubs/my-clubs' component={MyClubs} />
							<AuthorisedRoute exact path='/clubs/my-clubs/page/:page' component={MyClubs} />

							<Route exact path='/credits' component={CreditsPage} />

                            <Redirect from="/msn-esports" to="/motorsport-games-esports" />
                            <Route exact path='/motorsport-games-esports' component={MsnEsports} />

                            <Route exact path="/FlatOut" component={Flatout} />

                            <Redirect from='/features' to="/" />
							
                            <Route component={NotFound} />
                        </Switch>
                    </SentryErrorBoundary>
                </Layout>
            </Bootstrapper>
        );
    }
}
