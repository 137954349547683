/**
 * Features
 */
import React from "react";
import { Helmet } from "react-helmet";
import MediaQuery from "react-responsive";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../components/Heading/Heading";
import Img from "../../components/Img/Img";

import eSportsBanner from "../../assets/images/esports/bg_header.jpg";
import eSportsBannerPH from "../../assets/images/esports/bg_header_ph.jpg";
import eSportsIntro from "../../assets/images/esports/intro_image.jpg";
import eSportsIntroPH from "../../assets/images/esports/intro_image_ph.jpg";

import "../../assets/scss/esports.scss";
import EsportSchedule from "../../components/EsportSchedule/EsportSchedule";
import Video from "../../components/Video/Video";
import rallyVideoImage from "../../assets/images/esports/videosPlaceholders/rally.png";
import rallyVideoImagePh from "../../assets/images/esports/videosPlaceholders/rally_ph.png";
import rxVideoImage from "../../assets/images/esports/videosPlaceholders/rx.png";
import rxVideoImagePh from "../../assets/images/esports/videosPlaceholders/rx_ph.png";

const regulationUrl =
  "https://dirtrally2.dirtgame.com/esports/WorldSeries_SportingRegulations";
const termsUrl =
  "https://dirtrally2.dirtgame.com/esports/WorldSeries_Terms&Conditions";

const racenetAccountSignupUrl =
  "https://accounts.codemasters.com/auth/linkstaging?client_id=zxpbgyqx7vmx&redirect_uri=https%3A%2F%2Faccounts.codemasters.com%2Foauthcallback%3Fbind%3D1552044287&skin=Clean&state=uri%3D%252f&reauthenticate=0&grant_type=code&flow=ext&idw=0&auth_hash=1WATo5argcKWaKkZUL7%2B37A78BY%3D";

const dirtRally2BuyUrl = "/buy";

const socialChannelUrl = "https://twitter.com/dirtgame";

const rallyVideoId = "oY6FGdz5bEA";
const rxVideoId = "9pQIIZ-qyTY";

export default function eSports() {
  return (
    <main className="eSports" role="main">
      <Helmet>
        <title>Esports - DiRT Rally 2.0</title>
        <meta
          name="description"
          content="Feel the excitement of the DiRT Rally 2.0 World Series, rise to the Esports challenge and compete to win"
        />
        <link rel="canonical" href="http://www.dirtrally2.com/buy" />
        <meta
          property="og:description"
          content="Feel the excitement of the DiRT Rally 2.0 World Series, rise to the Esports challenge and compete to win"
        />
        <meta
          name="twitter:description"
          content="Feel the excitement of the DiRT Rally 2.0 World Series, rise to the Esports challenge and compete to win"
        />
      </Helmet>

      <section className="eSports__Banner">
        <Img
          image={eSportsBanner}
          placeholder={eSportsBannerPH}
          width="100%"
          height="auto"
          alt=""
        />
      </section>

      <section className="eSports__Intro">
        <Container>
          <Row>
            <Col lg="12">
              <div
                style={{ maxWidth: "50%", float: "right", margin: "0px 15px" }}
              >
                <Img
                  image={eSportsIntro}
                  placeholder={eSportsIntroPH}
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
              <Heading rank="2">
                DiRT Rally 2.0 | World Series IS BACK!!
              </Heading>
              <p>
                In 2019 a field of over 36,000 entrants were whittled down to
                just under 100 who faced off in online playoffs streamed live to
                a global audience.
              </p>

              <p>
                In events filled with dramatic moments, rolling cars, heart
                stopping speed and blistering times, Killian Dall’olmo and Joona
                Pankkonen took victory in Rallycross and Rally finals
                respectively. The amazingly competitive Rally races saw
                Pankkonen win by under a second.
              </p>

              <Heading rank="3">Come on, rise to the challenge.</Heading>
              <p>
                Want a chance of winning a piece of the £20,000 prise pool, or a
                test drive in the QEV electric rallycross car that will power
                the new FIA Junior eRX Championship?
              </p>

              <Heading rank="3">To take part you’ll simply need:</Heading>
              <p>
                A{" "}
                <a href={racenetAccountSignupUrl} target="_blank">
                  RaceNet account
                </a>
                , access to the{" "}
                <a href={dirtRally2BuyUrl} target="_blank">
                  DiRT Rally 2.0
                </a>{" "}
                game and to look out Tuesday 25th August with the first
                qualification rounds going live in-game!
              </p>
              <p>
                We are also delighted to reward players with the in-game Ford
                Fiesta R5 MKII* and hope this is an added incentive for players
                of all levels to get involved.
              </p>

              <p>
                Keep and eye on the{" "}
                <a href={socialChannelUrl} target="_blank">
                  DiRT Social channels
                </a>{" "}
                for more information.
              </p>

              <p>
                Please refer to the{" "}
                <a href={termsUrl} target="_blank">
                  {" "}
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href={regulationUrl} target="_blank">
                  Sporting Regulations
                </a>{" "}
                for more information about the DiRT Rally 2.0 World Series.
              </p>

              <p>
                *Ford Fiesta R5 MKII delivered in patch 1.16 coming October
                2020.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="eSports__Structure">
        <Container>
          <Row>
            <Col xs="12">
              <Heading rank="2" className="text-center">
                The Structure
              </Heading>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="m-b-100">
              <MediaQuery query="(min-width: 992px)">
                <div className="eSports__Schedule--Header">
                  <Row>
                    <Col xs="5" className="text-center">
                      <Heading rank="3">Rally Qualifiers</Heading>
                    </Col>
                    <Col xs="2" />
                    <Col xs="5" className="text-center">
                      <Heading rank="3">RallyCross Qualifiers</Heading>
                    </Col>
                  </Row>
                </div>
              </MediaQuery>
              <div className="eSports__Schedule--Round">
                <Row>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3">Round 1 - Rally Qualifiers</Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      25th August
                      <span>New Zealand - Elsthorpe Sprint Forward - R2</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      1st September
                      <span>
                        New Zealand - Waimarama Sprint Forward - Group A
                      </span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      8th September
                      <span>
                        New Zealand - Ocean Beach Sprint Forward - H2 RWD
                      </span>
                    </div>
                  </Col>
                  <MediaQuery query="(min-width: 992px)">
                    <Col lg="2" className="text-center">
                      <div className="eSports__Schedule--RoundText">
                        Round 1
                      </div>
                    </Col>
                  </MediaQuery>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3" className="m-t-25">
                        Round 1 - RallyCross Qualifiers
                      </Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      25th August
                      <span>Sweden - Holjes - RX Supercars</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      1st September
                      <span>Norway - Hell - RX2</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      8th September
                      <span>France - Loheac-Bretagne - Super 1600</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="eSports__Schedule--Round">
                <Row>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3" className="m-t-25">
                        Round 2 - Rally Qualifiers
                      </Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      29th September
                      <span>Spain - Centenera - R5</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      6th October
                      <span>
                        Spain - Ascenso pore valle del Gualet - Rally GT
                      </span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      13th October
                      <span>Spain - Vinedos Dardenya - H2 FWD</span>
                    </div>
                  </Col>
                  <MediaQuery query="(min-width: 992px)">
                    <Col lg="2" className="text-center">
                      <div className="eSports__Schedule--RoundText">
                        Round 2
                      </div>
                    </Col>
                  </MediaQuery>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3" className="m-t-25">
                        Round 2 - RallyCross Qualifiers
                      </Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      29th September
                      <span>Spain - Circuit de Barcelonia-Catalunya - RX2</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      6th October
                      <span>Portugal - Montalegre - RX Supercars</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      13th October
                      <span>Norway - Hell - Super 1600</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="eSports__Schedule--Round">
                <Row>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3" className="m-t-25">
                        Round 3 - Rally Qualifiers
                      </Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      3rd November
                      <span>Australia - Noorinbee Ridge Ascent - H1 FWD</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      10th November
                      <span>Australia - Taylor Farm Sprint - R5</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      17th November
                      <span>Australia - Yambulla Mountain Ascent - H3 RWD</span>
                    </div>
                  </Col>
                  <MediaQuery query="(min-width: 992px)">
                    <Col lg="2" className="text-center">
                      <div className="eSports__Schedule--RoundText">
                        Round 3
                      </div>
                    </Col>
                  </MediaQuery>
                  <Col lg="5" className="text-center">
                    <MediaQuery query="(max-width: 991px)">
                      <Heading rank="3" className="m-t-25">
                        Round 3 - Rallycross Qualifiers
                      </Heading>
                    </MediaQuery>
                    <div className="eSports__Schedule--Date">
                      3rd November
                      <span>France - Loheac-Bretagne - RX Supercars</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      10th November
                      <span>Sweden - Holjes - RX2</span>
                    </div>
                    <div className="eSports__Schedule--Date">
                      17th November
                      <span>
                        Spain - Circuit de Barcelonia-Catalunya - Super 1600
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="text-center">
              <Heading rank="3" className="m-b-15">
                Rally Knockout Rounds
              </Heading>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--QuartersRally">
                3 Quarter Finals per Platform
              </div>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--SemisRally">
                3 Semi Finals per Platform
              </div>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--FinalRally">
                Grand Final 6 Finalists
              </div>
            </Col>
            <Col xl="6" className="text-center">
              <Heading rank="3" className="m-b-15">
                RallyCross Knockout Rounds
              </Heading>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--QuartersRallyCross">
                3 Quarter Finals per Platform
              </div>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--SemisRallyCross">
                3 Semi Finals per Platform
              </div>
              <div className="eSports__KnockoutBlock eSports__KnockoutBlock--FinalRallyCross">
                Grand Final 6 Finalists
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {rallyVideoId !== "" || rxVideoId !== "" ? (
        <section className="eSports__Media">
          {rallyVideoId === "" ? null : (
            <Video
              video={{
                videoId: rallyVideoId,
                highResThumbnail: {
                  url: rallyVideoImage,
                },
                lowResThumbnail: {
                  url: rallyVideoImagePh,
                },
              }}
            />
          )}
          {rxVideoId === "" ? null : (
            <Video
              video={{
                videoId: rxVideoId,
                highResThumbnail: { url: rxVideoImage },
                lowResThumbnail: { url: rxVideoImagePh },
              }}
            />
          )}
        </section>
      ) : null}

      <section className="eSports__Events">
        <Container>
          <Row>
            <Col xs="12">
              <EsportSchedule />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
