/**
 * Identity Reducers
 */

import * as actionTypes from '../actions/ActionTypes';

/**
 * initial state
 */
const INIT_STATE = {
    isAuthenticated: false,
    displayName: null,
    token: null,
    platforms: []
};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        
        case actionTypes.RECEIVED_CLIENT_STORE_INITIAL_STATE_SUCCESS:
            return {
                ...state,
                ...action.data.identity
            };

        case actionTypes.RECEIVED_IDENTITY_SUCCESS:
            return {
                ...state,
                ...action.data
            };

        case actionTypes.RECIEVED_UPDATE_PLATFORM_SUCCESS:
            return {
                ...state,
                platforms: action.data
            };

        default: return state;
    }
};