import React from 'react';
import Img from '../../components/Img/Img';
import PropTypes from 'prop-types';
import Heading from '../../components/Heading/Heading';
import Icon from '../../components/Icon/Icon';
import { connect } from 'react-redux';
import ChampionshipProgress from '../../components/ChampionshipProgress/ChampionshipProgress';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import Button from '../Button/Button';
import { sendJoinClub } from '../../actions/Clubs/JoinClub';
import { sendAcceptInvite } from '../../actions/Clubs/AcceptInvite';
import { sendDeclineInvite } from '../../actions/Clubs/DeclineInvite';
import DialogPopup from '../DialogPopup/DialogPopup';
import MediaQuery from 'react-responsive';
import { getTranslate } from 'react-localize-redux';
import map from 'lodash/map';

import './ClubTile.scss'

import { bindActionCreators } from 'redux';

library.add(faLockOpen, faLock);

/** 
 * Club Tile
 */
class ClubTile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			joinedClub: false,
			acceptInvite: false,
			declineInvite: false,
			hasAskedToJoin: false,
			validationMessage: ""
		}

		this.resetDialogs = this.resetDialogs.bind(this);
		this.goToClub = this.goToClub.bind(this);
		this.joinClub = this.joinClub.bind(this);
		this.acceptInvite = this.acceptInvite.bind(this);
		this.declineInvite = this.declineInvite.bind(this);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.joinedClub.value !== newProps.joinedClub.value) {
			if(this.props.joinedClub.isReady()) {
				if(this.props.club.id === this.props.joinedClub.value.id) {
					if(this.props.joinedClub.value.resultType === "JoinedSuccessfully") {
						this.setState({
							joinedClub: "joined"
						});
					} else if(this.props.joinedClub.value.resultType === "RequestedSuccessfully") {
						this.setState({
							joinedClub: "pending-accept",
							hasAskedToJoin: true
						});
					}
				}
			} else if(this.props.joinedClub.isFaulted()) {
				if(this.props.club.id === this.props.joinedClub.value.id) {
					const error = this.props.joinedClub.value.response.data;
					if(error.hasValidationErrors) {
						error.validationFieldErrors.forEach(function(item) {
							var errors = map(item.errors, this.props.translate);
							let errorMessage = errors.join(", ");
							this.setState( prevState => {
								return {
									...prevState,
									joinedClub: "error",
									validationMessage: errorMessage
								}
							})
						}, this);
					}
				}
			}
		}

		if(prevProps.acceptInvite !== newProps.acceptInvite) {
			if(this.props.acceptInvite.isReady()) {
				this.goToClub();
			} else if(this.props.acceptInvite.isFaulted()) {
				if(this.props.club.id === this.props.acceptInvite.value.id) {
					const error = this.props.acceptInvite.value.response.data;
					if(error.hasValidationErrors) {
						error.validationFieldErrors.forEach(function(item) {
							var errors = map(item.errors, this.props.translate);
							let errorMessage = errors.join(", ");
							this.setState( prevState => {
								return {
									...prevState,
									acceptInvite: "error",
									validationMessage: errorMessage
								}
							})
						}, this);
					}
				}
			}
		}

		if(prevProps.declineInvite !== newProps.declineInvite) {
			if(this.props.declineInvite.isReady()) {
				window.location.reload();
			} else if(this.props.declineInvite.isFaulted()) {
				this.setState({
					declineInvite: "error"
				});
			}
		}
	}

	goToClub() {
		this.props.push('/clubs/club/' + this.props.club.id);
	}

	joinClub() {
		this.props.sendJoinClub(this.props.club.id);
	}

	acceptInvite() {
		this.props.sendAcceptInvite(this.props.club.id);
	}

	declineInvite() {
		this.props.sendDeclineInvite(this.props.club.id);
	}

	resetDialogs() {
		this.setState({
			joinedClub: false,
			leaveClub: false,
			acceptInvite: false,
			declineInvite: false
		})
	}

	limitDescription(desc, limit) {
		if(desc !== undefined) {
			if(desc.length > limit) {
				desc = desc.substring(0, limit) + "...";
				return desc;
			} else {
				return desc;
			}
		}
	}
	
	render() { 
		const club = this.props.club;
		let bannerImg = require("../../assets/images/covers/" + ('0' + (club.backgroundImageId + 1)).slice(-2) + ".jpg");
		let bannerPlaceholder = require("../../assets/images/covers/" + ('0' + (club.backgroundImageId + 1)).slice(-2) + "_thumb.jpg");

		return (
			<React.Fragment>
				<div className="ClubTile">
					<Img image={bannerImg} placeholder={bannerPlaceholder} width="100%" height="auto" />
					<div className="ClubTile__Overlay">

						{club.clubAccessType === "Open" &&
							<div className="ClubTile__Status"><FontAwesomeIcon icon="lock-open" color="#FFF" /></div>
						}	

						{club.clubAccessType === "Moderated" &&
							<div className="ClubTile__Status"><FontAwesomeIcon icon="lock" color="#FFF" /></div>
						}	

						{club.clubAccessType === "Closed" &&
							<div className="ClubTile__Status"><FontAwesomeIcon icon="lock" color="#FFF" /></div>
						}	

						<div className="ClubTile__Details">
							<Heading rank="3">{club.name}</Heading>

							{!this.props.invite && 
								<div className="ClubTile__Members"><Icon name="icon-members_circle" size="32" /> {club.memberCount} Members</div>
							}

							{this.props.invite && 
								<div className="ClubTile__Members"><Icon name="icon-members_circle" size="32" /> Invited by {this.props.invitee}</div>
							}
						</div>

						{club.hasActiveChampionship &&
							<div className="ClubTile__ChampionshipProgress">
								<ChampionshipProgress championship={club.myChampionshipProgress} />
							</div>
						}

						{!club.hasActiveChampionship &&
							<div className="ClubTile__NoActiveChampionship">
								<p>No Active Championship</p>
							</div>
						}		
					</div>
					<div className="ClubTile__Hover">
						<div className="ClubTile__Hover__Content">
							<Heading rank="3">{club.name}</Heading>

							<MediaQuery query="(min-width: 769px)">
								<p className="ClubTile__Description">{this.limitDescription(club.description, 300)}</p>
							</MediaQuery>
							<MediaQuery query="(max-width: 768px)">
								<p className="ClubTile__Description">{this.limitDescription(club.description, 200)}</p>
							</MediaQuery>

							{!this.props.invite && 
								<div className="ClubTile__Actions">
									{club.isMember === true && club.clubAccessType === "Closed" &&
										<Button type="standard" onClick={this.goToClub}>View Club</Button>
									}

									{club.isMember === true && club.clubAccessType !== "Closed" &&
										<Button type="standard" onClick={this.goToClub}>View Club</Button>
									}

									{club.isMember === false && club.clubAccessType !== "Closed" && !club.hasAskedToJoin && !this.state.hasAskedToJoin &&
										<Button type="standard" onClick={this.joinClub}>Join Club</Button>
									}

									{club.isMember === false && club.hasAskedToJoin &&
										<Button type="standard" className="is-disabled">Request Pending</Button>
									}

									{club.isMember === false && this.state.hasAskedToJoin &&
										<Button type="standard" className="is-disabled">Request Pending</Button>
									}
								</div>
							}

							{this.props.invite && 
								<div className="ClubTile__Actions">
									{club.clubAccessType !== "Closed" && 
										<Button type="standard" onClick={this.goToClub}>View Club</Button>
									}

									{club.isMember === true && club.clubAccessType === "Closed" &&
										<Button type="standard" onClick={this.goToClub}>View Club</Button>
									}
									<Button type="standard" onClick={this.acceptInvite}>Accept Invite</Button>
									<Button type="standard" onClick={this.declineInvite}>Decline Invite</Button>
								</div>
							}

						</div>
					</div>
				</div>

				{this.state.joinedClub === "joined" && 
					<DialogPopup header="Success" description="You have successfully joined the club." confirmText="Ok" onConfirm={this.goToClub} />
				}

				{this.state.joinedClub === "pending-accept" && 
					<DialogPopup header="Request Pending" description="You have successfully applied to join the club, a club administrator will need to accept your request before you become a member." confirmText="Ok" onConfirm={this.resetDialogs} />
				}

				{this.state.joinedClub === "error" && 
					<DialogPopup header="Error" description={this.state.validationMessage} confirmText="Ok" onConfirm={this.resetDialogs} />
				}

				{this.state.acceptInvite === "error" && 
					<DialogPopup header="Error" description={this.state.validationMessage} confirmText="Ok" onConfirm={this.resetDialogs} />
				}

				{this.state.declineInvite === "error" && 
					<DialogPopup header="Error" description="An error occured and you didn't decline the invititation" confirmText="Ok" onConfirm={this.resetDialogs} />
				}		
			</React.Fragment>
		);
	};
}

/**
 * Define expected props.
 */
ClubTile.propTypes = {
    club: PropTypes.object.isRequired,
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
	}),
	joinedClub: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	sendJoinClub: PropTypes.func.isRequired,
	acceptInvite: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	sendAcceptInvite: PropTypes.func.isRequired,
	declineInvite: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	sendDeclineInvite: PropTypes.func.isRequired,
	invite: PropTypes.bool,
	invitee: PropTypes.string
};

//Connect this container component to the redux store.
export default connect(
	state => { 
		return { 
			joinedClub: state.joinClub.result, 
			acceptInvite: state.acceptInvite.result, 
			declineInvite: state.declineInvite.result, 
			application: state.application.value,
			translate: getTranslate(state.localize),
		} 
	},
	dispatch => bindActionCreators({push, sendJoinClub, sendAcceptInvite, sendDeclineInvite}, dispatch)
)(ClubTile);