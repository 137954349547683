import React from 'react';
import PropTypes from 'prop-types';
import tools from  '../WidgeTools';


export default function HtmlWidget(props) {
    return (<React.Fragment>
        {props.html !== "" &&        
        <div dangerouslySetInnerHTML={{__html: tools.decodeHTML(props.html)}} />}
        </React.Fragment>)
}

HtmlWidget.propTypes = {
    html: PropTypes.string
};

HtmlWidget.defaultProps  = {
    html: ""
};

