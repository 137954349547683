import React from 'react';
import { Translate } from "react-localize-redux";
import Img from '../../components/Img/Img';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';

import errorImage from '../../assets/images/error.jpg';
import errorImagePlaceholder from '../../assets/images/error_ph.jpg';

import './ErrorBanner.scss';


class ErrorBanner extends React.Component {

	constructor(props) {
		super(props);

		this.goToHome = this.goToHome.bind(this);
	}

	goToHome() {
        window.location.href = "/";
	}

	render() {
        const header = this.props.heading || "error.404Title";
        const description = this.props.description || "error.404Body";
        
		return (
			<React.Fragment>
				<div className="errorBanner">
					<Img image={errorImage} placeholder={errorImagePlaceholder} width="100%" height="auto" alt="" className="errorBanner__img" />  
                    <div className="errorBanner__content">
                        <Heading rank="1"><Translate id={header} /></Heading>
						<div className="errorBanner__divider"></div>
                        <p><Translate id={description} /></p>
                        <Button type="standard" onClick={this.goToHome}><Translate id="general.homePage">Home Page</Translate></Button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

ErrorBanner.propTypes = {
    description: PropTypes.string,
    heading: PropTypes.string,
    push: PropTypes.func.isRequired  
};

//Connect this container component to the redux store.
export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
)(ErrorBanner);