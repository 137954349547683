import React from 'react';
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import FindClubBannerImg from '../../assets/images/FindClubBanner.jpg';
import FindClubBannerImgMob from '../../assets/images/FindClubBannerMob.jpg';
import FindClubBannerImgPlaceholder from '../../assets/images/FindClubBanner_ph.jpg';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import './CreateClubActionBanner.scss';

/** 
 * Find Club Banner
 */
class CreateClubActionBanner extends React.Component {

	constructor(props) {
		super(props);

		this.createClub = this.createClub.bind(this);
	}

	createClub() {
		this.props.push('/clubs/create-club');
	}
	
	render() { 

		return (
			<div className="FindClubBanner">
				<MediaQuery query="(min-width: 768px)">
					<Img 
						image={FindClubBannerImg} 
						placeholder={FindClubBannerImgPlaceholder} 
						width="100%"
						height="100%"
						className="CreateClubActionBanner__bg"
					 />
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img 
						image={FindClubBannerImgMob} 
						placeholder={FindClubBannerImgPlaceholder} 
						className="CreateClubActionBanner__bg" 
					/>
				</MediaQuery>
				<div className="CreateClubActionBanner__content">
					<Heading rank="3">Create a Club</Heading>
					<p className="CreateClubActionBanner__description">If you haven’t found anything you like then why not create your own club?</p>
					<Button type="standard" size="small" onClick={this.createClub}>Create a Club</Button>
				</div>
			</div>
		);
	};
}

CreateClubActionBanner.propTypes = {
    push: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
 )(CreateClubActionBanner);