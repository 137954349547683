/**
 * Retailer Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    gameRetailers: new StatefulValue(),
    contentRetailers: new StatefulValue(),
    trialRetailers: new StatefulValue()
};

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_GAME_RETAILERS:
            return {
                ...state,
                gameRetailers: new StatefulValue(null, states.Loading)
            };

        case actionTypes.RECEIVED_GAME_RETAILERS_SUCCESS:
            return {
                ...state,
                gameRetailers: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_GAME_RETAILERS_FAILURE:
            return {
                ...state,
                gameRetailers: new StatefulValue(null, states.Faulted)
            };

        case actionTypes.REQUEST_CONTENT_RETAILERS:
            return {
                ...state,
                contentRetailers: new StatefulValue(null, states.Loading)
            };

        case actionTypes.RECEIVED_CONTENT_RETAILERS_SUCCESS:
            return {
                ...state,
                contentRetailers: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_CONTENT_RETAILERS_FAILURE:
            return {
                ...state,
                contentRetailers: new StatefulValue(null, states.Faulted)
            };
        
            case actionTypes.REQUEST_TRIAL_RETAILERS:
            return {
                ...state,
                trialRetailers: new StatefulValue(null, states.Loading)
            };

        case actionTypes.RECEIVED_TRIAL_RETAILERS_SUCCESS:
            return {
                ...state,
                trialRetailers: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_TRIAL_RETAILERS_FAILURE:
            return {
                ...state,
                trialRetailers: new StatefulValue(null, states.Faulted)
            };

        default: return state;
    }
}