import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import { NavLink } from 'react-router-dom';

import './HighlightedNews.scss';

/** 
 * Highlighted News
 */
class HighlightedNews extends React.Component {
	
	render() { 
		const {article} = this.props;
		const formattedDate = moment.utc(article.publicationDate).format('MMMM Do');

		return (
			<NavLink key={article.codename} to={"/News/" + article.url}>
				<div className="HighlightedNews">
					<Img 
						image={(article.featureImage.url == null ? "/images/news_default.jpg" : `${article.featureImage.url}?w=800`)} 
						placeholder={(article.featureImage.url == null ? "/images/news_default.jpg" : article.featureImage.url)} 
						width="100%" height="auto" alt={article.title} className="HighlightedNews__img" /> 
					<div className="HighlightedNews__content">
						<Heading rank="3" className="HighlightedNews__title">{article.title}</Heading>
						<div className="HighlightedNews__meta">Posted on {formattedDate} by {article.author.name} </div>
					</div>
				</div>
			</NavLink>
		);
	};
}

HighlightedNews.propTypes = {
	/** Provide news object to output */
	article: PropTypes.object.isRequired 
}

export default HighlightedNews;