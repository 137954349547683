import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestFriends() {
    return {
        type: actionTypes.REQUEST_FRIENDS
    };
}

export function receivedFriendsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_FRIENDS_SUCCESS,
        data
    };
}

export function receivedFriendsFailure(error) {
    return {
        type: actionTypes.RECEIVED_FRIENDS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Friends
 */
export function fetchFriends(id) {
    return function (dispatch) {
        dispatch(requestFriends());
        axiosFactory.getApiInstance().get('/api/Club/' + id + '/invite/friends')
            .then((resp) => {
                dispatch(receivedFriendsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedFriendsFailure(error));
            });
    };
}