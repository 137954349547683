import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Signpost from '../../components/Signpost/Signpost';
import Loader from '../../components/Loader/Loader';
import * as actions from '../../actions/NewsActions';
import { NavLink } from 'react-router-dom';
import { states } from '../../models/StatefulValue';

class ConnectedLatestNews extends Component {

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchLatestNews();
    }

    render() {
        if (this.props.latestNews.isReady()) {
			const latestNews = this.props.latestNews.value.news.shift();

            if (latestNews) {
                return (
                    <NavLink key={latestNews.url} to={"/News/" + latestNews.url}>
                        <Signpost key={latestNews.id} img={latestNews.featureImage.url} placeholder={latestNews.featureImageUrlSmall} title={latestNews.title} icon="icon-blog_circle" />
                    </NavLink>
                );
            } else {
                /*if for some reason no news is returned...*/
                return (<Loader currentState={states.Faulted} errorMessage="Failed to load latest news" />);
            }            

        } else if (this.props.latestNews.isFaulted()) {
			return (<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />);
        } else /*isIntialising() or isLoading()*/ {
            return (<Loader currentState={this.props.latestNews.state} />);
        }      
    }
}

ConnectedLatestNews.propTypes = {
    maxItemsToRender: PropTypes.number,
    latestNews: PropTypes.shape({
        state: PropTypes.string.isRequired
    }),
    fetchLatestNews: PropTypes.func.isRequired
};


//Connect this container component to the redux store.
export default connect(
    state => { return { latestNews: state.news.latest }; },
    dispatch => bindActionCreators(actions, dispatch)
)(ConnectedLatestNews);
