/**
 * Credits
 */
import React from 'react';
import Credits from '../../components/Credits/Credits';

export default function CreditsPage() {

    return (
        <main role="main">
			<Credits />
        </main>
    );
};