/**
 * Media
 */
import React from 'react';
import { Translate } from "react-localize-redux";
import {Helmet} from "react-helmet";
import YouTubePlaylist from '../../containers/Redux/YouTubePlaylist'; 
import ScreenshotGallery from '../../containers/Redux/ScreenshotGallery'; 

import '../../assets/scss/media.scss';

export default function Media() {
    return (
        <main className="Media" role="main">
            <Translate>
                {({ translate }) => <Helmet>
                    <title>{translate("media.title")} - DiRT Rally 2.0</title>
                    <meta name="description" content="See the latest videos, images and more on DiRT Rally 2.0" />
                    <link rel="canonical" href="http://www.dirtrally2.com/media" />
                    <meta property="og:description" content="See the latest videos, images and more on DiRT Rally 2.0" />
                    <meta name="twitter:description" content="See the latest videos, images and more on DiRT Rally 2.0" />
                </Helmet>}
            </Translate>
            <section className="Media__MediaGallery">
				<div className="m-b-50">
					<YouTubePlaylist />
				</div>
				<div className="m-b-50">
					<ScreenshotGallery />
				</div>
			</section>
        </main>
    );
} 