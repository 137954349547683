import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestMemberRequests() {
    return {
        type: actionTypes.REQUEST_MEMBERREQUESTS
    };
}

export function receivedMemberRequestsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_MEMBERREQUESTS_SUCCESS,
        data
    };
}

export function receivedMemberRequestsFailure(error) {
    return {
        type: actionTypes.RECEIVED_MEMBERREQUESTS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function fetchMemberRequests(id) {
    return function (dispatch) {
        dispatch(requestMemberRequests());
        axiosFactory.getApiInstance().get('/api/Club/' + id + '/requests')
            .then((resp) => {
                dispatch(receivedMemberRequestsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedMemberRequestsFailure(error));
            });
    };
}