import React from 'react';
import ReactDOM from "react-dom";
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DialogPopup.scss';

library.add(faTimes);

/** 
 * DialogPopup
 */
class DialogPopup extends React.Component {

	render() { 
		return (
			<Portal>
				<div className="DialogPopup" tabIndex="-1" role="dialog">								
					<div className="DialogPopup__Modal" role="document"> 

						{this.props.onDismiss !== undefined &&
							<Button type="standard" className="DialogPopup__close" onClick={this.props.onDismiss}>
								<FontAwesomeIcon icon="times" color="#FFF" />
							</Button>
						}

						<Heading rank="3" className="m-b-10">{this.props.header}</Heading>

						{this.props.description && <p>{this.props.description}</p>}

						{this.props.confirmation &&
							<React.Fragment>
								<Button type="standard" size="medium" onClick={this.props.onConfirm}>{this.props.confirmText}</Button>
								<Button type="standard" size="medium" onClick={this.props.onDismiss}>{this.props.dismissText}</Button>
							</React.Fragment>
						}

						{!this.props.confirmation &&
							<Button type="standard" size="medium" onClick={this.props.onConfirm} block>{this.props.confirmText}</Button>
						}
					</div>
				</div>
			</Portal>
		);
	};
}

DialogPopup.defaultProps = {
	confirmation: false,
	onConfirm: event => {
		// eslint-disable-next-line no-console
		console.log('You have confirmed me!', event.target); 
	}
};

DialogPopup.propTypes = {
	header: PropTypes.string.isRequired,
	description: PropTypes.string,
	confirmation: PropTypes.bool.isRequired,
	confirmText: PropTypes.string.isRequired,
	dismissText: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onDismiss: PropTypes.func.isRequired 
};

const container = document.createElement("div");
document.body.appendChild(container);
ReactDOM.render(document.getElementById('app'), container);

export default DialogPopup;