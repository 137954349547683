import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as errorActions from '../actions/ErrorActions';
import * as Sentry from '@sentry/browser';
import ErrorBanner from '../components/ErrorBanner/ErrorBanner';

class SentryErrorBoundary extends Component {

    /**
     * Provides the required error info to sentry ready for reporting.
     * @param {any} error Error
     * @param {any} errorInfo Error Info
     */
    applyErrorToSentry(error, errorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    /**
     * Catch exception / error
     * @param {any} error Error
     * @param {any} errorInfo Error Info
     */
    componentDidCatch(error, errorInfo) {
        this.props.setErrorState(error, errorInfo);
    }

    render() {
        if (this.props.applicationError.error) {
            this.applyErrorToSentry(this.props.applicationError.error, this.props.applicationError.errorInfo);

            return (
                <main role="main">
                    <ErrorBanner heading="error.500Title" description="error.500Body" />
                </main>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}

SentryErrorBoundary.defaultProps = {

};

/**
 * Maps the state items we want to props on this component.
 * @param {any} state state
 * @returns {object} props
 */
const mapStateToProps = state => {
    return {
        applicationError: state.applicationError
    };
};

//Connect this container component to the redux store.
export default connect(
    state => mapStateToProps, //map state to props, what state do you want coming into this component on its props.
    dispatch => bindActionCreators({ ...errorActions }, dispatch) //map dispatch to props, which actions you want to access via props.
)(SentryErrorBoundary);