import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postAcceptInvite() {
    return {
        type: actionTypes.SEND_ACCEPTINVITE
    };
}

export function postAcceptInviteSuccess(data, clubId) {
    return {
        type: actionTypes.RECEIVED_ACCEPTINVITE_SUCCESS,
        data: { ...data, id: clubId }
    };
}

export function postAcceptInviteFailure(error, clubId) {
    return {
        type: actionTypes.RECEIVED_ACCEPTINVITE_FAILURE,
        data: { ...error, id: clubId }
    };
}

/***
 * Accept Invite Function
 */
export function sendAcceptInvite(clubId) {
    return function (dispatch) {
        dispatch(postAcceptInvite());
        axiosFactory.getApiInstance().put('/api/Club/invitation/approve/' + clubId)
        .then((resp) => {
			dispatch(postAcceptInviteSuccess(resp.data, clubId));
		})
		.catch((error) => {
			dispatch(postAcceptInviteFailure(error, clubId));
		});
    };
}