import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestChampionships() {
    return {
        type: actionTypes.REQUEST_CHAMPIONSHIPS
    };
}

export function receivedChampionshipsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPS_SUCCESS,
        data
    };
}

export function receivedChampionshipsFailure(error) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club Championships
 */
export function fetchChampionships(id) {
    return function (dispatch) {
        dispatch(requestChampionships());
        axiosFactory.getApiInstance().get('/api/Club/' + id + '/championships')
            .then((resp) => {
                dispatch(receivedChampionshipsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedChampionshipsFailure(error));
            });
    };
}