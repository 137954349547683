import React from 'react';
import Button from '../../Button/Button';
import PropTypes from 'prop-types';

import './ButtonWidget.scss';

const handleClick = (btn) => {
    const type = btn.type.map((t) => t.codename)[0];

    if(type == 'link'){
        if(btn.url) {
            window.location.href = btn.url;
        }
    } else {
        if(btn.file.length > 0){
            window.location.href = btn.file.map((f) => f.url)[0]
        }
        
    }
}

const setPosition = (position) => {
    switch(position){
        case 'left':
            return 'flex-start';
        case 'centered': 
            return 'center'
        case 'justified':
            return 'space-between'
        case 'right':
            return 'flex-end'
    }
}

const setStyle = (alignment, position) => {
    let style = {
        'flexDirection': alignment === 'inline' ? 'row' : 'column',
        'justifyContent' : setPosition(position)
    };
    
    return style;
}

export default function ButtonWidget(props) {
    
    const { buttons, alignment, position, size } = props;


    return (
        <div style={setStyle(alignment, position)} className="ButtonWidget--wrapper">
            { buttons.length > 0 && buttons.map((btn, index) => {
                return (
                <div key={`buttondiv${index}`} style={ alignment === 'block' ? {'display': 'flex', 'justifyContent': setPosition(position) } : {'display': 'inherit'} }>
                    <Button className="ButtonWidget--btn" key={`button${index}`} type="inverted" size={size} onClick={() => handleClick(btn[0])}>{btn[0].text}</Button> 
                </div>)
            })}
        </div>
    )
}

ButtonWidget.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    alignment: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.oneOf(['small','medium','large'])
};

ButtonWidget.defaultProps  = {
    alignment: 'inline',
    position: 'left',
    size: 'medium'
}