/**
 * Redux Store
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { connectRouter, routerMiddleware } from 'connected-react-router';

export default function configureStore(history, initialState) {

    var composeEnhancers = compose;
    const middlewares = [thunk, routerMiddleware(history)/*for dispatching history actions*/];

    //connect redux dev tools in development mode.
    if (process.env.NODE_ENV === 'development') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    const store = createStore(
        connectRouter(history)(rootReducer),        
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}