import React from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	FacebookIcon,	
	TwitterIcon
} from 'react-share';
import PropTypes from 'prop-types';

import './SocialShare.scss';

/** 
 * Social Share
 */
class SocialShare extends React.Component {
	
	render() { 
		const shareUrl = window.location.href;
		const {quote} = this.props;

		return (
			<div className="SocialShare">
				<FacebookShareButton url={shareUrl} quote={quote}>
					<FacebookIcon size={44} round={true} />
				</FacebookShareButton>
				<TwitterShareButton url={shareUrl} title={quote} hashtag="dirtrally2">
					<TwitterIcon size={44} round={true} />
				</TwitterShareButton>
			</div>
		);
	};
}

SocialShare.propTypes = {
    quote: PropTypes.string
};

export default SocialShare;