import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postChampionship() {
    return {
        type: actionTypes.SEND_CREATECHAMPIONSHIP
    };
}

export function postChampionshipSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CREATECHAMPIONSHIP_SUCCESS,
        data
    };
}

export function postChampionshipFailure(error) {
    return {
        type: actionTypes.RECEIVED_CREATECHAMPIONSHIP_FAILURE,
        data: { error }
    };
}

/***
 * Post Create Championship Form
 */
export function sendCreateChampionshipForm(championshipData, clubId) {
    return function (dispatch, getState) {
        dispatch(postChampionship());

        axiosFactory.getApiInstance().post('/api/Club/' + clubId + '/championship', championshipData)
        .then((resp) => {
			dispatch(postChampionshipSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postChampionshipFailure(error));
		});
    };
}