/**
 * Decline Invite Reducers
 */

import * as actionTypes from '../../actions/ActionTypes';
import { StatefulValue, states } from '../../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    result: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.SEND_DECLINEINVITE:
            return {
                ...state,
                result: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_DECLINEINVITE_SUCCESS:
            return {
                ...state,
                result: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_DECLINEINVITE_FAILURE:
            return {
                ...state,
                result: new StatefulValue(action.data, states.Faulted)
            };
        
        default: return state;
    }
};