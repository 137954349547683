import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss'

/**
 * Basic Button
 */
class Button extends React.Component {
	
	render() { 
		
		const {type, size, className, block, onClick, buttonType} = this.props;
		let classNames = `btn btn-${type} btn-${size} ` + className + (block ? ' btn-block' : '');
 
		return (
			<button type={buttonType} className={classNames} onClick={onClick}>{this.props.children}</button>
		);
	};
}
  
Button.propTypes = {
	/** Specify button type */ 
	type: PropTypes.oneOf(['standard', 'inverted', 'text']),
	/**  Specify button size */
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	/**  Additional class names */
	className: PropTypes.string,
	/**  Block button */
	block: PropTypes.bool,
	/** Gets called when the user clicks on the button */
	onClick: PropTypes.func,
	buttonType: PropTypes.oneOf(['button', 'submit'])
}

Button.defaultProps = {
	type: "standard",
	size: "medium",
	className: "",
	block: false,
	buttonType: 'button'
}
 
export default Button;