import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchClubs } from '../../actions/Clubs/ClubSearch';
import Loader from '../Loader/Loader';
import {Container, Row, Col, Input, FormGroup} from 'reactstrap';
import ClubTile from '../ClubTile/ClubTile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faLockOpen, faComment, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ClubSearch.scss';
import Pagination from '../../components/Pagination/Pagination';
import Heading from '../Heading/Heading';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';

library.add(faSearch, faLockOpen, faComment, faUsers);


/** 
 * Club Search
 */
class ClubSearch extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			searchTerm: "",
			pageNumber: parseInt(this.props.page),
			pageSize: 10
		}

		this.emitChangeDebounced = debounce(this.handleSearch, 500);
		this.setToggle = this.setToggle.bind(this);
		this.updatePage = this.updatePage.bind(this);
	}

	componentDidMount() {
		this.props.searchClubs(this.state);
	}

	componentWillUnmount() {
		this.emitChangeDebounced.cancel()
	}

	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevState.searchTerm !== newState.searchTerm && prevState.pageNumber === newState.pageNumber) {
			this.props.searchClubs(this.state);
		}

		if(prevProps.page !== newProps.page) {
			this.setState({
				pageNumber: parseInt(this.props.page)
			}, () => {
				this.props.searchClubs(this.state);
			});
		}
	}

	handleChange = e => {   
		e.persist()
		this.emitChangeDebounced(e.target.value);
	}

	handleSearch = value => {
		this.setState( prevState => {
			return { 
				...prevState,
				searchTerm: value,
				pageNumber: 1
			}
		}, () => {
			this.props.push('/clubs/find-club/page/1/');
		});
	}

	updatePage(page) {
		this.props.push('/clubs/find-club/page/' + page);
	}
	
	setToggle(e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState(prevState => {
			return {
				...prevState,
				[name]: value
			}		
		});
	}

	renderResults() { 
		if (this.props.clubs.isReady()) {
			const clubs = this.props.clubs.value.clubs;
			return (
				<Container>
					<Row>
						<React.Fragment>
							{clubs.length > 0 &&
								<React.Fragment>
									{clubs.map((club, index) => 
										<Col xs="12" md="6" key={index}><ClubTile club={club} /></Col>
									)}
								</React.Fragment>
							}
						</React.Fragment>
						<React.Fragment>
							{!clubs.length &&
								<React.Fragment>
									<Col xs="12">
										<div className="ClubSearch__NoResults">
											<Heading rank="3" className="m-b-10">No Clubs Found</Heading>
											<p>Please adjust your search terms.</p>
										</div>
									</Col>
								</React.Fragment>
							}
						</React.Fragment>
					</Row>
					{this.props.clubs.value.pageCount > 1 &&
						<Row>
							<Col xs="12">
								<div className="ClubSearch__Pagination">
									<Pagination activePage={this.state.pageNumber} pageSize={this.props.clubs.value.pageCount} onChange={this.updatePage} />
								</div>
							</Col>
						</Row>
					}
				</Container>
			);
		} else if (this.props.clubs.isFaulted()) {
			return (<Loader currentState={this.props.clubs.state} errorMessage="Failed to load clubs" />);
		} else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubs.state} />);
		}    
	};

	render() { 
		return (
			<div className="ClubSearch">
				<div className="ClubSearch__Search">
					<Container>
						<Row> 
							<Col xs="12" xl={{ size: 8, offset: 2 }}>
								<FormGroup>
									<div style={{position: 'relative'}}>
										<Input
											type="search"
											name="clubSearch"
											id="clubSearch"
											placeholder="Enter club name..."
											onChange={this.handleChange}
										/>
										<FontAwesomeIcon icon="search" color="#FFF" />
									</div>
								</FormGroup>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="ClubSearch__Results">
					{this.renderResults()}
				</div>
			</div>
		);
	};
}


/**
 * Define expected props.
 */
ClubSearch.propTypes = {
	clubs: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	searchClubs: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { clubs: state.clubSearch.clubs  } }, 
    dispatch => bindActionCreators({ searchClubs, push }, dispatch)
)(ClubSearch); 