import React from 'react';
import PropTypes from 'prop-types';
import { states } from '../../models/StatefulValue';
import { Row, Col, ButtonGroup, Form, FormGroup, Label, Input } from 'reactstrap';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';

import '../../assets/scss/forms.scss';
import './BuyNowForm.scss';

/**
 * BuyNowForm - Form for providing links to content to buy.
 */
class BuyNowForm extends React.Component {

    constructor(props) {
        super(props);
                
        this.state = {
            processedState: 'unprocessed',
            retailers: [],
            selectedCountry: 0,
            selectedPlatform: 0,
            selectedContent: 0
        };

        this.selectCountry = this.selectCountry.bind(this);
        this.selectPlatform = this.selectPlatform.bind(this);
        this.selectContent = this.selectContent.bind(this);
        this.goToRetailer = this.goToRetailer.bind(this);
    }

    /**
     *  Process the raw data into a easier to use format when its ready.
     */
    componentDidUpdate() {
        if (this.props.state === states.Ready && this.state.processedState === 'unprocessed') {
            var r = this.buildContentLookup(this.props.content);
            this.setState({
                retailers: r,
                processedState: "processed"
            });
        }
    }

    /**
     * The data on the CDN is in a format that makes it easier to maintain, however that is not ideal to work with here, so
     * we transform it.
     * @param {any} rawContent
     */
    buildContentLookup(rawContent) {
        if (!rawContent) {
            return [];
        }

        var self = this;
        var result = [];
        var orderIndexNotProvidedValue = 99999; //orderIndex allows given content to be moved out of the normal alphabetical order. Currently used to bring deluxe content to the top. 

        rawContent.platforms.forEach(function (platform) {
            platform.links.forEach(function (link) {
                link.supportedCountries.forEach(function (supportedCountry) {
                    var countryInstance = self.getOrCreateCountry(result, supportedCountry);
                    var platformInstance = self.getOrCreatePlatform(countryInstance.platforms, platform.name);
                    self.getOrCreateContent(platformInstance.contents, link.content, link.uri, link.orderIndex || orderIndexNotProvidedValue);
                });
            });
        });

        //apply required sort order.
        result = orderBy(result, ['name'], ['asc']);
        result.forEach(function (country) {
            country.platforms.forEach(function (platform) {
                platform.contents = orderBy(platform.contents, ['orderIndex', 'name'], ['asc', 'asc']);
            });
        });

        return result;
    }

    /**
     * Gets the existing country or creates one. 
     * @param {any} data
     * @param {any} country
     */
    getOrCreateCountry(data, country) {
        var result = find(data, ['name', country]);
        if (!result) {
            result = { name: country, platforms: [] };
            data.push(result);
        }
        return result;
    }

    /**
     * Gets the existing platform or creates one. 
     * @param {any} data
     * @param {any} platform
     */
    getOrCreatePlatform(data, platform) {
        var result = find(data, ['name', platform]);
        if (!result) {
            result = { name: platform, contents: [] };
            data.push(result);
        }
        return result;
    }

    /**
     * Gets the existing content (edition or dlc) or creates one.
     * @param {any} data
     * @param {any} content
     * @param {any} uri
     */
    getOrCreateContent(data, content, uri, orderIndex) {
        var result = find(data, ['name', content]);
        if (!result) {
            result = { name: content, uri: uri, orderIndex: orderIndex };
            data.push(result);
        }
        return result;
    }

    /**
     * Renders the title if provided. 
     * @param {any} title
     */
    renderTitle(title) {
        if (title) {
            return (
                <Row >
                    <Col xs="12"><Heading rank="3" divider>{title}</Heading></Col>
                </Row>
            );
        } else {
            return null;
        }
    }

    /**
     * Renders the description if provided.
     * @param {any} description
     */
    renderDescription(description) {
        if (description) {
            return (
                <p>{description}</p>
            );
        } else {
            return null;
        }
    }

    /**
     * Applies the selected country to the components state.
     * @param {any} e
     */
    selectCountry(e) {
        this.setState({
            selectedCountry: e.target.value,
            selectedPlatform: 0,
            selectedContent: 0
        });
    }

    /**
     * Applies the selected platform to the components state.
     * @param {any} platformIndex
     */
    selectPlatform(platformIndex) {
        this.setState({
            selectedPlatform: platformIndex,
            selectedContent: 0
        });
    }

    /**
     * Applies the selected content (edition / dlc) to the components state.
     * @param {any} e
     */
    selectContent(e) {
        this.setState({
            selectedContent: e.target.value
        });
    }

    /**
     * Browses to the selected content with the selected retailer. 
     */
    goToRetailer() {
        var country = this.state.retailers[this.state.selectedCountry];

        if (country) {
            var platform = country.platforms[this.state.selectedPlatform];

            if (platform) {
                var content = platform.contents[this.state.selectedContent];

                if (content) {

                    // ReactGA.event({
                    //     category: 'Buy Now Page',
                    //     action: 'Buy Now Button Clicked',
                    //     label: country.name + ' -> ' + platform.name + ' -> ' + content.name
                    // });

                    var data = {
                        country: country.name,
                        platform: platform.name,
                        content: content.name,
                        uri: content.uri
                    };
                        
                    // ReactPixel.trackCustom('Buy Now Button Clicked', data); 

                    var win = window.open(content.uri, '_blank');
                    win.focus();
                }
            }
        }
    }

    /**
     * Renders the platform selection buttons. 
     * @param {any} platforms
     */
    renderPlatforms(platforms) {
        return platforms.map((p, i) =>
            (<Button key={i} size = "small" className = {(this.state.selectedPlatform === i ? 'is-active' : '')} onClick = {(e) => this.selectPlatform(i)}>
                { p.name }
			</Button >)
        );
    }

    /**
     * Renders the entire form.
     * @param {any} state
     */
    renderForm(state) {
        if (state === states.Ready) {
            if (this.state.processedState === "unprocessed") {
                return <Loader currentState={states.Loading} />;
            }

            var platforms = this.state.retailers[this.state.selectedCountry].platforms;
            var contents = this.state.retailers[this.state.selectedCountry].platforms[this.state.selectedPlatform].contents;

            return (
                <React.Fragment>
                    {this.renderTitle(this.props.content.title)}
                    <Row>
                        <Col xs="12">
                            <FormGroup>
                                <Label for="selectCountry" className="sr-only">Select Country</Label>
                                <Input type="select" name="selectCountry" id="selectCountry" onChange={this.selectCountry} value={this.state.selectedCountry}>
                                    <option value="" disabled>Select Country</option>
                                    { this.state.retailers.map((c, i) => <option key={i} value={i}>{c.name}</option>) }
                                </Input>
                            </FormGroup>

                            <ButtonGroup>
                                {this.renderPlatforms(platforms)}
                            </ButtonGroup>

                            <FormGroup>
                                <Label for="selectContent" className="sr-only">Select Content</Label>
                                <Input type="select" name="selectContent" id="selectContent" onChange={this.selectContent} value={this.state.selectedContent}>
                                    <option value="" disabled>Select Content</option>
                                    {contents.map((c, i) => <option key={i} value={i}>{c.name}</option>)}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col lg="2" />
                                <Col lg="8"><Button type="standard" size="medium" onClick={this.goToRetailer} block>Buy Now</Button></Col>
                                <Col lg="2" />
                            </Row>
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: this.props.content.description }}></div>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return <Loader currentState={state} errorMessage="Failed to load." />;
        }    
    }

    /**
     * Renders the component. 
     */
    render() { 
        const align = this.props.align || "center";

        return (
            <Form className={'BuyNowForm ' + align}>
                { this.renderForm(this.props.state, this.props.content) }
            </Form>
        );
	}
}

BuyNowForm.propTypes = {
    align: PropTypes.oneOf(['left', 'center', 'right']),
    content: PropTypes.shape({
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        platforms: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                links: PropTypes.arrayOf(
                    PropTypes.shape({
                        content: PropTypes.string.isRequired,
                        uri: PropTypes.string.isRequired,
                        supportedCountries: PropTypes.arrayOf(PropTypes.string).isRequired
                    })
                ).isRequired
            })
        ).isRequired
    }),
    state: PropTypes.string.isRequired
};

export default BuyNowForm;