import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestRecommendedClubs() {
    return {
        type: actionTypes.REQUEST_RECOMMENDEDCLUBS
    };
}

export function receivedRecommendedClubsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_RECOMMENDEDCLUBS_SUCCESS,
        data
    };
}

export function receivedRecommendedClubsFailure(error) {
    return {
        type: actionTypes.RECEIVED_RECOMMENDEDCLUBS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Recommended Clubs
 */
export function fetchRecommendedClubs() {
    return function (dispatch, getState) {
        dispatch(requestRecommendedClubs());

        axiosFactory.getApiInstance().get('/api/Club/Recommended')
            .then((resp) => {
                dispatch(receivedRecommendedClubsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedRecommendedClubsFailure(error));
            });
    };
}