import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { states } from '../../models/StatefulValue';
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import TrialLogo from '../TrialLogo/TrialLogo';
import Button from '../Button/Button';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TrialBannerXl from '../../assets/images/header_freetrial.jpg';
import TrialBannerMedium from '../../assets/images/header_freetrial_1200.jpg';
import TrialBannerSmall from '../../assets/images/header_freetrial_mob.jpg';
import keyartPlaceholder from '../../assets/images/keyart_placeholder.jpg'; 

import './TrialBanner.scss';

library.add(faAngleDown);

export default class TrialBanner extends Component {
	
	constructor(props){
		super(props);
		this.state = {
            processedState: 'unprocessed',
			playStationUri: '',
			xboxUri: '',
			browserCulture: 'en-GB'
        };
	}

	componentDidUpdate() {
		if (this.props.state === states.Ready && this.state.processedState === 'unprocessed') {
			this.setState({
				playStationUri: this.getPlatformUri(this.props.content, 'PlayStation 4'),
				xboxUri: this.getPlatformUri(this.props.content, 'Xbox One'),
				browserCulture: window.navigator.language,
				processedState: "processed"
			})
		}
	}

	getPlatformUri(rawContent, platformName){
		if(!rawContent){
			return;
		}
		
		var platform = rawContent.platforms.find((platform) => {
			return platform.name.trim().toLowerCase() === platformName.trim().toLowerCase();
		});

		if(platform) {
			return platform.link;
		}

		return '';
	}

		
	downloadPs4 = () => {
		var uri = this.state.playStationUri;
		if(uri){
		window.open(uri, '_blank');
		window.focus();
		}
    }

    downloadXbox = () => {
		var uri = this.state.xboxUri;
		if(uri){
			window.open(this.state.xboxUri, '_blank');
			window.focus();
		}
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="TrialBanner">
                    <MediaQuery query="(min-width: 1200px)">
                        <Img image={TrialBannerXl} placeholder={keyartPlaceholder} className="TrialBanner__bg" />
                    </MediaQuery>
					<MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
						<Img image={TrialBannerMedium} placeholder={keyartPlaceholder} className="TrialBanner__bg--mobile" />
					</MediaQuery>
					<MediaQuery query="(max-width: 767px)">
						<Img image={TrialBannerSmall} placeholder={keyartPlaceholder} className="TrialBanner__bg--mobile" />
					</MediaQuery>
                    <div className="TrialBanner__container">
						<div className="TrialBanner__overlay">
							<TrialLogo />
							<div className="TrialBanner__actions">
                                <div className="TrialBanner__btn">
                                    <Button type="standard" onClick={this.downloadPs4} block><Translate id="trial.downloadPs4">Download on PlayStation 4</Translate></Button>
								</div>
								<div className="TrialBanner__btn">
									<Button type="standard" onClick={this.downloadXbox} block><Translate id="trial.downloadXbox">Download on Xbox One</Translate></Button>
								</div>
							</div>
						</div>
						<div className="TrialBanner__scrollmore">
							<FontAwesomeIcon icon="angle-down" color="#ff003c" />
						</div>
					</div>
				</div>
            </React.Fragment>
        )
    }
}
