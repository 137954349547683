import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postDeclineInvite() {
    return {
        type: actionTypes.SEND_DECLINEINVITE
    };
}

export function postDeclineInviteSuccess(data, clubId) {
    return {
        type: actionTypes.RECEIVED_DECLINEINVITE_SUCCESS,
        data: { ...data, id: clubId }
    };
}

export function postDeclineInviteFailure(error, clubId) {
    return {
        type: actionTypes.RECEIVED_DECLINEINVITE_FAILURE,
        data: { ...error, id: clubId }
    };
}

/***
 * Decline Invite Function
 */
export function sendDeclineInvite(clubId) {
    return function (dispatch) {
        dispatch(postDeclineInvite());
        axiosFactory.getApiInstance().put('/api/Club/invitation/decline/' + clubId)
        .then((resp) => {
			dispatch(postDeclineInviteSuccess(resp.data, clubId));
		})
		.catch((error) => {
			dispatch(postDeclineInviteFailure(error, clubId));
		});
    };
}