import React from 'react';
import { Translate } from "react-localize-redux";
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import advertImage from '../../assets/images/location_advert.jpg';
import advertImageTablet from '../../assets/images/features_wide_1200.jpg';
import advertImageMobile from '../../assets/images/location_advert_mobile.jpg';
import advertPlaceholder from '../../assets/images/location_advert_placeholder.jpg';

import './LocationAdvert.scss';

class LocationAdvert extends React.Component {
	render() {
		return (
			<div className="LocationAdvert">
				<MediaQuery query="(min-width: 1200px)">
					<Img image={advertImage} placeholder={advertPlaceholder} className="LocationAdvert__img--desktop" /> 
				</MediaQuery>
				<MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
					<Img image={advertImageTablet} placeholder={advertPlaceholder} className="LocationAdvert__img--desktop" /> 
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img image={advertImageMobile} placeholder={advertPlaceholder} className="LocationAdvert__img--mobile" />  
				</MediaQuery>
				<div className="LocationAdvert__content">
                    <Heading rank="2"><Translate id="locationAdvert.title">Rally Around the Globe</Translate></Heading>
                    <p><Translate id="locationAdvert.body">Take the wheel through the stunning environments of New Zealand, Argentina, Spain, Poland, Australia and the USA.</Translate></p>
				</div>		
			</div>
		);
	}
}
 
export default LocationAdvert;