import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postEditClubSettings() {
    return {
        type: actionTypes.SEND_EDITCLUBSETTINGS
    };
}

export function postEditClubSettingsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_EDITCLUBSETTINGS_SUCCESS,
        data
    };
}

export function postEditClubSettingsFailure(error) {
    return {
        type: actionTypes.RECEIVED_EDITCLUBSETTINGS_FAILURE,
        data: { error }
    };
}

/***
 * Post the Edit Club Settings form
 */
export function sendEditSettingsForm(id, clubData) {
    return function (dispatch, getState) {
        dispatch(postEditClubSettings());

        axiosFactory.getApiInstance().put('/api/Club/' + id + '/settings', clubData)
        .then((resp) => {
			dispatch(postEditClubSettingsSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postEditClubSettingsFailure(error));
		});
    };
}