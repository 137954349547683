import React from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';
import { states, statesArray } from '../../models/StatefulValue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Loader.scss';

library.add(faCircleNotch, faExclamationTriangle);

/**
 * Component Loading Placeholder
 */
class Loader extends React.Component {

	render() { 
		const {currentState} = this.props;

		if(currentState === states.Initialising || currentState === states.Loading) {
			return (
				<div className="loader">
					<FontAwesomeIcon icon="circle-notch" color="#FFF" className="fa-spin fa-2x" />
				</div>
			);
		} else if(currentState === states.Faulted) {
			return (
				<div className="loader">
					<FontAwesomeIcon icon="exclamation-triangle" color="#FFF" className="fa-2x" />
                    <p><Translate id="general.failedToLoadContent">Failed to load content.</Translate></p>
				</div>
			);
		}
	};
}
 
Loader.propTypes = {
    /** Specify loading style to output */
    currentState: PropTypes.oneOf(statesArray)
};

Loader.defaultProps = {
    currentState: "loading"
};
 
export default Loader;