import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";


const AuthorisedRoute = ({ component: Component, identity, application, ...rest }) => {
    if (identity.isAuthenticated) {
        return (<Route {...rest} render={props => <Component {...props} />} />);
    } else {
        var returnUrl = window.location.pathname;
        window.location.href = '/account/login?returnUri=' + encodeURIComponent(returnUrl);
        return null;
    }
};


/**
 * Define expected props.
 */
AuthorisedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    identity: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => {
        return {
            identity: state.identity,
            application: state.application
        };
    },
)(AuthorisedRoute);