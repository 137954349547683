import React from 'react';
import {Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendCancelChampionship } from '../../actions/Clubs/CancelChampionship';
import { sendCancelFutureChampionship } from '../../actions/Clubs/CancelFutureChampionship';
import { deleteClub } from '../../actions/Clubs/DisbandClub';
import DialogPopup from '../DialogPopup/DialogPopup';
import { push } from 'connected-react-router';

import './ClubMenu.scss';

library.add(faCog);

/** 
 * Club Menu
 */
class ClubMenu extends React.Component {

	constructor(props) {
		super(props);
	
		this.toggleMenu = this.toggleMenu.bind(this);
		this.disbandClub = this.disbandClub.bind(this);
		this.goToClubsHome = this.goToClubsHome.bind(this);
		this.goToEditClub = this.goToEditClub.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
		this.cancelActiveChampionship = this.cancelActiveChampionship.bind(this);
		this.cancelFutureChampionship = this.cancelFutureChampionship.bind(this);
		this.confirmAction = this.confirmAction.bind(this);
		this.reloadPage = this.reloadPage.bind(this);

		this.state = {
			isOpen: false,
			clubDisband: false,
			championshipCancelled: false,
			futureChampionshipCancelled: false
		};
	}
	
	toggleMenu() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.club !== newProps.club) {
			if(this.props.club.isReady()) {
				this.setState({
					clubDisband: "disbanded"
				});
			} else if(this.props.club.isFaulted()) {
				this.setState({
					clubDisband: "error"
				});
			}
		}

		if(prevProps.cancelChampionship !== newProps.cancelChampionship) {
			if(this.props.cancelChampionship.isReady()) {
				this.setState({
					championshipCancelled: "cancelled"
				});
			} else if(this.props.cancelChampionship.isFaulted()) {
				this.setState({
					championshipCancelled: "error"
				});
			}
		}

		if(prevProps.cancelFutureChampionship !== newProps.cancelFutureChampionship) {
			if(this.props.cancelFutureChampionship.isReady()) {
				this.setState({
					futureChampionshipCancelled: "cancelled"
				});
			} else if(this.props.cancelFutureChampionship.isFaulted()) {
				this.setState({
					futureChampionshipCancelled: "error"
				});
			}
		}
	}

	resetDialogs() {
		this.setState({
			clubDisband: false,
			championshipCancelled: false,
			futureChampionshipCancelled: false
		})
	}

	goToClubsHome() {
		this.props.push('/clubs');
	}

	reloadPage() {
		window.location.reload();
	}

	goToEditClub() {
		this.props.push('/clubs/edit-club/' + this.props.clubInfo.club.id);
	}

	disbandClub() {
		this.props.deleteClub(this.props.clubInfo.club.id);
	}

	cancelActiveChampionship() {
		this.props.sendCancelChampionship(this.props.clubInfo.club.id);
	}

	cancelFutureChampionship() {
		this.props.sendCancelFutureChampionship(this.props.clubInfo.club.id);
	}

	confirmAction(action) {
		this.setState({
			[action]: "requestConfirmation"
		});
	}
	
	render() { 
		const permissions = this.props.permissions;
		const clubInfo = this.props.clubInfo.club;

		if(permissions.canEditClubSettings || permissions.canCancelChampionship || permissions.canDisbandClub) {
			return (
				<React.Fragment>
						<Dropdown className="ClubMenu" isOpen={this.state.isOpen} toggle={this.toggleMenu}>
							<DropdownToggle><FontAwesomeIcon icon="cog" color="#FFF" /></DropdownToggle>
							<DropdownMenu right={true}>
								{permissions.canEditClubSettings && <DropdownItem onClick={this.goToEditClub}>Edit Club Details</DropdownItem>}
								{permissions.canCancelChampionship && clubInfo.hasActiveChampionship && <DropdownItem onClick={() => this.confirmAction("championshipCancelled")}>Cancel Active Championship</DropdownItem>}
								{permissions.canCancelChampionship && clubInfo.hasFutureChampionship && <DropdownItem onClick={() => this.confirmAction("futureChampionshipCancelled")}>Cancel Upcoming Championship</DropdownItem>}
								{permissions.canDisbandClub && <DropdownItem onClick={() => this.confirmAction("clubDisband")}>Disband Club</DropdownItem>}
							</DropdownMenu>
						</Dropdown>

						{this.state.clubDisband === "requestConfirmation" && 
							<DialogPopup header="Disband Club" description="Are you sure you want to disband this club?" confirmation={true} confirmText="Confirm" dismissText="Cancel" onConfirm={this.disbandClub} onDismiss={this.resetDialogs} />
						}

						{this.state.clubDisband === "disbanded" && 
							<DialogPopup header="Club Disbanded" description="You have successfully disbanded your club." confirmText="Ok" onConfirm={this.goToClubsHome} />
						}

						{this.state.clubDisband === "error" && 
							<DialogPopup header="Error" description="An error occured and your club wasn't deleted." confirmText="Ok" onConfirm={this.resetDisband} />
						}

						{this.state.championshipCancelled === "requestConfirmation" && 
							<DialogPopup header="Cancel Active Championship" description="Are you sure you want to cancel the currently active championship?" confirmation={true} confirmText="Confirm" dismissText="Cancel" onConfirm={this.cancelActiveChampionship} onDismiss={this.resetDialogs} />
						}

						{this.state.futureChampionshipCancelled === "requestConfirmation" && 
							<DialogPopup header="Cancel Upcoming Championship" description="Are you sure you want to cancel the upcoming championship?" confirmation={true} confirmText="Confirm" dismissText="Cancel" onConfirm={this.cancelFutureChampionship} onDismiss={this.resetDialogs} />
						}


						{this.state.championshipCancelled === "cancelled" && 
							<DialogPopup header="Active Championship Cancelled" description="You have successfully cancelled your clubs active championship." confirmText="Ok" onConfirm={this.reloadPage} />
						}

						{this.state.championshipCancelled === "error" && 
							<DialogPopup header="Error" description="An error occured and your championship wasn't cancelled." confirmText="Ok" onConfirm={this.resetDialogs} />
						}

						{this.state.futureChampionshipCancelled === "cancelled" && 
							<DialogPopup header="Upcoming Championship Cancelled" description="You have successfully cancelled your clubs upcoming championship." confirmText="Ok" onConfirm={this.reloadPage} />
						}

						{this.state.futureChampionshipCancelled === "error" && 
							<DialogPopup header="Error" description="An error occured and your championship wasn't cancelled." confirmText="Ok" onConfirm={this.resetDialogs} />
						}
				</React.Fragment>
			);
		} else {
			return null;
		}
	};
}

/*
* Define expected props.
*/
ClubMenu.propTypes = {
	permissions: PropTypes.object.isRequired,
	clubInfo: PropTypes.object.isRequired,
	club: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	cancelChampionship: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	cancelFutureChampionship: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	deleteClub: PropTypes.func.isRequired,
	sendCancelChampionship: PropTypes.func.isRequired,
	sendCancelFutureChampionship: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { club: state.disbandClub.club, cancelChampionship: state.cancelChampionship.result, cancelFutureChampionship: state.cancelFutureChampionship.result  } }, 
    dispatch => bindActionCreators({ push, deleteClub, sendCancelChampionship, sendCancelFutureChampionship }, dispatch)
)(ClubMenu); 