import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function requestYouTubePlaylist(name) {
    return {
        type: actionTypes.REQUEST_YOUTUBE_PLAYLIST,
        data: { name }
    };
}

export function receivedYouTubePlaylistSuccess(name, items) {
    return {
        type: actionTypes.RECEIVED_YOUTUBE_PLAYLIST_SUCCESS,
        data: { name, items }
    };
}

export function receivedYouTubePlaylistFailure(name, error) {
    return {
        type: actionTypes.RECEIVED_YOUTUBE_PLAYLIST_FAILURE,
        data: { name, error }
    };
}

/***
 * Fetch YouTube playlist.
 */
export function fetchYouTubePlaylist(playlistName /*optional, default value: 'default'*/) {
    return function (dispatch, getState) {
        playlistName = playlistName || 'default';
        dispatch(requestYouTubePlaylist(playlistName));
        axiosFactory.getApiInstance().get('/api/youtube/playlist/' + playlistName)
            .then((resp) => { dispatch(receivedYouTubePlaylistSuccess(playlistName, resp.data)); })
            .catch((error) => { dispatch(receivedYouTubePlaylistFailure(playlistName, error)); });
    };
}