import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import NewsListings from '../../containers/Redux/NewsListings';

import '../../assets/scss/news.scss';

export default class News extends Component {
    displayName = News.name

    /**
     * Render the page / component.
     */
    render() {
        
        return (
            <div className="News">
				<Helmet>
                    <title>Latest News - DiRT Rally 2.0</title>
                    <meta name="description" content="Whats happening in the world of DiRT Rally 2.0? See the latest news and read the latest Blogs right here" />
                    <link rel="canonical" href="http://www.dirtrally2.com/news" />
                    <meta property="og:description" content="Whats happening in the world of DiRT Rally 2.0? See the latest news and read the latest Blogs right here" />
                    <meta name="twitter:description" content="Whats happening in the world of DiRT Rally 2.0? See the latest news and read the latest Blogs right here" />
				</Helmet>
                <NewsListings />
            </div>
        );
    }
}
