/**
 * View Club Results
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClub } from '../../actions/Clubs/GetClub';
import { Container, Row, Col } from 'reactstrap';
import ClubOverview from '../../components/ClubOverview/ClubOverview';
import Loader from '../../components/Loader/Loader';
import ClubResults from '../../components/ClubResults/ClubResults';
import NoGameDataCheck from '../../containers/NoGameDataCheck';

import '../../assets/scss/clubs.scss';

class RecentResults extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            clubId: parseInt(props.match.params.clubId),
            championshipId: (props.match.params.championshipId ? parseInt(props.match.params.championshipId) : 0),
            eventId: (props.match.params.eventId ? parseInt(props.match.params.eventId) : 0)
        };
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchClub(this.state.clubId);
	}

	render() {
		const clubInfo = this.props.club.value;

		if (this.props.club.isReady()) {
            return (
                <NoGameDataCheck>
				    <main className="ClubResults" role="main">
					    <Row>
						    <Col xs="12">
							    <ClubOverview club={clubInfo} fullView={false} />
						    </Col>
					    </Row>
					    <Container>
							<ClubResults championshipId={this.state.championshipId} clubId={this.state.clubId} eventId={this.state.eventId} />
					    </Container>
                    </main>
                </NoGameDataCheck>
			);
		} else if (this.props.club.isFaulted()) {
            return (<NoGameDataCheck><Loader currentState={this.props.club.state} errorMessage="Failed to load club" /></NoGameDataCheck>);
		} else /*isIntialising() or isLoading()*/ {
            return (<NoGameDataCheck><Loader currentState={this.props.club.state} /></NoGameDataCheck>);
		}    
	};
}

/**
 * Define expected props.
 */
RecentResults.propTypes = {
	club: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchClub: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { club: state.getClub.club  } }, 
    dispatch => bindActionCreators({ fetchClub }, dispatch)
)(RecentResults);