import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/NewsActions';
import Loader from '../../components/Loader/Loader';
import NewsListing from '../../components/NewsListing/NewsListing';
import { NavLink } from 'react-router-dom';
import take from 'lodash/take';

class ConnectedRecentNews extends Component {
	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchLatestNews();
	}
	
	getHighlightedNews() {
		return this.props.latestNews.value.news.shift();
	}

    render() {
        if (this.props.latestNews.isReady()) {
			const news = take(this.props.latestNews.value.news, 5)
            return (
				<div>
					<Row>
						{news.map((item, index) =>
							(this.props.currentNewsId !== item.url ?
								<Col key={`col-${index}`} xs="12">
									<NavLink key={`$nav-{index}`} to={"/News/" + item.url}>
										<NewsListing 
											key={item.codename} 
											image={(item.featureImage == null ? "/images/news_default.jpg" : item.featureImage.url)} 
											placeholder={(item.featureImage == null ? "/images/news_default.jpg" : item.featureImage.url)} 
											title={item.title} 
										/>
									</NavLink>
								</Col>
							: '')
						)}
					</Row>
				</div>
			);
		} else if (this.props.latestNews.isFaulted()) {
			return (<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />);
        } else /*isIntialising() or isLoading()*/ {
            return (<Loader currentState={this.props.latestNews.state} />);
        }           
    }
}

ConnectedRecentNews.propTypes = {
    latestNews: PropTypes.shape({
        state: PropTypes.string.isRequired
    }),
	fetchLatestNews: PropTypes.func.isRequired,
	currentNewsId: PropTypes.string
};

//Connect this container component to the redux store.
export default connect(
    state => { return { latestNews: state.news.latest } },
    dispatch => bindActionCreators(actions, dispatch)
)(ConnectedRecentNews);
