import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function getGeneratedStages() {
    return {
        type: actionTypes.SEND_GENERATESTAGES
    };
}

export function getGeneratedStagesSuccess(data) {
    return {
        type: actionTypes.RECEIVED_GENERATESTAGES_SUCCESS,
        data
    };
}

export function getGeneratedStagesFailure(error) {
    return {
        type: actionTypes.RECEIVED_GENERATESTAGES_FAILURE,
        data: { error }
    };
}

/***
 * Get the generated stages
 */
export function sendGenerateStages(location, stageCount) {
    return function (dispatch) {
        dispatch(getGeneratedStages());

        axiosFactory.getApiInstance().get('/api/Club/championship/generate/stages?location=' +  location + '&stageCount=' + stageCount)
        .then((resp) => {
			dispatch(getGeneratedStagesSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(getGeneratedStagesFailure(error));
		});
    };
}