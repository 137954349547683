import React from 'react'
import VideoWidget from './Video/VideoWidget';
import TextBlockWidget from './TextBlock/TextBlockWidget';
import HtmlWidget from './HTML/HtmlWidget';
import ButtonWidget from './Button/ButtonWidget';
import GalleryWidget from './Gallery/GalleryWidget';

const VIDEO_WIDGET_NAME = 'youtube_video_widget';
const TEXTBLOCK_WIDGET_NAME = 'text_block_widget';
const HTML_WIDGET_NAME = 'html_widget';
const BUTTON_WIDGET_NAME = 'button_widget';
const GALLERY_WIDGET_NAME = 'gallery_widget';

export function resolveWidget(widget, index) {
    switch(widget.system.type){
       case VIDEO_WIDGET_NAME:  
        const videotype = widget.videoType.map((v) => v.codename)[0];
        const videoOptions = widget.playerOptions.map((o) => o.codename);
        return <VideoWidget key={`widget${index}`} video={widget.youtubeUrl} type={videotype} width={widget.videoWidth} options={videoOptions}/>
      case TEXTBLOCK_WIDGET_NAME:
         const alignOption = widget.textAlign.map((a) => a.codename)[0];
         return <TextBlockWidget key={`widget${index}`} text={widget.content} options={alignOption} />
      case HTML_WIDGET_NAME:
         const html = widget.code.map((h) => h.html)[0];
         return <HtmlWidget key={`widget${index}`} html={html} />
      case BUTTON_WIDGET_NAME:
         const alignmentOption = widget.alignement.map((a) => a.codename)[0];
         const sizeOption = widget.size.map((s) => s.codename)[0];
         const positionOption = widget.position.map((p) => p.codename)[0];
         return <ButtonWidget key={`widget${index}`}  buttons={widget.buttons} alignment={alignmentOption} size={sizeOption} position={positionOption}/>
      case GALLERY_WIDGET_NAME:
         const size = widget.size.map((s) => s.codename)[0];
         return <GalleryWidget key={`widget${index}`} images={widget.images} size={size} />
   }
}

