import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestChampionshipEnd() {
    return {
        type: actionTypes.REQUEST_CHAMPIONSHIPEND
    };
}

export function receivedChampionshipDataSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPEND_SUCCESS,
        data
    };
}

export function receivedChampionshipEndFailure(error) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPEND_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Championship Data
 */
export function fetchChampionshipEnd(id) {
    return function (dispatch) {
        dispatch(requestChampionshipEnd());
        axiosFactory.getApiInstance().get('/api/Club/' + id + '/championships/last/end')
            .then((resp) => {
                dispatch(receivedChampionshipDataSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedChampionshipEndFailure(error));
            });
    };
}