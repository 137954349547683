import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import FlatoutBanner from '../../components/FlatoutBanner/FlatoutBanner';
import Heading from '../../components/Heading/Heading';
import { Container, Row, Col } from 'reactstrap';
import  YoutubeVideo  from '../../components/YoutubeVideo/YoutubeVideo';
import Img from '../../components/Img/Img';

import featureOneImg from '../../assets/images/colinMcRae/flatout_screen1.jpg';
import featureOnePlaceholder from '../../assets/images/colinMcRae/flatout_screen1_ph.jpg';
import featureTwoImg from '../../assets/images/colinMcRae/flatout_screen2.jpg';
import featureTwoPlaceholder from '../../assets/images/colinMcRae/flatout_screen2_ph.jpg';
import featureThreeImg from '../../assets/images/colinMcRae/flatout_screen3.jpg';
import featureThreePlaceholder from '../../assets/images/colinMcRae/flatout_screen3_ph.jpg';


import '../../assets/scss/flatout.scss'

class Flatout extends React.Component {
    render() {
        return (
        <main className="Flatout" role="main">
            <FlatoutBanner />
            <section className="Flatout__Section">
                <Container>
                    <Row className="m-b-50">
                        <Col xs="12" sm="12" md="12">
                            <div className="Flatout__Section__Intro">
                                <Heading rank="2" className="Flatout__Section__Intro--heading">Celebrate decades of rallying history with the Colin McRae: FLAT OUT Pack</Heading>
                                <div className="Flatout__Section__Intro--textline">&ldquo;If in doubt, FLAT OUT.&rdquo;</div>
                                <p>This is the mantra that Colin McRae lived by, solidifying his place as one of rallying’s finest drivers. This attitude powered him to dozens memorable moments, and now it’s your turn to relive these with the DiRT Rally 2.0 Colin McRae: FLAT OUT Pack.</p>
                                <p style={{margin: 0}}>Celebrate a storied history by driving his most famous cars in 40 all-new scenarios and a new location. This is a love-letter to a legend of rally.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-b-75">
                        <Col xs="12" sm="12" md="12">
                            <div className="Flatout__Section__Trailer">
                                <YoutubeVideo videoId={this.props.application.flatoutTrailerId} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-b-50">
						<Col xs="12" sm="4">
							<div className="Flatout__Features__Title">
                                <Heading rank="4">Relive Colin&rsquo;s greatest moments</Heading>
                            </div>
                            <Img image={featureOneImg} placeholder={featureOnePlaceholder} width="100%" height="auto" alt="" className="m-b-10" /> 
                            <p>Colin McRae is a driver who triumphed over adversity in competition, overcoming everything that the world of rallying threw at him to come out on top.</p>
                            <p>Across 40 brand new scenarios, you will play through his entire career; from humble beginnings in 1984 through to his victories on the world stage and beyond.</p>
						</Col>
						<Col xs="12" sm="4">
                            <div className="Flatout__Features__Title">
                                <Heading rank="4">Take on the world in 2 new cars</Heading>
                            </div>
                            <Img image={featureTwoImg} placeholder={featureTwoPlaceholder} width="100%" height="auto" alt="" className="m-b-10" />  
                            <p>Colin McRae&rsquo;s dramatic history has made the cars he drove icons in their own right. With the Colin McRae: Flat Out Pack, it&rsquo;s your turn to hit the road in the legendary SUBARU Impreza S4 Rally and the SUBARU Legacy RS.</p>
						</Col>
						<Col xs="12" sm="4">
                            <div className="Flatout__Features__Title">
                                <Heading rank="4">Your next adventure awaits</Heading>
                            </div>
                            <Img image={featureThreeImg} placeholder={featureThreePlaceholder} width="100%" height="auto" alt="" className="m-b-10" />  
                            <p>&ldquo;Straight roads are for fast cars, turns are for fast drivers&rdquo;.</p>
                            <p>Head to Colin McRae&rsquo;s homeland and tackle the Scottish Highlands of Perth and Kinross, some of the most challenging roads in DiRT Rally 2.0.</p>
						</Col>
					</Row>
                </Container>
            </section>
        </main>
    )}
}
//Connect this container component to the redux store.
export default connect(
	state => { return { application: state.application.value } }, 
	dispatch => bindActionCreators({push}, dispatch)
)(Flatout);

