/**
 * No platform with game data warning page.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Row, Col, Form } from 'reactstrap';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import { Translate } from "react-localize-redux";
import find from 'lodash/find';

import '../assets/scss/forms.scss';
import '../assets/scss/no-game-data-check.scss';

class NoGameDataCheck extends Component {
    displayName = NoGameDataCheck.name

    constructor(props) {
        super(props);

        this.refreshAccount = this.refreshAccount.bind(this);
        this.goToAccounts = this.goToAccounts.bind(this);
    }

    refreshAccount() {
        window.location.reload(false);
    }

    goToAccounts() {
        var win = window.open('/account/manage', '_blank');
        win.focus();
    }

    goToLogin() {
        var returnUrl = window.location.pathname;
        window.location.href = '/account/login?returnUri=' + encodeURIComponent(returnUrl);
    }

    renderWarning() {
        return (
            <main className="NoGameDataCheck" role="main">
                <Helmet>
                    <title>Linked platform not found - DiRT Rally 2.0</title>
                </Helmet>
                <Form>
                    <Row className="no-gutters">
                        <Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }} xs={{ size: 10, offset: 1 }}>
                            <Row >
                                <Col xs="12"><Heading rank="3" divider><Translate id="noGameDataCheck.title">Linked platform not found</Translate></Heading></Col>
                            </Row>
                            <Row>
                                <Col xs="12"><p><Translate id="noGameDataCheck.body">To access this area you require a platform linked to your Codemasters account where you have played DiRT Rally 2.0. To link a platform, click 'MANAGE ACCOUNT' below. Once linked, press the 'REFRESH ACCOUNT' button to continue.</Translate></p></Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Row>
                                        <Col lg="6"><Button type="standard" size="medium" onClick={this.goToAccounts} block><Translate id="loginStatus.manageAccount">Manage Account</Translate></Button></Col>
                                        <Col lg="6"><Button type="standard" size="medium" onClick={this.refreshAccount} block><Translate id="noGameDataCheck.refresh">Refresh Account</Translate></Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </main>

        );
    }    
  
    render() {
        var hasPlatform = find(this.props.identity.platforms, { 'isSelected': true });
        if (!this.props.identity.isAuthenticated) {
            this.goToLogin();
            return null;
        } else if (!hasPlatform) {
            return <React.Fragment>{this.renderWarning()}</React.Fragment>;
        } else {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        }
    } 
}

export default connect(
    state => { return { identity: state.identity }; }
)(NoGameDataCheck);