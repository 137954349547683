import React from 'react';
import PropTypes from 'prop-types';
import tools from  '../WidgeTools';

const getAlign = (align) => {
    switch(align)
    {
        case 'left':
            return 'left';
        case 'right':
            return 'right';
        case 'centered':
            return 'center';
        case 'justified':
            return 'justify';
    }
}

export default function TextBlockWidget(props) {
    
    const {options, text} = props;
 
    return (
        <div style={{textAlign: getAlign(options)}}>
           {text.length > 0 && text.map((t, index) => <div key={`textblock${index}`} dangerouslySetInnerHTML={{__html: tools.decodeUnderligne(t.html)}} /> )}          
        </div>
    )
}

TextBlockWidget.propTypes = {
    text: PropTypes.arrayOf(PropTypes.shape({
        html: PropTypes.string
    })).isRequired,
    options: PropTypes.string
};

TextBlockWidget.defaultProps = {
    align: "left"
}
