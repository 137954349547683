import React, { Fragment } from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';

import './Pagination.scss';

const range = (from, to, step = 1) => {
	let i = from;
	const range = [];
  
	while (i <= to) {
		range.push(i);
		i += step;
	}
  
	return range;
}

/**
 * Pagination
 */
class Pagination extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			activePage: this.props.activePage
		}

		const { pageSize = null, pageLimit = 30, pageNeighbours = 1 } = props;

		this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
		this.totalPages = pageSize ? parseInt(pageSize) : 0;

		this.pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;

		this.updatePage = this.updatePage.bind(this);
		this.prevPage = this.prevPage.bind(this); 
		this.nextPage = this.nextPage.bind(this);
	}

		
	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevState.activePage !== newState.activePage) {
			this.props.onChange(this.state.activePage);
		}

		if(prevProps.activePage !== newProps.activePage) {
			this.setState({
				activePage: this.props.activePage
			})
		}
	}

	updatePage(i) {
		this.setState( prevState => {
			return {
				...prevState, activePage: i
			}
		});
	}

	prevPage() {
		if(this.state.activePage > 1) {
			this.setState( prevState => {
				return {
					...prevState, activePage: this.state.activePage - 1
				}
			});
		}
	}

	nextPage() {
		if(this.state.activePage < this.props.pageSize) {
			this.setState( prevState => {
				return {
					...prevState, activePage: this.state.activePage + 1
				}
			});
		}
	}

	fetchPageNumbers() {

		const totalPages = this.totalPages;
		const activePage = this.state.activePage;
		const pageNeighbours = this.pageNeighbours;
		const totalNumbers = (this.pageNeighbours * 2) + 3;
		const totalBlocks = totalNumbers + 2;
	
		if (totalPages > totalBlocks) {
	
			const startPage = Math.max(2, activePage - pageNeighbours);
			const endPage = Math.min(totalPages - 1, activePage + pageNeighbours);

			let pages = range(startPage, endPage);

			const hasLeftSpill = startPage > 2;
			const hasRightSpill = (totalPages - endPage) > 1;
			const spillOffset = totalNumbers - (pages.length + 1);

			switch (true) {
				case (hasLeftSpill && !hasRightSpill): {
					const extraPages = range(startPage - spillOffset, startPage - 1);
					pages = [...extraPages, ...pages];
					break;
				}
				case (!hasLeftSpill && hasRightSpill): {
					const extraPages = range(endPage + 1, endPage + spillOffset);
					pages = [...pages, ...extraPages];
					break;
				}
				case (hasLeftSpill && hasRightSpill):
				default: {
					pages = [...pages];
					break;
				}
			}
			return [1, ...pages, totalPages];
		}
		return range(1, totalPages);
	}

	render() {  

		if (this.totalPages === 1) return null;

		const { activePage } = this.state;
		const totalPages = this.totalPages;
		const pages = this.fetchPageNumbers();

		return (
			<Fragment>
				<nav aria-label="Pagination" className="Pagination">
					{totalPages >= 1 &&
						<ul>
							<li className={"page-item prev " + (this.state.activePage === 1 ? "disabled" : "")} aria-label="Previous" onClick={this.prevPage}>
								<Translate id="async.previousPage">Previous Page</Translate>
							</li>
								
							{pages.map((page, index) => {
								return (
									<li key={index} 
										className={"page-item" + (activePage === page ? " is-active" : "") + (page === 1 && pages[1] !== 2 ? " first-page" : "") + (page === totalPages && pages[pages.length - 2] !== totalPages - 1 ? " last-page" : "")}  
										onClick={ () => this.updatePage(page) }
									>
										{ page }
									</li>
								);
							})}						
						
							<li className={"page-item next " + (this.state.activePage === this.props.pageSize ? "disabled" : "")} aria-label="Next" onClick={this.nextPage}>
								<Translate id="async.nextPage">Next Page</Translate>
							</li>
						</ul>
					}
				</nav>
			</Fragment>
		);
	};
}

Pagination.propTypes = {
    activePage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
	onChange: PropTypes.func
}


export default Pagination;