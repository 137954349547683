import React from 'react';
import { Translate } from "react-localize-redux";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import Heading from '../../components/Heading/Heading';
import LeaderboardToggles from '../../components/LeaderboardToggles/LeaderboardToggles';
import MediaQuery from 'react-responsive';

import './Leaderboard.scss';
import '../../assets/scss/nationality.scss';

library.add(faSortDown, faCaretRight);

/**
 * Leaderboard
 */
class Leaderboard extends React.Component {
	
	constructor(props) {
		super(props);

		this.sortBy = this.sortBy.bind(this);
		this.updatePage = this.updatePage.bind(this);
		this.updateFilters = this.updateFilters.bind(this);

		this.state = {
			leaderboard: {
				orderByTotalTime: this.props.order,
				page: 1,
                platformFilter: (this.props.platformOnly ? this.props.currentFilter : 'None'),
				playerFilter: "Everyone",
				filterByAssists: "Unspecified",
				filterByWheel: "Unspecified",
				nationalityFilter: "None"
			}
		}
	}

	componentDidUpdate(oldProps, prevState) {
		const newState = this.state;

		if(prevState.leaderboard !== newState.leaderboard) {
			this.props.onChange(this.state.leaderboard);
		}
	}

	sortBy(order) {
		this.setState( prevState => {
			return {
				leaderboard: {
					...prevState.leaderboard,
					orderByTotalTime: order
				}
			}
		});
	}

	updatePage(page) {
		this.setState( prevState => {
			return {
				leaderboard: {
					...prevState.leaderboard,
					page: page
				}
			}
		});
	}

	updateFilters(filters) {
		this.setState( prevState => {
			return {
				leaderboard: {
					...prevState.leaderboard,
					platformFilter: filters.platformFilter,
					playerFilter: filters.playerFilter,
					filterByAssists: filters.filterByAssists,
					filterByWheel: filters.filterByWheel,
					nationalityFilter: filters.nationalityFilter
				}
			}
		});
	}

	render() {
		if (this.props.data.isReady()) {
			const entries = this.props.data.value.entries;

			return (
				<div className="Leaderboard">
					<MediaQuery query="(min-width: 768px)">
						<table>
							<thead>
								<tr>
									<th>
										<Translate id="async.position">Pos</Translate>.
									</th>
									<th>
										<Translate id="async.driver">Driver</Translate>
									</th>
									{!this.props.overall &&
										<React.Fragment>
											<th>
												<Translate id="async.vehicle">Vehicle</Translate>
											</th>
											<th onClick={() => this.sortBy(false)} style={{cursor: (!this.state.leaderboard.orderByTotalTime ? 'default' : 'pointer')}}>
												<Translate id="async.stage">Stage</Translate>
												<FontAwesomeIcon icon={(!this.state.leaderboard.orderByTotalTime ? 'caret-right' : 'sort-down')} color="#FFF" />
											</th>
											<th>
												<Translate id="async.diff">Diff</Translate>
											</th>
										</React.Fragment>
									}
									<th onClick={() => this.sortBy(true)} style={{cursor: (this.state.leaderboard.orderByTotalTime ? 'default' : 'pointer')}}>
										<Translate id="async.total">Total</Translate>
										<FontAwesomeIcon icon={(this.state.leaderboard.orderByTotalTime ? 'caret-right' : 'sort-down')} color="#FFF" />
									</th>
									<th>
										<Translate id="async.diff">Diff</Translate>
									</th>
								</tr>
							</thead>
							<tbody>
								{entries.map((entry, index) => 
									<tr key={index} className={(entry.isPlayer ? "is-user" : "")}>
										<td className="pos">
											{entry.rank}
										</td>
										<td className="driver">
											<i className={"nationality " + entry.nationality}></i>
											{entry.name}
										</td>

										{!this.props.overall &&
											<React.Fragment>
												<td className="vehicle">
													{entry.vehicleName}
												</td>
												<td className="stage">
													{entry.stageTime}
												</td>
												<td className="diff">
													{entry.stageDiff}
												</td>
											</React.Fragment>
										}
										<td className="total">
											{entry.totalTime}
										</td>
										<td className="diff">
											{entry.totalDiff}
										</td>
									</tr>
								)}

								{!entries.length &&
									<React.Fragment>
										{[...Array(20)].map((x, i) =>
											<tr key={i}>
												<td className="pos">&nbsp;</td> 
												<td className="driver">&nbsp;</td>
												{!this.props.overall &&
													<React.Fragment>
														<td className="vehicle">&nbsp;</td>
														<td className="stage">&nbsp;</td>
														<td className="diff">&nbsp;</td>
													</React.Fragment>
												}
												<td className="total">&nbsp;</td>
												<td className="diff">&nbsp;</td>
											</tr>
										)}
									</React.Fragment>
								}

							</tbody>
						</table>
						{!entries.length &&
							<div className="Leaderboard__noEntries">
								<Heading rank="3"><Translate id="async.noData">Sorry, there is no data for this event</Translate></Heading>
								<p><Translate id="async.checkBack">Check back again soon</Translate></p>
							</div>
						}
						<MediaQuery query="(min-width: 1200px)">
							<div className="Leaderboard__toggles">
								<Row>
									<Col xs="12" xl="7">
										<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly} />
									</Col>
									<Col xs="12" xl="5">
										<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
									</Col>
								</Row>
							</div>
						</MediaQuery>
						<MediaQuery query="(min-width: 768px) and (max-width: 1200px)">
							<div className="Leaderboard__toggles m-b-10">
								<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly}  />
							</div>
							<div className="Leaderboard__toggles">
								<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
							</div>
						</MediaQuery>
					</MediaQuery>
					<MediaQuery query="(max-width: 767px)">
						<table>
							<thead>
								<tr>
									<th>
										<Translate id="async.position">Pos</Translate>.
									</th>
									<th>
										<Translate id="async.driver">Driver</Translate>
									</th>
									{!this.props.overall &&
										<React.Fragment>
											<th onClick={() => this.sortBy(false)} style={{cursor: (!this.state.orderByTotalTime ? 'default' : 'pointer')}}>
												<Translate id="async.stage">Stage</Translate>
												<FontAwesomeIcon icon={(!this.state.orderByTotalTime ? 'sort-down' : 'caret-right')} color="#FFF" />
											</th>
											<th>
												<Translate id="async.diff">Diff</Translate>
											</th>
										</React.Fragment>
									}
									<th onClick={() => this.sortBy(true)} style={{cursor: (this.state.orderByTotalTime ? 'default' : 'pointer')}}>
										<Translate id="async.total">Total</Translate>
										<FontAwesomeIcon icon={(this.state.orderByTotalTime ? 'sort-down' : 'caret-right')} color="#FFF" />
									</th>
								</tr>
							</thead>
							<tbody>
								{entries.map((entry, index) => 
									<tr key={index}>
										<td className="pos">
											{entry.rank}
										</td>
										<td className="driver">
											{entry.name}
										</td>
										{!this.props.overall &&
											<React.Fragment>
												<td className="stage">
													{entry.stageTime}
												</td>
												<td className="diff">
													{entry.stageDiff}
												</td>
											</React.Fragment>
										}
										<td className="total">
											{entry.totalTime}
										</td>
									</tr>
								)}

								{!entries.length &&
									<React.Fragment>
										{[...Array(10)].map((x, i) =>
											<tr key={i}>
												<td className="pos">&nbsp;</td> 
												<td className="driver">&nbsp;</td>
												{!this.props.overall &&
													<React.Fragment>
														<td className="vehicle">&nbsp;</td>
														<td className="stage">&nbsp;</td>
														<td className="diff">&nbsp;</td>
													</React.Fragment>
												}
												<td className="total">&nbsp;</td>
												<td className="diff">&nbsp;</td>
											</tr>
										)}
									</React.Fragment>
								}
							</tbody>
						</table>
						{!entries.length &&
							<div className="Leaderboard__noEntries">
								<Heading rank="4"><Translate id="async.noData">Sorry, there is no data for this event</Translate></Heading>
								<p><Translate id="async.checkBack">Check back again soon</Translate></p>
							</div>
						}
						<div className="Leaderboard__toggles m-b-10">
							<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly} />
						</div>
						<div className="Leaderboard__toggles">
							<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
						</div>
					</MediaQuery>				
				</div>
			);
		} else if (this.props.data.isFaulted()) {
			return (
				<div className="Leaderboard">
					<MediaQuery query="(min-width: 768px)">
						<table>
							<thead>
								<tr>
									<th>
										<Translate id="async.position">Pos</Translate>.
									</th>
									<th>
										<Translate id="async.driver">Driver</Translate>
									</th>
									{!this.props.overall &&
										<React.Fragment>
											<th>
												<Translate id="async.vehicle">Vehicle</Translate>
											</th>
											<th onClick={() => this.sortBy(false)} style={{cursor: (!this.state.leaderboard.orderByTotalTime ? 'default' : 'pointer')}}>
												<Translate id="async.stage">Stage</Translate>
												<FontAwesomeIcon icon={(!this.state.leaderboard.orderByTotalTime ? 'caret-right' : 'sort-down')} color="#FFF" />
											</th>
											<th>
												<Translate id="async.diff">Diff</Translate>
											</th>
										</React.Fragment>
									}
									<th onClick={() => this.sortBy(true)} style={{cursor: (this.state.leaderboard.orderByTotalTime ? 'default' : 'pointer')}}>
										<Translate id="async.total">Total</Translate>
										<FontAwesomeIcon icon={(this.state.leaderboard.orderByTotalTime ? 'caret-right' : 'sort-down')} color="#FFF" />
									</th>
									<th>
										<Translate id="async.diff">Diff</Translate>
									</th>
								</tr>
							</thead>
							<tbody>
								<React.Fragment>
									{[...Array(20)].map((x, i) =>
										<tr key={i}>
											<td className="pos">&nbsp;</td> 
											<td className="driver">&nbsp;</td>
											{!this.props.overall &&
												<React.Fragment>
													<td className="vehicle">&nbsp;</td>
													<td className="stage">&nbsp;</td>
													<td className="diff">&nbsp;</td>
												</React.Fragment>
											}
											<td className="total">&nbsp;</td>
											<td className="diff">&nbsp;</td>
										</tr>
									)}
								</React.Fragment>
							</tbody>
						</table>

						<div className="Leaderboard__noEntries">
							<Heading rank="3"><Translate id="async.noData">Sorry, there is no data for this event</Translate></Heading>
							<p><Translate id="async.checkBack">Check back again soon</Translate></p>
						</div>
					
						<MediaQuery query="(min-width: 1200px)">
							<div className="Leaderboard__toggles">
								<Row>
									<Col xs="12" xl="7">
										<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly} />
									</Col>
									<Col xs="12" xl="5">
										<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
									</Col>
								</Row>
							</div>
						</MediaQuery>

						<MediaQuery query="(min-width: 768px) and (max-width: 1200px)">
							<div className="Leaderboard__toggles m-b-10">
								<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly}  />
							</div>
							<div className="Leaderboard__toggles">
								<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
							</div>
						</MediaQuery>
					</MediaQuery>
					<MediaQuery query="(max-width: 767px)">
						<table>
							<thead>
								<tr>
									<th>
										<Translate id="async.position">Pos</Translate>.
									</th>
									<th>
										<Translate id="async.driver">Driver</Translate>
									</th>
									{!this.props.overall &&
										<React.Fragment>
											<th onClick={() => this.sortBy(false)} style={{cursor: (!this.state.orderByTotalTime ? 'default' : 'pointer')}}>
												<Translate id="async.stage">Stage</Translate>
												<FontAwesomeIcon icon={(!this.state.orderByTotalTime ? 'sort-down' : 'caret-right')} color="#FFF" />
											</th>
											<th>
												<Translate id="async.diff">Diff</Translate>
											</th>
										</React.Fragment>
									}
									<th onClick={() => this.sortBy(true)} style={{cursor: (this.state.orderByTotalTime ? 'default' : 'pointer')}}>
										<Translate id="async.total">Total</Translate>
										<FontAwesomeIcon icon={(this.state.orderByTotalTime ? 'sort-down' : 'caret-right')} color="#FFF" />
									</th>
								</tr>
							</thead>
							<tbody>
								<React.Fragment>
									{[...Array(10)].map((x, i) =>
										<tr key={i}>
											<td className="pos">&nbsp;</td> 
											<td className="driver">&nbsp;</td>
											{!this.props.overall &&
												<React.Fragment>
													<td className="vehicle">&nbsp;</td>
													<td className="stage">&nbsp;</td>
													<td className="diff">&nbsp;</td>
												</React.Fragment>
											}
											<td className="total">&nbsp;</td>
											<td className="diff">&nbsp;</td>
										</tr>
									)}
									</React.Fragment>
							</tbody>
						</table>
						<div className="Leaderboard__noEntries">
							<Heading rank="4"><Translate id="async.noData">Sorry, there is no data for this event</Translate></Heading>
							<p><Translate id="async.checkBack">Check back again soon</Translate></p>
						</div>
						<div className="Leaderboard__toggles m-b-10">
							<LeaderboardToggles filterState={this.state.leaderboard} onChange={this.updateFilters} platformOnly={this.props.platformOnly} />
						</div>
						<div className="Leaderboard__toggles">
							<Pagination activePage={this.state.leaderboard.page} pageSize={this.props.data.value.pageCount} onChange={this.updatePage} />
						</div>
					</MediaQuery>				
				</div>
			);
        } else /*isIntialising() or isLoading()*/ {
            return (<Loader currentState={this.props.data.state} />);
        }    
	};
}

Leaderboard.defaultProps = {
	platformOnly: false
};

/**
 * Define expected props.
 */
Leaderboard.propTypes = {
	data: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	onChange: PropTypes.func,
	platformOnly: PropTypes.bool
};

export default Leaderboard;