import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LatestNews from '../../components/LatestNews/LatestNews';
import * as actions from '../../actions/NewsActions';
import HighlightedNews from '../../components/HighlightedNews/HighlightedNews';
import Loader from '../../components/Loader/Loader';
import take from 'lodash/take';

class ConnectedLatestNewsListings extends Component {

    defaultNumberOfItemsToRender = 20;

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchLatestNews();
	}
	
	getHighlightedNews() {
		return this.props.latestNews.value.news.shift();
	}

    render() {
        if (this.props.latestNews.isReady()) {
            const fullNews = this.props.latestNews.value.news;
			const HighlightedNewsArticle = fullNews.shift();

            return (
				<div>
					<Container>
						<Row className="m-b-50">
							<Col xs="12">
								<HighlightedNews article={HighlightedNewsArticle} />
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<LatestNews news={fullNews}></LatestNews>
							</Col>
						</Row>
					</Container>
				</div>
			);
		} else if (this.props.latestNews.isFaulted()) {
			return (
				<Container>
					<Row className="m-b-50">
						<Col xs="12">
							<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} errorMessage="Failed to load latest news" />
						</Col>
					</Row>
				</Container>
			);
        } else /*isIntialising() or isLoading()*/ {
            return (
				<Container>
					<Row className="m-b-50">
						<Col xs="12">
							<Loader currentState={this.props.latestNews.state} />
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.latestNews.state} />
						</Col>
					</Row>
				</Container>
			);
        }           
    }
}

ConnectedLatestNewsListings.propTypes = {
    maxItemsToRender: PropTypes.number,
    latestNews: PropTypes.shape({
        state: PropTypes.string.isRequired
    }),
    fetchLatestNews: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { latestNews: state.news.latest } },
    dispatch => bindActionCreators(actions, dispatch)
)(ConnectedLatestNewsListings);
