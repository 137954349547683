import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../containers/Redux/Header';
import Footer from '../components/Footer/Footer';
import StickyHeader from 'react-sticky-header';
import { Helmet } from "react-helmet";

import 'react-sticky-header/styles.css';

class Layout extends Component {
	displayName = Layout.name
	
	componentDidMount() {
		if(window.navigator.userAgent.indexOf("Edge") > -1) {
			document.body.classList.add('is-edge');
		}
	}

    render() {
        return (
            <div>
				<Helmet>
                    <meta http-equiv="x-ua-compatible" content="ie=edge" />
                    <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />
                    <meta id="vp" name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="image" content="/images/icon.png" />
                    <link rel="shortcut icon" type="image/png" href="/images/icon.png" />
                    <meta property="og:title" content="Dirt Rally 2.0" />
                    <meta property="og:image" content="/images/icon.png" />
                    <meta name="twitter:image" content="/images/icon.png" />
                    <meta name="image" content="/images/icon.png" />
                    <link rel="shortcut icon" type="image/png" href="/images/icon.png" />
                    <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
                    <link rel="manifest" href="/manifest.webmanifest" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Dirt Rally 2.0" />
				</Helmet>
				<StickyHeader
					header={
						<Header />
					}
				>
				</StickyHeader>
			    	{this.props.children}
                <Footer languages={this.props.application.value.languages} />
            </div>
        );
    }
}

export default connect(state => {
    return {
        application: state.application
    };
})(Layout);