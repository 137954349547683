import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Heading from '../../components/Heading/Heading';
import GotyBanner from '../../components/GotyBanner/GotyBanner';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import Img from '../../components/Img/Img';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '../../components/Button/Button';

import Para1 from '../../assets/images/goty/para_1.jpg';
import Para1_Ph from '../../assets/images/goty/para_1_ph.jpg';
import Para2 from '../../assets/images/goty/para_2.jpg';
import Para2_Ph from '../../assets/images/goty/para_2_ph.jpg';
import Feature1 from '../../assets/images/goty/screen01.jpg';
import Feature1_Ph from '../../assets/images/goty/screen01_ph.jpg';
import Feature2 from '../../assets/images/goty/screen02.jpg';
import Feature2_Ph from '../../assets/images/goty/screen02_ph.jpg';
import Feature3 from '../../assets/images/goty/screen03.jpg';
import Feature3_Ph from '../../assets/images/goty/screen03_ph.jpg';
import Feature4 from '../../assets/images/goty/screen04.jpg';
import Feature4_Ph from '../../assets/images/goty/screen04_ph.jpg';
import Feature5 from '../../assets/images/goty/screen05.jpg';
import Feature5_Ph from '../../assets/images/goty/screen05_ph.jpg';
import Feature6 from '../../assets/images/goty/screen06.jpg';
import Feature6_Ph from '../../assets/images/goty/screen06_ph.jpg';
import Feature7 from '../../assets/images/goty/screen07.jpg';
import Feature7_Ph from '../../assets/images/goty/screen07_ph.jpg';
import Feature8 from '../../assets/images/goty/screen08.jpg';
import Feature8_Ph from '../../assets/images/goty/screen08_ph.jpg';
import Feature9 from '../../assets/images/goty/screen09.jpg';
import Feature9_Ph from '../../assets/images/goty/screen09_ph.jpg';
import Feature10 from '../../assets/images/goty/screen10.jpg';
import Feature10_Ph from '../../assets/images/goty/screen10_ph.jpg';
import Feature11 from '../../assets/images/goty/screen11.jpg';
import Feature11_Ph from '../../assets/images/goty/screen11_ph.jpg';
import Feature12 from '../../assets/images/goty/screen12.jpg';
import Feature12_Ph from '../../assets/images/goty/screen12_ph.jpg';
import Review from '../../assets/images/goty/reviews.png';
import Review_Ph from '../../assets/images/goty/reviews_ph.png';

import '../../assets/scss/goty.scss'

class Goty extends Component {

    callToAction = () => {
        this.props.push('/buy');
    }

    render() {
        return (
            <main className="Goty" role="main">
                <GotyBanner />
                <section className="Goty__Section">
                    <Container>
                        <Row className="m-b-50">
                            <Col xs="12" sm="12" md="6">
                                <div className="Goty__Section__Trailer">
                                    <YoutubeVideo videoId={this.props.application.gotyTrailerId} />
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="6">
                                <Heading rank="2">DIRT RALLY 2.0 Game of the year features:</Heading>
                                <p>The most comprehensive rally and rallycross experience ever made, with over 80 cars and 26 locations</p>
                                <p>Experience the most authentic point-to-point rally game combined with thrilling, fast-paced action of the Official FIA World Rallycross Championship.</p>
                                <div className="Goty__Section__Buy">
                                   <Button type="standard" size="large" onClick={this.callToAction} block>Buy Now</Button> 
                                </div>                       
                            </Col>
                        </Row>
                    </Container>
                    <div className="Goty__Section__Para">
                        <Img image={Para1} placeholder={Para1_Ph} width="100%" height="auto"/>
                        <div className="Goty__Section__Para__Text">
                           <Heading rank="3">&quot;DIRT RALLY 2.0 Feels Great, Looks GOOD AND SOUNDS FABULOUS&quot; - <span className="Goty__Section__Para__Quote">IGN</span></Heading> 
                        </div>
                        
                    </div>
                    <Container>
                        <Row>
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature1} placeholder={Feature1_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>26 of the world most iconic locations including Wales, Monte Carlo, Sweden, Germany, Greece, Finland and Scotland.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature2} placeholder={Feature2_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>Head to Colin McRae&apos;s homeland and tackle the Scottish gravel roads of Perth and Kinross.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature3} placeholder={Feature3_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>Take on the varying conditions of gravel, tarmac and snow.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-b-50">
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature4} placeholder={Feature4_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>Legendary rally cars. Including fan-favourites like the SUBARU Impreza 2001, Peugeot 206 Rally and the Skoda Fabia Rally.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature5} placeholder={Feature5_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>The most authentic DIRT Rally experience yet with tyre degradation, surface deformation, and everything in between.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div className="Goty__Section__Feature">
                                    <Img image={Feature6} placeholder={Feature6_Ph} width="100%" height="auto"/>
                                </div>
                                <div className="Goty__Section__Feature__Title">
                                    <p>Turn back the clock with some of the most iconic vehicles in rally history.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="Goty__Section__Para">
                        <Img image={Para2} placeholder={Para2_Ph} width="100%" height="auto"/>
                        <div className="Goty__Section__Para__Text">
                           <Heading rank="3">&quot;It&apos;s easily one the of the most thrilling racing games in the recent years&quot; - <span className="Goty__Section__Para__Quote">PUSH SQUARE</span></Heading> 
                        </div>
                        
                    </div>
                    <Container>
                        <Row>
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature7} placeholder={Feature7_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>13 World Rallycross locations, 25 World Rallycross cars, 15 bonus liveries.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature8} placeholder={Feature8_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>All 2018 and 2019 Official FIA World Rallycross Championship, cars, drivers and locations at your fingertips.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature9} placeholder={Feature9_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>Visit classic tracks from Rallycross history.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-b-50">
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature10} placeholder={Feature10_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>Take to the track in Timmy Hansen&apos;s 2019 Championship-winning Peugeot 208 WRX.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature11} placeholder={Feature11_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>Relive epic battles or rewrite Rallycross history.</p>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <div class="Goty__Section__Feature">
                                    <Img image={Feature12} placeholder={Feature12_Ph} width="100%" height="auto"/>
                                </div>
                                <div class="Goty__Section__Feature__Title">
                                    <p>Take on the raw power of fearsome classic Rallycross cars.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-b-50">
                        <Col xs="12" sm="12" md="12">
                            <div className="Goty__Section__Review">
                                <Img image={Review} placeholder={Review_Ph} width="100%" height="auto" />
                            </div>
                            
                        </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        )
    }
}

//Connect this container component to the redux store.
export default connect(
    state => { return { application: state.application.value } },
    dispatch => bindActionCreators({ push }, dispatch)
)(Goty);
