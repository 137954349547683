import React from 'react';
import PropTypes from 'prop-types';
import {
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from 'reactstrap';
import Button from '../Button/Button';
import ChampionshipStageEditor from '../ChampionshipStageEditor/ChampionshipStageEditor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendGenerateStages } from '../../actions/Clubs/GenerateStages';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogPopup from '../DialogPopup/DialogPopup';

import './ChampionshipEventEditor.scss'

library.add(faPlusCircle, faTrashAlt);

/** 
 *  Championship Event Editor
 */
class ChampionshipEventEditor extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            activeLocation: 0,
			event: this.props.event,
			dlcContent: false
        };

		this.updateLocation = this.updateLocation.bind(this);
		this.generateStages = this.generateStages.bind(this);
		this.updateStages = this.updateStages.bind(this);
		this.handleDuration = this.handleDuration.bind(this);
		this.addEvent = this.addEvent.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);
		this.handleVehicleClass = this.handleVehicleClass.bind(this);
		this.resetDialog = this.resetDialog.bind(this);
	}

	componentDidMount() {
        this.setState({
            activeLocation: this.props.options.locations.findIndex(x => x.id === this.props.event.locationId)
        });
	}

	componentDidUpdate(oldProps, oldState) {
		const newProps = this.props;
		const newState = this.state;

		if(oldState.event !== newState.event) {
			this.props.eventUpdate(this.state.event);
		}

		if(oldProps.event !== newProps.event) {
			this.setState( prevState => {
                return {
                    activeLocation: this.props.options.locations.findIndex(x => x.id === this.props.event.locationId),
                    event: this.props.event
                };
			});
		}

		if(oldProps.stages.value !== newProps.stages.value) {
			if(this.props.stages.isReady()) { 
				this.setState( prevState => {
                    return {
                        event: {
                            ...prevState.event, stages: this.props.stages.value
                        }
                    };
				});
			}
		}
	}

	updateStages(stages) {
		this.setState( prevState => {
            return {
                event: {
                    ...prevState.event, stages: stages
                }
            };
		});
	}
	
	updateLocation(e) {
		const locations = this.props.options.locations;
		const value = this.props.options.locations[e.target.value];

		if(value.isDlc) {
			this.setState({
				dlcContent: "warning"
			});
		}
		
		this.setState( prevState => {
            return {
                activeLocation: this.props.options.locations.findIndex(x => x.id === value.id),
                event: {
                    ...prevState.event, locationId: value.id
                }
            };
		}, () => this.generateStages());
	}

	generateStages() {
		const location = this.props.options.locations[this.state.activeLocation].id;
		const stageCount = this.state.event.stages.length;
		this.props.sendGenerateStages(location, stageCount);
	}

	handleDuration(e) {
		let value = parseInt(e.target.value);
		let name = e.target.name;

		if(name === "durationDays" && value === 0) {
			if(this.state.event.durationHours < 1) {
				this.setState(prevState => {
					return {
						event: {
							...prevState.event, 
							durationHours: 1
						}
					};
				});
			}
		}

		if(name === "durationHours" && value === 0) {
			if(this.state.event.durationDays < 1 && this.state.event.durationMins < 15) {
				this.setState(prevState => {
					return {
						event: {
							...prevState.event, 
							durationMins: 15
						}
					};
				});
			}
		}

        this.setState(prevState => {
            return {
                event: {
					...prevState.event, 
					[name]: value
                }
            };
        });
	}

	handleVehicleClass(e) {
		const vehicleClassRestrictions = this.props.options.vehicleOptions;
		let value = vehicleClassRestrictions[e.target.value];

		if(value.dlcStatus === "IsSolelyDlcVehicles") {
			this.setState(prevState => {
				return {
					event: {
						...prevState.event, 
						vehicleClassRestriction: value.id
					},
					dlcContent: "warning"
				};
			});
		} else {
			this.setState(prevState => {
				return {
					event: {
						...prevState.event, 
						vehicleClassRestriction: value.id
					}
				};
			});
		}
	}

	resetDialog() {
		this.setState({
			dlcContent: false
		})
	}

	deleteEvent() {
		this.props.deleteEvent();
	}

	addEvent() {
		this.props.addEvent();
	}

	render() { 
		let days = [];
		let hours = [];
		let mins = [];
		const vehicleClassRestrictions = this.props.options.vehicleOptions;

		for (let i = 0; i <= 31; i++) { 
            days.push(<option key={i} value={i}>{i} Day{i > 1 ? "s" : ""}</option>);
		}

		for (let i = 0; i <= 24; i++) { 
			hours.push(<option key={i} value={i}>{i} Hour{i > 1 ? "s" : ""}</option>);
		}

		if(this.state.event.durationDays === 0 && this.state.event.durationHours === 0) {
			for (let i = 15; i <= 60; i++) { 
				mins.push(<option key={i} value={i}>{i} Mins</option>);
			}
		} else {
			for (let i = 0; i <= 60; i++) { 
				mins.push(<option key={i} value={i}>{i} Mins</option>);
			}
		}

		return (
			<div className="ChampionshipEventEditor">
				<Row>
					<Col xs="12">
						<div className="ChampionshipEventEditor__options">
							<Row>
								<Col md="3" lg="3">
									<FormGroup>
										<Label for="location">Location</Label>
										<Input 
											type="select" 
											name="location" 
											id="locationSelector" 
											className="small"
											value={this.props.options.locations.findIndex(x => x.id === this.state.event.locationId)}
											onChange={this.updateLocation}
										>
											{this.props.options.locations.map((location, index) => 
												<option key={index} value={index}>{location.name}</option>
											)}
										</Input>
									</FormGroup>
								</Col>
								<Col md="6" lg="3">
									<FormGroup>
										<Label for="eventDuration">Event Duration</Label>
										<Row>
											<Col xs="4">
												<Input 
													type="select" 
													name="durationDays" 
													id="durationDays" 
													className="small"
													value={this.state.event.durationDays}
													onChange={this.handleDuration}
												>
													{days}
												</Input>
											</Col>
											<Col xs="4">
												<Input 
													type="select" 
													name="durationHours" 
													id="durationHours" 
													className="small"
													value={this.state.event.durationHours}
													onChange={this.handleDuration}
												>
													{hours}
												</Input>
											</Col>
											<Col xs="4">
												<Input 
													type="select" 
													name="durationMins" 
													id="durationMins" 
													className="small"
													value={this.state.event.durationMins}
													onChange={this.handleDuration}
												>
													{mins}
												</Input>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col md="3" lg="3">
									<FormGroup>
										<Label for="vehicleRestrictions">Vehicle Class</Label>
										<Row>
											<Col xs="12">
												<Input 
													type="select" 
													name="vehicleClassRestriction" 
													id="vehicleClassRestriction" 
													className="small"
													value={this.props.options.vehicleOptions.findIndex(x => x.id === this.state.event.vehicleClassRestriction)}
													onChange={this.handleVehicleClass}
												>
													{vehicleClassRestrictions.map((vehicleClassRestriction, index) => 
														<option key={index} value={index}>
															{vehicleClassRestriction.name}
														</option>
													)}

												</Input>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col md="4" lg="3">
									<div className="ChampionshipEventEditor__Actions">

										{this.props.totalEvents < 12 &&
											<Button type="text" onClick={this.addEvent} size="small"><FontAwesomeIcon icon="plus-circle" color="#fff" />Add New Event</Button>
										}

										{this.props.totalEvents > 1 &&
											<Button type="text" onClick={this.deleteEvent} size="small"><FontAwesomeIcon icon="trash-alt" color="#fff" />Delete Event</Button>
										}
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<ChampionshipStageEditor stages={this.state.event.stages} options={this.props.options.locations[this.state.activeLocation]} onChange={this.updateStages} />
					</Col>
				</Row>

				{this.state.dlcContent === "warning" && 
					<DialogPopup header="DLC Content" description="You have selected DLC content, some users may not be able to participate." confirmText="Ok" onConfirm={this.resetDialog} />
				}
			</div>
		);
	};
}

/**
 * Define expected props.
 */
ChampionshipEventEditor.propTypes = {
	event: PropTypes.object.isRequired,
	options: PropTypes.object.isRequired,
	stages: PropTypes.shape({
        state: PropTypes.string,
		value: PropTypes.array,
		isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired
    }),
	sendGenerateStages: PropTypes.func
};

 
//Connect this container component to the redux store.
export default connect(
    state => { return { stages: state.championshipStages.stages} },   
    dispatch => bindActionCreators({ sendGenerateStages }, dispatch)
)(ChampionshipEventEditor);