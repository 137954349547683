import React from 'react';
import PropTypes from 'prop-types';

import './LanguageSelector.scss';

/**
 * Language selector component. 
 */
class LanguageSelector extends React.Component {

    getChangeLanguageUrl(isActive, cultureCode) {
        const returnUrl = window.location.pathname;
        const url = '/account/changeLanguage?cultureCode=' + cultureCode + '&returnUrl=' + returnUrl;

        return isActive ? '' : url;
    }

    render() {
        if (!this.props.languages) {
            return null;
        }

        const languagesList = this.props.languages.map((language) =>
            (<li key={language.name} className={language.isActive ? 'active' : ''}>
                <a href={this.getChangeLanguageUrl(language.isActive, language.code)}>{language.name}</a>
            </li>)
        );
        return (
            <ul className="language-selector">
                {languagesList}
            </ul>
        );
    }
}

LanguageSelector.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired
    }))
};

export default LanguageSelector;