import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../components/Icon/Icon';
import './IconButton.scss'

/**
 * Icon Button
 */
class IconButton extends React.Component {
	
	render() { 
		
		const {type, size, className, icon, onClick} = this.props;
		let classNames = `icon-btn icon-btn-${type} icon-btn-${size} ` + className;
 
		return (
			<button type="button" href="#" className={classNames} onClick={onClick}>
				<Icon name={icon} size="32" />
				{this.props.children}
			</button>
		);
	};
}
  
IconButton.propTypes = {
	/** Specify button type */ 
	type: PropTypes.oneOf(['standard', 'text']),
	/**  Specify button size */
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	/**  Additional class names */
	className: PropTypes.string,
	/** Specify icon to output */ 
	icon: PropTypes.oneOf([ 
		'icon-blog_circle', 
		'icon-cog', 
		'icon-download', 
		'icon-expand_circle', 
		'icon-leaderboard_circle',
		'icon-left_circle',
		'icon-members_circle',
		'icon-play_circle',
		'icon-right_circle',
		'icon-search',
		'icon-trophy',
		'icon-trophy_circle',
		'icon-upload',
		'icon-video_circle',
		'icon-plus_circle'
	]).isRequired,
	/** Gets called when the user clicks on the button */
	onClick: PropTypes.func

}

IconButton.defaultProps = {
	type: "text",
	size: "medium",
	className: "",
	icon: "icon-expand_circle", 
	onClick: event => {
		// eslint-disable-next-line no-console
		console.log('You have clicked me!', event.target); 
	}
}
 
export default IconButton;