import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestInvites() {
    return {
        type: actionTypes.REQUEST_INVITES
    };
}

export function receivedInvitesSuccess(data) {
    return {
        type: actionTypes.RECEIVED_INVITES_SUCCESS,
        data
    };
}

export function receivedInvitesFailure(error) {
    return {
        type: actionTypes.RECEIVED_INVITES_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Friends
 */
export function fetchInvites(data) {
    return function (dispatch) {
        dispatch(requestInvites());
        axiosFactory.getApiInstance().get('/api/Club/invitations/?pageSize=' + data.pageSize + '&page=' + data.page)
            .then((resp) => {
                dispatch(receivedInvitesSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedInvitesFailure(error));
            });
    };
}