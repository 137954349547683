import axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as clientStoreActions from '../actions/ClientStoreActions';
import * as applicationActions from '../actions/ApplicationActions';
import { states } from '../models/StatefulValue';
import * as Sentry from '@sentry/browser';
import NProgress from 'nprogress/nprogress';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import axiosFactory from '../models/AxiosFactory';

import '../assets/scss/bootstrapper.scss';
import 'nprogress/nprogress.css';

/***
 * Wrapper / Container component that bootstraps the application fetching any required initial store state.
 */
class Bootstrapper extends Component {
    displayName = Bootstrapper.name

    /**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). 
     * Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.configureAxiosInterceptor();
        this.props.fetchInitialState();
    }

    /**
     * componentDidUpdate() is invoked immediately after updating occurs. This method is not called for the initial render.
     * @param {any} prevProps
     * @param {any} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.application.isInState(states.LoadSuccessful)) {
            axiosFactory.configureAxiosCSRFToken(this.props.application.value.antiForgeryHeaderName, this.props.identity.token);
            this.configureSentry(this.props.application.value);
            this.configureLocalize(this.props.application.value.localizeConfig);
            // this.configureGoogleAnalytics();
            // this.configureFacebookPixel();
            NProgress.done();
            this.props.setApplicationIsReady();
        } 
    }

    /***
     * Initialises sentry (sentry.io) SDK for error reporting.
     * @param {object} appSettings The application settings from the redux store.
     */
    configureSentry(appSettings) {
        if (appSettings.sentryDsn) {
            Sentry.init({
                dsn: appSettings.sentryDsn,
                environment: appSettings.environment,
                release: appSettings.release
            });
        }
    }

    /***
     * Configures Axios with standard interceptor for handling 401 (not authorised) responses.
     */
    configureAxiosInterceptor() {
        axios.interceptors.response.use(undefined, function (error) {
            if (error.response.status === 401 /*Not Authorised*/) {
                var returnUrl = window.location.pathname;
                window.location.href = '/account/login?returnUri=' + encodeURIComponent(returnUrl);
            } 
            return Promise.reject(error.response);
        });
    }

    /**
     * Configures client side localisation provider: see https://ryandrewjohnson.github.io/react-localize-redux-docs
     * @param {object} config localize configuration.
     */
    configureLocalize(config) {
        this.props.initialize({
            languages: config.languages,
            translation: config.translation,
            options: { renderToStaticMarkup, ignoreTranslateChildren: true, renderInnerHtml: config.options.renderInnerHtml }
        });
    }

    /**
     * Initialises Google analytics module, see: https://github.com/react-ga/react-ga
     */
    // configureGoogleAnalytics() {
    //     if (this.props.application.value.environment === 'Production') {
    //         ReactGA.initialize('UA-2766471-85');
    //     } else {
    //         ReactGA.initialize('UA-2766471-85', { debug: true });
    //     }
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }

    /**
     * Initialises the Facebook analytics module. see: https://www.npmjs.com/package/react-facebook-pixel
     */
    
    // configureFacebookPixel() {
    //     if (this.props.application.value.environment === 'Production') {
    //         ReactPixel.init('404405997026394', {/*advancedMatching */}, { autoConfig: true, debug: false });
    //     } else {
    //         ReactPixel.init('404405997026394', {/*advancedMatching */}, { autoConfig: true, debug: true });
    //     }
    //     ReactPixel.pageView();
    // } 

    render() {
        if (this.props.application.isReady()) {
            return (<div>{this.props.children}</div>);
        } else {
			NProgress.inc();
            return (<div></div>);
        } 
    }
}

/**
 * Maps the state items we want to props on this component.
 * @param {any} state state
 * @returns {object} props
 */
const mapStateToProps = state => {
    return {
        application: state.application,
        identity: state.identity
    };
};

//Connect this container component to the redux store.
export default withLocalize(connect(
    state => mapStateToProps, //map state to props, what state do you want coming into this component on its props.
    dispatch => bindActionCreators({ ...clientStoreActions, ...applicationActions }, dispatch) //map dispatch to props, which actions you want to access via props.
)(Bootstrapper));
