import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postIsNameAvailable() {
    return {
        type: actionTypes.SEND_ISNAMEAVAILABLE
    };
}

export function postIsNameAvailableSuccess(data) {
    return {
        type: actionTypes.RECEIVED_ISNAMEAVAILABLE_SUCCESS,
        data
    };
}

export function postIsNameAvailableFailure(error) {
    return {
        type: actionTypes.RECEIVED_ISNAMEAVAILABLE_FAILURE,
        data: { error }
    };
}

/***
 * Post 'is name available' check
 */
export function sendIsNameAvailable(data) {
    return function (dispatch) {
        dispatch(postIsNameAvailable());
        
        axiosFactory.getApiInstance().post('/api/Club/IsNameAvailable', data)
        .then((resp) => {
			dispatch(postIsNameAvailableSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postIsNameAvailableFailure(error));
		});
    };
}