/**
 * Create Club Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    cumulativeLeaderboard: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.SEND_CUMULATIVE_LEADERBOARD:
            return {
                ...state,
                cumulativeLeaderboard: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_CUMULATIVE_LEADERBOARD_SUCCESS:
            return {
                ...state,
                cumulativeLeaderboard: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_CUMULATIVE_LEADERBOARD_FAILURE:
            return {
                ...state,
                cumulativeLeaderboard: new StatefulValue({}, states.Faulted)
            };
        
        default: return state;
    }
};