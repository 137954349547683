import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './IconDivider.scss';

/**
 * Divider Button
 */
class IconDivider extends React.Component {
	
	render() { 
		const {icon} = this.props;

		return (
			<div className="IconDivider">
				<div className="IconDivider__icon">
					<Icon name={icon} size="50" />
				</div>
			</div>
		);
	};
}

IconDivider.propTypes = {
	/** Specify icon to output */ 
	icon: PropTypes.oneOf([ 
		'icon-blog_circle', 
		'icon-cog', 
		'icon-download', 
		'icon-expand_circle', 
		'icon-leaderboard_circle',
		'icon-left_circle',
		'icon-members_circle',
		'icon-play_circle',
		'icon-right_circle',
		'icon-search',
		'icon-trophy',
		'icon-trophy_circle',
		'icon-upload',
		'icon-video_circle'
	]).isRequired,
}

IconDivider.defaultProps = {
	icon: 'icon-blog_circle'
}

export default IconDivider;