import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

export function postLeaderboard() {
    return {
        type: actionTypes.SEND_LEADERBOARD
    };
}

export function postLeaderboardSuccess(data) {
    return {
        type: actionTypes.RECEIVED_LEADERBOARD_SUCCESS,
        data
    };
} 

export function postLeaderboardFailure(error) {
    return {
        type: actionTypes.RECEIVED_LEADERBOARD_FAILURE,
        data: { error }
    };
}

/***
 * Get the Leaderboard
 */
export function getLeaderboard(leaderboardData) {
    return function (dispatch, getState) {
        dispatch(postLeaderboard());

        axiosFactory.getApiInstance().post('/api/Leaderboard', leaderboardData)
        .then((resp) => {
			dispatch(postLeaderboardSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postLeaderboardFailure(error));
		});
    };
}