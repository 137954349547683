import React from 'react';
import { Translate } from "react-localize-redux";
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';

import PopoutBannerImg from '../../assets/images/popoutbanner.png';
import MobilePopoutBannerImg from '../../assets/images/mob_popoutbanner.png';
import PopoutBannerImgPlaceholder from '../../assets/images/popoutbanner_placeholder.png';

import './PopoutBanner.scss';

/** 
 * Popout Banner
 */
class PopoutBanner extends React.Component {
	
	render() { 
		return (
			<div className="PopoutBanner">
				<MediaQuery query="(min-width: 768px)">
					<Img image={PopoutBannerImg} placeholder={PopoutBannerImgPlaceholder} width="100%" height="auto" className="PopoutBanner__img--desktop" />
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img image={MobilePopoutBannerImg} placeholder={PopoutBannerImgPlaceholder} width="100%" height="auto" className="m-b-25" />
				</MediaQuery>
                <div className="PopoutBanner__content">
                    <Heading rank="2"><Translate id="popOutBanner.officialWrc">Official Game of the FIA World Rallycross Championship</Translate></Heading>
				</div>  
			</div>
		);
	};
}

export default PopoutBanner;