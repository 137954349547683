import React from 'react';
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

import ClubsBannerImg from '../../assets/images/clubs-banner.jpg';
import ClubsBannerImgPh from '../../assets/images/clubs-banner_ph.jpg';

import ClubBannerImg1200 from '../../assets/images/features_wide_1200.jpg';
import ClubBannerImgMob from '../../assets/images/clubbanner_mob.jpg';


import './ClubsBanner.scss';

/** 
 * Clubs Banner
 */
class ClubsBanner extends React.Component {

	constructor(props) {
		super(props);

		this.goToCreateClub = this.goToCreateClub.bind(this);
	}

	goToCreateClub() {
		this.props.push('/clubs/create-club');
	}
	
	render() { 
		return (
			<div className="ClubsBanner">
				<MediaQuery query="(min-width: 1201px)">
					<Img image={ClubsBannerImg} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(min-width: 769px) and (max-width: 1200px)">
					<Img image={ClubBannerImg1200} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
				<MediaQuery query="(max-width: 768px)">
					<Img image={ClubBannerImgMob} placeholder={ClubsBannerImgPh} width="100%" height="auto" />
				</MediaQuery>
                <div className="ClubsBanner__content">
                    <Heading rank="3" className="ClubsBanner__header"><Translate id="clubs.welcome">Welcome to Clubs</Translate></Heading>
                    <p><Translate id="clubs.welcomeBody1">As players, this is your home to create and manage your own competitions against your friends, as well as the rest of the DiRT community.</Translate></p>
                    <p><Translate id="clubs.welcomeBody2">We’ve given you the tools to create your own events, it’s up to you to carve out the experience you want. Whether that be super competitive, just for fun, or somewhere in between, it’s your call. Your Club, your rules.</Translate></p>
                    <Button type="standard" size="small" onClick={this.goToCreateClub}>Create Club</Button>
				</div>  
			</div>
		);
	};
}

ClubsBanner.propTypes = {
    push: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
 )(ClubsBanner);