import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ProgressiveImage from 'react-progressive-image'

/**
 * Progressive image with lazy loading
 */
class Img extends React.Component {

	render() { 
		const {image, placeholder, width, height, alt, className} = this.props;

		return (
			<React.Fragment>
				<LazyLoad height={200} offset={100}>
					<React.Fragment>
						<ProgressiveImage 
							src={image} 
							placeholder={placeholder}
						>
							{(src, loading) => <img style={{ opacity: loading ? 0.2 : 1, filter: loading ? 'blur(10px)' : 'blur(0px)', transition: 'opacity 0.5s ease 0.25s' }} src={src} alt={alt} width={width} height={height} className={className} />}
						</ProgressiveImage>
						</React.Fragment>
				</LazyLoad>
			</React.Fragment>
		);
	};
}
  
Img.propTypes = {
	/** Import image */ 
	image: PropTypes.string.isRequired,
	/** Import image placeholder */ 
	placeholder: PropTypes.string.isRequired,
	/**  Specify image width */
	width: PropTypes.string,
	/**  Specify image height */
	height: PropTypes.string,
	/**  Specify image ALT tag */
	alt: PropTypes.string.isRequired,
	/**  Specify additional classes to apply */
	className: PropTypes.string
}

Img.defaultProps = {
	width: "100%",
	height: "auto",
	alt: "",
	className: ""
}
 
export default Img;