import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import Modal from 'react-modal';
import { Container, Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { fetchScreenshots } from '../../actions/MediaActions';
import Img from '../../components/Img/Img';
import Button from '../../components/Button/Button';
import DividerButton from '../../components/DividerButton/DividerButton';
import IconDivider from '../../components/IconDivider/IconDivider';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faAngleLeft, faAngleRight, faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/scss/modal.scss';

library.add(faTimes, faAngleLeft, faAngleRight, faArrowCircleLeft, faArrowCircleRight);

class ScreenshotGallery extends Component {
	
	constructor(props) {
		super(props);

		this.loadMoreScreenshots = this.loadMoreScreenshots.bind(this);
		this.prevScreenshot = this.prevScreenshot.bind(this);
		this.nextScreenshot = this.nextScreenshot.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.displayScreenshot = this.displayScreenshot.bind(this);

		this.state = {
			modalIsOpen: false,
			screenshotsPerPage: 9,
			screenshotsToAdd: 3,
			currentIndex: 0
		}
	}

	componentWillMount() {
		Modal.setAppElement('body');
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchScreenshots();
	}

	/**
     * Display full size screenshot in modal
     */
	displayScreenshot(index) {
		this.setState({
			currentIndex: index
		});
		this.openModal();
	}

	openModal() {
		this.setState({modalIsOpen: true});
	}

	closeModal() {
		this.setState({modalIsOpen: false});
	}

	/**
     * Load more videos into the page
     */
	loadMoreScreenshots() {
		this.setState({
			screenshotsPerPage: this.state.screenshotsPerPage + this.state.screenshotsToAdd
		})
	}

	prevScreenshot() {
		let prevIndex = ((this.state.currentIndex - 1 < 0) ? (this.props.gallery.value.length - 1) : this.state.currentIndex - 1);
		this.setState({
			currentIndex: prevIndex
		});
	}

	nextScreenshot() {
		let nextIndex = ((this.state.currentIndex + 1) > (this.props.gallery.value.length - 1) ? 0 : this.state.currentIndex + 1);
		this.setState({
			currentIndex: nextIndex
		});
	}

    /**
     * Turns the provided relative path to an absolute cdn path. 
     * @param {any} relativePath
     */
    getCdnImagePath(relativePath) {
        return this.props.application.cdnEndpoint + relativePath;
    }

    /**
     * Render screenshots
     */
    renderScreenshots(screenshots) {
		const currentScreenshots = screenshots.slice(0, this.state.screenshotsPerPage);

        return (
			<Container>
				<Row className="Media__ScreenshotGallery">
                    {currentScreenshots.map((screenshot, index) => 
						<Col xs="12" sm="6" md="4" key={screenshot.full}>
							<div className="Media__Screenshot" onClick={() => this.displayScreenshot(index)} style={{'cursor' : 'pointer'}}>
								<Img image={this.getCdnImagePath(screenshot.full)} 
									placeholder={this.getCdnImagePath(screenshot.placeholder)} 
									width="100%" 
									height="auto" 
									alt="" />
								<div className="Media__Screenshot__overlay">
									<div className="Media__Screenshot__icon">
										<IconDivider icon="icon-expand_circle" />
									</div>
								</div>
							</div>
						</Col>
					)}
				</Row>

				<Modal 
					isOpen={this.state.modalIsOpen} 
					onRequestClose={this.closeModal}  
					portalClassName="cmModal" 
					className="cmModal__body"
					overlayClassName="cmModal__overlay"
					contentLabel="Screenshot"
				>
					<Button type="standard" className="cmModal__close" onClick={this.closeModal}>
						<FontAwesomeIcon icon="times" color="#FFF" />
					</Button>
					<div className="cmModal__content">
						<Img image={this.getCdnImagePath(this.props.gallery.value[this.state.currentIndex].full)} placeholder={this.getCdnImagePath(this.props.gallery.value[this.state.currentIndex].placeholder)} width="100%" height="auto" alt="" />  
 					</div>
					<Button type="standard" className="cmModal__prev" onClick={this.prevScreenshot}>
						<FontAwesomeIcon icon="angle-left" color="#FFF" />
					</Button>
					<Button type="standard" className="cmModal__next" onClick={this.nextScreenshot}>
						<FontAwesomeIcon icon="angle-right" color="#FFF" />
					</Button>
				</Modal>
				
				{this.state.screenshotsPerPage < this.props.gallery.value.length &&
					<Row className="m-t-15">
						<Col xs="12">
                        <DividerButton onClick={this.loadMoreScreenshots}><Translate id="general.loadMore">Load More</Translate></DividerButton>
						</Col>
					</Row>
				}
			</Container>
        );     
    }

    render() {
        if (this.props.gallery.isReady()) {
			return this.renderScreenshots(this.props.gallery.value);      
        } else if (this.props.gallery.isFaulted()) {
			return (
				<Container>
					<Row>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state}  />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state} />
						</Col>
						<Col  xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state} />
						</Col>
					</Row>
				</Container>
			);
        } else /*isIntialising() or isLoading()*/ {
            return (
				<Container>
					<Row>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state} />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state} />
						</Col>
						<Col xs="12" sm="6" md="4">
							<Loader currentState={this.props.gallery.state} />
						</Col>
					</Row>
				</Container>
			);
        }    
    }
}

/**
 * Define expected props.
 */
ScreenshotGallery.propTypes = {
    gallery: PropTypes.shape({
        state: PropTypes.string.isRequired,
        value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
    }),
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
    }),
    fetchScreenshots: PropTypes.func.isRequired 
};

//Connect this container component to the redux store.
export default connect(
    state => { return { gallery: state.media.screenshots, application: state.application.value }; }, 
    dispatch => bindActionCreators({ fetchScreenshots }, dispatch)
)(ScreenshotGallery);
 