import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { Container, Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Video from '../../components/Video/Video';
import Loader from '../../components/Loader/Loader';
import { fetchYouTubePlaylist } from '../../actions/YouTubeActions';
import DividerButton from '../../components/DividerButton/DividerButton';

class YouTubePlaylist extends Component {

	playlistName = "default"; 
	
	constructor(props) {
		super(props);

		this.loadMoreVideos = this.loadMoreVideos.bind(this);

        this.state = {
            videosPerPage: 2,
            videosToAdd: 2
        };
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchYouTubePlaylist(this.playlistName);
	}

	/**
     * Load more videos into the page
     */
	loadMoreVideos() {
		this.setState({
			videosPerPage: this.state.videosPerPage + this.state.videosToAdd
		})
	}
	
    /**
     * Render a playlists items.
     * @param {any} name The name of the playlist.
     * @param {any} playlists The playlist dictionary.
     */
    renderPlaylist(name, playlists) {
		const currentPlaylist = playlists[name].value.slice(0, this.state.videosPerPage);

        return (
			<Container>
				<Row>
					{currentPlaylist.map(video => <Col xs="12" sm="12" md="6" key={video.videoId}><Video video={video} key={video.videoId} /></Col>)}
				</Row>
				
				{this.state.videosPerPage < playlists[name].value.length &&
					<Row className="m-t-15">
                    <Col xs="12">
                        <DividerButton onClick={this.loadMoreVideos}><Translate id="general.loadMore">Load More</Translate></DividerButton>
						</Col>
					</Row>
				}
			</Container>
        );     
    }

    render() {
        if (this.props.youtube.playlists[this.playlistName].isReady()) {
			return this.renderPlaylist(this.playlistName, this.props.youtube.playlists);      
        } else if (this.props.youtube.playlists[this.playlistName].isFaulted()) {
			return (
				<Container>
					<Row>
						<Col xs="12" sm="12" md="6">
							<Loader currentState={this.props.youtube.playlists[this.playlistName].state} />
						</Col>
						<Col xs="12" sm="12" md="6">
							<Loader currentState={this.props.youtube.playlists[this.playlistName].state} />
						</Col>
					</Row>
				</Container>
			);
        } else /*isIntialising() or isLoading()*/ {
            return (
				<Container>
					<Row>
						<Col xs="12" sm="12" md="6">
							<Loader currentState={this.props.youtube.playlists[this.playlistName].state} />
						</Col>
						<Col xs="12" sm="12" md="6">
							<Loader currentState={this.props.youtube.playlists[this.playlistName].state} />
						</Col>
					</Row>
				</Container>
			);
        }    
    }
}

YouTubePlaylist.propTypes = {
    youtube: PropTypes.shape({
        playlists: PropTypes.object.isRequired 
    }),
    fetchYouTubePlaylist: PropTypes.func.isRequired 
};

//Connect this container component to the redux store.
export default connect(
    state => { return { youtube: state.youtube }; }, 
    dispatch => bindActionCreators({ fetchYouTubePlaylist }, dispatch)
)(YouTubePlaylist);
 