/**
 * My Club Reducers
 */

import * as actionTypes from '../../actions/ActionTypes';
import { StatefulValue, states } from '../../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    clubs: new StatefulValue({}),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_MYCLUBS:
            return {
                ...state,
                clubs: new StatefulValue({}, states.Loading)
            };

        case actionTypes.RECEIVED_MYCLUBS_SUCCESS:
            return {
                ...state,
                clubs: new StatefulValue(action.data, states.Ready)
            };

        case actionTypes.RECEIVED_MYCLUBS_FAILURE:
            return {
                ...state,
                clubs: new StatefulValue([], states.Faulted)
            };
        
        default: return state;
    }
};