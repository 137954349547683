import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function disbandClub() {
    return {
        type: actionTypes.DISBAND_CLUB
    };
}

export function receivedDisbandClubSuccess(data) {
    return {
        type: actionTypes.RECEIVED_DISBAND_CLUB_SUCCESS,
        data
    };
}

export function receivedDisbandClubFailure(error) {
    return {
        type: actionTypes.RECEIVED_DISBAND_CLUB_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function deleteClub(id) {
    return function (dispatch) {
        dispatch(disbandClub());
        axiosFactory.getApiInstance().delete('/api/Club/' + id + '/disband')
            .then((resp) => {
                dispatch(receivedDisbandClubSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedDisbandClubFailure(error));
            });
    };
}