import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postPromoteMember() {
    return {
        type: actionTypes.SEND_PROMOTEMEMBER
    };
}

export function postPromoteMemberSuccess(data) {
    return {
        type: actionTypes.RECEIVED_PROMOTEMEMBER_SUCCESS,
        data
    };
}

export function postPromoteMemberFailure(error) {
    return {
        type: actionTypes.RECEIVED_PROMOTEMEMBER_FAILURE,
        data: { error }
    };
}

/***
 * Promote Member Function
 */
export function sendPromoteMember(clubId, memberId) {
    return function (dispatch, getState) {
        dispatch(postPromoteMember());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/member/' + memberId + '/promote')
        .then((resp) => {
			dispatch(postPromoteMemberSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postPromoteMemberFailure(error));
		});
    };
}