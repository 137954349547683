import React from 'react';
import {Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendPromoteMember } from '../../actions/Clubs/PromoteMember';
import { sendDemoteMember } from '../../actions/Clubs/DemoteMember';
import { sendKickMember } from '../../actions/Clubs/KickMember';
import { sendApproveMember } from '../../actions/Clubs/ApproveMember';
import { sendDeclineMember } from '../../actions/Clubs/DeclineMember';
import DialogPopup from '../DialogPopup/DialogPopup';
import { push } from 'connected-react-router';

import './MemberMenu.scss';

library.add(faEllipsisH);

/** 
 * Member Menu
 */
class MemberMenu extends React.Component {

	constructor(props) {
		super(props);
	
		this.toggleMenu = this.toggleMenu.bind(this);

		this.state = {
			isOpen: false,
			memberPromoted: false,
			memberDemoted: false,
			memberKicked: false,
			memberApproved: false,
			memberDeclined: false
		};

		this.promoteMember = this.promoteMember.bind(this);
		this.demoteMember = this.demoteMember.bind(this);
		this.kickMember = this.kickMember.bind(this);
		this.resetDialogs = this.resetDialogs.bind(this);
		this.reloadMembers = this.reloadMembers.bind(this);
		this.declineMember = this.declineMember.bind(this);
		this.approveMember = this.approveMember.bind(this);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.promoteMember !== newProps.promoteMember) {
			if(this.props.promoteMember.isReady()) {
				this.setState({
					memberPromoted: "promoted"
				});
			} else if(this.props.promoteMember.isFaulted()) {
				this.setState({
					memberPromoted: "error"
				});
			}
		}
		
		if(prevProps.demoteMember !== newProps.demoteMember) {
			if(this.props.demoteMember.isReady()) {
				this.setState({
					memberDemoted: "demoted"
				});
			} else if(this.props.demoteMember.isFaulted()) {
				this.setState({
					memberDemoted: "error"
				});
			}
		}

		if(prevProps.kickMember !== newProps.kickMember) {
			if(this.props.kickMember.isReady()) {
				this.setState({
					memberKicked: "kicked"
				});
			} else if(this.props.kickMember.isFaulted()) {
				this.setState({
					memberKicked: "error"
				});
			}
		}

		if(prevProps.approveMember !== newProps.approveMember) {
			if(this.props.approveMember.isReady()) {
				this.setState({
					memberApproved: "approved"
				});
			} else if(this.props.approveMember.isFaulted()) {
				this.setState({
					memberApproved: "error"
				});
			}
		}

		if(prevProps.declineMember !== newProps.declineMember) {
			if(this.props.declineMember.isReady()) {
				this.setState({
					memberDeclined: "declined"
				});
			} else if(this.props.declineMember.isFaulted()) {
				this.setState({
					memberKicked: "error"
				});
			}
		}
	}

	promoteMember() {
		this.props.sendPromoteMember(this.props.clubId, this.props.member.id);
	}

	demoteMember() {
		this.props.sendDemoteMember(this.props.clubId, this.props.member.id);
	}

	kickMember() {
		this.props.sendKickMember(this.props.clubId, this.props.member.id);
	}

	approveMember() {
		this.props.sendApproveMember(this.props.clubId, this.props.member.id);
	}

	declineMember() {
		this.props.sendDeclineMember(this.props.clubId, this.props.member.id);
	}

	reloadMembers() {
		window.location.reload();
	}

	resetDialogs() {
		this.setState({
			memberPromoted: false,
			memberDemoted: false,
			memberKicked: false,
			memberApproved: false
		})
	}
	
	toggleMenu() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	render() { 
		const membershipType = this.props.member.membershipType;
		const permissions = this.props.permissions;
		
		if( 
			(permissions.canPromoteToOwner && membershipType !== "Owner") || 
			(permissions.canPromoteToAdmin && (membershipType !== "Administrator" && membershipType !== "Owner")) && this.props.identity.displayName !== this.props.member.displayName  || 
			(permissions.canKickMember && (membershipType !== "Administrator" && membershipType !== "Owner")) || 
			(permissions.canPromoteToOwner && membershipType === "Owner" && this.props.identity.displayName !== this.props.member.displayName) || 
			(permissions.canAcceptOrDenyJoinRequest && membershipType === null)
		) {

			return (
				<React.Fragment>
					<Dropdown className="MemberMenu" isOpen={this.state.isOpen} toggle={this.toggleMenu}>
						<DropdownToggle><FontAwesomeIcon icon="ellipsis-h" color="#FFF" /></DropdownToggle>

						{typeof membershipType === 'undefined' &&
							<DropdownMenu right={true}>
								{permissions.canAcceptOrDenyJoinRequest && <DropdownItem onClick={this.approveMember}>Accept</DropdownItem>}
								{permissions.canAcceptOrDenyJoinRequest && <DropdownItem onClick={this.declineMember}>Decline</DropdownItem>}
							</DropdownMenu>
						}

						{typeof membershipType !== 'undefined' &&
							<DropdownMenu right={true}>
								{permissions.canPromoteToOwner && membershipType !== "Owner" && membershipType !== "Player" && <DropdownItem onClick={this.promoteMember}>Promote</DropdownItem>}
								{permissions.canPromoteToAdmin && membershipType !== "Administrator" && membershipType !== "Owner" && <DropdownItem onClick={this.promoteMember}>Promote</DropdownItem>}
								{permissions.canPromoteToAdmin && membershipType !== "Owner" && membershipType !== "Player" && this.props.identity.displayName !== this.props.member.displayName && <DropdownItem onClick={this.demoteMember}>Demote</DropdownItem>}
								{permissions.canPromoteToOwner && membershipType === "Owner" && this.props.identity.displayName !== this.props.member.displayName && <DropdownItem onClick={this.demoteMember}>Demote</DropdownItem>}
								{permissions.canKickMember && membershipType !== "Owner" && membershipType !== "Administrator" && <DropdownItem onClick={this.kickMember}>Kick</DropdownItem>}
							</DropdownMenu>
						}
					</Dropdown>

					{this.state.memberPromoted === "promoted" && 
						<DialogPopup header="Member Promoted" description="You have successfully promoted a member." confirmText="Ok" onConfirm={this.reloadMembers} />
					}

					{this.state.memberPromoted === "error" && 
						<DialogPopup header="Error" description="An error occured and your member wasn't promoted." confirmText="Ok" onConfirm={this.resetDialogs} />
					}

					{this.state.memberDemoted === "demoted" && 
						<DialogPopup header="Member Demoted" description="You have successfully Demoted a member." confirmText="Ok" onConfirm={this.reloadMembers} />
					}

					{this.state.memberPromoted === "error" && 
						<DialogPopup header="Error" description="An error occured and your member wasn't promoted." confirmText="Ok" onConfirm={this.resetDialogs} />
					}

					{this.state.memberKicked === "kicked" && 
						<DialogPopup header="Member Kicked" description="You have successfully kicked a member." confirmText="Ok" onConfirm={this.reloadMembers} />
					}

					{this.state.memberPromoted === "error" && 
						<DialogPopup header="Error" description="An error occured and your member wasn't promoted." confirmText="Ok" onConfirm={this.resetDialogs} />
					}

					{this.state.memberApproved === "approved" && 
						<DialogPopup header="Member Approved" description="You have successfully approved a member." confirmText="Ok" onConfirm={this.reloadMembers} />
					}

					{this.state.memberApproved === "error" && 
						<DialogPopup header="Error" description="An error occured and your member wasn't approved." confirmText="Ok" onConfirm={this.resetDialogs} />
					}

					{this.state.memberDeclined === "declined" && 
						<DialogPopup header="Member Declined" description="You have successfully declined a member." confirmText="Ok" onConfirm={this.reloadMembers} />
					}

					{this.state.memberDeclined === "error" && 
						<DialogPopup header="Error" description="An error occured and your member wasn't declined." confirmText="Ok" onConfirm={this.resetDialogs} />
					}

				</React.Fragment>
			);
		} else {
			return null;
		}
	};
}

/*
* Define expected props.
*/
MemberMenu.propTypes = {
	clubId: PropTypes.number.isRequired,
	permissions: PropTypes.object.isRequired,
	member: PropTypes.object.isRequired,
	promoteMember: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	demoteMember: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	kickMember: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	approveMember: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	declineMember: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	sendPromoteMember: PropTypes.func.isRequired,
	sendDemoteMember: PropTypes.func.isRequired,
	sendKickMember: PropTypes.func.isRequired,
	sendApproveMember: PropTypes.func.isRequired,
	sendDeclineMember: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

MemberMenu.defaultProps = {
    identity: {
        store: {
            displayName: "",
        }
    }
};

//Connect this container component to the redux store.
export default connect(
    state => { return { 
		promoteMember: state.promoteMember.member, 
		demoteMember: state.demoteMember.member,
		kickMember: state.kickMember.member,
		approveMember: state.approveMember.member,
		declineMember: state.declineMember.member,
		identity: state.identity 
	} }, 
    dispatch => bindActionCreators({ 
		push, 
		sendPromoteMember, 
		sendDemoteMember, 
		sendKickMember, 
		sendApproveMember, 
		sendDeclineMember 
	}, dispatch)
)(MemberMenu); 
