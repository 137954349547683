/**
 * Create Club
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import CreateClubBanner from '../../components/CreateClubBanner/CreateClubBanner';
import CreateClubForm from '../../components/CreateClubForm/CreateClubForm';
import NoGameDataCheck from '../../containers/NoGameDataCheck';

import '../../assets/scss/clubs.scss';

export default function CreateClub() {
    return (
        <NoGameDataCheck>
            <main className="CreateClub" role="main">
                <CreateClubBanner />
                <div className="Clubs__CreateClub">
                    <Row>
                        <Col xs="12">
                            <CreateClubForm />
                        </Col>
                    </Row>
                </div>
            </main>
        </NoGameDataCheck>
    );
}