import React from 'react';
import ScreenshotWidget from './Screenchot/ScreenshotWidget';
import { resolveWidget } from './WidgetResolver';
import tools from './WidgeTools';

export default function ContentResolver(props) {
    
    return (
        <React.Fragment>
            {props.content.map((data, index) => {
                var item = toCamel(data);
                //text
                if (item.html != undefined)
                {
                    var decoded = tools.decodeUnderligne(item.html);
                    return <div key={`p${index}`} dangerouslySetInnerHTML={{__html: decoded}} />
                } 
                //image
                else if (item.url !== undefined) {
                    return <ScreenshotWidget key={`screenshot${index}`} image={item} />
                }
                else if(Array.isArray(item)) {
                    return item.map((widget) => {
                        return resolveWidget(widget, index);
                    })
                }
            } 
            )}
        </React.Fragment>
    )
}

//IE11
function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}