import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function getGeneratedChampionship() {
    return {
        type: actionTypes.SEND_GENERATECHAMPIONSHIP
    };
}

export function getGeneratedChampionshipSuccess(data) {
    return {
        type: actionTypes.RECEIVED_GENERATECHAMPIONSHIP_SUCCESS,
        data
    };
}

export function getGeneratedChampionshipFailure(error) {
    return {
        type: actionTypes.RECEIVED_GENERATECHAMPIONSHIP_FAILURE,
        data: { error }
    };
}

/***
 * Get the generated championship
 */
export function sendGenerateChampionship(counts) {
    return function (dispatch) {
        dispatch(getGeneratedChampionship());

        axiosFactory.getApiInstance().get('/api/Club/championship/generate?eventCount=' +  counts.eventCount + '&stageCount=' + counts.stageCount)
        .then((resp) => {
			dispatch(getGeneratedChampionshipSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(getGeneratedChampionshipFailure(error));
		});
    };
}