import React from 'react';
import PropTypes from 'prop-types';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import IconDivider from '../../components/IconDivider/IconDivider';

import './NewsListing.scss'

/** 
 * News Listing
 */
class NewsListing extends React.Component {
	
	render() { 
		const {image, placeholder, title} = this.props;

		return (
			<div className="NewsListing m-b-50">
				<div className="NewsListing__thumb">
					<Img image={image} placeholder={placeholder} width="100%" height="auto" alt={title} /> 
					<div className="NewsListing__overlay">
						<div className="NewsListing__overlay__content">
							<IconDivider icon="icon-blog_circle" />
						</div>
					</div>
				</div>
				<Heading rank="4" className="NewsListing__title">{title}</Heading>
			</div>
		);
	};
}

NewsListing.propTypes = {
	/** Specify img to output */
	image: PropTypes.string.isRequired,
	/** Specify placeholder img to output */
	placeholder: PropTypes.string.isRequired,
	/** Specify heading text output */
	title: PropTypes.string.isRequired
}

export default NewsListing;