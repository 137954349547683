import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './YoutubeVideo.scss';


export default class YoutubeVideo extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="YoutubeVideo">
                <iframe src={`https://www.youtube.com/embed/${this.props.videoId}`} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        )
    }
}
YoutubeVideo.propTypes = {
	/** Specify YouTube video id */ 
	videoId: PropTypes.string.isRequired
}