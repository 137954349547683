import React from 'react';
import PropTypes from 'prop-types';
import {
	Input
} from 'reactstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogPopup from '../DialogPopup/DialogPopup';

import './ChampionshipStageEditor.scss'

library.add(faTimes, faPlusCircle);

/** 
 *  Championship Event Editor
 */
class ChampionshipStageEditor extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			stages: this.props.stages,
			dlcContent: false
		}

		this.updateRoute = this.updateRoute.bind(this);
		this.updateCondition = this.updateCondition.bind(this);
		this.updateServiceArea = this.updateServiceArea.bind(this);
		this.updateSurfaceDegredation = this.updateSurfaceDegredation.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.deleteStage = this.deleteStage.bind(this);
		this.addStage = this.addStage.bind(this);
		this.resetDialog = this.resetDialog.bind(this);
	}

	componentDidUpdate(oldProps, prevState) {
		const newProps = this.props;
		const newState = this.state; 

		if(oldProps.stages !== newProps.stages) {
			this.setState( prevState => {
				return { 
					...prevState, stages: this.props.stages
				}
			});
		}

		if(prevState.stages !== newState.stages) {
			this.props.onChange(this.state.stages);
		}
	}

	addStage() {
		const newStage = {
			route: this.props.options.routes[Math.floor(Math.random() * this.props.options.routes.length)].id,
			serviceArea: "None",
			timeOfDay: this.props.options.supportedConditions[Math.floor(Math.random() * this.props.options.supportedConditions.length)].id
		}
		const newStages = this.state.stages;
		newStages.push(newStage);

		this.setState( prevState => {
			return { 
				...prevState, stages: newStages
			}
		});
	}

	deleteStage(index) {
		let stages = this.state.stages;
		stages[index].deleted = true;

		if(stages.length > 1) {
			this.setState( prevState => {
				return { 
					...prevState, stages: stages
				}
			}, () => {
				let newStages = this.state.stages;
				newStages.splice(index, 1);

				if(newStages[0].serviceArea === "None") {
					newStages[0].serviceArea = "eMedium";
				}
		
				setTimeout(() => {
					this.setState( prevState => {
						return { 
							...prevState, stages: newStages
						}
					});
				}, 800);
			});
		}


	}

	updateRoute(e, index) {
		let value = e.target.value;
		let stages = this.state.stages;
		stages[index].route = value;

		this.setState( prevState => {
			return { 
				...prevState, stages: stages
			}
		});
	}

	updateCondition(e, index) {
		let value = e.target.value;
		let stages = this.state.stages;
		stages[index].timeOfDay = value;

		this.setState( prevState => {
			return { 
				...prevState, stages: stages
			}
		});
	}

	updateServiceArea(e, index) {
		let value = e.target.value;
		let stages = this.state.stages;
		stages[index].serviceArea = value;

		this.setState( prevState => {
			return { 
				...prevState, stages: stages
			}
		});
	}

	updateSurfaceDegredation(e, index) {
		let value = e.target.value;
		let stages = this.state.stages;
		stages[index].surfaceDegradation = value;

		this.setState( prevState => {
			return { 
				...prevState, stages: stages
			}
		});
	}

	addLeadingZeros(value) {
		value = String(value);
		while (value.length < 2) {
		  value = '0' + value;
		}
		return value;
	}

	onDragEnd(result) {		
		const {destination, source} = result;

		if(!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const stage = this.state.stages[source.index];
		const newStages = this.state.stages;
		newStages.splice(source.index, 1);
		newStages.splice(destination.index, 0, stage);

		this.setState( prevState => {
			return { 
				...prevState, stages: newStages
			}
		});
	}

	resetDialog() {
		this.setState({
			dlcContent: false
		})
	}

	render() { 
		let stages = this.state.stages;

		return (
			<div className="ChampionshipStageEditor">
				<DragDropContext onDragEnd={this.onDragEnd}>
					<table>
						<thead>
							<tr>
								<th></th>
								<th className="stage text-center">
									Stage
								</th>
								<th className="route">
									Route
								</th>
								<th className="timeOfDay">
									Time of Day / Conditions
								</th>
								<th>
									Surface Deg
								</th> 
								<th>
									Service Area
								</th>
							</tr>
						</thead>
						<Droppable droppableId="stagesDroppable">
							{(provided) => (
								<tbody
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{provided.placeholder}
									{stages.map((stage, index) => 
										<Draggable draggableId={"draggable_" + index} index={index} key={index}>
											{(provided, snapshot) => (
												<tr 
													key={index} 
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													ref={provided.innerRef}
													className={(snapshot.isDragging ? 'is-dragging ' : '') + (stage.deleted ? 'is-deleted' : '')}
												>

													<td className="delete">
														{this.state.stages.length > 1 &&
															<div className="ChampionshipStageEditor__deleteStage" onClick={() => this.deleteStage(index)}>
																<FontAwesomeIcon icon="times" color="#FFF" />
															</div>
														}
													</td>

													<td className="text-center stage">
														<span>{this.addLeadingZeros(index + 1)}</span>
													</td>
													<td className="no-padding route">
														<Input 
															type="select" 
															name="routeSelector" 
															id={"routeSelector_" + index}
															value={stage.route}
															onChange={(e) => this.updateRoute(e, index)} 
														>
															{this.props.options.routes.map((route, index) => 
																<option key={index} value={route.id}>{route.name} - {Math.round(route.lengthKm * 10) / 10}km </option>
															)}
														</Input>
													</td>	
													<td className="timeOfDay">
														<Input 
															type="select" 
															name="conditionSelector" 
															id={"conditionSelector_" + index}
															value={stage.timeOfDay}
															onChange={(e) => this.updateCondition(e, index)} 
														>
															{this.props.options.supportedConditions.map((condition, index) => 
																<option key={index} value={condition.id}>{condition.name}</option>
															)}
														</Input>
													</td>
													<td className="serviceArea">
														<Input 
															type="select" 
															name="surfaceDegradation" 
															id={"surfaceDegradation" + index}
															value={stage.surfaceDegradation}
															onChange={(e) => this.updateSurfaceDegredation(e, index)} 
														>
															<option value="0">None</option>
															<option value="25">Low</option>
															<option value="50">Medium</option>
															<option value="75">High</option>
															<option value="100">Max</option>
														</Input>
								
													</td>
													<td className="serviceArea">
														<Input 
															type="select" 
															name="serviceArea" 
															id={"serviceArea_" + index}
															value={stage.serviceArea}
															onChange={(e) => this.updateServiceArea(e, index)} 
														>
															{index !== 0 &&
																<option value="None">None</option>
															}
															<option value="eShort">Short</option>
															<option value="eMedium">Medium</option>
															<option value="eLong">Long</option>
														</Input>
													</td>
												</tr>
											)}
										</Draggable>
									)}
							</tbody>
						)}
						</Droppable>
					</table>
				</DragDropContext>
				{this.state.stages.length < 12 &&
					<div className="ChampionshipStageEditor__addStage" onClick={this.addStage}>
						<FontAwesomeIcon icon="plus-circle" color="#FFF" />
						Add Additional Stage							
					</div>
				}

				
				{this.state.dlcContent === "warning" && 
					<DialogPopup header="DLC Content" description="You have selected DLC content, some users may not be able to participate." confirmText="Ok" onConfirm={this.resetDialog} />
				}
			</div>
		);
	};
}

/**
 * Define expected props.
 */
ChampionshipStageEditor.propTypes = {
	stages: PropTypes.array.isRequired,
	options: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

export default ChampionshipStageEditor;