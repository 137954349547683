import React from 'react';
import Img from '../Img/Img';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '../Heading/Heading';
import Countdown from '../Countdown/Countdown';
import MediaQuery from 'react-responsive';
import eSportLogo from '../../assets/images/esports/icon_esportlogo_white.png';

import './EventTypeSelector.scss';

library.add(faArrowCircleRight, faArrowCircleLeft);

/**
 * Async Event Type Selector
 */
class EventTypeSelector extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedChallengeType: 0,
			hideCountdown: false
		}

		this.nextChallenge = this.nextChallenge.bind(this);
		this.prevChallenge = this.prevChallenge.bind(this);
	}

	nextChallenge() {
		if(this.state.selectedChallengeType === this.props.totalChallenges) {
			this.setState({selectedChallengeType: 0});
		} else {
			this.setState({selectedChallengeType: this.state.selectedChallengeType + 1});
		}
	}

	prevChallenge() {
		if(this.state.selectedChallengeType === 0) {
			this.setState({selectedChallengeType: this.props.totalChallenges});
		} else {
			this.setState({selectedChallengeType: this.state.selectedChallengeType - 1});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevState.selectedChallengeType !== newState.selectedChallengeType) {
			this.props.onChange(this.state.selectedChallengeType);
		}

		if(prevProps.challengeType !== newProps.challengeType) {
			this.setState({
				hideCountdown: true
			}, () => {
				setTimeout(() => {
					this.setState({
						hideCountdown: false
					});
				}, 1000);
			});
		}
	}

	render() { 
		const challengeType = this.props.challengeType;
		let eventInfo;
		let country;
		let discipline;
		let bannerWide;
		let bannerWidePh;
		let bannerTablet;
		let bannerTabletPh;
		let bannerMob;
		let bannerMobPh;
		let currentDate;

		if (this.props.overall === false) {
			eventInfo = this.props.challengeType.challengeGroups[this.props.selectedChallengeGroup].challenges[this.props.selectedChallenge];
			country = this.props.challengeType.challengeGroups[this.props.selectedChallengeGroup].challenges[this.props.selectedChallenge].events[this.props.selectedEvent].stages[this.props.selectedStage].country;
			discipline = this.props.challengeType.challengeGroups[this.props.selectedChallengeGroup].challenges[this.props.selectedChallenge].events[this.props.selectedEvent].discipline;
			bannerWide = require('../../assets/images/event-covers/' + discipline + '/' + country + "_wide.jpg");
			bannerWidePh = require('../../assets/images/event-covers/' + discipline + '/' + country + "_wide_ph.jpg");
			bannerTablet = require('../../assets/images/event-covers/' + discipline + '/' + country + "_1200.jpg");
			bannerTabletPh = require('../../assets/images/event-covers/' + discipline + '/' + country + "_1200_ph.jpg");
			bannerMob = require('../../assets/images/event-covers/' + discipline + '/' + country + "_mob.jpg");
			bannerMobPh = require('../../assets/images/event-covers/' + discipline + '/' + country + "_mob_ph.jpg");
			currentDate = new Date();
			currentDate = currentDate.toISOString();
		} else {
			bannerWide = require('../../assets/images/event-covers/eRally/communityevents_wide.jpg');
			bannerWidePh = require('../../assets/images/event-covers/eRally/communityevents_wide_ph.jpg');
			bannerTablet = require('../../assets/images/event-covers/eRally/communityevents_1200.jpg');
			bannerTabletPh = require('../../assets/images/event-covers/eRally/communityevents_1200_ph.jpg');
			bannerMob = require('../../assets/images/event-covers/eRally/communityevents_mob.jpg');
			bannerMobPh = require('../../assets/images/event-covers/eRally/communityevents_mob_ph.jpg');
		}

		return (
			<div className={"EventTypeSelector " + (this.props.overall ? "overall" : "")}>

				<MediaQuery query="(min-width: 1201px)">
					<Img image={bannerWide} placeholder={bannerWidePh} width="100%" height="auto" alt="Community Events" />  
				</MediaQuery>
				<MediaQuery query="(min-width: 768px) and (max-width: 1200px)">
					<Img image={bannerTablet} placeholder={bannerTabletPh} width="100%" height="auto" alt="Community Events" />  
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img image={bannerMob} placeholder={bannerMobPh} className="HeroBanner__bg--mobile" />
				</MediaQuery>

				{ !this.props.overall &&  eventInfo.isDirtPlus &&
					<img src={"/images/dlc_bg_s" + eventInfo.seasonNumber + ".png"} alt={"Dirt Plus - Season" + eventInfo.seasonNumber +  "Content"} className="EventTypeSelector__dlc" /> 
				}

				<div className="EventTypeSelector__overlay">	
					{!this.props.esport &&
						<Icon name="icon-community-events" size="185" style={{marginTop: "20px", marginBottom: "-40px"}} />
					}

					{this.props.esport &&
						<Img image={eSportLogo} placeholder={eSportLogo} width="125px" className="m-t-15" />
					}

					<div className="EventTypeSelector__selector">
						<div className="EventTypeSelector__prev" onClick={this.prevChallenge}>
							<FontAwesomeIcon icon="arrow-circle-left" color="#FFF" className="fa-2x" />
						</div>
						<div className="EventTypeSelector__heading">
							<Heading rank="2">{challengeType.typeName}</Heading> 
						</div>
						<div className="EventTypeSelector__next" onClick={this.nextChallenge}>
							<FontAwesomeIcon icon="arrow-circle-right" color="#FFF" className="fa-2x" />
						</div>
					</div>

					{!this.props.overall &&
						<div className="EventTypeSelector__details">
							<div className="EventTypeSelector__discipline">
								<Icon name={"icon-" + discipline} size="102" />
							</div>
							<div className="EventTypeSelector__vehicles">
								<Icon name={"icon-" + eventInfo.vehicleClass} size="102" style={{marginBottom: "10px"}} />
							</div>
							<div className="EventTypeSelector__timer" style={{display: (!this.state.hideCountdown ? "inline-block" : "none")}}>
								{currentDate < eventInfo.entryWindow.close &&
									<React.Fragment>
										<Icon name="icon-timer" size="42" style={{marginRight: "-40px", display: "inline"}} />
										<Countdown date={eventInfo.entryWindow.close} />
									</React.Fragment>
								}
							</div>
						</div>
					}

				</div>
			</div>
		);
	};
}

EventTypeSelector.defaultProps = {
	overall: false
};

/**
 * Define expected props.
 */
EventTypeSelector.propTypes = {
	challengeType: PropTypes.object.isRequired,
	selectedChallengeGroup: PropTypes.number.isRequired,
	selectedChallenge: PropTypes.number.isRequired,
	selectedEvent: PropTypes.number.isRequired,
	selectedStage: PropTypes.number.isRequired,
	totalChallenges: PropTypes.number.isRequired,
	overall: PropTypes.bool,
	onChange: PropTypes.func,
}

 
export default EventTypeSelector;