import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestClubResults() {
    return {
        type: actionTypes.REQUEST_CLUBRESULTS
    };
}

export function receivedClubResultsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CLUBRESULTS_SUCCESS,
        data
    };
}

export function receivedClubResultsFailure(error) {
    return {
        type: actionTypes.RECEIVED_CLUBRESULTS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Club
 */
export function fetchClubResults(clubId) {
    return function (dispatch) {
        dispatch(requestClubResults());

        axiosFactory.getApiInstance().get('/api/Club/' + clubId + '/recentResults')
            .then((resp) => {
                dispatch(receivedClubResultsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedClubResultsFailure(error));
            });
    };
}