import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFriends } from '../../actions/Clubs/GetFriends';
import { sendInviteFriends } from '../../actions/Clubs/InviteFriends';
import {Portal} from 'react-portal';
import {Col, Row, Input, Label, InputGroup, InputGroupAddon} from 'reactstrap';
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';
import DialogPopup from '../DialogPopup/DialogPopup';

import './InviteMembers.scss';

library.add(faPlusCircle);

/**
 * Invite Members
 */
class InviteMembers extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			availableFriends: [],
			friends: [],
			friend: null,
			invitesSent: false
		}

		this.toggleModal = this.toggleModal.bind(this);
		this.addFriend = this.addFriend.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.sendInvites = this.sendInvites.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
	}

	componentDidMount() {
		this.props.fetchFriends(this.props.clubId);
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.friends !== newProps.friends) {
			if(this.props.friends.isReady()) {
				this.setState({
					friend: this.props.friends.value[0],
					availableFriends: this.props.friends.value
				});
			}
		}

		if(prevProps.inviteFriends !== newProps.inviteFriends) {
			if(this.props.inviteFriends.isReady()) {
				this.setState({
					friends: [],
					isOpen: false
				});
			} else if(this.props.inviteFriends.isFaulted()) {
				this.setState({
					invitesSent: "error"
				});
			}
		}
	}

	toggleModal() {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	closeDialog() {
		this.setState({
			isOpen: false,
			invitesSent: false
		})
	}

	handleChange(e) {
		let value = e.target.value;

		this.setState( prevState => {
			return {
				...prevState,
				friend: this.state.availableFriends[value]
			}
		});
	}

	addFriend() {
		const newFriends = this.state.friends;
		newFriends.push(this.state.friend);

		this.setState( prevState => {
			return {
				...prevState,
				friends: newFriends
			}
		}, () => {
			let availableFriends = this.state.availableFriends;
			const friend = availableFriends.findIndex(x => x.id === this.state.friend.id);
			availableFriends.splice(friend, 1);

			this.setState( prevState => {
				return {
					...prevState,
					availableFriends: availableFriends,
					friend: availableFriends[0]
				}
			})
		});
	}

	sendInvites() {
		this.props.sendInviteFriends(this.props.clubId, this.state.friends);
	}

	displayModal() {
		if(this.props.friends.isReady()) {
			
			const listFriends = this.state.availableFriends.map((friend, index) =>
				<option key={index} value={index}>{friend.displayName}</option>
			);
			
			return (
				<Portal>
					<div className="InviteMembers">
						<div className="InviteMembers__Modal">
							<Button type="standard" className="InviteMembers__close" onClick={this.toggleModal}>
								<FontAwesomeIcon icon="times" color="#FFF" />
							</Button>

							<Row>
								<Col xs="12">
									<Heading rank="3" className="m-b-25">Invite Friends</Heading>
								</Col>
							</Row>

							{this.state.availableFriends.length > 0 &&
								<React.Fragment>
									<Row>
										<Col lg={{ size: 12 }}>
											<Label className="InviteMembers__label" for="selectFriend">Friends List:</Label>
											<InputGroup>
												<Input className="InviteMembers__selector small" type="select" name="selectFriend" id="selectFriend" onChange={this.handleChange}>
													{listFriends}
												</Input>
												<InputGroupAddon addonType="append"><Button type="standard" size="small" onClick={this.addFriend}>Add</Button></InputGroupAddon>
											</InputGroup>
										</Col>
									</Row>
								</React.Fragment>
							}

							{this.state.availableFriends.length < 1 && this.state.friends.length < 1 &&
								<React.Fragment>
									<Row>
										<Col lg={{ size: 12 }}>
											<div className="InviteMembers__noFriends">
												<p>You have no available friends to invite.</p>
												<p>You can add friends to RaceNet via <a href="https://accounts.codemasters.com/friends" target="_blank" rel="noopener noreferrer">Codemasters Accounts</a>.</p>
											</div>
										</Col>
									</Row>
								</React.Fragment>
							}

							{this.state.friends.length > 0 &&
								<React.Fragment>
									<Row>
										<Col xs="12">
											<div className="InviteMembers__invitees">
												{this.state.friends.map((friend, index) =>
													<div key={index} className="InviteMembers__invitee">{index + 1} - {friend.displayName}</div>
												)}
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs="12" className="m-t-25 text-center"><Button type="standard" block onClick={this.sendInvites}>Send Invites</Button></Col>
									</Row>
								</React.Fragment>
							}
						</div>
					</div>
				</Portal>
			);
		} else {
			return null;
		}
	}

	render() {  
		return (
			<React.Fragment>
				<div className="InviteMembers__InviteButton" onClick={this.toggleModal}>
					<FontAwesomeIcon icon="plus-circle" color="#FFF" />
					Invite Friends
				</div>
				{this.state.isOpen &&
					<React.Fragment>
						{this.displayModal()}
					</React.Fragment>
				}

				{this.state.invitesSent === "sent" && 
					<DialogPopup header="Error" description="An error occured and your friends were not invited." confirmText="Ok" onConfirm={this.closeDialog} />
				}
			</React.Fragment>
		);
	};
}

/*
* Define expected props.
*/
InviteMembers.propTypes = {
	clubId: PropTypes.number.isRequired,
	friends: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchFriends: PropTypes.func.isRequired,
	sendInviteFriends: PropTypes.func.isRequired,
	inviteFriends: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
};

//Connect this container component to the redux store.
export default connect(
    state => { return { 
		friends: state.getFriends.friends, 
		inviteFriends: state.inviteFriends.result
	} }, 
    dispatch => bindActionCreators({ fetchFriends, sendInviteFriends }, dispatch)
)(InviteMembers); 