import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestCurrentStandings() {
    return {
        type: actionTypes.REQUEST_CURRENTSTANDINGS
    };
}

export function receivedCurrentStandingsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CURRENTSTANDINGS_SUCCESS,
        data
    };
}

export function receivedCurrentStandingsFailure(error) {
    return {
        type: actionTypes.RECEIVED_CURRENTSTANDINGS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Current Standings
 */
export function fetchCurrentStandings(clubId, data, standings) {
    return function (dispatch) {
        dispatch(requestCurrentStandings());
        axiosFactory.getApiInstance().get('/api/Club/' + clubId + '/standings/' + standings + '/?page=' + data.page + '&pageLength=' + data.pageLength)
            .then((resp) => {
                dispatch(receivedCurrentStandingsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedCurrentStandingsFailure(error));
            });
    };
}