import * as actionTypes from './ActionTypes';

/**
 * Application Actions
 */

export function setApplicationIsReady() {
    return {
        type: actionTypes.APPLICATION_READY
    };
}