import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Video from '../../components/Video/Video';
import { fetchYouTubePlaylist } from '../../actions/YouTubeActions';
import first from 'lodash/first';


class LatestYoutube extends Component {

    playlistName = "default"; 

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchYouTubePlaylist(this.playlistName);
	}
	
	/**
     * Gets the first / latest video (or undefined if no such video / playlist exists)
     * @param {any} name The name of the playlist the video is in.
     * @param {any} playlists The playlist dictionary.
     */
    latestVideo(name, playlists) {
        if (playlists[name]) {
            return first(playlists[name].value); 
        }    	 
    }

    render() {
        if (this.props.youtube.playlists[this.playlistName].isReady()) {
            const video = this.latestVideo(this.playlistName, this.props.youtube.playlists);
            if (video) {
                return (<Video video={video} />);
            } else {
                return (<span>Failed to load latest video :(</span>);
            }            
        } else if (this.props.youtube.playlists[this.playlistName].isFaulted()) {
            return (<span>Failed to load latest video :(</span>);
        } else /*isIntialising() or isLoading()*/ {
            return (<span>Loading youtube playlist...</span>);
        }    
    }
}

LatestYoutube.propTypes = {
    youtube: PropTypes.shape({
        playlists: PropTypes.object.isRequired
    }),
    fetchYouTubePlaylist: PropTypes.func.isRequired 
};


//Connect this container component to the redux store.
export default connect(
    state => { return { youtube: state.youtube } }, 
    dispatch => bindActionCreators({ fetchYouTubePlaylist }, dispatch)
)(LatestYoutube);
