import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postFriendsInvite() {
    return {
        type: actionTypes.SEND_INVITEFRIENDS
    };
}

export function postFriendsInviteSuccess(data) {
    return {
        type: actionTypes.RECEIVED_FRIENDSINVITE_SUCCESS,
        data
    };
}

export function postFriendsInviteFailure(error) {
    return {
        type: actionTypes.RECEIVED_FRIENDSINVITE_FAILURE,
        data: { error }
    };
}

/***
 * Invite Friends Function
 */
export function sendInviteFriends(clubId, friends) {
    return function (dispatch, getState) {
        dispatch(postFriendsInvite());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/invite/friends', friends)
        .then((resp) => {
			dispatch(postFriendsInviteSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postFriendsInviteFailure(error));
		});
    };
}