import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/** 
 * Rating Logo
 */
class RatingLogo extends React.Component {

    getImageUrl(imageName) {
        return '/images/ratings/' + imageName + ".png";
    }

    render() {
        var { ratingBoard } = this.props.application;
        if (ratingBoard && ratingBoard.imageName) {
            return (
                <a href={ratingBoard.url} target="_blank" rel="noopener noreferrer" className="ratingLogo">
                    <img src={this.getImageUrl(ratingBoard.imageName)} alt="" />
                </a>
            );
        }

        //if we could not resolve a ratings logo, show nothing.
        return null;
    };
}

RatingLogo.propTypes = {
    application: PropTypes.shape({
        ratingBoard: PropTypes.shape({
            imageName: PropTypes.string,
            url: PropTypes.string
        })
    })
};

export default connect(
    state => { return { application: state.application.value } }
)(RatingLogo);