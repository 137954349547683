/**
 * Free Trial
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions/RetailersActions';
import {Helmet} from "react-helmet";
import { Translate } from "react-localize-redux";
import { Container, Row, Col } from 'reactstrap';
import MediaQuery from 'react-responsive';
import Heading from '../../components/Heading/Heading';
import Img from '../../components/Img/Img';

import TrialBanner from '../../components/TrialBanner/TrialBanner';

import ImgTrialSectionOne from '../../assets/images/free_trial_01.jpg';
import ImgTrialSectionOnePlaceholder from '../../assets/images/free_trial_01__placeholder.jpg';
import ImgTrialSectionTwo from '../../assets/images/free_trial_02.jpg';
import ImgTrialSectionTwoPlaceholder from '../../assets/images/free_trial_02__placeholder.jpg';

import ImgTrialFooter from '../../assets/images/free_trial_upgrade.jpg';
import ImgTrialFooterTablet from '../../assets/images/free_trial_upgrade_1200.jpg';
import ImgTrialFooterMobile from '../../assets/images/free_trial_upgrade_mob.jpg';
import ImgTrialFooterPlaceholder from '../../assets/images/free_trial_upgrade_placeholder.jpg';

import '../../assets/scss/trial.scss';

class FreeTrial extends Component {
	
	componentDidMount(){
		this.props.fetchGameTrial()
	}
	
	render () {
	return (
        <main className="FreeTrial" role="main">
            <Translate>
                {({ translate }) => <Helmet><title>{translate("trial.title")} - DiRT Rally 2.0</title></Helmet> }
            </Translate>
            <TrialBanner state={this.props.trialRetailers.state} content={this.props.trialRetailers.value}/>
            <section className="FreeTrial__Section">
                <Container>
                	<Row className="m-b-50">
						<Col xs="12" sm="12" md="12">
							<div className="FreeTrial__Section__Intro">
                                <Heading rank="2" className="FreeTrial__Section__Intro--heading"><Translate id="trial.intro.title">Get your first taste of DiRT Rally 2.0<br/>for free with the Trial Version</Translate></Heading>
                                <p><Translate id="trial.intro.content">This is your chance to experience what it’s like to launch a rally car down a stage, dance on the edge of control and overcome the challenges waiting around every corner; or go head-to-head with other drivers in the fast and frenetic world of rallycross.<br/><br/>We’ve worked with our community of players, drivers, team members and car manufacturers to bring you our take on two flavours of the sport we love. Whether you’re aiming to prove yourself as one of the fastest drivers in the world or simply explore rallying’s rich history, there’s something here for you.</Translate></p>
							</div>
						</Col>
					</Row>
                    <Row className="m-b-50">
						<Col xs="12" sm="8" md="6" className="FreeTrial__Section__Text--Col">
							<div className="FreeTrial__Section__Text">
                                <Heading rank="2"><Translate id="trial.section1.title">Welcome to Rally</Translate></Heading>
                                <p><Translate id="trial.section1.content">Get your first taste of rallying by competing in the H1 Historic Trophy. This series features rally cars from the 1960s: the MINI Cooper S, DS21 and the Lancia Fulvia HF. In these events you’ll visit the smooth tarmac of Ribadelles in Spain and the stunning vistas of Hawkes Bay in New Zealand.</Translate></p>
							</div>
						</Col>
						<Col xs="12" sm="4" md="6" className="FreeTrial__Section__Image">
							<Img image={ImgTrialSectionOne} placeholder={ImgTrialSectionOnePlaceholder} width="100%" height="auto" alt="" />  
						</Col>
					</Row>
					<Row className="m-b-50">
						<MediaQuery query="(min-width: 768px)">
							<Col xs="12" sm="4" md="6" className="FreeTrial__Section__Image">
								<Img image={ImgTrialSectionTwo} placeholder={ImgTrialSectionTwoPlaceholder} width="100%" height="auto" alt="" className="m-b-15" />  
							</Col>
							<Col xs="12" sm="8" md="6" className="FreeTrial__Section__Text--Col">
								<div className="FreeTrial__Section__Text">
                                    <Heading rank="2"><Translate id="trial.section2.title">Welcome to Rally</Translate></Heading>
                                    <p><Translate id="trial.section2.content">Get your first taste of rallying by competing in the H1 Historic Trophy. This series features rally cars from the 1960s: the MINI Cooper S, DS21 and the Lancia Fulvia HF. In these events you’ll visit the smooth tarmac of Ribadelles in Spain and the stunning vistas of Hawkes Bay in New Zealand.</Translate></p>
								</div>
							</Col>
						</MediaQuery>
						<MediaQuery query="(max-width: 767px)">
							<Col xs="12" sm="8" md="6" className="FreeTrial__Section__Text--Col">
								<div className="FreeTrial__Section__Text">
                                    <Heading rank="2"><Translate id="trial.section2.title">Welcome to Rally</Translate></Heading>
                                    <p><Translate id="trial.section2.content">Get your first taste of rallying by competing in the H1 Historic Trophy. This series features rally cars from the 1960s: the MINI Cooper S, DS21 and the Lancia Fulvia HF. In these events you’ll visit the smooth tarmac of Ribadelles in Spain and the stunning vistas of Hawkes Bay in New Zealand.</Translate></p>
								</div>
							</Col>
							<Col xs="12" sm="4" md="6" className="FreeTrial__Section__Image">
								<Img image={ImgTrialSectionTwo} placeholder={ImgTrialSectionTwoPlaceholder} width="100%" height="auto" alt="" className="m-b-15" />  
							</Col>
						</MediaQuery>
					</Row>
				</Container>
            </section>
			<div className="FreeTrialFooter">
				<MediaQuery query="(min-width: 1200px)">
					<Img image={ImgTrialFooter} placeholder={ImgTrialFooterPlaceholder} className="FreeTrialFooter__img--desktop" /> 
				</MediaQuery>
				<MediaQuery query="(min-width: 768px) and (max-width: 1199px)">
					<Img image={ImgTrialFooterTablet} placeholder={ImgTrialFooterPlaceholder} className="FreeTrialFooter__img--desktop" /> 
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img image={ImgTrialFooterMobile} placeholder={ImgTrialFooterPlaceholder} className="FreeTrialFooter__img--mobile" />  
				</MediaQuery>
				<div className="FreeTrialFooter__Content">
					<Heading rank="2"><Translate id="trial.section3.title">Upgrade to the full version of DiRT Rally 2.0</Translate></Heading>
                    <p><Translate id="trial.section3.content">
						This is your chance to experience what it’s like to launch a rally car down a stage, dance on the edge of control and overcome the challenges waiting around every corner; or go head-to-head with other drivers in the fast and frenetic world of rallycross.
						<br/>
						<br/>
						We’ve worked with our community of players, drivers, team members and car manufacturers to bring you our take on two flavours of the sport we love. Whether you’re aiming to prove yourself as one of the fastest drivers in the world or simply explore rallying’s rich history, there’s something here for you.
					</Translate></p>
				</div>		
			</div>
        </main>
	)
	}
}

export default connect(
    state => { return { trialRetailers: state.retailers.trialRetailers }; },
    dispatch => bindActionCreators({ ...actions }, dispatch)
)(FreeTrial);