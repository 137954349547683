import React from 'react';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '../Heading/Heading';

import './ChallengeSelector.scss';

library.add(faArrowCircleRight, faArrowCircleLeft);

/**
 * Challenge Selector
 */
class ChallengeSelector extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedChallenge: this.props.selectedChallenge
		}

		this.nextChallenge = this.nextChallenge.bind(this);
		this.prevChallenge = this.prevChallenge.bind(this);
	}

	
	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevProps.selectedChallenge !== newProps.selectedChallenge) {
			this.setState({
				selectedChallenge: this.props.selectedChallenge
			});
		}

		if(prevState.selectedChallenge !== newState.selectedChallenge) {
			this.props.onChange(this.state.selectedChallenge);
		}
	}

	prevChallenge() {
		if(this.state.selectedChallenge !== 0) {
			this.setState({selectedChallenge: this.state.selectedChallenge - 1});
		}
	}

	nextChallenge() {
		if(this.state.selectedChallenge !== this.props.totalChallenges) {
			this.setState({selectedChallenge: this.state.selectedChallenge + 1});
		}
	}

	render() { 
		if(this.props.totalChallenges > 0) {
			return (
				<div className="ChallengeSelector">
					<div className={"ChallengeSelector__prev " +  (this.state.selectedChallenge !== 0 ? "" : "is-disabled")} onClick={this.prevChallenge}>
						<FontAwesomeIcon icon="arrow-circle-left" color="#FFF" className="fa-2x" />
					</div>
					<div className="ChallengeSelector__heading">
						<Heading rank="2">{this.props.description}</Heading> 
					</div>
					<div className={"ChallengeSelector__next " +  (this.state.selectedChallenge !== this.props.totalChallenges ? "" : "is-disabled")} onClick={this.nextChallenge}>
						<FontAwesomeIcon icon="arrow-circle-right" color="#FFF" className="fa-2x" />
					</div>
				</div>
			);
		} else {
			return (
				<div className="ChallengeSelector">
					<div className="ChallengeSelector__heading">
						<Heading rank="2">{this.props.description}</Heading> 
					</div>
				</div>
			);
		}
	};
}

/**
 * Define expected props.
 */
ChallengeSelector.propTypes = {
	selectedChallenge: PropTypes.number.isRequired,
	totalChallenges: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	description: PropTypes.string.isRequired
}

 
export default ChallengeSelector;