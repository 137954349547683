import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import { Translate } from "react-localize-redux";
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEvents } from '../../actions/EventActions';
import { getLeaderboard } from '../../actions/LeaderboardActions';
import EventTypeSelector from '../EventTypeSelector/EventTypeSelector';
import Tabs from '../Tabs/Tabs';
import Leaderboard from '../Leaderboard/Leaderboard';
import EventDropdownMenu from '../EventDropdownMenu/EventDropdownMenu';
import Loader from '../Loader/Loader';
import MediaQuery from 'react-responsive';
import ChallengeSelector from '../ChallengeSelector/ChallengeSelector';

import './CommunityEvents.scss'

/** 
 * Community Events
 */
class CommunityEvents extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			selected: {
				challengeType: 0,
				challengeGroup: 0,
				challenge: 0,
				event: 0,
				stage: 0,
			},
			leaderboard: {
				challengeId: 0,
				selectedEventId: 0,
				stageId: 0,
				page: 1,
				pageSize: 10,
				orderByTotalTime: true,
				platformFilter: "None",
				playerFilter: "Everyone",
				filterByAssists: "Unspecified",
				filterByWheel: "Unspecified",
				nationalityFilter: "None"
			}
		}

		this.updateChallengeType = this.updateChallengeType.bind(this);
		this.updateChallengeGroup = this.updateChallengeGroup.bind(this);
		this.updateChallenge = this.updateChallenge.bind(this);
		this.updateEvent = this.updateEvent.bind(this);
		this.updateStage = this.updateStage.bind(this);
		this.updateLeaderboard = this.updateLeaderboard.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchEvents();
	}

	componentDidUpdate(oldProps, prevState) {
		const newProps = this.props;
		const newState = this.state;

		if(oldProps.challenges.state !== newProps.challenges.state) {
			if (this.props.challenges.isReady()) {
				this.setState( prevState => {
					return {
						leaderboard: {
							...prevState.leaderboard, 
							challengeId: this.props.challenges.value[0].challengeGroups[0].challenges[0].id,
							eventId: this.props.challenges.value[0].challengeGroups[0].challenges[0].events[0].id,
							stageId: this.props.challenges.value[0].challengeGroups[0].challenges[0].events[0].stages[0].id,
						}
					}
				}, () => this.props.getLeaderboard(this.state.leaderboard));
			}
		}

		if(prevState.selected !== newState.selected) {
			this.setState( prevState => {
				return {
					leaderboard: {
						...prevState.leaderboard, 
						challengeId: this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].id,
						eventId: this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].id,
						stageId: this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].stages[this.state.selected.stage].id,
					}
				}
			}, () => this.props.getLeaderboard(this.state.leaderboard));
		}
	}

	updateChallengeType(type) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challengeType: type,
					challengeGroup: 0,
					challenge: 0,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateChallengeGroup(group) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challengeGroup: group,
					challenge: 0,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateChallenge(challenge) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challenge: challenge,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateEvent(event) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					event: event,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateStage(stage) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					stage: stage
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}
	
	updateLeaderboard(options) {
		this.setState( prevState => {
			return {
				leaderboard: {
					...prevState.leaderboard,
					platformFilter: options.platformFilter,
					playerFilter: options.playerFilter,
					filterByAssists: options.filterByAssists,
					filterByWheel: options.filterByWheel,
					nationalityFilter: options.nationalityFilter,
					page: options.page,
					orderByTotalTime: options.orderByTotalTime
				}
			}
		}, () => this.props.getLeaderboard(this.state.leaderboard));
	}

	render() {

        if (this.props.challenges.isReady()) {			
            return (
				<div className="CommunityEvents">
					<EventTypeSelector challengeType={this.props.challenges.value[this.state.selected.challengeType]} selectedChallengeGroup={this.state.selected.challengeGroup} selectedChallenge={this.state.selected.challenge} selectedEvent={this.state.selected.event} selectedStage={this.state.selected.stage} totalChallenges={this.props.challenges.value.length - 1} onChange={this.updateChallengeType} />
					{this.props.challenges.value.length &&
						<React.Fragment>

							<MediaQuery query="(min-width: 769px)">
								<Tabs tabs={this.props.challenges.value[this.state.selected.challengeType].challengeGroups} onChange={this.updateChallengeGroup} />
							</MediaQuery>

							<MediaQuery query="(min-width: 769px)">
								<Container>
									<Row noGutters={true} className="CommunityEvents__EventSelectors">
										<MediaQuery query="(max-width: 768px)">
											<Col xs="12">
												<EventDropdownMenu type="challengeGroup" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups} onChange={this.updateChallengeGroup} />
											</Col>
										</MediaQuery>
										<Col xs="12">
											<ChallengeSelector selectedChallenge={this.state.selected.challenge} totalChallenges={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges.length - 1} onChange={this.updateChallenge} description="Select Challenge" />
										</Col>
										<Col xs="12" md={{ size: 6 }} lg={{size: 4, offset: 2}}>
											<EventDropdownMenu type="event" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events} onChange={this.updateEvent} />
										</Col>
										<Col xs="12" md={{ size: 6 }} lg={{size: 4}}> 
											<EventDropdownMenu type="stages" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].stages} onChange={this.updateStage} />
										</Col>
									</Row>		
									<Row>
										<Col xs="12">
											<Leaderboard data={this.props.leaderboard} onChange={this.updateLeaderboard} order={this.state.leaderboard.orderByTotalTime} />
										</Col>
									</Row>
								</Container>
							</MediaQuery>

							<MediaQuery query="(max-width: 768px)">
								<div className="CommunityEvents__EventSelectors">
									<ChallengeSelector selectedChallenge={this.state.selected.challenge} totalChallenges={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges.length - 1} onChange={this.updateChallenge} description="Select Challenge" />
									<EventDropdownMenu type="challengeGroup" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups} onChange={this.updateChallengeGroup} />
									<EventDropdownMenu type="event" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events} onChange={this.updateEvent} />
									<EventDropdownMenu type="stages" options={this.props.challenges.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].stages} onChange={this.updateStage} />
								</div>		
								<Leaderboard data={this.props.leaderboard} onChange={this.updateLeaderboard} />
							</MediaQuery>
						</React.Fragment>
					}


				</div>
			);
        } else if (this.props.challenges.isFaulted()) {
            return (<Loader currentState={this.props.challenges.state} errorMessage="Failed to load community events" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.challenges.state} />);
        }    
    }
}

/**
 * Define expected props.
 */
CommunityEvents.propTypes = {
    challenges: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	leaderboard: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
    }),
	fetchEvents: PropTypes.func.isRequired,
	getLeaderboard: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { challenges: state.challenges.events, leaderboard: state.challenge.leaderboard, application: state.application.value } }, 
    dispatch => bindActionCreators({ fetchEvents, getLeaderboard }, dispatch)
)(CommunityEvents); 