/**
 * My Clubs Page
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import NoGameDataCheck from '../../containers/NoGameDataCheck';
import CreateClubActionBanner from '../../components/CreateClubActionBanner/CreateClubActionBanner';
import ClubManagement from '../../components/ClubManagement/ClubManagement';
import Heading from '../../components/Heading/Heading';

import '../../assets/scss/clubs.scss';

class MyClubs extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			page: (props.match.params.page ? parseInt(props.match.params.page) : 1)
		};
	}

	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.match.params.page !== newProps.match.params.page) {
			this.setState({
				page: (this.props.match.params.page ? parseInt(this.props.match.params.page) : 1)
			});
		}
	}

	render() {
		return (
			<NoGameDataCheck>
				<main className="FindClub" role="main">
					<div className="FindClub__Search">
						<Row>
							<Col xs="12" className="text-center">
								<Heading rank="3" className="m-t-50">My Clubs</Heading>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<ClubManagement page={this.state.page} />
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<CreateClubActionBanner />
							</Col>
						</Row>
					</div>
				</main>
			</NoGameDataCheck>
		);
	}
}

export default MyClubs;