import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function CancelFutureChampionship() {
    return {
        type: actionTypes.SEND_CANCEL_FUTURE_CHAMPIONSHIP
    };
}

export function receivedCancelFutureChampionshipSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_SUCCESS,
        data
    };
}

export function receivedCancelFutureChampionshipFailure(error) {
    return {
        type: actionTypes.RECEIVED_CANCEL_FUTURE_CHAMPIONSHIP_FAILURE,
        data: { error }
    };
}

/***
 * Cancel Future Championship Function
 */
export function sendCancelFutureChampionship(cludId) {
    return function (dispatch) {
        dispatch(CancelFutureChampionship());
        axiosFactory.getApiInstance().delete('/api/Club/' + cludId + '/championship')
            .then((resp) => {
                dispatch(receivedCancelFutureChampionshipSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedCancelFutureChampionshipFailure(error));
            });
    };
}