import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../Img/Img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './GalleryWidget.scss';

const setSizeClass = (size) => {
    switch(size) {
        case 'small':
            return 'GalleryWidget__Item--small'
        case 'medium':
            return 'GalleryWidget__Item--medium'
        case 'large':
         return 'GalleryWidget__Item--large'
                
    }
}

export default function GalleryWidget(props) {
    const {images, size} = props;

    return (
        <div className="GalleryWidget">
            {props.images.length > 0 && 
                images.map((img, index) => {
                    return <div key={`gallery${index}`} className={`GalleryWidget__Item ${setSizeClass(size)}`}>
                        <a href={img.url} target="_blank"><Img image={`${img.url}?w=600`} placeholder={`${img.url}?w=50`} alt={img.description ? img.description : ''}/></a>
                        <div className="GalleryWidget__HoverIcon">
                                <a href={img.url} target="_blank">
                                    <FontAwesomeIcon icon="search-plus" color="#FFF" size="6x"/>
                                </a>
                            </div>
                        </div>
                })
            }
        </div>
    )
}

GalleryWidget.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    size: PropTypes.oneOf(['small','medium','large'])
};

GalleryWidget.defaultProps = {
    size: 'medium'
}

