import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMyClubs } from '../../actions/Clubs/MyClubs';
import Loader from '../Loader/Loader';
import {Container, Row, Col} from 'reactstrap';
import ClubTile from '../ClubTile/ClubTile';
import Pagination from '../Pagination/Pagination';
import Heading from '../Heading/Heading';
import { push } from 'connected-react-router';

import './ClubManagement.scss';

/** 
 * Club Management
 */
class ClubManagement extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			pageSize: 10
		}

		this.updatePage = this.updatePage.bind(this);
	}

	componentDidMount() {
		this.props.fetchMyClubs(this.props.page, this.state.pageSize); //todo: use paging.
	}

	componentDidUpdate(prevProps, prevState) {
		const newState = this.state;
		const newProps = this.props;

		if(prevState !== newState) {
			this.props.fetchMyClubs(this.props.page, this.state.pageSize); //todo: use paging.
		}

		if(prevProps.page !== newProps.page) {
			this.props.fetchMyClubs(this.props.page, this.state.pageSize); //todo: use paging.
		}
	}

	updatePage(page) {
		this.props.push('/clubs/my-clubs/page/' + page);
	}

	renderResults() { 
		if (this.props.clubs.isReady()) {
			const pageCount = this.props.clubs.value.pageCount; //todo: use paging.
			const clubs = this.props.clubs.value.clubs;

			return (
				<Container>
					<Row>

						{clubs.length > 0 &&
							<React.Fragment>
								{clubs.map((club, index) => 
									<Col xs="12" md="6" key={index}><ClubTile club={club} /></Col>
								)}
							</React.Fragment>
						}

						{clubs.length < 1 &&
							<Col xs="12">
								<div className="ClubManagement__NoResults">
									<Heading rank="3" className="m-b-10">No Clubs Found</Heading>
									<p>You are not a member of any clubs.</p>
								</div>
							</Col>
						}
					</Row>
					{pageCount > 1 &&
						<Row>
							<Col xs="12">
								<div className="ClubManagement__Pagination">
									<Pagination activePage={this.props.page} pageSize={pageCount} onChange={this.updatePage} />
								</div>
							</Col>
						</Row>
					}
				</Container>
			);
		} else if (this.props.clubs.isFaulted()) {
			return (<Loader currentState={this.props.clubs.state} errorMessage="Failed to load clubs" />);
		} else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubs.state} />);
		}    
	};

	render() { 
		return (
			<div className="ClubManagement">
				<div className="ClubManagement__Results">
					{this.renderResults()}
				</div>
			</div>
		);
	};
}


/**
 * Define expected props.
 */
ClubManagement.propTypes = {
	clubs: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchMyClubs: PropTypes.func.isRequired,
	push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { clubs: state.myClubs.clubs  } }, 
    dispatch => bindActionCreators({ fetchMyClubs, push }, dispatch)
)(ClubManagement); 