/**
 * Home Page
 */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import { Helmet } from "react-helmet";
import { Translate } from "react-localize-redux";
import Heading from '../../components/Heading/Heading';
import Img from '../../components/Img/Img';
import LatestNews from '../../containers/Redux/LatestNews';
import LatestYoutube from '../../containers/Redux/LatestYouTube';
import PreorderAdvert from '../../components/PreorderAdvert/PreorderAdvert';

import PopoutBanner from '../../components/PopoutBanner/PopoutBanner';
import featureOneImg from '../../assets/images/featureOne.jpg';
import featureOnePlaceholder from '../../assets/images/featureOne_placeholder.jpg';
import featureTwoImg from '../../assets/images/featureTwo.jpg';
import featureTwoPlaceholder from '../../assets/images/featureTwo_placeholder.jpg';
import featureThreeImg from '../../assets/images/featureThree.jpg';
import featureThreePlaceholder from '../../assets/images/featureThree_placeholder.jpg';

import '../../assets/scss/index.scss';

export default function Home() {
    return (
        <main className="Home" role="main">
            <Translate>
                {({ translate }) =>
                    <Helmet>
                        <title>DiRT Rally 2.0</title>
                        <link rel="canonical" href="http://www.dirtrally2.com/" />
                        <meta name="description" content={translate("meta.description")} />
                        <meta property="og:description" content={translate("meta.description")} />
                        <meta name="twitter:description" content={translate("meta.description")} />
                        <meta name="keywords" content="dirt, dirt rally, dirt rally 2.0, colin mcrae, colin mcrae rally, codemasters, racing, codemasters rally games, racing games, sim racing, xbox racing games, ps4 racing games, steam racing games, rally, rallycross, fia world rallycross championship, rallycross game, official rallycross game, dirt rally vr, vr racing games, oculus racing games, dirt rally oculus" />
                    </Helmet>
                }
            </Translate>
            <HeroBanner />
            <section className="Home__MainFeatures">
				<Container>
					<Row>
                        <Col xs="12" className="m-b-25">
                            <Heading rank="2"><Translate id="home.riseToTheChallenge">Rise to the Challenge</Translate></Heading>
                            <p><Translate id="home.riseToTheChallengeBody">DiRT Rally 2.0 dares you to carve your way through a selection of iconic rally locations from across the globe, in the most powerful off-road vehicles ever made, knowing that the smallest mistake could end your stage.</Translate></p>
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="4">
							<Img image={featureOneImg} placeholder={featureOnePlaceholder} width="100%" height="auto" alt="" className="m-b-10" /> 
                            <p><Translate id="home.riseToTheChallengeF1">The most immersive and truly focused off-road experience ever</Translate></p>
						</Col>
						<Col xs="12" sm="4">
							<Img image={featureTwoImg} placeholder={featureTwoPlaceholder} width="100%" height="auto" alt="" className="m-b-10" />  
                            <p><Translate id="home.riseToTheChallengeF2">Stunning new locations and vehicles, as well as the return of fan favourites</Translate></p>
						</Col>
						<Col xs="12" sm="4">
							<Img image={featureThreeImg} placeholder={featureThreePlaceholder} width="100%" height="auto" alt="" className="m-b-10" />  
                            <p><Translate id="home.riseToTheChallengeF3">Lots more rally and rallycross content to come in DiRT Seasons</Translate></p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="Home__RallycrossAdvert">
				<PopoutBanner />
			</section>
			<section className="Home__LatestMedia">
				<Container>
					<Row>
						<Col xs="12" sm="12" md="6" className="m-b-15">
							<LatestYoutube />
						</Col>
						<Col xs="12" sm="12" md="6">
							<LatestNews maxItemsToRender={1} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className="Home__PreorderAdvert">
				<PreorderAdvert />
			</section>
        </main>
    );
}