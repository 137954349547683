import * as actionTypes from './ActionTypes';
import axiosFactory from '../models/AxiosFactory';

function requestNewsFeed() {
    return {
        type: actionTypes.REQUEST_NEWS_FEED
    };
}

function receivedNewsFeedSuccess(data) {
    return {
        type: actionTypes.RECEIVED_NEWS_FEED_SUCCESS,
        data
    };
}

function receivedNewsFeedFailure(error) {
    return {
        type: actionTypes.RECEIVED_NEWS_FEED_FAILURE,
        data: { error }
    };
}

/***
 * Fetch the news feed. 
 */
export function fetchLatestNews() {
    return function (dispatch, getState) {
        dispatch(requestNewsFeed());
        axiosFactory.getApiInstance().get('/api/news/')
            .then((resp) => { dispatch(receivedNewsFeedSuccess(resp.data)); })
            .catch((error) => { dispatch(receivedNewsFeedFailure(error)); });
    };
};

function requestNewsPost(url) {
    return {
        type: actionTypes.REQUEST_NEWS_POST,
        data: {url}
    };
}

function receivedNewsPostSuccess(id, post) {
    return {
        type: actionTypes.RECEIVED_NEWS_POST_SUCCESS,
        data: {id, post}
    };
}

function receivedNewsPostFailure(id, error) {
    return {
        type: actionTypes.RECEIVED_NEWS_POST_FAILURE,
        data: { id, error }
    };
}

/***
 * Fetches a specific news item / post.
 */
export function fetchNewsPost(url) {
    return function (dispatch, getState) {
        //dont bother requesting something we have already requested / got.
        if (getState().news.posts[url]) {
            return;
        }

        dispatch(requestNewsPost(url));
        axiosFactory.getApiInstance().get('/api/news/' + url)
            .then((resp) => { dispatch(receivedNewsPostSuccess(url, resp.data)); })
            .catch((error) => { dispatch(receivedNewsPostFailure(url, error)); });
    };
};



