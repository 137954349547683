import React from 'react';
import { Translate } from "react-localize-redux";
import MediaQuery from 'react-responsive';
import Img from '../Img/Img';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import PreorderBg from '../../assets/images/preorder_bg.jpg';
import PreorderBgMobile from '../../assets/images/preorder_bg_mobile.jpg';
import PreorderBgPlaceholder from '../../assets/images/preorder_bg_placeholder.jpg';
import packshotImage from '../../assets/images/packshots.png';
import packshotPlaceholder from '../../assets/images/packshots_placeholder.png';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import './PreorderAdvert.scss';

/** 
 * Preorder Advert
 */
class PreorderAdvert extends React.Component {

	constructor(props) {
		super(props);

		this.goToBuyNow = this.goToBuyNow.bind(this);
	}

	goToBuyNow() {
		this.props.push('/buy');
	}
	
	render() { 

		return (
			<div className="PreorderAdvert">
				<MediaQuery query="(min-width: 768px)">
					<Img 
						image={PreorderBg} 
						placeholder={PreorderBgPlaceholder} 
						width="100%"
						height="100%"
						className="PreorderAdvert__bg"
					 />
				</MediaQuery>
				<MediaQuery query="(max-width: 767px)">
					<Img 
						image={PreorderBgMobile} 
						placeholder={PreorderBgPlaceholder} 
						className="PreorderAdvert__bg" 
					/>
				</MediaQuery>
				<div className="PreorderAdvert__packshots">
					<Img image={packshotImage} placeholder={packshotPlaceholder} width="460px" height="auto" alt="Dirt Rally 2.0 Packshots" className="m-b-15" />  
				</div>
				<div className="PreorderAdvert__content">
                    <Heading rank="3"><Translate id="home.riseToTheChallenge">Rise to the Challenge</Translate></Heading>
                    <p className="PreorderAdvert__description"><Translate id="preOrderBanner.body">DiRT Rally 2.0&comma; available now on PlayStation 4&comma; Xbox One and PC.</Translate></p>
                    <Button type="standard" size="small" onClick={this.goToBuyNow}><Translate id="general.preOrderNow">Buy Now</Translate></Button>
				</div>
			</div>
		);
	};
}

PreorderAdvert.propTypes = {
    push: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => bindActionCreators({push}, dispatch)
 )(PreorderAdvert);