import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import Img from '../Img/Img';
import Button from '../Button/Button';
import Countdown from '../Countdown/Countdown';
import {Row, Col} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip  } from 'reactstrap';
import moment from 'moment';
import Guid from 'guid';
import Icon from '../Icon/Icon';
import './EventTile.scss';

library.add(faHourglassHalf);

/** 
 * Club Tile
 */
class EventTile extends React.Component {

	constructor(props) {
		super(props);

		this.goToEvent = this.goToEvent.bind(this);
    }

	addLeadingZeros(value) {
		value = String(value);
		while (value.length < 2) {
		  value = '0' + value;
		}
		return value;
	}
	
	goToEvent() {
		this.props.push('/clubs/club/' + this.props.clubId + '/results/championship/' + this.props.championshipId + '/event/' + this.props.eventNumber );
	}

	getEventLength(start, end) {
		var b = moment(start);
		var a = moment(end);

		if(a.diff(b, 'days') > 0) {
			return a.diff(b, 'days') + " Days";
		} else if(a.diff(b, 'hours') > 0) {
			return a.diff(b, 'hours') + " Hours";
		} else if(a.diff(b, 'minutes') > 0) {
			return a.diff(b, 'minutes') + " Mins";
		}
    }

	render() { 
		const event = this.props.event;
        const guid = Guid.create();
 
        let locationImg = '';
        let locationPhImg = '';

        try {
            locationImg = require("../../assets/images/locations/" + event.locationId + ".jpg");
            locationPhImg = require("../../assets/images/locations/" + event.locationId + "_ph.jpg");
        } catch{
            locationImg = require("../../assets/images/locations/ePlaceholder.jpg");
            locationPhImg = require("../../assets/images/locations/ePlaceholder_ph.jpg");
        }

		return (
			<div className={"EventTile " + (this.props.upcoming ? "is-upcoming" : "")}>
				<Img image={locationImg} placeholder={locationPhImg} width="100%" height="auto" alt={event.locationName} />

				<div className="EventTile__Overlay">
					<div className="EventTile__Location">
						<img src={"/images/flags/" + event.locationId + ".jpg"} className="EventTile__Flag" alt={event.locationName} />
                        <div className="EventTile__LocationName">
							{event.locationName}<br />
							<span>{event.countryName}</span>
						</div>
                    </div>
                    <div className="EventTile__VehicleClass" >
                        <Icon name={"icon-" + event.vehicleClass}  size="64" />
                    </div>
					<div className="EventTile__Number">
						{this.addLeadingZeros(this.props.eventNumber + 1)}
					</div>
				</div>

				{event.isDlc && this.props.upcoming &&
					<React.Fragment>
						<div className="EventTile__DLC" id={"hasDLCContent__" + guid}></div>
						<UncontrolledTooltip placement="bottom" target={"hasDLCContent__" + guid}>
							This event contains downloadable content, some club members may have restricted participation.
						</UncontrolledTooltip>
					</React.Fragment>
				}

				{this.props.upcoming &&
					<React.Fragment>
						<div className="EventTile__Length" id={"EventDuration__" + guid}>
							{this.getEventLength(event.entryWindow.start, event.entryWindow.end)}
						</div>
						<UncontrolledTooltip placement="bottom" target={"EventDuration__" + guid}>
							Event Duration
						</UncontrolledTooltip>
					</React.Fragment>
				}

				{!this.props.upcoming &&
					<div className="EventTile__Hover">
						<Button onClick={this.goToEvent}>View Results</Button>
					</div>
				}

				{event.eventStatus === "Completed" && event.eventTime !== "" &&
					<div className="EventTile__Completed">
						<div className="float-left text-left">Completed</div>
						<div className="float-right text-right">{event.eventTime}</div>
					</div>
				}

				{event.eventStatus === "Finished" &&
					<div className="EventTile__Completed">
						Finished
					</div>
				}

				{event.eventStatus === "Active" &&
					<div className="EventTile__Active">
						<Row>
							<Col xs="6">
								<FontAwesomeIcon icon="hourglass-half" color="#b9bbc4" />
								<Countdown date={event.entryWindow.end} />
							</Col>
							<Col xs="6">
								In Progress
							</Col>
						</Row>
					</div>
				}
			</div>
		);
	};
}

/**
 * Define expected props.
 */
EventTile.propTypes = {
    event: PropTypes.object.isRequired,
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
    }),
    push: PropTypes.func,
    getChampionshipOptions: PropTypes.func
};

//Connect this container component to the redux store.
export default connect(
    state => { return { application: state.application.value } },
    dispatch => bindActionCreators({ push }, dispatch)
)(EventTile);