/**
 * Screenshot Reducers
 */

import * as actionTypes from '../actions/ActionTypes';
import { StatefulValue, states } from '../models/StatefulValue';

/**
 * initial state
 */
const INIT_STATE = {
    screenshots: new StatefulValue([]),
}; 

export default function (state = INIT_STATE, action) {
    switch (action.type) {

        case actionTypes.REQUEST_SCREENSHOTS:
            return {
                ...state,
                screenshots: new StatefulValue([], states.Loading)
            };

        case actionTypes.RECEIVED_SCREENSHOTS_SUCCESS:
            return {
                ...state,
                screenshots: new StatefulValue(action.data.screenshots, states.Ready)
            };

        case actionTypes.RECEIVED_SCREENSHOTS_FAILURE:
            return {
                ...state,
                screenshots: new StatefulValue([], states.Faulted)
            };
        
        default: return state;
    }
};