import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function requestChampionshipOptions() {
    return {
        type: actionTypes.REQUEST_CHAMPIONSHIPOPTIONS
    };
}

export function receivedChampionshipOptionsSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPOPTIONS_SUCCESS,
        data
    };
}

export function receivedChampionshipOptionsFailure(error) {
    return {
        type: actionTypes.RECEIVED_CHAMPIONSHIPOPTIONS_FAILURE,
        data: { error }
    };
}

/***
 * Fetch Championship Options
 */
export function getChampionshipOptions() {
    return function (dispatch) {
        dispatch(requestChampionshipOptions());
        axiosFactory.getApiInstance().get('/api/Club/championship/options')
            .then((resp) => {
                dispatch(receivedChampionshipOptionsSuccess(resp.data));
            })
            .catch((error) => {
                dispatch(receivedChampionshipOptionsFailure(error));
            });
    };
}