import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import * as actions from '../../actions/NewsActions';
import RecentNews from '../../containers/Redux/RecentNews';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { push } from 'connected-react-router';
import NewsBanner from '../../components/NewsBanner/NewsBanner';
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import { states } from '../../models/StatefulValue';
import SocialShare from '../../components/SocialShare/SocialShare';
import dom from 'component-dom';
import ContentResolver from '../../components/Kentico/ContentResolver';
import VideoWidget from '../../components/Kentico/Video/VideoWidget';
import moment from 'moment';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../assets/scss/news.scss';

library.add(faArrowLeft,faSearchPlus);

class NewsPost extends Component {
    displayName = NewsPost.name

    constructor(props) {
        super(props);
		this.state = { postId: this.props.match.params.url };
		this.goToNews = this.goToNews.bind(this);
    }

    /**
     * Fetch the requested news item.
     */
    componentDidMount() {      
        this.props.fetchNewsPost(this.state.postId);
        window.addEventListener("resize", this.updateVideoDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateVideoDimensions);
    }

    /**
     * Redirect to not found if fetching the provided news item fails.
     * @param {any} prevProps
     * @param {any} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        this.updatePostFromUrl();
        this.updateVideoDimensions();

        const posts = this.props.posts;
        const postId = this.state.postId;
        if (posts[postId] && posts[postId].isFaulted()) {
            this.props.push('/not-found');
        }

        //initialise any instagram or twitter embeds.
        /*if (posts[postId] && posts[postId].isReady()) {
            window.instgrm.Embeds.process();
            window.twttr.widgets.load();
        }*/
    }

    /***
     *
     */
    updatePostFromUrl() {
        const newIndex = this.props.match.params.url;
        if (this.state.postId !== newIndex) {
            this.setState({ postId: newIndex });
            this.props.fetchNewsPost(newIndex);
        }
    }

    /***
     * Navigate to the news page.
     */
    goToNews() {
        this.props.push('/News');
    }

    updateVideoDimensions() {
        var allVideos = dom(".News__Content").find("iframe[src*='player.vimeo.com']," + 
            "iframe[src*='youtube.com']," + 
            "iframe[src*='youtube-nocookie.com']," + 
            "object," + 
            "embed");
    
        allVideos.each(function() {
            let width = (this).parent()[0].offsetWidth;
    
            (this)
            .attr('height', (width * 720) / 1280)
            .attr('width', width);
        });
    }

    /**
     * Renders the news item.
     * @param {StatefulValue} newsPost newsPost item.
     * @returns {JSX} jsx
     */
    renderPost(newsPost) {
        if (newsPost && newsPost.isReady()) {
            var post = newsPost.value;
            const formattedDate = moment.utc(post.publicationDate).format('MMMM Do');
			return (
                <div>
					<Helmet>
						<title>{post.title} - DiRT Rally 2.0</title>
                        <meta name="description" content={post.metaDescription} />
					</Helmet>
                    {post.teaser.type === 1 &&
                        <NewsBanner
                            image={(post.teaser.teaserImage.url === null ? "/images/news_default.jpg" : `${post.teaser.teaserImage.url}?w=2000&h=800&fit=crop`)}
                            placeholder={(post.teaser.teaserImage.url === null ? "/images/news_default_ph.jpg" : `${post.teaser.teaserImage.url}?w=200&h=80&fit=crop`)}
                            title={post.title} 
                            author={post.author.name}
                            date={post.publicationDate}
                        />}
					<div className="News">
                        <Container>
							<Row>
                                <Col xs="12" sm="12" md="8" lg="9">
                                    {post.teaser.type === 2 &&
                                        <React.Fragment>
                                            <div className="News__Trailer m-b-50">
                                                <VideoWidget video={post.teaser.teaserVideo} />
                                            </div>  
                                            <div className="News__Title m-b-50">
                                                <Heading rank="3">{post.title}</Heading>
                                                <span className="NewsBanner__meta">By {post.author.name} -  On {formattedDate}</span>
                                            </div>
                                        </React.Fragment>                             
                                    }
                                    <div className="News__Content">
                                        <ContentResolver content={post.content} />
                                    </div>
									<div className="News__social">
										<Row className="m-b-50">
											<Col xs="6">
												<Button type="standard" onClick={this.goToNews}><FontAwesomeIcon icon="arrow-left" color="#FFF" /> All News</Button>
											</Col>
											<Col xs="6">
												<SocialShare quote={post.title} />
											</Col>
										</Row>
									</div>
								</Col>
								<Col xs="12" sm="12" md="4" lg="3">
									<Heading rank="3" divider={true}>Recent News</Heading>
									<div>
										<RecentNews currentNewsId={this.state.postId} />
									</div>
								</Col>
							</Row>
						</Container>
					</div>
                </div>
            );
        } else {
            return (
                <Container>
					<Row>
						<Col xs="12">
							<Loader currentState={states.Loading} />
						</Col>
					</Row>
                </Container>
            );
        }
    }

    /**
     * Render the page / component.
     */
    render() {
        const posts = this.props.posts;
        const postId = this.state.postId;

        return (
            <main role="main">
				{this.renderPost(posts[postId])}
            </main>
        );        
    }
}


NewsPost.propTypes = {
    posts: PropTypes.object.isRequired,
    fetchNewsPost: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { posts: state.news.posts }; },
    dispatch => bindActionCreators({ ...actions, push }, dispatch)
)(NewsPost);
