import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postJoinClub() {
    return {
        type: actionTypes.SEND_JOINCLUB
    };
}

export function postJoinClubSuccess(data, clubId) {
    return {
        type: actionTypes.RECEIVED_JOINCLUB_SUCCESS,
		data: { ...data, id: clubId }
    };
}

export function postJoinClubFailure(error, clubId) {
    return {
        type: actionTypes.RECEIVED_JOINCLUB_FAILURE,
        data: { ...error, id: clubId }
    };
}

/***
 * Join Club Function
 */
export function sendJoinClub(clubId) {
    return function (dispatch) {
        dispatch(postJoinClub());
        axiosFactory.getApiInstance().put('/api/Club/' + clubId + '/join')
        .then((resp) => {
			dispatch(postJoinClubSuccess(resp.data, clubId));
		})
		.catch((error) => {
			dispatch(postJoinClubFailure(error, clubId));
		});
    };
}