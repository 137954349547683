import * as actionTypes from '../ActionTypes';
import axiosFactory from '../../models/AxiosFactory';

export function postClubForm() {
    return {
        type: actionTypes.SEND_CREATECLUBFORM
    };
}

export function postClubFormSuccess(data) {
    return {
        type: actionTypes.RECEIVED_CREATECLUBFORM_SUCCESS,
        data
    };
}

export function postClubFormFailure(error) {
    return {
        type: actionTypes.RECEIVED_CREATECLUBFORM_FAILURE,
        data: { error }
    };
}

/***
 * Post the Create Club Form
 */
export function sendCreateClubForm(clubData) {
    return function (dispatch, getState) {
        dispatch(postClubForm());
        axiosFactory.getApiInstance().post('/api/Club', clubData)
        .then((resp) => {
			dispatch(postClubFormSuccess(resp.data));
		})
		.catch((error) => {
			dispatch(postClubFormFailure(error));
		});
    };
}