/**
 * Find Club
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import ClubSearch from '../../components/ClubSearch/ClubSearch';
import NoGameDataCheck from '../../containers/NoGameDataCheck';
import CreateClubActionBanner from '../../components/CreateClubActionBanner/CreateClubActionBanner';

import '../../assets/scss/clubs.scss';

class FindClub extends React.Component {
	
	constructor(props) {
		super(props);

        this.state = {
            page: (props.match.params.page ?  parseInt(props.match.params.page) : 1)
        };
	}
	
	componentDidUpdate(prevProps) {
		const newProps = this.props;

		if(prevProps.match.params.page !== newProps.match.params.page) {
			this.setState({
				page: (this.props.match.params.page ? parseInt(this.props.match.params.page) : 1)
			});
		}
	}

	render() {
		return (
			<NoGameDataCheck>
				<main className="FindClub" role="main">
					<div className="FindClub__Search">
						<Row>
							<Col xs="12">
								<ClubSearch page={this.state.page} />
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<CreateClubActionBanner />
							</Col>
						</Row>
					</div>
				</main>
			</NoGameDataCheck>
		);
	}
}

export default FindClub;