import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEsportEvents } from '../../actions/EsportActions';
import { getLeaderboard } from '../../actions/LeaderboardActions';
import EventTypeSelector from '../EventTypeSelector/EventTypeSelector';
import Tabs from '../Tabs/Tabs';
import Leaderboard from '../Leaderboard/Leaderboard';
import Loader from '../Loader/Loader';
import MediaQuery from 'react-responsive';
import { getCumulativeLeaderboard } from '../../actions/CumulativeLeaderboard';
import Heading from '../Heading/Heading';
import EventDropdownMenu from '../EventDropdownMenu/EventDropdownMenu';


import './EsportSchedule.scss'

/** 
 * Community Events
 */
class EsportSchedule extends React.Component {

	constructor(props) {
        super(props);

		this.state = {
			type: 'standard',
			selected: {
				challengeType: 0,
				challengeGroup: 0,
				challenge: 0,
				event: 0,
				stage: 0,
			},
			leaderboard: {
				challengeId: 0,
				selectedEventId: 0,
				stageId: 0,
				leaderboardId: 0, //cumulative only
				page: 1,
				pageSize: 10,
				orderByTotalTime: true,
                platformFilter: this.getIdentityPlatformFilter(),
				playerFilter: "Everyone",
				filterByAssists: "Unspecified",
				filterByWheel: "Unspecified",
				nationalityFilter: "None"
			}
		}

		this.updateChallengeType = this.updateChallengeType.bind(this);
		this.updateChallengeGroup = this.updateChallengeGroup.bind(this);
		this.updateChallenge = this.updateChallenge.bind(this);
		this.updateEvent = this.updateEvent.bind(this);
		this.updateStage = this.updateStage.bind(this);
		this.updateLeaderboard = this.updateLeaderboard.bind(this);
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
        this.props.fetchEsportEvents();
	}

	componentDidUpdate(oldProps, prevState) {
		const newProps = this.props;
		const newState = this.state;

		if(oldProps.esports.state !== newProps.esports.state) {
			if (this.props.esports.isReady()) {
				if(typeof this.props.esports.value[this.state.selected.challengeType] !== 'undefined') {			
					this.setState( prevState => {
						return {
							leaderboard: {
								...prevState.leaderboard, 
								challengeId: this.props.esports.value[0].challengeGroups[0].challenges[0].id,
								eventId: this.props.esports.value[0].challengeGroups[0].challenges[0].events[0].id,
								stageId: this.props.esports.value[0].challengeGroups[0].challenges[0].events[0].stages[0].id,
							}
						}
					}, () => this.props.getLeaderboard(this.state.leaderboard));
				}
			}
		}

		if(prevState.selected !== newState.selected) {
			if(this.state.type === "standard") {
				this.setState( prevState => {
					return {
						leaderboard: {
							...prevState.leaderboard, 
							challengeId: this.props.esports.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].id,
							eventId: this.props.esports.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].id,
							stageId: this.props.esports.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].challenges[this.state.selected.challenge].events[this.state.selected.event].stages[this.state.selected.stage].id,
						}
					}
				}, () => this.props.getLeaderboard(this.state.leaderboard));
			} else {
				this.setState( prevState => {
					return {
						leaderboard: {
							...prevState.leaderboard, 
							leaderboardId: this.props.esports.value[this.state.selected.challengeType].challengeGroups[this.state.selected.challengeGroup].overallLeaderboardId,
						}
					}
				}, () => this.props.getCumulativeLeaderboard(this.state.leaderboard));
			}
		}
	}

    getIdentityPlatformFilter() {
        var platforms = this.props.identityPlatforms;

        var currentPlatform = platforms.filter((value) => {
            return value.isSelected;
        });

        return currentPlatform.length > 0 ? this.getIdentityPlatformName(currentPlatform[0].name) : "None";
    }

    getIdentityPlatformName(name) {
        switch (name) {
            case 'Xbox One':
                return 'MicrosoftLive';
            case 'PS4':
                return 'PlaystationNetwork';
            case 'Steam':
                return 'Steam';
            case 'Oculus':
                return 'Oculus';
            case 'Windows Store':
                return 'WindowsStore';
            default:
                return 'MicrosoftLive';
        }
    }

	updateChallengeType(type) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challengeType: type,
					challengeGroup: 0,
					challenge: 0,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateChallengeGroup(group) {
		if(this.props.esports.value[this.state.selected.challengeType].challengeGroups[group].isOverall === true) {
			this.setState( prevState => {
				return {
					type: 'overall',
					selected: {
						...prevState.selected,
						challengeGroup: group,
						challenge: 0,
						event: 0,
						stage: 0
					},
					leaderboard: {
						...prevState.leaderboard,
						leaderboardId: this.props.esports.value[this.state.selected.challengeType].challengeGroups[group].overallLeaderboardId,
						page: 1
					}
				}
			});
	    } else {
			this.setState( prevState => {
				return {
					type: 'standard',
					selected: {
						...prevState.selected,
						challengeGroup: group,
						challenge: 0,
						event: 0,
						stage: 0
					},
					leaderboard: {
						...prevState.leaderboard,
						page: 1
					}
				}
			});
		} 
	}

	updateChallenge(challenge) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					challenge: challenge,
					event: 0,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateEvent(event) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					event: event,
					stage: 0
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}

	updateStage(stage) {
		this.setState( prevState => {
			return {
				selected: {
					...prevState.selected,
					stage: stage
				},
				leaderboard: {
					...prevState.leaderboard,
					page: 1
				}
			}
		});
	}
	
	updateLeaderboard(options) {
		if(this.state.type === "overall") {
			this.setState( prevState => {
				return {
					leaderboard: {
						...prevState.leaderboard,
						platformFilter: options.platformFilter,
						playerFilter: options.playerFilter,
						filterByAssists: options.filterByAssists,
						filterByWheel: options.filterByWheel,
						nationalityFilter: options.nationalityFilter,
						page: options.page,
						orderByTotalTime: options.orderByTotalTime
					}
				}
			}, () => this.props.getCumulativeLeaderboard(this.state.leaderboard));
		} else {
			this.setState( prevState => {
				return {
					leaderboard: {
						...prevState.leaderboard,
						platformFilter: options.platformFilter,
						playerFilter: options.playerFilter,
						filterByAssists: options.filterByAssists,
						filterByWheel: options.filterByWheel,
						nationalityFilter: options.nationalityFilter,
						page: options.page,
						orderByTotalTime: options.orderByTotalTime
					}
				}
			}, () => this.props.getLeaderboard(this.state.leaderboard));
		}
	}

	render() {

        if (this.props.esports.isReady()) {
			if(typeof this.props.esports.value[this.state.selected.challengeType] !== 'undefined') {			
				return (
					<div className="CommunityEvents">

						{this.state.type === "standard" &&
							<EventTypeSelector challengeType={this.props.esports.value[this.state.selected.challengeType]} selectedChallengeGroup={this.state.selected.challengeGroup} selectedChallenge={this.state.selected.challenge} selectedEvent={this.state.selected.event} selectedStage={this.state.selected.stage} totalChallenges={this.props.esports.value.length - 1} esport={true} onChange={this.updateChallengeType} overall={false} />
						}

						{this.state.type === "overall" &&
							<EventTypeSelector challengeType={this.props.esports.value[this.state.selected.challengeType]} totalChallenges={this.props.esports.value.length - 1} esport={true} onChange={this.updateChallengeType} overall={true} />
						}

						{this.props.esports.value.length &&
							<React.Fragment>

								<MediaQuery query="(min-width: 769px)">
									<Tabs tabs={this.props.esports.value[this.state.selected.challengeType].challengeGroups} onChange={this.updateChallengeGroup} />
								</MediaQuery>

								<MediaQuery query="(min-width: 769px)">
									<Container>
									
										<Row>
											<Col xs="12">
												{this.state.type === "standard" &&		
                                                <Leaderboard data={this.props.leaderboard} onChange={this.updateLeaderboard} order={this.state.leaderboard.orderByTotalTime} platformOnly={true} currentFilter={this.state.leaderboard.platformFilter} />
												}
												
												{this.state.type === "overall" &&		
                                                <Leaderboard data={this.props.cumulativeLeaderboard} onChange={this.updateLeaderboard} order={this.state.leaderboard.orderByTotalTime} platformOnly={true} overall={true} currentFilter={this.state.leaderboard.platformFilter} />
												}
											</Col>
										</Row>
									</Container>
								</MediaQuery>

								<MediaQuery query="(max-width: 768px)">
									<div className="CommunityEvents__EventSelectors">
										<EventDropdownMenu type="challengeGroup" options={this.props.esports.value[this.state.selected.challengeType].challengeGroups} onChange={this.updateChallengeGroup} />
									</div>	
                                {this.state.type === "standard" &&
                                    <Leaderboard data={this.props.leaderboard} onChange={this.updateLeaderboard} platformOnly={true} currentFilter={this.state.leaderboard.platformFilter} />
									}

									{this.state.type === "overall" &&		
                                    <Leaderboard data={this.props.cumulativeLeaderboard} onChange={this.updateLeaderboard} platformOnly={true} overall={true} currentFilter={this.state.leaderboard.platformFilter} />
									}
								</MediaQuery>
							</React.Fragment>
						}


					</div>
				);
			} else {
				return (
					<div className="CommunityEvents">
						<div className="Club__NoChampionship">
							<Heading rank="3" className="m-b-10">Looks like no eSports events exist</Heading>
							<p>Please check back again later</p>
						</div>
					</div>
				);
			} 
        } else if (this.props.esports.isFaulted()) {
            return (<Loader currentState={this.props.esports.state} errorMessage="Failed to load community events" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.esports.state} />);
        }    
    }
}

/**
 * Define expected props.
 */
EsportSchedule.propTypes = {
    esports: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.array.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	leaderboard: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	cumulativeLeaderboard: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
    application: PropTypes.shape({
        cdnEndpoint: PropTypes.string.isRequired,
    }),
	fetchEsportEvents: PropTypes.func.isRequired,
	getLeaderboard: PropTypes.func.isRequired,
	getCumulativeLeaderboard: PropTypes.func.isRequired
};

//Connect this container component to the redux store.
export default connect(
    state => { return { esports: state.esports.events, leaderboard: state.challenge.leaderboard, cumulativeLeaderboard: state.cumulativeLeaderboard.cumulativeLeaderboard, application: state.application.value, identityPlatforms: state.identity.platforms } }, 
    dispatch => bindActionCreators({ fetchEsportEvents, getLeaderboard, getCumulativeLeaderboard }, dispatch)
)(EsportSchedule); 