import React from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './EventDropdownMenu.scss';

library.add(faSortDown);

/**
 * Styled Dropdown Menu
 */
class EventDropdownMenu extends React.Component {
	
	constructor(props) {
		super(props);

		this.setActiveItem = this.setActiveItem.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.setDropdownRef = this.setDropdownRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);

		this.state = {
			isOpen: false,
			activeItem: this.props.selectedEvent,
			selectedEvent: this.props.selectedEvent,
			selectedStage: 0
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(oldProps) {
		const newProps = this.props;

		if(oldProps.options !== newProps.options) {
			this.setState({
				activeItem: 0
			})
		}
	}
	
	setActiveItem(index) {
		this.setState({
			activeItem: index
		}, () => this.props.onChange(this.state.activeItem));
	}

	toggleMenu() {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	setDropdownRef(node) {
		this.dropdownRef = node;
	}

	handleClickOutside(event) {
		if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
			this.setState({
				isOpen: false
			})
		}
	}

	addLeadingZeros(value) {
		value = String(value);
		while (value.length < 2) {
		  value = '0' + value;
		}
		return value;
	}

	render() {  
		const events = this.props.options;

		if(this.props.type === "event") {
			return (
				<div className="EventDropDownContainer">
					<div className={"EventDropdownMenu " + (this.state.isOpen ? 'is-active' : '')} 
						onClick={events.length > 1 ? () => this.toggleMenu() : null}
						ref={this.setDropdownRef}
					>
						{events.length > 1 && <FontAwesomeIcon icon="sort-down" color="#FFF" />}
						<ul>
							{events.map((event, index) => 
								<li key={index} className={this.state.activeItem === index ? "is-active" : ""} onClick={this.state.isOpen ? () => this.setActiveItem(index) : null}>
									<span><Translate id="async.event">Event</Translate> {this.addLeadingZeros(index + 1)}:</span> {event.name}
								</li>
							)}
						</ul>
					</div>
				</div>
			);
		} else if (this.props.type === "stages") {
			const stages = this.props.options;
			return (
				<div className="EventDropDownContainer">
					<div className={"EventDropdownMenu " + (this.state.isOpen ? 'is-active' : '')} 
						onClick={stages.length > 1 ? () => this.toggleMenu() : null}
						ref={this.setDropdownRef}
					>
						{stages.length > 1 && <FontAwesomeIcon icon="sort-down" color="#FFF" />}
						<ul>
							{stages.map((stage, index) => 
								<li key={index} className={this.state.activeItem === index ? "is-active" : ""} onClick={this.state.isOpen ? () => this.setActiveItem(index) : null}>
									<span><Translate id="async.stage">Stage</Translate> {this.addLeadingZeros(index + 1)}:</span> {stage.name}
								</li>
							)}
						</ul>
					</div>
				</div>
			);
		} else if(this.props.type === "challengeGroup") {
			const challengeGroups = this.props.options;
			return (
				<div className="EventDropDownContainer">
					<div className={"EventDropdownMenu " + (this.state.isOpen ? 'is-active' : '')} 
						onClick={challengeGroups.length > 1 ? () => this.toggleMenu() : null}
						ref={this.setDropdownRef}
					>
						{challengeGroups.length > 1 && <FontAwesomeIcon icon="sort-down" color="#FFF" />}
						<ul>
							{challengeGroups.map((challengeGroup, index) => 
								<li key={index} className={this.state.activeItem === index ? "is-active" : ""} onClick={this.state.isOpen ? () => this.setActiveItem(index) : null}>
									<span>{challengeGroup.name}</span>
								</li>
							)}
						</ul>
					</div>
				</div>
			);
		}
	};
}

EventDropdownMenu.defaultProps = {
	type: "event",
	selectedEvent: 0,
	onChange: event => {
		// eslint-disable-next-line no-console
		console.log('You have clicked me!', event.target); 
	},
};

/**
 * Define expected props.
 */
EventDropdownMenu.propTypes = {
	type: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	selectedEvent: PropTypes.number,
	onChange: PropTypes.func,
};

export default EventDropdownMenu;