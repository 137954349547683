import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchInvites } from '../../actions/Clubs/GetInvites';
import ClubTile from '../ClubTile/ClubTile';
import Loader from '../Loader/Loader';
import { Row, Col } from 'reactstrap';
import MediaQuery from 'react-responsive';
import Heading from '../../components/Heading/Heading';
import Slider from "react-slick";

import './ClubInvites.scss'

/** 
 * Club Invites
 */
class ClubInvites extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			page: 1,
			pageSize: 10
		}
	}

	/**
     * componentDidMount() is invoked immediately after a component is mounted (inserted into the tree). Initialization that requires DOM nodes should go here.
     */
    componentDidMount() {
		this.props.fetchInvites(this.state);
	}

	render() {

		var sliderSettings = {
			dots: true,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: false,
			infinite: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				  	}
				}
			  ]
		};

        if (this.props.clubs.isReady()) {
			const invitations = this.props.clubs.value.invitations;
			
            return (
				<React.Fragment>
					{invitations.length > 0 &&
						<div className="ClubInvites">
							<Row className="m-b-15">
								<MediaQuery query="(min-width: 769px)">
									<Col xs="12" style={{textAlign: "center"}}>
										<Heading rank="3">Pending Invites</Heading>
									</Col>
								</MediaQuery>
								<MediaQuery query="(max-width: 768px)">
									<Col xs="12">
										<Heading rank="3" className="p-l-30">Pending Invites</Heading>
									</Col>
								</MediaQuery>
							</Row>
							<Row>
								<Col xs="12">
									<Slider {...sliderSettings}>
										{invitations.map((club, index) => 
											<div className="ClubInvites__Tile" key={index}><ClubTile key={index} club={club.club} invite={true} invitee={club.inviteFromName} /></div>
										)}
									</Slider>	
								</Col>
							</Row>
						</div>
					}
				</React.Fragment>
			);
        } else if (this.props.clubs.isFaulted()) {
            return (<Loader currentState={this.props.clubs.state} errorMessage="Failed to load clubs" />);
        } else /*isIntialising() or isLoading()*/ {
			return (<Loader currentState={this.props.clubs.state} />);
        }    
    }
}

/**
 * Define expected props.
 */
ClubInvites.propTypes = {
    clubs: PropTypes.shape({
        state: PropTypes.string.isRequired,
		value: PropTypes.object.isRequired,
        isReady: PropTypes.func.isRequired,
        isFaulted: PropTypes.func.isRequired,
	}),
	fetchInvites: PropTypes.func.isRequired,
};

//Connect this container component to the redux store.
export default connect(
    state => { return { clubs: state.getInvites.clubs } }, 
    dispatch => bindActionCreators({ fetchInvites }, dispatch)
)(ClubInvites); 